import React from "react";
import Typography from "@material-ui/core/Typography";
import { ErrorSuccessPopupBody } from "./index.css";
import { Grid } from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelIcon from "@material-ui/icons/Cancel";

function SuccessPopup(props) {
  return (
    <ErrorSuccessPopupBody>
      <div id="successPopup_successMsgBox" className="successMsgBox">
        <Grid container>
          <Grid item xs={1}>
            <CheckCircleOutlineOutlinedIcon
              id="successPopup_Icon"
              className="successOrErrorIcon"
            />
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h5"
              id="successPopup_successLabel"
              className="successLabel"
            >
              Success:
            </Typography>
          </Grid>
          <Grid item xs={1} align="right">
            <CancelIcon
              id="successPopup_closeIcon"
              className="closeIcon"
              onClick={() => {
                props?.handleClose();
              }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <label id="successPopup_respMsg" className="respMsg">
              <Typography
                variant="h6"
                id="successPopup_successText"
                className="successText"
                data-testid="successText"
              >
                {props.successPopupText}
              </Typography>
            </label>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </ErrorSuccessPopupBody>
  );
}

export default SuccessPopup;
