export const accountItemColumns = [
  { id: "changeType", label: "Change Type" },
  { id: "issuerName", label: "Issuer Name" },
  { id: "issuerLei", label: "Issuer LEI" },
  { id: "issuerAccountNo", label: "Issuer Account No (optional)" },
  { id: "issuerAgentName", label: "Issuer Agent Name" },
  { id: "issuerAgentAccountNo", label: "Issuer Agent Account No" },
  { id: "payAgentAccountNo", label: "Paying Agent Account No" },
  { id: "cbfAktEmitCode", label: "Issuer Short Code" },
  { id: "protocol", label: "Protocol" },
];

export const blacklistColumns = [
  { id: "changeType", label: "Change Type" },
  { id: "underlyingISIN", label: "ISIN of the Underlying" },
  { id: "underlyingName", label: "Name of the Underlying" },
  { id: "issuerCountry", label: "Country of the Issuer" },
  { id: "entryDate", label: "Date of Entry" },
  { id: "lE", label: "LE" },
  { id: "comment", label: "Comment" },
  { id: "addedBy", label: "Added By" },
  { id: "reviewedBy", label: "Reviewed By" },
  { id: "protocol", label: "Protocol" },
  { id: "errorText", label: "Error" },
];

export const whitelistColumns = [
  { id: "changeType", label: "Change Type" },
  { id: "underlyingISIN", label: "ISIN of the Underlying" },
  { id: "underlyingName", label: "Name of the Underlying" },
  { id: "entryDate", label: "Date of Entry" },
  { id: "lE", label: "LE" },
  { id: "comment", label: "Comment" },
  { id: "addedBy", label: "Added By" },
  { id: "reviewedBy", label: "Reviewed By" },
  { id: "protocol", label: "Protocol" },
  { id: "errorText", label: "Error" },
];

export const issuerGreylistColumns = [
  { id: "changeType", label: "Change Type" },
  { id: "LEI", label: "LEI" },
  { id: "name", label: "Name" },
  { id: "entryDate", label: "Date of Entry" },
  { id: "lE", label: "LE" },
  { id: "comment", label: "Comment" },
  { id: "addedBy", label: "Added By" },
  { id: "reviewedBy", label: "Reviewed By" },
  { id: "protocol", label: "Protocol" },
  { id: "errorText", label: "Error" },
];

export const issuerWhitelistColumns = [
  { id: "changeType", label: "Change Type" },
  { id: "LEI", label: "LEI" },
  { id: "name", label: "Issuer Name" },
  { id: "issuerCountry", label: "Country of Institution" },
  { id: "entryDate", label: "Date of Entry" },
  { id: "lE", label: "LE" },
  { id: "comment", label: "Comment" },
  { id: "addedBy", label: "Added By" },
  { id: "reviewedBy", label: "Reviewed By" },
  { id: "protocol", label: "Protocol" },
  { id: "errorText", label: "Error" },
];

export const validationErrorColumns = [
  { id: "fieldId", label: "Field" },
  { id: "messageId", label: "Error Message" },
  { id: "message", label: "Details" },
];
