/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Trading model module.
 * @module model/Trading
 * @version 1.0.3
 */
class Trading {
    /**
     * Constructs a new <code>Trading</code>.
     * @alias module:model/Trading
     */
    constructor() { 
        
        Trading.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Trading</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Trading} obj Optional instance to populate.
     * @return {module:model/Trading} The populated <code>Trading</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Trading();

            if (data.hasOwnProperty('MarketIdentifier')) {
                obj['MarketIdentifier'] = ApiClient.convertToType(data['MarketIdentifier'], 'String');
            }
            if (data.hasOwnProperty('FirstTradingDate')) {
                obj['FirstTradingDate'] = ApiClient.convertToType(data['FirstTradingDate'], 'Date');
            }
            if (data.hasOwnProperty('LastTradingDate')) {
                obj['LastTradingDate'] = ApiClient.convertToType(data['LastTradingDate'], 'Date');
            }
            if (data.hasOwnProperty('TradingSegment')) {
                obj['TradingSegment'] = ApiClient.convertToType(data['TradingSegment'], 'String');
            }
            if (data.hasOwnProperty('TradingHoursStart')) {
                obj['TradingHoursStart'] = ApiClient.convertToType(data['TradingHoursStart'], 'String');
            }
            if (data.hasOwnProperty('TradingHoursEnd')) {
                obj['TradingHoursEnd'] = ApiClient.convertToType(data['TradingHoursEnd'], 'String');
            }
            if (data.hasOwnProperty('TechnicalSuspension')) {
                obj['TechnicalSuspension'] = ApiClient.convertToType(data['TechnicalSuspension'], 'Date');
            }
            if (data.hasOwnProperty('AdmissionRequestDate')) {
                obj['AdmissionRequestDate'] = ApiClient.convertToType(data['AdmissionRequestDate'], 'Date');
            }
            if (data.hasOwnProperty('Index')) {
                obj['Index'] = ApiClient.convertToType(data['Index'], 'String');
            }
            if (data.hasOwnProperty('TransactionType')) {
                obj['TransactionType'] = ApiClient.convertToType(data['TransactionType'], 'String');
            }
            if (data.hasOwnProperty('FinalPriceType')) {
                obj['FinalPriceType'] = ApiClient.convertToType(data['FinalPriceType'], 'String');
            }
            if (data.hasOwnProperty('ReferenceRate')) {
                obj['ReferenceRate'] = ApiClient.convertToType(data['ReferenceRate'], 'String');
            }
            if (data.hasOwnProperty('CommoditiesDerivativeIndicator')) {
                obj['CommoditiesDerivativeIndicator'] = ApiClient.convertToType(data['CommoditiesDerivativeIndicator'], 'Boolean');
            }
            if (data.hasOwnProperty('TranactionType')) {
                obj['TranactionType'] = ApiClient.convertToType(data['TranactionType'], 'String');
            }
            if (data.hasOwnProperty('NominalValuePerUnit')) {
                obj['NominalValuePerUnit'] = ApiClient.convertToType(data['NominalValuePerUnit'], 'Number');
            }
            if (data.hasOwnProperty('Term')) {
                obj['Term'] = ApiClient.convertToType(data['Term'], 'Number');
            }
            if (data.hasOwnProperty('AdmissionReceiptDate')) {
                obj['AdmissionReceiptDate'] = ApiClient.convertToType(data['AdmissionReceiptDate'], 'Date');
            }
            if (data.hasOwnProperty('ExpiryDate')) {
                obj['ExpiryDate'] = ApiClient.convertToType(data['ExpiryDate'], 'Date');
            }
            if (data.hasOwnProperty('AdmissionApprovalDate')) {
                obj['AdmissionApprovalDate'] = ApiClient.convertToType(data['AdmissionApprovalDate'], 'Date');
            }
            if (data.hasOwnProperty('TerminationDate')) {
                obj['TerminationDate'] = ApiClient.convertToType(data['TerminationDate'], 'Date');
            }
            if (data.hasOwnProperty('NotionalCurrency')) {
                obj['NotionalCurrency'] = ApiClient.convertToType(data['NotionalCurrency'], 'String');
            }
            if (data.hasOwnProperty('PriceMultiplier')) {
                obj['PriceMultiplier'] = ApiClient.convertToType(data['PriceMultiplier'], 'Number');
            }
            if (data.hasOwnProperty('BaseProduct')) {
                obj['BaseProduct'] = ApiClient.convertToType(data['BaseProduct'], 'String');
            }
            if (data.hasOwnProperty('SubProduct')) {
                obj['SubProduct'] = ApiClient.convertToType(data['SubProduct'], 'String');
            }
            if (data.hasOwnProperty('AdditionalSubProduct')) {
                obj['AdditionalSubProduct'] = ApiClient.convertToType(data['AdditionalSubProduct'], 'String');
            }
            if (data.hasOwnProperty('BasisPoint')) {
                obj['BasisPoint'] = ApiClient.convertToType(data['BasisPoint'], 'Number');
            }
            if (data.hasOwnProperty('FxType')) {
                obj['FxType'] = ApiClient.convertToType(data['FxType'], 'String');
            }
            if (data.hasOwnProperty('StrikePrice')) {
                obj['StrikePrice'] = ApiClient.convertToType(data['StrikePrice'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Trading</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Trading</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['MarketIdentifier'] && !(typeof data['MarketIdentifier'] === 'string' || data['MarketIdentifier'] instanceof String)) {
            throw new Error("Expected the field `MarketIdentifier` to be a primitive type in the JSON string but got " + data['MarketIdentifier']);
        }
        // ensure the json data is a string
        if (data['TradingSegment'] && !(typeof data['TradingSegment'] === 'string' || data['TradingSegment'] instanceof String)) {
            throw new Error("Expected the field `TradingSegment` to be a primitive type in the JSON string but got " + data['TradingSegment']);
        }
        // ensure the json data is a string
        if (data['TradingHoursStart'] && !(typeof data['TradingHoursStart'] === 'string' || data['TradingHoursStart'] instanceof String)) {
            throw new Error("Expected the field `TradingHoursStart` to be a primitive type in the JSON string but got " + data['TradingHoursStart']);
        }
        // ensure the json data is a string
        if (data['TradingHoursEnd'] && !(typeof data['TradingHoursEnd'] === 'string' || data['TradingHoursEnd'] instanceof String)) {
            throw new Error("Expected the field `TradingHoursEnd` to be a primitive type in the JSON string but got " + data['TradingHoursEnd']);
        }
        // ensure the json data is a string
        if (data['Index'] && !(typeof data['Index'] === 'string' || data['Index'] instanceof String)) {
            throw new Error("Expected the field `Index` to be a primitive type in the JSON string but got " + data['Index']);
        }
        // ensure the json data is a string
        if (data['TransactionType'] && !(typeof data['TransactionType'] === 'string' || data['TransactionType'] instanceof String)) {
            throw new Error("Expected the field `TransactionType` to be a primitive type in the JSON string but got " + data['TransactionType']);
        }
        // ensure the json data is a string
        if (data['FinalPriceType'] && !(typeof data['FinalPriceType'] === 'string' || data['FinalPriceType'] instanceof String)) {
            throw new Error("Expected the field `FinalPriceType` to be a primitive type in the JSON string but got " + data['FinalPriceType']);
        }
        // ensure the json data is a string
        if (data['ReferenceRate'] && !(typeof data['ReferenceRate'] === 'string' || data['ReferenceRate'] instanceof String)) {
            throw new Error("Expected the field `ReferenceRate` to be a primitive type in the JSON string but got " + data['ReferenceRate']);
        }
        // ensure the json data is a string
        if (data['TranactionType'] && !(typeof data['TranactionType'] === 'string' || data['TranactionType'] instanceof String)) {
            throw new Error("Expected the field `TranactionType` to be a primitive type in the JSON string but got " + data['TranactionType']);
        }
        // ensure the json data is a string
        if (data['NotionalCurrency'] && !(typeof data['NotionalCurrency'] === 'string' || data['NotionalCurrency'] instanceof String)) {
            throw new Error("Expected the field `NotionalCurrency` to be a primitive type in the JSON string but got " + data['NotionalCurrency']);
        }
        // ensure the json data is a string
        if (data['BaseProduct'] && !(typeof data['BaseProduct'] === 'string' || data['BaseProduct'] instanceof String)) {
            throw new Error("Expected the field `BaseProduct` to be a primitive type in the JSON string but got " + data['BaseProduct']);
        }
        // ensure the json data is a string
        if (data['SubProduct'] && !(typeof data['SubProduct'] === 'string' || data['SubProduct'] instanceof String)) {
            throw new Error("Expected the field `SubProduct` to be a primitive type in the JSON string but got " + data['SubProduct']);
        }
        // ensure the json data is a string
        if (data['AdditionalSubProduct'] && !(typeof data['AdditionalSubProduct'] === 'string' || data['AdditionalSubProduct'] instanceof String)) {
            throw new Error("Expected the field `AdditionalSubProduct` to be a primitive type in the JSON string but got " + data['AdditionalSubProduct']);
        }
        // ensure the json data is a string
        if (data['FxType'] && !(typeof data['FxType'] === 'string' || data['FxType'] instanceof String)) {
            throw new Error("Expected the field `FxType` to be a primitive type in the JSON string but got " + data['FxType']);
        }

        return true;
    }


}



/**
 * Exchange(s) where the securities are to be listed. Note the enumeration includes 'Not Listed' as an option for unlisted trades.[279]
 * @member {String} MarketIdentifier
 */
Trading.prototype['MarketIdentifier'] = undefined;

/**
 * Date on which the transaction was executed for private placements; first pricing date for benchmark transactions.[98]
 * @member {Date} FirstTradingDate
 */
Trading.prototype['FirstTradingDate'] = undefined;

/**
 * Last day of trading at the relevant exchanges or OTC channels[99]
 * @member {Date} LastTradingDate
 */
Trading.prototype['LastTradingDate'] = undefined;

/**
 * Trading segment that is designated in the listing application.[289]
 * @member {String} TradingSegment
 */
Trading.prototype['TradingSegment'] = undefined;

/**
 * The trading time start for an instrument on the respective exchange.[294]
 * @member {String} TradingHoursStart
 */
Trading.prototype['TradingHoursStart'] = undefined;

/**
 * Time at which trading of the instrument on the respective exchange stops.[295]
 * @member {String} TradingHoursEnd
 */
Trading.prototype['TradingHoursEnd'] = undefined;

/**
 * Date and time of technical suspension[323]
 * @member {Date} TechnicalSuspension
 */
Trading.prototype['TechnicalSuspension'] = undefined;

/**
 * Date and time of the request for admission to trading on the trading venue.[534]
 * @member {Date} AdmissionRequestDate
 */
Trading.prototype['AdmissionRequestDate'] = undefined;

/**
 * Name of the index or benchmark of a floating rate bond. To be filled where no ISIN identifier exists. If the index name is not included in the index list on Table 1 of the Annex of the Regulatory Technical Standard on MiFIR Article 27 (RTS 23), then a 25 character name should be used.[539]
 * @member {String} Index
 */
Trading.prototype['Index'] = undefined;

/**
 * A code field identifying the transaction type as specified by the trading venue (Futures, Options, TAPOS, Swaps, Minis, OTC, Outright, Crack, Differential).[543]
 * @member {String} TransactionType
 */
Trading.prototype['TransactionType'] = undefined;

/**
 * A code field identifying the final price type as specified by the trading venue (Argus/McCloskey, Baltic, Exchange, GlobalCOAL, HIS McCloskey, Platts).[544]
 * @member {String} FinalPriceType
 */
Trading.prototype['FinalPriceType'] = undefined;

/**
 * Name of the reference rate. This field is only to be populated for instruments that have a nonfinancial instrument of type interest rates as underlying.[545]
 * @member {String} ReferenceRate
 */
Trading.prototype['ReferenceRate'] = undefined;

/**
 * Indication as to whether the financial instrument falls within the definition of commodities derivative under Article 2(1)(30) of Regulation (EU) No 600/2014.[546]
 * @member {Boolean} CommoditiesDerivativeIndicator
 */
Trading.prototype['CommoditiesDerivativeIndicator'] = undefined;

/**
 * A code field identifying the transaction type as specified by the trading venue (Futures, Options, TAPOS, Swaps, Minis, OTC, Outright, Crack, Differential).[552]
 * @member {String} TranactionType
 */
Trading.prototype['TranactionType'] = undefined;

/**
 * Nominal value of each instrument. If not available, the minimum traded value shall be populated. Must be expressed in the currency reported under field 16.[553]
 * @member {Number} NominalValuePerUnit
 */
Trading.prototype['NominalValuePerUnit'] = undefined;

/**
 * If the asset class is Interest Rates, this field states the term of the contract. The term shall be expressed in days, weeks, months or years.[554]
 * @member {Number} Term
 */
Trading.prototype['Term'] = undefined;

/**
 * Whether the issuer of the financial instrument has requested or approved the trading or admission to trading of their financial instruments on a trading venue.[570]
 * @member {Date} AdmissionReceiptDate
 */
Trading.prototype['AdmissionReceiptDate'] = undefined;

/**
 * Expiry date of the financial instrument. Field only applies to derivatives with a defined expiry date.[571]
 * @member {Date} ExpiryDate
 */
Trading.prototype['ExpiryDate'] = undefined;

/**
 * Date and time the issuer has approved admission to trading or trading in its financial instruments on a trading venue.[650]
 * @member {Date} AdmissionApprovalDate
 */
Trading.prototype['AdmissionApprovalDate'] = undefined;

/**
 * Date and time when the financial instrument ceases to be traded or to be admitted to trading on the trading venue. Where this date and time is not yet known, the field shall not be populated. The field shall always be populated as soon as it is known, even if it is deemed redundant with “Maturity date” for bonds or “Expiry date” for derivatives. The day should indicate the last day when the instrument is still traded on the trading venue. The time can be either end of day, or end of the trading session.[651]
 * @member {Date} TerminationDate
 */
Trading.prototype['TerminationDate'] = undefined;

/**
 * Currency in which the notional is denominated. In the case of an interest rate or currency derivative contract, this will be the notional currency of leg 1 or the currency 1 of the pair. In the case of swaptions where the underlying swap is single-currency, this will be the notional currency of the underlying swap. For swaptions where the underlying is multi-currency, this will be the notional currency of leg 1 of the swap.[652]
 * @member {String} NotionalCurrency
 */
Trading.prototype['NotionalCurrency'] = undefined;

/**
 * Number of units of the underlying instrument represented by a single derivative contract. For a future or option on an index, the amount per index point.[653]
 * @member {Number} PriceMultiplier
 */
Trading.prototype['PriceMultiplier'] = undefined;

/**
 * A code field identifying the base product for the underlying class.[654]
 * @member {String} BaseProduct
 */
Trading.prototype['BaseProduct'] = undefined;

/**
 * A code field identifying the sub product for the underlying asset class. Field requires a Base product.[655]
 * @member {String} SubProduct
 */
Trading.prototype['SubProduct'] = undefined;

/**
 * A code field identifying the base product for the underlying class. The Further sub product for the underlying asset class as specified in the classification of commodities derivatives table. Field requires a Sub product.[656]
 * @member {String} AdditionalSubProduct
 */
Trading.prototype['AdditionalSubProduct'] = undefined;

/**
 * Number of basis points above or below the index used to calculate a price[657]
 * @member {Number} BasisPoint
 */
Trading.prototype['BasisPoint'] = undefined;

/**
 * Type of undelying currency (FX Cross Rates, FX Emerging Markets, FX Majors)[658]
 * @member {String} FxType
 */
Trading.prototype['FxType'] = undefined;

/**
 * Predetermined price at which the holder will have to buy or sell the underlying instrument, or an indication that the price cannot be determined at the time of execution. Field only applies to options, warrants, spread bet on an option on an equity or contract for difference on an option on an equity. Where price is currently not available but pending, the value shall be ’PNDG’. Where strike price is not applicable the field shall not be populated.[659]
 * @member {Number} StrikePrice
 */
Trading.prototype['StrikePrice'] = undefined;






export default Trading;

