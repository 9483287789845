/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountInfo model module.
 * @module model/AccountInfo
 * @version 1.0.3
 */
class AccountInfo {
    /**
     * Constructs a new <code>AccountInfo</code>.
     * @alias module:model/AccountInfo
     */
    constructor() { 
        
        AccountInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountInfo} obj Optional instance to populate.
     * @return {module:model/AccountInfo} The populated <code>AccountInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountInfo();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('issuerAccountNo')) {
                obj['issuerAccountNo'] = ApiClient.convertToType(data['issuerAccountNo'], 'String');
            }
            if (data.hasOwnProperty('issuerLei')) {
                obj['issuerLei'] = ApiClient.convertToType(data['issuerLei'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentName')) {
                obj['issuerAgentName'] = ApiClient.convertToType(data['issuerAgentName'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentAccountNo')) {
                obj['issuerAgentAccountNo'] = ApiClient.convertToType(data['issuerAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('payAgentAccountNo')) {
                obj['payAgentAccountNo'] = ApiClient.convertToType(data['payAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('cbfAktEmitCode')) {
                obj['cbfAktEmitCode'] = ApiClient.convertToType(data['cbfAktEmitCode'], 'String');
            }
            if (data.hasOwnProperty('uploadTimestamp')) {
                obj['uploadTimestamp'] = ApiClient.convertToType(data['uploadTimestamp'], 'Date');
            }
            if (data.hasOwnProperty('uploaderUserId')) {
                obj['uploaderUserId'] = ApiClient.convertToType(data['uploaderUserId'], 'String');
            }
            if (data.hasOwnProperty('uploaderUserName')) {
                obj['uploaderUserName'] = ApiClient.convertToType(data['uploaderUserName'], 'String');
            }
            if (data.hasOwnProperty('reviewerUserName')) {
                obj['reviewerUserName'] = ApiClient.convertToType(data['reviewerUserName'], 'String');
            }
            if (data.hasOwnProperty('reviewerUserId')) {
                obj['reviewerUserId'] = ApiClient.convertToType(data['reviewerUserId'], 'String');
            }
            if (data.hasOwnProperty('reviewTimestamp')) {
                obj['reviewTimestamp'] = ApiClient.convertToType(data['reviewTimestamp'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AccountInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AccountInfo</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['issuerAccountNo'] && !(typeof data['issuerAccountNo'] === 'string' || data['issuerAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAccountNo` to be a primitive type in the JSON string but got " + data['issuerAccountNo']);
        }
        // ensure the json data is a string
        if (data['issuerLei'] && !(typeof data['issuerLei'] === 'string' || data['issuerLei'] instanceof String)) {
            throw new Error("Expected the field `issuerLei` to be a primitive type in the JSON string but got " + data['issuerLei']);
        }
        // ensure the json data is a string
        if (data['issuerAgentName'] && !(typeof data['issuerAgentName'] === 'string' || data['issuerAgentName'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentName` to be a primitive type in the JSON string but got " + data['issuerAgentName']);
        }
        // ensure the json data is a string
        if (data['issuerAgentAccountNo'] && !(typeof data['issuerAgentAccountNo'] === 'string' || data['issuerAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentAccountNo` to be a primitive type in the JSON string but got " + data['issuerAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['payAgentAccountNo'] && !(typeof data['payAgentAccountNo'] === 'string' || data['payAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `payAgentAccountNo` to be a primitive type in the JSON string but got " + data['payAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['cbfAktEmitCode'] && !(typeof data['cbfAktEmitCode'] === 'string' || data['cbfAktEmitCode'] instanceof String)) {
            throw new Error("Expected the field `cbfAktEmitCode` to be a primitive type in the JSON string but got " + data['cbfAktEmitCode']);
        }
        // ensure the json data is a string
        if (data['uploaderUserId'] && !(typeof data['uploaderUserId'] === 'string' || data['uploaderUserId'] instanceof String)) {
            throw new Error("Expected the field `uploaderUserId` to be a primitive type in the JSON string but got " + data['uploaderUserId']);
        }
        // ensure the json data is a string
        if (data['uploaderUserName'] && !(typeof data['uploaderUserName'] === 'string' || data['uploaderUserName'] instanceof String)) {
            throw new Error("Expected the field `uploaderUserName` to be a primitive type in the JSON string but got " + data['uploaderUserName']);
        }
        // ensure the json data is a string
        if (data['reviewerUserName'] && !(typeof data['reviewerUserName'] === 'string' || data['reviewerUserName'] instanceof String)) {
            throw new Error("Expected the field `reviewerUserName` to be a primitive type in the JSON string but got " + data['reviewerUserName']);
        }
        // ensure the json data is a string
        if (data['reviewerUserId'] && !(typeof data['reviewerUserId'] === 'string' || data['reviewerUserId'] instanceof String)) {
            throw new Error("Expected the field `reviewerUserId` to be a primitive type in the JSON string but got " + data['reviewerUserId']);
        }

        return true;
    }


}



/**
 * Id
 * @member {String} id
 */
AccountInfo.prototype['id'] = undefined;

/**
 * Issuer Name
 * @member {String} issuerName
 */
AccountInfo.prototype['issuerName'] = undefined;

/**
 * issuerAccountNo
 * @member {String} issuerAccountNo
 */
AccountInfo.prototype['issuerAccountNo'] = undefined;

/**
 * Issuer Lei
 * @member {String} issuerLei
 */
AccountInfo.prototype['issuerLei'] = undefined;

/**
 * Issuer Agent Name
 * @member {String} issuerAgentName
 */
AccountInfo.prototype['issuerAgentName'] = undefined;

/**
 * Issuer Agent Account No
 * @member {String} issuerAgentAccountNo
 */
AccountInfo.prototype['issuerAgentAccountNo'] = undefined;

/**
 * payAgentAccountNo
 * @member {String} payAgentAccountNo
 */
AccountInfo.prototype['payAgentAccountNo'] = undefined;

/**
 * cbfAktEmitCode
 * @member {String} cbfAktEmitCode
 */
AccountInfo.prototype['cbfAktEmitCode'] = undefined;

/**
 * The time when changeset was uploaded (ISO 8601 standard)
 * @member {Date} uploadTimestamp
 */
AccountInfo.prototype['uploadTimestamp'] = undefined;

/**
 * The internal id of the user who uploaded this changeset
 * @member {String} uploaderUserId
 */
AccountInfo.prototype['uploaderUserId'] = undefined;

/**
 * The name of the user who uploaded this changeset
 * @member {String} uploaderUserName
 */
AccountInfo.prototype['uploaderUserName'] = undefined;

/**
 * The name of the user who reviewed this changeset
 * @member {String} reviewerUserName
 */
AccountInfo.prototype['reviewerUserName'] = undefined;

/**
 * The internal id of the user who reviewed this changeset
 * @member {String} reviewerUserId
 */
AccountInfo.prototype['reviewerUserId'] = undefined;

/**
 * The time when review was done (ISO 8601 standard)
 * @member {Date} reviewTimestamp
 */
AccountInfo.prototype['reviewTimestamp'] = undefined;






export default AccountInfo;

