/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TXStoreTransaction from './TXStoreTransaction';

/**
 * The TXStoreTransactions model module.
 * @module model/TXStoreTransactions
 * @version 1.0.3
 */
class TXStoreTransactions {
    /**
     * Constructs a new <code>TXStoreTransactions</code>.
     * @alias module:model/TXStoreTransactions
     */
    constructor() { 
        
        TXStoreTransactions.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TXStoreTransactions</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TXStoreTransactions} obj Optional instance to populate.
     * @return {module:model/TXStoreTransactions} The populated <code>TXStoreTransactions</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TXStoreTransactions();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [TXStoreTransaction]);
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>TXStoreTransactions</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TXStoreTransactions</code>.
     */
    static validateJSON(data) {
        if (data['items']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['items'])) {
                throw new Error("Expected the field `items` to be an array in the JSON data but got " + data['items']);
            }
            // validate the optional field `items` (array)
            for (const item of data['items']) {
                TXStoreTransaction.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * @member {Array.<module:model/TXStoreTransaction>} items
 */
TXStoreTransactions.prototype['items'] = undefined;

/**
 * Total number of records
 * @member {Number} count
 */
TXStoreTransactions.prototype['count'] = undefined;






export default TXStoreTransactions;

