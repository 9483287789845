import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const StatusListing = ({
  handleChange,
  expandState,
  isDesktop,
  user,
  issuanceObj,
}) => (
  <Accordion
    className="accordianStyle"
    id="detcp_Acc3"
    expanded={expandState["panel3"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel3a-content"
      id="panel3a-header"
      onClick={() => {
        handleChange("panel3");
      }}
    >
      <Typography variant="h3" id="detcp_sl">
        Status & Listing
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="detcp_sl_acc">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Overall Status"}
          value={issuanceObj.overallStatus}
        />
        <RowElement
          label={"Settlement Currency"}
          value={issuanceObj.redemptionCashAmountCurrency}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Processing Status (external)"}
          value={issuanceObj.processingStatus}
        />
        <RowElement label={"MIC"} value={issuanceObj.listingLocation} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Processing Status (internal)"}
          value={issuanceObj.internalStatus}
        />
        <RowElement
          label={"OTC Channel"}
          value={issuanceObj.otcChannelsActual}
        />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default StatusListing;
