import React, { useState, useEffect, useRef } from "react";
import "./NavBar.css";
import backImg from "../../assets/clearstream-vector-logo.svg";
import userLogo from "../../assets/user-logo.png";
import { Typography, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useUserStore } from "../../store/Store";
import moment from "moment-timezone";
import decode from "jwt-decode";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useHistory } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { logout } from "../../store/actions/UserActions";
import { convertDateTime } from "../../utils/dateUtils";
import { DATE_FORMATS } from "../../constants";

function NavBar(props) {
  const [activePage, setActivePage] = useState(""); // to set the styling of nav-underline on currently selected tab
  const [userState, userDispatch] = useUserStore(); // to have access of store
  const [open, setOpen] = React.useState(false); //for visibility of maintainance dropdown
  const anchorRef = useRef(null); //refer "material-ui/react-menu/"" --> MenuList composition example
  const prevOpen = useRef(open); //refer "material-ui/react-menu/"" --> MenuList composition example
  const history = useHistory(); // to use useHistory hook we need to declare it this way
  const initialNotificationState = {
    dot: "",
    color: "settingIcon",
    errorItemHighlight: "",
  };
  const [notificationState, setNotificationState] = React.useState(
    initialNotificationState
  );

  let id_token = sessionStorage.getItem("jwtToken");
  const userId = sessionStorage.getItem("userId");
  let user = "";
  /*
   * Token decoding
   */
  if (process.env.REACT_APP_ENABLED === "false") {
    user = sessionStorage.getItem("user");
    if (user) {
      sessionStorage.setItem(
        "party",
        decode(id_token)["https://daml.com/ledger-api"].actAs[0]
      );
    }
  } else {
    let userId = decode(id_token).sub;
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem(
      "party",
      decode(id_token)["https://daml.com/ledger-api"].actAs[0]
    );
    user = decode(id_token)
      ["https://daml.com/ledger-api"].actAs[0].substring(11)
      .replace(/(([A-Z])[a-z])/g, " $1")
      .replace(/:.*/, "")
      .trimStart();
    sessionStorage.setItem("user", user);
  }

  useEffect(() => {
    history.location.pathname === "/issuanceDetails" && setActivePage("");
  }, [history.location.pathname]);

  /*
   * This method is to let user logout
   */
  const signOut = () => {
    sessionStorage.clear();
    window.location.reload(false);

    userDispatch(logout());
  };

  /*
   * This method is to toggle between maintainance dropdown appreance and disappreance when clicked on setting's icon
   */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /*
   * This method is to close maintainance dropdown once clicked on any of the option or outside that dropdown
   */
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    sessionStorage.removeItem("pending");
    if (event.target.id === "compList") {
      history.push("/complianceLists");
      setActivePage("");
    } else if (event.target.id === "audExp") {
      history.push("/auditExport");
      setActivePage("");
    } else if (event.target.id === "issuerStaticData") {
      history.push("/issuerStaticData");
      setActivePage("");
    }
    // else if (event.target.id === "nonProcessableIsinLog") {
    //   history.push("/nonProcessableIsinLog");
    //   setActivePage("");
    //   setNotificationState(initialNotificationState);
    // }
    else if (event.target.id === "accountsList") {
      history.push("/accountlist");
      setActivePage("");
    } else if (event.target.id === "transactionStoreLog") {
      history.push("/transactionStore");
      setActivePage("");
    }
  };

  /*
   * This method is return focus to the button when we transitioned from !open -> open
   */
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <nav id="nav_NavStyling" className="NavStyling">
      <Grid className="customContainer">
        <Grid item xs={3} alignItems="center" className="backImg">
          <img src={backImg} alt="dbg" id="nav_backImg" />
        </Grid>
        {((process.env.REACT_APP_ENABLED === "true" &&
          userState.isAuthenticated) ||
          (process.env.REACT_APP_ENABLED === "false" && user)) && (
          <Grid className="customContainer" item xs={9}>
            <Grid className="customContainer" item xs={9} align="center">
              <Grid
                item
                xs={user === "Clearstream" ? 3 : 4}
                align="center"
                id="nav_issuance"
                className={`${
                  activePage === "issuance" ? "nav-underlink" : ""
                }`}
              >
                <NavLink
                  exact
                  to="/issuance"
                  isActive={(match) => {
                    if (match) {
                      setActivePage("issuance");
                      sessionStorage.removeItem("search");
                      return true;
                    }
                  }}
                  onClick={() => {
                    props.setShowDetails(false);
                    props.setTimeStamp(
                      convertDateTime(moment.now(), DATE_FORMATS.DATETIME)
                    );
                    sessionStorage.removeItem("pending");
                  }}
                >
                  <Typography
                    variant={activePage === "issuance" ? "h3" : "h4"}
                    id="nav_issuances"
                    data-testid="issuances"
                    className="navLink"
                  >
                    Issuances
                  </Typography>
                </NavLink>
              </Grid>
              {user === "Clearstream" &&
                (!userState.userRoleRight.includes("View_Pending") ? (
                  <Grid
                    item
                    xs={user === "Clearstream" ? 3 : 4}
                    align="center"
                    id="nav_pending"
                    data-testid="pending"
                    className={`${
                      activePage === "pending" ? "nav-underlink" : ""
                    }`}
                  >
                    <NavLink
                      exact
                      to="/pending"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Typography
                        variant={activePage === "pending" ? "h3" : "h4"}
                        id="nav_pendingText"
                        className="navLink"
                      >
                        Pending
                      </Typography>
                    </NavLink>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={user === "Clearstream" ? 3 : 4}
                    align="center"
                    id="nav_pending"
                    data-testid="pending"
                    className={`${
                      activePage === "pending" ? "nav-underlink" : ""
                    }`}
                  >
                    <NavLink
                      exact
                      to="/pending"
                      isActive={(match) => {
                        if (match) {
                          setActivePage("pending");
                          return true;
                        }
                      }}
                    >
                      <Typography
                        variant={activePage === "pending" ? "h3" : "h4"}
                        id="nav_pendingText"
                        className="navLink"
                      >
                        Pending [{props.count}]
                      </Typography>
                    </NavLink>
                  </Grid>
                ))}
              {!userState.userRoles.includes("Clearstream Compliance") && (
                <Grid
                  item
                  xs={user === "Clearstream" ? 3 : 4}
                  align="center"
                  id="nav_cefi"
                  data-testid="cefi"
                >
                  <a href="/centralregister/">
                    <h4
                      class="MuiTypography-root navLink MuiTypography-h4"
                      id="nav_cefi"
                      data-testid="cefi"
                    >
                      Central Register{" "}
                      {user === "Clearstream" && `[${props.ceFiCount || 0}]`}
                    </h4>
                  </a>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              xs={3}
              id="nav_paddingLeft"
              className="paddingLeft"
            >
              <Grid item xs={1} id="nav_paddingTop" className="paddingTop">
                <img src={userLogo} id="nav_userLogo_img" alt="userLogo" />
              </Grid>
              <Grid item xs={8} id="nav_userLogo" className="userLogo">
                <Typography
                  variant="h6"
                  id="nav_userLogoText"
                  className="userLogoText"
                >
                  {userId}
                </Typography>
                <Typography
                  variant="subtitle1"
                  id="nav_timeStamp"
                  className="timeStamp"
                >
                  {props.timeStamp
                    ? props.timeStamp
                    : convertDateTime(moment.now(), DATE_FORMATS.DATETIME)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                align="center"
                id="nav_notification"
                className={notificationState.color}
              >
                <div id="nav_dot" className={notificationState.dot}></div>
                {
                  <SettingsIcon
                    className="settingMargin"
                    align="end"
                    ref={anchorRef}
                    id="composition-button"
                    data-testid="settings_icon"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  />
                }
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  className="setting"
                  id="nav_setting"
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <Typography
                          variant="h6"
                          id="nav_maintenance"
                          className="maintenance"
                        >
                          Maintenance
                        </Typography>
                        <Divider id="nav_divider" className="divider" />
                        {user === "Clearstream" ? (
                          <>
                            {userState.userRoleRight.includes(
                              "View_Audit_Export"
                            ) && (
                              <MenuItem id="audExp" onClick={handleClose}>
                                <Typography
                                  data-testid="audexp_text"
                                  variant="h6"
                                  id="audExp"
                                  onClick={handleClose}
                                >
                                  Audit Export
                                </Typography>
                              </MenuItem>
                            )}
                            {(userState.userRoleRight.includes(
                              "View_Compliance_List"
                            ) ||
                              userState.userRoles.includes(
                                "Clearstream Compliance"
                              )) && (
                              <MenuItem id="compList" onClick={handleClose}>
                                <Typography
                                  variant="h6"
                                  data-testid="compliance_text"
                                  id="compList"
                                  onClick={handleClose}
                                >
                                  Compliance Lists
                                </Typography>
                              </MenuItem>
                            )}
                            {userState.userRoleRight.includes(
                              "View_Issuer_Static_Data"
                            ) && (
                              <MenuItem id="accountsList" onClick={handleClose}>
                                <Typography
                                  data-testid="accountsList_text"
                                  variant="h6"
                                  id="accountsList"
                                  onClick={handleClose}
                                >
                                  Account Lists
                                </Typography>
                              </MenuItem>
                            )}
                            {/* {userState.userRoleRight.includes(
                              "View_Unknown_ISIN_Log"
                            ) && (
                              <MenuItem
                                id="nonProcessableIsinLog"
                                onClick={handleClose}
                              >
                                <Typography
                                  className={
                                    notificationState.errorItemHighlight
                                  }
                                  data-testid="unknownIsin_text"
                                  variant="h6"
                                  id="nonProcessableIsinLog"
                                  onClick={handleClose}
                                >
                                  Non Processable ISIN Log
                                </Typography>
                              </MenuItem>
                            )} */}
                            {userState.userRoleRight.includes(
                              "View_Transaction_Store_Log"
                            ) && (
                              <MenuItem
                                id="transactionStoreLog"
                                onClick={handleClose}
                              >
                                <Typography
                                  className={
                                    notificationState.errorItemHighlight
                                  }
                                  data-testid="transaction-store"
                                  variant="h6"
                                  id="transactionStoreLog"
                                  onClick={handleClose}
                                >
                                  Transaction Store Query
                                </Typography>
                              </MenuItem>
                            )}
                          </>
                        ) : (
                          <>
                            {userState.userRoleRight.includes(
                              "View_Audit_Export"
                            ) && (
                              <MenuItem>
                                <Typography
                                  id="audExp"
                                  variant="h6"
                                  onClick={handleClose}
                                >
                                  Audit Export
                                </Typography>
                              </MenuItem>
                            )}
                            {/* {userState.userRoleRight.includes(
                              "View_Unknown_ISIN_Log"
                            ) && (
                              <MenuItem>
                                <Typography
                                  className={
                                    notificationState.errorItemHighlight
                                  }
                                  id="nonProcessableIsinLog"
                                  variant="h6"
                                  onClick={handleClose}
                                >
                                  Non Processable ISIN Log
                                </Typography>
                              </MenuItem>
                            )} */}
                          </>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              </Grid>
              {process.env.REACT_APP_ENABLED === "false" && (
                <Grid item xs={1} align="end" className="paddingTop">
                  <NavLink exact to="/">
                    <ExitToAppIcon
                      className="exitIcon"
                      id="nav_exitIcon"
                      data-testid="signout_icon"
                      fontSize="medium"
                      variant="fill"
                      href="/"
                      onClick={() => signOut()}
                    />
                  </NavLink>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </nav>
  );
}

export default NavBar;
