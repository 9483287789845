import React, { useState } from "react";
import { ViewDetailsContainer } from "../index.css";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUserStore } from "../../../store/Store";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDowloadIcon from "@mui/icons-material/FileDownload";
import MessagePopup from "../MessagePopup";
import UploadFile from "../UploadFile";
import { AppCommonContext } from "../../../App";
import ListTable from "../ListTable";
import { useHistory } from "react-router-dom";
import { typesValueToLabel } from "../../Pending/Reviews/Reviews";
import { useChangesets } from "../hooks/useChangesets";
import { Space } from "antd";
import { DATE_FORMATS } from "../../../constants";
import { convertDateTime } from "../../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
  dialogPaper: {
    width: "480px",
    height: "320px",
  },
  reportModal: {
    width: "80%",
    height: "400px",
  },
}));

const WhiteListChangeset = () => {
  const classes = useStyles();
  const history = useHistory();
  const appContext = React.useContext(AppCommonContext);
  const { setIndividualPendingSectionCount } = appContext;
  const [userState] = useUserStore();
  const [data, setData] = useState([]);
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [uploadFileOpen, setUploadFileOpen] = useState(false);
  const [lastUploadTime, setLastUploadTime] = useState();

  const { exportTemplate, uploadList, itemsCount, changePage, loading } =
    useChangesets(
      "whitelist",
      setData,
      setResponseText,
      setShowPopUp,
      setflagPopUp,
      userState,
      setLastUploadTime,
      setUploadFileOpen,
      setIndividualPendingSectionCount
    );

  const uploadWhitelist = (uploadedFiles) =>
    uploadList("whitelist", uploadedFiles);

  const exportWhitelistTemplate = () => exportTemplate("whitelist");

  const handlePageChange = (pageNumber, pageSize) => {
    const newNumOfPages = Math.ceil(itemsCount / pageSize);
    const newPage = pageNumber > newNumOfPages ? newNumOfPages : pageNumber;

    changePage("whitelist", newPage, pageSize);
  };

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  const openDetailsPage = (id, type) => {
    const _type = Object.keys(typesValueToLabel).find(
      (key) => typesValueToLabel[key] === type
    );
    history.push({
      pathname: `/${_type}/details/${id}`,
    });
  };

  return (
    <React.Fragment>
      <ViewDetailsContainer>
        <MessagePopup
          responseText={responseText}
          showPopUp={showPopUp}
          flagPopUp={flagPopUp}
          closeErrorSuccessPopup={closeErrorSuccessPopup}
        />

        <Grid container>
          <Grid
            item
            xs={3}
            align="start"
            style={{ textAlign: "justify", alignSelf: "end" }}
          >
            <Typography variant="h5" id="det_typo_isindet" className="item">
              Last Upload:{" "}
              {lastUploadTime &&
                convertDateTime(lastUploadTime, DATE_FORMATS.DATE_AND_TIME)}
            </Typography>
          </Grid>
          <Grid item xs={9} align="end">
            <Space>
              <Button
                variant="outlined"
                id="btn_uploadWhiteList"
                color="primary"
                className="btnUploadWhiteList"
                disabled={userState.userRoles.includes(
                  "Clearstream Compliance"
                )}
                onClick={() => setUploadFileOpen(true)}
              >
                {" "}
                <FileUploadIcon /> Upload
              </Button>
              <Button
                variant="outlined"
                id="btn_downloadloadWhiteList"
                color="primary"
                className="btnUploadWhiteList"
                onClick={exportWhitelistTemplate}
              >
                {" "}
                <FileDowloadIcon /> Export Whitelist Template
              </Button>
            </Space>
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      <ListTable
        loading={loading}
        data={data}
        classes={classes}
        onRowClick={openDetailsPage}
        displayTypeColumn={false}
        itemsCount={itemsCount}
        onPageChange={handlePageChange}
      />
      <UploadFile
        classes={classes}
        uploadFileOpen={uploadFileOpen}
        closeDialog={() => setUploadFileOpen(false)}
        uploadlist={uploadWhitelist}
      />
    </React.Fragment>
  );
};
export default WhiteListChangeset;
