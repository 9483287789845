import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const AccountSenderDetails = ({
  handleChange,
  expandState,
  isDesktop,
  issuanceObj,
}) => (
  <Accordion
    className="accordianStyle"
    id="cp_account_acc6"
    expanded={expandState["panel6"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel6a-content"
      id="panel6a-header"
      onClick={() => {
        handleChange("panel6");
      }}
    >
      <Typography variant="h3" id="cp_account_typo6">
        Account & Sender Details
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="cp_account_accdetails">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Issuer Agent Account"}
          value={issuanceObj.issuerCbfNo}
        />
        <RowElement
          label={"Issuer Agent Name"}
          value={issuanceObj.senderName}
        />
        <RowElement label={"Issuer LEI"} value={issuanceObj.issuerLei} />
        <RowElement label={"Message Type"} value={issuanceObj.messageType} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Paying Agent Account"}
          value={issuanceObj.payingAgentAccount}
        />
        <RowElement
          label={"Exercise Agent"}
          value={issuanceObj.exerciseAgent}
        />
        <RowElement
          label={"Telephone"}
          value={issuanceObj.issuerAgentTelephone}
        />
        <RowElement label={"E-Mail"} value={issuanceObj.issuerAgentEmail} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Issuer Short Code"}
          value={issuanceObj.issuerShortCode}
        />
        <RowElement
          label={"Securities Account Number"}
          value={issuanceObj.securitiesAccountNumber}
        />
        <RowElement
          label={"Date of Delivery"}
          value={issuanceObj.messageDateTime}
        />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default AccountSenderDetails;
