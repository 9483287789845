import styled from "styled-components";
import CustomTooltip from "../CustomTooltip";

export const StyledDiv = styled.div`
  display: inline-block;
  max-width: inherit;
`;

export const StyledCustomTooltip = styled(CustomTooltip)`
  .MuiTooltip-arrow {
    left: 2% !important;
  }
  .MuiTooltip-tooltip {
    height: 30px;
    font-size: 12px;
    max-width: 150px !important;
  }

  .MuiTooltip-tooltipPlacementTop {
    margin: 2px 0 !important;
  }
`;
