/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The IssuanceErrorDetails model module.
 * @module model/IssuanceErrorDetails
 * @version 1.0.3
 */
class IssuanceErrorDetails {
    /**
     * Constructs a new <code>IssuanceErrorDetails</code>.
     * @alias module:model/IssuanceErrorDetails
     * @param errorAdditionalInfo {Array.<String>} Error Additional Info
     * @param errorCode {String} Error Code
     * @param errorMessage {String} Error Message
     */
    constructor(errorAdditionalInfo, errorCode, errorMessage) { 
        
        IssuanceErrorDetails.initialize(this, errorAdditionalInfo, errorCode, errorMessage);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, errorAdditionalInfo, errorCode, errorMessage) { 
        obj['errorAdditionalInfo'] = errorAdditionalInfo;
        obj['errorCode'] = errorCode;
        obj['errorMessage'] = errorMessage;
    }

    /**
     * Constructs a <code>IssuanceErrorDetails</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/IssuanceErrorDetails} obj Optional instance to populate.
     * @return {module:model/IssuanceErrorDetails} The populated <code>IssuanceErrorDetails</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new IssuanceErrorDetails();

            if (data.hasOwnProperty('errorAdditionalInfo')) {
                obj['errorAdditionalInfo'] = ApiClient.convertToType(data['errorAdditionalInfo'], ['String']);
            }
            if (data.hasOwnProperty('errorCode')) {
                obj['errorCode'] = ApiClient.convertToType(data['errorCode'], 'String');
            }
            if (data.hasOwnProperty('errorMessage')) {
                obj['errorMessage'] = ApiClient.convertToType(data['errorMessage'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>IssuanceErrorDetails</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>IssuanceErrorDetails</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of IssuanceErrorDetails.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is an array
        if (!Array.isArray(data['errorAdditionalInfo'])) {
            throw new Error("Expected the field `errorAdditionalInfo` to be an array in the JSON data but got " + data['errorAdditionalInfo']);
        }
        // ensure the json data is a string
        if (data['errorCode'] && !(typeof data['errorCode'] === 'string' || data['errorCode'] instanceof String)) {
            throw new Error("Expected the field `errorCode` to be a primitive type in the JSON string but got " + data['errorCode']);
        }
        // ensure the json data is a string
        if (data['errorMessage'] && !(typeof data['errorMessage'] === 'string' || data['errorMessage'] instanceof String)) {
            throw new Error("Expected the field `errorMessage` to be a primitive type in the JSON string but got " + data['errorMessage']);
        }

        return true;
    }


}

IssuanceErrorDetails.RequiredProperties = ["errorAdditionalInfo", "errorCode", "errorMessage"];

/**
 * Error Additional Info
 * @member {Array.<String>} errorAdditionalInfo
 */
IssuanceErrorDetails.prototype['errorAdditionalInfo'] = undefined;

/**
 * Error Code
 * @member {String} errorCode
 */
IssuanceErrorDetails.prototype['errorCode'] = undefined;

/**
 * Error Message
 * @member {String} errorMessage
 */
IssuanceErrorDetails.prototype['errorMessage'] = undefined;






export default IssuanceErrorDetails;

