/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BusinessProcess from './BusinessProcess';

/**
 * The LifeCycle model module.
 * @module model/LifeCycle
 * @version 1.0.3
 */
class LifeCycle {
    /**
     * Constructs a new <code>LifeCycle</code>.
     * @alias module:model/LifeCycle
     */
    constructor() { 
        
        LifeCycle.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LifeCycle</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LifeCycle} obj Optional instance to populate.
     * @return {module:model/LifeCycle} The populated <code>LifeCycle</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LifeCycle();

            if (data.hasOwnProperty('BusinessProcess')) {
                obj['BusinessProcess'] = ApiClient.convertToType(data['BusinessProcess'], [BusinessProcess]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>LifeCycle</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LifeCycle</code>.
     */
    static validateJSON(data) {
        if (data['BusinessProcess']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['BusinessProcess'])) {
                throw new Error("Expected the field `BusinessProcess` to be an array in the JSON data but got " + data['BusinessProcess']);
            }
            // validate the optional field `BusinessProcess` (array)
            for (const item of data['BusinessProcess']) {
                BusinessProcess.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * Model: BusinessProcess
 * @member {Array.<module:model/BusinessProcess>} BusinessProcess
 */
LifeCycle.prototype['BusinessProcess'] = undefined;






export default LifeCycle;

