/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SecuritiesTransfer model module.
 * @module model/SecuritiesTransfer
 * @version 1.0.3
 */
class SecuritiesTransfer {
    /**
     * Constructs a new <code>SecuritiesTransfer</code>.
     * @alias module:model/SecuritiesTransfer
     */
    constructor() { 
        
        SecuritiesTransfer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SecuritiesTransfer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SecuritiesTransfer} obj Optional instance to populate.
     * @return {module:model/SecuritiesTransfer} The populated <code>SecuritiesTransfer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SecuritiesTransfer();

            if (data.hasOwnProperty('SharesOddAmount')) {
                obj['SharesOddAmount'] = ApiClient.convertToType(data['SharesOddAmount'], 'Number');
            }
            if (data.hasOwnProperty('SecurityIdentification')) {
                obj['SecurityIdentification'] = ApiClient.convertToType(data['SecurityIdentification'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SecuritiesTransfer</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SecuritiesTransfer</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['SecurityIdentification'] && !(typeof data['SecurityIdentification'] === 'string' || data['SecurityIdentification'] instanceof String)) {
            throw new Error("Expected the field `SecurityIdentification` to be a primitive type in the JSON string but got " + data['SecurityIdentification']);
        }

        return true;
    }


}



/**
 * Amount not booked[345]
 * @member {Number} SharesOddAmount
 */
SecuritiesTransfer.prototype['SharesOddAmount'] = undefined;

/**
 * Used in DIML payment file, AutomaticRedemptionProcess Field 7   Shares ISIN[344]
 * @member {String} SecurityIdentification
 */
SecuritiesTransfer.prototype['SecurityIdentification'] = undefined;






export default SecuritiesTransfer;

