/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Step from './Step';
import Submitter from './Submitter';

/**
 * The BusinessProcess model module.
 * @module model/BusinessProcess
 * @version 1.0.3
 */
class BusinessProcess {
    /**
     * Constructs a new <code>BusinessProcess</code>.
     * @alias module:model/BusinessProcess
     */
    constructor() { 
        
        BusinessProcess.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BusinessProcess</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BusinessProcess} obj Optional instance to populate.
     * @return {module:model/BusinessProcess} The populated <code>BusinessProcess</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BusinessProcess();

            if (data.hasOwnProperty('CorrelationId')) {
                obj['CorrelationId'] = ApiClient.convertToType(data['CorrelationId'], 'String');
            }
            if (data.hasOwnProperty('Submitter')) {
                obj['Submitter'] = Submitter.constructFromObject(data['Submitter']);
            }
            if (data.hasOwnProperty('Version')) {
                obj['Version'] = ApiClient.convertToType(data['Version'], 'String');
            }
            if (data.hasOwnProperty('BusinessProcessName')) {
                obj['BusinessProcessName'] = ApiClient.convertToType(data['BusinessProcessName'], 'String');
            }
            if (data.hasOwnProperty('Step')) {
                obj['Step'] = ApiClient.convertToType(data['Step'], [Step]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>BusinessProcess</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BusinessProcess</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['CorrelationId'] && !(typeof data['CorrelationId'] === 'string' || data['CorrelationId'] instanceof String)) {
            throw new Error("Expected the field `CorrelationId` to be a primitive type in the JSON string but got " + data['CorrelationId']);
        }
        // validate the optional field `Submitter`
        if (data['Submitter']) { // data not null
          Submitter.validateJSON(data['Submitter']);
        }
        // ensure the json data is a string
        if (data['Version'] && !(typeof data['Version'] === 'string' || data['Version'] instanceof String)) {
            throw new Error("Expected the field `Version` to be a primitive type in the JSON string but got " + data['Version']);
        }
        // ensure the json data is a string
        if (data['BusinessProcessName'] && !(typeof data['BusinessProcessName'] === 'string' || data['BusinessProcessName'] instanceof String)) {
            throw new Error("Expected the field `BusinessProcessName` to be a primitive type in the JSON string but got " + data['BusinessProcessName']);
        }
        if (data['Step']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Step'])) {
                throw new Error("Expected the field `Step` to be an array in the JSON data but got " + data['Step']);
            }
            // validate the optional field `Step` (array)
            for (const item of data['Step']) {
                Step.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * 
 * @member {String} CorrelationId
 */
BusinessProcess.prototype['CorrelationId'] = undefined;

/**
 * @member {module:model/Submitter} Submitter
 */
BusinessProcess.prototype['Submitter'] = undefined;

/**
 * 
 * @member {String} Version
 */
BusinessProcess.prototype['Version'] = undefined;

/**
 * 
 * @member {String} BusinessProcessName
 */
BusinessProcess.prototype['BusinessProcessName'] = undefined;

/**
 * Model: Step
 * @member {Array.<module:model/Step>} Step
 */
BusinessProcess.prototype['Step'] = undefined;






export default BusinessProcess;

