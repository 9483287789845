/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Form model module.
 * @module model/Form
 * @version 1.0.3
 */
class Form {
    /**
     * Constructs a new <code>Form</code>.
     * @alias module:model/Form
     */
    constructor() { 
        
        Form.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Form</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Form} obj Optional instance to populate.
     * @return {module:model/Form} The populated <code>Form</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Form();

            if (data.hasOwnProperty('AfterExchangePhysicalForm')) {
                obj['AfterExchangePhysicalForm'] = ApiClient.convertToType(data['AfterExchangePhysicalForm'], 'String');
            }
            if (data.hasOwnProperty('LegalForm')) {
                obj['LegalForm'] = ApiClient.convertToType(data['LegalForm'], 'String');
            }
            if (data.hasOwnProperty('GlobalNoteRequired')) {
                obj['GlobalNoteRequired'] = ApiClient.convertToType(data['GlobalNoteRequired'], 'Boolean');
            }
            if (data.hasOwnProperty('GlobalType')) {
                obj['GlobalType'] = ApiClient.convertToType(data['GlobalType'], 'String');
            }
            if (data.hasOwnProperty('InitialPhysicalForm')) {
                obj['InitialPhysicalForm'] = ApiClient.convertToType(data['InitialPhysicalForm'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Form</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Form</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['AfterExchangePhysicalForm'] && !(typeof data['AfterExchangePhysicalForm'] === 'string' || data['AfterExchangePhysicalForm'] instanceof String)) {
            throw new Error("Expected the field `AfterExchangePhysicalForm` to be a primitive type in the JSON string but got " + data['AfterExchangePhysicalForm']);
        }
        // ensure the json data is a string
        if (data['LegalForm'] && !(typeof data['LegalForm'] === 'string' || data['LegalForm'] instanceof String)) {
            throw new Error("Expected the field `LegalForm` to be a primitive type in the JSON string but got " + data['LegalForm']);
        }
        // ensure the json data is a string
        if (data['GlobalType'] && !(typeof data['GlobalType'] === 'string' || data['GlobalType'] instanceof String)) {
            throw new Error("Expected the field `GlobalType` to be a primitive type in the JSON string but got " + data['GlobalType']);
        }
        // ensure the json data is a string
        if (data['InitialPhysicalForm'] && !(typeof data['InitialPhysicalForm'] === 'string' || data['InitialPhysicalForm'] instanceof String)) {
            throw new Error("Expected the field `InitialPhysicalForm` to be a primitive type in the JSON string but got " + data['InitialPhysicalForm']);
        }

        return true;
    }


}



/**
 * Indicates the physical form of the securities after the exchange of the initial certificate issued on the closing date. Mandatory for Bearer Notes with a Temporary Global Note Initial Physical Form.[54]
 * @member {String} AfterExchangePhysicalForm
 */
Form.prototype['AfterExchangePhysicalForm'] = undefined;

/**
 * Bearer: The owner is not registered in the books of the issuer or of the registrar Registered: Securities are recorded in the name of the owner on the books of the issuer or the issuer's registrar and can only be transferred to another owner when endorsed by the registered owner. Other forms of the notes refer to local domestic formats which are currently not eligible for the ICSDs.[55]
 * @member {String} LegalForm
 */
Form.prototype['LegalForm'] = undefined;

/**
 * Denotes whether a Global Note is required for this transaction. Mandatory.[56]
 * @member {Boolean} GlobalNoteRequired
 */
Form.prototype['GlobalNoteRequired'] = undefined;

/**
 * Type of certificate -> Suggest: D7 indication of global note type of the instrument under custody[327]
 * @member {String} GlobalType
 */
Form.prototype['GlobalType'] = undefined;

/**
 * Denotes whether a Global Note is required for this transaction. Mandatory.[57]
 * @member {String} InitialPhysicalForm
 */
Form.prototype['InitialPhysicalForm'] = undefined;






export default Form;

