import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

export const StyledCustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(135, 135, 135, 0.6);
    transform: matrix(1, 0, 0, -1, 0, 0);
    color: #00a7c4;
    border-radius: 10px;
    width: 320px;
    height: auto !important;
  }
  .MuiTooltip-arrow {
    font-size: 16;
    width: 17;
    cursor: pointer;
    &::before {
      box-shadow: 0px 0px 4px rgba(135, 135, 135, 0.6);
      background-color: #fff;
      box-sizing: border-box;
    }
    &::after {
      box-shadow: 0px 0px 4px rgba(135, 135, 135, 0.6);
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .font-regular {
    font-size: 12px;
    font-family: "FFDINRegular";
  }
`;
