/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RefData model module.
 * @module model/RefData
 * @version 1.0.3
 */
class RefData {
    /**
     * Constructs a new <code>RefData</code>.
     * @alias module:model/RefData
     */
    constructor() { 
        
        RefData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RefData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RefData} obj Optional instance to populate.
     * @return {module:model/RefData} The populated <code>RefData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RefData();

            if (data.hasOwnProperty('SecuritizationType')) {
                obj['SecuritizationType'] = ApiClient.convertToType(data['SecuritizationType'], 'String');
            }
            if (data.hasOwnProperty('AutomaticExercise')) {
                obj['AutomaticExercise'] = ApiClient.convertToType(data['AutomaticExercise'], 'Number');
            }
            if (data.hasOwnProperty('MinimumExerciseAmount')) {
                obj['MinimumExerciseAmount'] = ApiClient.convertToType(data['MinimumExerciseAmount'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RefData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RefData</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['SecuritizationType'] && !(typeof data['SecuritizationType'] === 'string' || data['SecuritizationType'] instanceof String)) {
            throw new Error("Expected the field `SecuritizationType` to be a primitive type in the JSON string but got " + data['SecuritizationType']);
        }

        return true;
    }


}



/**
 * CBF internal category (set automatically by system)[374]
 * @member {String} SecuritizationType
 */
RefData.prototype['SecuritizationType'] = undefined;

/**
 * Exercise type (Currently only 5  Compulsory cash comp is supported)[376]
 * @member {Number} AutomaticExercise
 */
RefData.prototype['AutomaticExercise'] = undefined;

/**
 * Minimum exercise amount[377]
 * @member {Number} MinimumExerciseAmount
 */
RefData.prototype['MinimumExerciseAmount'] = undefined;






export default RefData;

