import ReactDOM from "react-dom";
import Authenticate from "./Authenticate";
import axios from "axios";

if (process.env.REACT_APP_MOCKS_ENABLED === "true") {
  const { setupWorker } = require("msw/browser");
  const mocks = require("./mocks/index");
  const worker = setupWorker(...mocks.default);
  worker.start({ onUnhandledRequest: "bypass" });
}

//below code is to handle any uncaught exceptions regarding TOKEN
window.onerror = (message) => {
  if (message.toLocaleLowerCase().includes("token")) {
    sessionStorage.clear();
    window.location.reload();
  }
}

axios.interceptors.request.use(
  function (config) {
    const selecteduser = sessionStorage.getItem("user");
    config.headers.common.user = selecteduser?.toLowerCase();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

ReactDOM.render(<Authenticate />, document.getElementById("root"));
