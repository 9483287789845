import React from "react";
import Typography from "@material-ui/core/Typography";
import { ErrorSuccessPopupBody } from "./index.css";
import { Grid } from "@material-ui/core";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@material-ui/icons/Cancel";

function ErrorPopup(props) {
  return (
    <ErrorSuccessPopupBody>
      <div id="errorPopup_errorMsgBox" className="errorMsgBox">
        <Grid container>
          <Grid item xs={1}>
            <WarningAmberIcon
              id="errorPopup_successOrErrorIcon"
              className="successOrErrorIcon"
            />
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h5"
              id="errorPopup_errorLabel"
              className="errorLabel"
            >
              Error:
            </Typography>
          </Grid>
          <Grid item xs={1} align="right">
            <CancelIcon
              className="closeIcon"
              id="errorPopup_closeIcon"
              onClick={() => {
                props?.handleClose();
              }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <label id="errorPopup_respMsg" className="respMsg">
              <Typography
                variant="h6"
                id="errorPopup_errorText"
                className="errorText"
                data-testid="errorText"
              >
                {props?.errorPopupText}
              </Typography>
            </label>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </ErrorSuccessPopupBody>
  );
}

export default ErrorPopup;
