import React from "react";
import { StyledDropdown } from "./index.css";
function Dropdown(props) {
  return props.multi === true ? (
    <StyledDropdown
      classNamePrefix="select"
      isMulti
      isClearable={props.isClearable}
      className={props.className}
      placeholder={props.placeholder}
      name={props.id}
      id={props.id}
      options={props.options}
      menuPortalTarget={document.body}
      onChange={props.onChange}
      value={props.value}
      styles={{
        menu: (base) => ({
          ...base,
          borderRadius: "0px",
          height: "fit-content",
        }),
      }}
    />
  ) : (
    <StyledDropdown
      classNamePrefix="select"
      isClearable={props.isClearable}
      className={props.className}
      placeholder={props.placeholder}
      name={props.id}
      id={props.id}
      options={props.options}
      menuPortalTarget={document.body}
      onChange={props.onChange}
      value={props.value}
      styles={{
        menu: (base) => ({
          ...base,
          borderRadius: "0px",
          height: "fit-content",
        }),
      }}
      isDisabled={props.disabled}
    />
  );
}

export default Dropdown;
