import styled from "styled-components";
import { Container } from "@material-ui/core";

export const TabHead = styled(Container)`
  @media (min-width: 1280px) {
    max-width: 100%;
  }
  .tabsClass {
    border-bottom: 3px solid #00a5c0;
  }
  Tabs {
    display: inline-block;
    width: 1400px;
    margin: 48px auto;
    border-bottom: 1px solid #dae0e7;
    background: #fff;
    list-style-type: none;
    padding: 12px 12px 0;
  }

  button {
    text-decoration: none;
    color: #00a5c0;
  }

  .MuiTab-root {
    float: left;
    min-height: 35px;
    height: 25px;
    width: 150px;
    margin: 0 0px -1px 0;
    color: #00a5c0;
    border: 2px solid #00a5c0;
    border-bottom: none;
    background: #fff;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }

  .MuiTab-root,
  .MuiTab-root button {
    transition: all 0.25s;
  }

  .MuiTab-root button {
    display: inline-block;
    font-weight: 600;
  }

  .MuiTab-root button:first-child {
    padding: 8px 2px 12px 16px;
    white-space: nowrap;
  }

  .MuiTab-root.tabClosed,
  .MuiTab-root.tabClosed button:first-child {
    margin-right: 0;
    border-width: 0;
  }

  .MuiTab-root.tabClosed {
    width: 0 !important; /* use important because we need to set the width of tabs in javascript in order to be able to animate them to 0 */
  }

  .MuiTab-root.tabClosed button {
    opacity: 0;
  }
  .MuiTab-root:hover {
    background: #00a5c0;
    color: #fff;
  }
  .MuiTab-root:hover button {
    background: #00a5c0;
    color: #fff;
  }
  .MuiTab-root:hover .closeTab {
    background: #00a5c0;
    color: #fff;
  }
  .Mui-selected {
    height: 35px;
    width: 151px;
    border-bottom: none;
    background: #00a5c0;
    color: #fff;
  }
  .Mui-selected button {
    background: #00a5c0;
    color: #fff;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-scroller {
    margin-top: 20px;
  }
`;
export const BoxContainer = styled(Container)`
  @media (min-width: 1280px) {
    max-width: 100%;
  }
  .tabPanel {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    box-shadow: 0px 1px 5px 1px rgb(135, 135, 135, 0.35);
    border-radius: 0px 0px 12px 12px;
  }
`;
