/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProductGovernance model module.
 * @module model/ProductGovernance
 * @version 1.0.3
 */
class ProductGovernance {
    /**
     * Constructs a new <code>ProductGovernance</code>.
     * @alias module:model/ProductGovernance
     */
    constructor() { 
        
        ProductGovernance.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductGovernance</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductGovernance} obj Optional instance to populate.
     * @return {module:model/ProductGovernance} The populated <code>ProductGovernance</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductGovernance();

            if (data.hasOwnProperty('TargetMarketVerbose')) {
                obj['TargetMarketVerbose'] = ApiClient.convertToType(data['TargetMarketVerbose'], 'String');
            }
            if (data.hasOwnProperty('SellingRestrictions')) {
                obj['SellingRestrictions'] = ApiClient.convertToType(data['SellingRestrictions'], 'String');
            }
            if (data.hasOwnProperty('MifidManufacturers')) {
                obj['MifidManufacturers'] = ApiClient.convertToType(data['MifidManufacturers'], 'String');
            }
            if (data.hasOwnProperty('MifirManufacturers')) {
                obj['MifirManufacturers'] = ApiClient.convertToType(data['MifirManufacturers'], 'String');
            }
            if (data.hasOwnProperty('TargetMarket')) {
                obj['TargetMarket'] = ApiClient.convertToType(data['TargetMarket'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProductGovernance</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProductGovernance</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['TargetMarketVerbose'] && !(typeof data['TargetMarketVerbose'] === 'string' || data['TargetMarketVerbose'] instanceof String)) {
            throw new Error("Expected the field `TargetMarketVerbose` to be a primitive type in the JSON string but got " + data['TargetMarketVerbose']);
        }
        // ensure the json data is a string
        if (data['SellingRestrictions'] && !(typeof data['SellingRestrictions'] === 'string' || data['SellingRestrictions'] instanceof String)) {
            throw new Error("Expected the field `SellingRestrictions` to be a primitive type in the JSON string but got " + data['SellingRestrictions']);
        }
        // ensure the json data is a string
        if (data['MifidManufacturers'] && !(typeof data['MifidManufacturers'] === 'string' || data['MifidManufacturers'] instanceof String)) {
            throw new Error("Expected the field `MifidManufacturers` to be a primitive type in the JSON string but got " + data['MifidManufacturers']);
        }
        // ensure the json data is a string
        if (data['MifirManufacturers'] && !(typeof data['MifirManufacturers'] === 'string' || data['MifirManufacturers'] instanceof String)) {
            throw new Error("Expected the field `MifirManufacturers` to be a primitive type in the JSON string but got " + data['MifirManufacturers']);
        }
        // ensure the json data is a string
        if (data['TargetMarket'] && !(typeof data['TargetMarket'] === 'string' || data['TargetMarket'] instanceof String)) {
            throw new Error("Expected the field `TargetMarket` to be a primitive type in the JSON string but got " + data['TargetMarket']);
        }

        return true;
    }


}



/**
 * A more detailed description of the target market for the notes.[234]
 * @member {String} TargetMarketVerbose
 */
ProductGovernance.prototype['TargetMarketVerbose'] = undefined;

/**
 * Requirements on the Issuer/Manager/Noteholders on the selling of the securities in the market.[239]
 * @member {String} SellingRestrictions
 */
ProductGovernance.prototype['SellingRestrictions'] = undefined;

/**
 * A list of MiFID Manufacturers on the transaction.[284]
 * @member {String} MifidManufacturers
 */
ProductGovernance.prototype['MifidManufacturers'] = undefined;

/**
 * A list of MiFIR Manufacturers on the transaction.[285]
 * @member {String} MifirManufacturers
 */
ProductGovernance.prototype['MifirManufacturers'] = undefined;

/**
 * Describes whether the target market for the notes includes retail investors or is professional investors and eligible counterparties only.[286]
 * @member {String} TargetMarket
 */
ProductGovernance.prototype['TargetMarket'] = undefined;






export default ProductGovernance;

