/*
 * userReducer is responsible for changing the state of our application,
 * via using reducers only we can update our application
 */

import {
  LOGIN_SUCCESS,
  SIGN_OUT_SUCCESS,
} from "../actionTypes/UserActionTypes";

export const UserReducer = (state, action) => {
  if (action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      isAuthenticated: true, // setting isAuthenticated to true if the action of login is successful
      ...action.userData,
    };
  }

  if (action.type === SIGN_OUT_SUCCESS) {
    return { ...state, isAuthenticated: false }; // setting isAuthenticated to false if the action of login is not successful
  } else {
    return { ...state };
  }
};
