/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Programme model module.
 * @module model/Programme
 * @version 1.0.3
 */
class Programme {
    /**
     * Constructs a new <code>Programme</code>.
     * @alias module:model/Programme
     */
    constructor() { 
        
        Programme.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Programme</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Programme} obj Optional instance to populate.
     * @return {module:model/Programme} The populated <code>Programme</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Programme();

            if (data.hasOwnProperty('MinimumMultipleQuantity')) {
                obj['MinimumMultipleQuantity'] = ApiClient.convertToType(data['MinimumMultipleQuantity'], 'Number');
            }
            if (data.hasOwnProperty('RecordDateRule')) {
                obj['RecordDateRule'] = ApiClient.convertToType(data['RecordDateRule'], 'Number');
            }
            if (data.hasOwnProperty('ProspectusDate')) {
                obj['ProspectusDate'] = ApiClient.convertToType(data['ProspectusDate'], 'Date');
            }
            if (data.hasOwnProperty('Currency')) {
                obj['Currency'] = ApiClient.convertToType(data['Currency'], 'String');
            }
            if (data.hasOwnProperty('ProgrammeNumber')) {
                obj['ProgrammeNumber'] = ApiClient.convertToType(data['ProgrammeNumber'], 'String');
            }
            if (data.hasOwnProperty('ProgrammeName')) {
                obj['ProgrammeName'] = ApiClient.convertToType(data['ProgrammeName'], 'String');
            }
            if (data.hasOwnProperty('ProgrammeType')) {
                obj['ProgrammeType'] = ApiClient.convertToType(data['ProgrammeType'], 'String');
            }
            if (data.hasOwnProperty('BaseProspectusName')) {
                obj['BaseProspectusName'] = ApiClient.convertToType(data['BaseProspectusName'], 'String');
            }
            if (data.hasOwnProperty('BaseProspectusDate')) {
                obj['BaseProspectusDate'] = ApiClient.convertToType(data['BaseProspectusDate'], 'Date');
            }
            if (data.hasOwnProperty('ProgrammeLimit')) {
                obj['ProgrammeLimit'] = ApiClient.convertToType(data['ProgrammeLimit'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Programme</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Programme</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Currency'] && !(typeof data['Currency'] === 'string' || data['Currency'] instanceof String)) {
            throw new Error("Expected the field `Currency` to be a primitive type in the JSON string but got " + data['Currency']);
        }
        // ensure the json data is a string
        if (data['ProgrammeNumber'] && !(typeof data['ProgrammeNumber'] === 'string' || data['ProgrammeNumber'] instanceof String)) {
            throw new Error("Expected the field `ProgrammeNumber` to be a primitive type in the JSON string but got " + data['ProgrammeNumber']);
        }
        // ensure the json data is a string
        if (data['ProgrammeName'] && !(typeof data['ProgrammeName'] === 'string' || data['ProgrammeName'] instanceof String)) {
            throw new Error("Expected the field `ProgrammeName` to be a primitive type in the JSON string but got " + data['ProgrammeName']);
        }
        // ensure the json data is a string
        if (data['ProgrammeType'] && !(typeof data['ProgrammeType'] === 'string' || data['ProgrammeType'] instanceof String)) {
            throw new Error("Expected the field `ProgrammeType` to be a primitive type in the JSON string but got " + data['ProgrammeType']);
        }
        // ensure the json data is a string
        if (data['BaseProspectusName'] && !(typeof data['BaseProspectusName'] === 'string' || data['BaseProspectusName'] instanceof String)) {
            throw new Error("Expected the field `BaseProspectusName` to be a primitive type in the JSON string but got " + data['BaseProspectusName']);
        }

        return true;
    }


}



/**
 * Minimum denomination on drawdowns (and if multiple currencies, minimum denomination for each currency if applicable). This is not monitored at programme level by the ICSDs but the ICSDs need to ensure the documentation is in line with the ICMSA guide on Denominations and the exchange from Global to Definitive Notes.[262]
 * @member {Number} MinimumMultipleQuantity
 */
Programme.prototype['MinimumMultipleQuantity'] = undefined;

/**
 * Date/time at which positions are struck to note which parties will receive the relevant amount of entitlement, due to be distributed on payment date. Number of business days prior to the Entitlement Date. See ICMSA recommendation in Annex 11 of the MPB.[485]
 * @member {Number} RecordDateRule
 */
Programme.prototype['RecordDateRule'] = undefined;

/**
 * The date on which the programme base prospectus is finalized. ISO 8601 date format YYYY,MM,DD. Optional, to be provided where possible to aid identification of the programme.[145]
 * @member {Date} ProspectusDate
 */
Programme.prototype['ProspectusDate'] = undefined;

/**
 * The currency used to define the limit on the programme. Optional, to be provided where possible to aid identification of the programme.[146]
 * @member {String} Currency
 */
Programme.prototype['Currency'] = undefined;

/**
 * 9 digit numeric programme number (with initial leading zeros) related to the security. Each Issuer on the Programme Documentation has a Programme Number allocated.[147]
 * @member {String} ProgrammeNumber
 */
Programme.prototype['ProgrammeNumber'] = undefined;

/**
 * Name of the programme under which the securities are issued as appearing on cover page.[149]
 * @member {String} ProgrammeName
 */
Programme.prototype['ProgrammeName'] = undefined;

/**
 * Type of the programme under which the securities are issued as appearing on cover page.[150]
 * @member {String} ProgrammeType
 */
Programme.prototype['ProgrammeType'] = undefined;

/**
 * Name of the (base) prospectus[307]
 * @member {String} BaseProspectusName
 */
Programme.prototype['BaseProspectusName'] = undefined;

/**
 * Date on which the base prospectus was deposited[308]
 * @member {Date} BaseProspectusDate
 */
Programme.prototype['BaseProspectusDate'] = undefined;

/**
 * Maximum amount to be issued off the program.[477]
 * @member {Number} ProgrammeLimit
 */
Programme.prototype['ProgrammeLimit'] = undefined;






export default Programme;

