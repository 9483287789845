/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Exchange model module.
 * @module model/Exchange
 * @version 1.0.3
 */
class Exchange {
    /**
     * Constructs a new <code>Exchange</code>.
     * @alias module:model/Exchange
     */
    constructor() { 
        
        Exchange.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Exchange</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Exchange} obj Optional instance to populate.
     * @return {module:model/Exchange} The populated <code>Exchange</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Exchange();

            if (data.hasOwnProperty('MIC')) {
                obj['MIC'] = ApiClient.convertToType(data['MIC'], 'String');
            }
            if (data.hasOwnProperty('MarketSegmentName')) {
                obj['MarketSegmentName'] = ApiClient.convertToType(data['MarketSegmentName'], 'String');
            }
            if (data.hasOwnProperty('MarketSegmentMIC')) {
                obj['MarketSegmentMIC'] = ApiClient.convertToType(data['MarketSegmentMIC'], 'String');
            }
            if (data.hasOwnProperty('TradingActivationStart')) {
                obj['TradingActivationStart'] = ApiClient.convertToType(data['TradingActivationStart'], 'Date');
            }
            if (data.hasOwnProperty('TradingModel')) {
                obj['TradingModel'] = ApiClient.convertToType(data['TradingModel'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Exchange</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Exchange</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['MIC'] && !(typeof data['MIC'] === 'string' || data['MIC'] instanceof String)) {
            throw new Error("Expected the field `MIC` to be a primitive type in the JSON string but got " + data['MIC']);
        }
        // ensure the json data is a string
        if (data['MarketSegmentName'] && !(typeof data['MarketSegmentName'] === 'string' || data['MarketSegmentName'] instanceof String)) {
            throw new Error("Expected the field `MarketSegmentName` to be a primitive type in the JSON string but got " + data['MarketSegmentName']);
        }
        // ensure the json data is a string
        if (data['MarketSegmentMIC'] && !(typeof data['MarketSegmentMIC'] === 'string' || data['MarketSegmentMIC'] instanceof String)) {
            throw new Error("Expected the field `MarketSegmentMIC` to be a primitive type in the JSON string but got " + data['MarketSegmentMIC']);
        }
        // ensure the json data is a string
        if (data['TradingModel'] && !(typeof data['TradingModel'] === 'string' || data['TradingModel'] instanceof String)) {
            throw new Error("Expected the field `TradingModel` to be a primitive type in the JSON string but got " + data['TradingModel']);
        }

        return true;
    }


}



/**
 * MIC code(s) (ISO standard 10383) of the exchange(s) on which the securities will be listed.[287]
 * @member {String} MIC
 */
Exchange.prototype['MIC'] = undefined;

/**
 * The market of the exchange where the bond is to be listed.[288]
 * @member {String} MarketSegmentName
 */
Exchange.prototype['MarketSegmentName'] = undefined;

/**
 * The market of the exchange where the bond is to be listed. MIC Code[526]
 * @member {String} MarketSegmentMIC
 */
Exchange.prototype['MarketSegmentMIC'] = undefined;

/**
 * Date and time for the activation of the intraday issuance[122]
 * @member {Date} TradingActivationStart
 */
Exchange.prototype['TradingActivationStart'] = undefined;

/**
 * Indication of the trading model applied for the instrument at the listing venue[290]
 * @member {String} TradingModel
 */
Exchange.prototype['TradingModel'] = undefined;






export default Exchange;

