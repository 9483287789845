/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Messages model module.
 * @module model/Messages
 * @version 1.0.3
 */
class Messages {
    /**
     * Constructs a new <code>Messages</code>.
     * @alias module:model/Messages
     */
    constructor() { 
        
        Messages.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Messages</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Messages} obj Optional instance to populate.
     * @return {module:model/Messages} The populated <code>Messages</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Messages();

            if (data.hasOwnProperty('Code')) {
                obj['Code'] = ApiClient.convertToType(data['Code'], 'String');
            }
            if (data.hasOwnProperty('Message')) {
                obj['Message'] = ApiClient.convertToType(data['Message'], 'String');
            }
            if (data.hasOwnProperty('AffectedAttributes')) {
                obj['AffectedAttributes'] = ApiClient.convertToType(data['AffectedAttributes'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Messages</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Messages</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Code'] && !(typeof data['Code'] === 'string' || data['Code'] instanceof String)) {
            throw new Error("Expected the field `Code` to be a primitive type in the JSON string but got " + data['Code']);
        }
        // ensure the json data is a string
        if (data['Message'] && !(typeof data['Message'] === 'string' || data['Message'] instanceof String)) {
            throw new Error("Expected the field `Message` to be a primitive type in the JSON string but got " + data['Message']);
        }
        // ensure the json data is a string
        if (data['AffectedAttributes'] && !(typeof data['AffectedAttributes'] === 'string' || data['AffectedAttributes'] instanceof String)) {
            throw new Error("Expected the field `AffectedAttributes` to be a primitive type in the JSON string but got " + data['AffectedAttributes']);
        }

        return true;
    }


}



/**
 * 
 * @member {String} Code
 */
Messages.prototype['Code'] = undefined;

/**
 * 
 * @member {String} Message
 */
Messages.prototype['Message'] = undefined;

/**
 * 
 * @member {String} AffectedAttributes
 */
Messages.prototype['AffectedAttributes'] = undefined;






export default Messages;

