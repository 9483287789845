// prettier-ignore
export const externalProcessStatus = [
  { label: "Eligibility check in progress", value: "Eligibility_Check_In_Progress" },
  { label: "Eligibility check failed", value: "Eligibility_Check_Failed" },
  { label: "Admission in progress", value: "Admission_In_Progress" },
  { label: "Admission check failed", value: "Admission_Rejected" },
  { label: "Instrument registered", value: "Registered" },
  { label: "Registration failed", value: "Registration_Fail" },
  { label: "Admission complete", value: "Live" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Payment file received", value: "Live_DRP_Received" },
  { label: "Payment file in progress", value: "Live_DRP_In_Progress" },
  { label: "Payment file accepted", value: "Live_DRP_Accepted" },
  { label: "Payment file failed", value: "Live_DRP_Failed" },
  { label: "Redemption scheduled", value: "Live_DRP_Scheduled" },
  { label: "Redemption request failed", value: "Live_DRP_Request_Failed" },
  { label: "Redemption completed", value: "Expired" },
  { label: "Redemption cancelled", value: "Live_Redemption_Cancelled" },
  { label: "Increase initiated", value: "Increase_Initiated" },
  { label: "Increase eligibility check in progress", value: "Increase_Eligibility_Check_In_Progress" },
  { label: "Increase eligibility check failed", value: "Increase_Eligibility_Check_Failed" },
  { label: "Increase admission in progress", value: "Increase_Admission_In_Progress" },
  { label: "Increase admission rejected", value: "Increase_Admission_Rejected" },
  { label: "Increase stored", value: "Increase_Stored" },
  { label: "Increase in progress", value: "Increase_In_Progress" },
  { label: "Increase rejected", value: "Increase_Rejected" },
  { label: "Increase confirmed", value: "Increase_Confirmed" },
  // Below are statuses regarding update
  { label: "Update file in progress", value: "Live_DRP_in_progress" },
  { label: "Update completed", value: "Live_Update_Completed" },
  { label: "Update failed", value: "Live_Update_Rejected" },
];

export const externalOverallStatus = [
  { label: "Initiated", value: "Initiated" },
  { label: "Live", value: "Live" },
  { label: "Expired", value: "Expired" },
  { label: "Rejected", value: "Rejected" },
];

// prettier-ignore
export const internalStatus = [
  { label: "Issuance initiated", value: "Issuance_Initiated" },
  { label: "Issuer whitelist check initiated", value: "Issuer_Whitelist_Check_Initiated" },
  { label: "Issuer whitelist check passed", value: "Issuer_Whitelist_Check_Accepted" },
  { label: "Issuer whitelist check failed", value: "Issuer_Whitelist_Check_Failed" },
  { label: "Issuer check initiated", value: "Issuer_Greylist_Check_Initiated" },
  { label: "Issuer check passed", value: "Issuer_Greylist_Check_Accepted" },
  { label: "Issuer review pending", value: "Issuer_Greylist_Check_Failed_Human_Review_Pending" },
  { label: "Issuer check passed", value: "Issuer_Greylist_Check_Human_Review_Accepted" },
  { label: "Issuer check failed", value: "Issuer_Greylist_Check_Rejected" },
  { label: "Underlying blacklist check initiated", value: "Underlying_Blacklist_Check_Initiated" },
  { label: "Underlying blacklist check passed", value: "Underlying_Blacklist_Check_Accepted" },
  { label: "Underlying blacklist check failed", value: "Underlying_Blacklist_Check_Rejected" },
  { label: "Underlying whitelist check initiated", value: "Underlying_Whitelist_Check_Initiated" },
  { label: "Underlying whitelist check passed", value: "Underlying_Whitelist_Check_Accepted" },
  { label: "Underlying whitelist review pending", value: "Underlying_Whitelist_Check_Failed_Human_Review_Pending" },
  { label: "Underlying whitelist check passed", value: "Underlying_Whitelist_Check_Human_Review_Accepted" },
  { label: "Underlying whitelist check failed", value: "Underlying_Whitelist_Check_Rejected" },
  { label: "Recon initiated", value: "DIRecon_Initiated" },
  { label: "Recon passed", value: "DIRecon_Accepted" },
  { label: "Recon review pending", value: "DIRecon_Failed_Human_Review_Waiting" },
  { label: "Recon accepted", value: "DIRecon_Human_Review_Accepted" },
  { label: "Recon rejected", value: "DIRecon_Failed" },
  { label: "RefData publication requested", value: "RefData_Publication_Requested" },
  { label: "RefData published", value: "RefData_Published_WSS_RDF" }, // ATTENTION REPETITION OF LABEL
  { label: "RefData review pending", value: "RefData_Failed_Human_Review_Pending" },
  { label: "RefData upload failed", value: "RefData_Rejected" }, // ATTENTION REPETITION OF LABEL
  { label: "RefData published", value: "Refdata_Published_To_WSS_RDF_Post_HR_Approval" }, // ATTENTION REPETITION OF LABEL
  { label: "RefData upload failed", value: "Refdata_Upload_Failed" }, // ATTENTION REPETITION OF LABEL
  { label: "Register initiated", value: "DIRegister_Initiated" },
  { label: "Instrument registered in Cefi", value: "DIRegister_Prebooked" },
  { label: "Registration failed", value: "DIRegister_Validation_Fail" },
  { label: "Admission complete", value: "DI_Live" },
  { label: "Cancelled", value: "DI_Cancelled" },
  { label: "Increase initiated", value: "Increase_Initiated" },
  { label: "Increase issuer whitelist check initiated", value: "Increase_Issuer_Whitelist_Check_Initiated" },
  { label: "Increase issuer whitelist check passed", value: "Increase_Issuer_Whitelist_Check_Accepted" },
  { label: "Increase issuer whitelist check failed", value: "Increase_Issuer_Whitelist_Check_Failed" },
  { label: "Increase issuer check initiated", value: "Increase_Issuer_Greylist_Check_Initiated" },
  { label: "Increase issuer check passed", value: "Increase_Issuer_Greylist_Check_Accepted" },
  { label: "Increase issuer review pending", value: "Increase_Issuer_Greylist_Review_Pending" },
  { label: "Increase issuer check failed", value: "Increase_Issuer_Greylist_Check_Failed" },
  { label: "Increase underlying blacklist check initiated", value: "Increase_Underlying_Blacklist_Check_Initiated" },
  { label: "Increase underlying blacklist check passed", value: "Increase_Underlying_Blacklist_Check_Accepted" },
  { label: "Increase underlying blacklist check failed", value: "Increase_Underlying_Blacklist_Check_Failed" },
  { label: "Increase underlying whitelist check initiated", value: "Increase_Underlying_Whitelist_Check_Initiated" },
  { label: "Increase underlying whitelist check passed", value: "Increase_Underlying_Whitelist_Check_Accepted" },
  { label: "Increase underlying whitelist review pending", value: "Increase_Underlying_Whitelist_Review_Pending" },
  { label: "Increase underlying whitelist check passed", value: "Increase_Underlying_Whitelist_Check_Accepted" },
  { label: "Increase underlying whitelist check failed", value: "Increase_Underlying_Whitelist_Check_Rejected" },
  { label: "Increase recon initiated", value: "Increase_Recon_Initiated" },
  { label: "Increase recon passed", value: "Increase_Recon_Passed" },
  { label: "Increase recon review pending", value: "Increase_Recon_Review_Pending" },
  { label: "Increase recon accepted", value: "Increase_Recon_Accepted" },
  { label: "Increase recon rejected", value: "Increase_Recon_Rejected" },
  { label: "Increase storage initiated", value: "Increase_Storage_Initiated" },
  { label: "Increase stored", value: "Increase_Stored" },
  { label: "Increase storage rejected", value: "Increase_Storage_Rejected" },
  { label: "Increase CeFi confirmed", value: "Increase_CeFi_Confirmed" },
  { label: "Increase CeFi rejected", value: "Increase_CeFi_Rejected" },
  { label: "Increase RefData publication requested", value: "Increase_RefData_Publication_Requested" },
  { label: "Increase RefData published", value: "Increase_RefData_Published" },
  { label: "Increase RefData published with warning", value: "Increase_RefData_Published_With_Warning" },
  { label: "Increase confirmed", value: "Increase_Confirmed" },
  { label: "DRP received", value: "DRP_Received" },
  { label: "DRP published", value: "DRP_Update_Registration" },
  { label: "DRP complete", value: "DRP_Update_Successful" }, // ATTENTION REPETITION OF LABEL
  { label: "DRP manual review", value: "DRP_Update_Failed_Human_Review_Pending" },
  { label: "DRP complete", value: "DRP_Update_Human_Review_Accepted" }, // ATTENTION REPETITION OF LABEL
  { label: "DRP failed", value: "DRP_Update_Human_Review_Failed" }, // ATTENTION REPETITION OF LABEL
  { label: "DRP failed", value: "DRP_Update_Failed" }, // ATTENTION REPETITION OF LABEL
  { label: "DRP stored", value: "Store_Redemption" }, // ATTENTION REPETITION OF LABEL
  { label: "Redemption scheduled", value: "Redemption_Stored" },
  { label: "Redemption request failed", value: "Redemption_Storage_Failed" },
  { label: "Redemption completed", value: "Global_Note_Matured" },
  { label: "Redemption cancelled", value: "Redemption_Cancelled" },
  // Below are statuses regarding update
  { label: "Update published", value: "DRP_update_registration" },
  { label: "Update completed", value: "DRP_update_successful" }, // ATTENTION REPETITION OF LABEL
  { label: "Update manual review", value: "DRP_update_failed_human_review_pending" },
  { label: "Update review successful", value: "DRP_update_human_review_accepted" },
  { label: "Update completed", value: "Live_Manual_Update_Succesful" }, // ATTENTION REPETITION OF LABEL
  { label: "Update failed", value: "Live_Manual_Update_Failed" },
];

export const approvementStatus = [
  { label: "To Review", value: "TO_REVIEW", statusColor: "#FF0000" },
  { label: "Approved", value: "APPROVED", statusColor: "#000000d9" },
  { label: "Rejected", value: "REJECTED", statusColor: "#000000d9" },
];

export const publisherEnums = [
  { label: "Command Submitter", value: "Csub" },
  { label: "Compliance Service", value: "IssuerWLCheck" },
  { label: "Compliance Service", value: "IssuerGLCheck" },
  { label: "DI Recon", value: "DIRecon" },
  { label: "Reference Data Adapter", value: "RefData" },
  { label: "Central Register(CeFi)", value: "CeFi" },
  { label: "DRP Connector", value: "DRP" },
  { label: "Compliance Service", value: "UnderlyingBLCheck" },
  { label: "Compliance Service", value: "UnderlyingWLCheck" },
  { label: "DI Recon", value: "IncreaseRecon" },
];

export const transactionTypesEnums = [
  { label: "Issuance", value: "ISSUANCE" },
  { label: "Redemption", value: "REDEMPTION" },
  { label: "Increase", value: "INCREASE" },
];

export const transactionStatusOptions = [
  { value: "SUCCESS", label: "Success" },
  { value: "FAILURE", label: "Failure" },
];

export const getExternalProcessStatusLabel = (value) => {
  let label = externalProcessStatus.find((item) => item.value === value)?.label;
  return label ? label : value;
};

export const getExternalOverallStatusLabel = (value) => {
  let label = externalOverallStatus.find((item) => item.value === value)?.label;
  return label ? label : value;
};

export const getInternalStatusLabel = (value) => {
  let label = internalStatus.find((item) => item.value === value)?.label;
  return label ? label : value;
};

export const getInternalStatusValue = (label) => {
  let value = internalStatus.find((item) => item.label === label)?.value;
  return value ? value : label;
};

export const getApprovementStatusLabel = (value) => {
  let label = approvementStatus.find((item) => item.value === value)?.label;
  return label
    ? label
    : value?.replace("_", " ").replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
};

export const getApprovementStatusClass = (value) => {
  let colorCode = approvementStatus.find(
    (item) => item.label === value
  )?.statusColor;
  return { color: colorCode ? colorCode : "#ff0000", lineHeight: "17px" };
};

export const getPublisherLabel = (value) => {
  let label = publisherEnums.find((item) => item.value === value)?.label;
  return label ? label : value;
};

export const displayDescription = (row) => {
  if (row?.type === "redemption" && row.description !== null) {
    return row.description;
  } else {
    if (row.numOfRecords) {
      return row.numOfRecords === 0
        ? ""
        : row.numOfRecords === 1
        ? "1 record"
        : `${row.numOfRecords} records`;
    } else {
      return "";
    }
  }
};

export const getLabel = (array, value) => {
  let label = array.find((item) => item.value === value)?.label;
  return label ? label : value;
};
