//RolesOfAUser --> will fetch from API call
export const Clearstream = [
  "ClearstreamOperationsRegular",
  "ClearstreamOperationsAdvanced",
  "ClearstreamBasic",
  "ClearstreamAuditor",
  "ClearstreamIt",
];

export const Issuer = [
  "PayingAgentOperator",
  "IssuingAgentOperator",
  "AccountOperator",
];

//RightsOfARole
export const ClearstreamOperationsAdvanced = [
  "View_Template",
  "View_Pending",
  "View_Template_Review",
  "View_Blotter_Accept_Reject_Buttons",
  "View_Confirm",
  "View_DRP_Sync",
  "View_Pdf",
  "View_Error_Log",
  "View_Combined_Log",
  "View_Audit_Export",
  "View_Compliance_List",
  "View_Compliance_Report",
  "View_Issuer_Static_Data",
  "View_Unknown_ISIN_Log",
  "View_Sender_Notified",
  "View_Acknowledged",
  "View_Template_History",
  "View_Dashboard_Templates",
  "View_Compliance_Upload",
  "View_Compliance_List_Upload_Time",
  "View_Internal_Status",
  "View_Transaction_Store_Log",
  "Set_StatusSince_Default",
];

export const ClearstreamOperationsRegular = [
  "View_Template",
  "View_Pending",
  "View_Template_Review",
  "View_DRP_Sync",
  "View_Pdf",
  "View_Error_Log",
  "View_Combined_Log",
  "View_Audit_Export",
  "View_Compliance_List",
  "View_Compliance_Report",
  "View_Issuer_Static_Data",
  "View_Unknown_ISIN_Log",
  "View_Sender_Notified",
  "View_Acknowledged",
  "View_Template_History",
  "View_Dashboard_Templates",
  "View_Compliance_Upload",
  "View_Compliance_List_Upload_Time",
  "View_Internal_Status",
  "View_Transaction_Store_Log",
  "Set_StatusSince_Default",
];

export const ClearstreamBasic = [
  "View_Template",
  "View_Pending",
  "View_Pdf",
  "View_Combined_Log",
  "View_Error_Log",
  "View_Issuer_Static_Data",
  "View_Unknown_ISIN_Log",
  "View_Template_History",
  "View_Dashboard_Templates",
  "View_Internal_Status",
  "View_Transaction_Store_Log",
  "Set_StatusSince_Default",
];

export const ClearstreamAuditor = [
  "View_Template",
  "View_Issuer_Static_Data",
  "View_Unknown_ISIN_Log",
  "View_Combined_Log",
  "View_Audit_Export",
  "View_Compliance_List",
  "View_Issuer_Static_Data",
  "View_Unknown_ISIN_Log",
  "View_Pdf",
  "View_Error_Log",
  "View_Dashboard_Templates",
  "View_Internal_Status",
  "View_Transaction_Store_Log",
  "Set_StatusSince_Default",
];

export const ClearstreamIt = [
  "View_Template",
  "View_Pending",
  "View_Issuer_Static_Data",
  "View_Combined_Log",
  "View_Unknown_ISIN_Log",
  "View_Dashboard_Templates",
  "View_Error_Log",
  "View_Internal_Status",
  "View_Transaction_Store_Log",
  "Set_StatusSince_Default",
];

export const ClearstreamCompliance = [
  "View_Pending",
  "View_Pdf",
  "View_Error_Log",
  "View_Combined_Log",
  "View_Internal_Status",
  "View_Compliance_List",
  "View_Compliance_List_Upload_Time",
];
export const PayingAgentOperator = [
  "View_DRP",
  "View_Combined_Log",
  "View_Audit_Export",
  "View_Unknown_ISIN_Log",
  "View_Pdf",
  "View_Error_Log",
  "View_Acknowledged_Issuer",
];

export const IssuingAgentOperator = [
  "View_Update_Issuance",
  "View_Template",
  "View_Combined_Log",
  "View_Audit_Export",
  "View_Unknown_ISIN_Log",
  "View_Pdf",
  "View_Error_Log",
  "View_Dashboard_Templates",
  "View_Acknowledged_Issuer",
  "View_New_Template",
  "View_New_Issuance",
  "View_Template_Delete",
  "View_Template_History",
];

export const AccountOperator = [
  "View_Template",
  "View_Combined_Log",
  "View_Audit_Export",
  "View_Unknown_ISIN_Log",
  "View_Pdf",
  "View_Error_Log",
  "View_Dashboard_Templates",
];

export const NonExistingRole = [];

export const mappingRoles = {
  "Clearstream Operations Advanced": ClearstreamOperationsAdvanced,
  "Clearstream Auditor": ClearstreamAuditor,
  "Clearstream Basic": ClearstreamBasic,
  "Clearstream Operations Regular": ClearstreamOperationsRegular,
  "Clearstream IT": ClearstreamIt,
  "Paying Agent Operator": PayingAgentOperator,
  "Issuing Agent Operator": IssuingAgentOperator,
  "Account Operator": AccountOperator,
  "Clearstream Compliance": ClearstreamCompliance,
  "Non Existing Role": NonExistingRole,
};
