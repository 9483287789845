/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProcessingDetail from './ProcessingDetail';
import Status from './Status';

/**
 * The Step model module.
 * @module model/Step
 * @version 1.0.3
 */
class Step {
    /**
     * Constructs a new <code>Step</code>.
     * @alias module:model/Step
     */
    constructor() { 
        
        Step.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Step</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Step} obj Optional instance to populate.
     * @return {module:model/Step} The populated <code>Step</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Step();

            if (data.hasOwnProperty('Status')) {
                obj['Status'] = Status.constructFromObject(data['Status']);
            }
            if (data.hasOwnProperty('Version')) {
                obj['Version'] = ApiClient.convertToType(data['Version'], 'String');
            }
            if (data.hasOwnProperty('Timestamp')) {
                obj['Timestamp'] = ApiClient.convertToType(data['Timestamp'], 'Date');
            }
            if (data.hasOwnProperty('ProcessingDetail')) {
                obj['ProcessingDetail'] = ProcessingDetail.constructFromObject(data['ProcessingDetail']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Step</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Step</code>.
     */
    static validateJSON(data) {
        // validate the optional field `Status`
        if (data['Status']) { // data not null
          Status.validateJSON(data['Status']);
        }
        // ensure the json data is a string
        if (data['Version'] && !(typeof data['Version'] === 'string' || data['Version'] instanceof String)) {
            throw new Error("Expected the field `Version` to be a primitive type in the JSON string but got " + data['Version']);
        }
        // validate the optional field `ProcessingDetail`
        if (data['ProcessingDetail']) { // data not null
          ProcessingDetail.validateJSON(data['ProcessingDetail']);
        }

        return true;
    }


}



/**
 * @member {module:model/Status} Status
 */
Step.prototype['Status'] = undefined;

/**
 * 
 * @member {String} Version
 */
Step.prototype['Version'] = undefined;

/**
 * 
 * @member {Date} Timestamp
 */
Step.prototype['Timestamp'] = undefined;

/**
 * @member {module:model/ProcessingDetail} ProcessingDetail
 */
Step.prototype['ProcessingDetail'] = undefined;






export default Step;

