import axios from "axios";
import decode from "jwt-decode";
import { roleRights } from "./App";
import {
  PendingApiClient,
  ComplianceApiClient,
  AccountsInternalApiClient,
} from "./api";

export const debounce = (fn, ms) => {
  let timer;
  return () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn();
    }, ms);
  };
};

/*
 * Fetching individual and overall count for Pending subtabs and navlink respectively
 */
export const getCountsBySection = async (user) => {
  if (sessionStorage.getItem("user") === "Clearstream") {
    let jwtToken = sessionStorage.getItem("jwtToken");
    const token = decode(jwtToken);
    const _userRoleRight = roleRights(token.digitiser_roles);
    let individualPendingCount = [];
    if (_userRoleRight.includes("View_Pending")) {
      await PendingApiClient.getPendingIssuancesCount()
        .then(async (response) => {
          //setting counts for subtabs in pending
          if (token.digitiser_roles.includes("Clearstream Compliance")) {
            const complianceCount = !isNaN(response.data["compliance"])
              ? response.data["compliance"]
              : 0;
            individualPendingCount = [0, 0, complianceCount, 0];
            return individualPendingCount;
          }

          individualPendingCount.push(
            !isNaN(response.data["issuanceBlotter"])
              ? response.data["issuanceBlotter"]
              : 0
          );
          individualPendingCount.push(
            !isNaN(response.data["lifecycleManagement"])
              ? response.data["lifecycleManagement"]
              : 0
          );
          individualPendingCount.push(
            !isNaN(response.data["compliance"])
              ? response.data["compliance"]
              : 0
          );

          //call individual count api's for review and update here
          let reviewsCount = 0;

          const complianceCountPromise =
            ComplianceApiClient.getCompliancePendingCount();
          const accountsCountPromise =
            AccountsInternalApiClient.getAccountsPendingCount();
          const redemptionReviewCount = !isNaN(
            response.data["redemptionReview"]
          )
            ? response.data["redemptionReview"]
            : 0;
          const redemptionCountPromise = Promise.resolve({
            data: { count: redemptionReviewCount },
          });

          await axios
            .all([
              complianceCountPromise,
              accountsCountPromise,
              redemptionCountPromise,
            ])
            .then(
              axios.spread((...res) => {
                let complianceRes = res[0]?.response?.data
                  ? res[0]?.response?.data
                  : null;
                let complianceCount = 0;
                if (complianceRes) {
                  complianceCount +=
                    complianceRes.whitelist && !isNaN(complianceRes.whitelist)
                      ? complianceRes.whitelist
                      : 0;
                  complianceCount +=
                    complianceRes.blacklist && !isNaN(complianceRes.blacklist)
                      ? complianceRes.blacklist
                      : 0;
                  complianceCount +=
                    complianceRes.issuerBlacklist &&
                    !isNaN(complianceRes.issuerBlacklist)
                      ? complianceRes.issuerBlacklist
                      : 0;
                  complianceCount +=
                    complianceRes.issuerWhitelist &&
                    !isNaN(complianceRes.issuerWhitelist)
                      ? complianceRes.issuerWhitelist
                      : 0;
                }
                let accountCount =
                  res[1] &&
                  res[1].response?.data &&
                  !isNaN(res[1].response.data?.count)
                    ? res[1].response.data.count
                    : 0;
                let redemptionCount =
                  res[2] && res[2].data && !isNaN(res[2].data?.count)
                    ? res[2].data.count
                    : 0;
                reviewsCount = complianceCount + accountCount + redemptionCount;
                individualPendingCount.push(reviewsCount);
              })
            )
            .catch((error) => {});
        })
        .catch((error) => {});
      return individualPendingCount;
    }
  }
};

export const getPendingCount = (individualPendingSectionCount) => {
  return individualPendingSectionCount.reduce((sum, a) => sum + a, 0);
};
