export const styles = {
  errorFormat: {
    color: "#CC0099",
  },

  chooseUnderline: {
    display: "flex",
    alignItems: "self-end",
    borderBottom: "1px solid rgba(135, 135, 135, 0.6)",
  },

  title: {
    fontFamily: "FFDINBold",
    fontStyle: "normal",
    fontSize: "17px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "21px",
  },

  description: {
    fontFamily: "FFDINBold",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "17px",
  },

  driveFileIcon: {
    marginTop: "16px",
  },

  dialogActionContainer: {
    padding: "24px",
    boxSizing: "border-box",
  },

  chooseFile: {
    fontFamily: "FFDINBold",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#00A5C0",
  },

  cancel: {
    fontFamily: "FFDINBold",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    color: "#00A5C0",
    lineHeight: "17px",
  },

  submit: {
    borderRadius: "17px",
    fontFamily: "FFDINBold",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
  },
};
