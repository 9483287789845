/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Increase model module.
 * @module model/Increase
 * @version 1.0.3
 */
class Increase {
    /**
     * Constructs a new <code>Increase</code>.
     * @alias module:model/Increase
     */
    constructor() { 
        
        Increase.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Increase</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Increase} obj Optional instance to populate.
     * @return {module:model/Increase} The populated <code>Increase</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Increase();

            if (data.hasOwnProperty('NominalAmount')) {
                obj['NominalAmount'] = ApiClient.convertToType(data['NominalAmount'], 'Number');
            }
            if (data.hasOwnProperty('TrancheNumber')) {
                obj['TrancheNumber'] = ApiClient.convertToType(data['TrancheNumber'], 'String');
            }
            if (data.hasOwnProperty('IssuedNominalAmount')) {
                obj['IssuedNominalAmount'] = ApiClient.convertToType(data['IssuedNominalAmount'], 'Number');
            }
            if (data.hasOwnProperty('ValueDate')) {
                obj['ValueDate'] = ApiClient.convertToType(data['ValueDate'], 'Date');
            }
            if (data.hasOwnProperty('FungibilityStatus')) {
                obj['FungibilityStatus'] = ApiClient.convertToType(data['FungibilityStatus'], 'String');
            }
            if (data.hasOwnProperty('TefraCertified')) {
                obj['TefraCertified'] = ApiClient.convertToType(data['TefraCertified'], 'Boolean');
            }
            if (data.hasOwnProperty('ISIN')) {
                obj['ISIN'] = ApiClient.convertToType(data['ISIN'], 'String');
            }
            if (data.hasOwnProperty('DocumentURL')) {
                obj['DocumentURL'] = ApiClient.convertToType(data['DocumentURL'], 'String');
            }
            if (data.hasOwnProperty('DocumentHash')) {
                obj['DocumentHash'] = ApiClient.convertToType(data['DocumentHash'], 'String');
            }
            if (data.hasOwnProperty('DocumentHashAlgorithm')) {
                obj['DocumentHashAlgorithm'] = ApiClient.convertToType(data['DocumentHashAlgorithm'], 'String');
            }
            if (data.hasOwnProperty('ReplaceDocumentation')) {
                obj['ReplaceDocumentation'] = ApiClient.convertToType(data['ReplaceDocumentation'], 'Boolean');
            }
            if (data.hasOwnProperty('ClientInstructionReference')) {
                obj['ClientInstructionReference'] = ApiClient.convertToType(data['ClientInstructionReference'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Increase</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Increase</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['TrancheNumber'] && !(typeof data['TrancheNumber'] === 'string' || data['TrancheNumber'] instanceof String)) {
            throw new Error("Expected the field `TrancheNumber` to be a primitive type in the JSON string but got " + data['TrancheNumber']);
        }
        // ensure the json data is a string
        if (data['FungibilityStatus'] && !(typeof data['FungibilityStatus'] === 'string' || data['FungibilityStatus'] instanceof String)) {
            throw new Error("Expected the field `FungibilityStatus` to be a primitive type in the JSON string but got " + data['FungibilityStatus']);
        }
        // ensure the json data is a string
        if (data['ISIN'] && !(typeof data['ISIN'] === 'string' || data['ISIN'] instanceof String)) {
            throw new Error("Expected the field `ISIN` to be a primitive type in the JSON string but got " + data['ISIN']);
        }
        // ensure the json data is a string
        if (data['DocumentURL'] && !(typeof data['DocumentURL'] === 'string' || data['DocumentURL'] instanceof String)) {
            throw new Error("Expected the field `DocumentURL` to be a primitive type in the JSON string but got " + data['DocumentURL']);
        }
        // ensure the json data is a string
        if (data['DocumentHash'] && !(typeof data['DocumentHash'] === 'string' || data['DocumentHash'] instanceof String)) {
            throw new Error("Expected the field `DocumentHash` to be a primitive type in the JSON string but got " + data['DocumentHash']);
        }
        // ensure the json data is a string
        if (data['DocumentHashAlgorithm'] && !(typeof data['DocumentHashAlgorithm'] === 'string' || data['DocumentHashAlgorithm'] instanceof String)) {
            throw new Error("Expected the field `DocumentHashAlgorithm` to be a primitive type in the JSON string but got " + data['DocumentHashAlgorithm']);
        }
        // ensure the json data is a string
        if (data['ClientInstructionReference'] && !(typeof data['ClientInstructionReference'] === 'string' || data['ClientInstructionReference'] instanceof String)) {
            throw new Error("Expected the field `ClientInstructionReference` to be a primitive type in the JSON string but got " + data['ClientInstructionReference']);
        }

        return true;
    }


}



/**
 * The aggregate nominal amount of the issue of the current tranche. Mandatory if the tranche being issued is a tap of an existing issuance.[267]
 * @member {Number} NominalAmount
 */
Increase.prototype['NominalAmount'] = undefined;

/**
 * Typically a sequential number indicating the number of the tranche which is added to the initial issuance[531]
 * @member {String} TrancheNumber
 */
Increase.prototype['TrancheNumber'] = undefined;

/**
 * The aggregate nominal amount of the series after the issue of the current tranche. Mandatory if the tranche being issued is a tap of an existing series.[268]
 * @member {Number} IssuedNominalAmount
 */
Increase.prototype['IssuedNominalAmount'] = undefined;

/**
 * Effective date for the Increase/Decrease[528]
 * @member {Date} ValueDate
 */
Increase.prototype['ValueDate'] = undefined;

/**
 * Whether the notes are immediately fungible or funge after a given period of time. Mandatory where the notes are fungible[266]
 * @member {String} FungibilityStatus
 */
Increase.prototype['FungibilityStatus'] = undefined;

/**
 * Certifies compliance with Tefra Regulation[540]
 * @member {Boolean} TefraCertified
 */
Increase.prototype['TefraCertified'] = undefined;

/**
 * For fungible issuances, the daughter code of the tranche which is to funge with the mother code. 12 digit alpha,numeric code; 2 alpha + 10 numeric. Mandatory if the trade is a tap issuance and the notes are not immediately fungible.[137]
 * @member {String} ISIN
 */
Increase.prototype['ISIN'] = undefined;

/**
 * Storage Location Terms&Conditions for the tranche (set automatically by system)[575]
 * @member {String} DocumentURL
 */
Increase.prototype['DocumentURL'] = undefined;

/**
 * Hashbyte of a document (also used as unique identifier of the document)  (set automatically by system)[660]
 * @member {String} DocumentHash
 */
Increase.prototype['DocumentHash'] = undefined;

/**
 * name of the algo which was used to hash the pdf[661]
 * @member {String} DocumentHashAlgorithm
 */
Increase.prototype['DocumentHashAlgorithm'] = undefined;

/**
 * Whether to replace or append the Terms & Conditions of the increase[587]
 * @member {Boolean} ReplaceDocumentation
 */
Increase.prototype['ReplaceDocumentation'] = undefined;

/**
 * Client Reference[668]
 * @member {String} ClientInstructionReference
 */
Increase.prototype['ClientInstructionReference'] = undefined;






export default Increase;

