/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Status model module.
 * @module model/Status
 * @version 1.0.3
 */
class Status {
    /**
     * Constructs a new <code>Status</code>.
     * @alias module:model/Status
     */
    constructor() { 
        
        Status.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Status</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Status} obj Optional instance to populate.
     * @return {module:model/Status} The populated <code>Status</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Status();

            if (data.hasOwnProperty('InternalStatus')) {
                obj['InternalStatus'] = ApiClient.convertToType(data['InternalStatus'], 'String');
            }
            if (data.hasOwnProperty('ExternalStatus')) {
                obj['ExternalStatus'] = ApiClient.convertToType(data['ExternalStatus'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Status</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Status</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['InternalStatus'] && !(typeof data['InternalStatus'] === 'string' || data['InternalStatus'] instanceof String)) {
            throw new Error("Expected the field `InternalStatus` to be a primitive type in the JSON string but got " + data['InternalStatus']);
        }
        // ensure the json data is a string
        if (data['ExternalStatus'] && !(typeof data['ExternalStatus'] === 'string' || data['ExternalStatus'] instanceof String)) {
            throw new Error("Expected the field `ExternalStatus` to be a primitive type in the JSON string but got " + data['ExternalStatus']);
        }

        return true;
    }


}



/**
 * 
 * @member {String} InternalStatus
 */
Status.prototype['InternalStatus'] = undefined;

/**
 * 
 * @member {String} ExternalStatus
 */
Status.prototype['ExternalStatus'] = undefined;






export default Status;

