/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PendingCount model module.
 * @module model/PendingCount
 * @version 1.0.3
 */
class PendingCount {
    /**
     * Constructs a new <code>PendingCount</code>.
     * @alias module:model/PendingCount
     */
    constructor() { 
        
        PendingCount.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PendingCount</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PendingCount} obj Optional instance to populate.
     * @return {module:model/PendingCount} The populated <code>PendingCount</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PendingCount();

            if (data.hasOwnProperty('issuanceBlotter')) {
                obj['issuanceBlotter'] = ApiClient.convertToType(data['issuanceBlotter'], 'Number');
            }
            if (data.hasOwnProperty('lifecycleManagement')) {
                obj['lifecycleManagement'] = ApiClient.convertToType(data['lifecycleManagement'], 'Number');
            }
            if (data.hasOwnProperty('redemptionReview')) {
                obj['redemptionReview'] = ApiClient.convertToType(data['redemptionReview'], 'Number');
            }
            if (data.hasOwnProperty('compliance')) {
                obj['compliance'] = ApiClient.convertToType(data['compliance'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PendingCount</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PendingCount</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * issuance blotter Count
 * @member {Number} issuanceBlotter
 */
PendingCount.prototype['issuanceBlotter'] = undefined;

/**
 * Lifecycle Management Count
 * @member {Number} lifecycleManagement
 */
PendingCount.prototype['lifecycleManagement'] = undefined;

/**
 * Redemption Review Count
 * @member {Number} redemptionReview
 */
PendingCount.prototype['redemptionReview'] = undefined;

/**
 * Compliance Review Count
 * @member {Number} compliance
 */
PendingCount.prototype['compliance'] = undefined;






export default PendingCount;

