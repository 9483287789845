import { useEffect, useCallback, useState } from "react";
import { TransactionStoreApiClient } from "../../api";
import {
  getLabel,
  transactionTypesEnums,
  transactionStatusOptions,
} from "../../utils/StatusUtils";
import { saveAs } from "file-saver";
import { DATE_FORMATS } from "../../constants";
import { convertDateTime } from "../../utils/dateUtils";

const useTransaction = (
  setData,
  setResponseText,
  setShowPopUp,
  setflagPopUp,
  userState,
  setErrorCodes,
  setSubmitterRoles
) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = (queryParams) => {
    if (userState.userRoleRight.includes("View_Transaction_Store_Log")) {
      TransactionStoreApiClient.getTXStoreTransactions(queryParams)
        .then(({ response }) => {
          if (
            response &&
            response.status === 200 &&
            response.data?.items &&
            Array.isArray(response.data.items)
          ) {
            const sortedArr = response.data.items
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((record) => ({
                id: record.id,
                isin: record.isin,
                timestamp: convertDateTime(
                  record.timestamp,
                  DATE_FORMATS.DATE_AND_TIME
                ),
                message: record.messages,
                transactionType: getLabel(
                  transactionTypesEnums,
                  record.transactionType
                ),
                submitterId: record.submitterID,
                submitterRole: record.submitterRole,
                status: getLabel(
                  transactionStatusOptions,
                  record.transactionStatus
                ),
                hasXML: record.hasXml,
                hasPDF: record.hasPdf,
              }));
            setItemsCount(response.data.count);
            setData(sortedArr);
            setLoading(false);
          } else {
            setData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setData([]);
          setResponseText("Something went wrong. Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  const downloadXML = (id, isin) => {
    TransactionStoreApiClient.getXmlByTransactionId(id)
      .then(({ response }) => {
        const blob = new Blob([response.data]);
        saveAs(blob, `${isin}.xml`);
        setResponseText("XML downloaded successfully");
        setShowPopUp(true);
        setflagPopUp("success");
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
      })
      .catch((error) => {
        setResponseText(`Unable to download XML.Please try later`);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const downloadPDF = (id, isin) => {
    TransactionStoreApiClient.getPdfByTransactionId(id)
      .then(({ response }) => {
        const blob = new Blob([response.data]);
        saveAs(blob, `${isin}.pdf`);
        setResponseText("PDF downloaded successfully");
        setShowPopUp(true);
        setflagPopUp("success");
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
      })
      .catch((error) => {
        setResponseText(`Unable to download PDF.Please try later`);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const createDropdownOptions = (arr, keyId) => {
    let arrValues = arr.map(function (value) {
      return value[keyId];
    });
    let tempArr = arrValues.filter(function (item, pos) {
      return arrValues.indexOf(item) == pos;
    });
    let optionsArr = [];
    tempArr.forEach(function (item, index) {
      if (item) {
        optionsArr.push({ value: item, label: item });
      }
    });
    return optionsArr;
  };

  const getErrorCodes = useCallback(() => {
    TransactionStoreApiClient.getTXStoreErrorcodes()
      .then(({ response }) => {
        let errorCodes = [];
        if (response.status === 200) {
          let data = [];
          if (Array.isArray(response?.data)) {
            data = response?.data;
          } else if (
            response?.data?.items &&
            Array.isArray(response?.data?.items)
          ) {
            data = response?.data.items;
          }
          for (var i = 0; i < data.length; i++) {
            let obj = {
              errorCode: data[i],
            };
            errorCodes.push(obj);
          }
          let errorCodesArr = createDropdownOptions(errorCodes, "errorCode");
          setErrorCodes(errorCodesArr);
        }
      })
      .catch((error) => {
        setResponseText(error.toString());
        setShowPopUp(true);
        setflagPopUp("error");
      });
  }, [userState.userRoleRight]);

  const getSubmitterRoles = () => {
    TransactionStoreApiClient.getTXStoreSubmitterRoles({
      page: 1,
      pagesize: 1000,
    })
      .then(async ({ response }) => {
        if (
          response.status === 200 &&
          response?.data?.items &&
          Array.isArray(response?.data?.items)
        ) {
          let submitters = [];
          let data = response.data.items;
          for (var i = 0; i < data.length; i++) {
            let obj = {
              submitterRole: data[i],
            };
            submitters.push(obj);
          }
          let submitterRoles = createDropdownOptions(
            submitters,
            "submitterRole"
          );
          setSubmitterRoles(submitterRoles);
        }
      })
      .catch((error) => {
        setResponseText(error.toString());
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  useEffect(() => {
    if (userState.userRoleRight.includes("View_Transaction_Store_Log")) {
      getErrorCodes();
      getSubmitterRoles();
    }
  }, [getErrorCodes, userState.userRoleRight]);

  return {
    fetchTransactions,
    itemsCount,
    loading,
    setLoading,
    downloadXML,
    downloadPDF,
  };
};

export { useTransaction };
