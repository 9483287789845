import React, { useEffect, useState } from "react";
import { StyledOffSubSectionContainer } from "./index.css";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Dropdown from "../../components/Dropdown";

import { DEFAULT_PAGE_LIST } from "./constants";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

const Paginator = ({
  itemsCount,
  pageList = DEFAULT_PAGE_LIST,
  onPageChange,
  resetPage = 0,
}) => {
  const defaultPageSize = pageList.length ? pageList[1]?.value : 100;
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [itemsCount]);

  useEffect(() => {
    if (resetPage) {
      setPage(1);
    }
  }, [resetPage]);

  return (
    <div>
      <StyledOffSubSectionContainer>
        <Grid
          container
          id="isList_rowsAndPaginationGrid"
          className="rowsAndPaginationGrid"
        >
          <Grid container xs={4}>
            <Typography
              variant="subtitle1"
              id="isList_rowsPerPage"
              className="rowsPerPage"
            >
              Rows per page:
            </Typography>
            <Dropdown
              isClearable={false}
              className="select-styles"
              onChange={(option) => {
                const newNumOfPages = Math.ceil(itemsCount / option.value);
                if (page > newNumOfPages) {
                  setPage(newNumOfPages);
                }
                setPageSize(option.value);
                onPageChange(page, option.value);
              }}
              id="rowOption"
              options={pageList}
              value={{ value: pageSize, label: pageSize.toString() }}
            />
          </Grid>
          <Grid container xs={4} justifyContent="center">
            <Typography
              variant="subtitle1"
              className="paginationLabel"
              id="isList_paginationLabel"
            >
              Page:
            </Typography>
            {itemsCount && (
              <Pagination
                onChange={(event, page) => {
                  setPage(page);
                  onPageChange(page, pageSize);
                }}
                count={Math.ceil(itemsCount / pageSize)}
                page={page}
                classes={{ ul: classes.ul }}
                id="isList_pagination"
              />
            )}
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>
      </StyledOffSubSectionContainer>
    </div>
  );
};

export default Paginator;
