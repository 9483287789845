/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BusinessValidationError from '../model/BusinessValidationError';
import DetailsModel from '../model/DetailsModel';
import Error from '../model/Error';
import IssuanceData from '../model/IssuanceData';
import StatusByIsinModel from '../model/StatusByIsinModel';

/**
* IssuancesQueries service.
* @module api/IssuancesQueriesApi
* @version 1.0.3
*/
export default class IssuancesQueriesApi {

    /**
    * Constructs a new IssuancesQueriesApi. 
    * @alias module:api/IssuancesQueriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getIssuanceByISIN operation.
     * @callback module:api/IssuancesQueriesApi~getIssuanceByISINCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DetailsModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get limited Issaunce details corresponding to an ISIN for the Details view.
     * @param {String} isin 'ISIN against which the Issuance details need to be retrieved. Example value : ''DE000IT7GPQ5''.'
     * @param {module:api/IssuancesQueriesApi~getIssuanceByISINCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DetailsModel}
     */
    getIssuanceByISIN(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getIssuanceByISIN");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DetailsModel;
      return this.apiClient.callApi(
        '/di/v1/issuances/{isin}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getIssuanceFileByISIN operation.
     * @callback module:api/IssuancesQueriesApi~getIssuanceFileByISINCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get limited Issaunce file corresponding to an ISIN for the Details view.
     * @param {String} isin 'ISIN against which the Issuance file need to be retrieved. Example value : ''DE000IT7GPQ5''.'
     * @param {Object} opts Optional parameters
     * @param {String} opts.format format of response
     * @param {module:api/IssuancesQueriesApi~getIssuanceFileByISINCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getIssuanceFileByISIN(isin, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getIssuanceFileByISIN");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
        'format': opts['format']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/issuances/{isin}/export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getIssuancesV2 operation.
     * @callback module:api/IssuancesQueriesApi~getIssuancesV2Callback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuanceData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get Issuances corresponding to the logged in user based on specified search parameters. These search fields can be optional (empty) or used in any combination.
     * @param {Object} opts Optional parameters
     * @param {String} opts.isin Issuances can be queried using the Unique Identifier - ISIN  Example : SA000A3E5FA4
     * @param {String} opts.issuerAgentAccountNo Issuances can be queried using the - CBF Number of the Issuer  Example : 7259000
     * @param {String} opts.issuerKvNoPayAgent Issuances can be queried using the - KV Account Number of the Pay Agent  Example : 7259
     * @param {Array.<String>} opts.payingAgentNames Issuances can be queried using the - paying agent name  Example : PrincipalPayingAgentName
     * @param {Array.<String>} opts.issuerNames Issuances can be queried using the- Name of the Issuer  Example : BNP Paribas Emissions- und Handelsgesellschaft mbH
     * @param {Array.<String>} opts.issuerAgentNames Issuances can be queried using the- Name of the Issuer Agent  Example : BNP Paribas Emissions- und Handelsgesellschaft mbH
     * @param {Array.<String>} opts.internalStatuses Issuances can be queried using the - Lifeycle Status of the Issuance  Example : DrpPending
     * @param {Array.<String>} opts.externalOverallStatuses Issuances can be queried using the - Overall Status of the Issuance  Example : Initiated
     * @param {Array.<String>} opts.externalProcessingStatuses Issuances can be queried using the - Overall Status of the Issuance  Example : Eligibility_Check_in_progress
     * @param {String} opts.paymentDateFrom Issuances can be queried using the - Payment Date of the Issuance (starting from a particular date)  Example : 2021-11-31
     * @param {String} opts.paymentDateTo Issuances can be queried using the - Payment Date of the Issuance (up to a particular date)  Example : 2021-12-31
     * @param {String} opts.statusSinceFrom Issuances can be queried using the - Status Since of the Issuance (value depicting the time period from which an issuance is in a particular status)  Example : 2021-12-11T19:01:02Z
     * @param {String} opts.statusSinceTo Issuances can be queried using the - Status Since of the Issuance (value depicting the time period for which an issuance is in a particular status)  Example : 2021-12-11T20:01:02Z
     * @param {Array.<String>} opts.instrumentTypes Issuances can be queried using instrument id (https://eusipa.org/governance/)   Example : 1200
     * @param {String} opts.valueDateFrom Issuances can be queried using the - Value Date of the Isuance (starting from a particular date)  Example : 2020-08-17
     * @param {String} opts.valueDateTo Issuances can be queried using the - Value Date of the Issuance (up to a particular date)  Example : 2020-09-17
     * @param {Number} opts.page page number
     * @param {Number} opts.pageSize number of elements per page
     * @param {module:api/IssuancesQueriesApi~getIssuancesV2Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuanceData}
     */
    getIssuancesV2(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'isin': opts['isin'],
        'issuerAgentAccountNo': opts['issuerAgentAccountNo'],
        'issuerKvNoPayAgent': opts['issuerKvNoPayAgent'],
        'payingAgentNames': this.apiClient.buildCollectionParam(opts['payingAgentNames'], 'multi'),
        'issuerNames': this.apiClient.buildCollectionParam(opts['issuerNames'], 'multi'),
        'issuerAgentNames': this.apiClient.buildCollectionParam(opts['issuerAgentNames'], 'multi'),
        'internalStatuses': this.apiClient.buildCollectionParam(opts['internalStatuses'], 'multi'),
        'externalOverallStatuses': this.apiClient.buildCollectionParam(opts['externalOverallStatuses'], 'multi'),
        'externalProcessingStatuses': this.apiClient.buildCollectionParam(opts['externalProcessingStatuses'], 'multi'),
        'paymentDateFrom': opts['paymentDateFrom'],
        'paymentDateTo': opts['paymentDateTo'],
        'statusSinceFrom': opts['statusSinceFrom'],
        'statusSinceTo': opts['statusSinceTo'],
        'instrumentTypes': this.apiClient.buildCollectionParam(opts['instrumentTypes'], 'multi'),
        'valueDateFrom': opts['valueDateFrom'],
        'valueDateTo': opts['valueDateTo'],
        'page': opts['page'],
        'pageSize': opts['pageSize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuanceData;
      return this.apiClient.callApi(
        '/di/v1/issuances', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPdfUsingGET operation.
     * @callback module:api/IssuancesQueriesApi~getPdfUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to view the PDF for any Issuance from the Issuance page.
     * @param {String} docId ISIN against which PDF details need to be exported. Example value 'DE000HR1ATN2'.
     * @param {module:api/IssuancesQueriesApi~getPdfUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getPdfUsingGET(docId, callback) {
      let postBody = null;
      // verify the required parameter 'docId' is set
      if (docId === undefined || docId === null) {
        throw new Error("Missing the required parameter 'docId' when calling getPdfUsingGET");
      }

      let pathParams = {
        'docId': docId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/xlsx', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/terms-conditions/{docId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProcessingStatusByISIN operation.
     * @callback module:api/IssuancesQueriesApi~getProcessingStatusByISINCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/StatusByIsinModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get latest live status by ISINs
     * @param {Array.<String>} isin ISIN for which the status is to be fetched
     * @param {module:api/IssuancesQueriesApi~getProcessingStatusByISINCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/StatusByIsinModel>}
     */
    getProcessingStatusByISIN(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getProcessingStatusByISIN");
      }

      let pathParams = {
      };
      let queryParams = {
        'isin': this.apiClient.buildCollectionParam(isin, 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [StatusByIsinModel];
      return this.apiClient.callApi(
        '/di/v1/isins/status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
