import React, { useState } from "react";
import axios from "axios";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  TextField,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import { getComparator, stableSort } from "../../components/Sorting";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "@material-ui/lab/Pagination";
import nodataIcon from "../../assets/Error Icon.svg";
import filterIcon from "../../assets/FilterIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { DatePicker, Space } from "antd";
import { TimePicker } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";
import clockIcon from "../../assets/clock-icon.png";
import moment from "moment-timezone";
import {
  StyledContainer,
  StyledHeader,
  StyledSubSectionGrid,
  StyledDialog,
} from "./index.css";
import { MultiSelect } from "react-multi-select-component";
import Dropdown from "../../components/Dropdown";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import { useUserStore } from "../../store/Store";
import { DATE_FORMATS } from "../../constants";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  back: {
    " > &": {
      marginTop: theme.spacing(2),
    },
  },
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
}));

const columns = [
  {
    id: "errorOccurranceDateTime",
    label: "Datetime error occurred",
    type: "date",
    width: "225px",
  },
  {
    id: "uploadPartyIdOrSenderSystem",
    label: "Upload Party ID/ Sender System",
    width: "150px",
  },
  { id: "isin", label: "ISIN", width: "125px" },
  { id: "context", label: "Context (DRP or Update Issuance)", width: "200px" },
  { id: "status", label: "Status", width: "100px" },
  {
    id: "statusDateTime",
    label: "Datetime last Status Change",
    type: "date",
    width: "225px",
  },
];

const searchList = {
  errorOccurranceDateTime: "",
  uploadPartyIdOrSenderSystem: "",
  isin: "",
  context: "",
  status: "",
  statusDateTime: "",
};
const defaultOptions = {
  errorOccurranceDateTime: [],
  uploadPartyIdOrSenderSystem: [],
  isin: [],
  context: [],
  status: [],
  statusDateTime: [],
};

const pageList = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
];

function NonProcessableISINLog() {
  const classes = useStyles();
  const [userState] = useUserStore();
  const [applyFilter, setApplyFilter] = React.useState(false); //flag for filter applied or not
  const [orderBy, setOrderBy] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  //eslint-disable-next-line no-unused-vars
  const [tempData, setTempData] = React.useState([]); //seting temp issuer details
  const [optionsArray, setOptionsArray] = React.useState({});
  const [responseText, setResponseText] = React.useState("");
  const [showPopUp, setShowPopUp] = React.useState(false);
  //const [selectedOption, setSelectedOption] = React.useState();
  const [flagPopUp, setflagPopUp] = React.useState("");
  const [data, setData] = React.useState([]); //contains all issuerData
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);
  const [searchArray, setSearchArray] = React.useState([]);
  const [page, setPage] = React.useState(0); //pagination - page number
  const [searchValue, setSearchValue] = React.useState(searchList);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(30); //current row per page on pagination
  const [dateFilterData, setDateFilterData] = React.useState([]);
  const [actualData, setActualData] = React.useState([]);
  const [errorObj, setErrorObj] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const user = sessionStorage.getItem("user");

  // This method fetch error items data
  const getNonProcessableISINLog = () => {
    const flag = "true";
    let id_token = sessionStorage.getItem("jwtToken");
    axios
      .get(`di/unknownIsin/details?viewedFlag=${flag}`, {
        headers: {
          Authorization: `Bearer ${id_token}`,
        },
      })
      .then((resp) => {
        if (
          resp.status === 200 &&
          Array.isArray(resp.data) &&
          resp.data.length > 0
        ) {
          let errorItems = [];
          for (let i = 0; i < resp.data?.length; i++) {
            let temp = resp.data[i];
            const FormatErrorItems = () => {
              let errorStatus = [];
              if (temp.status === "SenderNotifiedUnknownIsin") {
                errorStatus = "Sender Notified";
              }
              if (temp.status === "PendingUnknownIsin") {
                errorStatus = "Pending";
              }
              return errorStatus;
            };

            let obj = {
              context: temp.context,
              errorOccurranceDateTime: temp.errorOccurranceDateTime,
              isin: temp.isin,
              uploadPartyIdOrSenderSystem: temp.uploadPartyIdOrSenderSystem,
              status: FormatErrorItems(),
              statusDateTime: temp.statusDateTime,
              key: "temp-" + i,
            };
            errorItems.push(obj);
          }
          setData(errorItems);
          setTempData(errorItems); //storing issuance data for refernce
          createFilterOptions(errorItems);
          setActualData(errorItems);
        }
      })
      .catch((error) => {
        setResponseText(
          error.response.data.message
            ? error.response.data.message
            : "Unknown Server error. Please try later."
        );
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  //setting filter options based to data
  const createFilterOptions = (issuances) => {
    let columnNames = issuances.length !== 0 ? Object.keys(issuances[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(issuances, elem);
    });
    setOptionsArray(optionsArrList);
  };
  // updating options in dropdown dynamically
  const getOptionList = (issuances, columnName) => {
    let arr = [];
    issuances.map((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
      return arr;
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({ value: elem, label: elem });
      return returnList;
    });
    return returnList;
  };

  const updateStatus = (action) => {
    // filter only the specific action type
    let body = [];
    if (errorObj.length > 0) {
      if (action === "NotifySender") {
        if (errorObj.length > 1) {
          // In UI, if sender Notified and pending
          //eslint-disable-next-line array-callback-return
          body = errorObj?.filter((row) => {
            if (action === "NotifySender" && row.status !== "Sender Notified") {
              return true;
            }
          });
        } else {
          body = errorObj;
        }
      } else {
        body = errorObj;
      }

      let jwtToken = sessionStorage.getItem("jwtToken");
      let url = `/di/unknownIsin?action=${action}`;
      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.message.length > 1) {
              //For distinguishing popup messages when multiple rows selected
              let successMessage = "";
              res.data.message.forEach((item) => {
                successMessage = successMessage.concat(item + ", ");
              });
              setResponseText(successMessage);
              setShowPopUp(true);
              setflagPopUp("success");
            } else if (body.length !== 0) {
              body?.forEach((row) => {
                if (
                  action === "NotifySender" &&
                  row.status === "Sender Notified"
                ) {
                  //Setting errorpopup in this case from UI side
                  setResponseText(
                    "Sender Notified action cannot be taken on this ISIN"
                  );
                  setShowPopUp(true);
                  setflagPopUp("error");
                } else {
                  setResponseText(res.data.message); //This block will execute if sender Notified action is taken on pending status row
                  setShowPopUp(true);
                  setflagPopUp("success");
                }
              });
            } else {
              setResponseText(res.data.message); //This block is for when only single row is clicked to perform action. In UI no concatenation is enabled
              setShowPopUp(true);
              setflagPopUp("success");
            }

            setTimeout(() => {
              setShowPopUp(false);
              setResponseText("");
            }, 10000);
            getNonProcessableISINLog();
          } else {
            setResponseText(res.data.message); //This block is for status other than 200 for axios call
            setShowPopUp(true);
            setflagPopUp("error");
            getNonProcessableISINLog();
          }
          setOpen(false);
          setSelected([]); //For deselecting checkboxes after performimg action
          setErrorObj([]); //For emptying obj set during handleClick functionality
        })
        .catch((error) => {
          setResponseText(
            error.response.data.message
              ? error.response.data.message
              : "Unknown Server Error. Please try later."
          );
          setShowPopUp(true);
          setflagPopUp("error");
          setOpen(false);
          setSelected([]);
          setErrorObj([]);
        });
    } else {
      setResponseText("Please select atleast one record");
      setShowPopUp(true);
      setflagPopUp("error");
      setOpen(false);
      setSelected([]);
      setErrorObj([]);
    }
  };

  //close Success/Error Popup
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  const handleCancel = () => {
    setOpen(false);
    setSelected([]);
    setErrorObj([]);
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onPageChange = (pageValue) => {
    setPage(pageValue - 1);
  };

  const newRequestSearchTime = (value) => {
    let column = value.target.id;
    let newData = [...dateFilterData];
    newData = dateFilterData?.filter((row) => {
      sessionStorage.setItem("search", "true");
      const extractTimeFromColumnValue = formatDateTime(row[column])
        .split(" ")[1]
        .replace(/:/g, "-");
      return extractTimeFromColumnValue
        .toLowerCase()
        .includes(value.target.value);
    });
    setPage(0);
    setData(newData);
    setTempData(newData);
  };

  // Filtering with multiple column values
  const newRequestSearch = (event) => {
    sessionStorage.setItem("search", "true");

    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }

    let tempSearchValue = { ...searchValue };
    //setSelectedOption(tempSearchValue)
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue);

    let newData = [...actualData];
    searchArray.forEach((column) => {
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;

      tempNewData = newData.filter((row) => {
        sessionStorage.setItem("search", "true");
        if (
          column === "errorOccurranceDateTime" ||
          column === "statusDateTime"
        ) {
          if (value !== "") {
            const formatedExistingDate = formatDateTime(row[column]);
            return formatedExistingDate?.split(" ")[0] === value;
          } else {
            return true;
          }
        } else if (column === "isin") {
          return row[column].toLowerCase().includes(value.toLowerCase());
        } else {
          if (value.length === 0) {
            return true;
          } else {
            return value.includes(row[column]);
          }
        }
      });
      //setting new data based on filter
      newData = tempNewData;
    });
    setPage(0);
    setData(newData);
    setDateFilterData(newData);
    setTempData(newData);
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    let newSelectedRow = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      newSelectedRow = newSelectedRow.concat(errorObj, {
        isin: row.isin,
        statusDateTime: row.statusDateTime,
        status: row.status,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRow = newSelectedRow.concat(errorObj.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRow = newSelectedRow.concat(errorObj.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedRow = newSelectedRow.concat(
        errorObj.slice(0, selectedIndex),
        errorObj.slice(selectedIndex + 1)
      );
    }
    data.forEach((row) => {
      if (row.key === newSelected[0]) {
        setData(row);
      }
    });

    setErrorObj(newSelectedRow);
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const formatDateTime = (field) => {
    return moment(field).format(DATE_FORMATS.DATE_AND_TIME);
  };
  const removeAppliedFilter = () => {
    setSelectedColumnOption({
      errorOccurranceDateTime: [],
      uploadPartyIdOrSenderSystem: [],
      isin: [],
      context: [],
      status: [],
      statusDateTime: [],
    });
    setSearchArray([]);
  };

  return (
    <>
      <StyledContainer>
        <div>
          {responseText && showPopUp && (
            <div id="nonIsin_popup2" className="popup">
              {flagPopUp !== "error" ? (
                <SuccessPopup
                  successPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              ) : (
                <ErrorPopup
                  errorPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              )}
            </div>
          )}
        </div>
        <StyledHeader>
          <Typography
            variant="h2"
            id="nonIsin_mainHeader"
            className="mainHeader"
          >
            Non Processable ISIN Log
          </Typography>
        </StyledHeader>
        <StyledSubSectionGrid container>
          {user === "Clearstream" ? (
            <Grid item xs={12} align="end">
              <Space>
                <Button
                  variant="outlined"
                  color="primary"
                  className="acknowledged"
                  disabled={
                    selected.length > 10 ||
                    !userState.userRoleRight.includes("View_Acknowledged")
                  }
                  id="nonIsin_ack"
                  data-testid="btn_acknowledged"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Acknowledged
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="senderNotified"
                  disabled={
                    selected.length > 10 ||
                    !userState.userRoleRight.includes("View_Sender_Notified")
                  }
                  id="nonIsin_senderNotified"
                  data-testid="btn_senderNotified"
                  onClick={() => {
                    updateStatus("NotifySender");
                  }}
                >
                  Sender Notified
                </Button>
              </Space>
            </Grid>
          ) : (
            <Grid item xs={12} align="end">
              <Button
                variant="outlined"
                color="primary"
                className="acknowledged"
                disabled={
                  selected.length > 10 ||
                  !userState.userRoleRight.includes("View_Acknowledged_Issuer")
                }
                id="nonIsin_ack_button"
                data-testid="issuer_acknowledged"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Acknowledged
              </Button>
            </Grid>
          )}
        </StyledSubSectionGrid>
        <TableContainer id="nonIsin_tableStyling" className="tableStyling">
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>

                <TableCell
                  id="nonIsin_tableCellBorder"
                  className="tableCellBorder"
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="nonIsin_applyFilterText" variant="h5">
                          {applyFilter !== true
                            ? "Add Filters"
                            : "Remove Filters"}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span className="filterIcon">
                      <img
                        src={filterIcon}
                        id="nonIsin_filterIcon"
                        data-testid="nonIsin_filterIcon"
                        alt="triangle"
                        onClick={() => {
                          if (data.length === 0 && applyFilter === true) {
                            removeAppliedFilter();
                            setApplyFilter(!applyFilter);
                          } else if (applyFilter === true) {
                            setData(actualData);
                            sessionStorage.setItem("search", "false");
                          } else if (applyFilter === false) {
                            removeAppliedFilter();
                          }
                          if (data.length !== 0) {
                            removeAppliedFilter();
                            setApplyFilter(!applyFilter);
                          }
                        }}
                      />
                    </span>
                  </HtmlTooltip>
                </TableCell>

                {columns.map((column, index) => {
                  return (
                    <TableCell
                      className="cellBorder"
                      id={`nonIsin_${index}`}
                      key={column.key}
                      style={{ maxWidth: column.width, width: column.length }}
                    >
                      <TableSortLabel
                        style={{
                          width: column.width,
                          marginTop: column.marginTop,
                        }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                      >
                        <Typography
                          variant="h5"
                          id={`nonIsin_Text_${index}`}
                          className="lH-17"
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <span className="visuallyhidden">
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </Typography>
                      </TableSortLabel>

                      <div>
                        {applyFilter &&
                          (column.id !== "isin" ? (
                            column.type === "date" ? (
                              <Grid container>
                                <Grid item xs={8}>
                                  <DatePicker
                                    showNow={false}
                                    format={DATE_FORMATS.DATE}
                                    id="nonIsin_datePickerColumn"
                                    placeholder={DATE_FORMATS.DATE}
                                    size="small"
                                    onChange={(date, dateString) => {
                                      newRequestSearch({
                                        target: {
                                          id: column.id,
                                          value:
                                            date !== null ? dateString : "",
                                        },
                                      });
                                    }}
                                    suffixIcon={
                                      <img
                                        src={calendarIcon}
                                        id="nonIsin_calendarIcon"
                                        alt="calendar"
                                        className="iconColumn"
                                      />
                                    }
                                    className="datePickerColumn"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TimePicker
                                    placeholder="hh-mm-ss"
                                    size="small"
                                    suffixIcon={
                                      <img
                                        src={clockIcon}
                                        id="nonIsin_clockIcon"
                                        alt="clock"
                                        className="iconColumnTime"
                                      />
                                    }
                                    onChange={(time) => {
                                      newRequestSearchTime({
                                        target: {
                                          id: column.id,
                                          value:
                                            time !== null
                                              ? moment(time).format("HH-mm-ss")
                                              : "",
                                        },
                                      });
                                    }}
                                    className="datePickerColumn"
                                    id="nonIsin_datePickerColumn"
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <MultiSelect
                                style={{ overflow: "visible" }}
                                id="nonIsin_selectColumn"
                                className="selectColumn"
                                options={optionsArray[column.id]}
                                onChange={(option) => {
                                  let arr = [];
                                  option.map((elem) => {
                                    arr.push(elem.value);
                                    return arr;
                                  });
                                  let optionSelected = selectedColumnOption;
                                  optionSelected[column.id] = option;
                                  setSelectedColumnOption(optionSelected);
                                  newRequestSearch({
                                    target: {
                                      id: column.id,
                                      value: arr,
                                    },
                                  });
                                }}
                                value={selectedColumnOption[column.id]}
                              />
                            )
                          ) : (
                            <TextField
                              className="searchbarColumn"
                              fullWidth
                              placeholder="Enter"
                              InputProps={{ disableUnderline: true }}
                              data-testid="nonIsin_Text"
                              onChange={(event) => {
                                newRequestSearch(event);
                              }}
                              id={column.id}
                            ></TextField>
                          ))}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* if api is called and no data is available to show */}
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                    id="nonIsin_noDataIcon"
                    className="nodataicon"
                  >
                    <div>
                      <img
                        src={nodataIcon}
                        alt="nodata"
                        id="nonIsin_noiconColumn"
                        className="noiconColumn"
                      />
                      <Typography
                        id="nonIsin_noDataText"
                        data-testid="noDataText"
                      >
                        No data available to display
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(index);
                      let statusClass = "";
                      if (row.status === "Pending") {
                        statusClass = "pendingStatus";
                      } else if (row.status === "Sender Notified") {
                        statusClass = "senderNotifiedStatus";
                      }

                      return (
                        <TableRow
                          className="tableRow"
                          hover
                          onClick={(event) => handleClick(event, index, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          selected={isItemSelected}
                          data-testid="data_tableRow"
                          id={`nonIsin_tableRow_${index}`}
                        >
                          <Checkbox
                            className="checkboxStyling"
                            id={`nonIsin_checkboxStyling_${index}`}
                            checked={isItemSelected}
                          />
                          <TableCell></TableCell>
                          <TableCell
                            id={`nonIsin_errorDateTime_${index}`}
                            className="padding2"
                          >
                            <Typography
                              variant="subtitle1"
                              id={`nonIsin_errorDateTime_${index}`}
                              className="lH-17"
                            >
                              {formatDateTime(row.errorOccurranceDateTime)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id={`nonIsin_uploadParty_${index}`}
                            className="padding2"
                          >
                            <Typography
                              id={`nonIsin_uploadPartyData_${index}`}
                              variant="subtitle1"
                              className="lH-17"
                            >
                              {row.uploadPartyIdOrSenderSystem}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id={`nonIsin_isin_${index}`}
                            className="padding2"
                          >
                            <Typography
                              id={`nonIsin_isinData_${index}`}
                              variant="subtitle1"
                              className="lH-17"
                            >
                              {row.isin}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id={`nonIsin_context_${index}`}
                            className="padding2"
                          >
                            <Typography
                              id={`nonIsin_contextData_${index}`}
                              variant="subtitle1"
                              className="lH-17"
                            >
                              {row.context}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id={`nonIsin_status_${index}`}
                            className="padding2"
                          >
                            <Typography
                              id={`nonIsin_statusData_${index}`}
                              variant="subtitle1"
                              className={statusClass}
                            >
                              {row.status}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id={`nonIsin_statusDateTime_${index}`}
                            className="padding2"
                          >
                            <Typography
                              id={`nonIsin_statusDateTimeData_${index}`}
                              variant="subtitle1"
                              className="lH-17"
                            >
                              {formatDateTime(row.statusDateTime)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container id="nonIsin_paginationGrid" className="paginationGrid">
          <Grid container xs={2}>
            <Grid item xs={7}>
              <Typography
                id="nonIsin_rowsText"
                variant="subtitle1"
                className="pT-2"
              >
                Rows per page:
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Dropdown
                isClearable={false}
                className="select-styles"
                onChange={(option) => {
                  setRowsPerPage(option.value);
                }}
                id="rowOption"
                options={pageList}
                value={{ value: rowsPerPage, label: rowsPerPage.toString() }}
              />
            </Grid>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={3}></Grid>
            <Grid item xs={1} align="right">
              <Typography
                variant="subtitle1"
                className="page"
                id="nonIsin_page"
              >
                Page:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Pagination
                onChange={(event, page) => onPageChange(page)}
                count={Math.ceil(data.length / rowsPerPage)}
                page={page + 1}
                classes={{ ul: classes.ul }}
              />
            </Grid>
          </Grid>
        </Grid>

        <StyledDialog
          id="nonIsin_syncDialog"
          className="syncDialog"
          open={open}
          data-testid="dialogPopup"
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogContent>
            <DialogContentText>
              <Typography
                className="syncConfirm"
                id="nonIsin_syncConfirm"
                data-testid="acknowledge_text"
              >
                Do you want to Acknowledge the following Unknown ISIN?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions id="nonIsin_syncYes" className="syncYes">
            <Button
              variant="outlined"
              color="primary"
              className="cancel"
              id="nonIsin_cancel"
              data-testid="dialogPopup_cancel"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateStatus("Acknowledge");
              }}
              variant="contained"
              color="primary"
              className="acknowledged"
              id="nonIsin_acknowledged"
              data-testid="acknowledge"
            >
              Acknowledge
            </Button>
          </DialogActions>
        </StyledDialog>
      </StyledContainer>
    </>
  );
}

export default NonProcessableISINLog;
