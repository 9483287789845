import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TabHead, BoxContainer } from "./index.css";
import CancelIcon from "@material-ui/icons/Close";
import { UserContext } from "../../TableContext";

function TabBody(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      className="tabPanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ padding: "20px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabBody.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TabPanel(props) {
  const [value, setValue] = React.useState(0);
  //const [flag, setflag] = React.useState(false);
  const { name, content } = useContext(UserContext);

  const [tabNameList, setTabNameList] = name;
  const [tabContentList, setTabContentList] = content;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //close the tab and update the list
  const handleClose = (index) => {
    let tempTabNames = [...tabNameList];
    let removeName = [tempTabNames[index - 1]]; //check for tab name to be closed
    tempTabNames = tempTabNames.filter(
      (element) => !removeName.includes(element)
    ); //remove the specific tab
    let tempTabContents = [...tabContentList];
    let removeContent = [tempTabContents[index - 1]]; //checks for tab content to be closed
    tempTabContents = tempTabContents.filter(
      (element) => !removeContent.includes(element)
    ); //removes the specific content
    setTabNameList(tempTabNames); //update tab name list
    setTabContentList(tempTabContents); //update tab content list
  };
  useEffect(() => {
    //setflag(true)
    setValue(tabNameList.indexOf(props.currentIsin) + 1);
  }, [tabNameList, tabContentList, props.currentIsin]);
  return (
    <Box>
      <TabHead disableGutters={true}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
          className="tabsClass"
          data-testid="tabDataId"
        >
          <Tab
            label={
              <div style={{ textTransform: "capitalize" }}>Query/List</div>
            }
          />
          {tabNameList.map((tab_name, index) => {
            return (
              <Tab
                label={
                  <div style={{ display: "flex" }} data-testid="eachNewTab">
                    {tab_name}
                    <span
                      style={{ marginLeft: "5px" }}
                      onClick={(event) => {
                        event.target.classList.contains("closeTab") &&
                          handleClose(index + 1);
                      }}
                    >
                      <CancelIcon
                        className="closeTab"
                        style={{ verticalAlign: "middle", fontSize: "20px" }}
                        onClick={(event) => {
                          event.target.classList.contains("closeTab") &&
                            handleClose(index + 1);
                        }}
                      />
                    </span>{" "}
                  </div>
                }
              />
            );
          })}
        </Tabs>
      </TabHead>
      <BoxContainer disableGutters={true}>
        <TabBody value={value} index={0}>
          {props.children}
        </TabBody>

        {tabContentList.map((tab_content, index) => {
          return (
            <TabBody value={value} index={index + 1}>
              {tab_content}
            </TabBody>
          );
        })}
      </BoxContainer>
    </Box>
  );
}
