import styled from "styled-components";
import { Container, Card, DialogContent, Dialog } from "@material-ui/core";
import { Popover, Tag } from "antd";
export const StyledForm = styled.form`
  padding-top: 20px;
  padding-left: 68px;
  padding-right: 66px;

  .ant-picker {
    border: none;
    width: 100%;
    padding: 0;
  }
  .ant-picker-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding-bottom: 2px;
  }
  .ant-picker-clear {
    display: none;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
`;

export const StyledTableContainer = styled(Container)`
  border-radius: 12px;
  background-color: #ffffff;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-top: 20px;
  @media (min-width: 1280px) {
    max-width: 100%;
  }
  .popup {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 75px;
  }
  .checkboxStyling {
    color: #00a7c4;
    padding-top: 35%;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .typo-h6 {
    color: whitesmoke;
  }
  .initiatedStatus {
    color: #b7b7b7;
    line-height: 17px;
  }
  .legallyExistingStatus {
    color: #00a7c4;
    line-height: 17px;
  }
  .liveStatus {
    color: #00cc99;
    line-height: 17px;
  }
  .deadStatus {
    color: #cc0099;
    line-height: 17px;
  }
  .paginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .pT-2 {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  #iss_button_GoToFilterButton {
    width: 10px;
    margin-right: 10px;
    background-color: #00a5c0;
    color: #fff;
    font-weight: 500;
  }
  .gotoInput div {
    height: 28px;
    border-radius: 15px;
    width: 58px;
    color: #000;
    font-weight: 500;
    margin-right: 10px;
  }
  .gotoInput input {
    text-align: center;
  }
  #iss_grid_pagechange,
  #iss_grid_issuanceCount {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  #iss_grid_issuanceCount {
    justify-content: center;
  }
  #iss_typo_rowsperpage,
  #iss_grid_issuanceCount,
  #iss_typo_page {
    color: #000;
    font-weight: 500;
    font-size: small;
  }
  .fr {
    margin-left: 5px;
    background-color: #000099;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .bs {
    background-color: #00cc99;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .tableStyling {
    border-radius: 12px;
    background-color: #ffffff;
    padding-bottom: 10px;
  }
  .lH-17 {
    line-height: 17px;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: #d3f3f7;
  }
  .select-styles {
    width: 80px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
      color: black;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
`;
export const StyledSearchContainer = styled(Card)`
  box-shadow: none;
  padding-top: 15px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 15px;
  @media (min-width: 1280px) {
    max-width: 100%;
  }
  .inputEntry,
  .select__control {
    min-height: 40px;
  }
  input {
    font-size: medium;
  }
  input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 40px;
    border: 1px solid rgb(135 135 135 / 60%);
    border-radius: 17px;
    width: 100%;
    box-shadow: none;
    margin-top: 15px;
  }
  .inputDetails,
  .queryDropdown {
    width: 80%;
  }
  #selectIssuerName,
  #selectOverallStatus,
  #selectLifecycleStatus,
  #selectOverallStatus2,
  #selectLifecycleStatus2,
  #selectProcessStatus {
    width: 88%;
  }
  .queryFeild {
    margin-top: 15px;
  }
  .addIcon {
    margin-left: -20px;
  }
  .ant-picker {
    width: 90%;
    border-radius: 17px;
  }
  .queryFromTo {
    margin-left: 10px;
  }
  .queryDropdown,
  .queryDataPicker {
    margin-top: 10px;
  }
  .fromTo {
    margin-top: 5px;
    color: #00a5c0;
  }
`;

export const StyledContainer = styled(Container)`
  padding-top: 0px;
  padding-left: 80px;
  padding-right: 80px;
  @media (min-width: 1280px) {
    max-width: 100%;
  }
  .MuiPaper-root {
    padding-top: 5px;
  }
  .addFilterButton {
    float: right;
    color: #00a5c0;
    font: 14px;
    font-weight: 500;
  }
  .ant-picker {
    width: 90%;
    border-radius: 17px;
  }
  .addFilterClass {
    padding-top: 10px;
  }
  .addFilterButtonDisable {
    float: right;
    color: #b7b7b7;
    font: 14px;
    font-weight: 500;
    pointer-events: none;
  }
  .cancelFilterButton {
    float: right;
    color: #00a5c0;
    font: 14px;
    font-weight: 500;
  }
  .SearchButton,
  .cancelFilterButton {
    text-transform: capitalize;
    float: right;
    color: #fff;
    background-color: #00a7c4;
    margin-left: 5px;
  }
  .MuiButton-outlinedPrimary {
    border: none;
    font-size: 14px;
  }
  .btnContainer {
    float: right;
  }
  .fromTo {
    margin-top: 5px;
    float: right;
    padding-right: 5px;
  }
  .searchLabel {
    float: right;
    padding-right: 5px;
  }
  .ForwardRef-icon-6 {
    color: hsl(0, 0%, 80%);
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    font-size: 17px;
  }
  .row > * {
    padding: 0px;
  }
  .searchBar {
    padding-top: 0px;
  }
  .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .MuiTableCell-stickyHeader {
    z-index: 0;
  }
  .MuiDialog-paperWidthXs {
    max-width: 491px;
  }
  .buttonStyle {
    color: #00a7c4;
    background-color: white;
    border: solid 1px;
  }
  .buttonStyleSync {
    color: #00a7c4;
    background-color: white;
    border: solid 1px;
    width: 190px;
  }
  .popup {
    position: absolute;
    z-index: 4;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 75px;
  }
  .popup2 {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 180px;
  }
  .checkboxStyling {
    color: #cc0099;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .typo-h6 {
    color: whitesmoke;
  }
  .initiatedStatus {
    color: #ff6600;
    line-height: 17px;
  }
  .legallyExistingStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .liveStatus {
    color: #00cc99;
    line-height: 17px;
  }
  .deadStatus {
    color: #878787;
    line-height: 17px;
  }
  .deactivatedStatus {
    color: #878787;
    line-height: 17px;
  }
  .paginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .pT-2 {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .fr {
    margin-left: 5px;
    background-color: #000099;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .frDeactivated {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    margin-left: 5px;
    background-color: #878787;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .bs {
    background-color: #00cc99;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .bsDeactivated {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    background-color: #878787;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .filterColumn .css-g1d714-ValueContainer {
    padding-top: 0px;
  }
  .filterColumn .select__control {
    margin-top: 10px;
    max-height: 35px;
    border: 1px solid rgb(135 135 135 / 50%);
  }
  .filterColumn .select__control .select__dropdown-indicator {
    color: #aaaaaa;
  }
  .datePickerColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .iconColumn {
    height: 15px;
  }
  .rmsc .dropdown-heading {
    padding-bottom: 5px;
  }
  .rmsc .options {
    max-height: 200px !important;
  }
  .selectColumn .dropdown-container {
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .searchbarColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    padding-left: 10px;
    padding-top: 2px;
  }
  .tableStyling {
    margin-top: 30px;
    background-color: #ffffff;
    padding-bottom: 10px;
  }
  #iss_typo_mostRecent {
    margin-top: 8px;
  }
  .activeIcon {
    cursor: pointer;
  }
  .greyIconForUpdate {
    cursor: pointer;
  }
  .MuiButtonGroup-root {
    display: -webkit-inline-box;
  }
  .lH-17 {
    line-height: 17px;
  }
  .cellBorder {
    // border-bottom: 1px solid #00A5C0
  }
  .MuiTableCell-root {
    padding-left: 6px;
    padding-right: 6px;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: #d3f3f7;
  }
  .select-styles {
    width: 80px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
      color: black;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
  .tableCellBorder {
    border-bottom: 1px solid #00a5c0;
    paddingright: 5;
  }
  .filterIcon {
    cursor: pointer;
  }
  .nodataicon {
    padding-top: 30px;
  }
  .noiconColumn {
    height: 30px;
  }
  .page {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 20px;
  }
  .padding1 {
    padding-left: 20px;
  }
  .padding2 {
    padding-left: 18px;
  }
  @keyframes moving-gradient {
    0% {
      background-position: -250px 0;
    }
    100% {
      background-position: 250px 0;
    }
  }

  .tableLoader span {
    display: block;
    height: 12px;
    border-radius: 10px;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient !important;
    animation-duration: 1s !important;
    animation-iteration-count: infinite !important;
    animation-timing-function: linear !important;
    animation-fill-mode: forwards !important;
  }
  .tableLoader .smallBlock {
    width: 90px;
  }
  .tableLoader .largeBlock {
    width: 130px;
  }
`;

export const StyledHeader = styled(Card)`
  border: none;
  padding-top: 40px;
  padding-bottom: 21px;
  box-shadow: none;
  background-color: #ffffff;
  z-index: 1;
  .MuiInputBase-root.Mui-disabled {
    color: currentColor;
  }
  .btn-style {
    margin-right: 8px;
  }
  .select-styles {
    width: 100%;
    padding-bottom: 8px;
  }
  .ForwardRef-iconButton-2 {
    color: #00a7c4;
  }

  .searchBar {
    height: 35px;
    border: 1px solid rgb(135 135 135 / 60%);
    border-radius: 17px;
    width: 100%;
    box-shadow: none;
  }
  .gridContainer {
    padding-top: 42px;
    margin: 0px;
  }
  .pT-8 {
    padding-top: 7px;
  }
  .pL-10 {
    padding-left: 10px;
  }
  .activeIcon {
    cursor: pointer;
  }
`;

export const StyledContent = styled(DialogContent)`
  padding: 0px;
  hr {
    margin-left: 39px;
    margin-right: 36px;
  }
  .MuiBreadcrumbs-separator {
    margin-right: 0px;
    margin-left: 39px;
  }
`;
export const StyledDialog = styled(Dialog)`
    .syncDialog{
        z-index: 1;
    }
    .uploadDRP{
        z-index: 3;
    }
    .uploadHeading{
        font-size: 17px;
        padding-top: 40px;
        padding-left: 24px
    }
    .selectXML{
        color: #000000;
        padding-top: 25px
    }
    .paddingXML{
        padding-top: 10px
    }
    .chooseUnderline{
        padding-top: 10px;
        border-bottom: 1px solid black
    }
    .textFormat{
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
    }
    .float{
        float = right
    }
    .paddingFileUpload{
        padding-left: 2%
    }
    .display{
        display: none
    }
    .errorFormat{
        color: #CC0099
    }
    .paddingCancel{
        padding-bottom: 30px;
        padding-right: 20px;
        padding-top: 20px
    }
    .colorCancel{
        color: #00A7C4;
        width: 8%
    }
    .marginSubmit{
        margin-right: 14px
    }
    .syncConfirm{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        padding-top: 20px;
        padding-left: 10px
    }
    .syncYes{
        padding-top: "20px;
        padding-bottom: "30px;
        padding-right: 100px
    }

`;

export const SearchTags = styled(Tag)`
  background-color: hsl(0, 0%, 90%);
  border-radius: 8px;
  font-family: sans-serif;
  margin: 2px;
  font-size: 12px;
  box-sizing: border-box;
  position: relative;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  padding-right: ${(props) => (props.closable ? "1.2rem" : "auto")};
  vertical-align: middle;

  span:first-child {
    padding-right: 5px;
  }
  .ant-tag-close-icon {
    position: absolute;
    right: 0.3rem;
    margin-top: 4px;
  }
`;
