/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The IssuanceSearchListItem model module.
 * @module model/IssuanceSearchListItem
 * @version 1.0.3
 */
class IssuanceSearchListItem {
    /**
     * Constructs a new <code>IssuanceSearchListItem</code>.
     * @alias module:model/IssuanceSearchListItem
     * @param instrumentType {String} Instrument Type
     * @param isin {String} ISIN
     * @param issuerAgentAccountNo {String} CBF Account
     * @param issuerKvNoPayAgent {String} Paying Agent
     * @param issuerName {String} Issuer Name
     * @param internalStatus {String} Lifecycle Status
     * @param externalOverallStatus {String} Status
     * @param externalProcessingStatus {String} Status
     * @param paymentDate {Date} Pay Date
     * @param statusSince {Date} Status Since
     * @param valueDate {Date} Value Date
     */
    constructor(instrumentType, isin, issuerAgentAccountNo, issuerKvNoPayAgent, issuerName, internalStatus, externalOverallStatus, externalProcessingStatus, paymentDate, statusSince, valueDate) { 
        
        IssuanceSearchListItem.initialize(this, instrumentType, isin, issuerAgentAccountNo, issuerKvNoPayAgent, issuerName, internalStatus, externalOverallStatus, externalProcessingStatus, paymentDate, statusSince, valueDate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, instrumentType, isin, issuerAgentAccountNo, issuerKvNoPayAgent, issuerName, internalStatus, externalOverallStatus, externalProcessingStatus, paymentDate, statusSince, valueDate) { 
        obj['instrumentType'] = instrumentType;
        obj['isin'] = isin;
        obj['issuerAgentAccountNo'] = issuerAgentAccountNo;
        obj['issuerKvNoPayAgent'] = issuerKvNoPayAgent;
        obj['issuerName'] = issuerName;
        obj['internalStatus'] = internalStatus;
        obj['externalOverallStatus'] = externalOverallStatus;
        obj['externalProcessingStatus'] = externalProcessingStatus;
        obj['paymentDate'] = paymentDate;
        obj['statusSince'] = statusSince;
        obj['valueDate'] = valueDate;
    }

    /**
     * Constructs a <code>IssuanceSearchListItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/IssuanceSearchListItem} obj Optional instance to populate.
     * @return {module:model/IssuanceSearchListItem} The populated <code>IssuanceSearchListItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new IssuanceSearchListItem();

            if (data.hasOwnProperty('instrumentType')) {
                obj['instrumentType'] = ApiClient.convertToType(data['instrumentType'], 'String');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentAccountNo')) {
                obj['issuerAgentAccountNo'] = ApiClient.convertToType(data['issuerAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('issuerKvNoPayAgent')) {
                obj['issuerKvNoPayAgent'] = ApiClient.convertToType(data['issuerKvNoPayAgent'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
            if (data.hasOwnProperty('externalOverallStatus')) {
                obj['externalOverallStatus'] = ApiClient.convertToType(data['externalOverallStatus'], 'String');
            }
            if (data.hasOwnProperty('externalProcessingStatus')) {
                obj['externalProcessingStatus'] = ApiClient.convertToType(data['externalProcessingStatus'], 'String');
            }
            if (data.hasOwnProperty('paymentDate')) {
                obj['paymentDate'] = ApiClient.convertToType(data['paymentDate'], 'Date');
            }
            if (data.hasOwnProperty('statusSince')) {
                obj['statusSince'] = ApiClient.convertToType(data['statusSince'], 'Date');
            }
            if (data.hasOwnProperty('valueDate')) {
                obj['valueDate'] = ApiClient.convertToType(data['valueDate'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>IssuanceSearchListItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>IssuanceSearchListItem</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of IssuanceSearchListItem.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['instrumentType'] && !(typeof data['instrumentType'] === 'string' || data['instrumentType'] instanceof String)) {
            throw new Error("Expected the field `instrumentType` to be a primitive type in the JSON string but got " + data['instrumentType']);
        }
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['issuerAgentAccountNo'] && !(typeof data['issuerAgentAccountNo'] === 'string' || data['issuerAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentAccountNo` to be a primitive type in the JSON string but got " + data['issuerAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['issuerKvNoPayAgent'] && !(typeof data['issuerKvNoPayAgent'] === 'string' || data['issuerKvNoPayAgent'] instanceof String)) {
            throw new Error("Expected the field `issuerKvNoPayAgent` to be a primitive type in the JSON string but got " + data['issuerKvNoPayAgent']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }
        // ensure the json data is a string
        if (data['externalOverallStatus'] && !(typeof data['externalOverallStatus'] === 'string' || data['externalOverallStatus'] instanceof String)) {
            throw new Error("Expected the field `externalOverallStatus` to be a primitive type in the JSON string but got " + data['externalOverallStatus']);
        }
        // ensure the json data is a string
        if (data['externalProcessingStatus'] && !(typeof data['externalProcessingStatus'] === 'string' || data['externalProcessingStatus'] instanceof String)) {
            throw new Error("Expected the field `externalProcessingStatus` to be a primitive type in the JSON string but got " + data['externalProcessingStatus']);
        }

        return true;
    }


}

IssuanceSearchListItem.RequiredProperties = ["instrumentType", "isin", "issuerAgentAccountNo", "issuerKvNoPayAgent", "issuerName", "internalStatus", "externalOverallStatus", "externalProcessingStatus", "paymentDate", "statusSince", "valueDate"];

/**
 * Instrument Type
 * @member {String} instrumentType
 */
IssuanceSearchListItem.prototype['instrumentType'] = undefined;

/**
 * ISIN
 * @member {String} isin
 */
IssuanceSearchListItem.prototype['isin'] = undefined;

/**
 * CBF Account
 * @member {String} issuerAgentAccountNo
 */
IssuanceSearchListItem.prototype['issuerAgentAccountNo'] = undefined;

/**
 * Paying Agent
 * @member {String} issuerKvNoPayAgent
 */
IssuanceSearchListItem.prototype['issuerKvNoPayAgent'] = undefined;

/**
 * Issuer Name
 * @member {String} issuerName
 */
IssuanceSearchListItem.prototype['issuerName'] = undefined;

/**
 * Lifecycle Status
 * @member {String} internalStatus
 */
IssuanceSearchListItem.prototype['internalStatus'] = undefined;

/**
 * Status
 * @member {String} externalOverallStatus
 */
IssuanceSearchListItem.prototype['externalOverallStatus'] = undefined;

/**
 * Status
 * @member {String} externalProcessingStatus
 */
IssuanceSearchListItem.prototype['externalProcessingStatus'] = undefined;

/**
 * Pay Date
 * @member {Date} paymentDate
 */
IssuanceSearchListItem.prototype['paymentDate'] = undefined;

/**
 * Status Since
 * @member {Date} statusSince
 */
IssuanceSearchListItem.prototype['statusSince'] = undefined;

/**
 * Value Date
 * @member {Date} valueDate
 */
IssuanceSearchListItem.prototype['valueDate'] = undefined;






export default IssuanceSearchListItem;

