/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class BatchItemStatusDetail.
* @enum {}
* @readonly
*/
export default class BatchItemStatusDetail {
    
        /**
         * value: "NEW"
         * @const
         */
        "NEW" = "NEW";

    
        /**
         * value: "ACCEPTED"
         * @const
         */
        "ACCEPTED" = "ACCEPTED";

    
        /**
         * value: "REJECTED"
         * @const
         */
        "REJECTED" = "REJECTED";

    
        /**
         * value: "RESCHEDULED"
         * @const
         */
        "RESCHEDULED" = "RESCHEDULED";

    

    /**
    * Returns a <code>BatchItemStatusDetail</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/BatchItemStatusDetail} The enum <code>BatchItemStatusDetail</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

