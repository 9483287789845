import {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MoreHorizOutlined from "@material-ui/icons/MoreHorizOutlined";
import { Button } from "@material-ui/core";
import { Dropdown } from "antd";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid rgba(135, 135, 135, 0.6)",
  "& .MuiTabs-indicator": {
    backgroundColor: "#00A5C0",
    height: "3px",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#B7B7B7",
    "&:hover": {
      color: "#00A5C0",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#00A5C0",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#00A5C0",
    },
  })
);

const ResponsiveTabs = ({ allTabs, value, setValue, sessionStorageItem }) => {
  const [lastWindowWidth, setLastWindowWidth] = useState(window.innerWidth);
  const [visibleTabs, setVisibleTabs] = useState(allTabs);

  useEffect(() => {
    if (sessionStorageItem === "pending") {
      setVisibleTabs(allTabs);
    }
  }, [allTabs, sessionStorageItem]);

  const tabsAriaLabel = `${sessionStorageItem} tabs`;

  const tabContainerRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let complianceObj = {
      tabName: newValue,
      array: [],
    };
    sessionStorage.setItem(sessionStorageItem, JSON.stringify(complianceObj));
  };

  const calculateTabsToShow = useCallback(() => {
    const currentWidth = window.innerWidth;
    const isResizedToSmaller = lastWindowWidth >= currentWidth;
    setLastWindowWidth(currentWidth);

    const sizeChangeThreshold = 100;
    const sizeDifference = Math.abs(currentWidth - lastWindowWidth);

    const showMoreBtnWidth = 64;
    const parentContainerPadding = 160;
    const parentWidth =
      tabContainerRef.current?.parentElement.parentElement.clientWidth -
      parentContainerPadding -
      showMoreBtnWidth; // TODO: change parent's padding to margin and remove minus 160

    const tabsWidth = tabContainerRef.current?.scrollWidth;

    const tabListElements = tabContainerRef.current?.querySelector(
      `[aria-label="${tabsAriaLabel}"]`
    );

    const hiddenTabs = allTabs.slice(visibleTabs.length, allTabs.length);

    const lastTabWidth =
      tabListElements.children.length > 1
        ? tabListElements.children[tabListElements.children.length - 2]
            .scrollWidth - 22
        : 0;

    if (tabsWidth >= parentWidth && isResizedToSmaller) {
      setVisibleTabs(visibleTabs.slice(0, visibleTabs.length - 1));
    }
    if (
      tabsWidth + lastTabWidth < parentWidth &&
      hiddenTabs.length > 0 &&
      !isResizedToSmaller
    ) {
      if (sizeDifference > sizeChangeThreshold) {
        // handle case when window maximized
        setVisibleTabs([...allTabs]);
      } else {
        setVisibleTabs([...visibleTabs, hiddenTabs[0]]);
      }
    }
  }, [allTabs, lastWindowWidth, tabsAriaLabel, visibleTabs]);

  useLayoutEffect(() => {
    calculateTabsToShow();
    window.addEventListener("resize", calculateTabsToShow);
    return () => {
      window.removeEventListener("resize", calculateTabsToShow);
    };
  }, [calculateTabsToShow, visibleTabs]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <AntTabs
        value={value}
        onChange={handleChange}
        aria-label={tabsAriaLabel}
        TabIndicatorProps={{ style: { transition: "none" } }}
        ref={tabContainerRef}
      >
        {visibleTabs.map((obj) => (
          <AntTab
            key={obj.value}
            label={obj.label}
            value={obj.value}
            data-testid={obj.value}
          />
        ))}
      </AntTabs>
      {visibleTabs.length < allTabs.length && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Dropdown
            menu={{
              selectable: true,
              selectedKeys: [value],
              onClick: (tab) => {
                handleChange(undefined, tab.key);
              },
              items: allTabs
                .slice(visibleTabs.length, allTabs.length)
                .map((tab) => ({
                  key: tab.value,
                  label: tab.label,
                })),
            }}
            placement="bottomRight"
          >
            <Button
              color={
                visibleTabs.some((tab) => tab.value === value)
                  ? "default"
                  : "primary"
              }
              variant="outlined"
              style={{ width: 64 }}
            >
              <MoreHorizOutlined />
            </Button>
          </Dropdown>
        </div>
      )}
    </Box>
  );
};

export default ResponsiveTabs;
