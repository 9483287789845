import { useEffect, useCallback, useState } from "react";
import moment from "moment-timezone";
import { saveAs } from "file-saver";

import { ComplianceApiClient as complianceApiClient } from "../../../api";
import { DATE_FORMATS } from "../../../constants";

const useRecords = (
  type,
  setData,
  setResponseText,
  setShowPopUp,
  setflagPopUp,
  userState,
  defaultRowsCount = 100
) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const exportCurrentRecordsList = (type) => {
    complianceApiClient
      .exportComplianceRecords(type)
      .then(({ response }) => {
        const blob = new Blob([response.data]);
        saveAs(blob, `Current_${type}.csv`);
        setResponseText(`Current ${type} downloaded successfully`);
        setShowPopUp(true);
        setflagPopUp("success");
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
      })
      .catch((error) => {
        setResponseText(
          `Unable to download current ${type} Report.Please try later`
        );
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const fetchRecords = useCallback(
    (type, page = 1, pagesize = 100) => {
      if (
        userState.userRoleRight.includes("View_Compliance_List_Upload_Time")
      ) {
        const paginationParam = { page, pagesize };
        complianceApiClient
          .getRecords(type, paginationParam)
          .then(({ response }) => {
            let hasContent = response && Array.isArray(response.data.items);
            if (hasContent) {
              const sortedArr = response.data.items
                .toSorted(
                  (a, b) => new Date(b.entryDate) - new Date(a.entryDate)
                )
                .map((record) => ({
                  ...record,
                  entryDate: moment(record.entryDate).format(DATE_FORMATS.DATE),
                  addedBy: `${record.addedByUserName} (${record.addedByUserId})`,
                  reviewedBy: `${record.reviewedByUserName} (${record.reviewedByUserId})`,
                }));
              setItemsCount(response.data.count);
              setData(sortedArr);
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
            setResponseText("Something went wrong.Please try later");
            setShowPopUp(true);
            setflagPopUp("error");
          });
      }
    },
    [
      setData,
      setResponseText,
      setShowPopUp,
      setflagPopUp,
      userState.userRoleRight,
    ]
  );

  const changePage = (type, pageNumber, pageSize) =>
    fetchRecords(type, pageNumber, pageSize);

  useEffect(() => {
    fetchRecords(type, 1, defaultRowsCount);
  }, [fetchRecords, type, defaultRowsCount]);

  return {
    fetchRecords,
    exportCurrentRecordsList,
    changePage,
    itemsCount,
    loading,
  };
};

export { useRecords };
