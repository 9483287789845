import React, { useState } from "react";
import { StyledContainer } from "./index.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";

import AccountListChangeSets from "./AccountListChangeSets";
import AccountListData from "./AccountListData";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid rgba(135, 135, 135, 0.6)",
  "& .MuiTabs-indicator": {
    backgroundColor: "#00A5C0",
    height: "3px",
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: "0px",
  },
});

const AntTabPanel = styled(TabPanel)({
  padding: "0px",
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#B7B7B7",
    "&:hover": {
      color: "#00A5C0",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#00A5C0",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#00A5C0",
    },
  })
);

function AccountList() {
  const [value, setValue] = useState(
    sessionStorage.getItem("accountList")
      ? JSON.parse(sessionStorage.getItem("accountList")).tabName
      : "AccountList"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let accountObj = {
      tabName: newValue,
      array: [],
    };
    sessionStorage.setItem("accountList", JSON.stringify(accountObj));
  };
  return (
    <React.Fragment>
      <StyledContainer>
        <TabContext value={value}>
          <Box sx={{ width: "fit-content" }}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="accountlist tabs"
              TabIndicatorProps={{ style: { transition: "none" } }}
            >
              <AntTab
                label={"Account List"}
                value="AccountList"
                data-testid="AccountListTab"
              />
              <AntTab
                label={"Account Changesets"}
                value="AccountChangeSets"
                data-testid="AccountChangeSetsTab"
              />
            </AntTabs>
          </Box>
          <AntTabPanel value="AccountList">
            <AccountListData />
          </AntTabPanel>
          <AntTabPanel value="AccountChangeSets">
            <AccountListChangeSets />
          </AntTabPanel>
        </TabContext>
      </StyledContainer>
    </React.Fragment>
  );
}

export default AccountList;
