import React, { useContext, useEffect } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import Searchbar from "material-ui-search-bar";
import axios from "axios";
import EllipisWithTooltip from "../../components/EllipsisWithTooltip";
import Tooltip from "@material-ui/core/Tooltip";
import {
  StyledContainer,
  StyledHeader,
  StyledForm,
  StyledContent,
  StyledOfDialog,
  StyledDialogActions,
  StyledDialogContentText,
} from "./index.css";
import Dropdown from "../../components/Dropdown";
import Checkbox from "@material-ui/core/Checkbox";
import { getComparator, stableSort } from "../../components/Sorting";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment-timezone";
import { DialogContentText } from "@material-ui/core";
import { TextareaAutosize } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import historyCircle from "../../assets/Oval Copy.png";
import { catchBlockError } from "../Issuance/Issuance";
import filterIcon from "../../assets/FilterIcon.svg";
import nodataIcon from "../../assets/Error Icon.svg";
import { MultiSelect } from "react-multi-select-component";
import { DatePicker } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";

import reviewGreyIcon from "../../assets/inactiveActionIcons/Review-Grey.svg";
import historyGreyIcon from "../../assets/inactiveActionIcons/History-Grey.svg";
import deleteGreyIcon from "../../assets/inactiveActionIcons/Delete-Grey.svg";
import updateGreyIcon from "../../assets/inactiveActionIcons/Update-Grey.svg";
import downloadGreyIcon from "../../assets/inactiveActionIcons/Download-Grey.svg";
import createGreyIcon from "../../assets/inactiveActionIcons/Create-Grey.svg";
import createActiveIcon from "../../assets/deselectedActionIcons/Create-Outline.svg";
import historyActiveIcon from "../../assets/deselectedActionIcons/History-Outline.svg";
import deleteActiveIcon from "../../assets/deselectedActionIcons/Delete-Outline.svg";
import reviewActiveIcon from "../../assets/deselectedActionIcons/Review-Outline.svg";
import newTemplateActiveIcon from "../../assets/deselectedActionIcons/NewTemplate-Outline.svg";
import newTemplateGreyIcon from "../../assets/inactiveActionIcons/NewTemplate-Grey.svg";
import uploadSuccessIcon from "../../assets/UploadSuccess.svg";
import uploadFailureIcon from "../../assets/UploadFailure.svg";

import { useUserStore } from "../../store/Store";

import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import RecieptErrorPopup from "../../components/ErrorSuccessPopUp/RecieptErrorPopup";
import { AppCommonContext } from "../../App";
import { instrumentTypes } from "../Issuance/instrumentTypes";
import { DATE_FORMATS } from "../../constants";
import { convertDateTime } from "../../utils/dateUtils";

/*Formatting TemplateName in Templates tab*/
export const TemplateNameFormat = (type) => {
  let templateName = [];
  if (type === "KnockoutWarrantCall") {
    templateName = "Knockout Warrant Call";
  }
  if (type === "KnockoutWarrantPut") {
    templateName = "Knockout Warrant Put";
  }
  if (type === "BonusCertificatePhysicalEurop") {
    templateName = "Bonus Certificate Physical European";
  }
  if (type === "BonusCertificateCashAndOrPhysicalEurop") {
    templateName = "Bonus Certificate Cash and/or Physical European";
  }
  if (type === "BonusCertificateCashEurop") {
    templateName = "Bonus Certificate Cash European";
  }
  if (type === "ZeroCouponBond") {
    templateName = "Zero-Coupon Bond";
  }
  if (type === "FixedRateBond") {
    templateName = "Fixed-Rate Bond";
  }
  if (type === "CommercialPaper") {
    templateName = "Commercial Paper";
  }
  return templateName;
};
/*Formatting PayoffCalculation in Templates tab*/
export const PayoffCalculationFormat = (payoffCalc) => {
  let payoffCalculation = [];
  if (payoffCalc === "KnockoutWarrantCalculation") {
    payoffCalculation = "Knockout Warrant Calculation";
  }
  if (payoffCalc === "BonusClassicCertificateCalculation") {
    payoffCalculation = "Bonus Classic Certificate Calculation";
  }
  if (payoffCalc === "ZeroCouponCalculation") {
    payoffCalculation = "Zero Coupon Calculation";
  }
  if (payoffCalc === "InterestRateFixedCalculation") {
    payoffCalculation = "Interest Rate Fixed Calculation";
  }
  if (payoffCalc === "NoInterestRateCalculation") {
    payoffCalculation = "No Interest Rate Calculation";
  }
  return payoffCalculation;
};
/*
 * This array is for search dropdown list
 */
const optionsList = [
  { value: "select", label: "" },
  { value: "status", label: "Status" },
  { value: "type", label: "Template Type" },
  { value: "name", label: "Template Name" },
  { value: "tempId", label: "Template ID" },
  { value: "issuer", label: "Issuer Name" },
  { value: "payOff", label: "Payoff" },
  { value: "prospectus", label: "Prospectus Name" },
  { value: "creation", label: "Creation Date" },
];

/*
 * The below two objects are being used in new filter functionality
 */
const searchList = {
  select: "",
  status: "",
  type: "",
  name: "",
  tempId: "",
  issuer: "",
  payoff: "",
  prospectus: "",
  creation: "",
};
const defaultOptions = {
  select: [],
  status: [],
  type: [],
  name: [],
  tempId: [],
  issuer: [],
  payoff: [],
  prospectus: [],
  creation: [],
};
/*
 * This array is for template type dropdown in New template dialog box
 */
export const TemplateTypeList = instrumentTypes
  .sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  })
  .reduce((acc, currentOption) => {
    const itemWithLabelExists = acc.find(
      (item) => item.label === currentOption.label
    );
    if (!itemWithLabelExists) {
      acc.push(currentOption);
    }
    return acc;
  }, []);

export const getInstrumentTypeLabel = (value) => {
  const instrumentLabel = instrumentTypes.find(
    (item) => item.value === value
  )?.label;
  return instrumentLabel ? instrumentLabel : value;
};
const columns = [
  { id: "select", label: "", width: "20px" },
  { id: "status", label: "Status", width: "100px" },
  { id: "type", label: "Template Type", width: "140px" },
  { id: "name", label: "Template Name", width: "160px" },
  { id: "tempId", label: "Template ID", width: "160px" },
  { id: "issuer", label: "Issuer Name", width: "140px" },
  { id: "prospectus", label: "Prospectus Name", width: "220px" },
  { id: "payoff", label: "Payoff", width: "80px" },
  { id: "creation", label: "Creation Date", width: "116px", type: "date" },
];

/*
 * ul is used for pagination styling and dialogPaper is used for New Issuance dialog box styling
 */
const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
  dialogPaper: {
    width: "450px",
    height: "380px",
  },
}));

/*
 * Below represents the styling of Tooltip used in Template Listing
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function Templates() {
  const [userState] = useUserStore();
  const [selectedOption, setselectedOption] = React.useState();
  const [data, setData] = React.useState([]);
  const [tempList, setTempList] = React.useState([]);
  const [searched, setSearched] = React.useState("");
  const [order, setOrder] = React.useState("asc"); //default sorting set to ascending order
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showPDFmsg, setShowPDFmsg] = React.useState(false);
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [uploadedfiles, setuploadedfiles] = React.useState([]);
  const [uploadedPdffiles, setuploadedPdffiles] = React.useState([]);
  const [flag, setflag] = React.useState("");
  const [flagForPDF, setflagForPDF] = React.useState("");
  const [flagPopUp, setflagPopUp] = React.useState("");
  const [respText, setrespText] = React.useState("");
  //const [respTextForPdf, setrespTextForPdf] = React.useState("");
  const [respTextBackend, setRespTextBackend] = React.useState("");
  const [tempFileData, setTempFileData] = React.useState();
  const [tempFileDataForPdf, setTempFileDataForPdf] = React.useState();
  const [templateObj, setTemplateObj] = React.useState({});
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [previousPage, setPreviousPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [recieptResponseText, setRecieptResponseText] = React.useState("");
  const [showRecieptPopUp, setShowRecieptPopUp] = React.useState(false);
  //const [continueUpdate, setContinueUpdate] = React.useState(false);
  const [newTemplateOpen, setNewTemplateOpen] = React.useState(false);
  const [revokeTemplate, setRevokeTemplateOpen] = React.useState(false);
  //const [updateTemplate, setUpdateTemplateOpen] = React.useState(false);
  const [finishReviewOpen, setFinishReviewOpen] = React.useState(false);
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [selectedTemplateTypeValue, setSelectedTemplateTypeValue] =
    React.useState({});
  const [selectedPayoffValue, setSelectedPayoffValue] = React.useState({});
  const [templateName, setTemplateName] = React.useState("");
  const [comment, setComment] = React.useState("");
  //const [uploadedPdf, setUploadedPdf] = React.useState([]);
  //const [pdfFileData, setPdfFileData] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [isinData, setIsinData] = React.useState("");
  const [eventTransactionData, setEventTransactionData] = React.useState("");
  const [applyFilter, setApplyFilter] = React.useState(false);
  const [optionsArray, setOptionsArray] = React.useState({});
  const [selectedColumnOption, setSelectedColumnOption] =
    React.useState(defaultOptions);
  const [searchArray, setSearchArray] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(searchList);
  const [respTextForPdfPopup, setRespTextForPdfPopup] = React.useState("");
  const [showPdfPopUp, setShowPdfPopUp] = React.useState(false);
  const [flagPdfPopUp, setflagPdfPopUp] = React.useState("");
  //const [contractIdForReview, setContractIdForReview] = React.useState("")
  const [auditData, setAuditData] = React.useState([]);
  const [templateApiFlag, setTemplateApiFlag] = React.useState("firstApiCall"); //checking api response
  const { state, dispatch } = useContext(AppCommonContext);

  useEffect(() => {
    const templatesTimer = process.env.REACT_APP_TEMPLATES_TIMER
      ? process.env.REACT_APP_TEMPLATES_TIMER
      : 30;
    let user = sessionStorage.getItem("user");
    //checks if api is called for first time
    if (templateApiFlag === "firstApiCall") {
      getTemplate(); //calling this fn to load templates as soon as component renders
    }
    if (user !== "Clearstream") {
      const intervalID = setInterval(() => {
        //checks if api response is recieved,success/failure
        if (templateApiFlag === "successApiCall") {
          getTemplate();
        }
      }, templatesTimer * 1000);
      return () => {
        clearInterval(intervalID);
      };
    } else {
      const intervalID = setInterval(() => {
        //checks if api response is recieved,success/failure
        if (templateApiFlag === "successApiCall") {
          getTemplate();
        }
      }, templatesTimer * 1000);
      return () => {
        clearInterval(intervalID);
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateApiFlag]);

  /*
   * This method will fetch all templates
   */
  const getTemplate = () => {
    let jwtToken = sessionStorage.getItem("jwtToken");
    let searchFlag = sessionStorage.getItem("search");
    setTemplateApiFlag("resetApiCall"); //setting api response flag
    if (searchFlag === null || searchFlag === "false") {
      axios
        .get("/di/templates", {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((resp) => {
          let templates = [];

          for (let i = 0; i < resp.data?.length; i++) {
            let temp = resp.data[i];
            let templateid = resp.data[i].templateId;
            let issuer = resp.data[i].issuerName;
            let obj = {
              name: temp.templateName,
              payoff: (temp.payoffCalculation = PayoffCalculationFormat(
                temp.payoffCalculation
              )),
              issuer: temp.issuerName,
              tempId: templateid,
              templateType: temp.templateType,
              comment: temp.comment,
              type: (temp.templateType = TemplateNameFormat(temp.templateType)),
              prospectus: temp.baseProspectusName,
              creation: moment(temp.creationDate).format(DATE_FORMATS.DATE),
              status: temp.status === "Cancelled" ? "Inactive" : temp.status,
              partyIssuer: issuer,
              partyId: temp.partyId,
              key: "temp-" + i,
            };
            templates.push(obj);
          }
          setData(templates);
          setTempList(templates);
          createFilterOptions(templates);
          setTemplateApiFlag("successApiCall"); //setting api response flag
        })
        .catch((error) => {
          let errRes = error.response.data.message
            ? "XML Error : " + error.response.data.message
            : "XML Error : " + catchBlockError(error);
          setRespTextBackend(errRes);
          setTemplateApiFlag("successApiCall"); //setting api response flag
          setShowPopUp(true);
          setuploadedfiles([]);
          setflagPopUp("error");
        });
    }
  };
  /*
   * Updating options array for filter search
   */
  const createFilterOptions = (templates) => {
    let columnNames = templates.length !== 0 ? Object.keys(templates[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(templates, elem);
    });
    setOptionsArray(optionsArrList);
  };
  /*
   * Updating option lists of columns dynamically with available values
   */
  const getOptionList = (issuances, columnName) => {
    let arr = [];
    issuances.map((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
      return arr;
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({
        value: elem,
        label:
          columnName === "issuer"
            ? elem
                .substring(11)
                .replace(/(([A-Z])[a-z])/g, " $1")
                .replace(/:.*/, "")
                .trimStart()
            : elem.replace(/([a-z])([A-Z])/g, "$1 $2"),
      });
      return returnList;
    });
    return returnList;
  };

  /* const getISINValue = (xml) => {
    if(xml.getElementsByTagName('IDS')[0]?.childNodes[1]?.nodeName === 'ISIN'){
      return xml.getElementsByTagName('IDS')[0].childNodes[1].innerHTML;
    } else if(xml.getElementsByTagName('ns0:IDS')[0]?.childNodes[1]?.nodeName === 'ns0:ISIN'){
      return xml.getElementsByTagName('ns0:IDS')[0].childNodes[1].innerHTML;
    } else{
      return '';
    }
  } */
  /*
   * Validating uploaded excel file in UI
   */
  /*const filePathSet = (event) => {
    event.preventDefault();
    let fileData = event.target.files;

    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length
      );
      let obj = {
        fileName: fileData[x].name,
        file: fileData[x],
      };
      let files = [];
      files.push(obj);
      setuploadedfiles(files);
      if (fileExtension.toLowerCase() === "xml") {
        var reader = new FileReader();
        reader.readAsText(event.target.files[0]);
        reader.onloadend = evt => {                         //code for fetching ISIN from XML
          const readerData = evt.target.result;
          const parser = new DOMParser();
          const xml = parser.parseFromString(readerData, 'text/xml');
          const isin = getISINValue(xml);
          setIsinData(isin);
          const eventTrxId = xml.getElementsByTagName('EVENT_TRANSACTION_ID').length!==0 ? (xml.getElementsByTagName('EVENT_TRANSACTION_ID')[0].childNodes.length!==0 ? (xml.getElementsByTagName('EVENT_TRANSACTION_ID')[0].childNodes[0].nodeValue? xml.getElementsByTagName('EVENT_TRANSACTION_ID')[0].childNodes[0].nodeValue : ""):"") :(xml.getElementsByTagName('ns0:EVENT_TRANSACTION_ID').length!==0 ? (xml.getElementsByTagName('ns0:EVENT_TRANSACTION_ID')[0].childNodes.length!==0? (xml.getElementsByTagName('ns0:EVENT_TRANSACTION_ID')[0].childNodes[0].nodeValue ? xml.getElementsByTagName('ns0:EVENT_TRANSACTION_ID')[0].childNodes[0].nodeValue : "") : "") : "" )  ;
          setEventTransactionData(eventTrxId)
        }
        setrespText("");
        setflag("success");
        setShowPopUp(false);
      } else {
        setrespText(
          "Error! Selected file is not supported. Please choose another file."
        );
        setflag("error");
        setShowPopUp(false);
      }
      setShow(true);
    }
    setTempFileData({ fileData });                       //storing XML file data
  }; */

  /*
   * Validating uploaded PDF file in UI for Issuance creation
   */
  /* const filePathSetForPDF = (event) => {
    event.preventDefault();
    let fileData = event.target.files;

    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length
      );
      let obj = {
        fileName: fileData[x].name,
        file: fileData[x],
      };
      let files = [];
      files.push(obj);
      setuploadedPdffiles(files);
      if (fileExtension.toLowerCase() === "pdf") {
        //setrespTextForPdf("");
        setflagForPDF("success");
        setShowPopUp(false);
      } else {
        // setrespTextForPdf(
        //   "Error! Selected file is not supported. Please choose another file."
        // );
        setflagForPDF("error");
        setShowPopUp(false);
      }
      setShowPDFmsg(true);
    }
    setTempFileDataForPdf({ fileData });                   //storing PDF file data
  }; */

  /*
   * Validating uploaded PDF file for template creation in UI
   */
  // const uploadNewTemplate = (event) => {
  //   event.preventDefault();
  //   let fileData = event.target.files;

  //   for (let x = 0; x < fileData.length; x++) {
  //     let fileExtension = fileData[x].name.substring(
  //       fileData[x].name.lastIndexOf(".") + 1,
  //       fileData[x].name.length
  //     );
  //     if (fileExtension.toLowerCase() === "pdf") {
  //       let obj = {
  //         fileName: fileData[x].name,
  //         file: fileData[x],
  //       };
  //       let files = uploadedPdf;
  //       files.push(obj);
  //       setUploadedPdf(files);
  //       setShow(false);
  //       setrespText("");
  //       setflag("success");
  //       setShowPopUp(false);
  //     } else {
  //       setUploadedPdf([]);
  //       setrespText(
  //         "Error! Selected file is not supported. Please choose another file."
  //       );
  //       setflag("error");
  //       setShow(true);
  //       setShowPopUp(false);
  //     }
  //   }
  //   setPdfFileData({ fileData });                 //storing PDF file data
  // };

  /*
   * This method will fetch the receipt message
   */

  useEffect(() => {
    if (state.error) {
      setRespTextBackend(state.error);
      setShowPopUp(true);
      setflagPopUp("error");
      dispatch({ type: "RESET_RECEIPT_RETRY_COUNT" });
    } else if (state.response) {
      setShowPopUp(false); //closing success/error popup for issunace upload
      setRecieptResponseText(state.response); //setting receipt response
      setShowRecieptPopUp(true);
      dispatch({ type: "RESET_RECEIPT_RETRY_COUNT" });
    }
  }, [state.error, state.response]);

  /*
   * This method will send the request for creating new issuance from issuer
   */
  /* const upload = (event) => {
    closeDialog()
    setShowPopUp(true);
    //let user = sessionStorage.getItem("user");

    let formData = new FormData();
    formData.append("diXMLParamFile", uploadedfiles[0].file);

    let pdfFormData = new FormData();
    pdfFormData.append("pdfFile", uploadedPdffiles[0].file);
    pdfFormData.append("isin", isinData);
    pdfFormData.append("eventTransactionId", eventTransactionData);
    let jwtToken = sessionStorage.getItem("jwtToken");

    axios
      .post("/di/issuance", formData, {                 //formData consisting XML file and Template ID sent as params
        headers: {
          "Authorization": `Bearer ${jwtToken}`
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRespTextBackend(res.data.message);
          setShowPopUp(true);
          setuploadedfiles([]);
          setflagPopUp("success");
          dispatch({ type: 'TRIGGER_RECEIPT', payload: isinData });
          setTimeout(() => {
            setShowPopUp(false);
            setRespTextBackend("");
          }, 8000);

        } else {
          let errRes = "XML Error : " + res.data.message;
          setRespTextBackend(errRes);
          setShowPopUp(true);
          setuploadedfiles([]);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        let errRes = error.response.data.message ? "XML Error : " + error.response.data.message : "XML Error : " + catchBlockError(error)
        setRespTextBackend(errRes);
        setShowPopUp(true);
        setuploadedfiles([]);
        setflagPopUp("error");
      });

    axios
      .post("/di/pdf/terms-conditions", pdfFormData, {           //pdfFormData consisting PDF file and ISIN sent as params
        headers: {
          "Authorization": `Bearer ${jwtToken}`
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setShowPopUp(true);
          setuploadedPdffiles([]);
        } else {
          let errRes = "PDF Error : " + res.data.message;
          setRespTextForPdfPopup(errRes)
          setShowPdfPopUp(true)
          setuploadedPdffiles([]);
          setflagPdfPopUp("error");
        }
      })
      .catch((error) => {
        let errRes = error.response.data.message ? "PDF Error : " + error.response.data.message : "PDF Error : " + catchBlockError(error);
        setRespTextForPdfPopup(errRes)
        setShowPdfPopUp(true)
        setuploadedPdffiles([]);
        setflagPdfPopUp("error");
      });
  }; */

  /*
   * This method will send the request for creating new template from issuer
   */
  const uploadTemplate = (event) => {
    closeDialog();
    let user = sessionStorage.getItem("user");
    let formData = new FormData();
    formData.append("templateName", templateName);
    formData.append("templateType", selectedTemplateTypeValue?.value);
    formData.append("payoffCalculation", selectedPayoffValue?.value);
    formData.append("comments", comment);
    let userId = sessionStorage.getItem("userId");
    formData.append("userId", userId);

    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .post("/di/template", formData, {
        //formData consisting Template Name, Template Type, Payoff Calculation, Comments and User ID sent as params
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          issuer: user,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRespTextBackend(
            "Request for a new template has been successfully submitted."
          );
          setShowPopUp(true);
          //setUploadedPdf([]);
          setComment("");
          setTemplateName("");
          setSelectedTemplateTypeValue({});
          setSelectedPayoffValue({});
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setRespTextBackend("");
          }, 10000);
        } else {
          setRespTextBackend(res.data.message);
          setShowPopUp(true);
          //setUploadedPdf([]);
          setComment("");
          setTemplateName("");
          setSelectedTemplateTypeValue({});
          setSelectedPayoffValue({});
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setRespTextBackend(error.response.data.message);
        setShowPopUp(true);
        //setUploadedPdf([]);
        setComment("");
        setTemplateName("");
        setSelectedTemplateTypeValue({});
        setSelectedPayoffValue({});
        setflagPopUp("error");
      });
  };

  /*
   * This method will send the request for updating the template (Currently functionally inactive)
   */
  /* const amendTemplate = () => {
    closeDialog()
    let user = sessionStorage.getItem("user");
    let formData = new FormData();
    formData.append("templatePdf", uploadedPdf[0].file);
    formData.append("templateId", templateObj.tempId);
    formData.append("comments", comment);
    let userId = sessionStorage.getItem("userId");

    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .post("/di/template/update", formData, {         //formData consisting PDF file, Template ID and Comments sent as params
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRespTextBackend("Request to update " + templateObj.name + " has been successfully submitted.");
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setRespTextBackend("");
          }, 10000);
        } else {
          setRespTextBackend("Template updation failed. Please try again after some time.");
          setShowPopUp(true);
          setflagPopUp("error");
        }
        setUploadedPdf([]);
        setComment("")
      })
      .catch((error) => {
        setRespTextBackend("Template updation failed. Please try again after some time.");
        setShowPopUp(true);
        setUploadedPdf([]);
        setComment("")
        setflagPopUp("error");
      });
  } */

  /*
   * This method is used for sorting Template Listing column data in ascending and descending order
   */
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /*
   * This method is used for selecting a row from Template Listing Table
   */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    data.forEach((row) => {
      if (row.key === newSelected[0]) {
        setTemplateObj(row);
      }
    });

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /*
   * This method is used for Cancelling search performed on Template Listing Table data
   */
  const cancelSearch = () => {
    setPage(previousPage);
    setselectedOption(false);
    sessionStorage.setItem("search", "false");
    setData(tempList);
    setSearched("");
  };

  /*
   * This method is used for performing page change in Pagination
   */
  const onPageChange = (event, page) => {
    setPage(page - 1);
    setPreviousPage(page - 1);
  };

  /*
   * Search with single column
   */
  const requestSearch = (value) => {
    let column = selectedOption.value;
    let newData = [...tempList];
    newData = tempList.filter((row) => {
      sessionStorage.setItem("search", "true");
      return row[column].toLowerCase().includes(value.toLowerCase());
    });
    setPage(0);
    setData(newData);
  };

  /* For clearing payoff on reselecting template Type after Payoff*/
  const onHandleChangeTemplateType = (option) => {
    if (option.value !== selectedTemplateTypeValue.value) {
      // clearing payoff
      setSelectedPayoffValue({});
    }
    setSelectedTemplateTypeValue(option);
  };

  /* Options for Dropdown list of Payoff Calculation*/
  const PayoffList = () => {
    var payoffOptions = [];
    if (
      selectedTemplateTypeValue?.value === "BonusCertificatePhysicalEurop" ||
      selectedTemplateTypeValue?.value ===
        "BonusCertificateCashAndOrPhysicalEurop" ||
      selectedTemplateTypeValue?.value === "BonusCertificateCashEurop"
    ) {
      payoffOptions = [
        { value: "BonusClassicCertificateCalculation", label: "Bonus Classic" },
      ];
    }
    if (selectedTemplateTypeValue?.value === "KnockoutWarrantCall") {
      payoffOptions = [{ value: "KnockoutWarrantCalculation", label: "KO" }];
    }
    if (selectedTemplateTypeValue?.value === "KnockoutWarrantPut") {
      payoffOptions = [{ value: "KnockoutWarrantCalculation", label: "KO" }];
    }
    if (selectedTemplateTypeValue?.value === "ZeroCouponBond") {
      payoffOptions = [
        { value: "ZeroCouponCalculation", label: "Zero Coupon" },
      ];
    }
    if (selectedTemplateTypeValue?.value === "FixedRateBond") {
      payoffOptions = [
        { value: "InterestRateFixedCalculation", label: "Interest Rate Fixed" },
      ];
    }
    if (selectedTemplateTypeValue?.value === "CommercialPaper") {
      payoffOptions = [
        { value: "NoInterestRateCalculation", label: "No Interest Rate" },
      ];
    }
    return payoffOptions;
  };

  /*
   * This method will send the request for revoke
   */
  const revokeRequestedTemplate = () => {
    closeDialog();
    let formData = new FormData();
    formData.append("templateId", templateObj.tempId);
    formData.append("comment", templateObj.comment);
    formData.append("damlpartyname", templateObj.partyId);

    let jwtToken = sessionStorage.getItem("jwtToken");
    if (templateObj.status === "WaitingForApproval") {
      axios
        .post("/di/template/manage/revoke", formData, {
          //formData consisting Template ID, Comments and DAML Party Name sent as params
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setRespTextBackend(
              "Request to create " + templateObj.name + " has been cancelled."
            );
            setShowPopUp(true);
            setflagPopUp("success");
            setTimeout(() => {
              setShowPopUp(false);
              setRespTextBackend("");
            }, 10000);
          } else {
            setRespTextBackend(
              templateObj.name + " is under review and cannot be cancelled."
            );
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setRespTextBackend(error.response.data.message);
          setShowPopUp(true);
          setflagPopUp("error");
        });
    } else if (templateObj.status === "Inactive") {
      axios
        .post("/di/template/manage/delete", formData, {
          //formData consisting Template ID, Comments and DAML Party Name sent as params
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setRespTextBackend(
              templateObj.name + " has been deleted successfully."
            );
            setShowPopUp(true);
            setflagPopUp("success");
            setTimeout(() => {
              setShowPopUp(false);
              setRespTextBackend("");
            }, 10000);
          } else {
            setRespTextBackend(response.data.message);
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setRespTextBackend(error.response.data.message);
          setShowPopUp(true);
          setflagPopUp("error");
        });
    } else {
      axios
        .post("/di/template/manage/cancel", formData, {
          //formData consisting Template ID, Comments and DAML Party Name sent as params
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setRespTextBackend(
              templateObj.name + " has been cancelled successfully."
            );
            setShowPopUp(true);
            setflagPopUp("success");
            setTimeout(() => {
              setShowPopUp(false);
              setRespTextBackend("");
            }, 10000);
          } else {
            setRespTextBackend(response.data.message);
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setRespTextBackend(error.response.data.message);
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  /*
   * This method will check active issuances related to template
   */
  const checkActiveIssuance = () => {
    let formData = new FormData();
    formData.append("templateName", templateObj.name);
    let url = "/di/template/active/issuances/" + templateObj.name;

    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        if (response.data === true) {
          setIsActive(true);
          if (templateObj.status === "Inactive") {
            openDialog("revoke");
          } else {
            openDialog("update");
          }
        } else {
          if (templateObj.status === "Active") {
            setIsActive(false);
            openDialog("update");
          } else {
            setIsActive(false);
            openDialog("revoke");
          }
        }
      })
      .catch((error) => {
        let errRes = error.response.data.message
          ? error.response.data.message
          : catchBlockError(error);
        setRespTextBackend(errRes);
        setShowPopUp(true);
        //setUploadedPdf([]);
        setComment("");
        setflagPopUp("error");
      });
  };

  /*
   * This method is for opening different dialog boxes based on the parameter
   */
  const openDialog = (para) => {
    if (para === "newIssuance") {
      setOpen(true);
    } else if (para === "newTemplate") {
      setNewTemplateOpen(true);
    } else if (para === "finishReview") {
      setFinishReviewOpen(true);
    } else if (para === "history") {
      setHistoryOpen(true);
    } else if (para === "revoke") {
      setRevokeTemplateOpen(true);
    }
    //else if (para === "update") { setUpdateTemplateOpen(true) }
  };

  /*
   * This method is for closing different dialog boxes
   */
  const closeDialog = () => {
    setflag("");
    setShow(false);
    setShowPDFmsg(false);
    setuploadedfiles([]);
    setuploadedPdffiles([]);
    //setUploadedPdf([]);
    setComment("");
    setTemplateName("");
    setSelectedTemplateTypeValue({});
    setSelectedPayoffValue({});
    setOpen(false);
    setFinishReviewOpen(false);
    setNewTemplateOpen(false);
    setHistoryOpen(false);
    setRevokeTemplateOpen(false);
    //setUpdateTemplateOpen(false)
    setSelected([]);
    //setContinueUpdate(false)
    setIsActive(false);
  };

  /*
   * This method is for updating Form fields with our set value
   */
  const changeFieldValue = (event) => {
    const id = [event.target.id][0];
    const value = event.target.value;

    if (id === "templateName") {
      setTemplateName(value);
    } else if (id === "comment") {
      setComment(value);
    }
  };

  /*
   * This method is to start the review process of a template creation request
   */
  const reviewTemplate = () => {
    let formData = new FormData();
    formData.append("templateId", templateObj.tempId);
    formData.append("comment", "");
    formData.append("damlpartyname", templateObj.partyId);
    //let userId = sessionStorage.getItem("userId");
    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .post("/di/template/manage/review", formData, {
        //formData consisting Template Id, Comments and DAML Party Name sent as params
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //setContractIdForReview(response.data.contractId)
          openDialog("finishReview");
        } else {
          setRespTextBackend(response.data.message);
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setRespTextBackend(error.response.data.message);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  /*
   * This method will send the request for rejecting template request
   */
  const rejectRequestedTemplate = () => {
    closeDialog();
    let formData = new FormData();
    formData.append("templateId", templateObj.tempId);
    formData.append("comment", templateObj.comment);
    formData.append("damlpartyname", templateObj.partyId);

    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .post("/di/template/manage/reject", formData, {
        //formData consisting Template ID, Comment and DAML Party Name sent as params
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRespTextBackend(
            templateObj.name + " has been successfully rejected."
          );
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setRespTextBackend("");
          }, 10000);
        } else {
          setRespTextBackend(response.data.message);
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setRespTextBackend(error.response.data.message);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  /*
   * This method will send the request for approving template request
   */
  const approveRequestedTemplate = () => {
    closeDialog();
    let formData = new FormData();
    formData.append("templateId", templateObj.tempId);
    formData.append("comment", comment);
    formData.append("damlpartyname", templateObj.partyId);

    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .post("/di/template/manage/approve", formData, {
        //formData consisting of Template ID, Comment, DAML Party is sent as params
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRespTextBackend(
            templateObj.name + " has been successfully approved."
          );
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setRespTextBackend("");
          }, 10000);
        } else {
          setRespTextBackend(response.data.message);
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setRespTextBackend(error.response.data.message);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  /*
   * This method will fetch audit log for template
   */
  const getAuditHistory = () => {
    let jwtToken = sessionStorage.getItem("jwtToken");
    const params = {
      templateId: templateObj.tempId,
    };
    axios
      .get("/di/audit", {
        params, //params consisting Template ID sent
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        let auditArray = [];
        let respHistory = response.data.auditHistory;
        for (let i = 0; i < respHistory.length; i++) {
          let obj = {
            timestamp: convertDateTime(
              respHistory[i].timestamp,
              DATE_FORMATS.DATE_AND_TIME
            ),
            action:
              templateObj.name +
              " " +
              respHistory[i].auditAction
                .substring(6)
                .replace(/(([A-Z])[a-z])/g, " $1"),
            comment: respHistory[i].comments
              ? "Comment: " + respHistory[i].comments
              : "Comment: None",
            party:
              "By " +
              respHistory[i].party +
              " -User ID: " +
              respHistory[i].userId,
          };
          auditArray.push(obj);
        }
        setAuditData(auditArray);
      });
    openDialog("history");
  };

  const user = sessionStorage.getItem("user");

  /*
   * This method is for closing reciept error msg popup
   */
  const closeRecieptErrorPopup = () => {
    setShowRecieptPopUp(false);
    setRecieptResponseText("");
  };

  /*
   * This method is for closing error and success msg popup
   */
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setRespTextBackend("");
  };
  /*
   * This method is for closing error and success msg pdf popup
   */
  const closeErrorSuccessPdfPopup = () => {
    setShowPdfPopUp(false);
    setRespTextForPdfPopup("");
  };
  /*
   * This method will refresh the filters by removing all applied search criteria
   */
  const removeAppliedFilter = () => {
    setSelectedColumnOption({
      select: [],
      status: [],
      type: [],
      name: [],
      tempId: [],
      issuer: [],
      payoff: [],
      prospectus: [],
      creation: [],
    });
    setSearchArray([]);
  };
  /*
   * Filter with multiple column
   */
  const newRequestSearch = (event) => {
    sessionStorage.setItem("search", "true");

    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }
    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue); //Updating searchValue object with column id and search value
    let newData = [...tempList];
    searchArray.forEach((column) => {
      //Iterate each column and check if any filter applied to that column and update the table data with the search
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;
      tempNewData = newData.filter((row) => {
        sessionStorage.setItem("search", "true");

        if (column === "creation") {
          if (value !== "") {
            return row[column] === value;
          } else {
            return true;
          }
        } else if (column === "tempId") {
          return row[column].toLowerCase().includes(value.toLowerCase());
        } else {
          if (value.length === 0) {
            return true;
          } else {
            return value.includes(row[column]);
          }
        }
      });
      newData = tempNewData;
    });
    setPage(0);
    setData(newData);
  };
  return (
    <React.Fragment>
      <StyledContainer>
        <div>
          {recieptResponseText && showRecieptPopUp ? (
            <div>
              <div id="temp_popup" className="popup">
                <RecieptErrorPopup
                  data-testid="errorPopup"
                  recieptErrorPopupText={recieptResponseText}
                  recieptErrorVisibility={showRecieptPopUp}
                  handleClose={() => closeRecieptErrorPopup()}
                />
              </div>
              <div>
                {respTextBackend && showPopUp && (
                  <div id="temp_popup2" className="popup2">
                    {flagPopUp !== "error" ? (
                      <SuccessPopup
                        successPopupText={respTextBackend}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    ) : (
                      <ErrorPopup
                        errorPopupText={respTextBackend}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    )}
                  </div>
                )}
              </div>
              <div>
                {respTextForPdfPopup && showPdfPopUp && (
                  <div id="temp_popup3_pdf" className="popup3">
                    {flagPdfPopUp !== "error" ? (
                      <SuccessPopup
                        successPopupText={respTextForPdfPopup}
                        popupVisibility={showPdfPopUp}
                        handleClose={() => closeErrorSuccessPdfPopup()}
                      />
                    ) : (
                      <ErrorPopup
                        errorPopupText={respTextForPdfPopup}
                        popupVisibility={showPdfPopUp}
                        handleClose={() => closeErrorSuccessPdfPopup()}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div>
                {respTextBackend && showPopUp && (
                  <div id="temp_popup_receipt" className="popup">
                    {flagPopUp !== "error" ? (
                      <SuccessPopup
                        successPopupText={respTextBackend}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    ) : (
                      <ErrorPopup
                        errorPopupText={respTextBackend}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    )}
                  </div>
                )}
              </div>
              <div>
                {respTextForPdfPopup && showPdfPopUp && (
                  <div id="temp_popup_pdfResp" className="popup3">
                    {flagPdfPopUp !== "error" ? (
                      <SuccessPopup
                        successPopupText={respTextForPdfPopup}
                        popupVisibility={showPdfPopUp}
                        handleClose={() => closeErrorSuccessPdfPopup()}
                      />
                    ) : (
                      <ErrorPopup
                        errorPopupText={respTextForPdfPopup}
                        popupVisibility={showPdfPopUp}
                        handleClose={() => closeErrorSuccessPdfPopup()}
                      />
                    )}
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <StyledHeader>
          <Typography id="temp_heading" variant="h2">
            Templates
          </Typography>
          <Grid container id="temp_gridContainer" className="gridContainer">
            <Grid container xs={3}>
              <Grid item xs={3}>
                <Typography variant="h5" id="temp_pT-8" className="pT-8">
                  Search by:
                </Typography>
              </Grid>
              <Grid item xs={9} id="temp_pL-10" className="pL-10">
                <Dropdown
                  isClearable={false}
                  placeholder="Please Select"
                  onChange={(option) => {
                    setselectedOption(option);
                  }}
                  id="searchOption1"
                  options={optionsList}
                  value={selectedOption}
                  disabled={applyFilter}
                />
              </Grid>
            </Grid>
            <Grid item xs={3} id="temp_searchBar_grid" className="pL-10">
              <Searchbar
                id="temp_searchBar"
                className="searchBar"
                data-testid="searchBar_icon"
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                disabled={!selectedOption}
              />
            </Grid>
            <Grid item xs={6} align="right">
              {user !== "Clearstream" &&
                (selected.length !== 1 ||
                templateObj.status !== "Active" ||
                !userState.userRoleRight.includes("View_New_Issuance") ? (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_newIssuance_toolTip" variant="h5">
                          New Issuance
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      id="temp_create_greyIcon"
                      src={createGreyIcon}
                      alt={"create"}
                      className="greyIcon"
                    />
                  </HtmlTooltip>
                ) : (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          id="temp_newIssuance_htmlToolTip"
                          variant="h5"
                        >
                          New Issuance
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      id="temp_create_activeIcon"
                      src={createActiveIcon}
                      alt={"create"}
                      data-testid="createIssuanceIcon"
                      className="activeIcon"
                      onClick={() => openDialog("newIssuance")}
                    />
                  </HtmlTooltip>
                ))}
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="temp_download_toolTip" variant="h5">
                      Download
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={downloadGreyIcon}
                  id="temp_download_greyIcon"
                  alt={"download"}
                  className="greyIcon"
                />
              </HtmlTooltip>
              {selected.length !== 1 ||
              !userState.userRoleRight.includes("View_Template_History") ? (
                <>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_history_toolTip" variant="h5">
                          History
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={historyGreyIcon}
                      id="temp_history_greyIcon"
                      alt={"history"}
                      className="greyIcon"
                    />
                  </HtmlTooltip>
                </>
              ) : (
                <>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_history_htmlToolTip" variant="h5">
                          History
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={historyActiveIcon}
                      id="temp_history_activeIcon"
                      alt={"history"}
                      className="activeIcon"
                      data-testid="auditHistoryIcon"
                      onClick={() => getAuditHistory()}
                    />
                  </HtmlTooltip>
                </>
              )}
              {user !== "Clearstream" &&
                (selected.length !== 0 ||
                !userState.userRoleRight.includes("View_New_Template") ? (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_newTemplate_toolTip" variant="h5">
                          New Template
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={newTemplateGreyIcon}
                      id="temp_newTemplate_greyicon"
                      alt={"newTemplate"}
                      className="greyIcon"
                    />
                  </HtmlTooltip>
                ) : (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          id="temp_newTemplate_htmlToolTip"
                          variant="h5"
                        >
                          New Template
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={newTemplateActiveIcon}
                      id="temp_newTemplate_activeIcon"
                      data-testid="createTemplateIcon"
                      alt={"newTemplate"}
                      className="activeIcon"
                      onClick={() => openDialog("newTemplate")}
                    />
                  </HtmlTooltip>
                ))}
              {user === "Clearstream" &&
                (selected.length !== 1 ||
                  (templateObj.status !== "WaitingForApproval" &&
                    templateObj.status !== "ApprovalInProgress") ||
                  !userState.userRoleRight.includes(
                    "View_Template_Review"
                  )) && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_review_toolTip" variant="h5">
                          Review
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      id="temp_review_greyIcon"
                      src={reviewGreyIcon}
                      alt={"review"}
                      className="greyIcon"
                    />
                  </HtmlTooltip>
                )}

              {userState.userRoleRight.includes("View_Template_Review") &&
                user === "Clearstream" &&
                selected.length === 1 &&
                templateObj.status === "WaitingForApproval" && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_startReview_toolTip" variant="h5">
                          Start Review
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={reviewActiveIcon}
                      id="temp_startReview_activeIcon"
                      data-testid="review_icon"
                      alt={"startReview"}
                      className="activeIcon"
                      onClick={() => reviewTemplate()}
                    />
                  </HtmlTooltip>
                )}

              {userState.userRoleRight.includes("View_Template_Review") &&
                user === "Clearstream" &&
                selected.length === 1 &&
                templateObj.status === "ApprovalInProgress" && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_finishReview_toolTip" variant="h5">
                          Finish Review
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={reviewActiveIcon}
                      id="temp_finishReview_activeIcon"
                      alt={"finishReview"}
                      className="activeIcon"
                      onClick={() => openDialog("finishReview")}
                    />
                  </HtmlTooltip>
                )}

              {user !== "Clearstream" && (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography id="temp_update_toolTip" variant="h5">
                        Update
                      </Typography>
                    </React.Fragment>
                  }
                  placement="top"
                >
                  <img
                    src={updateGreyIcon}
                    id="temp_update_greyIcon"
                    alt={"update"}
                    className="greyIcon"
                  />
                </HtmlTooltip>
              )}

              {userState.userRoleRight.includes("View_Template_Delete") &&
                user !== "Clearstream" &&
                selected.length === 1 &&
                (templateObj.status === "WaitingForApproval" ||
                  templateObj.status === "Active") && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_cancel_toolTip" variant="h5">
                          Cancel
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={deleteActiveIcon}
                      id="temp_cancel_activeIcon"
                      alt={"revoke"}
                      className="activeIcon"
                      data-testid="cancelIssuanceIcon"
                      onClick={() => openDialog("revoke")}
                    />
                  </HtmlTooltip>
                )}
              {userState.userRoleRight.includes("View_Template_Delete") &&
                user !== "Clearstream" &&
                selected.length === 1 &&
                templateObj.status === "Inactive" && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_delete_toolTip" variant="h5">
                          Delete
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={deleteActiveIcon}
                      data-testid="delete_icon"
                      id="temp_delete_activeIcon"
                      alt={"revoke"}
                      className="activeIcon"
                      onClick={() => checkActiveIssuance()}
                    />
                  </HtmlTooltip>
                )}
              {user !== "Clearstream" &&
                (selected.length !== 1 ||
                  templateObj.status === "ApprovalInProgress" ||
                  templateObj.status === "Rejected" ||
                  !userState.userRoleRight.includes(
                    "View_Template_Delete"
                  )) && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="temp_cancel_htmlToolTip" variant="h5">
                          Cancel
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={deleteGreyIcon}
                      alt={"revoke"}
                      id="temp_cancel_deleteGreyIcon"
                      className="greyIcon"
                    />
                  </HtmlTooltip>
                )}
            </Grid>
          </Grid>
        </StyledHeader>

        <TableContainer
          id="temp_tableStyling"
          className="tableStyling"
          style={{ maxHeight: 580 }}
        >
          <Table aria-label="sticky table" stickyHeader data-testid="TableView">
            <TableHead>
              <TableRow>
                <TableCell
                  id="temp_cellBorder"
                  className="cellBorder filterStyle"
                >
                  {!selectedOption ? (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            id="temp_applyFilter_toolTip"
                            variant="h5"
                          >
                            {applyFilter !== true
                              ? "Add Filters"
                              : "Remove Filters"}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <span className="filterIcon">
                        <img
                          src={filterIcon}
                          alt="triangle"
                          data-testid="addFilter_icon"
                          id="temp_filterIcon"
                          onClick={() => {
                            if (applyFilter === true) {
                              setData(tempList);
                              sessionStorage.setItem("search", "false");
                            }
                            if (data.length !== 0) {
                              removeAppliedFilter();
                              setApplyFilter(!applyFilter);
                            } else if (applyFilter) {
                              removeAppliedFilter();
                              setApplyFilter(!applyFilter);
                            }
                          }}
                        />
                      </span>
                    </HtmlTooltip>
                  ) : (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography id="temp_filterDisabled" variant="h5">
                            Filters disabled due to active search field
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <img
                        src={filterIcon}
                        id="temp_triangleIcon"
                        alt="triangle"
                      />
                    </HtmlTooltip>
                  )}
                </TableCell>
                {columns.map((column, key) => (
                  <React.Fragment>
                    {" "}
                    {column.label !== "" && (
                      <TableCell
                        id={`temp_cellBorder_column_${key}`}
                        className="cellBorder"
                        key={column.key}
                      >
                        <TableSortLabel
                          style={{ width: column.width }}
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                        >
                          <Typography
                            variant="h5"
                            id={`temp_lH-17_${key}`}
                            className="lH-17"
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <span
                                id={`temp_visuallyhidden_sort_${key}`}
                                className="visuallyhidden"
                              >
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </Typography>
                        </TableSortLabel>

                        {applyFilter &&
                          (column.id !== "tempId" ? (
                            column.type === "date" ? (
                              <DatePicker
                                showNow={false}
                                format={DATE_FORMATS.DATE}
                                placeholder={DATE_FORMATS.DATE}
                                onChange={(date, dateString) => {
                                  newRequestSearch({
                                    target: {
                                      id: column.id,
                                      value: date !== null ? dateString : "",
                                    },
                                  });
                                }}
                                suffixIcon={
                                  <img
                                    src={calendarIcon}
                                    id="temp_calendarIcon"
                                    alt="calendar"
                                    className="iconColumn"
                                  />
                                }
                                className="datePickerColumn"
                              />
                            ) : (
                              <MultiSelect
                                style={{ overflow: "visible" }}
                                id="temp_selectColumn"
                                className="selectColumn"
                                options={optionsArray[column.id]}
                                onChange={(option) => {
                                  let arr = [];
                                  option.map((elem) => {
                                    arr.push(elem.value);
                                    return arr;
                                  });
                                  let optionSelected = selectedColumnOption;
                                  optionSelected[column.id] = option;
                                  setSelectedColumnOption(optionSelected);
                                  newRequestSearch({
                                    target: {
                                      id: column.id,
                                      value: arr,
                                    },
                                  });
                                }}
                                value={selectedColumnOption[column.id]}
                              />
                            )
                          ) : (
                            <TextField
                              className="searchbarColumn"
                              data-testid="filter_tempId_text"
                              fullWidth
                              placeholder="Enter"
                              InputProps={{ disableUnderline: true }}
                              onChange={(event) => {
                                newRequestSearch(event);
                              }}
                              id={column.id}
                            ></TextField>
                          ))}
                      </TableCell>
                    )}{" "}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                    id="temp_noDataIcon"
                    className="nodataicon"
                  >
                    <div>
                      <img
                        src={nodataIcon}
                        alt="nodata"
                        id="temp_noiconColumn"
                        className="noiconColumn"
                      />
                      <Typography
                        data-testid="noData_text"
                        id="temp_noDataText"
                      >
                        No data available to display
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {stableSort(data, getComparator(order, orderBy))
                    .reverse()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.key);

                      let statusClass = "";
                      if (row.status === "WaitingForApproval") {
                        statusClass = "waitingStatus";
                      } else if (row.status === "ApprovalInProgress") {
                        statusClass = "inProgressStatus";
                      } else if (row.status === "Active") {
                        statusClass = "activeStatus";
                      } else {
                        statusClass = "inActiveStatus";
                      }

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.key)}
                          id={`temp_checkbox_${row.key}`}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          selected={isItemSelected}
                        >
                          <Checkbox
                            data-testid="tableRowData"
                            id={`temp_checkboxStyling_${row.key}`}
                            className="checkboxStyling"
                            checked={isItemSelected}
                          />
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_statusClass_${row.key}`}
                              className={statusClass}
                            >
                              {row.status.replace(/(([A-Z])[a-z])/g, " $1")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_tableType_${row.key}`}
                              className="tableType"
                            >
                              {row.type}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_tableRow${row.key}`}
                              className="tableRow"
                            >
                              {row.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_tablerow${row.key}`}
                              className="tableRow"
                            >
                              {row.tempId}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_row_lH-17${row.key}`}
                              className="lH-17"
                            >
                              {row.issuer}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_tablePros${row.key}`}
                              className="tablePros"
                            >
                              <EllipisWithTooltip>
                                {row.prospectus}
                              </EllipisWithTooltip>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_payoff${row.key}`}
                              className="tableRow"
                            >
                              {row.payoff}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`temp_creation${row.key}`}
                              className="lH-17"
                            >
                              {row.creation}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container id="temp_paginationGrid" className="paginationGrid">
          <Grid container xs={2}></Grid>
          <Grid container xs={10}>
            <Grid item xs={3}></Grid>
            <Grid item xs={1} align="right">
              <Typography variant="subtitle1" id="temp_page" className="page">
                Page:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Pagination
                onChange={onPageChange}
                count={Math.ceil(data.length / rowsPerPage)}
                page={page + 1}
                classes={{ ul: classes.ul }}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledContainer>

      <Dialog
        style={{ zIndex: "1" }}
        id="temp_newTemplateOpen"
        open={newTemplateOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <StyledContent>
          <div id="temp_newTemplateContent" className="newTemplateContent">
            <Typography
              variant="h5"
              className="fontTemp"
              id="temp_templateDialogTitle"
              data-testid="templateDialogTitle"
            >
              New Template
            </Typography>
            <Typography
              id="temp_underlineTemp"
              className="underlineTemp"
            ></Typography>

            <DialogContentText>
              <Typography
                variant="h5"
                id="temp_tempPlease"
                className="tempPlease"
              >
                Please provide the following
              </Typography>
              <StyledForm>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="h5"
                      id="temp_textTemp"
                      className="textTemp"
                    >
                      Template Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      placeholder="Please Enter"
                      data-testid="templateName"
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      value={templateName}
                      onChange={(event) => {
                        changeFieldValue(event);
                      }}
                      id="templateName"
                    />
                  </Grid>
                </Grid>
                <Grid container className="paddingTemp">
                  <Grid item xs={4} className="paddingType">
                    <Typography
                      variant="h5"
                      id="temp_colorTemp"
                      className="colorTemp"
                    >
                      Template Type:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Dropdown
                      isClearable={false}
                      placeholder="Please Select"
                      onChange={(options) =>
                        onHandleChangeTemplateType(options)
                      }
                      id="searchOption2"
                      options={TemplateTypeList}
                      value={selectedTemplateTypeValue}
                    />
                  </Grid>
                </Grid>
                <Grid container id="temp_paddingTemp" className="paddingTemp">
                  <Grid
                    item
                    xs={4}
                    id="temp_paddingType"
                    className="paddingType"
                  >
                    <Typography
                      variant="h5"
                      id="temp_payoffText"
                      className="colorTemp"
                    >
                      Payoff Calculation:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Dropdown
                      isClearable={false}
                      placeholder="Please Select"
                      onChange={(option) => setSelectedPayoffValue(option)}
                      id="searchOption3"
                      options={PayoffList()}
                      value={selectedPayoffValue}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  id="temp_upload_paddingTemp"
                  className="paddingTemp"
                >
                  <Grid
                    item
                    xs={4}
                    id="temp_upload_paddingType"
                    className="paddingType"
                  >
                    <Typography
                      variant="h5"
                      id="temp_uploadTemplateText"
                      className="colorTemp"
                    >
                      Upload Template:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div id="temp_uploadTemp" className="uploadTemp">
                      {/* {uploadedPdf.length !== 0
                        ? (
                          <div>
                            {pdfFileData.fileData[0].name}
                          </div>
                        )
                        : (
                          <div id='temp_noFile' className="noFile">No file choosen</div>
                        )} */}
                    </div>
                    {show && (
                      <div>
                        {flag !== "error" ? (
                          <label>
                            <Typography
                              variant="subtitle1"
                              className="tempError"
                              id="temp_tempError"
                            >
                              {respText}
                            </Typography>
                          </label>
                        ) : (
                          <label>
                            <Typography
                              variant="subtitle1"
                              className="tempError1"
                              id="temp_tempError1"
                            >
                              {respText}
                            </Typography>
                          </label>
                        )}
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    id="temp_marginChoose"
                    className="marginChoose"
                  >
                    <label htmlFor="templateUpload">
                      <Button
                        disabled
                        variant="outlined"
                        id="temp_noFile_disabled"
                        className="noFile"
                        component="span"
                      >
                        Choose File
                      </Button>
                    </label>
                    <input
                      disabled
                      className="noDisplay"
                      type="file"
                      id="templateUpload"
                      // onChange={uploadNewTemplate.bind(this)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  id="temp_comments_paddingTemp"
                  className="paddingTemp"
                >
                  <Grid
                    item
                    xs={4}
                    id="temp_comments_paddingType"
                    className="paddingType"
                  >
                    <Typography
                      variant="h5"
                      id="temp_commentsText"
                      className="colorTemp"
                    >
                      Comments (Optional):
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Enter"
                      style={{
                        width: 310,
                        border: "1px solid rgba(135, 135, 135, 0.6)",
                        borderRadius: "7px",
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      value={comment}
                      onChange={(event) => {
                        changeFieldValue(event);
                      }}
                      id="comment"
                    />
                  </Grid>
                </Grid>
              </StyledForm>
            </DialogContentText>
          </div>
        </StyledContent>
        <StyledDialogActions>
          <Button
            onClick={() => closeDialog()}
            className="cancelColor"
            id="temp_cancelColor"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="createTemplate"
            disabled={
              Object.keys(selectedTemplateTypeValue).length === 0 ||
              flag === "error" ||
              templateName === "" ||
              Object.keys(selectedPayoffValue).length === 0
            }
            onClick={() => {
              uploadTemplate();
            }}
            id="temp_createTemplate"
          >
            Submit
          </Button>
        </StyledDialogActions>
      </Dialog>

      <StyledOfDialog
        className="deleteUpdateHistoryReviewNewTemplateDialog"
        id="temp_deleteUpdateHistoryReviewNewTemplateDialog"
        open={finishReviewOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Typography variant="h5" className="historyFont" id="temp_historyFont">
          Review {templateObj.name}
        </Typography>
        <Typography
          id="temp_historyDialogs"
          className="historyDialogs"
        ></Typography>
        <StyledContent>
          <StyledDialogContentText>
            <Typography
              variant="h5"
              className="reviewPadding"
              id="temp_reviewPadding"
              data-testid="review_text"
            >
              Please review the following:
            </Typography>
          </StyledDialogContentText>
          <Grid container>
            <Grid item xs={4} id="temp_TemplateName" className="tempName">
              <Typography variant="h5" id="temp_TemplateNameText">
                Template Name:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              id="temp_paddingTempName"
              className="paddingTempName"
            >
              <Typography id="temp_templateObjText" variant="subtitle1">
                {templateObj.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container id="temp_tempPaddingTop" className="tempPaddingTop">
            <Grid item xs={4} id="temp_templateType" className="tempName">
              <Typography variant="h5" id="temp_templateTypeText">
                Template Type:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              id="temp_paddingTempName"
              className="paddingTempName"
            >
              <Typography variant="subtitle1" id="temp_Type">
                {templateObj.type}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            id="temp_tempPaddingTop_payoff"
            className="tempPaddingTop"
          >
            <Grid item xs={4} id="temp_tempName_payoff" className="tempName">
              <Typography variant="h5" id="temp_payoffText">
                Payoff Calculation:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              id="temp_paddingTempName_payoff"
              className="paddingTempName"
            >
              <Typography variant="subtitle1" id="temp_payoff_value">
                {templateObj.payoff}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            id="temp_tempPaddingTop_comments"
            className="tempPaddingTop"
          >
            <Grid item xs={4} id="temp_tempName_comments" className="tempName">
              <Typography variant="h5" id="temp_comments_text">
                Issuer Comments:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              id="temp_paddingTempName_comment"
              className="paddingTempName"
            >
              <Typography id="temp_commentText" variant="subtitle1">
                {templateObj.comment}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            id="temp_tempPaddingTop_comments"
            className="tempPaddingTop"
          >
            <Grid item xs={4} id="temp_tempName_comments" className="tempName">
              <Typography
                variant="h5"
                id="temp_colorComment_comments"
                className="colorComment"
              >
                Comments:
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              id="temp_paddingTempName"
              className="paddingTempName"
            >
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Enter"
                data-testid="comments_reject"
                style={{
                  width: 310,
                  border: "1px solid rgba(135, 135, 135, 0.6)",
                  borderRadius: "7px",
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                value={comment}
                onChange={(event) => {
                  changeFieldValue(event);
                }}
                id="comment"
              />
            </Grid>
          </Grid>
        </StyledContent>
        <StyledDialogActions
          id="temp_paddingActions_cancel"
          className="paddingActions"
        >
          <Button
            onClick={() => {
              closeDialog();
            }}
            className="cancelColor"
            id="temp_cancelColor"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            id="temp_reject"
            onClick={() => {
              rejectRequestedTemplate();
            }}
            disabled={comment === ""}
            data-testid="review_reject"
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="temp_approve"
            data-testid="review_approve"
            onClick={() => {
              approveRequestedTemplate();
            }}
          >
            Approve
          </Button>
        </StyledDialogActions>
      </StyledOfDialog>

      <StyledOfDialog
        className="deleteUpdateHistoryReviewNewTemplateDialog"
        id="temp_revokeTemplate"
        open={revokeTemplate}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        {templateObj.status === "Inactive" ? (
          <Typography
            variant="h3"
            className="historyFont"
            id="temp_historyFontDelete"
          >
            Delete {templateObj.name}
          </Typography>
        ) : (
          <Typography
            variant="h3"
            id="temp_historyFont_cancel"
            className="historyFont"
            data-testid="cancelIssuanceDialogTitle"
          >
            Cancel {templateObj.name}
          </Typography>
        )}

        <Typography
          id="temp_historyDialog_Text"
          className="historyDialog"
        ></Typography>
        <StyledContent>
          {templateObj.status === "WaitingForApproval" && (
            <StyledDialogContentText>
              <Typography
                variant="h5"
                className="reviewPadding"
                id="temp_reviewPadding"
                data-testid="waitingForApproval_cancel_text"
              >
                Are you sure you want to cancel your request for{" "}
                {templateObj.name}?
              </Typography>
            </StyledDialogContentText>
          )}

          {templateObj.status === "Active" && (
            <StyledDialogContentText>
              <Typography
                variant="h5"
                className="reviewPadding"
                data-testid="active_cancel_text"
                id="temp_reviewPadding_cancel"
              >
                Are you sure you want to cancel {templateObj.name}?
              </Typography>
            </StyledDialogContentText>
          )}
          {templateObj.status === "Inactive" && isActive === false && (
            <StyledDialogContentText>
              <Typography
                variant="h5"
                className="reviewPadding"
                id="temp_reviewPadding_deleteText"
                data-testid="delete_text"
              >
                Are you sure you want to delete {templateObj.name}?
              </Typography>
            </StyledDialogContentText>
          )}
          {templateObj.status === "Inactive" && isActive === true && (
            <StyledDialogContentText>
              <Typography
                variant="h5"
                className="reviewPadding"
                id="temp_deletion_warning"
              >
                Due to active issuances linked to this template, template
                deletion cannot be processed.
              </Typography>
            </StyledDialogContentText>
          )}
        </StyledContent>
        <StyledDialogActions
          id="temp_paddingActions"
          className="paddingActions"
        >
          {!(templateObj.status === "Inactive" && isActive === true) && (
            <Button
              onClick={() => {
                setRevokeTemplateOpen(false);
                closeDialog();
              }}
              className="cancelColor"
              id="temp_cancel_button"
            >
              Cancel
            </Button>
          )}

          {templateObj.status === "Inactive" && isActive === true ? (
            <Button
              variant="contained"
              color="primary"
              id="temp_ok_button"
              onClick={() => {
                setRevokeTemplateOpen(false);
              }}
            >
              OK
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              id="temp_ok"
              onClick={() => {
                revokeRequestedTemplate();
              }}
              data-testid="OK_Button"
            >
              OK
            </Button>
          )}
        </StyledDialogActions>
      </StyledOfDialog>

      {/* The below code is of Update template dialog box which is currently out of scope, but may come up in future */}
      {/* <StyledOfDialog
        className="deleteUpdateHistoryReviewNewTemplateDialog"
        open={updateTemplate}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <StyledContent>
          <Typography
            variant="h5"
            className="fontTemp"
          >
            Update {templateObj.name}
          </Typography>
          {!continueUpdate && <Typography className="underlineTemp"></Typography>}
          {!continueUpdate && isActive === false &&
            <DialogContentText>
              <Typography
                variant="h5"
                className="tempPlease"
              >
                Please provide the following:
              </Typography>
              <StyledForm>
                <Grid containerclassName="paddingTemp">
                  <Grid item xs={4} className="paddingType">
                    <Typography variant="h5" className="colorTemp">
                      Upload Template:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      className="uploadTemp"
                    >
                      {uploadedPdf.length !== 0
                        ? (
                          <div>
                            {pdfFileData.fileData[0].name}
                          </div>)
                        : (
                          <div className="updateColor">No file choosen</div>
                        )}
                    </div>
                    {show && (
                      <div>
                        {flag !== "error" ? (
                          <label>
                            <Typography
                              variant="subtitle1"
                              className="tempError"
                            >
                              {respText}
                            </Typography>
                          </label>
                        ) : (
                          <label>
                            <Typography
                              variant="subtitle1"
                              className="tempError1"
                            >
                              {respText}
                            </Typography>
                          </label>
                        )}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={3} className="marginChoose">
                    <label htmlFor="templateUpload">
                      <Button variant="outlined" color="primary" component="span">
                        Choose File
                      </Button>
                    </label>
                    <input
                      className="noDisplay"
                      type="file"
                      id="templateUpload"
                      onChange={uploadNewTemplate.bind(this)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />


                  </Grid>
                </Grid>
                <Grid containerclassName="paddingTemp" >
                  <Grid item xs={4} className="paddingType">
                    <Typography variant="h5" className="colorTemp">
                      Comments:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} className="paddingType2">
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Enter"
                      style={{ width: 310, border: "1px solid rgba(135, 135, 135, 0.6)", borderRadius: "7px", fontSize: "14px" }}
                      onKeyPress={event => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                      value={comment}
                      onChange={(event) => { changeFieldValue(event) }}
                      id="comment"
                    />
                  </Grid>
                </Grid>
              </StyledForm>

            </DialogContentText>
          }
          {!continueUpdate && isActive === true &&
            <StyledDialogContentText>
              <Typography
                variant="h5"
                className="reviewPadding"
              >
                Active issuance are linked with {templateObj.name}. This action will create a new template and deactivate the previous version.
              </Typography>
              <Typography
                variant="h5"
                className="reviewPadding"
              >
                Would you like to continue with the update process?
              </Typography>

            </StyledDialogContentText>}
          {continueUpdate &&
            <StyledDialogContentText>
              <Typography
                variant="subtitle1"
                className="activeIss"
              >
                Active issuance are linked with {templateObj.name}. This action will create a new template and deactivate the previous version.
              </Typography>
              <Typography className="underlineIss"></Typography>
              <Typography
                variant="h5"
                className="reviewPadding"
              >
                Please provide the following:
              </Typography>
              <StyledForm>
                <Grid containerclassName="paddingTemp">
                  <Grid item xs={4} className="paddingType">
                    <Typography variant="h5" className="colorTemp">
                      Upload Template:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      className="uploadTemp"
                    >
                      {uploadedPdf.length !== 0
                        ? (
                          <div>
                            {pdfFileData.fileData[0].name}
                          </div>)
                        : (
                          <div className="updateColor">No file choosen</div>
                        )}
                    </div>
                    {show && (
                      <div>
                        {flag !== "error" ? (
                          <label>
                            <Typography
                              variant="subtitle1"
                              className="tempError"
                            >
                              {respText}
                            </Typography>
                          </label>
                        ) : (
                          <label>
                            <Typography
                              variant="subtitle1"
                              className="tempError1"
                            >
                              {respText}
                            </Typography>
                          </label>
                        )}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={3} className="marginChoose">
                    <label htmlFor="templateUpload">
                      <Button variant="outlined" color="primary" component="span">
                        Choose File
                      </Button>
                    </label>
                    <input
                      className="noDisplay"
                      type="file"
                      id="templateUpload"
                      onChange={uploadNewTemplate.bind(this)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />


                  </Grid>
                </Grid>
                <Grid containerclassName="paddingTemp" >
                  <Grid item xs={4} className="paddingType">
                    <Typography variant="h5" className="colorTemp">
                      Comments:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} className="paddingType2">
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Enter"
                      style={{ width: 310, border: "1px solid rgba(135, 135, 135, 0.6)", borderRadius: "7px", fontSize: "14px" }}
                      onKeyPress={event => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                      value={comment}
                      onChange={(event) => { changeFieldValue(event) }}
                      id="comment"
                    />
                  </Grid>
                </Grid>
              </StyledForm>

            </StyledDialogContentText>


          }
        </StyledContent>
        <StyledDialogActions>
          <Button
            onClick={() => {
              setUpdateTemplateOpen(false)
              setContinueUpdate(false)
              closeDialog()
            }}
            className="cancelColor"
          >
            Cancel
          </Button>
          {continueUpdate || !isActive ?
            <Button variant="contained" color="primary"

              onClick={() => {
                amendTemplate()
              }}
              disabled={comment === "" || uploadedPdf.length === 0}
            >
              Submit
            </Button> :
            <Button variant="contained" color="primary"
              onClick={() => {
                setContinueUpdate(true)
              }}
            >
              Continue
            </Button>}

        </StyledDialogActions>
      </StyledOfDialog> */}

      <StyledOfDialog
        className="deleteUpdateHistoryReviewNewTemplateDialog"
        id="temp_historyOpen"
        open={historyOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Typography
          variant="h3"
          className="historyFont"
          id="temp_auditHistoryDialogTitle"
          data-testid="auditHistoryDialogTitle"
        >
          History {templateObj.tempId && templateObj.tempId}
        </Typography>
        <Typography
          id="temp_historyDialog_text"
          className="historyDialog"
        ></Typography>
        <StyledContent>
          {auditData.map((data) => {
            return (
              <Grid
                container
                id="temp_dialogHistory_grid"
                className="dialogHistory"
              >
                <Grid item xs={4}>
                  <Typography
                    variant="h5"
                    id="temp_timeline_text"
                    className="timeline"
                  >
                    {data.timestamp}
                  </Typography>
                </Grid>
                <Grid container xs={8}>
                  <Grid item xs={1}>
                    <img src={historyCircle} id="temp_circle" alt="circle" />
                    <div className="hzDottedLine" id="temp_hzDottedLine"></div>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="h5"
                      className="action"
                      id="temp_action"
                    >
                      {data.action}
                    </Typography>
                    <Typography variant="h6" className="party" id="temp_party">
                      {data.party}
                    </Typography>
                    <Typography
                      variant="h6"
                      className="comment"
                      id="temp_comment"
                    >
                      {data.comment}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </StyledContent>
        <StyledDialogActions
          id="temp_paddingActions_styledDialog"
          className="paddingActions"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={closeDialog}
            id="temp_close_button"
          >
            Close
          </Button>
        </StyledDialogActions>
      </StyledOfDialog>

      {/* <StyledOfDialog
        classes={{ paper: classes.dialogPaper }}
        className="newIssuanceDialog"
        id='temp_newIssuanceDialog'
        open={open}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <Typography
          variant="h5"
          className="newIssFont"
          id='temp_newIssFont'
        >
          New Issuance
        </Typography>
        <StyledContent>
          <StyledDialogContentText>
            <Typography variant="h5" id='temp_uploadXmlPdf' className="uploadXmlPdf" data-testid='issuanceDialogTitle' >
              Please upload XML & PDF file to create a new issuance.
            </Typography>
          </StyledDialogContentText>
          <Grid container id='temp_tempPaddingTop_container' className="tempPaddingTop">
            <Grid item xs={1}>
              <InsertDriveFileOutlinedIcon id='temp_issuanceMargin' className="issuanceMargin" />
            </Grid>
            <Grid item container xs={7} id='temp_underlineXml' className="underlineXml">
              <Grid item xs={11}>
                <div id='temp_word' className="word"
                >{uploadedfiles.length !== 0
                  ? tempFileData.fileData[0]?.name
                  : "No file choosen"}</div>
              </Grid>
              <Grid item xs={1}>
                <span id='temp_float' className="float">
                  {show === true &&
                    <>
                      {flag === "error" ? <img src={uploadFailureIcon} alt={"uploadFailure"} id='temp_uploadFailureIcon' />
                        : <img src={uploadSuccessIcon} alt={"uploadSuccess"} id='temp_uploadSuccessIcon' data-testid='uploadSuccessIconXML' />
                      }
                    </>
                  } </span>
              </Grid>
            </Grid>
            <Grid item xs={4} id='temp_chooseXML' className="paddingXml">
              <label htmlFor="xmlfileUpload">
                <Button variant="outlined" id='temp_chooseXML_button' color="primary" component="span">
                  Choose XML
                </Button>
              </label>
              <input
                className="displayIss"
                type="file"
                id="xmlfileUpload" data-testid='uploadFileXML'
                onChange={filePathSet.bind(this)}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}> {show &&
              <>
                {flag === "error" ?
                  <Typography variant="subtitle1" id='temp_error_message' className="colorError" >
                    Error: File type is not supported. Please select another file and try again.
                  </Typography>
                  : ""}
              </>
            }
            </Grid>
          </Grid>
          <Grid container id='temp_tempPaddingTop_Grid' className="tempPaddingTop" >
            <Grid item xs={1}>
              <InsertDriveFileOutlinedIcon id='temp_margin' className="issuanceMargin" />
            </Grid>
            <Grid item container xs={7} id='temp_underline' className="underlineXml">
              <Grid item xs={11}>
                <div className="word" id='temp_word_div'
                >{uploadedPdffiles.length !== 0
                  ? tempFileDataForPdf.fileData[0]?.name
                  : "No file choosen"}</div>
              </Grid>
              <Grid item xs={1}>
                <span id='temp_float_span' className="float">
                  {showPDFmsg === true &&
                    <>
                      {flagForPDF === "error" ? <img id='temp_failureIconPdf' alt={"FailureIcon"} src={uploadFailureIcon} />
                        : <img src={uploadSuccessIcon} alt={"successIcon"} id='temp_uploadSuccessIconPDF' data-testid='uploadSuccessIconPDF' />
                      }
                    </>
                  } </span>
              </Grid>
            </Grid>
            <Grid item xs={4} id='temp_choosePDF' className="paddingXml">
              <label htmlFor="pdffileUpload">
                <Button variant="outlined" color="primary" id='temp_choosePDF_button' component="span">
                  Choose PDF
                </Button>
              </label>
              <input
                className="displayIss" data-testid='uploadFilePDF'
                type="file"
                id="pdffileUpload"
                onChange={filePathSetForPDF.bind(this)}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}> {showPDFmsg &&
              <>
                {flagForPDF === "error" ?
                  <Typography variant="subtitle1" id='temp_error_text' className="colorError">
                    Error: File type is not supported. Please select another file and try again.
                  </Typography>
                  : ""}
              </>
            }
            </Grid>
          </Grid>
        </StyledContent>

        <StyledDialogActions id='temp_actions' className="paddingActions">
          <Button
            onClick={() => {
              closeDialog()
            }}
            id='temp_cancelButton'
            data-testid='cancel_issuance'
            className="cancelColor"
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" className="marginNega" id='temp_submitButton'
            disabled={uploadedfiles.length === 0 || flagForPDF === "error" || flag === "error" || uploadedPdffiles.length === 0} data-testid='submit_issuance' onClick={() => {
              upload();
            }}
          >
            Submit
          </Button>
        </StyledDialogActions>
      </StyledOfDialog> */}
    </React.Fragment>
  );
}

export default Templates;
