import styled from "styled-components";
import {
  Container,
  Card,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

export const StyledForm = styled.form`
  padding-top: 15px;
  padding-left: 25px;

  .ant-picker {
    border: none;
    width: 100%;
    padding: 0;
  }
  .ant-picker-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding-bottom: 2px;
  }
  .ant-picker-clear {
    display: none;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
  .textTemp {
    color: #00a7c4;
    padding-top: 10px;
  }
  .paddingTemp {
    padding-top: 20px;
  }
  .paddingType {
    padding-top: 10px;
  }
  .paddingType2 {
    padding-top: 4px;
  }
  .colorTemp {
    color: #00a7c4;
  }
  .uploadTemp {
    border-bottom: 1px solid rgba(135, 135, 135, 0.6);
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .updateColor {
    color: #686c6e;
  }
  .noFile {
    color: #b7b7b7;
  }
  .tempError {
    color: #00cc99;
  }
  .tempError1 {
    color: #cc0099;
  }
  .marginChoose {
    margin-left: 2%;
  }
  .noDisplay {
    display: none;
  }
`;

export const StyledContainer = styled(Container)`
  padding-top: 0px;
  padding-left: 80px;
  padding-right: 80px;
  @media (min-width: 1280px) {
    max-width: inherit;
  }
  .MuiPaper-root {
    padding-top: 5px;
  }
  .MuiTableCell-stickyHeader {
    z-index: 0;
  }
  .paginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .popup {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 75px;
  }
  .popup2 {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 180px;
  }
  .popup3 {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 180px;
  }
  .pT-2 {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .fr {
    margin-left: 5px;
    background-color: #000099;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .frDeactivated {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    margin-left: 5px;
    background-color: #878787;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .bs {
    background-color: #00cc99;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .bsDeactivated {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    background-color: #878787;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .filterStyle {
    z-index: 1;
  }
  .filterColumn .css-g1d714-ValueContainer {
    padding-top: 0px;
  }
  .filterColumn .select__control {
    margin-top: 10px;
    max-height: 35px;
    border: 1px solid rgb(135 135 135 / 50%);
  }
  .filterColumn .select__control .select__dropdown-indicator {
    color: #aaaaaa;
  }
  .dropown-content {
    z-index: 2;
  }
  .datePickerColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .iconColumn {
    height: 15px;
  }
  .rmsc .dropdown-heading {
    padding-bottom: 5px;
  }
  .rmsc .options {
    max-height: 200px !important;
  }

  .selectColumn .dropdown-container {
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .searchbarColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    padding-left: 10px;
    padding-top: 2px;
  }
  .tableStyling {
    box-shadow: 0px 1px 5px 1px #87878799;
    border-radius: 12px;
    background-color: #ffffff;
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 10px;
    min-height: 350px;
  }
  .lH-17 {
    line-height: 17px;
  }
  .tableType {
    line-height: 17px;
    min-width: 185px;
  }
  .tableRow {
    line-height: 17px;
    min-width: 175px;
  }
  .tablePros {
    line-height: 17px;
    min-width: 220px;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .checkboxStyling {
    z-index: 0;
    color: #00a7c4;
    margin-top: 45%;
    margin-bottom: 45%;
    vertical-align: middle;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: #d3f3f7;
  }
  .select-styles {
    width: 60px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
  .waitingStatus {
    color: rgba(135, 135, 135, 0.6);
    line-height: 17px;
  }
  .inProgressStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .activeStatus {
    color: #00cc99;
    line-height: 17px;
  }
  .inActiveStatus {
    color: #cc0099;
    line-height: 17px;
  }
  .page {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 20px;
  }
`;

export const StyledHeader = styled(Card)`
  border: none;
  padding-top: 40px;
  padding-bottom: 21px;
  box-shadow: none;
  background-color: #ffffff;
  .MuiInputBase-root.Mui-disabled {
    color: currentColor;
  }
  z-index: 1;
  .btn-style {
    margin-right: 8px;
  }
  .select-styles {
    width: 100%;
    padding-bottom: 8px;
  }
  .ForwardRef-iconButton-2 {
    color: #00a7c4;
  }
  .searchBar {
    height: 35px;
    border: 1px solid rgb(135 135 135 / 60%);
    border-radius: 17px;
    width: 100%;
    box-shadow: none;
  }
  .gridContainer {
    padding-top: 42px;
    margin: 0px;
  }
  .pT-8 {
    padding-top: 8px;
  }
  .pL-10 {
    padding-left: 10px;
  }
  .activeIcon {
    cursor: pointer;
  }
`;

export const StyledContent = styled(DialogContent)`
    .newTemplateContent{
        padding-left: 10px;
    }
    .paddingContent{
        padding-top: 40px;
        padding-left: 35px;
        padding-right: 0px;
    }
    .fontTemp{
        color: #000000;
        font-size: 17px;
        line-height: 21px;
        padding-bottom: 25px
    }
    .underlineTemp{
        margin-right: 10px;
        border-top: 1px solid #00A7C4
    }
    .tempPlease{
        color: #000000;
        padding-top: 20px
    }
    .textReview{
        color: #000000, 
        padding-top: 20px
    }
    .tempName{
        padding-top: 10px;
        margin-left: 5%
    }
    .paddingTempName{
        padding-top: 4px
    }
    .tempPaddingTop{
        padding-top: 20px
    }
    .tempMarginLeft{
        Margin-left: 2%
    }
    .colorComment{
        color: #00A7C4
    }
    .timeline{
        color: rgba(135, 135, 135, 0.6);
        padding-top: 2%
    }
    .dialogHistory{
        padding-left: 20px
    }
    .hzDottedLine{
        border-left: 1px dashed #B7B7B7;
        height: 100%;
        margin-left: 5px
    }
    .action{
        color: #00A5C0;
        line-height: 25px;
        padding-left: 10px
    }
    .party{
        color: #000000;
        padding-top: 8px;
        padding-left: 10px
    }
    .comment{
        color: #000000;
        padding-top: 8px;
        padding-bottom: 25px;
        padding-left: 10px
    }
    .issuanceMargin{
        margin-top: 10px
    }
    .underlineXml{
        padding-top: 10px;
        border-bottom: 1px solid black
    }
    .word{
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap; 
        word-break: break-all
    }
    .float{
        float: right
    }
    .paddingXml{
        padding-left: 2%
    }
    .displayIss{
        display: none
    }
    .colorError{
        color: #CC0099
    }


`;

export const StyledDialogActions = styled(DialogActions)`
  padding-bottom: 30px;
  padding-right: 40px;
  .cancelColor {
    color: #00a7c4;
    width: 8%;
  }
  .paddingActions {
    paddingtop: 20px;
    padding-bottom: 30px;
    padding-right: 40px;
  }
  .marginNega {
    margin-right: -10px;
  }
`;

export const StyledDialogContentText = styled(DialogContentText)`
  .reviewPadding {
    color: #000000;
    padding-top: 20px;
  }
  .activeIss {
    color: #000000;
    line-height: 20px;
    padding-bottom: 25px;
  }
  .underlineIss {
    marginright: 40px;
    bordertop: 1px solid #00a7c4;
  }
  .uploadXmlPdf {
    color: #000000;
    padding-top: 25px;
  }
`;
export const StyledOfDialog = styled(Dialog)`
  .deleteUpdateHistoryReviewNewTemplateDialog {
    z-index: 1;
  }
  .newIssuanceNDialog {
    z-index: 3;
  }
  .newIssFont {
    font-size: 17px;
    padding-top: 40px;
    padding-left: 24px;
  }
  .historyFont {
    font-size: 17px;
    padding-top: 20px;
    padding-left: 24px;
    padding-bottom: 20px;
  }
  .historyDialog {
    margin-left: 5%;
    margin-right: 5%;
    border-top: 1px solid #00a7c4;
  }
  .historyDialogs {
    margin-left: 4%;
    margin-right: 4%;
    border-top: 1px solid #00a7c4;
  }
`;
