/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import General from './General';
import Identifiers from './Identifiers';
import Increase from './Increase';
import Interest from './Interest';
import Issuance from './Issuance';
import MarkUpDown from './MarkUpDown';
import Position from './Position';
import Redemption from './Redemption';
import Transaction from './Transaction';
import Venue from './Venue';

/**
 * The DIBase model module.
 * @module model/DIBase
 * @version 1.0.3
 */
class DIBase {
    /**
     * Constructs a new <code>DIBase</code>.
     * @alias module:model/DIBase
     */
    constructor() { 
        
        DIBase.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DIBase</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DIBase} obj Optional instance to populate.
     * @return {module:model/DIBase} The populated <code>DIBase</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DIBase();

            if (data.hasOwnProperty('General')) {
                obj['General'] = General.constructFromObject(data['General']);
            }
            if (data.hasOwnProperty('Identifiers')) {
                obj['Identifiers'] = Identifiers.constructFromObject(data['Identifiers']);
            }
            if (data.hasOwnProperty('Issuance')) {
                obj['Issuance'] = Issuance.constructFromObject(data['Issuance']);
            }
            if (data.hasOwnProperty('Redemption')) {
                obj['Redemption'] = ApiClient.convertToType(data['Redemption'], [Redemption]);
            }
            if (data.hasOwnProperty('Increase')) {
                obj['Increase'] = ApiClient.convertToType(data['Increase'], [Increase]);
            }
            if (data.hasOwnProperty('Interest')) {
                obj['Interest'] = ApiClient.convertToType(data['Interest'], [Interest]);
            }
            if (data.hasOwnProperty('Venue')) {
                obj['Venue'] = Venue.constructFromObject(data['Venue']);
            }
            if (data.hasOwnProperty('Position')) {
                obj['Position'] = Position.constructFromObject(data['Position']);
            }
            if (data.hasOwnProperty('Transaction')) {
                obj['Transaction'] = Transaction.constructFromObject(data['Transaction']);
            }
            if (data.hasOwnProperty('MarkUpDown')) {
                obj['MarkUpDown'] = ApiClient.convertToType(data['MarkUpDown'], [MarkUpDown]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DIBase</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DIBase</code>.
     */
    static validateJSON(data) {
        // validate the optional field `General`
        if (data['General']) { // data not null
          General.validateJSON(data['General']);
        }
        // validate the optional field `Identifiers`
        if (data['Identifiers']) { // data not null
          Identifiers.validateJSON(data['Identifiers']);
        }
        // validate the optional field `Issuance`
        if (data['Issuance']) { // data not null
          Issuance.validateJSON(data['Issuance']);
        }
        if (data['Redemption']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Redemption'])) {
                throw new Error("Expected the field `Redemption` to be an array in the JSON data but got " + data['Redemption']);
            }
            // validate the optional field `Redemption` (array)
            for (const item of data['Redemption']) {
                Redemption.validateJsonObject(item);
            };
        }
        if (data['Increase']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Increase'])) {
                throw new Error("Expected the field `Increase` to be an array in the JSON data but got " + data['Increase']);
            }
            // validate the optional field `Increase` (array)
            for (const item of data['Increase']) {
                Increase.validateJsonObject(item);
            };
        }
        if (data['Interest']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Interest'])) {
                throw new Error("Expected the field `Interest` to be an array in the JSON data but got " + data['Interest']);
            }
            // validate the optional field `Interest` (array)
            for (const item of data['Interest']) {
                Interest.validateJsonObject(item);
            };
        }
        // validate the optional field `Venue`
        if (data['Venue']) { // data not null
          Venue.validateJSON(data['Venue']);
        }
        // validate the optional field `Position`
        if (data['Position']) { // data not null
          Position.validateJSON(data['Position']);
        }
        // validate the optional field `Transaction`
        if (data['Transaction']) { // data not null
          Transaction.validateJSON(data['Transaction']);
        }
        if (data['MarkUpDown']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['MarkUpDown'])) {
                throw new Error("Expected the field `MarkUpDown` to be an array in the JSON data but got " + data['MarkUpDown']);
            }
            // validate the optional field `MarkUpDown` (array)
            for (const item of data['MarkUpDown']) {
                MarkUpDown.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * @member {module:model/General} General
 */
DIBase.prototype['General'] = undefined;

/**
 * @member {module:model/Identifiers} Identifiers
 */
DIBase.prototype['Identifiers'] = undefined;

/**
 * @member {module:model/Issuance} Issuance
 */
DIBase.prototype['Issuance'] = undefined;

/**
 * Model: Redemption
 * @member {Array.<module:model/Redemption>} Redemption
 */
DIBase.prototype['Redemption'] = undefined;

/**
 * Model: Increase
 * @member {Array.<module:model/Increase>} Increase
 */
DIBase.prototype['Increase'] = undefined;

/**
 * Model: Interest
 * @member {Array.<module:model/Interest>} Interest
 */
DIBase.prototype['Interest'] = undefined;

/**
 * @member {module:model/Venue} Venue
 */
DIBase.prototype['Venue'] = undefined;

/**
 * @member {module:model/Position} Position
 */
DIBase.prototype['Position'] = undefined;

/**
 * @member {module:model/Transaction} Transaction
 */
DIBase.prototype['Transaction'] = undefined;

/**
 * Model: MarkUpDown
 * @member {Array.<module:model/MarkUpDown>} MarkUpDown
 */
DIBase.prototype['MarkUpDown'] = undefined;






export default DIBase;

