/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UnderlyingInstrument model module.
 * @module model/UnderlyingInstrument
 * @version 1.0.3
 */
class UnderlyingInstrument {
    /**
     * Constructs a new <code>UnderlyingInstrument</code>.
     * @alias module:model/UnderlyingInstrument
     */
    constructor() { 
        
        UnderlyingInstrument.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UnderlyingInstrument</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UnderlyingInstrument} obj Optional instance to populate.
     * @return {module:model/UnderlyingInstrument} The populated <code>UnderlyingInstrument</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UnderlyingInstrument();

            if (data.hasOwnProperty('ISIN')) {
                obj['ISIN'] = ApiClient.convertToType(data['ISIN'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('UnderlyingType')) {
                obj['UnderlyingType'] = ApiClient.convertToType(data['UnderlyingType'], 'String');
            }
            if (data.hasOwnProperty('UnderlyingProductType')) {
                obj['UnderlyingProductType'] = ApiClient.convertToType(data['UnderlyingProductType'], 'String');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'String');
            }
            if (data.hasOwnProperty('Currency')) {
                obj['Currency'] = ApiClient.convertToType(data['Currency'], 'String');
            }
            if (data.hasOwnProperty('MarketIdentifierCode')) {
                obj['MarketIdentifierCode'] = ApiClient.convertToType(data['MarketIdentifierCode'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UnderlyingInstrument</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UnderlyingInstrument</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['ISIN'] && !(typeof data['ISIN'] === 'string' || data['ISIN'] instanceof String)) {
            throw new Error("Expected the field `ISIN` to be a primitive type in the JSON string but got " + data['ISIN']);
        }
        // ensure the json data is a string
        if (data['Name'] && !(typeof data['Name'] === 'string' || data['Name'] instanceof String)) {
            throw new Error("Expected the field `Name` to be a primitive type in the JSON string but got " + data['Name']);
        }
        // ensure the json data is a string
        if (data['UnderlyingType'] && !(typeof data['UnderlyingType'] === 'string' || data['UnderlyingType'] instanceof String)) {
            throw new Error("Expected the field `UnderlyingType` to be a primitive type in the JSON string but got " + data['UnderlyingType']);
        }
        // ensure the json data is a string
        if (data['UnderlyingProductType'] && !(typeof data['UnderlyingProductType'] === 'string' || data['UnderlyingProductType'] instanceof String)) {
            throw new Error("Expected the field `UnderlyingProductType` to be a primitive type in the JSON string but got " + data['UnderlyingProductType']);
        }
        // ensure the json data is a string
        if (data['Country'] && !(typeof data['Country'] === 'string' || data['Country'] instanceof String)) {
            throw new Error("Expected the field `Country` to be a primitive type in the JSON string but got " + data['Country']);
        }
        // ensure the json data is a string
        if (data['Currency'] && !(typeof data['Currency'] === 'string' || data['Currency'] instanceof String)) {
            throw new Error("Expected the field `Currency` to be a primitive type in the JSON string but got " + data['Currency']);
        }
        // ensure the json data is a string
        if (data['MarketIdentifierCode'] && !(typeof data['MarketIdentifierCode'] === 'string' || data['MarketIdentifierCode'] instanceof String)) {
            throw new Error("Expected the field `MarketIdentifierCode` to be a primitive type in the JSON string but got " + data['MarketIdentifierCode']);
        }

        return true;
    }


}



/**
 * ISIN of the underlying on which the interest parameters are dependent. Mandatory if the underlying asset if it has an ISIN code.[223]
 * @member {String} ISIN
 */
UnderlyingInstrument.prototype['ISIN'] = undefined;

/**
 * Name of the underlying on which the interest parameters are dependent. Mandatory if the issuance has interest parameters dependent on an underlying.[224]
 * @member {String} Name
 */
UnderlyingInstrument.prototype['Name'] = undefined;

/**
 * Type of the underlying on which the interest parameters are dependent. Mandatory if the issuance has interest parameters dependent on an underlying.[225]
 * @member {String} UnderlyingType
 */
UnderlyingInstrument.prototype['UnderlyingType'] = undefined;

/**
 * Asset class of the Underlying[226]
 * @member {String} UnderlyingProductType
 */
UnderlyingInstrument.prototype['UnderlyingProductType'] = undefined;

/**
 * The ISOCountry Code for the reference exchange of the underlying[227]
 * @member {String} Country
 */
UnderlyingInstrument.prototype['Country'] = undefined;

/**
 * The ISOCode of the currency used in the price quotation on the reference exchange of the underlying[228]
 * @member {String} Currency
 */
UnderlyingInstrument.prototype['Currency'] = undefined;

/**
 * The Market Identifier Code (MIC) of the exchange where the Underlying is traded on and the quotes are provided from.[229]
 * @member {String} MarketIdentifierCode
 */
UnderlyingInstrument.prototype['MarketIdentifierCode'] = undefined;






export default UnderlyingInstrument;

