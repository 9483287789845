import { TableCell, TableRow } from "@material-ui/core";
import { Spin } from "antd";
import nodataIcon from "../../assets/Error Icon.svg";
import Typography from "@material-ui/core/Typography";

const NoData = ({ loading }) => {
  return (
    <TableRow style={{ backgroundColor: "white" }}>
      <TableCell
        colSpan={9}
        align="center"
        id="isWhitelist_noDataIcon"
        className="nodataicon"
      >
        {loading ? (
          <Spin />
        ) : (
          <div>
            <img
              src={nodataIcon}
              alt="nodata"
              id="isWhitelist_noIconColumn"
              className="noiconColumn"
            />
            <Typography id="isWhitelist_noDataText">
              No data available to display
            </Typography>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default NoData;
