import styled from "styled-components";
import { Container, Card, Dialog, TableContainer } from "@material-ui/core";

export const StyledContainer = styled(Container)`
    padding-top: 15px;
    padding-left: 80px;
    padding-right: 80px;
    @media (min-width: 1280px){
        max-width: inherit;
    }
    .MuiTabPanel-root{
        padding-top: 12px;
    }
    .popup {
        position: absolute;
        width: 360px;
        height: 4em;
        top: 75px;
        right: 5%;
    }
    .mainItem {
        color: #00A7C4;
        border-bottom: 1px solid #00A5C0;
        padding-top: 5px;
        margin-Left: -10px;
        font-size: 14px;
        
    }
    .breadcrumbs{
        margin-left: 280px
    }
    .exportComplianceReport{
        font-size: 17px;
        font-weight:bolder;
        padding-bottom: 4%;
        margin-top: 14px;
        color: #000000;
    }
    .compLists{
        padding-bottom: 4%
`;

export const StyledCardForComplianceReport = styled(Card)`
  margin-top: 3%;
  background-color: #ffffff;
  padding-left: 48px;
  padding-right: 40px;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  border-radius: 10px;
  .ant-picker {
    border-radius: 17px;
    width: 100%;
  }
  .ant-picker-clear {
    display: none;
  }
  .dataRange {
    color: #000000;
    padding-top: 30px;
  }
  .fromTo {
    padding-top: 8px;
    color: #00a5c0;
  }
  .padding1 {
    padding-top: 28px;
  }
  .paddingTo {
    padding-left: 15px;
  }
  .paddingToR {
    padding-right: 0px;
  }
  .padding2 {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .cancel {
    color: #00a7c4;
    width: 8%;
    margin-right: 5px;
  }
  .export {
    margin-right: 0px;
  }
`;

export const StyledCard = styled(Card)`
  height: 90px;
  width: 280px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  border-radius: 10px;
  .padding1 {
    padding-top: 27px;
  }
  .padding2 {
    padding-top: 3%;
  }
  .cursor {
    cursor: pointer;
  }
  .exCurrentFile {
    color: #000000;
  }
  .lastUpload {
    font-size: 12px;
    font-weight: bold;
    color: #000000;
  }
`;
export const StyledDialog = styled(Dialog)`
  .uploadFile {
    z-index: 3;
  }
  .dialogText {
    font-size: 17px;
    padding-top: 40px;
    padding-left: 24px;
  }
  .fileSelect {
    color: #000000;
    padding-top: 30px;
  }
  .paddingChooseFile {
    padding-top: 20px;
  }
  .paddingFileIcon {
    margin-top: 10px;
  }
  .uploadFileFont {
    border-bottom: 1px solid black;
    padding-top: 10px;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
  .checkCircleIcon {
    border-bottom: 1px solid black;
    padding-top: 6px;
    color: lightgreen;
  }
  .underlined {
    border-bottom: 1px solid black;
    padding-top: 10px;
  }
  .error1 {
    color: #00cc99;
  }
  .error2 {
    color: #cc0099;
  }
  .paddingFile {
    padding-left: 2%;
  }
  .input {
    display: none;
  }
  .paddingBottom {
    padding-bottom: 30px;
    padding-right: 40px;
  }
  .cancelColor {
    color: #00a7c4;
    width: 8%;
  }
  .ant-picker {
    border-radius: 17px;
    width: inherit;
  }
`;
export const ViewDetailsContainer = styled(TableContainer)`
  margin-top: 20px;

  .viewDetailsGreyIcon {
  }
  .viewDetailsActiveIcon {
    cursor: pointer;
  }
  .btnUploadWhiteList {
    border-radius: 17px;
    width: auto;
  }
`;
export const ComplianceListsContainer = styled(TableContainer)`
  margin-top: 10px;
  box-shadow: 0px 1px 5px 1px #87878799;
  border-radius: 12px;
  background-color: #ffffff;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 10px;
  min-height: 350px;
  .lH-17 {
    line-height: 17px;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: rgba(178, 228, 236, 0.3) !important;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .status {
    line-height: 17px;
  }
  .requestedStatus {
    color: #ff6600;
    line-height: 17px;
  }
  .eligibilityCheckRequiredStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .checkReferenceDatabase {
    color: #cc0099;
    line-height: 17px;
  }
  .failedStatus {
    color: #cc0099;
    line-height: 17px;
  }
  .FFFFFFColor {
    background-color: #ffffff;
  }
  .F7F7F7Color {
    backgroundcolor: #f7f7f7;
  }
  .redColor {
    color: #ff0000;
    line-height: 17px;
  }
  .greenColor {
    color: #00cc99;
    line-height: 17px;
  }
`;

export const StyledOffSubSectionContainer = styled(TableContainer)`
  .rowsAndPaginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .paginationLabel {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .rowsPerPage {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .select-styles {
    width: 70px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
      color: black;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
`;
