import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const Identifier = ({ handleChange, expandState, isDesktop, issuanceObj }) => (
  <Accordion
    className="accordianStyle"
    id="det_cp_accordion"
    expanded={expandState["panel2"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel2a-content"
      id="panel2a-header"
      onClick={() => {
        handleChange("panel2");
      }}
    >
      <Typography variant="h3" id="det_cp_Identifier">
        Identifier
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="det_cp_Id_acc">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement label={"ISIN"} value={issuanceObj.isin} />
        <RowElement label={"WKN"} value={issuanceObj.wkn} />
        <RowElement label={"DTI"} value={issuanceObj.dti} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Instrument Structure Category"}
          value={issuanceObj.instrumentStructureCategory}
        />
        <RowElement label={"Issuer Name"} value={issuanceObj.issuer} />
        <RowElement label={"Instrument Name"} value={issuanceObj.productName} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Client Free Text"}
          value={issuanceObj.clientFreeText}
        />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default Identifier;
