/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Changeset from '../model/Changeset';
import CountResponse from '../model/CountResponse';
import Error from '../model/Error';
import RedemptionBriefInfo from '../model/RedemptionBriefInfo';

/**
* RedemptionsInternal service.
* @module api/RedemptionsInternalApi
* @version 1.0.3
*/
export default class RedemptionsInternalApi {

    /**
    * Constructs a new RedemptionsInternalApi. 
    * @alias module:api/RedemptionsInternalApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getRedemptionChangesetDetails operation.
     * @callback module:api/RedemptionsInternalApi~getRedemptionChangesetDetailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Changeset} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get specific ChangesetItem and it's full details.
     * @param {String} isin Changeset can be queried using the Unique Identifier - id
     * @param {module:api/RedemptionsInternalApi~getRedemptionChangesetDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Changeset}
     */
    getRedemptionChangesetDetails(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getRedemptionChangesetDetails");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Changeset;
      return this.apiClient.callApi(
        '/di/v1/redemptions/pending/reviews/{isin}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRedemptionPendingCount operation.
     * @callback module:api/RedemptionsInternalApi~getRedemptionPendingCountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CountResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return the count of the redemptions items.
     * @param {module:api/RedemptionsInternalApi~getRedemptionPendingCountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CountResponse}
     */
    getRedemptionPendingCount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CountResponse;
      return this.apiClient.callApi(
        '/di/v1/redemptions/pending/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRedemptionPendingList operation.
     * @callback module:api/RedemptionsInternalApi~getRedemptionPendingListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/RedemptionBriefInfo>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get list of redemptions.
     * @param {module:api/RedemptionsInternalApi~getRedemptionPendingListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/RedemptionBriefInfo>}
     */
    getRedemptionPendingList(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [RedemptionBriefInfo];
      return this.apiClient.callApi(
        '/di/v1/redemptions/pending/reviews', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateRedemptionStatusByIsin operation.
     * @callback module:api/RedemptionsInternalApi~updateRedemptionStatusByIsinCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return the count of the redemptions items.
     * @param {String} isin Redemption can be selected using the isin
     * @param {String} status Defines the new status of the Redemption approved/rejected
     * @param {module:api/RedemptionsInternalApi~updateRedemptionStatusByIsinCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    updateRedemptionStatusByIsin(isin, status, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling updateRedemptionStatusByIsin");
      }
      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling updateRedemptionStatusByIsin");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
        'status': status
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/di/v1/redemptions/pending/reviews/{isin}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
