/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BusinessValidationError from '../model/BusinessValidationError';
import Error from '../model/Error';

/**
* IssuancesCommands service.
* @module api/IssuancesCommandsApi
* @version 1.0.3
*/
export default class IssuancesCommandsApi {

    /**
    * Constructs a new IssuancesCommandsApi. 
    * @alias module:api/IssuancesCommandsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the convertUploadedXMLFileUsingPOST operation.
     * @callback module:api/IssuancesCommandsApi~convertUploadedXMLFileUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to upload a new Issuance in XML and its terms and conditions pdf.
     * @param {Object} opts Optional parameters
     * @param {File} opts.issuance 
     * @param {File} opts.tc 
     * @param {module:api/IssuancesCommandsApi~convertUploadedXMLFileUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    convertUploadedXMLFileUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'issuance': opts['issuance'],
        'tc': opts['tc']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/di/v1/issuances', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
