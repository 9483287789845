import styled from "styled-components";
import { Container, TableContainer, Grid } from "@material-ui/core";

export const StyledContainer = styled(Container)`
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 80px;
  @media (min-width: 1280px) {
    max-width: inherit;
  }
  .MuiTabPanel-root {
    padding-top: 12px;
  }
`;

export const StyledSubSectionGrid = styled(Grid)`
  .popup {
    position: absolute;
    width: 29%;
    z-index: 2;
    left: 65%;
    top: 75px;
  }
  .btnDRPonbehalf {
    border-radius: 17px;
    width: auto;
  }
  .icon2 {
    margin-right: 2%;
    margin-left: 2%;
    cursor: pointer;
  }
`;
export const StyledSubSectionContainer = styled(TableContainer)`
  margin-top: 10px;
  box-shadow: 0px 1px 5px 1px #87878799;
  border-radius: 12px;
  background-color: #ffffff;
  padding-bottom: 10px;
  min-height: 350px;
  tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }
  .searchbarColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    padding-left: 10px;
    padding-top: 2px;
  }
  .selectColumn .dropdown-container {
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .datePickerColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    display: flex;
  }
  .tableCellBorder {
    border-bottom: 1px solid #00a5c0;
    paddingright: 5;
  }
  .filterIcon {
    cursor: pointer;
  }
  .lH-17 {
    line-height: 17px;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: rgba(178, 228, 236, 0.3) !important;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .checkboxStyling {
    color: #00a7c4;
    padding: 13px 0px 0px 10px;
  }
  .FFFFFFColor {
    background-color: #ffffff;
  }
  .F7F7F7Color {
    backgroundcolor: #f7f7f7;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  .filterIcon {
    cursor: pointer;
  }
  .filterColumn .css-g1d714-ValueContainer {
    padding-top: 0px;
  }
  .filterColumn .select__control {
    margin-top: 10px;
    max-height: 35px;
    border: 1px solid rgb(135 135 135 / 50%);
  }
  .filterColumn .select__control .select__dropdown-indicator {
    color: #aaaaaa;
  }
  .iconColumn {
    height: 15px;
  }
`;
export const StyledOffSubSectionContainer = styled(TableContainer)`
  .rowsAndPaginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .paginationLabel {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .rowsPerPage {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .select-styles {
    width: 80px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
      color: black;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
`;
export const ViewDetailsContainer = styled(TableContainer)`
  .viewDetailsGreyIcon {
  }
  .viewDetailsActiveIcon {
    cursor: pointer;
  }
`;
export const StyledIssBlotterContainer = styled(TableContainer)`
  margin-top: 10px;
  box-shadow: 0px 1px 5px 1px #87878799;
  border-radius: 12px;
  background-color: #ffffff;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 10px;
  min-height: 350px;
  .lH-17 {
    line-height: 17px;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: rgba(178, 228, 236, 0.3) !important;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .completedStatus {
    color: #00cc99;
    line-height: 17px;
  }
  .requestedStatus {
    color: #ff6600;
    line-height: 17px;
  }
  .eligibilityCheckRequiredStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .checkReferenceDatabase {
    color: #cc0099;
    line-height: 17px;
  }
  .failedStatus {
    color: #cc0099;
    line-height: 17px;
  }
  .FFFFFFColor {
    background-color: #ffffff;
  }
  .F7F7F7Color {
    backgroundcolor: #f7f7f7;
  }
`;
