import styled from "styled-components";
export const StyledConsentCookie = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  .cookieBanner {
    justify-content: flex-end;
    background-color: #ffffff;
    bottom: 0;
    width: 100%;
    padding: 20px;
    padding-left: 50px;
  }
  .content {
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 30px;
    .content-color {
      color: #000000;
      padding-bottom: 5px;
    }
  }
  .detailContent {
    padding-bottom: 10px;
    padding-left: 32px;
  }
  .detailsTable {
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 60px;
  }
  .underlined {
    border-bottom: 4px solid white;
  }
  .TableHeaderStyling {
    height: 50px;
    display: flex;
    background: #00a7c4;
    justify-content: space-between;
    width: 100%;
  }
  .buttons {
    padding-right: 60px;
    padding-top: 12px;
    .accept {
      width: 100%;
      font-size: 15px;
    }
    .decline {
      background-color: navajowhite;
    }
  }
  .cookieBanner {
    border: 2px solid #00a5c0;
  }
  .content-color {
    font-weight: bold;
    line-height: 2;
  }
  .privacyNoticeColor {
    color: #00a7c4;
    padding-bottom: 4%;
    font-size: 15px;
    font-weight: bold;
  }
  .showDetails {
    font-size: 16px;
    color: #000000;
    padding-left: 20px;
    font-weight: bold;
  }
  .cookiesFont {
    font-size: 16px;
    color: #ffffff;
    padding-top: 5%;
    cursor: pointer;
  }
  .formatBg {
    background-color: #e7f6f9;
    padding: 10px 10px 20px 20px;
  }
  .formatText {
    font-size: 15px;
    color: #000000;
  }
  .tableHeight1 {
    max-height: 150px;
  }
  .bgColor {
    background-color: #d6e8ec;
  }
  .tableHeight2 {
    max-height: 170px;
  }
  .formatBg1 {
    background-color: #e7f6f9;
    padding: 10px 10px 20px 20px;
  }
  .showDetail {
    width: max-content;
  }
  .closeIcon {
    color: #ffffff;
    padding-top: 1%;
    padding-left: 5%;
  }
`;
