import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const DetailInstrumentInfo = ({
  handleChange,
  expandState,
  isDesktop,
  issuanceObj,
}) => (
  <Accordion
    className="accordianStyle"
    id="cp_inst_acc5"
    expanded={expandState["panel5"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel5a-content"
      id="panel5a-header"
      onClick={() => {
        handleChange("panel5");
      }}
    >
      <Typography variant="h3" id="cp_inst_typo5">
        Detail Instrument Information
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="cp_inst_accon5">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Initial Nominal Amount"}
          value={issuanceObj.initialNominalAmount}
        />
        <RowElement
          label={"Issued Nominal Amount"}
          value={issuanceObj.issuedNominalAmount}
        />
        <RowElement label={"UpTo Nominal"} value={issuanceObj.upToNominal} />
        <RowElement label={"Trading Method"} value={issuanceObj.quotation} />
        <RowElement
          label={"Payment Currency"}
          value={issuanceObj.paymentCurrency}
        />
        <RowElement
          label={"Issue Currency"}
          value={issuanceObj.nominalCurrency}
        />
        <RowElement
          label={"Applicable Law"}
          value={issuanceObj.applicableLaw}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Interest Payment Type"}
          value={issuanceObj.flagInterestRate}
        />
        <RowElement label={"Interest Rate"} value={issuanceObj.interestRate} />
        <RowElement
          label={"Interest Payment Frequency"}
          value={issuanceObj.interestPaymentFrequency}
        />
        <RowElement
          label={"Calculation Method"}
          value={issuanceObj.interestCalcType}
        />
        <RowElement
          label={"First Coupon"}
          value={issuanceObj.couponDevPeriodFirst}
        />
        <RowElement
          label={"Last Coupon"}
          value={issuanceObj.couponDevPeriodLast}
        />
        <RowElement
          label={"Redemption Rate"}
          value={issuanceObj.redemptionRate}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Private Placement"}
          value={issuanceObj.privatePlacement}
        />
        <RowElement
          label={"Minimum Exercise"}
          value={issuanceObj.exerciseMinimum}
        />
        <RowElement
          label={"Minimum Denomination"}
          value={issuanceObj.minTransferAmount}
        />
        <RowElement
          label={"Classification 1042S"}
          value={issuanceObj.irs1042sRelevant}
        />
        <RowElement
          label={"Relevant 871M"}
          value={issuanceObj.us871mRelevant}
        />
        <RowElement
          label={"Documentation Language"}
          value={issuanceObj.languageDocument}
        />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default DetailInstrumentInfo;
