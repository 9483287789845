import React, { useEffect } from "react";
import App from "./App";
import { ThemeProvider } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import "./index.css";
import { theme } from "./theme";
import {
  AuthenticationProvider,
  oidcLog,
  OidcSecure,
} from "@axa-fr/react-oidc-context"; // This react-oidc-context is a 3rd party library that we are using for Xact/OpanAM
import { oidcConfiguration, useNoIAM } from "./config";
import { UserProvider } from "./store/providers/UserProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd"; // antd --> used for calendar formatting
import en_GB from "antd/lib/locale/en_GB";
import moment from "moment"; // moment --> used for formatting timestamp date
import "moment/locale/en-gb";
import {
  Authenticating,
  AuthenticateError,
} from "./components/AuthenticationOverrides";
import decode from "jwt-decode";
moment.locale("en-gb");

const basename = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "";

function Authenticate() {
  useEffect(() => {
    //check token validity before launching the auth component
    let token = sessionStorage.getItem("jwtToken");
    if (token) {
      const expiryDateTime = decode(sessionStorage.getItem("jwtToken")).exp;
      const formatdate = moment(expiryDateTime * 1000).format(
        "YYYY-MM-DD  HH:mm:ss"
      );
      const currentTime = moment().format("YYYY-MM-DD  HH:mm:ss");
      const isExpired = moment(currentTime).isAfter(formatdate);
      if (isExpired) {
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }, []);

  return (
    <AuthenticationProvider // This AuthenticationProvider takes some configuration, authenticates the user and then redirects the user to our application (if successful, with xact/Xact_Clearstream).
      // Here we are passing some props to AuthenticationProvider component namely, configuration, loggerLevel and isEnabled
      configuration={oidcConfiguration} // configuration prop requires some oidcConfiguration
      loggerLevel={oidcLog.DEBUG} // some type of logging is required by the react-oidc-context library which we are providing via this loggerLevel
      isEnabled={!useNoIAM} // This flag is responsible for enabling the AuthenticationProvider which inturn is responsible for enabling and disabling Xact/Xact_Clearstream
      notAuthenticated={AuthenticateError}
      authenticating={Authenticating}
    >
      <OidcSecure>
        {" "}
        {/* If the flag isEnabled is false the configuration will not run and this oidcSecure will also not run.
                                                                 It tries to private our routes.
                                                                 It doesn't allow the user to access our application unless we are logged in to Xact/Xact_Clearstream
                                                             */}
        <UserProvider>
          {" "}
          {/* This is responsible for passing token to the application */}
          <ThemeProvider theme={theme}>
            {" "}
            {/* Line 38 to 46 --> part of code responsible for rendering our application and state */}
            <CssBaseline />
            <Router basename={basename}>
              <ConfigProvider
                locale={en_GB}
                theme={{ token: { colorPrimary: "#00a7c4" } }}
              >
                <App />
              </ConfigProvider>
            </Router>
          </ThemeProvider>
        </UserProvider>
      </OidcSecure>
    </AuthenticationProvider>
  );
}

export default Authenticate;
