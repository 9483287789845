/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Messages from './Messages';

/**
 * The AutomatedTask model module.
 * @module model/AutomatedTask
 * @version 1.0.3
 */
class AutomatedTask {
    /**
     * Constructs a new <code>AutomatedTask</code>.
     * @alias module:model/AutomatedTask
     */
    constructor() { 
        
        AutomatedTask.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AutomatedTask</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AutomatedTask} obj Optional instance to populate.
     * @return {module:model/AutomatedTask} The populated <code>AutomatedTask</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AutomatedTask();

            if (data.hasOwnProperty('Messages')) {
                obj['Messages'] = ApiClient.convertToType(data['Messages'], [Messages]);
            }
            if (data.hasOwnProperty('CustomMessage')) {
                obj['CustomMessage'] = ApiClient.convertToType(data['CustomMessage'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AutomatedTask</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AutomatedTask</code>.
     */
    static validateJSON(data) {
        if (data['Messages']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Messages'])) {
                throw new Error("Expected the field `Messages` to be an array in the JSON data but got " + data['Messages']);
            }
            // validate the optional field `Messages` (array)
            for (const item of data['Messages']) {
                Messages.validateJsonObject(item);
            };
        }
        // ensure the json data is a string
        if (data['CustomMessage'] && !(typeof data['CustomMessage'] === 'string' || data['CustomMessage'] instanceof String)) {
            throw new Error("Expected the field `CustomMessage` to be a primitive type in the JSON string but got " + data['CustomMessage']);
        }

        return true;
    }


}



/**
 * Model: Messages
 * @member {Array.<module:model/Messages>} Messages
 */
AutomatedTask.prototype['Messages'] = undefined;

/**
 * 
 * @member {String} CustomMessage
 */
AutomatedTask.prototype['CustomMessage'] = undefined;






export default AutomatedTask;

