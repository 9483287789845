import { useState } from "react"
import FLAGS from './constants'

const useSimplePopup = () => {
  const [show, setShow] = useState(false)
  const [flag, setFlag] = useState('')
  const [text, setText] = useState('')

  const showPopup = ({ flag = FLAGS.SUCCESS , text }) => {
    setFlag(flag)
    setText(text)
    setShow(true)
  }

  const hidePopup = () => {
    setFlag('')
    setText('')
    setShow(false)
  }

  return ({
    showPopup,
    hidePopup,
    show,
    text,
    flag,
  })
}

export default useSimplePopup