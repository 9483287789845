import React from "react";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import FLAGS from "./constants";
import useSimplePopup from "./useSimplePopup";

const SimplePopup = ({
  responseText,
  showPopUp,
  flagPopUp,
  handleClose,
}) => {
  return (
    <>
      {showPopUp && (
        <div id="drp_text_popup" className="popup">
          <div>
            {flagPopUp !== "error" ? (
              <SuccessPopup
                successPopupText={responseText}
                popupVisibility={showPopUp}
                handleClose={handleClose}
              />
            ) : (
              <ErrorPopup
                errorPopupText={responseText}
                popupVisibility={showPopUp}
                handleClose={handleClose}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SimplePopup;

export {
  FLAGS,
  useSimplePopup
}