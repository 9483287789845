import styled from "styled-components";
import { Container, Card, Dialog, Grid } from "@material-ui/core";

export const StyledHeader = styled(Card)`
  border: none;
  padding-top: 40px;
  padding-bottom: 21px;
  box-shadow: none;
  background-color: #ffffff;
  z-index: 1;
  .mainHeader {
    top: 110px;
    left: 421px;
  }
  .showJson {
    padding-left: 20px;
  }
  .MuiInputBase-root.Mui-disabled {
    color: currentColor;
  }
  .btn-style {
    margin-right: 8px;
  }
  .select-styles {
    width: 100%;
    padding-bottom: 8px;
  }
  .ForwardRef-iconButton-2 {
    color: #00a7c4;
  }

  .searchBar {
    height: 35px;
    border: 1px solid rgb(135 135 135 / 60%);
    border-radius: 17px;
    width: 100%;
    box-shadow: none;
  }
  .gridContainer {
    padding-top: 32px;
    margin: 0px;
  }
  .pT-8 {
    padding-top: 8px;
  }
  .pL-10 {
    padding-left: 10px;
  }
  .activeIcon {
    cursor: pointer;
  }
`;

export const StyledContainer = styled(Container)`
  padding-top: 0px;
  padding-left: 80px;
  padding-right: 80px;
  @media (min-width: 1280px) {
    max-width: inherit;
  }
  tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }
  tbody tr {
    height: 42px;
  }
  .MuiDialog-paperWidthXs {
    max-width: 491px;
  }
  .buttonStyle {
    color: #00a7c4;
    background-color: white;
    border: solid 1px #00a7c4;
  }
  .buttonStyleSync {
    color: #00a7c4;
    background-color: white;
    border: solid 1px #00a7c4;
    width: 190px;
  }
  .popup {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 75px;
  }
  .popup2 {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 6em;
    left: 65%;
    top: 180px;
  }
  .checkboxStyling {
    color: #00a7c4;
    padding: 13px 0px 0px 10px;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .typo-h6 {
    color: whitesmoke;
  }
  .initiatedStatus {
    color: #ff6600;
    line-height: 17px;
  }
  .legallyExistingStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .liveStatus {
    color: #00cc99;
    line-height: 17px;
  }
  .deadStatus {
    color: #878787;
    line-height: 17px;
  }
  .deactivatedStatus {
    color: #878787;
    line-height: 17px;
  }
  .paginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .pT-2 {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .fr {
    margin-left: 5px;
    background-color: #000099;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .frDeactivated {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    margin-left: 5px;
    background-color: #878787;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 10px;
  }
  .bs {
    background-color: #00cc99;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .bsDeactivated {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    background-color: #878787;
    border-radius: 9px;
    height: 20px;
    width: 35px;
    display: inline-block;
    padding-left: 8px;
  }
  .filterColumn .css-g1d714-ValueContainer {
    padding-top: 0px;
  }
  .filterColumn .select__control {
    margin-top: 10px;
    max-height: 35px;
    border: 1px solid rgb(135 135 135 / 50%);
  }
  .filterColumn .select__control .select__dropdown-indicator {
    color: #aaaaaa;
  }
  .datePickerColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .iconColumn {
    height: 15px;
  }
  .iconColumnTime {
    height: 15px;
    color: #00a7c4;
  }
  .rmsc .dropdown-heading {
    padding-bottom: 5px;
  }
  .rmsc .options {
    max-height: 200px !important;
  }
  .searchbarColumn,
  .selectColumn {
    max-width: inherit;
    width: 100%;
  }
  .selectColumn .dropdown-container {
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    resize: none;
  }
  .searchbarColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    padding-left: 10px;
    padding-top: 2px;
  }
  .tableStyling {
    box-shadow: 0px 1px 5px 1px #87878799;
    border-radius: 12px;
    background-color: #ffffff;
    min-height: 350px;
  }
  .lH-17 {
    line-height: 17px;
  }
  .pendingStatus {
    color: #00a7c4;
  }
  .senderNotifiedStatus {
    color: #ff6600;
  }
  .cellBorder {
    position: relative;
    border-bottom: 1px solid #00a5c0;
  }
  .MuiTableCell-root {
    padding: 14px 6px 12px 6px;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: #d3f3f7;
  }
  TableRow {
    width: 100%;
  }
  .select-styles {
    width: 80px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
      color: black;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
  .tableCellBorder {
    width: 35px;
    border-bottom: 1px solid #00a5c0;
    paddingright: 5;
  }
  .filterIcon {
    cursor: pointer;
  }
  .nodataicon {
    padding-top: 30px;
  }
  .noiconColumn {
    height: 30px;
  }
  .page {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 20px;
  }
  .padding1 {
    padding-left: 20px;
  }
  .padding2 {
    padding-left: 5px;
  }
`;

export const StyledSubSectionGrid = styled(Grid)`
  .popup {
    position: absolute;
    width: 29%;
    z-index: 2;
    left: 65%;
    top: 75px;
  }
  .acknowledged {
    border-radius: 10px;
    width: auto;
    margin-bottom: 20px;
  }
  .senderNotified {
    border-radius: 10px;
    width: auto;
    margin-bottom: 20px;
  }
  .icon2 {
    margin-right: 2%;
    margin-left: 2%;
    cursor: pointer;
  }
`;
export const StyledDialog = styled(Dialog)`
    .syncDialog{
        z-index: 1;
    }
    .uploadHeading{
        font-size: 17px;
        padding-top: 40px;
        padding-left: 24px
    }
    .selectXML{
        color: #000000;
        padding-top: 25px
    }
    .paddingXML{
        padding-top: 10px
    }
    .chooseUnderline{
        padding-top: 10px;
        border-bottom: 1px solid black
    }
    .textFormat{
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
    }
    .float{
        float = right
    }
    .paddingFileUpload{
        padding-left: 2%
    }
    .display{
        display: none
    }
    .errorFormat{
        color: #CC0099
    }
    .paddingCancel{
        padding-bottom: 30px;
        padding-right: 20px;
        padding-top: 20px
    }
    .colorCancel{
        color: #00A7C4;
        width: 8%
    }
    .marginSubmit{
        margin-right: 14px
    }
    .syncConfirm{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        padding-top: 20px;
        padding-left: 10px
    }
    .syncYes{
        padding-top: "20px;
        padding-bottom: "50px;
        padding-right: 100px;
    }
    .acknowledged{
        margin-bottom:25px;
    }
    .cancel{
        margin-bottom:25px;
    }

`;
