/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The HLC model module.
 * @module model/HLC
 * @version 1.0.3
 */
class HLC {
    /**
     * Constructs a new <code>HLC</code>.
     * @alias module:model/HLC
     */
    constructor() { 
        
        HLC.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>HLC</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/HLC} obj Optional instance to populate.
     * @return {module:model/HLC} The populated <code>HLC</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new HLC();

            if (data.hasOwnProperty('Price')) {
                obj['Price'] = ApiClient.convertToType(data['Price'], 'String');
            }
            if (data.hasOwnProperty('TransactionStatus')) {
                obj['TransactionStatus'] = ApiClient.convertToType(data['TransactionStatus'], 'String');
            }
            if (data.hasOwnProperty('ScTemplate')) {
                obj['ScTemplate'] = ApiClient.convertToType(data['ScTemplate'], 'String');
            }
            if (data.hasOwnProperty('TokenNetwork')) {
                obj['TokenNetwork'] = ApiClient.convertToType(data['TokenNetwork'], 'String');
            }
            if (data.hasOwnProperty('TokenAmount')) {
                obj['TokenAmount'] = ApiClient.convertToType(data['TokenAmount'], 'Number');
            }
            if (data.hasOwnProperty('BcAddress')) {
                obj['BcAddress'] = ApiClient.convertToType(data['BcAddress'], 'String');
            }
            if (data.hasOwnProperty('RequestID')) {
                obj['RequestID'] = ApiClient.convertToType(data['RequestID'], 'Number');
            }
            if (data.hasOwnProperty('TipsId')) {
                obj['TipsId'] = ApiClient.convertToType(data['TipsId'], 'String');
            }
            if (data.hasOwnProperty('Info')) {
                obj['Info'] = ApiClient.convertToType(data['Info'], 'String');
            }
            if (data.hasOwnProperty('HashCancellationKey')) {
                obj['HashCancellationKey'] = ApiClient.convertToType(data['HashCancellationKey'], 'String');
            }
            if (data.hasOwnProperty('HashExecutionKey')) {
                obj['HashExecutionKey'] = ApiClient.convertToType(data['HashExecutionKey'], 'String');
            }
            if (data.hasOwnProperty('BcBuyer')) {
                obj['BcBuyer'] = ApiClient.convertToType(data['BcBuyer'], 'String');
            }
            if (data.hasOwnProperty('BcSeller')) {
                obj['BcSeller'] = ApiClient.convertToType(data['BcSeller'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>HLC</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HLC</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Price'] && !(typeof data['Price'] === 'string' || data['Price'] instanceof String)) {
            throw new Error("Expected the field `Price` to be a primitive type in the JSON string but got " + data['Price']);
        }
        // ensure the json data is a string
        if (data['TransactionStatus'] && !(typeof data['TransactionStatus'] === 'string' || data['TransactionStatus'] instanceof String)) {
            throw new Error("Expected the field `TransactionStatus` to be a primitive type in the JSON string but got " + data['TransactionStatus']);
        }
        // ensure the json data is a string
        if (data['ScTemplate'] && !(typeof data['ScTemplate'] === 'string' || data['ScTemplate'] instanceof String)) {
            throw new Error("Expected the field `ScTemplate` to be a primitive type in the JSON string but got " + data['ScTemplate']);
        }
        // ensure the json data is a string
        if (data['TokenNetwork'] && !(typeof data['TokenNetwork'] === 'string' || data['TokenNetwork'] instanceof String)) {
            throw new Error("Expected the field `TokenNetwork` to be a primitive type in the JSON string but got " + data['TokenNetwork']);
        }
        // ensure the json data is a string
        if (data['BcAddress'] && !(typeof data['BcAddress'] === 'string' || data['BcAddress'] instanceof String)) {
            throw new Error("Expected the field `BcAddress` to be a primitive type in the JSON string but got " + data['BcAddress']);
        }
        // ensure the json data is a string
        if (data['TipsId'] && !(typeof data['TipsId'] === 'string' || data['TipsId'] instanceof String)) {
            throw new Error("Expected the field `TipsId` to be a primitive type in the JSON string but got " + data['TipsId']);
        }
        // ensure the json data is a string
        if (data['Info'] && !(typeof data['Info'] === 'string' || data['Info'] instanceof String)) {
            throw new Error("Expected the field `Info` to be a primitive type in the JSON string but got " + data['Info']);
        }
        // ensure the json data is a string
        if (data['HashCancellationKey'] && !(typeof data['HashCancellationKey'] === 'string' || data['HashCancellationKey'] instanceof String)) {
            throw new Error("Expected the field `HashCancellationKey` to be a primitive type in the JSON string but got " + data['HashCancellationKey']);
        }
        // ensure the json data is a string
        if (data['HashExecutionKey'] && !(typeof data['HashExecutionKey'] === 'string' || data['HashExecutionKey'] instanceof String)) {
            throw new Error("Expected the field `HashExecutionKey` to be a primitive type in the JSON string but got " + data['HashExecutionKey']);
        }
        // ensure the json data is a string
        if (data['BcBuyer'] && !(typeof data['BcBuyer'] === 'string' || data['BcBuyer'] instanceof String)) {
            throw new Error("Expected the field `BcBuyer` to be a primitive type in the JSON string but got " + data['BcBuyer']);
        }
        // ensure the json data is a string
        if (data['BcSeller'] && !(typeof data['BcSeller'] === 'string' || data['BcSeller'] instanceof String)) {
            throw new Error("Expected the field `BcSeller` to be a primitive type in the JSON string but got " + data['BcSeller']);
        }

        return true;
    }


}



/**
 * Agreed Price for TokenAmount[600]
 * @member {String} Price
 */
HLC.prototype['Price'] = undefined;

/**
 * Hash Link Contract status[601]
 * @member {String} TransactionStatus
 */
HLC.prototype['TransactionStatus'] = undefined;

/**
 * Template identifier of HLC[602]
 * @member {String} ScTemplate
 */
HLC.prototype['ScTemplate'] = undefined;

/**
 * Network of HLC[603]
 * @member {String} TokenNetwork
 */
HLC.prototype['TokenNetwork'] = undefined;

/**
 * Agreed TokenAmount for Price[530]
 * @member {Number} TokenAmount
 */
HLC.prototype['TokenAmount'] = undefined;

/**
 * Blockchain address of Hash Link Contract[555]
 * @member {String} BcAddress
 */
HLC.prototype['BcAddress'] = undefined;

/**
 * RequestID is returned to the seller upon initialising the DvP. The requestID allows the seller to retrieve the blockchain address of the smart contract after deployment.[557]
 * @member {Number} RequestID
 */
HLC.prototype['RequestID'] = undefined;

/**
 * TipsId for DvP[558]
 * @member {String} TipsId
 */
HLC.prototype['TipsId'] = undefined;

/**
 * Info field in Hash Link Contract[559]
 * @member {String} Info
 */
HLC.prototype['Info'] = undefined;

/**
 * Hash of cancellation key[560]
 * @member {String} HashCancellationKey
 */
HLC.prototype['HashCancellationKey'] = undefined;

/**
 * Hash of execution key[561]
 * @member {String} HashExecutionKey
 */
HLC.prototype['HashExecutionKey'] = undefined;

/**
 * Blockchain address of buyer[562]
 * @member {String} BcBuyer
 */
HLC.prototype['BcBuyer'] = undefined;

/**
 * Blockchain address of seller[563]
 * @member {String} BcSeller
 */
HLC.prototype['BcSeller'] = undefined;






export default HLC;

