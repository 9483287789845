/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Attribute from './Attribute';
import Clearing from './Clearing';
import Documentation from './Documentation';
import Form from './Form';
import InterestFeatures from './InterestFeatures';
import Jurisdiction from './Jurisdiction';
import Party from './Party';
import Pricing from './Pricing';
import ProductGovernance from './ProductGovernance';
import ProductRating from './ProductRating';
import Programme from './Programme';
import QuoteSystem from './QuoteSystem';
import RedemptionFeatures from './RedemptionFeatures';
import Tax from './Tax';
import TerminationRights from './TerminationRights';
import Underlying from './Underlying';
import VenueData from './VenueData';

/**
 * The Issuance model module.
 * @module model/Issuance
 * @version 1.0.3
 */
class Issuance {
    /**
     * Constructs a new <code>Issuance</code>.
     * @alias module:model/Issuance
     */
    constructor() { 
        
        Issuance.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Issuance</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Issuance} obj Optional instance to populate.
     * @return {module:model/Issuance} The populated <code>Issuance</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Issuance();

            if (data.hasOwnProperty('IssuePrice')) {
                obj['IssuePrice'] = ApiClient.convertToType(data['IssuePrice'], 'Number');
            }
            if (data.hasOwnProperty('ValueDate')) {
                obj['ValueDate'] = ApiClient.convertToType(data['ValueDate'], 'Date');
            }
            if (data.hasOwnProperty('MaturityDate')) {
                obj['MaturityDate'] = ApiClient.convertToType(data['MaturityDate'], 'Date');
            }
            if (data.hasOwnProperty('FinalValuationDate')) {
                obj['FinalValuationDate'] = ApiClient.convertToType(data['FinalValuationDate'], 'Date');
            }
            if (data.hasOwnProperty('SigningDate')) {
                obj['SigningDate'] = ApiClient.convertToType(data['SigningDate'], 'Date');
            }
            if (data.hasOwnProperty('SecurityLongName')) {
                obj['SecurityLongName'] = ApiClient.convertToType(data['SecurityLongName'], 'String');
            }
            if (data.hasOwnProperty('SecurityShortName')) {
                obj['SecurityShortName'] = ApiClient.convertToType(data['SecurityShortName'], 'String');
            }
            if (data.hasOwnProperty('TradingMethod')) {
                obj['TradingMethod'] = ApiClient.convertToType(data['TradingMethod'], 'String');
            }
            if (data.hasOwnProperty('PrivatePlacement')) {
                obj['PrivatePlacement'] = ApiClient.convertToType(data['PrivatePlacement'], 'Boolean');
            }
            if (data.hasOwnProperty('OpenEnded')) {
                obj['OpenEnded'] = ApiClient.convertToType(data['OpenEnded'], 'Boolean');
            }
            if (data.hasOwnProperty('EusipaID')) {
                obj['EusipaID'] = ApiClient.convertToType(data['EusipaID'], 'String');
            }
            if (data.hasOwnProperty('Type')) {
                obj['Type'] = ApiClient.convertToType(data['Type'], 'String');
            }
            if (data.hasOwnProperty('CFI')) {
                obj['CFI'] = ApiClient.convertToType(data['CFI'], 'String');
            }
            if (data.hasOwnProperty('ParticipationFactor')) {
                obj['ParticipationFactor'] = ApiClient.convertToType(data['ParticipationFactor'], 'Number');
            }
            if (data.hasOwnProperty('Seniority')) {
                obj['Seniority'] = ApiClient.convertToType(data['Seniority'], 'String');
            }
            if (data.hasOwnProperty('StatusOfTheNotes')) {
                obj['StatusOfTheNotes'] = ApiClient.convertToType(data['StatusOfTheNotes'], 'String');
            }
            if (data.hasOwnProperty('TimeOfExecution')) {
                obj['TimeOfExecution'] = ApiClient.convertToType(data['TimeOfExecution'], 'Date');
            }
            if (data.hasOwnProperty('UseOfProceeds')) {
                obj['UseOfProceeds'] = ApiClient.convertToType(data['UseOfProceeds'], 'Boolean');
            }
            if (data.hasOwnProperty('IssuerClearingHouse')) {
                obj['IssuerClearingHouse'] = ApiClient.convertToType(data['IssuerClearingHouse'], 'String');
            }
            if (data.hasOwnProperty('IssuanceChannel')) {
                obj['IssuanceChannel'] = ApiClient.convertToType(data['IssuanceChannel'], 'String');
            }
            if (data.hasOwnProperty('ClearingSystem')) {
                obj['ClearingSystem'] = ApiClient.convertToType(data['ClearingSystem'], 'String');
            }
            if (data.hasOwnProperty('PotentialEurosystemEligible')) {
                obj['PotentialEurosystemEligible'] = ApiClient.convertToType(data['PotentialEurosystemEligible'], 'Boolean');
            }
            if (data.hasOwnProperty('IsListed')) {
                obj['IsListed'] = ApiClient.convertToType(data['IsListed'], 'Boolean');
            }
            if (data.hasOwnProperty('ESGCategory')) {
                obj['ESGCategory'] = ApiClient.convertToType(data['ESGCategory'], 'String');
            }
            if (data.hasOwnProperty('IssuanceDistribution')) {
                obj['IssuanceDistribution'] = ApiClient.convertToType(data['IssuanceDistribution'], 'String');
            }
            if (data.hasOwnProperty('NetProceeds')) {
                obj['NetProceeds'] = ApiClient.convertToType(data['NetProceeds'], 'Number');
            }
            if (data.hasOwnProperty('SettlementCurrency')) {
                obj['SettlementCurrency'] = ApiClient.convertToType(data['SettlementCurrency'], 'String');
            }
            if (data.hasOwnProperty('SubscriptionFromDateTime')) {
                obj['SubscriptionFromDateTime'] = ApiClient.convertToType(data['SubscriptionFromDateTime'], 'Date');
            }
            if (data.hasOwnProperty('SubscriptionToDateTime')) {
                obj['SubscriptionToDateTime'] = ApiClient.convertToType(data['SubscriptionToDateTime'], 'Date');
            }
            if (data.hasOwnProperty('CustodyType')) {
                obj['CustodyType'] = ApiClient.convertToType(data['CustodyType'], 'String');
            }
            if (data.hasOwnProperty('CustomData')) {
                obj['CustomData'] = ApiClient.convertToType(data['CustomData'], 'String');
            }
            if (data.hasOwnProperty('MinimumDenomination')) {
                obj['MinimumDenomination'] = ApiClient.convertToType(data['MinimumDenomination'], 'Number');
            }
            if (data.hasOwnProperty('StepID')) {
                obj['StepID'] = ApiClient.convertToType(data['StepID'], 'String');
            }
            if (data.hasOwnProperty('ExercisePeriodStart')) {
                obj['ExercisePeriodStart'] = ApiClient.convertToType(data['ExercisePeriodStart'], 'Date');
            }
            if (data.hasOwnProperty('ExercisePeriodEnd')) {
                obj['ExercisePeriodEnd'] = ApiClient.convertToType(data['ExercisePeriodEnd'], 'Date');
            }
            if (data.hasOwnProperty('NominalCurrency')) {
                obj['NominalCurrency'] = ApiClient.convertToType(data['NominalCurrency'], 'String');
            }
            if (data.hasOwnProperty('InitialNominalAmount')) {
                obj['InitialNominalAmount'] = ApiClient.convertToType(data['InitialNominalAmount'], 'Number');
            }
            if (data.hasOwnProperty('InstrumentStructureCategory')) {
                obj['InstrumentStructureCategory'] = ApiClient.convertToType(data['InstrumentStructureCategory'], 'String');
            }
            if (data.hasOwnProperty('IssueDate')) {
                obj['IssueDate'] = ApiClient.convertToType(data['IssueDate'], 'Date');
            }
            if (data.hasOwnProperty('PlaceOfIncorporation')) {
                obj['PlaceOfIncorporation'] = ApiClient.convertToType(data['PlaceOfIncorporation'], 'String');
            }
            if (data.hasOwnProperty('AggregateProceedsCurrency')) {
                obj['AggregateProceedsCurrency'] = ApiClient.convertToType(data['AggregateProceedsCurrency'], 'Number');
            }
            if (data.hasOwnProperty('MinimumNoticeDayCount')) {
                obj['MinimumNoticeDayCount'] = ApiClient.convertToType(data['MinimumNoticeDayCount'], 'Number');
            }
            if (data.hasOwnProperty('FaceValueForUnitIssues')) {
                obj['FaceValueForUnitIssues'] = ApiClient.convertToType(data['FaceValueForUnitIssues'], 'Number');
            }
            if (data.hasOwnProperty('IsmagAdherance')) {
                obj['IsmagAdherance'] = ApiClient.convertToType(data['IsmagAdherance'], 'String');
            }
            if (data.hasOwnProperty('InstrumentClass')) {
                obj['InstrumentClass'] = ApiClient.convertToType(data['InstrumentClass'], 'String');
            }
            if (data.hasOwnProperty('ExtensionUponNotificationOrAutomatic')) {
                obj['ExtensionUponNotificationOrAutomatic'] = ApiClient.convertToType(data['ExtensionUponNotificationOrAutomatic'], 'String');
            }
            if (data.hasOwnProperty('InstrumentCategory')) {
                obj['InstrumentCategory'] = ApiClient.convertToType(data['InstrumentCategory'], 'String');
            }
            if (data.hasOwnProperty('LegalRestriction')) {
                obj['LegalRestriction'] = ApiClient.convertToType(data['LegalRestriction'], 'String');
            }
            if (data.hasOwnProperty('UnitIssuePrice')) {
                obj['UnitIssuePrice'] = ApiClient.convertToType(data['UnitIssuePrice'], 'Number');
            }
            if (data.hasOwnProperty('Confidential')) {
                obj['Confidential'] = ApiClient.convertToType(data['Confidential'], 'Boolean');
            }
            if (data.hasOwnProperty('UsTaxable')) {
                obj['UsTaxable'] = ApiClient.convertToType(data['UsTaxable'], 'Boolean');
            }
            if (data.hasOwnProperty('UpTo')) {
                obj['UpTo'] = ApiClient.convertToType(data['UpTo'], 'Boolean');
            }
            if (data.hasOwnProperty('IssuedNominalAmount')) {
                obj['IssuedNominalAmount'] = ApiClient.convertToType(data['IssuedNominalAmount'], 'Number');
            }
            if (data.hasOwnProperty('BSWCategory')) {
                obj['BSWCategory'] = ApiClient.convertToType(data['BSWCategory'], 'String');
            }
            if (data.hasOwnProperty('EusipaName')) {
                obj['EusipaName'] = ApiClient.convertToType(data['EusipaName'], 'String');
            }
            if (data.hasOwnProperty('BSWClass')) {
                obj['BSWClass'] = ApiClient.convertToType(data['BSWClass'], 'String');
            }
            if (data.hasOwnProperty('Description')) {
                obj['Description'] = ApiClient.convertToType(data['Description'], 'String');
            }
            if (data.hasOwnProperty('EventsOfDefault')) {
                obj['EventsOfDefault'] = ApiClient.convertToType(data['EventsOfDefault'], 'String');
            }
            if (data.hasOwnProperty('NegativePledgeDetails')) {
                obj['NegativePledgeDetails'] = ApiClient.convertToType(data['NegativePledgeDetails'], 'String');
            }
            if (data.hasOwnProperty('SeriesNumber')) {
                obj['SeriesNumber'] = ApiClient.convertToType(data['SeriesNumber'], 'String');
            }
            if (data.hasOwnProperty('ParentISIN')) {
                obj['ParentISIN'] = ApiClient.convertToType(data['ParentISIN'], 'String');
            }
            if (data.hasOwnProperty('FixingFrequency')) {
                obj['FixingFrequency'] = ApiClient.convertToType(data['FixingFrequency'], 'String');
            }
            if (data.hasOwnProperty('CreditEventType')) {
                obj['CreditEventType'] = ApiClient.convertToType(data['CreditEventType'], 'String');
            }
            if (data.hasOwnProperty('ZeroRecovery')) {
                obj['ZeroRecovery'] = ApiClient.convertToType(data['ZeroRecovery'], 'Boolean');
            }
            if (data.hasOwnProperty('Multiplier')) {
                obj['Multiplier'] = ApiClient.convertToType(data['Multiplier'], 'Number');
            }
            if (data.hasOwnProperty('OptionType')) {
                obj['OptionType'] = ApiClient.convertToType(data['OptionType'], 'String');
            }
            if (data.hasOwnProperty('OptionStyle')) {
                obj['OptionStyle'] = ApiClient.convertToType(data['OptionStyle'], 'String');
            }
            if (data.hasOwnProperty('OTCChannels')) {
                obj['OTCChannels'] = ApiClient.convertToType(data['OTCChannels'], 'String');
            }
            if (data.hasOwnProperty('MinimumQuantity')) {
                obj['MinimumQuantity'] = ApiClient.convertToType(data['MinimumQuantity'], 'Number');
            }
            if (data.hasOwnProperty('CoveredMaturityType')) {
                obj['CoveredMaturityType'] = ApiClient.convertToType(data['CoveredMaturityType'], 'String');
            }
            if (data.hasOwnProperty('ExtendedMaturityDate')) {
                obj['ExtendedMaturityDate'] = ApiClient.convertToType(data['ExtendedMaturityDate'], 'Date');
            }
            if (data.hasOwnProperty('Perpetual')) {
                obj['Perpetual'] = ApiClient.convertToType(data['Perpetual'], 'Boolean');
            }
            if (data.hasOwnProperty('RatingType')) {
                obj['RatingType'] = ApiClient.convertToType(data['RatingType'], 'String');
            }
            if (data.hasOwnProperty('InstrumentClassification')) {
                obj['InstrumentClassification'] = ApiClient.convertToType(data['InstrumentClassification'], 'String');
            }
            if (data.hasOwnProperty('OutstandingAmount')) {
                obj['OutstandingAmount'] = ApiClient.convertToType(data['OutstandingAmount'], 'Number');
            }
            if (data.hasOwnProperty('Attribute')) {
                obj['Attribute'] = Attribute.constructFromObject(data['Attribute']);
            }
            if (data.hasOwnProperty('Clearing')) {
                obj['Clearing'] = Clearing.constructFromObject(data['Clearing']);
            }
            if (data.hasOwnProperty('Documentation')) {
                obj['Documentation'] = Documentation.constructFromObject(data['Documentation']);
            }
            if (data.hasOwnProperty('Form')) {
                obj['Form'] = Form.constructFromObject(data['Form']);
            }
            if (data.hasOwnProperty('InterestFeatures')) {
                obj['InterestFeatures'] = InterestFeatures.constructFromObject(data['InterestFeatures']);
            }
            if (data.hasOwnProperty('Jurisdiction')) {
                obj['Jurisdiction'] = Jurisdiction.constructFromObject(data['Jurisdiction']);
            }
            if (data.hasOwnProperty('TerminationRights')) {
                obj['TerminationRights'] = TerminationRights.constructFromObject(data['TerminationRights']);
            }
            if (data.hasOwnProperty('Party')) {
                obj['Party'] = ApiClient.convertToType(data['Party'], [Party]);
            }
            if (data.hasOwnProperty('Pricing')) {
                obj['Pricing'] = Pricing.constructFromObject(data['Pricing']);
            }
            if (data.hasOwnProperty('ProductGovernance')) {
                obj['ProductGovernance'] = ProductGovernance.constructFromObject(data['ProductGovernance']);
            }
            if (data.hasOwnProperty('Programme')) {
                obj['Programme'] = Programme.constructFromObject(data['Programme']);
            }
            if (data.hasOwnProperty('QuoteSystem')) {
                obj['QuoteSystem'] = QuoteSystem.constructFromObject(data['QuoteSystem']);
            }
            if (data.hasOwnProperty('ProductRating')) {
                obj['ProductRating'] = ApiClient.convertToType(data['ProductRating'], [ProductRating]);
            }
            if (data.hasOwnProperty('RedemptionFeatures')) {
                obj['RedemptionFeatures'] = RedemptionFeatures.constructFromObject(data['RedemptionFeatures']);
            }
            if (data.hasOwnProperty('Tax')) {
                obj['Tax'] = Tax.constructFromObject(data['Tax']);
            }
            if (data.hasOwnProperty('Underlying')) {
                obj['Underlying'] = ApiClient.convertToType(data['Underlying'], [Underlying]);
            }
            if (data.hasOwnProperty('VenueData')) {
                obj['VenueData'] = VenueData.constructFromObject(data['VenueData']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Issuance</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Issuance</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['SecurityLongName'] && !(typeof data['SecurityLongName'] === 'string' || data['SecurityLongName'] instanceof String)) {
            throw new Error("Expected the field `SecurityLongName` to be a primitive type in the JSON string but got " + data['SecurityLongName']);
        }
        // ensure the json data is a string
        if (data['SecurityShortName'] && !(typeof data['SecurityShortName'] === 'string' || data['SecurityShortName'] instanceof String)) {
            throw new Error("Expected the field `SecurityShortName` to be a primitive type in the JSON string but got " + data['SecurityShortName']);
        }
        // ensure the json data is a string
        if (data['TradingMethod'] && !(typeof data['TradingMethod'] === 'string' || data['TradingMethod'] instanceof String)) {
            throw new Error("Expected the field `TradingMethod` to be a primitive type in the JSON string but got " + data['TradingMethod']);
        }
        // ensure the json data is a string
        if (data['EusipaID'] && !(typeof data['EusipaID'] === 'string' || data['EusipaID'] instanceof String)) {
            throw new Error("Expected the field `EusipaID` to be a primitive type in the JSON string but got " + data['EusipaID']);
        }
        // ensure the json data is a string
        if (data['Type'] && !(typeof data['Type'] === 'string' || data['Type'] instanceof String)) {
            throw new Error("Expected the field `Type` to be a primitive type in the JSON string but got " + data['Type']);
        }
        // ensure the json data is a string
        if (data['CFI'] && !(typeof data['CFI'] === 'string' || data['CFI'] instanceof String)) {
            throw new Error("Expected the field `CFI` to be a primitive type in the JSON string but got " + data['CFI']);
        }
        // ensure the json data is a string
        if (data['Seniority'] && !(typeof data['Seniority'] === 'string' || data['Seniority'] instanceof String)) {
            throw new Error("Expected the field `Seniority` to be a primitive type in the JSON string but got " + data['Seniority']);
        }
        // ensure the json data is a string
        if (data['StatusOfTheNotes'] && !(typeof data['StatusOfTheNotes'] === 'string' || data['StatusOfTheNotes'] instanceof String)) {
            throw new Error("Expected the field `StatusOfTheNotes` to be a primitive type in the JSON string but got " + data['StatusOfTheNotes']);
        }
        // ensure the json data is a string
        if (data['IssuerClearingHouse'] && !(typeof data['IssuerClearingHouse'] === 'string' || data['IssuerClearingHouse'] instanceof String)) {
            throw new Error("Expected the field `IssuerClearingHouse` to be a primitive type in the JSON string but got " + data['IssuerClearingHouse']);
        }
        // ensure the json data is a string
        if (data['IssuanceChannel'] && !(typeof data['IssuanceChannel'] === 'string' || data['IssuanceChannel'] instanceof String)) {
            throw new Error("Expected the field `IssuanceChannel` to be a primitive type in the JSON string but got " + data['IssuanceChannel']);
        }
        // ensure the json data is a string
        if (data['ClearingSystem'] && !(typeof data['ClearingSystem'] === 'string' || data['ClearingSystem'] instanceof String)) {
            throw new Error("Expected the field `ClearingSystem` to be a primitive type in the JSON string but got " + data['ClearingSystem']);
        }
        // ensure the json data is a string
        if (data['ESGCategory'] && !(typeof data['ESGCategory'] === 'string' || data['ESGCategory'] instanceof String)) {
            throw new Error("Expected the field `ESGCategory` to be a primitive type in the JSON string but got " + data['ESGCategory']);
        }
        // ensure the json data is a string
        if (data['IssuanceDistribution'] && !(typeof data['IssuanceDistribution'] === 'string' || data['IssuanceDistribution'] instanceof String)) {
            throw new Error("Expected the field `IssuanceDistribution` to be a primitive type in the JSON string but got " + data['IssuanceDistribution']);
        }
        // ensure the json data is a string
        if (data['SettlementCurrency'] && !(typeof data['SettlementCurrency'] === 'string' || data['SettlementCurrency'] instanceof String)) {
            throw new Error("Expected the field `SettlementCurrency` to be a primitive type in the JSON string but got " + data['SettlementCurrency']);
        }
        // ensure the json data is a string
        if (data['CustodyType'] && !(typeof data['CustodyType'] === 'string' || data['CustodyType'] instanceof String)) {
            throw new Error("Expected the field `CustodyType` to be a primitive type in the JSON string but got " + data['CustodyType']);
        }
        // ensure the json data is a string
        if (data['CustomData'] && !(typeof data['CustomData'] === 'string' || data['CustomData'] instanceof String)) {
            throw new Error("Expected the field `CustomData` to be a primitive type in the JSON string but got " + data['CustomData']);
        }
        // ensure the json data is a string
        if (data['StepID'] && !(typeof data['StepID'] === 'string' || data['StepID'] instanceof String)) {
            throw new Error("Expected the field `StepID` to be a primitive type in the JSON string but got " + data['StepID']);
        }
        // ensure the json data is a string
        if (data['NominalCurrency'] && !(typeof data['NominalCurrency'] === 'string' || data['NominalCurrency'] instanceof String)) {
            throw new Error("Expected the field `NominalCurrency` to be a primitive type in the JSON string but got " + data['NominalCurrency']);
        }
        // ensure the json data is a string
        if (data['InstrumentStructureCategory'] && !(typeof data['InstrumentStructureCategory'] === 'string' || data['InstrumentStructureCategory'] instanceof String)) {
            throw new Error("Expected the field `InstrumentStructureCategory` to be a primitive type in the JSON string but got " + data['InstrumentStructureCategory']);
        }
        // ensure the json data is a string
        if (data['PlaceOfIncorporation'] && !(typeof data['PlaceOfIncorporation'] === 'string' || data['PlaceOfIncorporation'] instanceof String)) {
            throw new Error("Expected the field `PlaceOfIncorporation` to be a primitive type in the JSON string but got " + data['PlaceOfIncorporation']);
        }
        // ensure the json data is a string
        if (data['IsmagAdherance'] && !(typeof data['IsmagAdherance'] === 'string' || data['IsmagAdherance'] instanceof String)) {
            throw new Error("Expected the field `IsmagAdherance` to be a primitive type in the JSON string but got " + data['IsmagAdherance']);
        }
        // ensure the json data is a string
        if (data['InstrumentClass'] && !(typeof data['InstrumentClass'] === 'string' || data['InstrumentClass'] instanceof String)) {
            throw new Error("Expected the field `InstrumentClass` to be a primitive type in the JSON string but got " + data['InstrumentClass']);
        }
        // ensure the json data is a string
        if (data['ExtensionUponNotificationOrAutomatic'] && !(typeof data['ExtensionUponNotificationOrAutomatic'] === 'string' || data['ExtensionUponNotificationOrAutomatic'] instanceof String)) {
            throw new Error("Expected the field `ExtensionUponNotificationOrAutomatic` to be a primitive type in the JSON string but got " + data['ExtensionUponNotificationOrAutomatic']);
        }
        // ensure the json data is a string
        if (data['InstrumentCategory'] && !(typeof data['InstrumentCategory'] === 'string' || data['InstrumentCategory'] instanceof String)) {
            throw new Error("Expected the field `InstrumentCategory` to be a primitive type in the JSON string but got " + data['InstrumentCategory']);
        }
        // ensure the json data is a string
        if (data['LegalRestriction'] && !(typeof data['LegalRestriction'] === 'string' || data['LegalRestriction'] instanceof String)) {
            throw new Error("Expected the field `LegalRestriction` to be a primitive type in the JSON string but got " + data['LegalRestriction']);
        }
        // ensure the json data is a string
        if (data['BSWCategory'] && !(typeof data['BSWCategory'] === 'string' || data['BSWCategory'] instanceof String)) {
            throw new Error("Expected the field `BSWCategory` to be a primitive type in the JSON string but got " + data['BSWCategory']);
        }
        // ensure the json data is a string
        if (data['EusipaName'] && !(typeof data['EusipaName'] === 'string' || data['EusipaName'] instanceof String)) {
            throw new Error("Expected the field `EusipaName` to be a primitive type in the JSON string but got " + data['EusipaName']);
        }
        // ensure the json data is a string
        if (data['BSWClass'] && !(typeof data['BSWClass'] === 'string' || data['BSWClass'] instanceof String)) {
            throw new Error("Expected the field `BSWClass` to be a primitive type in the JSON string but got " + data['BSWClass']);
        }
        // ensure the json data is a string
        if (data['Description'] && !(typeof data['Description'] === 'string' || data['Description'] instanceof String)) {
            throw new Error("Expected the field `Description` to be a primitive type in the JSON string but got " + data['Description']);
        }
        // ensure the json data is a string
        if (data['EventsOfDefault'] && !(typeof data['EventsOfDefault'] === 'string' || data['EventsOfDefault'] instanceof String)) {
            throw new Error("Expected the field `EventsOfDefault` to be a primitive type in the JSON string but got " + data['EventsOfDefault']);
        }
        // ensure the json data is a string
        if (data['NegativePledgeDetails'] && !(typeof data['NegativePledgeDetails'] === 'string' || data['NegativePledgeDetails'] instanceof String)) {
            throw new Error("Expected the field `NegativePledgeDetails` to be a primitive type in the JSON string but got " + data['NegativePledgeDetails']);
        }
        // ensure the json data is a string
        if (data['SeriesNumber'] && !(typeof data['SeriesNumber'] === 'string' || data['SeriesNumber'] instanceof String)) {
            throw new Error("Expected the field `SeriesNumber` to be a primitive type in the JSON string but got " + data['SeriesNumber']);
        }
        // ensure the json data is a string
        if (data['ParentISIN'] && !(typeof data['ParentISIN'] === 'string' || data['ParentISIN'] instanceof String)) {
            throw new Error("Expected the field `ParentISIN` to be a primitive type in the JSON string but got " + data['ParentISIN']);
        }
        // ensure the json data is a string
        if (data['FixingFrequency'] && !(typeof data['FixingFrequency'] === 'string' || data['FixingFrequency'] instanceof String)) {
            throw new Error("Expected the field `FixingFrequency` to be a primitive type in the JSON string but got " + data['FixingFrequency']);
        }
        // ensure the json data is a string
        if (data['CreditEventType'] && !(typeof data['CreditEventType'] === 'string' || data['CreditEventType'] instanceof String)) {
            throw new Error("Expected the field `CreditEventType` to be a primitive type in the JSON string but got " + data['CreditEventType']);
        }
        // ensure the json data is a string
        if (data['OptionType'] && !(typeof data['OptionType'] === 'string' || data['OptionType'] instanceof String)) {
            throw new Error("Expected the field `OptionType` to be a primitive type in the JSON string but got " + data['OptionType']);
        }
        // ensure the json data is a string
        if (data['OptionStyle'] && !(typeof data['OptionStyle'] === 'string' || data['OptionStyle'] instanceof String)) {
            throw new Error("Expected the field `OptionStyle` to be a primitive type in the JSON string but got " + data['OptionStyle']);
        }
        // ensure the json data is a string
        if (data['OTCChannels'] && !(typeof data['OTCChannels'] === 'string' || data['OTCChannels'] instanceof String)) {
            throw new Error("Expected the field `OTCChannels` to be a primitive type in the JSON string but got " + data['OTCChannels']);
        }
        // ensure the json data is a string
        if (data['CoveredMaturityType'] && !(typeof data['CoveredMaturityType'] === 'string' || data['CoveredMaturityType'] instanceof String)) {
            throw new Error("Expected the field `CoveredMaturityType` to be a primitive type in the JSON string but got " + data['CoveredMaturityType']);
        }
        // ensure the json data is a string
        if (data['RatingType'] && !(typeof data['RatingType'] === 'string' || data['RatingType'] instanceof String)) {
            throw new Error("Expected the field `RatingType` to be a primitive type in the JSON string but got " + data['RatingType']);
        }
        // ensure the json data is a string
        if (data['InstrumentClassification'] && !(typeof data['InstrumentClassification'] === 'string' || data['InstrumentClassification'] instanceof String)) {
            throw new Error("Expected the field `InstrumentClassification` to be a primitive type in the JSON string but got " + data['InstrumentClassification']);
        }
        // validate the optional field `Attribute`
        if (data['Attribute']) { // data not null
          Attribute.validateJSON(data['Attribute']);
        }
        // validate the optional field `Clearing`
        if (data['Clearing']) { // data not null
          Clearing.validateJSON(data['Clearing']);
        }
        // validate the optional field `Documentation`
        if (data['Documentation']) { // data not null
          Documentation.validateJSON(data['Documentation']);
        }
        // validate the optional field `Form`
        if (data['Form']) { // data not null
          Form.validateJSON(data['Form']);
        }
        // validate the optional field `InterestFeatures`
        if (data['InterestFeatures']) { // data not null
          InterestFeatures.validateJSON(data['InterestFeatures']);
        }
        // validate the optional field `Jurisdiction`
        if (data['Jurisdiction']) { // data not null
          Jurisdiction.validateJSON(data['Jurisdiction']);
        }
        // validate the optional field `TerminationRights`
        if (data['TerminationRights']) { // data not null
          TerminationRights.validateJSON(data['TerminationRights']);
        }
        if (data['Party']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Party'])) {
                throw new Error("Expected the field `Party` to be an array in the JSON data but got " + data['Party']);
            }
            // validate the optional field `Party` (array)
            for (const item of data['Party']) {
                Party.validateJsonObject(item);
            };
        }
        // validate the optional field `Pricing`
        if (data['Pricing']) { // data not null
          Pricing.validateJSON(data['Pricing']);
        }
        // validate the optional field `ProductGovernance`
        if (data['ProductGovernance']) { // data not null
          ProductGovernance.validateJSON(data['ProductGovernance']);
        }
        // validate the optional field `Programme`
        if (data['Programme']) { // data not null
          Programme.validateJSON(data['Programme']);
        }
        // validate the optional field `QuoteSystem`
        if (data['QuoteSystem']) { // data not null
          QuoteSystem.validateJSON(data['QuoteSystem']);
        }
        if (data['ProductRating']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['ProductRating'])) {
                throw new Error("Expected the field `ProductRating` to be an array in the JSON data but got " + data['ProductRating']);
            }
            // validate the optional field `ProductRating` (array)
            for (const item of data['ProductRating']) {
                ProductRating.validateJsonObject(item);
            };
        }
        // validate the optional field `RedemptionFeatures`
        if (data['RedemptionFeatures']) { // data not null
          RedemptionFeatures.validateJSON(data['RedemptionFeatures']);
        }
        // validate the optional field `Tax`
        if (data['Tax']) { // data not null
          Tax.validateJSON(data['Tax']);
        }
        if (data['Underlying']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Underlying'])) {
                throw new Error("Expected the field `Underlying` to be an array in the JSON data but got " + data['Underlying']);
            }
            // validate the optional field `Underlying` (array)
            for (const item of data['Underlying']) {
                Underlying.validateJsonObject(item);
            };
        }
        // validate the optional field `VenueData`
        if (data['VenueData']) { // data not null
          VenueData.validateJSON(data['VenueData']);
        }

        return true;
    }


}



/**
 * The issuance issue price of the security expressed in percentage. For benchmark issuance this is the reoffer price.[115]
 * @member {Number} IssuePrice
 */
Issuance.prototype['IssuePrice'] = undefined;

/**
 * The date on which issue’s proceeds are paid to the issuer and securities are created and distributed to investors (primary distribution/settlement).[93]
 * @member {Date} ValueDate
 */
Issuance.prototype['ValueDate'] = undefined;

/**
 * Planned final repayment date known at the time of issuance.[94]
 * @member {Date} MaturityDate
 */
Issuance.prototype['MaturityDate'] = undefined;

/**
 * Final valuation date of the instrument and its corresponding Underlying to determine the appropriate settlement amount and/or in some cases the settlement type (cash or physical delivery).[96]
 * @member {Date} FinalValuationDate
 */
Issuance.prototype['FinalValuationDate'] = undefined;

/**
 * The date on which the final terms / offering documentation is signed.[105]
 * @member {Date} SigningDate
 */
Issuance.prototype['SigningDate'] = undefined;

/**
 * Description of the financial instrument.[106]
 * @member {String} SecurityLongName
 */
Issuance.prototype['SecurityLongName'] = undefined;

/**
 * Abbreviation of the instrument name according to ISO defined FISN[107]
 * @member {String} SecurityShortName
 */
Issuance.prototype['SecurityShortName'] = undefined;

/**
 * This definition applies to all of the instruments value and price information.  Indicates whether the notional amount value is to be traded in either an amount, units, or percentage. See ISMAG recommendation in Annex 9 of the MPB.[108]
 * @member {String} TradingMethod
 */
Issuance.prototype['TradingMethod'] = undefined;

/**
 * Indication for a private placement (Indicates whether the details of the security are to be made available to the market, or kept private by the ICSDs and agent(s). Will only be provided if the information is available.)[110]
 * @member {Boolean} PrivatePlacement
 */
Issuance.prototype['PrivatePlacement'] = undefined;

/**
 * Identification for an OpenEnded instrument without fixed maturity date.[111]
 * @member {Boolean} OpenEnded
 */
Issuance.prototype['OpenEnded'] = undefined;

/**
 * EUSIPA Code: an instruments derivative category according to the EUSIPA Derivative Map[118]
 * @member {String} EusipaID
 */
Issuance.prototype['EusipaID'] = undefined;

/**
 * Indicates whether the securities are being issued under a programme / prospectus or on a standalone basis.[121]
 * @member {String} Type
 */
Issuance.prototype['Type'] = undefined;

/**
 * The CFI (ISO 10962, 6 letters) is assigned with the ISIN by the numbering agency. Conditional based on issuer requirements.[125]
 * @member {String} CFI
 */
Issuance.prototype['CFI'] = undefined;

/**
 * Indicates particiaption factor[126]
 * @member {Number} ParticipationFactor
 */
Issuance.prototype['ParticipationFactor'] = undefined;

/**
 * Seniority of the bond using the MiFID RTS definitions.[240]
 * @member {String} Seniority
 */
Issuance.prototype['Seniority'] = undefined;

/**
 * Indicates the seniority status of the notes (included alongside seniority as it enables the differentiation beween Senior Preferred/Senior NonPreferred). Mandatory.[241]
 * @member {String} StatusOfTheNotes
 */
Issuance.prototype['StatusOfTheNotes'] = undefined;

/**
 * Time of execution, expressed with a location to provide a timezone (e.g. 11am Brussels time).[243]
 * @member {Date} TimeOfExecution
 */
Issuance.prototype['TimeOfExecution'] = undefined;

/**
 * Indicator whether use of the proceeds of the issuance is provided[245]
 * @member {Boolean} UseOfProceeds
 */
Issuance.prototype['UseOfProceeds'] = undefined;

/**
 * Clearing house used by the issuer for the receipt of funds. Mandatory at the Post Trade stage.[254]
 * @member {String} IssuerClearingHouse
 */
Issuance.prototype['IssuerClearingHouse'] = undefined;

/**
 * Determine the appropriate debt issuance channel (CeFI/DeFi/Traditionnal) for the issuer in combination with the clearing system specified in the IssuerClearingHouse field.[488]
 * @member {String} IssuanceChannel
 */
Issuance.prototype['IssuanceChannel'] = undefined;

/**
 * The clearing system(s) which will be used to clear the trade. Mandatory.[255]
 * @member {String} ClearingSystem
 */
Issuance.prototype['ClearingSystem'] = undefined;

/**
 * Flag to indicate the security is intended to be held in a manner that could allow the Eurosystem Eligibility. Mandatory for NGN or for CGN if under the New Safekeeping Structure (NSS).[263]
 * @member {Boolean} PotentialEurosystemEligible
 */
Issuance.prototype['PotentialEurosystemEligible'] = undefined;

/**
 * Indicates whether the securities will be listed.[277]
 * @member {Boolean} IsListed
 */
Issuance.prototype['IsListed'] = undefined;

/**
 * Denotes whether the use of proceeds relates to general corporate purposes or is to be applied to a specific ESGrelated purpose. Mandatory.[278]
 * @member {String} ESGCategory
 */
Issuance.prototype['ESGCategory'] = undefined;

/**
 * The way in which the issue will be marketed to the primary market, via individual dealers (so called non syndicated distribution) or via a syndicate of managers, underwriters and selling group members (so called syndicated distribution).[280]
 * @member {String} IssuanceDistribution
 */
Issuance.prototype['IssuanceDistribution'] = undefined;

/**
 * The proceeds of the transaction due to the issuer on the settlement date.[281]
 * @member {Number} NetProceeds
 */
Issuance.prototype['NetProceeds'] = undefined;

/**
 * 3 letter ISO 4127 code representing the currency in which the issue will be settled. Mandatory if the settlement currency differs from the notional currency.[296]
 * @member {String} SettlementCurrency
 */
Issuance.prototype['SettlementCurrency'] = undefined;

/**
 * Primary market (subscription) start date[321]
 * @member {Date} SubscriptionFromDateTime
 */
Issuance.prototype['SubscriptionFromDateTime'] = undefined;

/**
 * Primary market (subscription) end date[322]
 * @member {Date} SubscriptionToDateTime
 */
Issuance.prototype['SubscriptionToDateTime'] = undefined;

/**
 * Indicates type of custodian holding, e.g. whether or not a collective safe custody is possible[331]
 * @member {String} CustodyType
 */
Issuance.prototype['CustodyType'] = undefined;

/**
 * Field allows to enter a free text which is passed through to investor[371]
 * @member {String} CustomData
 */
Issuance.prototype['CustomData'] = undefined;

/**
 * The minimum amount of the security that can be transferred.[380]
 * @member {Number} MinimumDenomination
 */
Issuance.prototype['MinimumDenomination'] = undefined;

/**
 * The STEP Market Convention lays down the criteria which shortterm paper programmes must fulfi ll to be STEP compliant and the procedures for granting and withdrawing the STEP label. Step ID is mandatory if STEP Flag is yes for an issuance[6]
 * @member {String} StepID
 */
Issuance.prototype['StepID'] = undefined;

/**
 * Exercise period start date[394]
 * @member {Date} ExercisePeriodStart
 */
Issuance.prototype['ExercisePeriodStart'] = undefined;

/**
 * Exercise period end date[395]
 * @member {Date} ExercisePeriodEnd
 */
Issuance.prototype['ExercisePeriodEnd'] = undefined;

/**
 * 3 letter ISO 4127 code representing the currency of the notional.[399]
 * @member {String} NominalCurrency
 */
Issuance.prototype['NominalCurrency'] = undefined;

/**
 * The aggregate nominal amount of the initial issuance, not including any additional issued tranches.[405]
 * @member {Number} InitialNominalAmount
 */
Issuance.prototype['InitialNominalAmount'] = undefined;

/**
 * Indicates the type of security to be issued.[406]
 * @member {String} InstrumentStructureCategory
 */
Issuance.prototype['InstrumentStructureCategory'] = undefined;

/**
 * The date when the security legally comes into existence and typically falls in line with settlement date and interest commencement date. Also referred to as closing date (though historically different).[407]
 * @member {Date} IssueDate
 */
Issuance.prototype['IssueDate'] = undefined;

/**
 * Place of inc orporation of the issuer, jurisdiction under which the issuer is established.[473]
 * @member {String} PlaceOfIncorporation
 */
Issuance.prototype['PlaceOfIncorporation'] = undefined;

/**
 * Gross Proceed Currency to issuer. It represents the Unit Issue Price multiplied by the Aggregate proceeds[474]
 * @member {Number} AggregateProceedsCurrency
 */
Issuance.prototype['AggregateProceedsCurrency'] = undefined;

/**
 * Minimum number of notice in days that must be given before the option can be exercised.[475]
 * @member {Number} MinimumNoticeDayCount
 */
Issuance.prototype['MinimumNoticeDayCount'] = undefined;

/**
 * Face value, per unit, issued (ie, the value of each unit).[476]
 * @member {Number} FaceValueForUnitIssues
 */
Issuance.prototype['FaceValueForUnitIssues'] = undefined;

/**
 * Defaulted to \"none\"Indicates if the Issue is ISMAG compliiant[580]
 * @member {String} IsmagAdherance
 */
Issuance.prototype['IsmagAdherance'] = undefined;

/**
 * A class in a debt instrument refers to a specific group of debt securities within a broader bond issuance. These classes are typically differentiated based on factors such as seniority, coupon rate and maturity date.[478]
 * @member {String} InstrumentClass
 */
Issuance.prototype['InstrumentClass'] = undefined;

/**
 * Extension is either automatic, or by notification, of the option holder.[479]
 * @member {String} ExtensionUponNotificationOrAutomatic
 */
Issuance.prototype['ExtensionUponNotificationOrAutomatic'] = undefined;

/**
 * It indicates the category of financial instrument.[481]
 * @member {String} InstrumentCategory
 */
Issuance.prototype['InstrumentCategory'] = undefined;

/**
 * Registration exceptions or RLS at the level of the security or issuer.[482]
 * @member {String} LegalRestriction
 */
Issuance.prototype['LegalRestriction'] = undefined;

/**
 * The issuance (i.e. closing date) Issue Price and Currency, expressed as the value of each Unit (an amount).[486]
 * @member {Number} UnitIssuePrice
 */
Issuance.prototype['UnitIssuePrice'] = undefined;

/**
 * Indicates whether the details of the security are to be made available to the market, or kept private by the ICSDs and agent(s). Will only be provided if the information is available.[256]
 * @member {Boolean} Confidential
 */
Issuance.prototype['Confidential'] = undefined;

/**
 * This field denotes  the type of taxability related to the related instrument[487]
 * @member {Boolean} UsTaxable
 */
Issuance.prototype['UsTaxable'] = undefined;

/**
 * Indicator for an UpTo Nominal amount[329]
 * @member {Boolean} UpTo
 */
Issuance.prototype['UpTo'] = undefined;

/**
 * The aggregate nominal amount of the series including all of the issued tranches.[330]
 * @member {Number} IssuedNominalAmount
 */
Issuance.prototype['IssuedNominalAmount'] = undefined;

/**
 * Name of the BSW category in which the instrument is to classified under.[117]
 * @member {String} BSWCategory
 */
Issuance.prototype['BSWCategory'] = undefined;

/**
 * Name of the EUSIPA derivative category according to the EUSIPA Derivative Map[119]
 * @member {String} EusipaName
 */
Issuance.prototype['EusipaName'] = undefined;

/**
 * dxxl category[123]
 * @member {String} BSWClass
 */
Issuance.prototype['BSWClass'] = undefined;

/**
 * Free text: e.g. description of a trading or selling restriction, a specific product type description from the issuer or the calculation method used for the redemption amount.[221]
 * @member {String} Description
 */
Issuance.prototype['Description'] = undefined;

/**
 * Describes any relevant events of default relating to the notes. Mandatory if the issuer requires this information to specified additional beyond what is covered in the Terms and Conditions.[282]
 * @member {String} EventsOfDefault
 */
Issuance.prototype['EventsOfDefault'] = undefined;

/**
 * Describes whether a negative pledge is applicable for the notes. Mandatory if the issuer requires this information to specified additional beyond what is covered in the Terms and Conditions.[283]
 * @member {String} NegativePledgeDetails
 */
Issuance.prototype['NegativePledgeDetails'] = undefined;

/**
 * Identifies the series number. Mandatory if the issuer uses series numbers.[52]
 * @member {String} SeriesNumber
 */
Issuance.prototype['SeriesNumber'] = undefined;

/**
 * Parent ISIN that will undergo Increase where not immediately fungible.[578]
 * @member {String} ParentISIN
 */
Issuance.prototype['ParentISIN'] = undefined;

/**
 * Determines the frequency of the fixing[5]
 * @member {String} FixingFrequency
 */
Issuance.prototype['FixingFrequency'] = undefined;

/**
 * The applicable Credit Events for a certain underlying defined in T&C for Credit Linked Notes[39]
 * @member {String} CreditEventType
 */
Issuance.prototype['CreditEventType'] = undefined;

/**
 * In case of a Zero Recovery option for the Credit Linked Notes, the investor no longer receives any payment and suffer a total loss if default happens.[40]
 * @member {Boolean} ZeroRecovery
 */
Issuance.prototype['ZeroRecovery'] = undefined;

/**
 * The multiplier/ratio of a financial instrument indicates the number of units, of an Underlying, on which the financial instrument refers ((e. g. a multiplier/ratio of 0.1, for a warrant on an equity (Underlying), refers to one tenth of that underlying stock, i. e. upon exercise, the investor will receive one tenth of the difference between the market price and the Strike).[230]
 * @member {Number} Multiplier
 */
Issuance.prototype['Multiplier'] = undefined;

/**
 * Specification whether payoff of the derivative is follows Put or Call Option[113]
 * @member {String} OptionType
 */
Issuance.prototype['OptionType'] = undefined;

/**
 * Indicates whether the Warrant can be exercised throughout its entire term (American), or only on the Exercise Date (European) or on predetermined fixed dates found in the Terms and Conditions of the financial instrument (Bermudan).[114]
 * @member {String} OptionStyle
 */
Issuance.prototype['OptionStyle'] = undefined;

/**
 * Name of OTC channel(s) for which trading is offered[382]
 * @member {String} OTCChannels
 */
Issuance.prototype['OTCChannels'] = undefined;

/**
 * The smallest amount from which the MinimumDenomination is allowed to be increased. Mandatory where an increment is required. Alternative to GD455D.[484]
 * @member {Number} MinimumQuantity
 */
Issuance.prototype['MinimumQuantity'] = undefined;

/**
 * Form Covered Bonds, indicates whether the maturity of the issue can be extended (Soft Bullet or Conditional Pass,Through) or not (Hard Bullet).[200]
 * @member {String} CoveredMaturityType
 */
Issuance.prototype['CoveredMaturityType'] = undefined;

/**
 * The Extended Final Maturity Date at the end of the extended period where the issuance is subject to a potential coupon extension. Mandatory for Covered Bonds with a Soft Bullet maturity type.[201]
 * @member {Date} ExtendedMaturityDate
 */
Issuance.prototype['ExtendedMaturityDate'] = undefined;

/**
 * Whether the securities have a maturity date or are issued in perpetual form. Mandatory. Security cannot be redeemed.[203]
 * @member {Boolean} Perpetual
 */
Issuance.prototype['Perpetual'] = undefined;

/**
 * Whether the instrument will be explicitly rated (Issue rating), whether a rating in line with other instruments of the same seniority issued under the programme will be specified in the final terms (Product rating) or whether the instruments will be explicitly unrated (Unrated).[235]
 * @member {String} RatingType
 */
Issuance.prototype['RatingType'] = undefined;

/**
 * Describes the classification of financial instruments. The classification system applies to financial instruments negotiated internationally as well as to domestic instruments.  This Classification Standard is intended for use by the Clearing Systems to define the type of security issued.[480]
 * @member {String} InstrumentClassification
 */
Issuance.prototype['InstrumentClassification'] = undefined;

/**
 * The aggregate Outstanding nominal amount[704]
 * @member {Number} OutstandingAmount
 */
Issuance.prototype['OutstandingAmount'] = undefined;

/**
 * @member {module:model/Attribute} Attribute
 */
Issuance.prototype['Attribute'] = undefined;

/**
 * @member {module:model/Clearing} Clearing
 */
Issuance.prototype['Clearing'] = undefined;

/**
 * @member {module:model/Documentation} Documentation
 */
Issuance.prototype['Documentation'] = undefined;

/**
 * @member {module:model/Form} Form
 */
Issuance.prototype['Form'] = undefined;

/**
 * @member {module:model/InterestFeatures} InterestFeatures
 */
Issuance.prototype['InterestFeatures'] = undefined;

/**
 * @member {module:model/Jurisdiction} Jurisdiction
 */
Issuance.prototype['Jurisdiction'] = undefined;

/**
 * @member {module:model/TerminationRights} TerminationRights
 */
Issuance.prototype['TerminationRights'] = undefined;

/**
 * Model: Party
 * @member {Array.<module:model/Party>} Party
 */
Issuance.prototype['Party'] = undefined;

/**
 * @member {module:model/Pricing} Pricing
 */
Issuance.prototype['Pricing'] = undefined;

/**
 * @member {module:model/ProductGovernance} ProductGovernance
 */
Issuance.prototype['ProductGovernance'] = undefined;

/**
 * @member {module:model/Programme} Programme
 */
Issuance.prototype['Programme'] = undefined;

/**
 * @member {module:model/QuoteSystem} QuoteSystem
 */
Issuance.prototype['QuoteSystem'] = undefined;

/**
 * Model: ProductRating
 * @member {Array.<module:model/ProductRating>} ProductRating
 */
Issuance.prototype['ProductRating'] = undefined;

/**
 * @member {module:model/RedemptionFeatures} RedemptionFeatures
 */
Issuance.prototype['RedemptionFeatures'] = undefined;

/**
 * @member {module:model/Tax} Tax
 */
Issuance.prototype['Tax'] = undefined;

/**
 * Model: Underlying
 * @member {Array.<module:model/Underlying>} Underlying
 */
Issuance.prototype['Underlying'] = undefined;

/**
 * @member {module:model/VenueData} VenueData
 */
Issuance.prototype['VenueData'] = undefined;






export default Issuance;

