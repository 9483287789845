import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const DetailInstrumentInfo = ({
  handleChange,
  expandState,
  isDesktop,
  issuanceObj,
}) => (
  <Accordion
    className="accordianStyle"
    id="cp_inst_acc5"
    expanded={expandState["panel5"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel5a-content"
      id="panel5a-header"
      onClick={() => {
        handleChange("panel5");
      }}
    >
      <Typography variant="h3" id="cp_inst_typo5">
        Detail Instrument Information
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="cp_inst_accon5">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Initial Nominal Amount"}
          value={issuanceObj.initialNominalAmount}
        />
        <RowElement
          label={"Issued Nominal Amount"}
          value={issuanceObj.issuedNominalAmount}
        />
        <RowElement label={"UpTo Nominal"} value={issuanceObj.upToNominal} />
        <RowElement label={"Option Type"} value={issuanceObj.optionsType} />
        <RowElement label={"Trading Method"} value={issuanceObj.quotation} />
        <RowElement
          label={"Applicable Law"}
          value={issuanceObj.applicableLaw}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement label={"Issue Price"} value={issuanceObj.issuePrice} />
        <RowElement
          label={"Redemption Value"}
          value={issuanceObj.redemptionValue}
        />
        <RowElement label={"Bonus Value"} value={issuanceObj.bonusAmount} />
        <RowElement
          label={"Payment Currency"}
          value={issuanceObj.paymentCurrency}
        />
        <RowElement
          label={"Bonus Currency"}
          value={issuanceObj.bonusCurrency}
        />
        <RowElement
          label={"Redemption Option"}
          value={issuanceObj.settlementType}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Private Placement"}
          value={issuanceObj.privatePlacement}
        />
        <RowElement
          label={"Minimum Exercise"}
          value={issuanceObj.exerciseMinimum}
        />
        <RowElement
          label={"Minimum Denomination"}
          value={issuanceObj.minTransferAmount}
        />
        <RowElement
          label={"Exercise Style"}
          value={issuanceObj.exerciseStyle}
        />
        <RowElement
          label={"Automatic Exercise"}
          value={issuanceObj.exerciseAutomatic}
        />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default DetailInstrumentInfo;
