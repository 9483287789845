import React, { useState } from "react";
import { Route } from "react-router-dom";
import { AppCommonContext } from "./App";
import Template from "./modules/Template/Template";
import Issuances from "./modules/Issuance/Issuance";
import IssuanceDetails from "./modules/IssuanceDetails/IssuanceDetails";
import ComplianceLists from "./modules/ComplianceList/ComplianceList";
import Details from "./modules/Details/Details";
//import Dashboard from './modules/Dashboard/Dashboard'
import AuditExport from "./modules/AuditExport/AuditExport";
import Pending from "./modules/Pending";
import ComplianceDetails from "./modules/ComplianceDetails";
import IssuerStaticData from "./modules/IssuerStaticData/IssuerStaticData";
import AccountList from "./modules/AccountList/AccountList";
import NonProcessableISINLog from "./modules/NonProcessableISINLog/NonProcessableISINLog";
import TransactionStore from "./modules/TransactionStoreLog";
import { useUserStore } from "./store/Store";
import { UserContext } from "./TableContext";
function Routes() {
  const appContext = React.useContext(AppCommonContext);
  const [userState] = useUserStore();
  const individualPendingSectionCount =
    appContext.individualPendingSectionCount;
  const showDetails = appContext.showDetails;
  const setShowDetails = appContext.setShowDetails;
  const setTimeStamp = appContext.setTimeStamp;
  const [queryTable, setQueryTable] = useState([]);
  const [tabNameList, setTabNameList] = useState([]);
  const [tabContentList, setTabContentList] = useState([]);
  const [tabDetailList, setTabDetailList] = useState([]);
  const [queryData, setQueryData] = useState({});

  return (
    <>
      {/* <Route exact path="/" component={Dashboard}></Route>
            <Route exact path="/home" component={Dashboard}></Route> */}
      {/* {userState.userRoleRight.includes("View_Template") &&
                <Route exact path="/template">
                    <Template />
                </Route>
            } */}
      <Route exact path="/issuance">
        <UserContext.Provider
          value={{
            table: [queryTable, setQueryTable],
            name: [tabNameList, setTabNameList],
            content: [tabContentList, setTabContentList],
            detail: [tabDetailList, setTabDetailList],
            query: [queryData, setQueryData],
          }}
        >
          <Issuances
            showDetailsPage={showDetails}
            setShowDetailsPage={setShowDetails}
            setTimeStamp={setTimeStamp}
          />
        </UserContext.Provider>
      </Route>
      <Route
        exact
        path="/issuanceDetails"
        component={() => (
          <div style={{ padding: "0 20px" }}>
            <IssuanceDetails />
          </div>
        )}
      ></Route>
      {userState.userRoleRight.includes("View_Compliance_List") && (
        <>
          <Route
            exact
            path="/complianceLists"
            component={ComplianceLists}
          ></Route>
          <Route exact path="/:type/details/:id" component={Details}></Route>
        </>
      )}
      {userState.userRoleRight.includes("View_Audit_Export") && (
        <Route exact path="/auditExport" component={AuditExport}></Route>
      )}
      {userState.userRoleRight.includes("View_Issuer_Static_Data") && (
        <>
          <Route
            exact
            path="/issuerStaticData"
            component={IssuerStaticData}
          ></Route>
          <Route exact path="/accountlist" component={AccountList}></Route>
        </>
      )}
      {userState.userRoleRight.includes("View_Unknown_ISIN_Log") && (
        <Route
          exact
          path="/nonProcessableIsinLog"
          component={NonProcessableISINLog}
        ></Route>
      )}
      {userState.userRoleRight.includes("View_Pending") && (
        <>
          <Route exact path="/pending">
            <Pending
              setTimeStamp={setTimeStamp}
              individualPendingSectionCount={individualPendingSectionCount}
            />
          </Route>
          <Route exact path="/issuances/manual-interaction/:isin">
            <ComplianceDetails />
          </Route>
        </>
      )}
      {userState.userRoleRight.includes("View_Transaction_Store_Log") && (
        <>
          <Route
            exact
            path="/transactionStore"
            component={TransactionStore}
          ></Route>
        </>
      )}
    </>
  );
}

export default Routes;
