import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import PaginationContainer from "../../components/Paginator";
import axios from "axios";
import {
  StyledContainer,
  StyledSearchContainer,
  StyledTableContainer,
  SearchTags,
} from "./index.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../components/CustomAccordion";
import { Col, Row } from "react-bootstrap";
import { getComparator, stableSort } from "../../components/Sorting";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dropdown from "../../components/Dropdown";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DatePicker, Space } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";
import moment from "moment-timezone";
import Tooltip from "@material-ui/core/Tooltip";
import IssuanceDetails from "../IssuanceDetails/IssuanceDetails";
import viewDetailsGreyIcon from "../../assets/inactiveActionIcons/View details-Grey.svg";
import viewDetailsActiveIcon from "../../assets/deselectedActionIcons/View Details-Outline.svg";
import CloseIcon from "@material-ui/icons/Close";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import filterIcon from "../../assets/FilterIcon.svg";
import nodataIcon from "../../assets/Error Icon.svg";
import notArray from "../../assets/notArray.svg";
import refresh from "../../assets/refresh.svg";
import { MultiSelect } from "react-multi-select-component";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import RecieptErrorPopup from "../../components/ErrorSuccessPopUp/RecieptErrorPopup";
import {
  TemplateNameFormat,
  TemplateTypeList,
  getInstrumentTypeLabel,
} from "../Template/Template";
import ArrayIcon from "../../assets/array.svg";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TabPanel from "../../components/CustomTab";
import { UserContext } from "../../TableContext";
import { useUserStore } from "../../store/Store";
import { AppCommonContext } from "../../App";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import createActiveIcon from "../../assets/deselectedActionIcons/Create-Outline.svg";
import increaseIcon from "../../assets/icon-arrow.png";
import CreateIssuance from "./CreateIssuance";
import {
  externalProcessStatus,
  externalOverallStatus,
  internalStatus,
  getExternalOverallStatusLabel,
  getExternalProcessStatusLabel,
  getInternalStatusLabel,
} from "../../utils/StatusUtils";
import {
  flipDateFormat,
  addOneDay,
  convertDateTime,
} from "../../utils/dateUtils";
import { instrumentTypes } from "./instrumentTypes";
import {
  IssuancesQueriesApiClient,
  AccountsInternalApiClient,
  IssuersQueriesApiClient,
  BatchProcessorApiClient,
  RedemptionsApiClient,
  PartiesQueriesApiClient,
} from "../../api";
import ValidationErrorPopup from "../../components/ErrorSuccessPopUp/ValidationErrorPopup";
import UploadDialog from "./UploadDialog";
import { DATE_FORMATS } from "../../constants";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import generatePicker from "antd/es/date-picker/generatePicker";
import { parse } from "date-fns";
import { ISSUANCE_PAGE_LIST } from "../../components/Paginator/constants";

const CustomDatePicker = generatePicker(dateFnsGenerateConfig);

/*
 * This array is for issuance listing table columns
 */
const columns = [
  { id: "overallStatus", label: "Status", width: "140px" },
  { id: "valueDate", label: "Value Date", type: "date", width: "125px" },
  { id: "payDate", label: "Pay Date", type: "date", width: "125px" },
  { id: "templateType", label: "Instrument Type", width: "160px" },
  {
    id: "issuerAgentAccountNo",
    label: "Issuer Agent Account No",
    width: "120px",
  },
  { id: "issuer", label: "Issuer Name", width: "122px" },
  { id: "isin", label: "ISIN", width: "110px" },
  { id: "processingStatus", label: "Processing Status", width: "160px" },
  { id: "internalStatus", label: "Internal Status", width: "160px" },
  { id: "payingAgent", label: "Paying Agent", width: "124px" },
  { id: "statusSince", label: "Status Since", type: "date", width: "125px" },
];

/*
 * The below two objects are being used in new filter functionality
 */
const searchList = {
  overallStatus: "",
  valueDate: "",
  payDate: "",
  templateType: "",
  issuerAgentName: "",
  issuer: "",
  isin: "",
  lifecycleStatus: "",
  payingAgent: "",
  statusSince: "",
};
const defaultOptions = {
  overallStatus: [],
  valueDate: null,
  payDate: null,
  templateType: [],
  issuerAgentName: [],
  issuer: [],
  isin: "",
  processingStatus: [],
  internalStatus: [],
  payingAgent: [],
  statusSince: null,
};
//dropdown options for overall status and lifecycle status
const searchDropdownList = {
  overallStatus: externalOverallStatus,
  processStatus: externalProcessStatus,
  internalStatus: internalStatus,
};

//error codes with error messages
export const catchBlockError = (error) => {
  if (error.response.status === 400) {
    return "Bad Request.Please try with correct request";
  } else if (error.response.status === 404) {
    return "Api not found.Please try again later";
  } else if (error.response.status === 408) {
    return "Request Timeout.Please try again later";
  } else if (error.response.status >= 500) {
    return "Services are not available.Please try again later";
  } else {
    return "Unknown Error.Please try later";
  }
};
const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
}));

/*
 * Below represents the styling of Tooltip used in icons
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function Issuances(props) {
  const [userState] = useUserStore();
  const setDefaultStatusSinceDate = userState.userRoleRight.includes(
    "Set_StatusSince_Default"
  );
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [order, setOrder] = useState("asc"); //default sorting is set to ascending order
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [issuanceObj, setIssuanceObj] = useState({});
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [recieptResponseText, setRecieptResponseText] = useState("");
  const [showRecieptPopUp, setShowRecieptPopUp] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);
  const [searchArray, setSearchArray] = useState([]);
  const [searchValue, setSearchValue] = useState(searchList);
  const [uploadFlag, setUploadFlag] = useState("");
  const [uploadTitle, setUploadTitle] = useState("");
  const [optionsArray, setOptionsArray] = useState({});
  const [expandedSearch, setExpandedSearch] = useState("expandPanel");
  const [expandedTable, setExpandedTable] = useState("");
  const dateFormat = "DD/MM/YYYY"; //set the date filter for date pickers
  const dateFormat1 = "dd/MM/yyyy";
  const [fromValueDate, setFromValueDate] = useState(); //store from date in date picker
  const [fromPayDate, setFromPayDate] = useState(); //store from date in date picker
  const [fromStatusDate, setFromStatusDate] = useState(); //store from date in date picker
  const [toValueDate, setToValueDate] = useState(); //store To date in date picker
  const [toPayDate, setToPayDate] = useState(); //store To date in date picker
  const [toStatusDate, setToStatusDate] = useState(); //store To date in date picker
  const [searchBtnFlag, setSearchBtnFlag] = useState(true); //flag for enabling and disabling search button
  const [selectedIssuerName, setSelectedIssuerName] = useState([]); // store selected Issuer name
  const [selectedIssuerNameSecond, setSelectedIssuerNameSecond] = useState([]); // store selected Issuer name
  const [addIssuerName, setAddIssuerName] = useState(false); // store selected Issuer name
  const [selectedOverallStatus, setSelectedOverallStatus] = useState([]); // store selected Issuer name
  const [selectedOverallStatusSecond, setSelectedOverallStatusSecond] =
    useState([]); // store selected Issuer name
  const [addOverallStatus, setAddOverallStatus] = useState(false); // store selected LifeCycle Status
  const [filteredIssuerName, setFilteredIssuerName] = useState([]); //stores filterd list of IIsuer Name
  const [filteredOverallStatus, setFilteredOverallStatus] = useState(
    searchDropdownList.overallStatus
  ); //stores filterd list of IIsuer Name
  const [selectedLifecycleStatus, setSelectedLifecycleStatus] = useState([]); // store selected Lifecycle Status
  const [selectedLifecycleStatusSecond, setSelectedLifecycleStatusSecond] =
    useState([]); // store selected Lifecycle Status
  const [filteredLifecycleStatus, setFilteredLifecycleStatus] = useState([]); //stores filterd list of IIsuer Name
  const [addInternalStatus, setAddInternalStatus] = useState(false);
  const [selectedInternalStatus, setSelectedInternalStatus] = useState([]);
  const [addLifecycleStatus, setAddLifecycleStatus] = useState(false); // store selected LifeCycle Status
  const [selectedInstrumentType, setSelectedInstrumentType] = useState([]); // store selected Instrument type
  const [selectedCbfNo, setSelectedCbfNo] = useState([]); // store selected cbf no
  const [selectedPayAgent, setSelectedPayAgent] = useState([]); //store selected pay agent value
  const [listIssuerName, setListIssuerName] = useState([]); //stores the list of issuer name
  const [listCbfNo, setListCbfNo] = useState([]); //store the list of cbf no
  const [listPayAgent, setListPayAgent] = useState([]); // stores the list of pay agent
  const [valueDateSwitch, setValueDateSwitch] = useState(true); //flag to switch between array of date and single date
  const [payDateSwitch, setPayDateSwitch] = useState(true); //flag to switch between array of date and single date
  const [statusDateSwitch, setStatusDateSwitch] = useState(true); //flag to switch between array of date and single date
  const [currentIsin, setCurrentIsin] = useState(""); //stores isin for tab view
  const [loadingTableFlag, setLoadingTableFlag] = useState(false); //flag to set table loader
  const [isShowSearchTags, setIsShowSearchTags] = useState(false);

  const [searchComponentWidth, setSearchComponentWidth] = useState(0);

  const { table, name, content, detail, query } = useContext(UserContext);
  const [itemsCount, setItemsCount] = useState(0);
  const [queryTable, setQueryTable] = table;
  const [tabNameList, setTabNameList] = name;
  const [tabContentList, setTabContentList] = content;
  const [tabDetailList, setTabDetailList] = detail;
  const [queryData, setQueryData] = query;

  const { state, dispatch } = useContext(AppCommonContext);
  const [createIssuance, setCreateIssuance] = useState(false);
  const [createActionTitle, setCreateActionTitle] = useState("New Issuance");
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validationMessage, setValidationMessage] = useState("");
  const searchTags = Object.entries(selectedFilter).filter(([_, v]) => v != "");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [triggerResetPage, setTriggerResetPage] = useState(0);
  const defaultStatusSinceDate = parse(
    moment().format(dateFormat),
    dateFormat1,
    new Date(),
    "UTC"
  );
  /*
   * This method will fetch issuances based on search filter
   */

  const addEndDate = (filters, startDateKey, endDateKey) => {
    let updatedFilters = JSON.parse(JSON.stringify(filters));
    if (updatedFilters[startDateKey] && updatedFilters[endDateKey]) {
      updatedFilters[endDateKey] = addOneDay(updatedFilters[endDateKey]);
    }
    if (updatedFilters[startDateKey] && !updatedFilters[endDateKey]) {
      updatedFilters[endDateKey] = addOneDay(updatedFilters[startDateKey]);
    }
    return updatedFilters;
  };

  const setupQueryParams = (page = 1, pagesize = 100) => {
    // Next lines are introduced in this manner to reduce the chance of regressions
    // before changing consider consider this setSelectedFilter function
    let renamedFilters;

    const {
      templateType,
      issuerKvNoPayAgent,
      issuerNames,
      issuerAgentName,
      ...restData
    } = selectedFilter;
    renamedFilters = { ...restData };
    if (templateType) {
      renamedFilters.instrumentTypes = templateType;
    }
    if (issuerKvNoPayAgent) {
      renamedFilters.payingAgentNames = issuerKvNoPayAgent.map((name) =>
        name.replace(",", "|")
      );
    }
    if (issuerNames) {
      renamedFilters.issuerNames = issuerNames.map((name) =>
        name.replace(",", "|")
      );
    }
    if (issuerAgentName) {
      renamedFilters.issuerAgentNames = issuerAgentName.map((name) =>
        name.replace(",", "|")
      );
    }
    setQueryData(selectedFilter); //setting context
    const filtersWithValueDate = addEndDate(
      renamedFilters,
      "valueDateFrom",
      "valueDateTo"
    );
    const filtersWithPaymentAndValueDate = addEndDate(
      filtersWithValueDate,
      "paymentDateFrom",
      "paymentDateTo"
    );
    const filtersWithAllDates = addEndDate(
      filtersWithPaymentAndValueDate,
      "statusSinceFrom",
      "statusSinceTo"
    );
    return {
      ...filtersWithAllDates,
      page: page,
      pageSize: pagesize,
    };
  };

  const getFilteredData = (evt, filterData) => {
    setLoadingTableFlag(true);
    setSelected([]);
    setIssuanceObj({});
    setIsShowSearchTags(true);
    let id_token = sessionStorage.getItem("jwtToken");
    let searchFlag = sessionStorage.getItem("searchFilter");
    for (var key in filterData) {
      if (filterData.hasOwnProperty(key)) {
        if (filterData[key] == "" || !filterData[key]) {
          delete filterData[key];
        }
      }
    }

    const queryParams = setupQueryParams(1, pageSize);
    if (searchFlag !== null || searchFlag !== "false") {
      IssuancesQueriesApiClient.getIssuancesV2(queryParams)
        .then(({ response }) => {
          setLoadingTableFlag(false);
          props.setTimeStamp(
            convertDateTime(moment.now(), DATE_FORMATS.DATETIME)
          ); //refreshing timestamp while updating issuance listing
          setExpandedSearch("false");
          evt.target.id !== "iss_icon_refreshTable" &&
            setExpandedTable("expandPanel");
          setItemsCount(response.data.count);
          setQueryTable(response.data.items);
          setFilterTable(response.data.items);
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            let errRes =
              "Search operation timed out, queried results are too large. Please apply additional filters to get more refined data.";
            setResponseText(errRes);
            setShowPopUp(true);
            setflagPopUp("error");
          } else {
            setResponseText(error.toString());
            setShowPopUp(true);
            setflagPopUp("error");
          }
        });
    }
  };
  //setting values for table data
  const setFilterTable = (resData) => {
    let issuances = [];
    for (let i = 0; i < resData?.length; i++) {
      let temp = resData[i];
      sessionStorage.setItem("issuer", temp.issuerName);
      let obj = {
        issuer:
          temp.issuerName || temp.issuerName !== null ? temp.issuerName : "",
        isin: temp.isin || temp.isin !== null ? temp.isin : "",
        templateType:
          temp.instrumentType || temp.instrumentType !== null
            ? getInstrumentTypeLabel(temp.instrumentType)
            : "",
        overallStatus:
          temp.externalOverallStatus || temp.externalOverallStatus !== null
            ? getExternalOverallStatusLabel(temp.externalOverallStatus)
            : "",
        valueDate:
          !temp.valueDate || temp.valueDate === null
            ? ""
            : moment(temp.valueDate).format(DATE_FORMATS.DATE),
        payDate:
          !temp.paymentDate || temp.paymentDate === null
            ? ""
            : moment(temp.paymentDate).format(DATE_FORMATS.DATE),
        statusSince:
          !temp.statusSince || temp.statusSince === null
            ? ""
            : moment(temp.statusSince).format(DATE_FORMATS.DATE),
        key: "iss-" + i,
        issuerAgentAccountNo:
          temp.issuerAgentAccountNo || temp.issuerAgentAccountNo !== null
            ? parseInt(temp.issuerAgentAccountNo).toString()
            : "", // TODO: check issuerAgentAccountNo
        payingAgent:
          temp.issuerKvNoPayAgent || temp.issuerKvNoPayAgent !== null
            ? temp.issuerKvNoPayAgent
            : "",
        processingStatus:
          temp.externalProcessingStatus ||
          temp.externalProcessingStatus !== null
            ? getExternalProcessStatusLabel(temp.externalProcessingStatus)
            : "",
        internalStatus:
          temp.internalStatus || temp.internalStatus !== null
            ? getInternalStatusLabel(temp.internalStatus)
            : "",
      };
      issuances.push(obj);
    }
    setData(issuances);
    setTempData(issuances);
    removeAppliedFilter();
    if (issuances.length > 0) {
      createFilterOptions(issuances);
    }
  };

  // This method fetch issuerName for search Filter
  const getIssuerNameForSearch = () => {
    IssuersQueriesApiClient.getIssuersNamesV1()
      .then(({ response }) => {
        let issuerNames = [];
        if (response.status === 200 && response?.data?.items) {
          for (var i = 0; i < response.data.items.length; i++) {
            let obj = {
              issuerName: response.data.items[i],
            };
            issuerNames.push(obj);
          }
          let issuerNameArr = createDropdownOptions(issuerNames, "issuerName");
          setListIssuerName(issuerNameArr);
        }
      })
      .catch((error) => {
        setResponseText(error.toString());
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const getIssuerAgentNames = () => {
    PartiesQueriesApiClient.getIssuersAgentNames({ page: 1, pagesize: 1000 })
      .then(async ({ response }) => {
        if (response.status === 200 && response?.data?.items) {
          let issuerAgents = response.data.items;
          if (issuerAgents) {
            const issuerAgentNames = [];
            for (var i = 0; i < issuerAgents.length; i++) {
              let obj = {
                issuerAgentName: issuerAgents[i],
              };
              issuerAgentNames.push(obj);
            }
            let issuerAgentNamesArr = createDropdownOptions(
              issuerAgentNames,
              "issuerAgentName"
            );
            setListCbfNo(issuerAgentNamesArr);
          }
        }
      })
      .catch((error) => {
        setResponseText(error.toString());
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const getPayingAgentNames = () => {
    PartiesQueriesApiClient.getPayingAgentNames({
      page: 1,
      pagesize: 1000,
    })
      .then((response) => {
        const data = response.data.items;
        if (data) {
          const payingAgentNames = [];
          for (var i = 0; i < data.length; i++) {
            let obj = {
              payingAgentName: data[i],
            };
            payingAgentNames.push(obj);
          }
          let payingAgentNamesArr = createDropdownOptions(
            payingAgentNames,
            "payingAgentName"
          );
          setListPayAgent(payingAgentNamesArr);
        }
      })
      .catch((error) => {
        setResponseText(error.toString());
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  //creating options for search filter options
  const createDropdownOptions = (arr, keyId) => {
    let arrValues = arr.map(function (value) {
      return value[keyId];
    });
    let tempArr = arrValues.filter(function (item, pos) {
      return arrValues.indexOf(item) == pos;
    });
    let optionsArr = [];
    tempArr.forEach(function (item, index) {
      optionsArr.push({ value: item, label: item });
    });
    return optionsArr;
  };
  /*
   * Updating options array for filter search
   */
  const createFilterOptions = (issuances) => {
    let columnNames = issuances.length !== 0 ? Object.keys(issuances[0]) : [];
    let optionsArrList = {};
    columnNames.map(
      (elem) => (optionsArrList[elem] = getOptionList(issuances, elem))
    );
    setOptionsArray(optionsArrList);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!searchBtnFlag) {
          getFilteredData(event, selectedFilter);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [getFilteredData, searchBtnFlag, selectedFilter]);

  useEffect(() => {
    //getIssuanceStaticData(); //call issuer static data to fetch data for search options
    getIssuerNameForSearch();
    getPayingAgentNames();
    getIssuerAgentNames();
    //calling this fn to load issuances as soon as component renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setDefaultStatusSinceDate) {
      setFromStatusDate(moment()._d);
      setSelectedFilter({
        ...selectedFilter,
        statusSinceFrom: moment().format("YYYY-MM-DD"),
      });
    }
  }, [userState]);

  useEffect(() => {
    setFilterTable(queryTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQueryTable, queryTable]);

  useEffect(() => {
    if (!statusDateSwitch) {
      setFromStatusDate("");
    }
  }, [statusDateSwitch]);

  useEffect(() => {
    sessionStorage.setItem("searchFilter", "false");
    sessionStorage.setItem("search", "false");
    EnableSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchBtnFlag,
    selectedInstrumentType,
    selectedIssuerName,
    selectedIssuerNameSecond,
    fromPayDate,
    fromStatusDate,
    fromValueDate,
    toValueDate,
    toPayDate,
    toStatusDate,
    listIssuerName,
    selectedCbfNo,
    listCbfNo,
    selectedPayAgent,
    listPayAgent,
    valueDateSwitch,
    payDateSwitch,
    statusDateSwitch,
    selectedLifecycleStatus,
    selectedLifecycleStatusSecond,
    selectedOverallStatus,
    selectedOverallStatusSecond,
    addIssuerName,
    addOverallStatus,
    addLifecycleStatus,
    selectedInternalStatus,
    addInternalStatus,
    loadingTableFlag,
  ]);

  /*
   * This method will fetch the receipt message
   */
  useEffect(() => {
    if (state.error) {
      setResponseText(state.error);
      setShowPopUp(true);
      setflagPopUp("error");
      dispatch({ type: "RESET_RECEIPT_RETRY_COUNT" });
    } else if (state.response) {
      setShowPopUp(false); //closing success/error popup for issunace upload
      setRecieptResponseText(state.response); //setting receipt response
      setShowRecieptPopUp(true);
      dispatch({ type: "RESET_RECEIPT_RETRY_COUNT" });
    }
  }, [state.error, state.response]);

  /*
   * This method will update the pagination for any page change
   */
  const onPageChange = (pageValue, pageSize) => {
    const newNumOfPages = Math.ceil(itemsCount / pageSize);
    const newPageValue = pageValue > newNumOfPages ? newNumOfPages : pageValue;

    setPageSize(pageSize);
    setPage(newPageValue);

    const queryParams = setupQueryParams(newPageValue, pageSize);

    IssuancesQueriesApiClient.getIssuancesV2(queryParams)
      .then(({ response }) => {
        setItemsCount(response.data.count);
        setQueryTable(response.data.items);
        setFilterTable(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*
   * This method is used for sorting Issaunce Listing column data in ascending and descending order
   */
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /*
   * This method is used for selecting a row from Issuance Listing Table
   */
  const handleClick = (event, name, row) => {
    const selectedIndex = name;
    let newSelected = [selectedIndex];
    if (selected[0] === selectedIndex) {
      setSelected([]);
    } else {
      setSelected(newSelected);
      setIssuanceObj(row);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /*
   * Navigating to issuance details page
   */
  const showIssuanceDetails = (elem, type) => {
    setCurrentIsin(elem.isin);
    var tempName = [...tabNameList];
    var tempDetails = [...tabDetailList];
    var tempContent = [...tabContentList];
    if (tempName.length < 8) {
      //setting issuance detailsa object
      var tempObj = {
        pathname: "/issuanceDetails",
        state: {
          isin: elem.isin,
          status: elem.overallStatus,
          issuer: elem.issuer,
          valueDate: elem.valueDate,
          payDate: elem.payDate,
          statusSince: elem.statusSince,
          issuerAgentAccountNo: elem.issuerAgentAccountNo,
          payingAgent: elem.payingAgent,
          lifecycleStatus: elem.lifecycleStatus,
          templateType: TemplateNameFormat(elem.instrumentType),
          pageType: type,
        },
      };
      if (!tempName.includes(elem.isin)) {
        tempContent.push(<IssuanceDetails issuanceObj={tempObj} />);
        setTabContentList(tempContent);
        //storing tab details to check between  rejected and initiated issuance opened in tab
        tempDetails.push({ isin: elem.isin });
        setTabDetailList(tempDetails);
      } else {
        for (const element of tempDetails) {
          if (element.isin === elem.isin) {
            let index = tempName.indexOf(elem.isin);
            tempContent[index] = <IssuanceDetails issuanceObj={tempObj} />;
            setTabContentList(tempContent);
            //storing tab details to check between  rejected and initiated issuance opened in tab
            tempDetails[index] = {
              isin: elem.isin,
            };
            setTabDetailList(tempDetails);
          }
        }
      }
      tempName.push(elem.isin);
      let uniqueTempName = tempName.filter(function (item, pos) {
        return tempName.indexOf(item) == pos;
      });
      setTabNameList(uniqueTempName);
    } else {
      let errRes =
        "Maximum limit exeeded.Please close existing tabs to open new tabs.";
      setResponseText(errRes);
      setShowPopUp(true);
      setflagPopUp("error");
    }
  };

  /*
   * This method will refresh the filters by removing all applied search criteria
   */
  const removeAppliedFilter = () => {
    setSelectedColumnOption({
      overallStatus: [],
      valueDate: null,
      payDate: null,
      templateType: [],
      issuerAgentAccountNo: [],
      issuer: [],
      isin: [],
      processingStatus: [],
      internalStatus: [],
      payingAgent: [],
      statusSince: null,
    });
    setSearchArray([]);
  };

  /*
   * This method will send the request for Uploading DRP/updating issuance
   */
  const uploadFile = (files) => {
    setOpenUploadDialog(false);
    if (uploadFlag === "uploadDRP" || uploadFlag === "uploadBatchRedemptions") {
      let axiosObject = "";
      if (uploadFlag === "uploadDRP") {
        axiosObject = RedemptionsApiClient.uploadDRPFileUsingPOST(
          files[0].file
        );
      } else {
        axiosObject = BatchProcessorApiClient.uploadBatchRedemption({
          file: files[0].file,
        });
      }
      if (axiosObject) {
        axiosObject
          .then(({ response }) => {
            if (response.status === 200 || response.status === 202) {
              setResponseText("Upload Successful");
              setShowPopUp(true);
              setflagPopUp("success");
              setTimeout(() => {
                setShowPopUp(false);
                setResponseText("");
              }, 10000);
            } else {
              if (response?.data?.businessValidationErrors?.length > 0) {
                setValidationMessage("XML Error : " + response?.data?.message);
                setValidationErrors(response?.data?.businessValidationErrors);
                setShowValidationErrors(true);
              } else {
                setResponseText(response.data.message);
                setShowPopUp(true);
                setflagPopUp("error");
              }
            }
          })
          .catch((error) => {
            setResponseText(
              error
                ? error?.response?.data?.message
                  ? error.response.data.message
                  : error?.toString()
                : "Unable to upload xml. Please try later."
            );
            setShowPopUp(true);
            setflagPopUp("error");
          });
      }
    } else {
      let jwtToken = sessionStorage.getItem("jwtToken");
      let formData = new FormData();
      formData.append("file", files[0].file);
      axios //requesting for updating issaunce
        .put("/di/issuance", formData, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            var message = res?.data?.message ? res.data.message : "Success";
            setResponseText(message);
            setShowPopUp(true);
            setflagPopUp("success");
            setTimeout(() => {
              setShowPopUp(false);
              setResponseText("");
            }, 10000);
          } else {
            var message = res?.data?.message ? res.data.message : "Error";
            setResponseText(message);
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setResponseText(
            error ? error.toString() : "Unable to upload XML. Please try later."
          );
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  /*
   * Filter with multiple column
   */
  const newRequestSearch = (event) => {
    sessionStorage.setItem("search", "true");
    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }
    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue); //Updating searchValue object with column id and search value
    let newData = [...tempData];
    searchArray.forEach((column) => {
      //Iterate each column and check if any filter applied to that column and update the table data with the search
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;
      tempNewData = newData.filter((row) => {
        sessionStorage.setItem("search", "true");
        if (
          column === "statusSince" ||
          column === "payDate" ||
          column === "valueDate"
        ) {
          if (value !== "") {
            return row[column] === value;
          } else {
            return true;
          }
        } else if (column === "isin") {
          return row[column].toLowerCase().includes(value.toLowerCase());
        } else {
          if (value.length === 0) {
            return true;
          } else {
            return value.includes(row[column]);
          }
        }
      });
      newData = tempNewData;
    });
    setData(newData);
  };
  /**Not required */
  // const uploadDrpSync = () => {
  //   setAcceptOrReject(true);
  //   setOpenUploadDialog(false);
  // };

  /*
   * Updating option lists of columns dynamically with available values
   */
  const getOptionList = (issuances, columnName) => {
    let arr = [];

    issuances.map(
      (elem) => elem[columnName] !== "" && arr.push(elem[columnName])
    );
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) =>
      returnList.push({
        value: elem,
        label:
          columnName === "issuer"
            ? elem
            : elem?.replace(/([a-z])([A-Z])/g, "$1 $2"),
      })
    );
    return returnList;
  };

  const user = sessionStorage.getItem("user");

  /*
   * Closing receipt error popup
   */
  const closeRecieptErrorPopup = () => {
    setShowRecieptPopUp(false);
    setRecieptResponseText("");
  };

  /*
   * Closing success/error popup boxes
   */
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  /*
   * This method is to update the form fields with our set values
   */
  const changeFieldValue = (event) => {
    const id = [event.target.id][0];
    const value = event.target.value;
    if (id === "fromValueDate") {
      setFromValueDate(value);
    } else if (id === "toValueDate") {
      setToValueDate(value);
    } else if (id === "fromPayDate") {
      setFromPayDate(value);
    } else if (id === "toPayDate") {
      setToPayDate(value);
    } else if (id === "fromStatusDate") {
      setFromStatusDate(value);
    } else {
      setToStatusDate(value);
    }
  };
  /*
       date related functionalities
     */

  //clears "to date" field  if "from data" is changed
  const clearToDateField = (type) => {
    if (type === "fromValueDate") {
      setToValueDate(null);
    } else if (type === "fromPayDate") {
      setToPayDate(null);
    } else if (type === "fromStatusDate") {
      setToStatusDate(null);
    }
  };

  /*
   *
   */
  //disable date before "from date"
  const toDateDisable = (current, type) => {
    var fromDateValue = "";
    if (type === "value") fromDateValue = fromValueDate;
    else if (type === "pay") fromDateValue = fromPayDate;
    else if (type === "status") fromDateValue = fromStatusDate;
    return fromDateValue > current;
  };

  //enable serach button and stores value on input in search filter
  const EnableSearch = () => {
    sessionStorage.setItem("searchFilter", "true");
    /*eslint eqeqeq: "off"*/
    if (
      ((document.getElementById("isinInput").value != "" &&
        document.getElementById("isinInput").value.length == 12 &&
        /[A-Z]{2}[A-Z0-9]{9}[0-9]{1}/.test(
          document.getElementById("isinInput").value
        )) ||
        document.getElementById("isinInput").value == "") &&
      ((valueDateSwitch === true &&
        fromValueDate != undefined &&
        fromValueDate != "" &&
        fromValueDate != null) ||
        (valueDateSwitch === true &&
          (fromValueDate == undefined ||
            fromValueDate == "" ||
            fromValueDate == null)) ||
        (valueDateSwitch != true &&
          fromValueDate != undefined &&
          fromValueDate != "" &&
          fromValueDate != null &&
          toValueDate != undefined &&
          toValueDate != "" &&
          toValueDate != null) ||
        (valueDateSwitch != true &&
          (fromValueDate == undefined ||
            fromValueDate == "" ||
            fromValueDate == null) &&
          (toValueDate == undefined ||
            toValueDate == "" ||
            toValueDate == null))) &&
      ((payDateSwitch === true &&
        fromPayDate != undefined &&
        fromPayDate != "" &&
        fromPayDate != null) ||
        (payDateSwitch === true &&
          (fromPayDate == undefined ||
            fromPayDate == "" ||
            fromPayDate == null)) ||
        (payDateSwitch != true &&
          fromPayDate != undefined &&
          fromPayDate != "" &&
          fromPayDate != null &&
          toPayDate != undefined &&
          toPayDate != "" &&
          toPayDate != null) ||
        (payDateSwitch != true &&
          (fromPayDate == undefined ||
            fromPayDate == "" ||
            fromPayDate == null) &&
          (toPayDate == undefined || toPayDate == "" || toPayDate == null))) &&
      ((statusDateSwitch === true &&
        fromStatusDate != undefined &&
        fromStatusDate != "" &&
        fromStatusDate != null) ||
        (statusDateSwitch === true &&
          (fromStatusDate == undefined ||
            fromStatusDate == "" ||
            fromStatusDate == null)) ||
        (statusDateSwitch != true &&
          fromStatusDate != undefined &&
          fromStatusDate != "" &&
          fromStatusDate != null &&
          toStatusDate != undefined &&
          toStatusDate != "" &&
          toStatusDate != null) ||
        (statusDateSwitch != true &&
          (fromStatusDate == undefined ||
            fromStatusDate == "" ||
            fromStatusDate == null) &&
          (toStatusDate == undefined ||
            toStatusDate == "" ||
            toStatusDate == null))) &&
      ((selectedInstrumentType !== null &&
        //selectedInstrumentType.hasOwnProperty("value") &&
        selectedInstrumentType !== undefined &&
        selectedInstrumentType !== "") ||
        selectedInstrumentType === null ||
        selectedInstrumentType === "" ||
        selectedInstrumentType === undefined ||
        selectedInstrumentType.length === 0) &&
      ((selectedPayAgent != null &&
        selectedPayAgent != "" &&
        selectedPayAgent != undefined) ||
        selectedPayAgent == null ||
        selectedPayAgent == "" ||
        selectedPayAgent == undefined) &&
      ((selectedCbfNo != null &&
        selectedCbfNo != "" &&
        selectedCbfNo != undefined) ||
        selectedCbfNo == null ||
        selectedCbfNo == undefined ||
        selectedCbfNo == "") &&
      ((addLifecycleStatus != true &&
        selectedLifecycleStatus != undefined &&
        selectedLifecycleStatus != null &&
        selectedLifecycleStatus != "") ||
        (addLifecycleStatus != true &&
          (selectedLifecycleStatus == undefined ||
            selectedLifecycleStatus == null ||
            selectedLifecycleStatus == "")) ||
        (addLifecycleStatus == true &&
          selectedLifecycleStatus != undefined &&
          selectedLifecycleStatus != null &&
          selectedLifecycleStatus != "" &&
          selectedLifecycleStatusSecond != undefined &&
          selectedLifecycleStatusSecond != null &&
          selectedLifecycleStatusSecond != "")) &&
      ((addInternalStatus != true &&
        selectedInternalStatus != undefined &&
        selectedInternalStatus != null &&
        selectedInternalStatus != "") ||
        (addInternalStatus != true &&
          (selectedInternalStatus == undefined ||
            selectedInternalStatus == null ||
            selectedInternalStatus == "")) ||
        (addInternalStatus == true &&
          selectedInternalStatus != undefined &&
          selectedInternalStatus != null &&
          selectedInternalStatus != "")) &&
      ((addIssuerName != true &&
        selectedIssuerName != undefined &&
        selectedIssuerName != null &&
        selectedIssuerName != "") ||
        (addIssuerName != true &&
          (selectedIssuerName == undefined ||
            selectedIssuerName == null ||
            selectedIssuerName == "")) ||
        (addIssuerName == true &&
          selectedIssuerName != undefined &&
          selectedIssuerName != null &&
          selectedIssuerName != "" &&
          selectedIssuerNameSecond != undefined &&
          selectedIssuerNameSecond != null &&
          selectedIssuerNameSecond != "")) &&
      ((addOverallStatus != true &&
        selectedOverallStatus != undefined &&
        selectedOverallStatus != null &&
        selectedOverallStatus != "") ||
        (addOverallStatus != true &&
          (selectedOverallStatus == undefined ||
            selectedOverallStatus == null ||
            selectedOverallStatus == "")) ||
        (addOverallStatus == true &&
          selectedOverallStatus != undefined &&
          selectedOverallStatus != null &&
          selectedOverallStatus != "" &&
          selectedOverallStatusSecond != undefined &&
          selectedOverallStatusSecond != null &&
          selectedOverallStatusSecond != ""))
    ) {
      setSearchBtnFlag(false);
    } else {
      setExpandedSearch("expandPanel");
      setExpandedTable("false");
      setItemsCount(0);
      setQueryTable([]);
      setData([]);
      setSearchBtnFlag(true);
    }
    if (
      (document.getElementById("isinInput").value == "" ||
        !/[A-Z]{2}[A-Z0-9]{9}[0-9]{1}/.test(
          document.getElementById("isinInput").value
        )) &&
      ((valueDateSwitch === true &&
        (fromValueDate == undefined ||
          fromValueDate == "" ||
          fromValueDate == null)) ||
        (valueDateSwitch != true &&
          (fromValueDate == undefined ||
            fromValueDate == "" ||
            fromValueDate == null) &&
          (toValueDate == undefined ||
            toValueDate == "" ||
            toValueDate == null))) &&
      ((payDateSwitch === true &&
        (fromPayDate == undefined ||
          fromPayDate == "" ||
          fromPayDate == null)) ||
        (payDateSwitch != true &&
          (fromPayDate == undefined ||
            fromPayDate == "" ||
            fromPayDate == null) &&
          (toPayDate == undefined || toPayDate == "" || toPayDate == null))) &&
      ((statusDateSwitch === true &&
        (fromStatusDate == undefined ||
          fromStatusDate == "" ||
          fromStatusDate == null)) ||
        (statusDateSwitch != true &&
          (fromStatusDate == undefined ||
            fromStatusDate == "" ||
            fromStatusDate == null) &&
          (toStatusDate == undefined ||
            toStatusDate == "" ||
            toStatusDate == null))) &&
      (selectedCbfNo == null ||
        selectedCbfNo == undefined ||
        selectedCbfNo == "") &&
      (selectedInstrumentType == null ||
        selectedInstrumentType == "" ||
        selectedInstrumentType == undefined ||
        selectedInstrumentType.length === 0) &&
      (selectedPayAgent == undefined ||
        selectedPayAgent == null ||
        selectedPayAgent == "") &&
      ((addIssuerName != true &&
        (selectedIssuerName == undefined ||
          selectedIssuerName == null ||
          selectedIssuerName == "")) ||
        (addIssuerName == true &&
          (selectedIssuerName == undefined ||
            selectedIssuerName == null ||
            selectedIssuerName == "" ||
            selectedIssuerNameSecond == undefined ||
            selectedIssuerNameSecond == null ||
            selectedIssuerNameSecond == ""))) &&
      ((addOverallStatus != true &&
        (selectedOverallStatus == undefined ||
          selectedOverallStatus == null ||
          selectedOverallStatus == "")) ||
        (addOverallStatus == true &&
          (selectedOverallStatus == undefined ||
            selectedOverallStatus == null ||
            selectedOverallStatus == "" ||
            selectedOverallStatusSecond == undefined ||
            selectedOverallStatusSecond == null ||
            selectedOverallStatusSecond == ""))) &&
      ((addLifecycleStatus != true &&
        (selectedLifecycleStatus == undefined ||
          selectedLifecycleStatus == null ||
          selectedLifecycleStatus == "")) ||
        (addLifecycleStatus == true &&
          (selectedLifecycleStatus == undefined ||
            selectedLifecycleStatus == null ||
            selectedLifecycleStatus == "" ||
            selectedLifecycleStatusSecond == undefined ||
            selectedLifecycleStatusSecond == null ||
            selectedLifecycleStatusSecond == ""))) &&
      ((addInternalStatus != true &&
        (selectedInternalStatus == undefined ||
          selectedInternalStatus == null ||
          selectedInternalStatus == "")) ||
        (addInternalStatus == true &&
          (selectedInternalStatus == undefined ||
            selectedInternalStatus == null ||
            selectedInternalStatus == "")))
    ) {
      setExpandedSearch("expandPanel");
      setExpandedTable("false");
      setItemsCount(0);
      setQueryTable([]);
      setData([]);
      setSearchBtnFlag(true);
    }
  };

  const removeSearchTags = (e, tagkey) => {
    if (tagkey === "isin") {
      document.getElementById("isinInput").value = "";
    } else if (tagkey === "valueDateFrom") {
      setFromValueDate(null);
      setToValueDate(null);
      delete selectedFilter["valueDateFrom"];
      delete selectedFilter["valueDateTo"];
    } else if (tagkey === "valueDateTo") {
      setToValueDate(null);
    } else if (tagkey === "paymentDateFrom") {
      setFromPayDate(null);
      setToPayDate(null);
      delete selectedFilter["paymentDateFrom"];
      delete selectedFilter["paymentDateTo"];
    } else if (tagkey === "paymentDateTo") {
      setToPayDate(null);
    } else if (tagkey === "templateType") {
      setSelectedInstrumentType([]);
    } else if (tagkey === "issuerAgentName") {
      setSelectedCbfNo([]);
    } else if (tagkey === "issuerNames") {
      setSelectedIssuerName([]);
    } else if (tagkey === "issuerNameSecondParam") {
      setSelectedIssuerNameSecond([]);
    } else if (tagkey === "issuerKvNoPayAgent") {
      setSelectedPayAgent([]);
    } else if (tagkey === "statusSinceFrom") {
      setFromStatusDate(null);
      setToStatusDate(null);
      delete selectedFilter["statusSinceFrom"];
      delete selectedFilter["statusSinceTo"];
    } else if (tagkey === "statusSinceTo") {
      setToStatusDate(null);
    } else if (tagkey === "externalOverallStatuses") {
      setSelectedOverallStatus([]);
    } else if (tagkey === "externalProcessingStatuses") {
      setSelectedLifecycleStatus([]);
    } else if (tagkey === "internalStatuses") {
      setSelectedInternalStatus([]);
    }
    delete selectedFilter[tagkey];

    setSelectedFilter(selectedFilter);
    if (Object.keys(selectedFilter).length) {
      getFilteredData(e, selectedFilter);
    }
    e.stopPropagation();
  };
  //clear all filter applied in search filter and reset all values of all filter
  const CancelFilter = () => {
    setSelectedFilter({});
    setQueryTable([]);
    setItemsCount(0);
    setData([]);
    setExpandedSearch("expandPanel");
    setExpandedTable("false");
    setSearchBtnFlag(true);
    setSelectedIssuerName([]);
    setSelectedIssuerNameSecond([]);
    setAddIssuerName(false);
    setAddLifecycleStatus(false);
    setAddInternalStatus(false);
    setAddOverallStatus(false);
    setSelectedInstrumentType([]);
    setSelectedCbfNo([]);
    setSelectedPayAgent([]);
    setFromPayDate(null);
    setFromStatusDate(null);
    setFromValueDate(null);
    setToPayDate(null);
    setToStatusDate(null);
    setToValueDate(null);
    setSelectedLifecycleStatus([]);
    setSelectedLifecycleStatusSecond([]);
    setSelectedOverallStatus([]);
    setSelectedOverallStatusSecond([]);
    setSelectedInternalStatus([]);

    if (document.getElementById("isinInput") !== null) {
      document.getElementById("isinInput").value = ""; //removing value from isin input
    }
    sessionStorage.setItem("searchFilter", "false");
    sessionStorage.setItem("search", "false");
    if (applyFilter) {
      //removes table filter if applied
      removeAppliedFilter();
      setApplyFilter(!applyFilter);
    }
  };

  const displaySearchTags = () => {
    const TEMPLATE_TYPE = "Instrument Type";

    return (
      <React.Fragment>
        {searchTags.map(([key, value], index) => {
          if (value) {
            let label = "";
            if (key === "isin") {
              label = "ISIN";
            } else if (key === "internalStatuses") {
              label = "Internal Status";
            }
            // else if(key === 'overallStatusSecondParam') {label='Overall Status Second'}
            else if (key === "valueDateFrom") {
              label = `Value Date ${
                selectedFilter.valueDateTo ? "From" : "On"
              }`;
            } else if (key === "valueDateTo") {
              label = "Value Date To";
            } else if (key === "paymentDateFrom") {
              label = `Pay Date ${
                selectedFilter.paymentDateTo ? "From" : "On"
              }`;
            } else if (key === "paymentDateTo") {
              label = "Pay Date To";
            } else if (key === "templateType") {
              label = "Instrument Type";
            } else if (key === "issuerAgentName") {
              label = "Issuer Agent Name";
            } else if (key === "issuerNames") {
              label = "Issuer Name First";
            }
            //  else if(key === 'issuerNameSecondParam') {label='Issuer Name Second'}
            else if (key === "externalOverallStatuses") {
              label = "Overall Status";
            } else if (key === "externalProcessingStatuses") {
              label = "Processing Status";
            }
            // else if(key === 'lifecycleStatusSecondParam') {label='LifeCycle Status Second'}
            else if (key === "issuerKvNoPayAgent") {
              label = "Paying Agent";
              value =
                listPayAgent.find((item) => item.value === value)?.label ||
                value;
            } else if (key === "statusSinceFrom") {
              label = `Status Since ${
                selectedFilter.statusSinceTo ? "From" : "On"
              }`;
            } else if (key === "statusSinceTo") {
              label = "Status Since To";
            }
            if (
              key.toLocaleLowerCase().includes("date") ||
              key.includes("statusSince")
            ) {
              const dateParts = value.split("-");
              value = dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
            }
            if (label === TEMPLATE_TYPE) {
              let values = value;
              if (values.length > 0) {
                value = [
                  ...new Set(values.map((i) => getInstrumentTypeLabel(i))),
                ].join(",");
              }
            }

            if (label === "Processing Status") {
              value = [
                ...new Set(
                  value.map((item) => getExternalProcessStatusLabel(item))
                ),
              ].join(", ");
            }
            if (label === "Internal Status") {
              value = [
                ...new Set(value.map((item) => getInternalStatusLabel(item))),
              ].join(", ");
            }

            let tagLabel;
            if (typeof value == "string") {
              tagLabel = `${label}: ${value.replace(/_/g, " ")}`;
            } else {
              tagLabel = `${label}: ${value}`;
            }

            return (
              <SearchTags
                key={key}
                closeIcon={<CloseIcon style={{ fontSize: "1rem" }} />}
                closable={true}
                onClose={(e) => removeSearchTags(e, key)}
              >
                <span> {tagLabel}</span>
              </SearchTags>
            );
          }
        })}
      </React.Fragment>
    );
  };

  const setInternalStatusForSearch = (option) => {
    var options = [];
    option.forEach((opt) => {
      let opts = internalStatus.filter((s) => s.label === opt.label);
      options.push(...opts);
    });
    setSelectedFilter({
      ...selectedFilter,
      internalStatuses: options.map((i) => i.value),
      //?.join(','),
    });
  };

  const setInstrumentTypeForSearch = (option) => {
    var options = [];
    option.forEach((opt) => {
      let opts = instrumentTypes.filter((s) => s.label === opt.label);
      options.push(...opts);
    });
    setSelectedFilter({
      ...selectedFilter,
      templateType: options.map((i) => i.value),
    });
  };

  const uniqueInternalStatusOptions = searchDropdownList.internalStatus.reduce(
    (acc, currentOption) => {
      const itemWithLabelExists = acc.find(
        (item) => item.label === currentOption.label
      );
      if (!itemWithLabelExists) {
        acc.push(currentOption);
      }
      return acc;
    },
    []
  );

  return (
    <React.Fragment>
      {props.showDetailsPage ? (
        <React.Fragment>
          <IssuanceDetails issuanceObj={issuanceObj} />
        </React.Fragment>
      ) : (
        <StyledContainer>
          <div>
            {recieptResponseText && showRecieptPopUp ? (
              <div>
                <div className="popup" id="iss_popup_reciept">
                  <RecieptErrorPopup
                    data-testid="errorPopup"
                    recieptErrorPopupText={recieptResponseText}
                    recieptErrorVisibility={showRecieptPopUp}
                    handleClose={() => closeRecieptErrorPopup()}
                  />
                </div>
              </div>
            ) : (
              <div>
                {responseText && showPopUp && (
                  <div className="popup" id="iss_popup_response">
                    {flagPopUp !== "error" ? (
                      <SuccessPopup
                        successPopupText={responseText}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    ) : (
                      <ErrorPopup
                        errorPopupText={responseText}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
            <Grid container item xs={12}>
              <Grid item xs={11}>
                <Typography variant="h2" id="iss_typo_issuance">
                  Issuances
                </Typography>
              </Grid>
              {userState.userRoles.includes("Issuing Agent Operator") && (
                <Grid item xs={1} align="end" style={{ zIndex: "3" }}>
                  <Space>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            id="temp_newIssuance_htmlToolTip"
                            variant="h5"
                          >
                            New Issuance
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img
                        id="temp_create_activeIcon"
                        src={createActiveIcon}
                        alt={"create"}
                        data-testid="createIssuanceIcon"
                        className="activeIcon"
                        onClick={() => {
                          setCreateIssuance(true);
                          setCreateActionTitle("New Issuance");
                        }}
                      />
                    </HtmlTooltip>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            id="temp_increaseDecrease_htmlToolTip"
                            variant="h5"
                          >
                            Increase
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img
                        id="temp_increaseIcon"
                        src={increaseIcon}
                        alt={"create"}
                        data-testid="increaseIcon"
                        className="activeIcon"
                        onClick={() => {
                          setCreateIssuance(true);
                          setCreateActionTitle("Increase");
                        }}
                      />
                    </HtmlTooltip>
                  </Space>
                </Grid>
              )}
            </Grid>
            <TabPanel currentIsin={currentIsin}>
              <React.Fragment>
                <Grid
                  container
                  className="gridContainer"
                  id="iss_accordion_gridContainer"
                >
                  <Accordion expanded={expandedSearch === "expandPanel"}>
                    <AccordionSummary
                      id="panelid-header"
                      onClick={(event) => {
                        if (
                          event.target.id !== "iss_buttonGroup_search" &&
                          event.target.id !== "iss_button_SearchButton" &&
                          event.target.id !== "iss_buttonGroup_cancel" &&
                          event.target.id !== "iss_button_cancelFilterButton" &&
                          event.target.className !== "MuiButton-label"
                        ) {
                          if (expandedSearch === "expandPanel") {
                            setExpandedSearch("false");
                            setIsShowSearchTags(true);
                          } else {
                            setExpandedSearch("expandPanel");
                            setIsShowSearchTags(false);
                          }
                        }
                      }}
                    >
                      <Grid
                        style={{ display: "flex", alignItems: "center" }}
                        container
                      >
                        <Grid
                          container
                          xs={1}
                          id="iss_heading_gridContainer_searchWith"
                        >
                          <Typography
                            variant="h5"
                            className="pT-8"
                            id="iss_typo_pT_8"
                          >
                            Search :
                          </Typography>
                        </Grid>
                        <Grid xs={8} container id="iss_grid_btnContainer">
                          {isShowSearchTags && (
                            <div style={{ "margin-top": "1px" }}>
                              {displaySearchTags()}
                            </div>
                          )}
                        </Grid>
                        <Grid
                          xs={3}
                          className="btnContainer"
                          id="iss_grid_btnContainer"
                        >
                          <div
                            className="btnContainer"
                            id="iss_div_btnContainer"
                          >
                            <ButtonGroup
                              aria-label="medium button group"
                              id="iss_buttonGroup_search"
                            >
                              <Button
                                variant="contained"
                                className="SearchButton"
                                disabled={searchBtnFlag}
                                size="medium"
                                id="iss_button_SearchButton"
                                data-testid="iss_button_SearchButton"
                                onClick={(evt) =>
                                  getFilteredData(evt, selectedFilter)
                                }
                              >
                                Search
                              </Button>
                            </ButtonGroup>
                            <ButtonGroup
                              aria-label="medium button group"
                              id="iss_buttonGroup_cancel"
                            >
                              <Button
                                variant="contained"
                                className="cancelFilterButton "
                                id="iss_button_cancelFilterButton"
                                onClick={() => CancelFilter()}
                              >
                                Reset
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <StyledSearchContainer>
                        <Box sx={{ width: "100%" }}>
                          <Grid container spacing={3}>
                            <Grid container xs={6}>
                              <Stack
                                spacing={2}
                                style={{
                                  width: "100%",
                                  borderRight: "1px solid #00a7c4",
                                }}
                              >
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      ISIN
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}></Grid>
                                  <Grid xs={8}>
                                    <TextField
                                      placeholder="Enter Details"
                                      className="inputDetails inputEntry"
                                      variant="outlined"
                                      id="isinInput"
                                      onChange={(e) => {
                                        setSelectedFilter({
                                          ...selectedFilter,
                                          isin: e.target.value,
                                        });
                                        EnableSearch();
                                      }}
                                      value={selectedFilter?.isin || ""}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container id="gridContainer_valueDate">
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                      id="typo_queryField_valueDate"
                                    >
                                      Value Date
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}>
                                    <img
                                      src={
                                        valueDateSwitch ? notArray : ArrayIcon
                                      }
                                      onClick={() => {
                                        valueDateSwitch
                                          ? setValueDateSwitch(false)
                                          : setValueDateSwitch(true);
                                      }}
                                      alt="calendar"
                                      className="queryFeild"
                                      id="icon_queryField_valueDate"
                                    />
                                  </Grid>
                                  <Grid
                                    container
                                    xs={8}
                                    className="queryDataPicker"
                                    id="grid_on_queryDataPicker_valueDate"
                                  >
                                    {valueDateSwitch ? (
                                      <React.Fragment>
                                        <Grid item xs={10}>
                                          <Row>
                                            <Col md={2} className="paddingToR">
                                              <Typography
                                                variant="h6"
                                                className="fromTo"
                                                id="iss_typo_on_queryDataPicker_valueDate"
                                              >
                                                On
                                              </Typography>
                                            </Col>
                                            <Col md={10}>
                                              <DatePicker
                                                showNow={false}
                                                id="valueOnDate"
                                                format={dateFormat}
                                                placeholder={dateFormat}
                                                className="queryFromTo"
                                                suffixIcon={
                                                  <img
                                                    src={calendarIcon}
                                                    alt="calendar"
                                                  />
                                                }
                                                onChange={(
                                                  date,
                                                  dateString
                                                ) => {
                                                  clearToDateField(
                                                    "fromValueDate"
                                                  );
                                                  changeFieldValue({
                                                    target: {
                                                      id: "fromValueDate",
                                                      value:
                                                        date !== null
                                                          ? date
                                                          : "",
                                                    },
                                                  });
                                                  date !== null
                                                    ? setSelectedFilter({
                                                        ...selectedFilter,
                                                        valueDateFrom:
                                                          flipDateFormat(
                                                            dateString
                                                          ),
                                                      })
                                                    : setSelectedFilter({
                                                        ...selectedFilter,
                                                        valueDateFrom: "",
                                                        valueDateTo: "",
                                                      });
                                                }}
                                                value={
                                                  fromValueDate !== null
                                                    ? fromValueDate
                                                    : ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Grid>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <Grid container spacing={1}>
                                          <Grid item xs={10}>
                                            <Row>
                                              <Col
                                                md={2}
                                                className="paddingToR"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className="fromTo"
                                                  id="iss_typo_pT-8_from_1"
                                                >
                                                  From
                                                </Typography>
                                              </Col>
                                              <Col md={10}>
                                                <DatePicker
                                                  data-testid="valueFromDate"
                                                  id="valueFromDate"
                                                  showNow={false}
                                                  format={dateFormat}
                                                  placeholder={dateFormat}
                                                  className="queryFromTo"
                                                  suffixIcon={
                                                    <img
                                                      src={calendarIcon}
                                                      alt="calendar"
                                                    />
                                                  }
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    clearToDateField(
                                                      "fromValueDate"
                                                    );
                                                    changeFieldValue({
                                                      target: {
                                                        id: "fromValueDate",
                                                        value:
                                                          date !== null
                                                            ? date
                                                            : "",
                                                      },
                                                    });
                                                    date !== null
                                                      ? setSelectedFilter({
                                                          ...selectedFilter,
                                                          valueDateFrom:
                                                            flipDateFormat(
                                                              dateString
                                                            ),
                                                        })
                                                      : setSelectedFilter({
                                                          ...selectedFilter,
                                                          valueDateFrom: "",
                                                          valueDateTo: "",
                                                        });
                                                  }}
                                                  value={
                                                    fromValueDate !== null
                                                      ? fromValueDate
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Grid>
                                          <Grid item xs={10}>
                                            <Row>
                                              <Col
                                                md={2}
                                                className="paddingToR"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className="fromTo"
                                                  id="iss_typo_pT_8_to_1"
                                                >
                                                  To
                                                </Typography>
                                              </Col>
                                              <Col md={10}>
                                                <DatePicker
                                                  data-testid="valueToDate"
                                                  id="valueToDate"
                                                  className="inputEntry"
                                                  style={{ marginLeft: "10px" }}
                                                  showNow={false}
                                                  format={dateFormat}
                                                  placeholder={dateFormat}
                                                  disabledDate={(event) =>
                                                    toDateDisable(
                                                      event,
                                                      "value"
                                                    )
                                                  }
                                                  suffixIcon={
                                                    <img
                                                      src={calendarIcon}
                                                      alt="calendar"
                                                    />
                                                  }
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    changeFieldValue({
                                                      target: {
                                                        id: "toValueDate",
                                                        value:
                                                          date !== null
                                                            ? date
                                                            : "",
                                                      },
                                                    });
                                                    date !== null
                                                      ? setSelectedFilter({
                                                          ...selectedFilter,
                                                          valueDateTo:
                                                            flipDateFormat(
                                                              dateString
                                                            ),
                                                        })
                                                      : setSelectedFilter({
                                                          ...selectedFilter,
                                                          valueDateTo: "",
                                                        });
                                                  }}
                                                  value={
                                                    toValueDate !== null
                                                      ? toValueDate
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Instrument Type
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}></Grid>
                                  <Grid xs={8}>
                                    <MultiSelectDropdown
                                      isClearable={true}
                                      placeholder="Please Select"
                                      className="queryDropdown"
                                      onChange={(option) => {
                                        setSelectedInstrumentType(option);
                                        option !== null && option.length > 0
                                          ? setInstrumentTypeForSearch(option)
                                          : setSelectedFilter({
                                              ...selectedFilter,
                                              templateType: [],
                                            });
                                      }}
                                      id="selectInstrumentType"
                                      value={selectedInstrumentType}
                                      options={TemplateTypeList}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Issuer Name
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}> </Grid>
                                  <Grid container xs={8}>
                                    <Grid xs={11}>
                                      <MultiSelectDropdown
                                        isClearable={true}
                                        placeholder="Please Select"
                                        onChange={(option) => {
                                          setSelectedIssuerName(option);
                                          option !== null && option.length > 0
                                            ? setSelectedFilter({
                                                ...selectedFilter,
                                                issuerNames: option.map(
                                                  (i) => i.value
                                                ),
                                                //?.join(','),
                                              })
                                            : setSelectedFilter({
                                                ...selectedFilter,
                                                issuerNames: [],
                                              });
                                        }}
                                        id="selectIssuerName"
                                        value={selectedIssuerName}
                                        options={listIssuerName}
                                        limitSelectCount={2}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Paying Agent
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}></Grid>
                                  <Grid xs={8}>
                                    <MultiSelectDropdown
                                      isClearable={true}
                                      placeholder="Please Select"
                                      onChange={(option) => {
                                        setSelectedPayAgent(option);
                                        option !== null && option.length > 0
                                          ? setSelectedFilter({
                                              ...selectedFilter,
                                              issuerKvNoPayAgent: option.map(
                                                (i) => i.value
                                              ),
                                            })
                                          : setSelectedFilter({
                                              ...selectedFilter,
                                              issuerKvNoPayAgent: [],
                                            });
                                      }}
                                      id="selectedPayAgent"
                                      options={listPayAgent}
                                      value={selectedPayAgent}
                                      className="queryDropdown searchOption inputEntry"
                                    />
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Grid>
                            <Grid container xs={6}>
                              <Stack
                                spacing={2}
                                style={{ width: "100%", paddingLeft: "30px" }}
                              >
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Overall Status
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}> </Grid>
                                  <Grid container xs={8}>
                                    <Grid xs={11}>
                                      <MultiSelectDropdown
                                        isClearable={true}
                                        placeholder="Please Select"
                                        onChange={(option) => {
                                          setSelectedOverallStatus(option);
                                          //option !== null && option.length === 1 ? setSelectedFilter({ ...selectedFilter, overallStatusFirstParam: option[0].value, overallStatusSecondParam: ''}) : option.length  >= 2 ? setSelectedFilter({ ...selectedFilter, overallStatusFirstParam: option[0].value, overallStatusSecondParam: option[1].value }) : setSelectedFilter({ ...selectedFilter, overallStatusFirstParam: '', overallStatusSecondParam: '' });
                                          option !== null && option.length > 0
                                            ? setSelectedFilter({
                                                ...selectedFilter,
                                                externalOverallStatuses:
                                                  option.map((i) => i.value),
                                                //?.join(','),
                                              })
                                            : setSelectedFilter({
                                                ...selectedFilter,
                                                externalOverallStatuses: [],
                                              });
                                        }}
                                        id="selectOverallStatus"
                                        value={selectedOverallStatus}
                                        options={filteredOverallStatus}
                                        menuPortalTarget={document.body}
                                        limitSelectCount={2}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Pay Date
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}>
                                    <img
                                      src={payDateSwitch ? notArray : ArrayIcon}
                                      onClick={() => {
                                        payDateSwitch
                                          ? setPayDateSwitch(false)
                                          : setPayDateSwitch(true);
                                      }}
                                      alt="calendar"
                                      data-testid="icon_queryField_payDate"
                                      id="icon_queryField_payDate"
                                      className="queryFeild"
                                    />
                                  </Grid>
                                  <Grid
                                    container
                                    xs={8}
                                    className="queryDataPicker"
                                  >
                                    {payDateSwitch ? (
                                      <React.Fragment>
                                        <Grid item xs={10}>
                                          <Row>
                                            <Col md={2} className="paddingToR">
                                              <Typography
                                                variant="h6"
                                                className="fromTo"
                                                id="iss_typo_on_paydate"
                                              >
                                                On
                                              </Typography>
                                            </Col>
                                            <Col md={10}>
                                              <DatePicker
                                                showNow={false}
                                                format={dateFormat}
                                                placeholder={dateFormat}
                                                className="queryFromTo"
                                                id="valueOnPayDate"
                                                suffixIcon={
                                                  <img
                                                    src={calendarIcon}
                                                    alt="calendar"
                                                  />
                                                }
                                                onChange={(
                                                  date,
                                                  dateString
                                                ) => {
                                                  clearToDateField(
                                                    "fromPayDate"
                                                  );
                                                  changeFieldValue({
                                                    target: {
                                                      id: "fromPayDate",
                                                      value:
                                                        date !== null
                                                          ? date
                                                          : "",
                                                    },
                                                  });
                                                  date !== null
                                                    ? setSelectedFilter({
                                                        ...selectedFilter,
                                                        paymentDateFrom:
                                                          flipDateFormat(
                                                            dateString
                                                          ),
                                                      })
                                                    : setSelectedFilter({
                                                        ...selectedFilter,
                                                        paymentDateFrom: "",
                                                        paymentDateTo: "",
                                                      });
                                                }}
                                                value={
                                                  fromPayDate !== null
                                                    ? fromPayDate
                                                    : ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Grid>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <Grid container spacing={1}>
                                          <Grid item xs={10}>
                                            <Row>
                                              <Col
                                                md={2}
                                                className="paddingToR"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className="fromTo"
                                                  id="iss_typo_from_paydate"
                                                >
                                                  From
                                                </Typography>
                                              </Col>
                                              <Col md={10}>
                                                <DatePicker
                                                  data-testid="valueFromPayDate"
                                                  id="valueFromPayDate"
                                                  showNow={false}
                                                  format={dateFormat}
                                                  placeholder={dateFormat}
                                                  className="queryFromTo"
                                                  suffixIcon={
                                                    <img
                                                      src={calendarIcon}
                                                      alt="calendar"
                                                    />
                                                  }
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    clearToDateField(
                                                      "fromPayDate"
                                                    );
                                                    changeFieldValue({
                                                      target: {
                                                        id: "fromPayDate",
                                                        value:
                                                          date !== null
                                                            ? date
                                                            : "",
                                                      },
                                                    });
                                                    date !== null
                                                      ? setSelectedFilter({
                                                          ...selectedFilter,
                                                          paymentDateFrom:
                                                            flipDateFormat(
                                                              dateString
                                                            ),
                                                        })
                                                      : setSelectedFilter({
                                                          ...selectedFilter,
                                                          paymentDateFrom: "",
                                                          paymentDateTo: "",
                                                        });
                                                  }}
                                                  value={
                                                    fromPayDate !== null
                                                      ? fromPayDate
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Grid>
                                          <Grid item xs={10}>
                                            <Row>
                                              <Col
                                                md={2}
                                                className="paddingToR"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className="fromTo"
                                                  id="iss_typo_to_payDate"
                                                >
                                                  To
                                                </Typography>
                                              </Col>
                                              <Col md={10}>
                                                <DatePicker
                                                  data-testid="valueToPayDate"
                                                  id="valueToPayDate"
                                                  className="inputEntry"
                                                  style={{ marginLeft: "10px" }}
                                                  showNow={false}
                                                  format={dateFormat}
                                                  placeholder={dateFormat}
                                                  disabledDate={(event) =>
                                                    toDateDisable(event, "pay")
                                                  }
                                                  suffixIcon={
                                                    <img
                                                      src={calendarIcon}
                                                      alt="calendar"
                                                    />
                                                  }
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    changeFieldValue({
                                                      target: {
                                                        id: "toPayDate",
                                                        value:
                                                          date !== null
                                                            ? date
                                                            : "",
                                                      },
                                                    });
                                                    date !== null
                                                      ? setSelectedFilter({
                                                          ...selectedFilter,
                                                          paymentDateTo:
                                                            flipDateFormat(
                                                              dateString
                                                            ),
                                                        })
                                                      : setSelectedFilter({
                                                          ...selectedFilter,
                                                          paymentDateTo: "",
                                                        });
                                                  }}
                                                  value={
                                                    toPayDate !== null
                                                      ? toPayDate
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Issuer Agent
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}></Grid>
                                  <Grid xs={8}>
                                    <MultiSelectDropdown
                                      isClearable={true}
                                      placeholder="Please Select"
                                      onChange={(option) => {
                                        setSelectedCbfNo(option);
                                        option !== null && option.length > 0
                                          ? setSelectedFilter({
                                              ...selectedFilter,
                                              issuerAgentName: option.map(
                                                (i) => i.value
                                              ),
                                            })
                                          : setSelectedFilter({
                                              ...selectedFilter,
                                              issuerAgentName: [],
                                            });
                                      }}
                                      className="searchOption queryDropdown"
                                      id="selectedCbfNo"
                                      options={listCbfNo}
                                      value={selectedCbfNo}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Processing Status
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}> </Grid>
                                  <Grid container xs={8}>
                                    <Grid xs={11}>
                                      <MultiSelectDropdown
                                        isClearable={true}
                                        placeholder="Please Select"
                                        onChange={(option) => {
                                          setSelectedLifecycleStatus(option);
                                          option !== null && option.length > 0
                                            ? setSelectedFilter({
                                                ...selectedFilter,
                                                externalProcessingStatuses:
                                                  option.map((i) => i.value),
                                                //?.join(','),
                                              })
                                            : setSelectedFilter({
                                                ...selectedFilter,
                                                externalProcessingStatuses: [],
                                              });
                                        }}
                                        id="selectLifecycleStatus"
                                        value={selectedLifecycleStatus}
                                        options={
                                          searchDropdownList.processStatus
                                        }
                                        limitSelectCount={2}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {user === "Clearstream" && (
                                  <Grid container>
                                    <Grid xs={3}>
                                      <Typography
                                        variant="h5"
                                        className="queryFeild"
                                      >
                                        Internal Status
                                      </Typography>
                                    </Grid>
                                    <Grid xs={1}> </Grid>
                                    <Grid container xs={8}>
                                      <Grid xs={11}>
                                        <MultiSelectDropdown
                                          isClearable={true}
                                          placeholder="Please Select"
                                          onChange={(option) => {
                                            setSelectedInternalStatus(option);
                                            option !== null && option.length > 0
                                              ? setInternalStatusForSearch(
                                                  option
                                                )
                                              : setSelectedFilter({
                                                  ...selectedFilter,
                                                  internalStatuses: [],
                                                });
                                          }}
                                          id="selectProcessStatus"
                                          value={selectedInternalStatus}
                                          options={uniqueInternalStatusOptions}
                                          limitSelectCount={2}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid container>
                                  <Grid xs={3}>
                                    <Typography
                                      variant="h5"
                                      className="queryFeild"
                                    >
                                      Status Since
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}>
                                    <img
                                      src={
                                        statusDateSwitch ? notArray : ArrayIcon
                                      }
                                      onClick={() => {
                                        statusDateSwitch
                                          ? setStatusDateSwitch(false)
                                          : setStatusDateSwitch(true);
                                      }}
                                      alt="calendar"
                                      data-testid="icon_queryField_StatusDate"
                                      id="icon_queryField_StatusDate"
                                      className="queryFeild"
                                    />
                                  </Grid>
                                  <Grid
                                    container
                                    xs={8}
                                    className="queryDataPicker"
                                  >
                                    {statusDateSwitch ? (
                                      <React.Fragment>
                                        <Grid item xs={10}>
                                          <Row>
                                            <Col md={2} className="paddingToR">
                                              <Typography
                                                variant="h6"
                                                className="fromTo"
                                                id="iss_typoon_status_date"
                                              >
                                                On
                                              </Typography>
                                            </Col>
                                            <Col md={10}>
                                              <CustomDatePicker
                                                defaultValue={
                                                  setDefaultStatusSinceDate
                                                    ? defaultStatusSinceDate
                                                    : ""
                                                }
                                                showNow={false}
                                                format={dateFormat}
                                                placeholder={dateFormat}
                                                className="queryFromTo"
                                                id="valueOnStatusDate"
                                                suffixIcon={
                                                  <img
                                                    src={calendarIcon}
                                                    alt="calendar"
                                                  />
                                                }
                                                onChange={(
                                                  date,
                                                  dateString
                                                ) => {
                                                  clearToDateField(
                                                    "fromStatusDate"
                                                  );
                                                  changeFieldValue({
                                                    target: {
                                                      id: "fromStatusDate",
                                                      value:
                                                        date !== null
                                                          ? date
                                                          : "",
                                                    },
                                                  });
                                                  date !== null
                                                    ? setSelectedFilter({
                                                        ...selectedFilter,
                                                        statusSinceFrom:
                                                          flipDateFormat(
                                                            dateString
                                                          ),
                                                      })
                                                    : setSelectedFilter({
                                                        ...selectedFilter,
                                                        statusSinceFrom: "",
                                                        statusSinceTo: "",
                                                      });
                                                }}
                                                value={
                                                  fromStatusDate !== null
                                                    ? fromStatusDate
                                                    : ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Grid>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <Grid container spacing={1}>
                                          <Grid item xs={10}>
                                            <Row>
                                              <Col
                                                md={2}
                                                className="paddingToR"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className="fromTo"
                                                  id="iss_typo_from_status_date"
                                                >
                                                  From
                                                </Typography>
                                              </Col>
                                              <Col md={10}>
                                                <CustomDatePicker
                                                  data-testid="valueFromStatusDate"
                                                  id="valueFromStatusDate"
                                                  showNow={false}
                                                  format={dateFormat}
                                                  placeholder={dateFormat}
                                                  className="queryFromTo"
                                                  suffixIcon={
                                                    <img
                                                      src={calendarIcon}
                                                      alt="calendar"
                                                    />
                                                  }
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    clearToDateField(
                                                      "fromStatusDate"
                                                    );
                                                    changeFieldValue({
                                                      target: {
                                                        id: "fromStatusDate",
                                                        value:
                                                          date !== null
                                                            ? date
                                                            : "",
                                                      },
                                                    });
                                                    date !== null
                                                      ? setSelectedFilter({
                                                          ...selectedFilter,
                                                          statusSinceFrom:
                                                            flipDateFormat(
                                                              dateString
                                                            ),
                                                        })
                                                      : setSelectedFilter({
                                                          ...selectedFilter,
                                                          statusSinceFrom: "",
                                                          statusSinceTo: "",
                                                        });
                                                  }}
                                                  value={
                                                    fromStatusDate !== null
                                                      ? fromStatusDate
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Grid>
                                          <Grid item xs={10}>
                                            <Row>
                                              <Col
                                                md={2}
                                                className="paddingToR"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className="fromTo"
                                                  id="iss_typo_to_status_date"
                                                >
                                                  To
                                                </Typography>
                                              </Col>
                                              <Col md={10}>
                                                <DatePicker
                                                  data-testid="valueToStatusDate"
                                                  id="valueToStatusDate"
                                                  className="inputEntry"
                                                  style={{ marginLeft: "10px" }}
                                                  showNow={false}
                                                  format={dateFormat}
                                                  placeholder={dateFormat}
                                                  disabledDate={(event) =>
                                                    toDateDisable(
                                                      event,
                                                      "status"
                                                    )
                                                  }
                                                  suffixIcon={
                                                    <img
                                                      src={calendarIcon}
                                                      alt="calendar"
                                                    />
                                                  }
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    changeFieldValue({
                                                      target: {
                                                        id: "toStatusDate",
                                                        value:
                                                          date !== null
                                                            ? date
                                                            : "",
                                                      },
                                                    });
                                                    date !== null
                                                      ? setSelectedFilter({
                                                          ...selectedFilter,
                                                          statusSinceTo:
                                                            flipDateFormat(
                                                              dateString
                                                            ),
                                                        })
                                                      : setSelectedFilter({
                                                          ...selectedFilter,
                                                          statusSinceTo: "",
                                                        });
                                                  }}
                                                  value={
                                                    toStatusDate !== null
                                                      ? toStatusDate
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    )}
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </StyledSearchContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <StyledTableContainer>
                  <Grid container className="gridContainer">
                    <Accordion expanded={expandedTable === "expandPanel"}>
                      <AccordionSummary
                        id="panel1d-header"
                        onClick={(event) => {
                          if (
                            event.target.id !== "iss_icon_updateIssuance" &&
                            event.target.id !== "iss_icon_viewdetails" &&
                            event.target.id !== "iss_icon_imgViewDetails" &&
                            event.target.id !== "iss_icon_refreshTable" &&
                            event.target.className !== "MuiButton-label"
                          ) {
                            if (expandedTable === "expandPanel")
                              setExpandedTable("false");
                            else {
                              setExpandedTable("expandPanel");
                            }
                          }
                        }}
                      >
                        <Grid container>
                          <Grid
                            container
                            xs={5}
                            id="iss_heading_gridContainer_mostRecent"
                          >
                            <Grid item xs={2}>
                              <Typography
                                variant="h5"
                                className="pT-8 "
                                id="iss_typo_mostRecent"
                              >
                                List
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className="pL-10"
                            id="iss_grid_iss_typo_mostRecent"
                          ></Grid>
                          {expandedTable === "expandPanel" && (
                            <Grid
                              xs={5}
                              className="btnContainer"
                              id="iss_grid_mostRecent_btnContainer"
                            >
                              <div
                                className="btnContainer"
                                id="iss_div_mostRecent_btnContainer"
                              >
                                <Space>
                                  <ButtonGroup
                                    aria-label="medium button group"
                                    id="iss_buttonGroup_uploadDrp"
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      data-testid="History_btn"
                                      id="iss_btn_btnUploadDRPFile"
                                      className="buttonStyle tableTabItem"
                                      disabled={
                                        selected.length === 0 ||
                                        !userState.userRoleRight.includes(
                                          "View_Combined_Log"
                                        )
                                      }
                                      onClick={() =>
                                        showIssuanceDetails(
                                          issuanceObj,
                                          "history"
                                        )
                                      }
                                    >
                                      History
                                    </Button>
                                  </ButtonGroup>
                                  <ButtonGroup
                                    aria-label="medium button group"
                                    id="iss_buttonGroup_uploadDrp"
                                  >
                                    {userState.userRoles.includes(
                                      "Paying Agent Operator"
                                    ) && (
                                      <HtmlTooltip
                                        id="iss_htmltooltip_updateIssuance1"
                                        title={
                                          <React.Fragment>
                                            <Typography
                                              variant="h5"
                                              id="iss_typo_updateIssuance1"
                                            >
                                              Upload a DRP File
                                            </Typography>
                                          </React.Fragment>
                                        }
                                        placement="top"
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          data-testid="btnUploadDRPFile"
                                          className="buttonStyle tableTabItem"
                                          id="btnBatchRedemptions"
                                          style={{
                                            width: "40px",
                                            borderRadius: "10px",
                                          }}
                                          onClick={() => {
                                            setOpenUploadDialog(true);
                                            setUploadFlag("uploadDRP");
                                            setUploadTitle("Upload a DRP File");
                                          }}
                                          disabled={
                                            selected.length > 0 ||
                                            !userState.userRoles.includes(
                                              "Paying Agent Operator"
                                            )
                                          }
                                        >
                                          <ArticleOutlinedIcon />
                                        </Button>
                                      </HtmlTooltip>
                                    )}
                                  </ButtonGroup>
                                  <ButtonGroup
                                    aria-label="medium button group"
                                    id="iss_buttonGroup_uploadDrp"
                                  >
                                    {userState.userRoles.includes(
                                      "Paying Agent Operator"
                                    ) && (
                                      <HtmlTooltip
                                        id="iss_htmltooltip_updateIssuance1"
                                        title={
                                          <React.Fragment>
                                            <Typography
                                              variant="h5"
                                              id="iss_typo_updateIssuance1"
                                            >
                                              Upload batch DRPs
                                            </Typography>
                                          </React.Fragment>
                                        }
                                        placement="top"
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          data-testid="btnUploadDRPFile"
                                          className="buttonStyle tableTabItem"
                                          id="btnBatchRedemptions"
                                          style={{
                                            width: "40px",
                                            borderRadius: "10px",
                                          }}
                                          disabled={
                                            selected.length > 0 ||
                                            !userState.userRoles.includes(
                                              "Paying Agent Operator"
                                            )
                                          }
                                          onClick={() => {
                                            setOpenUploadDialog(true);
                                            setUploadFlag(
                                              "uploadBatchRedemptions"
                                            );
                                            setUploadTitle(
                                              "Upload a batch of DRPs"
                                            );
                                          }}
                                        >
                                          <PostAddIcon />
                                        </Button>
                                      </HtmlTooltip>
                                    )}
                                  </ButtonGroup>
                                  {selected.length !== 1 ? (
                                    <HtmlTooltip
                                      id="iss_htmltooltip_viewDetails1"
                                      title={
                                        <React.Fragment>
                                          <Typography
                                            variant="h5"
                                            id="iss_typo_viewdetails"
                                          >
                                            View Details
                                          </Typography>
                                        </React.Fragment>
                                      }
                                      placement="top"
                                    >
                                      <img
                                        src={viewDetailsGreyIcon}
                                        alt="details"
                                        className="greyIcon activeIcon tableTabItem"
                                        id="iss_icon_viewdetails"
                                      />
                                    </HtmlTooltip>
                                  ) : (
                                    <HtmlTooltip
                                      id="iss_htmltooltip_viewDetails2"
                                      title={
                                        <React.Fragment>
                                          <Typography variant="h5">
                                            View Details{" "}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                      placement="top"
                                    >
                                      <img
                                        src={viewDetailsActiveIcon}
                                        data-testid="imgViewDetails"
                                        alt="details"
                                        className="activeIcon tableTabItem"
                                        id="iss_icon_imgViewDetails"
                                        onClick={() =>
                                          showIssuanceDetails(
                                            issuanceObj,
                                            "details"
                                          )
                                        }
                                      />
                                    </HtmlTooltip>
                                  )}
                                  <HtmlTooltip
                                    id="iss_htmltooltip_viewDetails3"
                                    title={
                                      <React.Fragment>
                                        <Typography
                                          variant="h5"
                                          id="iss_typo_refresh"
                                        >
                                          Refresh
                                        </Typography>
                                      </React.Fragment>
                                    }
                                    placement="top"
                                  >
                                    <img
                                      src={refresh}
                                      alt="details"
                                      onClick={(evt) => {
                                        setTriggerResetPage(
                                          (triggerResetPage) =>
                                            triggerResetPage + 1
                                        );
                                        getFilteredData(evt, selectedFilter);
                                      }}
                                      className="greyIcon tableTabItem"
                                      id="iss_icon_refreshTable"
                                    />
                                  </HtmlTooltip>
                                </Space>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer
                          className="tableStyling tableFixHead"
                          style={{ maxHeight: "auto" }}
                          id="iss_tableContainer_tableStyling"
                        >
                          <Table
                            aria-label="sticky table"
                            data-testid="tableView"
                            stickyHeader
                            id="iss_table_sticky-table"
                          >
                            <TableHead>
                              <TableRow>
                                {data.length !== 0 ? (
                                  <TableCell
                                    className="tableCellBorder"
                                    id="iss_tablecell_tableCellBorder"
                                  >
                                    {/* {!selectedOption ? */}
                                    <HtmlTooltip
                                      id="iss_htmltooltip_applyfilter"
                                      title={
                                        <React.Fragment>
                                          <Typography variant="h5">
                                            {applyFilter !== true
                                              ? "Add Filters"
                                              : "Remove Filters"}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <span
                                        className="filterIcon"
                                        id="iss_span_filterIcon"
                                      >
                                        <img
                                          src={filterIcon}
                                          alt="triangle"
                                          id="iss_img_applyTableFilter"
                                          onClick={() => {
                                            if (applyFilter) {
                                              setData(tempData);
                                              sessionStorage.setItem(
                                                "search",
                                                "false"
                                              );
                                            }
                                            if (data.length !== 0) {
                                              removeAppliedFilter();
                                              setApplyFilter(!applyFilter);
                                            } else if (applyFilter) {
                                              removeAppliedFilter();
                                              setApplyFilter(!applyFilter);
                                            }
                                          }}
                                        />
                                      </span>
                                    </HtmlTooltip>
                                    {/* :
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography variant="h5">
                                Filters disabled due to active search field
                              </Typography>
                            </React.Fragment>
                          }>
                          <img src={filterIcon} alt="triangle" />
                        </HtmlTooltip>
                      } */}
                                  </TableCell>
                                ) : (
                                  <React.Fragment>
                                    {applyFilter && (
                                      <TableCell
                                        className="tableCellBorder"
                                        id="iss_tablecell-tableCellBorder"
                                      >
                                        {/* {!selectedOption ? */}
                                        <HtmlTooltip
                                          id="iss_htmltooltip_nodata"
                                          title={
                                            <React.Fragment>
                                              <Typography
                                                variant="h5"
                                                id="iss_typo_applyFilter_tooltip"
                                              >
                                                {applyFilter !== true
                                                  ? "Add Filters"
                                                  : "Remove Filters"}
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        >
                                          <span
                                            className="filterIcon"
                                            id="iss_span-filterIcon"
                                          >
                                            <img
                                              src={filterIcon}
                                              alt="triangle"
                                              id="iss_img_applyFilter2"
                                              onClick={() => {
                                                if (applyFilter) {
                                                  setData(tempData);
                                                  sessionStorage.setItem(
                                                    "search",
                                                    "false"
                                                  );
                                                }
                                                if (data.length !== 0) {
                                                  removeAppliedFilter();
                                                  setApplyFilter(!applyFilter);
                                                } else if (applyFilter) {
                                                  removeAppliedFilter();
                                                  setApplyFilter(!applyFilter);
                                                }
                                              }}
                                            />
                                          </span>
                                        </HtmlTooltip>
                                      </TableCell>
                                    )}
                                  </React.Fragment>
                                )}
                                {columns.map((column) => {
                                  let flag = false;
                                  if (
                                    user !== "Clearstream" &&
                                    (column.id === "issuer" ||
                                      column.id === "internalStatus")
                                  ) {
                                    //checking user for hidding issuerName column to issuers
                                    flag = true;
                                  }
                                  return (
                                    <TableCell
                                      className="cellBorder"
                                      key={column.key}
                                      hidden={flag}
                                      id={`iss_tableCell_sortHandler_${column.id}`}
                                    >
                                      <TableSortLabel
                                        style={{
                                          width: column.width,
                                          paddingLeft: "12px",
                                        }}
                                        active={orderBy === column.id}
                                        direction={
                                          orderBy === column.id ? order : "asc"
                                        }
                                        onClick={createSortHandler(column.id)}
                                        id={`iss_tableSortLabel_sortHandler_${column.id}`}
                                      >
                                        <Typography
                                          variant="h5"
                                          className="lH-17"
                                          id={`iss_typo_sorting_${column.id}`}
                                        >
                                          {column.label}
                                          {orderBy === column.id ? (
                                            <span className="visuallyhidden">
                                              {order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                            </span>
                                          ) : null}
                                        </Typography>
                                      </TableSortLabel>
                                      {applyFilter &&
                                        (column.id !== "isin" ? (
                                          column.type === "date" ? (
                                            <DatePicker
                                              showNow={false}
                                              format={DATE_FORMATS.DATE}
                                              id={`iss_date_filter_${column.id}`}
                                              placeholder={DATE_FORMATS.DATE}
                                              onChange={(date, dateString) => {
                                                let optionSelected =
                                                  selectedColumnOption;
                                                optionSelected[column.id] =
                                                  date;
                                                setSelectedColumnOption(
                                                  optionSelected
                                                );
                                                newRequestSearch({
                                                  target: {
                                                    id: column.id,
                                                    value:
                                                      date !== null
                                                        ? dateString
                                                        : "",
                                                  },
                                                });
                                              }}
                                              suffixIcon={
                                                <img
                                                  src={calendarIcon}
                                                  alt="calendar"
                                                  className="iconColumn"
                                                />
                                              }
                                              className="datePickerColumn"
                                              value={
                                                selectedColumnOption[column.id]
                                              }
                                            />
                                          ) : (
                                            <MultiSelect
                                              style={{ overflow: "visible" }}
                                              className="selectColumn"
                                              options={optionsArray[column.id]}
                                              id={`iss_multiselect_column_${column.id}`}
                                              onChange={(option) => {
                                                let arr = [];
                                                option.map((elem) =>
                                                  arr.push(elem.value)
                                                );
                                                let optionSelected =
                                                  selectedColumnOption;
                                                optionSelected[column.id] =
                                                  option;
                                                setSelectedColumnOption(
                                                  optionSelected
                                                );
                                                newRequestSearch({
                                                  target: {
                                                    id: column.id,
                                                    value: arr,
                                                  },
                                                });
                                              }}
                                              value={
                                                selectedColumnOption[column.id]
                                              }
                                            />
                                          )
                                        ) : (
                                          <TextField
                                            className="searchbarColumn"
                                            fullWidth
                                            placeholder="Enter"
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            onChange={(event) => {
                                              let optionSelected =
                                                selectedColumnOption;
                                              optionSelected[column.id] =
                                                event.target.value;
                                              setSelectedColumnOption(
                                                optionSelected
                                              );
                                              newRequestSearch(event);
                                            }}
                                            id={column.id}
                                            value={
                                              selectedColumnOption[column.id]
                                            }
                                          ></TextField>
                                        ))}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.length === 0 ? (
                                <TableRow id="iss_tablerow_nodata">
                                  <TableCell
                                    colSpan={11}
                                    align="center"
                                    className="nodataicon"
                                    id="iss_tableCell_nodataIcon"
                                  >
                                    <div>
                                      <img
                                        src={nodataIcon}
                                        alt="nodata"
                                        className="noiconColumn"
                                        id="iss_icon_nodataIcon"
                                      />
                                      <Typography
                                        data-testid="noDataToDisplay"
                                        id="iss_typo_nodataIcon"
                                      >
                                        No data available to display
                                      </Typography>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <React.Fragment>
                                  {loadingTableFlag ? (
                                    <React.Fragment>
                                      {[...Array(10)].map(() => (
                                        <TableRow>
                                          <TableCell></TableCell>
                                          <TableCell className="tableLoader">
                                            <span className="largeBlock"></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span className="smallBlock"></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span className="smallBlock"></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span
                                              span
                                              className="largeBlock"
                                            ></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span className="smallBlock"></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span
                                              span
                                              className="largeBlock"
                                            ></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span
                                              span
                                              className="largeBlock"
                                            ></span>
                                          </TableCell>
                                          {user === "Clearstream" && (
                                            <TableCell className="tableLoader">
                                              <span
                                                span
                                                className="largeBlock"
                                              ></span>
                                            </TableCell>
                                          )}
                                          <TableCell className="tableLoader">
                                            <span className="smallBlock"></span>
                                          </TableCell>
                                          <TableCell className="tableLoader">
                                            <span className="smallBlock"></span>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {stableSort(
                                        data,
                                        getComparator(order, orderBy)
                                      )
                                        .slice(0, pageSize)
                                        .map((row, index) => {
                                          const isItemSelected =
                                            isSelected(index);
                                          //updating statusClass according to the status for styling
                                          let statusClass = "";
                                          if (
                                            row.overallStatus === "Initiated"
                                          ) {
                                            statusClass = "initiatedStatus";
                                          } else if (
                                            row.overallStatus ===
                                            "LegallyExisting"
                                          ) {
                                            statusClass =
                                              "legallyExistingStatus";
                                          } else if (
                                            row.overallStatus === "Live"
                                          ) {
                                            statusClass = "liveStatus";
                                          } else if (
                                            row.overallStatus === "Dead"
                                          ) {
                                            statusClass = "deadStatus";
                                          } else {
                                            statusClass = "deactivatedStatus";
                                          }

                                          return (
                                            <TableRow
                                              data-testid="issuanceDetails"
                                              id={`iss_tableRow_showIssuanceDetails_${index}`}
                                              hover
                                              onClick={(event) =>
                                                handleClick(event, index, row)
                                              }
                                              onDoubleClick={(event) => {
                                                showIssuanceDetails(
                                                  row,
                                                  "details"
                                                );
                                              }}
                                              role="checkbox"
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              key={row.key}
                                              selected={isItemSelected}
                                            >
                                              <TableCell></TableCell>

                                              <TableCell
                                                className="padding1"
                                                id={`iss_tableCell_status_${row.key}`}
                                                data-testid="overallStatus"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className={statusClass} // value of statusClass is used as className
                                                  id={`iss_typo_status_${row.key}`}
                                                >
                                                  {row.overallStatus ===
                                                  "LegallyExisting"
                                                    ? "Legally Existing"
                                                    : row.overallStatus}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_valueDate_${row.key}`}
                                                data-testid="valueDate"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_valueDate_${row.key}`}
                                                >
                                                  {row.valueDate}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_payDate_${row.key}`}
                                                data-testid="payDate"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_payDate_${row.key}`}
                                                >
                                                  {row.payDate}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_instrumentType_${row.key}`}
                                                data-testid="instrumentType"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_instrumentType_${row.key}`}
                                                >
                                                  {row.templateType}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_cbfAccount_${row.key}`}
                                                data-testid="issuerAgentAccountNo"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_cbfAccount_${row.key}`}
                                                >
                                                  {row.issuerAgentAccountNo}
                                                </Typography>
                                              </TableCell>
                                              {user === "Clearstream" && (
                                                <TableCell
                                                  className="padding2"
                                                  id={`iss_tableCell_issuer_${row.key}`}
                                                  data-testid="issuer"
                                                >
                                                  <Typography
                                                    variant="subtitle1"
                                                    className="lH-17"
                                                    id={`iss_typo_issuer_${row.key}`}
                                                  >
                                                    {row.issuer}
                                                  </Typography>
                                                </TableCell>
                                              )}
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_isin_${row.key}`}
                                                data-testid="isin"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_isin_${row.key}`}
                                                >
                                                  {row.isin}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_lifecycleStatus_${row.key}`}
                                                data-testid="processingStatus"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_lifecycleStatus_${row.key}`}
                                                >
                                                  {row.processingStatus}
                                                </Typography>
                                              </TableCell>
                                              {user === "Clearstream" && (
                                                <TableCell
                                                  className="padding2"
                                                  id={`iss_tableCell_internalStatus_${row.key}`}
                                                  data-testid="internalStatus"
                                                >
                                                  <Typography
                                                    variant="subtitle1"
                                                    className="lH-17"
                                                    id={`iss_typo_internalStatus_${row.key}`}
                                                  >
                                                    {row.internalStatus}
                                                  </Typography>
                                                </TableCell>
                                              )}
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_payingAgent_${row.key}`}
                                                data-testid="payingAgent"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_payingAgent_${row.key}`}
                                                >
                                                  {row.payingAgent}
                                                </Typography>
                                              </TableCell>
                                              <TableCell
                                                className="padding2"
                                                id={`iss_tableCell_statusSince_${row.key}`}
                                                data-testid="statusSince"
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  className="lH-17"
                                                  id={`iss_typo_statusSince_${row.key}`}
                                                >
                                                  {row.statusSince}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div style={{ paddingTop: 22 }}>
                          <Grid
                            item
                            xs={12}
                            style={{ paddingTop: "7px" }}
                            id="iss_grid_issuanceCount"
                          >
                            {itemsCount !== 1
                              ? `${itemsCount} records`
                              : `${itemsCount} record`}
                          </Grid>
                        </div>
                        {itemsCount !== 0 && (
                          <PaginationContainer
                            itemsCount={itemsCount}
                            onPageChange={onPageChange}
                            resetPage={triggerResetPage}
                            pageList={ISSUANCE_PAGE_LIST}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </StyledTableContainer>
              </React.Fragment>
            </TabPanel>
          </div>
        </StyledContainer>
      )}

      <UploadDialog
        uploadTitle={uploadTitle}
        onSubmit={uploadFile}
        open={openUploadDialog}
        close={() => setOpenUploadDialog(false)}
      />
      <CreateIssuance
        action={createActionTitle}
        setflagPopUp={(e) => setflagPopUp(e)}
        open={createIssuance}
        onClose={() => setCreateIssuance(false)}
        setResponseText={(e) => setResponseText(e)}
        showMessagePopUp={(e) => setShowPopUp(e)}
        setValidationError={(e) => setValidationErrors(e)}
        showValidationErrors={(e) => setShowValidationErrors(e)}
        setValidationMessage={(e) => setValidationMessage(e)}
      />
      <ValidationErrorPopup
        open={showValidationErrors}
        validationErrors={validationErrors}
        onClose={() => setShowValidationErrors(false)}
        validationMessage={validationMessage}
      />
    </React.Fragment>
  );
}
export default Issuances;
