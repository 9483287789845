import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { ViewDetailsContainer } from "../index.css";
import viewDetailsGreyIcon from "../../../assets/inactiveActionIcons/View details-Grey.svg";
import viewDetailsActiveIcon from "../../../assets/deselectedActionIcons/View Details-Outline.svg";
import { useHistory } from "react-router-dom";
import { PendingApiClient } from "../../../api";
import { getInternalStatusLabel } from "../../../utils/StatusUtils";
import moment from "moment-timezone";
import RecordsTable from "./../../ComplianceList/RecordsTable";
import {
  TemplateTypeList,
  getInstrumentTypeLabel,
} from "../../Template/Template";
import { DATE_FORMATS } from "../../../constants";
import { timer } from "../../../utils/dateUtils";
/*
 * This array is for table column headers
 */
const columns = [
  { id: "status", label: "Status" },
  { id: "valueDate", label: "Value Date" },
  { id: "isin", label: "ISIN" },
  { id: "issuerName", label: "Issuer Name" },
  { id: "instrumentType", label: "Instrument Type" },
  { id: "timer", label: "Timer" },
];

/*
 * Below represents the styling of Tooltip used for icons
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function IssuanceBlotter(props) {
  const history = useHistory();
  const storedItem = JSON.parse(sessionStorage.getItem("pending"));
  //the below variable first check if any row was selected before and tab was "issuanceBlotter", else store blank array
  const [selected, setSelected] = useState(
    storedItem?.tabName === "issuanceBlotter" && storedItem?.array.length === 1
      ? storedItem.array
      : []
  );
  //the below variable first check if any isin was stored before and tab was "issuanceBlotter", else store blank string
  const [selectedIsin, setSelectedIsin] = useState(
    storedItem?.tabName === "issuanceBlotter" && storedItem?.isin
      ? storedItem.isin
      : ""
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [itemsCount, setItemsCount] = useState(0);

  useEffect(() => {
    getIssuanceBlotterData();
  }, []);

  /*
   * This method will fetch all pending issuances under issuance blotter
   */
  const getIssuanceBlotterData = (page = 1, pagesize = 100) => {
    const paginationParam = { page, pagesize };
    PendingApiClient.getPendingIssuancesOfTypeIssuanceBlotter(paginationParam)
      .then(({ response }) => {
        let data = [];
        const res = Array.isArray(response.data)
          ? response.data
          : response.data?.items && Array.isArray(response.data?.items)
          ? response.data?.items
          : [];
        for (let i = 0; i < res.length; i++) {
          let temp = res[i];
          //obj defines single row's data
          let obj = {
            instrumentType:
              temp.instrumentType || temp.instrumentType !== null
                ? getInstrumentTypeLabel(temp.instrumentType)
                : "",
            isin: temp.isin || temp.isin !== null ? temp.isin : "",
            issuerName:
              temp.issuerName || temp.issuerName !== null
                ? temp.issuerName
                : "",
            timer:
              temp.pendingSince || temp.pendingSince !== null
                ? timer(temp.pendingSince)
                : "",
            status:
              temp.processStatus || temp.processStatus !== null
                ? getInternalStatusLabel(temp.processStatus)
                : "",
            valueDate:
              temp.valueDate || temp.valueDate !== null
                ? moment(temp.valueDate).format(DATE_FORMATS.DATE)
                : "",
          };
          data.push(obj);
        }
        setItemsCount(response.data.count);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  /*
   * This method handles the selection of row
   */
  const handleClick = (row, index) => {
    const selectedIndex = index;
    let newSelected = [selectedIndex];
    if (selected[0] === selectedIndex) {
      setSelected([]);
    } else {
      setSelected(newSelected);
      setSelectedIsin(data[selectedIndex].isin); //storing isin of selected issaunce for navigation to Issuance Details
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1; // this method checks for the index and returns true or false for selected row

  const onPageChange = (page, pagesize) => {
    const newNumOfPages = Math.ceil(itemsCount / pagesize);
    const newPage = page > newNumOfPages ? newNumOfPages : page;

    getIssuanceBlotterData(newPage, pagesize);
  };

  /*
   * Navigating to issuance details page
   */
  const openDetailsPage = (isin) => {
    let pendingObj = {
      tabName: "issuanceBlotter",
      array: selected,
      isin: isin,
    };
    sessionStorage.setItem("pending", JSON.stringify(pendingObj));
    history.push({
      pathname: "/issuanceDetails",
      state: {
        //object passed as props to issuance details component
        isin: isin,
        tabName: "blotter",
      },
    });
  };

  return (
    <React.Fragment>
      <ViewDetailsContainer>
        <Grid container>
          <Grid item xs={12} align="end">
            {selected.length !== 1 ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="issBlotter_toolTip" variant="h5">
                      View Details
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={viewDetailsGreyIcon}
                  alt="details"
                  id="issBlotter_viewDetailsGreyIcon"
                  className="viewDetailsGreyIcon"
                />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="issBlotter_htmlToolTip" variant="h5">
                      View Details{" "}
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={viewDetailsActiveIcon}
                  alt="details"
                  id="issBlotter_viewDetailsActiveIcon"
                  data-testid="imgViewDetails"
                  className="viewDetailsActiveIcon"
                  onClick={() => openDetailsPage(selectedIsin)}
                />
              </HtmlTooltip>
            )}
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      <RecordsTable
        data={data}
        loading={loading}
        columns={columns}
        onClick={(row, index) => handleClick(row, index)}
        onRowClick={(row, index) => {
          openDetailsPage(row.isin);
        }}
        onPageChange={onPageChange}
        itemsCount={itemsCount}
        isSelected={isSelected}
      />
    </React.Fragment>
  );
}

export default IssuanceBlotter;
