/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MarkUpDown model module.
 * @module model/MarkUpDown
 * @version 1.0.3
 */
class MarkUpDown {
    /**
     * Constructs a new <code>MarkUpDown</code>.
     * @alias module:model/MarkUpDown
     */
    constructor() { 
        
        MarkUpDown.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MarkUpDown</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MarkUpDown} obj Optional instance to populate.
     * @return {module:model/MarkUpDown} The populated <code>MarkUpDown</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MarkUpDown();

            if (data.hasOwnProperty('MarkUpIndicator')) {
                obj['MarkUpIndicator'] = ApiClient.convertToType(data['MarkUpIndicator'], 'Boolean');
            }
            if (data.hasOwnProperty('Quantity')) {
                obj['Quantity'] = ApiClient.convertToType(data['Quantity'], 'Number');
            }
            if (data.hasOwnProperty('ValueDate')) {
                obj['ValueDate'] = ApiClient.convertToType(data['ValueDate'], 'Date');
            }
            if (data.hasOwnProperty('SafeKeepingAccountNo')) {
                obj['SafeKeepingAccountNo'] = ApiClient.convertToType(data['SafeKeepingAccountNo'], 'String');
            }
            if (data.hasOwnProperty('PlaceOfSettlement')) {
                obj['PlaceOfSettlement'] = ApiClient.convertToType(data['PlaceOfSettlement'], 'String');
            }
            if (data.hasOwnProperty('ClientInstructionReference')) {
                obj['ClientInstructionReference'] = ApiClient.convertToType(data['ClientInstructionReference'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MarkUpDown</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MarkUpDown</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['SafeKeepingAccountNo'] && !(typeof data['SafeKeepingAccountNo'] === 'string' || data['SafeKeepingAccountNo'] instanceof String)) {
            throw new Error("Expected the field `SafeKeepingAccountNo` to be a primitive type in the JSON string but got " + data['SafeKeepingAccountNo']);
        }
        // ensure the json data is a string
        if (data['PlaceOfSettlement'] && !(typeof data['PlaceOfSettlement'] === 'string' || data['PlaceOfSettlement'] instanceof String)) {
            throw new Error("Expected the field `PlaceOfSettlement` to be a primitive type in the JSON string but got " + data['PlaceOfSettlement']);
        }
        // ensure the json data is a string
        if (data['ClientInstructionReference'] && !(typeof data['ClientInstructionReference'] === 'string' || data['ClientInstructionReference'] instanceof String)) {
            throw new Error("Expected the field `ClientInstructionReference` to be a primitive type in the JSON string but got " + data['ClientInstructionReference']);
        }

        return true;
    }


}



/**
 * Indicates if marking up or marking down the position of the security[582]
 * @member {Boolean} MarkUpIndicator
 */
MarkUpDown.prototype['MarkUpIndicator'] = undefined;

/**
 * Indicates the quantity by which the outstanding is increase or decrease[583]
 * @member {Number} Quantity
 */
MarkUpDown.prototype['Quantity'] = undefined;

/**
 * The date on which the new quantity is reflected within the securities available position[584]
 * @member {Date} ValueDate
 */
MarkUpDown.prototype['ValueDate'] = undefined;

/**
 * Mantains the position on the Issuers account[585]
 * @member {String} SafeKeepingAccountNo
 */
MarkUpDown.prototype['SafeKeepingAccountNo'] = undefined;

/**
 * Which CSD to be addressed (CBF, CBL, LuxCSD)[586]
 * @member {String} PlaceOfSettlement
 */
MarkUpDown.prototype['PlaceOfSettlement'] = undefined;

/**
 * SWIFT Client Reference[589]
 * @member {String} ClientInstructionReference
 */
MarkUpDown.prototype['ClientInstructionReference'] = undefined;






export default MarkUpDown;

