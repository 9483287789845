// this utility function is fomatting numbers by having till two decimal points
export const formatNumberWithDecimal = (value) => {
  try {
    value = parseFloat(value).toFixed(2);
    return value;
  } catch (error) {
    return value;
  }
};

// this utility function is fomatting numbers by having till two decimal points  and max decimal points
export const formatNumberWithDecimallimits = (value, max) => {
  try {
    if (value && value.toString().includes(".")) {
      let valArray = value.toString().split(".");
      if (valArray[1].length < 2) {
        valArray[1] = valArray[1] + "0";
      } else if (valArray[1].length > max) {
        valArray[1] = valArray[1].substring(0, max).toString();
      }
      return valArray.join(".");
    } else {
      return value;
    }
  } catch (error) {
    return value;
  }
};

// this utility function is fomatting numbers by removing decimals
export const formatNumberWithoutDecimal = (value) => {
  try {
    if (value && value.toString().includes(".")) {
      let valArray = value.split(".");
      return valArray[0];
    } else {
      return value;
    }
  } catch (error) {
    return value;
  }
};

// this utility function is fomatting numbers by adding comma before every three digit sets
export const addSeparator = (value) => {
  try {
    if (value) {
      let valArray = value.toString().split(".");
      valArray[0] = valArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return valArray.join(".");
    } else {
      return "";
    }
  } catch (error) {
    return value;
  }
};

export const isDCM = (
  instrumentStructureCategory,
  instrumentCategory,
  eusipaID
) => {
  if (eusipaID) {
    return false;
  } else {
    if (instrumentStructureCategory === "Commercial papers") {
      return true;
    } else if (
      instrumentCategory === "Medium-term notes" ||
      instrumentCategory === "Money market instruments" ||
      instrumentCategory === "Bonds"
    ) {
      return true;
    } else {
      return true;
    }
  }
};
