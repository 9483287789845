import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import { StyledDialog } from "./index.css";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import uploadSuccessIcon from "../../assets/UploadSuccess.svg";
import uploadFailureIcon from "../../assets/UploadFailure.svg";

const UploadDialog = ({ open, close, uploadTitle, onSubmit }) => {
  const [uploadedfiles, setuploadedfiles] = useState([]);
  const [show, setShow] = useState(false);
  const [flag, setflag] = useState("");
  const [selectedFileData, setSelectedFileData] = useState("");

  const handleClose = () => {
    setuploadedfiles([]);
    setShow(false);
    setflag("");
    close();
  };
  const uploadCsv = () => {
    onSubmit(uploadedfiles);
    handleClose();
  };
  const filePathSet = (event) => {
    event.preventDefault();
    let fileData = event.target.files;
    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length
      );
      let obj = {
        fileName: fileData[x].name,
        file: fileData[x],
      };
      let files = uploadedfiles;
      files.push(obj);
      setuploadedfiles(files);
      if (fileExtension.toLowerCase() === "xml") {
        setflag("success");
      } else {
        setflag("error");
      }
      setShow(true);
    }
    setSelectedFileData({ fileData }); //storing XML file data
  };

  return (
    <StyledDialog
      data-testid="file-dialog"
      className="uploadDRP"
      id="iss_styleDialog_uploadDrp"
      open={open}
      fullWidth={true}
      maxWidth={"xs"}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <Typography
        variant="h5"
        className="uploadHeading"
        data-testid="drpFileDialogTitle"
        id="iss_typo_drpFileDialogTitle"
      >
        {uploadTitle}
      </Typography>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant="h5"
            className="selectXML"
            id="iss_typo_selectXML"
          >
            Please select a XML file.
          </Typography>
        </DialogContentText>
        <Grid container className="paddingXML" id="iss_grid_paddingXML_insert">
          <Grid item xs={1} id="iss_grid_xs1-insert">
            <InsertDriveFileOutlinedIcon style={{ marginTop: "14px" }} />
          </Grid>
          <Grid
            item
            container
            xs={7}
            className="chooseUnderline"
            id="iss_grid_chooseUnderline"
          >
            <Grid item xs={11} id="iss_grid_xs11-fileUpload">
              <div
                className="textFormat"
                id="iss_grid_fileUploadtext"
                data-testid="fileUploadtext"
              >
                {uploadedfiles.length !== 0
                  ? selectedFileData.fileData[0]?.name
                  : "No file choosen"}
              </div>
            </Grid>
            <Grid item xs={1} id="iss_grid_xs1-uploadDrp">
              <span className="float">
                {show && (
                  <>
                    {flag === "error" && (
                      <img
                        src={uploadFailureIcon}
                        data-testid="uploadErrorIcon"
                        id="iss_img_uploadErrorIcon"
                        alt="uploadDrp"
                      />
                    )}
                    {flag === "success" && (
                      <img
                        src={uploadSuccessIcon}
                        data-testid="uploadSuccessIcon"
                        id="iss_img_uploadSuccessIcon"
                        alt="uploadDrp"
                      />
                    )}
                  </>
                )}
              </span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            className="paddingFileUpload"
            id="iss_grid_paddingFileUpload"
            align="end"
          >
            <label htmlFor="fileUpload" id="iss_label_fileupload">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                id="iss_button_chooseFile"
              >
                Choose File
              </Button>
            </label>
            <input
              className="display"
              type="file"
              data-testid="uploadFile"
              id="fileUpload"
              onChange={filePathSet.bind(this)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </Grid>
        </Grid>
        <Grid container id="iss_grid_conatiner_error">
          <Grid item xs={1}></Grid>
          <Grid item xs={10} id="iss_grid_xs10_error">
            {" "}
            {show && flag === "error" && (
              <Typography
                variant="subtitle1"
                className="errorFormat"
                id="iss_typo_errorFormat"
              >
                Error: File type is not supported. Please select another file
                and try again.
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="paddingCancel" id="iss_dialogActions_cancel">
        <Button
          onClick={() => {
            handleClose();
          }}
          className="colorCancel"
          id="iss_button_colorcancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="marginSubmit"
          data-testid="fileSubmit"
          id="iss_button_fileSubmit"
          disabled={uploadedfiles.length === 0 || flag === "error"}
          onClick={() => uploadCsv()}
        >
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default UploadDialog;
