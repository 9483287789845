/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Allowance from './Allowance';
import Balance from './Balance';

/**
 * The Account model module.
 * @module model/Account
 * @version 1.0.3
 */
class Account {
    /**
     * Constructs a new <code>Account</code>.
     * @alias module:model/Account
     */
    constructor() { 
        
        Account.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Account</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Account} obj Optional instance to populate.
     * @return {module:model/Account} The populated <code>Account</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Account();

            if (data.hasOwnProperty('Balance')) {
                obj['Balance'] = ApiClient.convertToType(data['Balance'], [Balance]);
            }
            if (data.hasOwnProperty('Allowance')) {
                obj['Allowance'] = ApiClient.convertToType(data['Allowance'], [Allowance]);
            }
            if (data.hasOwnProperty('OwnershipType')) {
                obj['OwnershipType'] = ApiClient.convertToType(data['OwnershipType'], 'String');
            }
            if (data.hasOwnProperty('BcAddress')) {
                obj['BcAddress'] = ApiClient.convertToType(data['BcAddress'], 'String');
            }
            if (data.hasOwnProperty('Identifier')) {
                obj['Identifier'] = ApiClient.convertToType(data['Identifier'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Account</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Account</code>.
     */
    static validateJSON(data) {
        if (data['Balance']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Balance'])) {
                throw new Error("Expected the field `Balance` to be an array in the JSON data but got " + data['Balance']);
            }
            // validate the optional field `Balance` (array)
            for (const item of data['Balance']) {
                Balance.validateJsonObject(item);
            };
        }
        if (data['Allowance']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Allowance'])) {
                throw new Error("Expected the field `Allowance` to be an array in the JSON data but got " + data['Allowance']);
            }
            // validate the optional field `Allowance` (array)
            for (const item of data['Allowance']) {
                Allowance.validateJsonObject(item);
            };
        }
        // ensure the json data is a string
        if (data['OwnershipType'] && !(typeof data['OwnershipType'] === 'string' || data['OwnershipType'] instanceof String)) {
            throw new Error("Expected the field `OwnershipType` to be a primitive type in the JSON string but got " + data['OwnershipType']);
        }
        // ensure the json data is a string
        if (data['BcAddress'] && !(typeof data['BcAddress'] === 'string' || data['BcAddress'] instanceof String)) {
            throw new Error("Expected the field `BcAddress` to be a primitive type in the JSON string but got " + data['BcAddress']);
        }
        // ensure the json data is a string
        if (data['Identifier'] && !(typeof data['Identifier'] === 'string' || data['Identifier'] instanceof String)) {
            throw new Error("Expected the field `Identifier` to be a primitive type in the JSON string but got " + data['Identifier']);
        }

        return true;
    }


}



/**
 * Model: Balance
 * @member {Array.<module:model/Balance>} Balance
 */
Account.prototype['Balance'] = undefined;

/**
 * Model: Allowance
 * @member {Array.<module:model/Allowance>} Allowance
 */
Account.prototype['Allowance'] = undefined;

/**
 * Specifies the type of account ownership, e.g., SIGL (SingleOwnerAccount) or CUST (Custodial Account)[564]
 * @member {String} OwnershipType
 */
Account.prototype['OwnershipType'] = undefined;

/**
 * Blockchain address of account[28]
 * @member {String} BcAddress
 */
Account.prototype['BcAddress'] = undefined;

/**
 * Identifier for Holder[29]
 * @member {String} Identifier
 */
Account.prototype['Identifier'] = undefined;






export default Account;

