/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ChangesetItem model module.
 * @module model/ChangesetItem
 * @version 1.0.3
 */
class ChangesetItem {
    /**
     * Constructs a new <code>ChangesetItem</code>.
     * @alias module:model/ChangesetItem
     */
    constructor() { 
        
        ChangesetItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ChangesetItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ChangesetItem} obj Optional instance to populate.
     * @return {module:model/ChangesetItem} The populated <code>ChangesetItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChangesetItem();

            if (data.hasOwnProperty('changeType')) {
                obj['changeType'] = ApiClient.convertToType(data['changeType'], 'String');
            }
            if (data.hasOwnProperty('underlyingIsin')) {
                obj['underlyingIsin'] = ApiClient.convertToType(data['underlyingIsin'], 'String');
            }
            if (data.hasOwnProperty('underlyingName')) {
                obj['underlyingName'] = ApiClient.convertToType(data['underlyingName'], 'String');
            }
            if (data.hasOwnProperty('issuerCountry')) {
                obj['issuerCountry'] = ApiClient.convertToType(data['issuerCountry'], 'String');
            }
            if (data.hasOwnProperty('dateOfEntry')) {
                obj['dateOfEntry'] = ApiClient.convertToType(data['dateOfEntry'], 'String');
            }
            if (data.hasOwnProperty('addedBy')) {
                obj['addedBy'] = ApiClient.convertToType(data['addedBy'], 'String');
            }
            if (data.hasOwnProperty('le')) {
                obj['le'] = ApiClient.convertToType(data['le'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ChangesetItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ChangesetItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['changeType'] && !(typeof data['changeType'] === 'string' || data['changeType'] instanceof String)) {
            throw new Error("Expected the field `changeType` to be a primitive type in the JSON string but got " + data['changeType']);
        }
        // ensure the json data is a string
        if (data['underlyingIsin'] && !(typeof data['underlyingIsin'] === 'string' || data['underlyingIsin'] instanceof String)) {
            throw new Error("Expected the field `underlyingIsin` to be a primitive type in the JSON string but got " + data['underlyingIsin']);
        }
        // ensure the json data is a string
        if (data['underlyingName'] && !(typeof data['underlyingName'] === 'string' || data['underlyingName'] instanceof String)) {
            throw new Error("Expected the field `underlyingName` to be a primitive type in the JSON string but got " + data['underlyingName']);
        }
        // ensure the json data is a string
        if (data['issuerCountry'] && !(typeof data['issuerCountry'] === 'string' || data['issuerCountry'] instanceof String)) {
            throw new Error("Expected the field `issuerCountry` to be a primitive type in the JSON string but got " + data['issuerCountry']);
        }
        // ensure the json data is a string
        if (data['dateOfEntry'] && !(typeof data['dateOfEntry'] === 'string' || data['dateOfEntry'] instanceof String)) {
            throw new Error("Expected the field `dateOfEntry` to be a primitive type in the JSON string but got " + data['dateOfEntry']);
        }
        // ensure the json data is a string
        if (data['addedBy'] && !(typeof data['addedBy'] === 'string' || data['addedBy'] instanceof String)) {
            throw new Error("Expected the field `addedBy` to be a primitive type in the JSON string but got " + data['addedBy']);
        }
        // ensure the json data is a string
        if (data['le'] && !(typeof data['le'] === 'string' || data['le'] instanceof String)) {
            throw new Error("Expected the field `le` to be a primitive type in the JSON string but got " + data['le']);
        }
        // ensure the json data is a string
        if (data['comment'] && !(typeof data['comment'] === 'string' || data['comment'] instanceof String)) {
            throw new Error("Expected the field `comment` to be a primitive type in the JSON string but got " + data['comment']);
        }

        return true;
    }


}



/**
 * Description type of the item type New or Delete
 * @member {String} changeType
 */
ChangesetItem.prototype['changeType'] = undefined;

/**
 * ISIN of the Underlying
 * @member {String} underlyingIsin
 */
ChangesetItem.prototype['underlyingIsin'] = undefined;

/**
 * Name of the Underlying
 * @member {String} underlyingName
 */
ChangesetItem.prototype['underlyingName'] = undefined;

/**
 * Country of the Issuer
 * @member {String} issuerCountry
 */
ChangesetItem.prototype['issuerCountry'] = undefined;

/**
 * Date of Entry, based on ISO 8601 standard
 * @member {String} dateOfEntry
 */
ChangesetItem.prototype['dateOfEntry'] = undefined;

/**
 * Entry Added by (incl. UserID)
 * @member {String} addedBy
 */
ChangesetItem.prototype['addedBy'] = undefined;

/**
 * Legal entity
 * @member {String} le
 */
ChangesetItem.prototype['le'] = undefined;

/**
 * Additional comments
 * @member {String} comment
 */
ChangesetItem.prototype['comment'] = undefined;






export default ChangesetItem;

