/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProductRating model module.
 * @module model/ProductRating
 * @version 1.0.3
 */
class ProductRating {
    /**
     * Constructs a new <code>ProductRating</code>.
     * @alias module:model/ProductRating
     */
    constructor() { 
        
        ProductRating.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductRating</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductRating} obj Optional instance to populate.
     * @return {module:model/ProductRating} The populated <code>ProductRating</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductRating();

            if (data.hasOwnProperty('Agency')) {
                obj['Agency'] = ApiClient.convertToType(data['Agency'], 'String');
            }
            if (data.hasOwnProperty('Outlook')) {
                obj['Outlook'] = ApiClient.convertToType(data['Outlook'], 'String');
            }
            if (data.hasOwnProperty('Value')) {
                obj['Value'] = ApiClient.convertToType(data['Value'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProductRating</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProductRating</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Agency'] && !(typeof data['Agency'] === 'string' || data['Agency'] instanceof String)) {
            throw new Error("Expected the field `Agency` to be a primitive type in the JSON string but got " + data['Agency']);
        }
        // ensure the json data is a string
        if (data['Outlook'] && !(typeof data['Outlook'] === 'string' || data['Outlook'] instanceof String)) {
            throw new Error("Expected the field `Outlook` to be a primitive type in the JSON string but got " + data['Outlook']);
        }
        // ensure the json data is a string
        if (data['Value'] && !(typeof data['Value'] === 'string' || data['Value'] instanceof String)) {
            throw new Error("Expected the field `Value` to be a primitive type in the JSON string but got " + data['Value']);
        }

        return true;
    }


}



/**
 * The agency providing the Issue/Product rating. Mandatory for issuance with an Issue/Product rating.[236]
 * @member {String} Agency
 */
ProductRating.prototype['Agency'] = undefined;

/**
 * The outlook for the rating provided by the agency providing the Issue/Product rating. Mandatory for issuance with an Issue/Product rating.[237]
 * @member {String} Outlook
 */
ProductRating.prototype['Outlook'] = undefined;

/**
 * The instrument rating provided by the rating agency.[275]
 * @member {String} Value
 */
ProductRating.prototype['Value'] = undefined;






export default ProductRating;

