import React, { useEffect, useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TextField,
  TableHead,
  TableRow,
  Grid,
  Button,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import {
  StyledSubSectionContainer,
  StyledOffSubSectionContainer,
  StyledSubSectionGrid,
} from "../index.css";
import viewDetailsGreyIcon from "../../../assets/inactiveActionIcons/View details-Grey.svg";
import viewDetailsActiveIcon from "../../../assets/deselectedActionIcons/View Details-Outline.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import filterIcon from "../../../assets/FilterIcon.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SuccessPopup from "../../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../../components/ErrorSuccessPopUp/ErrorPopup";
import XMLUpload from "./XMLUpload";
import moment from "moment-timezone";
import { DatePicker, Space } from "antd";
import calendarIcon from "../../../assets/calendar-icon.svg";
import { MultiSelect } from "react-multi-select-component";
import { useUserStore } from "../../../store/Store";
import { PendingApiClient } from "../../../api";
import PaginationContainer from "../../../components/Paginator";
import { getInternalStatusLabel } from "../../../utils/StatusUtils";
import NoData from "../../../components/NoData/NoData";
import { getInstrumentTypeLabel } from "../../Template/Template";
import { DATE_FORMATS } from "../../../constants";
import { getComparator, stableSort } from "../../../components/Sorting";
/*
 * This array is for table column headers
 */
const columns = [
  { id: "internalStatus", label: "Internal Status", width: "160px" },
  { id: "payDate", label: "Pay Date", type: "Date", width: "120px" },
  { id: "isin", label: "ISIN", width: "120px" },
  { id: "issuerName", label: "Issuer Name", width: "120px" },
  { id: "instrumentType", label: "Instrument Type", width: "200px" },
  { id: "errorMessage", label: "Error Message" },
];

/*
 * Below represents the styling of Tooltip used for icons
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

/*
 * The below two objects are being used in filter
 */
const defaultOptions = {
  internalStatus: [],
  payDate: [],
  isin: [],
  issuerName: [],
  instrumentType: [],
  errorMessage: [],
};
const searchList = {
  internalStatus: "",
  payDate: "",
  isin: "",
  issuerName: "",
  instrumentType: "",
  errorMessage: "",
};

function LifecycleManagement(props) {
  const history = useHistory();
  const [userState] = useUserStore();
  const storedItem = JSON.parse(sessionStorage.getItem("pending"));
  //the below variable first check if any row was selected before and tab was "lifecycleManagement", else store blank array
  const [selected, setSelected] = useState(
    storedItem?.tabName === "lifecycleManagement" &&
      storedItem?.array.length === 1
      ? storedItem.array
      : []
  );
  //the below variable first check if any isin was stored before and tab was "issuanceBlotter", else store blank string
  const [selectedIsin, setSelectedIsin] = useState(
    storedItem?.tabName === "lifecycleManagement" && storedItem?.isin
      ? storedItem.isin
      : ""
  );
  const [order, setOrder] = useState("asc"); //default sorting is set to ascending order
  const [orderBy, setOrderBy] = useState("");
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drpflag, setDrpflag] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);
  const [searchArray, setSearchArray] = useState([]);
  const [optionsArray, setOptionsArray] = useState({});
  const [searchValue, setSearchValue] = useState(searchList);
  const [isRejectedStatusClicked, setIsRejectedStatusClicked] = useState(false);

  const [itemsCount, setItemsCount] = useState(0);

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /*
   * Updating options array for filter search
   */
  const createFilterOptions = (issuances) => {
    let columnNames = issuances.length !== 0 ? Object.keys(issuances[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(issuances, elem);
    });
    setOptionsArray(optionsArrList);
  };

  /*
   * Updating option lists of columns dynamically with available values
   */
  const getOptionList = (issuances, columnName) => {
    let arr = [];
    issuances.map((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
      return arr;
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({ value: elem, label: elem });
      return returnList;
    });
    return returnList;
  };

  useEffect(() => {
    getLifecycleManagementIssuances();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * This method will fetch all pending issuances under lifecycle management
   */
  const getLifecycleManagementIssuances = (page = 1, pagesize = 100) => {
    const paginationParam = { page, pagesize };
    PendingApiClient.getPendingIssuancesOfTypeLifecycleMngmt(paginationParam)
      .then(({ response }) => {
        let data = [];
        for (let i = 0; i < response.data.items?.length; i++) {
          let temp = response.data.items[i];
          //obj defines single row's data
          let obj = {
            payDate:
              temp.payDate || temp.payDate !== null
                ? moment(temp.payDate).format(DATE_FORMATS.DATE)
                : "",
            isin: temp.isin || temp.isin !== null ? temp.isin : "",
            issuerName:
              temp.issuerName || temp.issuerName !== null
                ? temp.issuerName
                : "",
            errorMessage:
              temp.errorMessage || temp.errorMessage !== null
                ? temp.errorMessage
                : "",
            instrumentType:
              temp.instrumentType || temp.instrumentType !== null
                ? getInstrumentTypeLabel(temp.instrumentType)
                : "",
            internalStatus:
              temp.internalStatus || temp.internalStatus !== null
                ? getInternalStatusLabel(temp.internalStatus)
                : "",
          };
          data.push(obj);
        }
        setItemsCount(response.data.count);
        setData(data);
        setTempData(data);
        createFilterOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  /*
   * This method handle the selection of row
   */
  const handleClick = (event, name, status) => {
    const selectedIndex = name;
    let newSelected = [selectedIndex];
    if (selected[0] === selectedIndex) {
      setSelected([]);
      setIsRejectedStatusClicked(false);
    } else {
      setSelected(newSelected);
      //status checked with "DrpPublished" for enabling or disabling buttons
      setIsRejectedStatusClicked(status === "DrpPublished" ? true : false);
      setSelectedIsin(data[selectedIndex].isin);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1; // this method checks for the index and returns true or false for selected row

  const onPageChange = (page, pagesize) => {
    const newNumOfPages = Math.ceil(itemsCount / pagesize);
    const newPage = page > newNumOfPages ? newNumOfPages : page;

    getLifecycleManagementIssuances(newPage, pagesize);
  };

  /*
   * Navigating to issuance details page
   */
  const openDetailsPage = (isin) => {
    let pendingObj = {
      tabName: "lifecycleManagement",
      array: selected,
      isin: isin,
    };
    sessionStorage.setItem("pending", JSON.stringify(pendingObj));
    history.push({
      pathname: "/issuanceDetails",
      state: {
        //object passed as props to issuance details component
        isin: isin,
        tabName: "lifecycle",
      },
    });
  };

  //Added for Future scope
  const onSuccess = (res) => {
    if (res.status === 200) {
      setResponseText(res.data.message);
      setShowPopUp(true);
      setflagPopUp("success");
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 1000);
    } else {
      setResponseText(res.data.message);
      setShowPopUp(true);
      setflagPopUp("error");
    }
  };

  const onFailure = (error) => {
    setResponseText(
      error.response.data.message
        ? error.response.data.message
        : "Unable to upload XML. Please try later."
    );
    setShowPopUp(true);
    setflagPopUp("error");
  };

  /*
   * Filter with multiple column
   */
  const newRequestSearch = (event) => {
    sessionStorage.setItem("search", "true");
    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }
    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue); //Updating searchValue object with column id and search value
    let newData = [...tempData];
    searchArray.forEach((column) => {
      //Iterate each column and check if any filter applied to that column and update the table data with the search
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;
      tempNewData = newData.filter((row) => {
        sessionStorage.setItem("search", "true");
        if (
          column === "statusSince" ||
          column === "payDate" ||
          column === "valueDate"
        ) {
          if (value !== "") {
            return row[column] === value;
          } else {
            return true;
          }
        } else if (column === "isin") {
          return row[column].toLowerCase().includes(value.toLowerCase());
        } else {
          if (value?.length === 0) {
            return true;
          } else {
            return value.includes(row[column]);
          }
        }
      });
      newData = tempNewData;
    });
    setData(newData);
  };

  /*
   * This method will refresh the filters by removing all applied search criteria
   */
  const removeAppliedFilter = () => {
    setSelectedColumnOption({
      internalStatus: [],
      payDate: [],
      isin: [],
      issuerName: [],
      instrumentType: [],
      errorMessage: [],
    });
    setSearchArray([]);
  };

  /*
   * This method will send request for approving selected issuance
   */
  const managePending = () => {
    setSelected([]);
    let jwtToken = sessionStorage.getItem("jwtToken");
    let formData = new FormData();
    formData.append("isin", selectedIsin);
    axios
      .put("di/issuance/manage/confirm", formData, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((res) => {
        setResponseText(res.data.message);
        setShowPopUp(true);
        setflagPopUp("success");
      })
      .catch((error) => {
        setResponseText("Unable to proceed. Please try later.");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  /*
   * Closing success/error popup
   */
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  return (
    <React.Fragment>
      <StyledSubSectionGrid container>
        {responseText && showPopUp && (
          <div id="drp_text_popup" className="popup">
            <div>
              {flagPopUp !== "error" ? (
                <SuccessPopup
                  successPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              ) : (
                <ErrorPopup
                  errorPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              )}
            </div>
          </div>
        )}
        <Grid item xs={12} align="end">
          <Space>
            <Button
              variant="outlined"
              id="drp_onBehalf"
              color="primary"
              className="btnDRPonbehalf"
              onClick={() => {
                setDrpflag(true);
              }}
            >
              {" "}
              <FileUploadIcon /> DRP File on Behalf
            </Button>
            <XMLUpload
              open={drpflag}
              onFailure={onFailure}
              onSuccess={onSuccess}
              onHandleClose={() => {
                setDrpflag(false);
              }}
              title="Upload a DRP File on Behalf of"
            ></XMLUpload>
            <Button
              variant="outlined"
              data-testid="confirmButton"
              id="drp_confirm"
              color="primary"
              onClick={() => managePending()}
              disabled={
                selected.length !== 1 ||
                !isRejectedStatusClicked ||
                !userState.userRoleRight.includes("View_Confirm")
              }
            >
              Confirm
            </Button>
            {selected.length !== 1 ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="drp_viewDetails" variant="h5">
                      View Details
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={viewDetailsGreyIcon}
                  alt="details"
                  id="drp_viewDetailsGreyIcon"
                />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="viewDetails_toolTip" variant="h5">
                      View Details{" "}
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={viewDetailsActiveIcon}
                  alt="details"
                  data-testid="viewDetailsActiveIcon"
                  id="drp_viewDetailsActiveIcon"
                  className="icon2"
                  onClick={() => openDetailsPage(selectedIsin)}
                />
              </HtmlTooltip>
            )}
          </Space>
        </Grid>
      </StyledSubSectionGrid>
      <StyledSubSectionContainer style={{ maxHeight: "auto" }}>
        <Table
          aria-label="sticky table"
          id="drp_tableView"
          stickyHeader
          data-testid="TableView"
        >
          <TableHead>
            <TableRow>
              {data.length !== 0 ? (
                <TableCell className="tableCellBorder">
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography id="drp_applyFilterTooltip" variant="h5">
                          {applyFilter !== true
                            ? "Add Filters"
                            : "Remove Filters"}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span id="drp_filterIcon" className="filterIcon">
                      <img
                        src={filterIcon}
                        id="drp_triangle"
                        data-testid="addFilter_icon"
                        alt="triangle"
                        onClick={() => {
                          if (applyFilter === true) {
                            setData(tempData);
                            sessionStorage.setItem("search", "false");
                          }
                          if (data.length !== 0) {
                            removeAppliedFilter();
                            setApplyFilter(!applyFilter);
                          } else if (applyFilter) {
                            removeAppliedFilter();
                            setApplyFilter(!applyFilter);
                          }
                        }}
                      />
                    </span>
                  </HtmlTooltip>
                </TableCell>
              ) : (
                <React.Fragment>
                  {applyFilter && (
                    <TableCell
                      id="drp_tableCellBorder"
                      className="tableCellBorder"
                    >
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              id="drp_applyFilter_htmlToolTip"
                              data-testid="addFilter_icon"
                              variant="h5"
                            >
                              {applyFilter !== true
                                ? "Add Filters"
                                : "Remove Filters"}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <span id="drp_span_filterIcon" className="filterIcon">
                          <img
                            src={filterIcon}
                            id="drp_img_filterIcon"
                            data-testid="addFilter_icon"
                            alt="triangle"
                            onClick={() => {
                              if (applyFilter === true) {
                                setData(tempData);
                                sessionStorage.setItem("search", "false");
                              }
                              if (data.length !== 0) {
                                removeAppliedFilter();
                                setApplyFilter(!applyFilter);
                              } else if (applyFilter) {
                                removeAppliedFilter();
                                setApplyFilter(!applyFilter);
                              }
                            }}
                          />
                        </span>
                      </HtmlTooltip>
                    </TableCell>
                  )}
                </React.Fragment>
              )}

              {columns.map((column, index) => (
                <TableCell
                  id={`drp_cellBorder_${index}`}
                  className="cellBorder"
                  key={column.key}
                  style={{ minWidth: column.width }}
                >
                  <TableSortLabel
                    style={{
                      width: column.width,
                      paddingLeft: "12px",
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                    id={`iss_tableSortLabel_sortHandler_${column.id}`}
                  >
                    <Typography
                      variant="h5"
                      id={`drp_label_${index}`}
                      className="lH-17"
                    >
                      {column.label}
                    </Typography>
                  </TableSortLabel>
                  {applyFilter &&
                    (column.id !== "isin" ? (
                      column.type === "Date" ? ( //calender input for all date type column search
                        <DatePicker
                          showNow={false}
                          format="YYYY-MM-DD"
                          placeholder="YYYY-MM-DD"
                          onChange={(date, dateString) => {
                            newRequestSearch({
                              target: {
                                id: column.id,
                                value: date !== null ? dateString : "",
                              },
                            });
                          }}
                          suffixIcon={
                            <img
                              src={calendarIcon}
                              alt="calendar"
                              className="iconColumn"
                            />
                          }
                          className="datePickerColumn"
                          id="drp_datePickerColumn"
                        />
                      ) : (
                        <MultiSelect //multiselect dropdown for all other column except dates and isin
                          style={{ overflow: "visible" }}
                          className="selectColumn"
                          id="drp_selectColumn"
                          options={optionsArray[column.id]}
                          onChange={(option) => {
                            let arr = [];
                            option.map((elem) => {
                              arr.push(elem.value);
                              return arr;
                            });
                            let optionSelected = selectedColumnOption;
                            optionSelected[column.id] = option;
                            setSelectedColumnOption(optionSelected);
                            newRequestSearch({
                              target: {
                                id: column.id,
                                value: arr,
                              },
                            });
                          }}
                          value={selectedColumnOption[column.id]}
                        />
                      )
                    ) : (
                      <TextField
                        className="searchbarColumn"
                        data-testid="filter_tempId_text" //textField input for ISIN column search
                        fullWidth
                        placeholder="Enter"
                        InputProps={{ disableUnderline: true }}
                        onChange={(event) => {
                          newRequestSearch(event);
                        }}
                        id={column.id}
                      ></TextField>
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <NoData loading={loading} />
            ) : (
              <>
                {stableSort(data, getComparator(order, orderBy))?.map(
                  (row, index) => {
                    const isItemSelected = isSelected(index);
                    return (
                      <TableRow
                        key={index}
                        data-testid="tableRowData"
                        hover
                        onClick={(event) =>
                          handleClick(event, index, row.internalStatus)
                        }
                        onDoubleClick={(event) => openDetailsPage(row.isin)}
                        role="checkbox"
                        id={`drp_tableRow_${index}`}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        className={`${
                          index % 2 ? "FFFFFFColor" : "F7F7F7Color"
                        }`} //passing className to alternate rows for stylling
                      >
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography
                            id={`drp_overallStatus_${index}`}
                            variant="subtitle1"
                          >
                            {row.internalStatus
                              .replace(
                                /* formatting status by adding space in between words and capitalizing starting letter of every words */
                                /([a-z])([A-Z])/g,
                                "$1 $2"
                              )
                              ?.replace("Drp", "DRP")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            id={`drp_payDate_${index}`}
                            className="lH-17"
                          >
                            {row.payDate}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            id={`drp_isin_${index}`}
                            className="lH-17"
                          >
                            {row.isin}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            id={`drp_issuerName_${index}`}
                            className="lH-17"
                          >
                            {row.issuerName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            id={`drp_instrumentType_${index}`}
                            className="lH-17"
                          >
                            {row.instrumentType.replace(
                              /([a-z])([A-Z])/g,
                              "$1 $2"
                            )}{" "}
                            {/* adding space in between words */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            id={`drp_errorMessage_${index}`}
                            className="lH-17"
                          >
                            {row.errorMessage}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </>
            )}
          </TableBody>
        </Table>
      </StyledSubSectionContainer>
      <StyledOffSubSectionContainer>
        {itemsCount !== 0 && itemsCount !== undefined && (
          <PaginationContainer
            itemsCount={itemsCount}
            onPageChange={onPageChange}
          />
        )}
      </StyledOffSubSectionContainer>
    </React.Fragment>
  );
}

export default LifecycleManagement;
