/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import IssuerData from '../model/IssuerData';

/**
* IssuersQueries service.
* @module api/IssuersQueriesApi
* @version 1.0.3
*/
export default class IssuersQueriesApi {

    /**
    * Constructs a new IssuersQueriesApi. 
    * @alias module:api/IssuersQueriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getIssuersNamesV1 operation.
     * @callback module:api/IssuersQueriesApi~getIssuersNamesV1Callback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuerData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deprecated Api will be replaced with /di/v1/parties/issuers/names
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pagesize number of elements per page
     * @param {module:api/IssuersQueriesApi~getIssuersNamesV1Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuerData}
     */
    getIssuersNamesV1(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuerData;
      return this.apiClient.callApi(
        '/di/v1/issuers/names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
