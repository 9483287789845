/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Quote model module.
 * @module model/Quote
 * @version 1.0.3
 */
class Quote {
    /**
     * Constructs a new <code>Quote</code>.
     * @alias module:model/Quote
     */
    constructor() { 
        
        Quote.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Quote</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Quote} obj Optional instance to populate.
     * @return {module:model/Quote} The populated <code>Quote</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Quote();

            if (data.hasOwnProperty('MinimumOrderCurrency')) {
                obj['MinimumOrderCurrency'] = ApiClient.convertToType(data['MinimumOrderCurrency'], 'Number');
            }
            if (data.hasOwnProperty('MinimumOrderQuantity')) {
                obj['MinimumOrderQuantity'] = ApiClient.convertToType(data['MinimumOrderQuantity'], 'Number');
            }
            if (data.hasOwnProperty('MinimumIncrement')) {
                obj['MinimumIncrement'] = ApiClient.convertToType(data['MinimumIncrement'], 'Number');
            }
            if (data.hasOwnProperty('MaximumSpreadValue')) {
                obj['MaximumSpreadValue'] = ApiClient.convertToType(data['MaximumSpreadValue'], 'Number');
            }
            if (data.hasOwnProperty('MaximumSpreadPercentage')) {
                obj['MaximumSpreadPercentage'] = ApiClient.convertToType(data['MaximumSpreadPercentage'], 'Number');
            }
            if (data.hasOwnProperty('QuoteOriginator')) {
                obj['QuoteOriginator'] = ApiClient.convertToType(data['QuoteOriginator'], 'String');
            }
            if (data.hasOwnProperty('QuotationModel')) {
                obj['QuotationModel'] = ApiClient.convertToType(data['QuotationModel'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Quote</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Quote</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['QuoteOriginator'] && !(typeof data['QuoteOriginator'] === 'string' || data['QuoteOriginator'] instanceof String)) {
            throw new Error("Expected the field `QuoteOriginator` to be a primitive type in the JSON string but got " + data['QuoteOriginator']);
        }
        // ensure the json data is a string
        if (data['QuotationModel'] && !(typeof data['QuotationModel'] === 'string' || data['QuotationModel'] instanceof String)) {
            throw new Error("Expected the field `QuotationModel` to be a primitive type in the JSON string but got " + data['QuotationModel']);
        }

        return true;
    }


}



/**
 * The minimum trade volume expressed in the instruments trading currency.[297]
 * @member {Number} MinimumOrderCurrency
 */
Quote.prototype['MinimumOrderCurrency'] = undefined;

/**
 * The minimum trade volume expressed in the unit amount. Mandatory in either the currency (nominal) or units. Initially only even numbers are permitted.[298]
 * @member {Number} MinimumOrderQuantity
 */
Quote.prototype['MinimumOrderQuantity'] = undefined;

/**
 * The minimum quantity of securities that can be traded. May be different to the minimum amount that has to be transferred[299]
 * @member {Number} MinimumIncrement
 */
Quote.prototype['MinimumIncrement'] = undefined;

/**
 * Indicates the maximum value of the spread[300]
 * @member {Number} MaximumSpreadValue
 */
Quote.prototype['MaximumSpreadValue'] = undefined;

/**
 * Indicates the percentage rate of the maximum Spread[301]
 * @member {Number} MaximumSpreadPercentage
 */
Quote.prototype['MaximumSpreadPercentage'] = undefined;

/**
 * Name of the institution responsible for providing quotes[314]
 * @member {String} QuoteOriginator
 */
Quote.prototype['QuoteOriginator'] = undefined;

/**
 * Indicates whether continous trading takes place or actions are called out[318]
 * @member {String} QuotationModel
 */
Quote.prototype['QuotationModel'] = undefined;






export default Quote;

