/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TerminationRights model module.
 * @module model/TerminationRights
 * @version 1.0.3
 */
class TerminationRights {
    /**
     * Constructs a new <code>TerminationRights</code>.
     * @alias module:model/TerminationRights
     */
    constructor() { 
        
        TerminationRights.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TerminationRights</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TerminationRights} obj Optional instance to populate.
     * @return {module:model/TerminationRights} The populated <code>TerminationRights</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TerminationRights();

            if (data.hasOwnProperty('NoticePeriodDaysType')) {
                obj['NoticePeriodDaysType'] = ApiClient.convertToType(data['NoticePeriodDaysType'], 'String');
            }
            if (data.hasOwnProperty('NoticePeriodMin')) {
                obj['NoticePeriodMin'] = ApiClient.convertToType(data['NoticePeriodMin'], 'Number');
            }
            if (data.hasOwnProperty('ParCallTerm')) {
                obj['ParCallTerm'] = ApiClient.convertToType(data['ParCallTerm'], ['Number']);
            }
            if (data.hasOwnProperty('CallableStructure')) {
                obj['CallableStructure'] = ApiClient.convertToType(data['CallableStructure'], 'String');
            }
            if (data.hasOwnProperty('ExtraordinaryTerminationRight')) {
                obj['ExtraordinaryTerminationRight'] = ApiClient.convertToType(data['ExtraordinaryTerminationRight'], 'Boolean');
            }
            if (data.hasOwnProperty('ObligorTerminationRight')) {
                obj['ObligorTerminationRight'] = ApiClient.convertToType(data['ObligorTerminationRight'], 'Boolean');
            }
            if (data.hasOwnProperty('CreditorTerminationRight')) {
                obj['CreditorTerminationRight'] = ApiClient.convertToType(data['CreditorTerminationRight'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>TerminationRights</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TerminationRights</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['NoticePeriodDaysType'] && !(typeof data['NoticePeriodDaysType'] === 'string' || data['NoticePeriodDaysType'] instanceof String)) {
            throw new Error("Expected the field `NoticePeriodDaysType` to be a primitive type in the JSON string but got " + data['NoticePeriodDaysType']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['ParCallTerm'])) {
            throw new Error("Expected the field `ParCallTerm` to be an array in the JSON data but got " + data['ParCallTerm']);
        }
        // ensure the json data is a string
        if (data['CallableStructure'] && !(typeof data['CallableStructure'] === 'string' || data['CallableStructure'] instanceof String)) {
            throw new Error("Expected the field `CallableStructure` to be a primitive type in the JSON string but got " + data['CallableStructure']);
        }

        return true;
    }


}



/**
 * When an issuer call option is present, this specifies whether the notice period is define in calendar days or business days.[192]
 * @member {String} NoticePeriodDaysType
 */
TerminationRights.prototype['NoticePeriodDaysType'] = undefined;

/**
 * Notice period which the option holder is required to give the counterparty before redemptin in whole business days. Mandatory if an issuer call is applicable.[193]
 * @member {Number} NoticePeriodMin
 */
TerminationRights.prototype['NoticePeriodMin'] = undefined;

/**
 * The period in months before the maturity date within which the Par Call can be exercised by the issuer. Mandatory if a Par Call is specified within redemption.call_features.[196]
 * @member {Array.<Number>} ParCallTerm
 */
TerminationRights.prototype['ParCallTerm'] = undefined;

/**
 * The structure of the callable note, describing the tenor, non,call period and the optional redemption frequency after the initial call if applicable. Mandatory for callable notes.[197]
 * @member {String} CallableStructure
 */
TerminationRights.prototype['CallableStructure'] = undefined;

/**
 * Should an extraordinary termination right exist, then yes[220]
 * @member {Boolean} ExtraordinaryTerminationRight
 */
TerminationRights.prototype['ExtraordinaryTerminationRight'] = undefined;

/**
 * Should the obligor termination right exist, then yes[218]
 * @member {Boolean} ObligorTerminationRight
 */
TerminationRights.prototype['ObligorTerminationRight'] = undefined;

/**
 * Should the creditor termination right exist, then yes[219]
 * @member {Boolean} CreditorTerminationRight
 */
TerminationRights.prototype['CreditorTerminationRight'] = undefined;






export default TerminationRights;

