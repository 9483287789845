/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OtherIdentification model module.
 * @module model/OtherIdentification
 * @version 1.0.3
 */
class OtherIdentification {
    /**
     * Constructs a new <code>OtherIdentification</code>.
     * @alias module:model/OtherIdentification
     */
    constructor() { 
        
        OtherIdentification.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OtherIdentification</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OtherIdentification} obj Optional instance to populate.
     * @return {module:model/OtherIdentification} The populated <code>OtherIdentification</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OtherIdentification();

            if (data.hasOwnProperty('Type')) {
                obj['Type'] = ApiClient.convertToType(data['Type'], 'String');
            }
            if (data.hasOwnProperty('Value')) {
                obj['Value'] = ApiClient.convertToType(data['Value'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OtherIdentification</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OtherIdentification</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Type'] && !(typeof data['Type'] === 'string' || data['Type'] instanceof String)) {
            throw new Error("Expected the field `Type` to be a primitive type in the JSON string but got " + data['Type']);
        }
        // ensure the json data is a string
        if (data['Value'] && !(typeof data['Value'] === 'string' || data['Value'] instanceof String)) {
            throw new Error("Expected the field `Value` to be a primitive type in the JSON string but got " + data['Value']);
        }

        return true;
    }


}



/**
 * Type of Identifier used to represent the instrument across systems and standards[501]
 * @member {String} Type
 */
OtherIdentification.prototype['Type'] = undefined;

/**
 * The Numeric or Alphanumeric ID used of the specific Identification type[502]
 * @member {String} Value
 */
OtherIdentification.prototype['Value'] = undefined;






export default OtherIdentification;

