/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Observation from './Observation';

/**
 * The Underlying model module.
 * @module model/Underlying
 * @version 1.0.3
 */
class Underlying {
    /**
     * Constructs a new <code>Underlying</code>.
     * @alias module:model/Underlying
     */
    constructor() { 
        
        Underlying.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Underlying</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Underlying} obj Optional instance to populate.
     * @return {module:model/Underlying} The populated <code>Underlying</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Underlying();

            if (data.hasOwnProperty('ISIN')) {
                obj['ISIN'] = ApiClient.convertToType(data['ISIN'], 'String');
            }
            if (data.hasOwnProperty('InstrumentLongName')) {
                obj['InstrumentLongName'] = ApiClient.convertToType(data['InstrumentLongName'], 'String');
            }
            if (data.hasOwnProperty('UnderlyingType')) {
                obj['UnderlyingType'] = ApiClient.convertToType(data['UnderlyingType'], 'String');
            }
            if (data.hasOwnProperty('UnderlyingRisk')) {
                obj['UnderlyingRisk'] = ApiClient.convertToType(data['UnderlyingRisk'], 'String');
            }
            if (data.hasOwnProperty('Quanto')) {
                obj['Quanto'] = ApiClient.convertToType(data['Quanto'], 'Boolean');
            }
            if (data.hasOwnProperty('Observation')) {
                obj['Observation'] = ApiClient.convertToType(data['Observation'], [Observation]);
            }
            if (data.hasOwnProperty('RollOver')) {
                obj['RollOver'] = ApiClient.convertToType(data['RollOver'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Underlying</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Underlying</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['ISIN'] && !(typeof data['ISIN'] === 'string' || data['ISIN'] instanceof String)) {
            throw new Error("Expected the field `ISIN` to be a primitive type in the JSON string but got " + data['ISIN']);
        }
        // ensure the json data is a string
        if (data['InstrumentLongName'] && !(typeof data['InstrumentLongName'] === 'string' || data['InstrumentLongName'] instanceof String)) {
            throw new Error("Expected the field `InstrumentLongName` to be a primitive type in the JSON string but got " + data['InstrumentLongName']);
        }
        // ensure the json data is a string
        if (data['UnderlyingType'] && !(typeof data['UnderlyingType'] === 'string' || data['UnderlyingType'] instanceof String)) {
            throw new Error("Expected the field `UnderlyingType` to be a primitive type in the JSON string but got " + data['UnderlyingType']);
        }
        // ensure the json data is a string
        if (data['UnderlyingRisk'] && !(typeof data['UnderlyingRisk'] === 'string' || data['UnderlyingRisk'] instanceof String)) {
            throw new Error("Expected the field `UnderlyingRisk` to be a primitive type in the JSON string but got " + data['UnderlyingRisk']);
        }
        if (data['Observation']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Observation'])) {
                throw new Error("Expected the field `Observation` to be an array in the JSON data but got " + data['Observation']);
            }
            // validate the optional field `Observation` (array)
            for (const item of data['Observation']) {
                Observation.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * ISIN of the underlying asset.[205]
 * @member {String} ISIN
 */
Underlying.prototype['ISIN'] = undefined;

/**
 * Name of the underlying asset.[206]
 * @member {String} InstrumentLongName
 */
Underlying.prototype['InstrumentLongName'] = undefined;

/**
 * Type of the underlying asset.[207]
 * @member {String} UnderlyingType
 */
Underlying.prototype['UnderlyingType'] = undefined;

/**
 * The entity(ies), asset(s) or security(ies) on which the credit responsibility lies as identified in the documentation.[472]
 * @member {String} UnderlyingRisk
 */
Underlying.prototype['UnderlyingRisk'] = undefined;

/**
 * Indication whether currency fluctuation of the underlying is hedged[112]
 * @member {Boolean} Quanto
 */
Underlying.prototype['Quanto'] = undefined;

/**
 * Model: Observation
 * @member {Array.<module:model/Observation>} Observation
 */
Underlying.prototype['Observation'] = undefined;

/**
 * Determines whether Rollover is allowed by the T&C[37]
 * @member {Boolean} RollOver
 */
Underlying.prototype['RollOver'] = undefined;






export default Underlying;

