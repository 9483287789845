/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ComplianceDetails from '../model/ComplianceDetails';
import Error from '../model/Error';
import IssuanceBlotterData from '../model/IssuanceBlotterData';
import LifecycleManagementData from '../model/LifecycleManagementData';
import PendingCount from '../model/PendingCount';

/**
* Pending service.
* @module api/PendingApi
* @version 1.0.3
*/
export default class PendingApi {

    /**
    * Constructs a new PendingApi. 
    * @alias module:api/PendingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getComplianceDetails operation.
     * @callback module:api/PendingApi~getComplianceDetailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ComplianceDetails} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * To get the compliance details
     * @param {String} isin isin
     * @param {module:api/PendingApi~getComplianceDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ComplianceDetails}
     */
    getComplianceDetails(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getComplianceDetails");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ComplianceDetails;
      return this.apiClient.callApi(
        '/di/v1/pending/compliance-checks/{isin}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPendingComplianceCheck operation.
     * @callback module:api/PendingApi~getPendingComplianceCheckCallback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuanceBlotterData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * To get the pending details of specific type.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/PendingApi~getPendingComplianceCheckCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuanceBlotterData}
     */
    getPendingComplianceCheck(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuanceBlotterData;
      return this.apiClient.callApi(
        '/di/v1/issuances/pending/compliance-checks', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPendingIssuancesCount operation.
     * @callback module:api/PendingApi~getPendingIssuancesCountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PendingCount} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * To get the pending Issuance count corresponding to a user.
     * @param {module:api/PendingApi~getPendingIssuancesCountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PendingCount}
     */
    getPendingIssuancesCount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PendingCount;
      return this.apiClient.callApi(
        '/di/v1/issuances/pending/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPendingIssuancesOfTypeIssuanceBlotter operation.
     * @callback module:api/PendingApi~getPendingIssuancesOfTypeIssuanceBlotterCallback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuanceBlotterData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * To get the pending Issuance details of type Issuance Blotter corresponding to a user.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/PendingApi~getPendingIssuancesOfTypeIssuanceBlotterCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuanceBlotterData}
     */
    getPendingIssuancesOfTypeIssuanceBlotter(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuanceBlotterData;
      return this.apiClient.callApi(
        '/di/v1/issuances/pending/issuance-blotter', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPendingIssuancesOfTypeLifecycleMngmt operation.
     * @callback module:api/PendingApi~getPendingIssuancesOfTypeLifecycleMngmtCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LifecycleManagementData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * To get paginated list of the pending Issuance details of type Lifecycle Management corresponding to a user.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/PendingApi~getPendingIssuancesOfTypeLifecycleMngmtCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LifecycleManagementData}
     */
    getPendingIssuancesOfTypeLifecycleMngmt(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LifecycleManagementData;
      return this.apiClient.callApi(
        '/di/v1/issuances/pending/lifecycle-management', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
