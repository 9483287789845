import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";

import { styles } from "./XMLUpload.styles";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import uploadSuccessIcon from "../../../assets/UploadSuccess.svg";
import uploadFailureIcon from "../../../assets/UploadFailure.svg";

function XMLUpload(props) {
  const [uploadedfiles, setUploadedfiles] = useState([]);
  const [flag, setFlag] = useState("");
  const [selectedFileData, setSelectedFileData] = useState("");
  const [show, setShow] = useState(false);
  const { title, open, onHandleClose, onSuccess, onFailure } = props;

  const uploadFile = (flag) => {
    onHandleClose();
    setShow(false);
    let formData = new FormData();
    formData.append("file", uploadedfiles[0].file);
    let jwtToken = sessionStorage.getItem("jwtToken");
    let url = "/di/v1/redemptions"; //requesting for uploading DRP
    axios
      .post(url, formData, {
        //formData is being passed with the DRP filedata
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((res) => {
        onSuccess(res);
        setUploadedfiles([]);
      })
      .catch((error) => {
        onFailure(error);
        setUploadedfiles([]);
      });
  };

  const handleClose = () => {
    onHandleClose();
    setUploadedfiles([]);
    setShow(false);
  };

  /* Validating uploaded xml file in UI */
  const filePathSet = (event) => {
    event.preventDefault();
    let fileData = event.target.files;

    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length
      );
      let obj = {
        fileName: fileData[x].name,
        file: fileData[x],
      };
      let files = uploadedfiles;
      files.push(obj);
      setUploadedfiles(files);
      if (fileExtension.toLowerCase() === "xml") {
        setFlag("success");
      } else {
        setFlag("error");
      }
      setShow(true);
    }
    setSelectedFileData({ fileData });
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogContent style={styles.dialogContent}>
          <DialogContentText>
            <Typography variant="h5" id="xml_title" style={styles.title}>
              {title}
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography
              variant="h5"
              id="xml_selectxml"
              style={styles.description}
            >
              Please select a XML file.
            </Typography>
          </DialogContentText>
          <Grid container style={{ alignItems: "flex-end" }}>
            <Grid item xs={1}>
              <InsertDriveFileOutlinedIcon style={styles.driveFileIcon} />
            </Grid>
            <Grid item container xs={7} style={styles.chooseUnderline}>
              <Grid item xs={11}>
                <div style={styles.noFileSelected}>
                  {" "}
                  {uploadedfiles.length !== 0
                    ? selectedFileData.fileData[0]?.name
                    : "No file Selected"}
                </div>
              </Grid>
              <Grid item xs={1}>
                <span id="xml_float" className="float">
                  {show && (
                    <>
                      {flag === "error" && (
                        <img alt="FailureIcon" src={uploadFailureIcon} />
                      )}
                      {flag === "success" && (
                        <img alt="SuccessIcon" src={uploadSuccessIcon} />
                      )}
                    </>
                  )}
                </span>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              id="xml_paddingFileUpload"
              className="paddingFileUpload"
              align="end"
            >
              <label htmlFor="fileUpload">
                <Button
                  variant="outlined"
                  color="primary"
                  id="xml_chooseFile"
                  style={styles.chooseFile}
                  component="span"
                >
                  Choose File
                </Button>
              </label>
              <input
                id="fileUpload"
                className="inputFile"
                style={{ display: "none" }}
                type="file"
                onChange={filePathSet.bind(this)}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {" "}
              {show && flag === "error" && (
                <Typography
                  variant="subtitle1"
                  id="xml_errorText"
                  style={styles.errorFormat}
                >
                  Error: File type is not supported. Please select another file
                  and try again.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={styles.dialogActionContainer}>
          <Button
            onClick={() => {
              handleClose();
            }}
            style={styles.cancel}
            id="xml_cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={styles.submit}
            id="xml_submit"
            disabled={uploadedfiles.length === 0 || flag === "error"}
            onClick={() => {
              uploadFile("DRPonBehalf");
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default XMLUpload;
