import React from "react";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";

const MessagePopup = ({
  responseText,
  showPopUp,
  flagPopUp,
  closeErrorSuccessPopup,
}) => {
  return (
    <>
      {responseText && showPopUp && (
        <div id="drp_text_popup" className="popup">
          <div>
            {flagPopUp !== "error" ? (
              <SuccessPopup
                successPopupText={responseText}
                popupVisibility={showPopUp}
                handleClose={() => closeErrorSuccessPopup()}
              />
            ) : (
              <ErrorPopup
                errorPopupText={responseText}
                popupVisibility={showPopUp}
                handleClose={() => closeErrorSuccessPopup()}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MessagePopup;
