/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The QuoteSystem model module.
 * @module model/QuoteSystem
 * @version 1.0.3
 */
class QuoteSystem {
    /**
     * Constructs a new <code>QuoteSystem</code>.
     * @alias module:model/QuoteSystem
     */
    constructor() { 
        
        QuoteSystem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>QuoteSystem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/QuoteSystem} obj Optional instance to populate.
     * @return {module:model/QuoteSystem} The populated <code>QuoteSystem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new QuoteSystem();

            if (data.hasOwnProperty('XetraID')) {
                obj['XetraID'] = ApiClient.convertToType(data['XetraID'], 'String');
            }
            if (data.hasOwnProperty('XetraSubgroupID')) {
                obj['XetraSubgroupID'] = ApiClient.convertToType(data['XetraSubgroupID'], 'String');
            }
            if (data.hasOwnProperty('ExchangeMemberId')) {
                obj['ExchangeMemberId'] = ApiClient.convertToType(data['ExchangeMemberId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>QuoteSystem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>QuoteSystem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['XetraID'] && !(typeof data['XetraID'] === 'string' || data['XetraID'] instanceof String)) {
            throw new Error("Expected the field `XetraID` to be a primitive type in the JSON string but got " + data['XetraID']);
        }
        // ensure the json data is a string
        if (data['XetraSubgroupID'] && !(typeof data['XetraSubgroupID'] === 'string' || data['XetraSubgroupID'] instanceof String)) {
            throw new Error("Expected the field `XetraSubgroupID` to be a primitive type in the JSON string but got " + data['XetraSubgroupID']);
        }
        // ensure the json data is a string
        if (data['ExchangeMemberId'] && !(typeof data['ExchangeMemberId'] === 'string' || data['ExchangeMemberId'] instanceof String)) {
            throw new Error("Expected the field `ExchangeMemberId` to be a primitive type in the JSON string but got " + data['ExchangeMemberId']);
        }

        return true;
    }


}



/**
 * Xetra ID of the institution responsible for providing quotes of the instrument[315]
 * @member {String} XetraID
 */
QuoteSystem.prototype['XetraID'] = undefined;

/**
 * Xetra Subgroup ID of the institution responsible for providing quotes[316]
 * @member {String} XetraSubgroupID
 */
QuoteSystem.prototype['XetraSubgroupID'] = undefined;

/**
 * A specialists exchange member ID (KV number)[317]
 * @member {String} ExchangeMemberId
 */
QuoteSystem.prototype['ExchangeMemberId'] = undefined;






export default QuoteSystem;

