import React from "react";
import {
  StyledContent,
  StyledOfDialog,
  StyledDialogActions,
  StyledDialogContentText,
} from "../../modules/Template/index.css";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RecordsTable from "../../modules/ComplianceList/RecordsTable";
import { validationErrorColumns } from "../../utils/columnUtils";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "750px",
    height: "450px",
  },
}));
const ValidationErrorPopup = ({
  validationErrors,
  open,
  onClose,
  validationMessage
}) => {
  const classes = useStyles();

  const closeDialog = () => {
    onClose();
  };
  return (
    <StyledOfDialog
      classes={{ paper: classes.dialogPaper }}
      className="newIssuanceDialog"
      id="temp_newIssuanceDialog"
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <Typography variant="h5" className="newIssFont" id="temp_newIssFont">
        Validation Errors
      </Typography>
      <StyledContent>
        <StyledDialogContentText>
          <Typography
            variant="h5"
            id="temp_uploadXmlPdf"
            className="uploadXmlPdf"
            data-testid="issuanceDialogTitle"
          >
            {validationMessage}
          </Typography>
        </StyledDialogContentText>
        <Grid
          container
          id="temp_tempPaddingTop_container"
          className="tempPaddingTop"
        >
          <RecordsTable
            columns={validationErrorColumns}
            data={validationErrors}
          />
        </Grid>
      </StyledContent>
      <StyledDialogActions id="temp_actions" className="paddingActions">
        <Button
          onClick={() => {
            closeDialog();
          }}
          id="temp_cancelButton"
          data-testid="cancel_issuance"
          className="cancelColor"
        >
          Cancel
        </Button>
      </StyledDialogActions>
    </StyledOfDialog>
  );
}

export default ValidationErrorPopup
