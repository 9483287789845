import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LogBody } from "../index.css";

/*
 * This array is for table column headers
 */
const columns = [
  { id: "srNo", label: "Sr. no.", width: "5%" },
  { id: "dateTime", label: "Date Time", width: "10%" },
  { id: "logIndicator", label: "Log Indicator", width: "10%" },
  { id: "dti", label: "DTI", width: "10%" },
  { id: "userId", label: "User ID", width: "10%" },
  { id: "overAllStatus", label: "Overall Status", width: "10%" },
  { id: "processingStatus", label: "Processing Status", width: "15%" },
  { id: "internalStatus", label: "Internal Status", width: "10%" },
  { id: "content", label: "Content", width: "20%" },
];

function CombinedLog(props) {
  return (
    <React.Fragment>
      <LogBody>
        {props.combinedLogData.length !== 0 ? (
          <TableContainer className="tableheight">
            <Table
              aria-label="sticky table"
              data-testid="tableView"
              stickyHeader
              className="tableClass"
            >
              <TableHead>
                <TableRow className="bgcolour">
                  {columns.map((row, index) => {
                    if (row.id === "internalStatus" && !props.isClearstream) {
                      return;
                    }
                    return (
                      <TableCell width={row.width} align="left">
                        <Typography variant="h5"> {row.label}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.combinedLogData.map((row, index) => {
                  return (
                    <TableRow key={index} data-testid="TableRows">
                      <TableCell width="5%" align="left">
                        <Typography variant="h6" className="textcolour">
                          {++index}
                        </Typography>
                      </TableCell>
                      <TableCell width="15%" align="left">
                        <Typography variant="h6">{row.dateTime}</Typography>
                      </TableCell>
                      <TableCell align="left" width="10%">
                        <Typography variant="h6" className="textcolour">
                          {row.logIndicator}
                        </Typography>
                      </TableCell>
                      <TableCell width="15%" align="left">
                        <Typography variant="h6" className="textcolour">
                          {row.dti}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="15%"
                        style={{ textAlign: "justify" }}
                      >
                        <Typography variant="h6" className="textcolour">
                          {row.userId}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" width="15%">
                        <Typography variant="h6" className="textcolour">
                          {row.overAllStatus}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" width="15%">
                        <Typography variant="h6" className="textcolour">
                          {row.processingStatus}
                        </Typography>
                      </TableCell>
                      {props.isClearstream && (
                        <TableCell align="left" width="15%">
                          <Typography variant="h6" className="textcolour">
                            {row.internalStatus}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell
                        width="30%"
                        align="left"
                        style={{ textAlign: "justify" }}
                      >
                        <Typography variant="h6" className="textcolour">
                          {row.content}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ""
        )}
      </LogBody>
    </React.Fragment>
  );
}

export default CombinedLog;
