import styled from "styled-components";
import Select from "react-select";

export const StyledDropdown = styled(Select)`
  .select__placeholder {
    color: darkgray;
    top: 51%;
  }

  .select__control {
    border-radius: 17px;
    min-height: 35px;
    border: 1px solid rgb(135 135 135 / 60%);
    .select__indicator-separator {
      display: none;
    }
    .select__indicators {
      height: 35px;
      padding: 0;
    }
    .select__dropdown-indicator {
      color: #00a5c0;
      padding-right: 5px;
    }
  }
`;
