/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountsStaticIssuerData model module.
 * @module model/AccountsStaticIssuerData
 * @version 1.0.3
 */
class AccountsStaticIssuerData {
    /**
     * Constructs a new <code>AccountsStaticIssuerData</code>.
     * @alias module:model/AccountsStaticIssuerData
     */
    constructor() { 
        
        AccountsStaticIssuerData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountsStaticIssuerData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountsStaticIssuerData} obj Optional instance to populate.
     * @return {module:model/AccountsStaticIssuerData} The populated <code>AccountsStaticIssuerData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountsStaticIssuerData();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('changeType')) {
                obj['changeType'] = ApiClient.convertToType(data['changeType'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('payAgentAccountNo')) {
                obj['payAgentAccountNo'] = ApiClient.convertToType(data['payAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('issuerAccountNo')) {
                obj['issuerAccountNo'] = ApiClient.convertToType(data['issuerAccountNo'], 'String');
            }
            if (data.hasOwnProperty('issuerLei')) {
                obj['issuerLei'] = ApiClient.convertToType(data['issuerLei'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentName')) {
                obj['issuerAgentName'] = ApiClient.convertToType(data['issuerAgentName'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentAccountNo')) {
                obj['issuerAgentAccountNo'] = ApiClient.convertToType(data['issuerAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('cbfAktEmitCode')) {
                obj['cbfAktEmitCode'] = ApiClient.convertToType(data['cbfAktEmitCode'], 'String');
            }
            if (data.hasOwnProperty('protocol')) {
                obj['protocol'] = ApiClient.convertToType(data['protocol'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AccountsStaticIssuerData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AccountsStaticIssuerData</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['changeType'] && !(typeof data['changeType'] === 'string' || data['changeType'] instanceof String)) {
            throw new Error("Expected the field `changeType` to be a primitive type in the JSON string but got " + data['changeType']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['payAgentAccountNo'] && !(typeof data['payAgentAccountNo'] === 'string' || data['payAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `payAgentAccountNo` to be a primitive type in the JSON string but got " + data['payAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['issuerAccountNo'] && !(typeof data['issuerAccountNo'] === 'string' || data['issuerAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAccountNo` to be a primitive type in the JSON string but got " + data['issuerAccountNo']);
        }
        // ensure the json data is a string
        if (data['issuerLei'] && !(typeof data['issuerLei'] === 'string' || data['issuerLei'] instanceof String)) {
            throw new Error("Expected the field `issuerLei` to be a primitive type in the JSON string but got " + data['issuerLei']);
        }
        // ensure the json data is a string
        if (data['issuerAgentName'] && !(typeof data['issuerAgentName'] === 'string' || data['issuerAgentName'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentName` to be a primitive type in the JSON string but got " + data['issuerAgentName']);
        }
        // ensure the json data is a string
        if (data['issuerAgentAccountNo'] && !(typeof data['issuerAgentAccountNo'] === 'string' || data['issuerAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentAccountNo` to be a primitive type in the JSON string but got " + data['issuerAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['cbfAktEmitCode'] && !(typeof data['cbfAktEmitCode'] === 'string' || data['cbfAktEmitCode'] instanceof String)) {
            throw new Error("Expected the field `cbfAktEmitCode` to be a primitive type in the JSON string but got " + data['cbfAktEmitCode']);
        }
        // ensure the json data is a string
        if (data['protocol'] && !(typeof data['protocol'] === 'string' || data['protocol'] instanceof String)) {
            throw new Error("Expected the field `protocol` to be a primitive type in the JSON string but got " + data['protocol']);
        }

        return true;
    }


}



/**
 * id of the item
 * @member {String} id
 */
AccountsStaticIssuerData.prototype['id'] = undefined;

/**
 * Description type of the item type New or Delete
 * @member {String} changeType
 */
AccountsStaticIssuerData.prototype['changeType'] = undefined;

/**
 * name of the issuerName
 * @member {String} issuerName
 */
AccountsStaticIssuerData.prototype['issuerName'] = undefined;

/**
 * Pay Agent Account No
 * @member {String} payAgentAccountNo
 */
AccountsStaticIssuerData.prototype['payAgentAccountNo'] = undefined;

/**
 * Issuer Account No
 * @member {String} issuerAccountNo
 */
AccountsStaticIssuerData.prototype['issuerAccountNo'] = undefined;

/**
 * Issuer Lei
 * @member {String} issuerLei
 */
AccountsStaticIssuerData.prototype['issuerLei'] = undefined;

/**
 * Issuer Agent Name
 * @member {String} issuerAgentName
 */
AccountsStaticIssuerData.prototype['issuerAgentName'] = undefined;

/**
 * Issuer Agent Account No
 * @member {String} issuerAgentAccountNo
 */
AccountsStaticIssuerData.prototype['issuerAgentAccountNo'] = undefined;

/**
 * Issuer Sort Code
 * @member {String} cbfAktEmitCode
 */
AccountsStaticIssuerData.prototype['cbfAktEmitCode'] = undefined;

/**
 * Protocol details
 * @member {String} protocol
 */
AccountsStaticIssuerData.prototype['protocol'] = undefined;






export default AccountsStaticIssuerData;

