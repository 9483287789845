const ClearstreamRoles = {
  clearstream_oa: ["Clearstream Operations Advanced"],
  clearstream_or: ["Clearstream Operations Regular"],
  clearstream_b: ["Clearstream Basic"],
  clearstream_a: ["Clearstream Auditor"],
  clearstream_it: ["Clearstream IT"],
  clearstream_oa_or: [
    "Clearstream Operations Advanced",
    "Clearstream Operations Regular",
  ],
  clearstream_b_a: ["Clearstream Basic", "Clearstream Auditor"],
  clearstream_b_it: ["Clearstream Basic", "Clearstream IT"],
  clearstream_com: ["Clearstream Compliance"],
  clearstream_1m: ["Clearstream Operations Advanced"],
  clearstream_ner: ["Non Existing Role"]
};

const BNPParibasRoles = {
  bnpparibas_iao: ["Issuing Agent Operator"],
  bnpparibas_pao: ["Paying Agent Operator"],
  bnpparibas_ao: ["Account Operator"],
  bnpparibas_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  bnpparibas_pao_ao: ["Paying Agent Operator", "Account Operator"],
  bnpparibas_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  bnpparibas_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const DekaRoles = {
  deka_iao: ["Issuing Agent Operator"],
  deka_pao: ["Paying Agent Operator"],
  deka_ao: ["Account Operator"],
  deka_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  deka_pao_ao: ["Paying Agent Operator", "Account Operator"],
  deka_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  deka_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const DZBankRoles = {
  dzbank_iao: ["Issuing Agent Operator"],
  dzbank_pao: ["Paying Agent Operator"],
  dzbank_ao: ["Account Operator"],
  dzbank_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  dzbank_pao_ao: ["Paying Agent Operator", "Account Operator"],
  dzbank_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  dzbank_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const LandesbankRoles = {
  landesbank_iao: ["Issuing Agent Operator"],
  landesbank_pao: ["Paying Agent Operator"],
  landesbank_ao: ["Account Operator"],
  landesbank_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  landesbank_pao_ao: ["Paying Agent Operator", "Account Operator"],
  landesbank_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  landesbank_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const UniCreditRoles = {
  unicredit_iao: ["Issuing Agent Operator"],
  unicredit_pao: ["Paying Agent Operator"],
  unicredit_ao: ["Account Operator"],
  unicredit_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  unicredit_pao_ao: ["Paying Agent Operator", "Account Operator"],
  unicredit_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  unicredit_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const VontobelRoles = {
  vontobel_iao: ["Issuing Agent Operator"],
  vontobel_pao: ["Paying Agent Operator"],
  vontobel_ao: ["Account Operator"],
  vontobel_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  vontobel_pao_ao: ["Paying Agent Operator", "Account Operator"],
  vontobel_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  vontobel_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const NRWBankRoles = {
  nrwbank_iao: ["Issuing Agent Operator"],
  nrwbank_pao: ["Paying Agent Operator"],
  nrwbank_ao: ["Account Operator"],
  nrwbank_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  nrwbank_pao_ao: ["Paying Agent Operator", "Account Operator"],
  nrwbank_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  nrwbank_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const NordLBRoles = {
  nordlb_iao: ["Issuing Agent Operator"],
  nordlb_pao: ["Paying Agent Operator"],
  nordlb_ao: ["Account Operator"],
  nordlb_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  nordlb_pao_ao: ["Paying Agent Operator", "Account Operator"],
  nordlb_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  nordlb_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const MuHypRoles = {
  muhyp_iao: ["Issuing Agent Operator"],
  muhyp_pao: ["Paying Agent Operator"],
  muhyp_ao: ["Account Operator"],
  muhyp_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  muhyp_pao_ao: ["Paying Agent Operator", "Account Operator"],
  muhyp_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  muhyp_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const BerlinHypRoles = {
  berlinhyp_iao: ["Issuing Agent Operator"],
  berlinhyp_pao: ["Paying Agent Operator"],
  berlinhyp_ao: ["Account Operator"],
  berlinhyp_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  berlinhyp_pao_ao: ["Paying Agent Operator", "Account Operator"],
  berlinhyp_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  berlinhyp_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const KfWRoles = {
  kfw_iao: ["Issuing Agent Operator"],
  kfw_pao: ["Paying Agent Operator"],
  kfw_ao: ["Account Operator"],
  kfw_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  kfw_pao_ao: ["Paying Agent Operator", "Account Operator"],
  kfw_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  kfw_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

const DefaultIssuer3Roles = {
  defaultissuer3_iao: ["Issuing Agent Operator"],
  defaultissuer3_pao: ["Paying Agent Operator"],
  defaultissuer3_ao: ["Account Operator"],
  defaultissuer3_iao_ao: ["Issuing Agent Operator", "Account Operator"],
  defaultissuer3_pao_ao: ["Paying Agent Operator", "Account Operator"],
  defaultissuer3_iao_pao: ["Issuing Agent Operator", "Paying Agent Operator"],
  defaultissuer3_iao_pao_ao: [
    "Issuing Agent Operator",
    "Paying Agent Operator",
    "Account Operator",
  ],
};

export const rolesObject = {
  Clearstream: Object.keys(ClearstreamRoles),
  BNPParibas: Object.keys(BNPParibasRoles),
  Deka: Object.keys(DekaRoles),
  DZBank: Object.keys(DZBankRoles),
  Landesbank: Object.keys(LandesbankRoles),
  UniCredit: Object.keys(UniCreditRoles),
  Vontobel: Object.keys(VontobelRoles),
  NRWBank: Object.keys(NRWBankRoles),
  NordLB: Object.keys(NordLBRoles),
  MuHyp: Object.keys(MuHypRoles),
  BerlinHyp: Object.keys(BerlinHypRoles),
  KfW: Object.keys(KfWRoles),
  DefaultIssuer3: Object.keys(DefaultIssuer3Roles),
};
