/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CBF from './CBF';
import DeFi from './DeFi';

/**
 * The VenueData model module.
 * @module model/VenueData
 * @version 1.0.3
 */
class VenueData {
    /**
     * Constructs a new <code>VenueData</code>.
     * @alias module:model/VenueData
     */
    constructor() { 
        
        VenueData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>VenueData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/VenueData} obj Optional instance to populate.
     * @return {module:model/VenueData} The populated <code>VenueData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VenueData();

            if (data.hasOwnProperty('CBF')) {
                obj['CBF'] = CBF.constructFromObject(data['CBF']);
            }
            if (data.hasOwnProperty('DeFi')) {
                obj['DeFi'] = DeFi.constructFromObject(data['DeFi']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>VenueData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>VenueData</code>.
     */
    static validateJSON(data) {
        // validate the optional field `CBF`
        if (data['CBF']) { // data not null
          CBF.validateJSON(data['CBF']);
        }
        // validate the optional field `DeFi`
        if (data['DeFi']) { // data not null
          DeFi.validateJSON(data['DeFi']);
        }

        return true;
    }


}



/**
 * @member {module:model/CBF} CBF
 */
VenueData.prototype['CBF'] = undefined;

/**
 * @member {module:model/DeFi} DeFi
 */
VenueData.prototype['DeFi'] = undefined;






export default VenueData;

