import styled from "styled-components";
import { Container, Card } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

export const StyledContainer = styled(Container)`
  @media (min-width: 1280px) {
    max-width: inherit;
  }
  padding-top: 0px;
  padding-left: 80px;
  padding-right: 80px;
  .popup {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 4em;
    left: 65%;
    top: 75px;
  }
  .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const StyledHeader = styled(Card)`
  z-index: 2;
  border: none;
  padding-top: 40px;
  box-shadow: none;
  background-color: #ffffff;
  .MuiBreadcrumbs-ol {
    margin-top: 1%;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 2em;
  }
  .MuiSvgIcon-root {
    padding-top: 10px;
    color: #000000;
  }
  .mainItem {
    color: #00a7c4;
    border-bottom: 1px solid #00a5c0;
    padding-top: 10px;
  }
  .item {
    padding-top: 10px;
    color: #000000;
  }
  .container {
    flex: 1 0 0;
  }
  .divider {
    display: inline-block;
    width: 2px;
    background-color: #00a7c4;
    margin: 0 10px;
    height: 4em;
  }
  .processlog {
    width: 129px;
  }
  .icons {
    margin-right: 1%;
    cursor: pointer;
  }
  .showpdf {
    cursor: pointer;
  }
  .excolAll {
    color: #00a7c4;
    border-bottom: 1px solid #00a7c4;
  }
  .padding1,
  .paddingDetails {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .padding2 {
    paddding-top: 10px;
  }
  .Mui-disabled,
  .Mui-disabled:hover {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .MuiButton-root {
    width: inherit !important;
  }
  .navigationItem {
    color: #00a7c4;
    border-bottom: 1px solid #00a5c0;
    padding-top: 5px;
    margin-left: -10px;
    font-size: 14px;
  }
  .breadcrumbs {
    padding-bottom: 10px;
  }
  .btnExport {
    border-radius: 17px;
    padding: 10px;
  }
  .exportIcon {
    padding: 4px !important;
    margin: 2px !important;
    color: #ffffff !important;
  }
`;

export const StyledDialog = styled(Dialog)`
    .acceptRejectDialog{
        z-index: 1;
    }
    .acceptRejectButton{
        font-size: 18px;
        font-weight: bold; 
        color: #000000;
        padding-top: 20px; 
        padding-left: 10px
    }
    .accept{
        padding-top: 20px
        padding-bottom: 30px;
        padding-right: 100px
    }
`;

export const StyledBody = styled(Card)`
  overflow: visible;
  border: none;
  box-shadow: none;
  background-color: #ffffff;
  padding-top: 0;
  .MuiTableCell-root {
    border: none;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 1px 5px rgba(135, 135, 135, 0.6);
  }
  .accordianStyle {
    margin: 25px 0 30px 0 !important;
    border-radius: 12px;
  }
  .MuiAccordion-root:before {
    background-color: rgba(0, 0, 0, 0);
  }

  .accordionContent {
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid #00a7c4;
    background-color: #ffffff;
  }
  .MuiSvgIcon-root {
    transition: fill 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .bgColour {
    background-color: rgba(227, 227, 227, 0.15);
  }
  .divider1 {
    display: inline-block;
    background-color: #00a5c0;
    width: 1px;
    height: inherit;
    margin-top: 8px;
  }
  .padding3 {
    padding-top: 10px;
  }
  .padding4 {
    padding-left: 15px;
  }

  @media only screen and (max-width: 1450px) {
    /* For smaller screens: */
    [class*="accordionContent"] {
      flex-wrap: wrap;
    }
    [class*="padding4"] {
      padding-left: 0px;
    }
    [class*="divider1"] {
      display: none;
    }
  }
`;
