/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BatchStatusDetail from './BatchStatusDetail';

/**
 * The BatchDetail model module.
 * @module model/BatchDetail
 * @version 1.0.3
 */
class BatchDetail {
    /**
     * Constructs a new <code>BatchDetail</code>.
     * @alias module:model/BatchDetail
     */
    constructor() { 
        
        BatchDetail.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BatchDetail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BatchDetail} obj Optional instance to populate.
     * @return {module:model/BatchDetail} The populated <code>BatchDetail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BatchDetail();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = BatchStatusDetail.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('started')) {
                obj['started'] = ApiClient.convertToType(data['started'], 'Date');
            }
            if (data.hasOwnProperty('finished')) {
                obj['finished'] = ApiClient.convertToType(data['finished'], 'Date');
            }
            if (data.hasOwnProperty('retryCount')) {
                obj['retryCount'] = ApiClient.convertToType(data['retryCount'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>BatchDetail</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BatchDetail</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['userId'] && !(typeof data['userId'] === 'string' || data['userId'] instanceof String)) {
            throw new Error("Expected the field `userId` to be a primitive type in the JSON string but got " + data['userId']);
        }
        // ensure the json data is a string
        if (data['userName'] && !(typeof data['userName'] === 'string' || data['userName'] instanceof String)) {
            throw new Error("Expected the field `userName` to be a primitive type in the JSON string but got " + data['userName']);
        }

        return true;
    }


}



/**
 * Batch id
 * @member {String} id
 */
BatchDetail.prototype['id'] = undefined;

/**
 * @member {module:model/BatchStatusDetail} status
 */
BatchDetail.prototype['status'] = undefined;

/**
 * @member {Date} created
 */
BatchDetail.prototype['created'] = undefined;

/**
 * @member {Date} started
 */
BatchDetail.prototype['started'] = undefined;

/**
 * @member {Date} finished
 */
BatchDetail.prototype['finished'] = undefined;

/**
 * @member {Number} retryCount
 */
BatchDetail.prototype['retryCount'] = undefined;

/**
 * @member {String} userId
 */
BatchDetail.prototype['userId'] = undefined;

/**
 * @member {String} userName
 */
BatchDetail.prototype['userName'] = undefined;






export default BatchDetail;

