/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Documentation model module.
 * @module model/Documentation
 * @version 1.0.3
 */
class Documentation {
    /**
     * Constructs a new <code>Documentation</code>.
     * @alias module:model/Documentation
     */
    constructor() { 
        
        Documentation.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Documentation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Documentation} obj Optional instance to populate.
     * @return {module:model/Documentation} The populated <code>Documentation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Documentation();

            if (data.hasOwnProperty('DescriptionURL')) {
                obj['DescriptionURL'] = ApiClient.convertToType(data['DescriptionURL'], 'String');
            }
            if (data.hasOwnProperty('FinalTermsDate')) {
                obj['FinalTermsDate'] = ApiClient.convertToType(data['FinalTermsDate'], 'Date');
            }
            if (data.hasOwnProperty('FinalTermsPublication')) {
                obj['FinalTermsPublication'] = ApiClient.convertToType(data['FinalTermsPublication'], 'String');
            }
            if (data.hasOwnProperty('Expose')) {
                obj['Expose'] = ApiClient.convertToType(data['Expose'], 'Boolean');
            }
            if (data.hasOwnProperty('ProspectusSubmitted')) {
                obj['ProspectusSubmitted'] = ApiClient.convertToType(data['ProspectusSubmitted'], 'Boolean');
            }
            if (data.hasOwnProperty('Admitted')) {
                obj['Admitted'] = ApiClient.convertToType(data['Admitted'], 'Boolean');
            }
            if (data.hasOwnProperty('CeFiRegisterID')) {
                obj['CeFiRegisterID'] = ApiClient.convertToType(data['CeFiRegisterID'], 'String');
            }
            if (data.hasOwnProperty('DocumentHash')) {
                obj['DocumentHash'] = ApiClient.convertToType(data['DocumentHash'], 'String');
            }
            if (data.hasOwnProperty('DocumentURL')) {
                obj['DocumentURL'] = ApiClient.convertToType(data['DocumentURL'], 'String');
            }
            if (data.hasOwnProperty('Language')) {
                obj['Language'] = ApiClient.convertToType(data['Language'], 'String');
            }
            if (data.hasOwnProperty('DocumentHashAlgorithm')) {
                obj['DocumentHashAlgorithm'] = ApiClient.convertToType(data['DocumentHashAlgorithm'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Documentation</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Documentation</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['DescriptionURL'] && !(typeof data['DescriptionURL'] === 'string' || data['DescriptionURL'] instanceof String)) {
            throw new Error("Expected the field `DescriptionURL` to be a primitive type in the JSON string but got " + data['DescriptionURL']);
        }
        // ensure the json data is a string
        if (data['FinalTermsPublication'] && !(typeof data['FinalTermsPublication'] === 'string' || data['FinalTermsPublication'] instanceof String)) {
            throw new Error("Expected the field `FinalTermsPublication` to be a primitive type in the JSON string but got " + data['FinalTermsPublication']);
        }
        // ensure the json data is a string
        if (data['CeFiRegisterID'] && !(typeof data['CeFiRegisterID'] === 'string' || data['CeFiRegisterID'] instanceof String)) {
            throw new Error("Expected the field `CeFiRegisterID` to be a primitive type in the JSON string but got " + data['CeFiRegisterID']);
        }
        // ensure the json data is a string
        if (data['DocumentHash'] && !(typeof data['DocumentHash'] === 'string' || data['DocumentHash'] instanceof String)) {
            throw new Error("Expected the field `DocumentHash` to be a primitive type in the JSON string but got " + data['DocumentHash']);
        }
        // ensure the json data is a string
        if (data['DocumentURL'] && !(typeof data['DocumentURL'] === 'string' || data['DocumentURL'] instanceof String)) {
            throw new Error("Expected the field `DocumentURL` to be a primitive type in the JSON string but got " + data['DocumentURL']);
        }
        // ensure the json data is a string
        if (data['Language'] && !(typeof data['Language'] === 'string' || data['Language'] instanceof String)) {
            throw new Error("Expected the field `Language` to be a primitive type in the JSON string but got " + data['Language']);
        }
        // ensure the json data is a string
        if (data['DocumentHashAlgorithm'] && !(typeof data['DocumentHashAlgorithm'] === 'string' || data['DocumentHashAlgorithm'] instanceof String)) {
            throw new Error("Expected the field `DocumentHashAlgorithm` to be a primitive type in the JSON string but got " + data['DocumentHashAlgorithm']);
        }

        return true;
    }


}



/**
 * Internet address. Possibility to include a URL for a website where information regarding an instrument may be found[222]
 * @member {String} DescriptionURL
 */
Documentation.prototype['DescriptionURL'] = undefined;

/**
 * Date on which the Final Terms of the issuance was deposited with BaFin and published in accordance with Article 14 (2) of the securities law[309]
 * @member {Date} FinalTermsDate
 */
Documentation.prototype['FinalTermsDate'] = undefined;

/**
 * The location where the Final Terms of the issuance was deposited with BaFin and published in accordance with Article 14 (2) of the securities law[310]
 * @member {String} FinalTermsPublication
 */
Documentation.prototype['FinalTermsPublication'] = undefined;

/**
 * Has the Exposé been submitted?[311]
 * @member {Boolean} Expose
 */
Documentation.prototype['Expose'] = undefined;

/**
 * Has the Prospectus been submitted?[312]
 * @member {Boolean} ProspectusSubmitted
 */
Documentation.prototype['ProspectusSubmitted'] = undefined;

/**
 * Is the prospectus officially recognized by a regulatory or supervisory authority?[313]
 * @member {Boolean} Admitted
 */
Documentation.prototype['Admitted'] = undefined;

/**
 * Registration ID of electronic security that is assigned from Central Register[338]
 * @member {String} CeFiRegisterID
 */
Documentation.prototype['CeFiRegisterID'] = undefined;

/**
 * Hashbyte of a document (also used as unique identifier of the document)  (set automatically by system)[342]
 * @member {String} DocumentHash
 */
Documentation.prototype['DocumentHash'] = undefined;

/**
 * Storage Location Terms&Conditions (set automatically by system)[343]
 * @member {String} DocumentURL
 */
Documentation.prototype['DocumentURL'] = undefined;

/**
 * Language of documents (eg. Terms & Conditions)[384]
 * @member {String} Language
 */
Documentation.prototype['Language'] = undefined;

/**
 * name of the algo which was used to hash the pdf[388]
 * @member {String} DocumentHashAlgorithm
 */
Documentation.prototype['DocumentHashAlgorithm'] = undefined;






export default Documentation;

