import styled from "styled-components";
import { Container } from "@material-ui/core";

export const ErrorSuccessPopupBody = styled(Container)`
  .closeIcon {
    margin-top: 5px;
    margin-right: 5px;
  }
  .respMsg {
    padding-bottom: 5px;
  }
  .successOrErrorIcon {
    margin-left: 3px;
    margin-top: 8px;
  }
  .successMsgBox {
    border: 1px solid #00cc99;
    color: #00cc99;
    border-radius: 10px;
    background-color: #fafffe;
  }
  .successLabel {
    padding-top: 12px;
    color: #00cc99;
  }
  .successText {
    color: #00cc99;
  }
  .errorMsgBox {
    border: 1px solid #cc0099;
    color: #cc0099;
    border-radius: 10px;
    background-color: #fffafe;
  }
  .errorLabel {
    padding-top: 12px;
    color: #cc0099;
  }
  .errorText {
    color: #cc0099;
  }
`;
