/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BatchData from '../model/BatchData';
import BatchItemData from '../model/BatchItemData';
import BatchItemDetail from '../model/BatchItemDetail';
import BatchRedemption from '../model/BatchRedemption';
import Error from '../model/Error';

/**
* BatchProcessor service.
* @module api/BatchProcessorApi
* @version 1.0.3
*/
export default class BatchProcessorApi {

    /**
    * Constructs a new BatchProcessorApi. 
    * @alias module:api/BatchProcessorApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getBatchItemDetail operation.
     * @callback module:api/BatchProcessorApi~getBatchItemDetailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchItemDetail} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return full details for batch item.
     * @param {String} id Batch_ID can be queried using the Identifier
     * @param {String} item_id BatchItem_ID can be queried using the Identifier
     * @param {module:api/BatchProcessorApi~getBatchItemDetailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchItemDetail}
     */
    getBatchItemDetail(id, item_id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getBatchItemDetail");
      }
      // verify the required parameter 'item_id' is set
      if (item_id === undefined || item_id === null) {
        throw new Error("Missing the required parameter 'item_id' when calling getBatchItemDetail");
      }

      let pathParams = {
        'id': id,
        'item_id': item_id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BatchItemDetail;
      return this.apiClient.callApi(
        '/di/v1/batch/{id}/items/{item_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBatchItemMessage operation.
     * @callback module:api/BatchProcessorApi~getBatchItemMessageCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return the message itself as attachment
     * @param {String} id Batch_ID can be queried using the Identifier
     * @param {String} item_id BatchItem_ID can be queried using the Identifier
     * @param {module:api/BatchProcessorApi~getBatchItemMessageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getBatchItemMessage(id, item_id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getBatchItemMessage");
      }
      // verify the required parameter 'item_id' is set
      if (item_id === undefined || item_id === null) {
        throw new Error("Missing the required parameter 'item_id' when calling getBatchItemMessage");
      }

      let pathParams = {
        'id': id,
        'item_id': item_id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/xml', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/batch/{id}/items/{item_id}/message', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBatchItems operation.
     * @callback module:api/BatchProcessorApi~getBatchItemsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchItemData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return batch details.
     * @param {String} id Batch_ID can be queried using the Identifier
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/BatchProcessorApi~getBatchItemsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchItemData}
     */
    getBatchItems(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getBatchItems");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BatchItemData;
      return this.apiClient.callApi(
        '/di/v1/batch/{id}/items', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBatches operation.
     * @callback module:api/BatchProcessorApi~getBatchesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return the list of batches
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/BatchProcessorApi~getBatchesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchData}
     */
    getBatches(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BatchData;
      return this.apiClient.callApi(
        '/di/v1/batch', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadBatchRedemption operation.
     * @callback module:api/BatchProcessorApi~uploadBatchRedemptionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchRedemption} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to validate batch redemption xml and upload to Google Cloud Storage. Finally send the xml to BPO
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @param {module:api/BatchProcessorApi~uploadBatchRedemptionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchRedemption}
     */
    uploadBatchRedemption(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = BatchRedemption;
      return this.apiClient.callApi(
        '/di/v1/redemption-batches', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
