import Typography from "@material-ui/core/Typography";
import { TableCell } from "@material-ui/core";

const SimpleTableCell = ({ label }) => (
  <TableCell>
    <Typography
        variant="subtitle1"
        className="lH-17"
      >
        {label}
    </Typography>
  </TableCell>
)

export default SimpleTableCell
