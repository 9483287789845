import React, { useEffect, useState, useRef } from "react";
import { Button, Grid, Typography, TextField } from "@material-ui/core";
import {
  StyledContainer,
  StyledSearchContainer,
  StyledTableContainer,
  SearchTags,
} from "./index.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../components/CustomAccordion";
import { Col, Row } from "react-bootstrap";
import ButtonGroup from "@mui/material/ButtonGroup";
import { DatePicker, Space } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";
import notArray from "../../assets/notArray.svg";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import ArrayIcon from "../../assets/array.svg";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useUserStore } from "../../store/Store";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import RecordsTable from "../ComplianceList/RecordsTable";
import { useTransaction } from "./useTransaction";
import CloseIcon from "@material-ui/icons/Close";
import refresh from "../../assets/refresh.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import {
  transactionTypesEnums as transactionTypeOptions,
  transactionStatusOptions,
  getLabel,
} from "../../utils/StatusUtils";
import { flipDateTimeFormat } from "../../utils/dateUtils";
import moment from "moment";

const columns = [
  { id: "timestamp", label: "Datetime" },
  { id: "isin", label: "ISIN" },
  { id: "transactionType", label: "Transaction Type" },
  { id: "message", label: "Messages" },
  { id: "submitterId", label: "Submitter Id" },
  { id: "submitterRole", label: "Submitter Role" },
  { id: "status", label: "Status" },
  { id: "hasXML", label: "XML" },
  { id: "hasPDF", label: "PDF" },
];

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TransactionStore = () => {
  const [userState] = useUserStore();
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [errorCodes, setErrorCodes] = useState([]);
  const [submitterRoles, setSubmitterRoles] = useState([]);
  const [isin, setIsin] = useState("");
  const [selectedErrorCodes, setSelectedErrorCodes] = useState([]);
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState(
    []
  );
  const [selectedTransactionType, setSelectedTransactionType] = useState([]);
  const [selectedSubmitterRole, setSelectedSubmitterRole] = useState([]);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [selectedFilter, setSelectedFilter] = useState({});
  const [expandedSearch, setExpandedSearch] = useState(true);
  const [expandedTable, setExpandedTable] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(true); //flag for enabling and disabling search button
  const dateFormat = "DD/MM/YYYY HH:mm:ss";
  const [dateSwitch, setDateSwitch] = useState(true);
  const searchElementRef = useRef();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [isShowSearchTags, setIsShowSearchTags] = useState(false);
  const searchTags = Object.entries(selectedFilter).filter(([_, v]) => v != "");
  const [queryData, setQueryData] = useState({});
  const [selected, setSelected] = useState([]);
  const [triggerResetPage, setTriggerResetPage] = useState(0);

  const {
    loading,
    itemsCount,
    fetchTransactions,
    setLoading,
    downloadXML,
    downloadPDF,
  } = useTransaction(
    setData,
    setResponseText,
    setShowPopUp,
    setflagPopUp,
    userState,
    setErrorCodes,
    setSubmitterRoles
  );

  useEffect(() => {
    const selectedDates =
      (dateSwitch === true &&
        (fromDate === undefined || fromDate === "" || fromDate == null)) ||
      (dateSwitch != true &&
        (fromDate == undefined || fromDate == "" || fromDate == null) &&
        (toDate == undefined || toDate == "" || toDate == null));
    if (
      isin !== "" ||
      selectedErrorCodes.length > 0 ||
      !selectedDates ||
      selectedTransactionType.length > 0 ||
      selectedTransactionStatus.length > 0 ||
      selectedSubmitterRole.length > 0
    ) {
      setSearchDisabled(false);
    } else {
      setSearchDisabled(true);
      setExpandedSearch(true);
      setExpandedTable(false);
    }
  }, [
    isin,
    selectedErrorCodes,
    toDate,
    fromDate,
    selectedTransactionType,
    selectedSubmitterRole,
    selectedTransactionStatus,
  ]);

  useEffect(() => {
    if (dateSwitch) {
      setToDate(null);
      setSelectedFilter({
        ...selectedFilter,
        dateTo: "",
      });
    }
  }, [dateSwitch]);

  const handleClick = (event, name, row) => {
    const selectedIndex = name;
    let newSelected = [selectedIndex];
    if (selected[0] === selectedIndex) {
      setSelected([]);
    } else {
      setSelected(newSelected);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const downloadXMLbyId = (item) => {
    if (item && item?.id) {
      downloadXML(item.id, item.isin);
    } else {
      setResponseText(`Please select valid record`);
      setShowPopUp(true);
      setflagPopUp("error");
    }
  };

  const downloadPDFbyId = (item) => {
    if (item && item?.id) {
      downloadPDF(item.id, item.isin);
    } else {
      setResponseText(`Please select valid record`);
      setShowPopUp(true);
      setflagPopUp("error");
    }
  };

  const addEndDate = (filters, startDateKey, endDateKey) => {
    let updatedFilters = JSON.parse(JSON.stringify(filters));
    if (updatedFilters[startDateKey] && !updatedFilters[endDateKey]) {
      updatedFilters[endDateKey] = getToDate(updatedFilters[startDateKey]);
    }
    return updatedFilters;
  };

  const setupQueryParams = (page = 1, pagesize = 100) => {
    const { ...restData } = selectedFilter;
    let renamedFilters = { ...restData };
    const filtersWithDate = addEndDate(renamedFilters, "dateFrom", "dateTo");
    return {
      ...filtersWithDate,
      page: page,
      pageSize: pagesize,
    };
  };

  const getFilteredData = (evt, filterData) => {
    setIsShowSearchTags(true);
    setLoading(true);
    for (var key in filterData) {
      if (filterData.hasOwnProperty(key)) {
        if (filterData[key] == "" || !filterData[key]) {
          delete filterData[key];
        }
      }
    }
    setQueryData(selectedFilter);
    if (page === 1) {
      setTriggerResetPage((triggerResetPage) => triggerResetPage + 1);
    }
    const queryParams = setupQueryParams(page, pageSize);
    if (queryParams) {
      fetchTransactions(queryParams);
      setExpandedSearch(false);
      setExpandedTable(true);
      evt.target.id !== "iss_icon_refreshTable" && setExpandedTable(true);
    }
  };

  const removeSearchTags = (e, tagkey) => {
    if (tagkey === "isin") {
      setIsin("");
    } else if (tagkey === "dateFrom") {
      setFromDate(null);
      setToDate(null);
    } else if (tagkey === "dateTo") {
      setToDate(null);
    } else if (tagkey === "errorCodes") {
      setSelectedErrorCodes([]);
    } else if (tagkey === "transactionTypes") {
      setSelectedTransactionType([]);
    } else if (tagkey === "transactionStatuses") {
      setSelectedTransactionStatus([]);
    } else if (tagkey === "submitterRoles") {
      setSelectedSubmitterRole([]);
    }
    delete selectedFilter[tagkey];
    setSelectedFilter(selectedFilter);
    getFilteredData(e, selectedFilter);
    e.stopPropagation();
  };

  const displaySearchTags = () => {
    return (
      <React.Fragment>
        {searchTags.map(([key, value], index) => {
          if (value) {
            let label = "";
            if (key === "isin") {
              label = "ISIN";
            } else if (key === "dateFrom") {
              label = `Date ${
                !dateSwitch && selectedFilter.dateTo ? "From" : "On"
              }`;
            } else if (key === "dateTo") {
              label = "Date To";
            } else if (key === "errorCodes") {
              label = "Error Codes";
            } else if (key === "submitterRoles") {
              label = "Submitter Roles";
            } else if (key === "transactionStatuses") {
              label = "Transaction Status";
              value = value
                .map((item) => getLabel(transactionStatusOptions, item))
                .join(", ");
            } else if (key === "transactionTypes") {
              label = "Transaction Type";
              value = value
                .map((item) => getLabel(transactionTypeOptions, item))
                .join(", ");
            }
            let tagLabel;
            if (key.toLocaleLowerCase().includes("date")) {
              const dateTime = value.split("T");
              const dateParts = dateTime[0].split("-");
              value =
                dateParts[2] +
                "/" +
                dateParts[1] +
                "/" +
                dateParts[0] +
                " " +
                dateTime[1];
            }
            if (typeof value == "string") {
              tagLabel = `${label}: ${value.replace(/_/g, " ")}`;
            } else {
              tagLabel = `${label}: ${value}`;
            }
            return (
              <SearchTags
                key={key}
                closeIcon={<CloseIcon style={{ fontSize: "1rem" }} />}
                closable={true}
                onClose={(e) => removeSearchTags(e, key)}
              >
                <span> {tagLabel}</span>
              </SearchTags>
            );
          }
        })}
      </React.Fragment>
    );
  };

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  const handleChangePage = (pageNumber, pageSize) => {
    const newNumOfPages = Math.ceil(itemsCount / pageSize);
    const newPage = pageNumber > newNumOfPages ? newNumOfPages : pageNumber;
    const queryParams = setupQueryParams(newPage, pageSize);
    fetchTransactions(queryParams);
  };

  const cancelFilter = () => {
    setSelectedFilter({});
    setIsin("");
    setSelectedErrorCodes([]);
    setData([]);
    setToDate(null);
    setFromDate(null);
    setSelectedSubmitterRole([]);
    setSelectedTransactionStatus([]);
    setSelectedTransactionType([]);
    setExpandedTable(false);
  };

  const getISODate = (dateString) => {
    return moment(flipDateTimeFormat(dateString)).utc(true).toISOString();
  };

  const getToDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DDTHH:mm:ssZ").add(1, "d").toISOString();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!searchDisabled) {
          getFilteredData(event, selectedFilter);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [getFilteredData, searchDisabled, selectedFilter]);

  return (
    <React.Fragment>
      <StyledContainer>
        <div>
          {responseText && showPopUp && (
            <div className="popup" id="iss_popup_response">
              {flagPopUp !== "error" ? (
                <SuccessPopup
                  successPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              ) : (
                <ErrorPopup
                  errorPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              )}
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
          <Grid container item xs={12}>
            <Grid item xs={11}>
              <Typography variant="h2" id="iss_typo_issuance">
                Transaction Store Query
              </Typography>
            </Grid>
          </Grid>
          <React.Fragment>
            <Grid
              container
              className="gridContainer"
              id="iss_accordion_gridContainer"
            >
              <Accordion expanded={expandedSearch}>
                <AccordionSummary
                  id="panelid-header"
                  onClick={(event) => {
                    if (
                      event.target.id !== "iss_buttonGroup_search" &&
                      event.target.id !== "iss_button_SearchButton" &&
                      event.target.id !== "iss_buttonGroup_cancel" &&
                      event.target.id !== "iss_button_cancelFilterButton" &&
                      event.target.className !== "MuiButton-label"
                    ) {
                      if (expandedSearch) {
                        setExpandedSearch(false);
                        setIsShowSearchTags(true);
                      } else {
                        setExpandedSearch(true);
                        setIsShowSearchTags(false);
                      }
                    }
                  }}
                >
                  <Grid container>
                    <Grid
                      container
                      xs={1}
                      id="iss_heading_gridContainer_searchWith"
                    >
                      <Typography
                        variant="h5"
                        className="pT-8"
                        id="iss_typo_pT_8"
                      >
                        Query :
                      </Typography>
                    </Grid>
                    <Grid
                      xs={isShowSearchTags ? 8 : 6}
                      ref={searchElementRef}
                      container
                      id="iss_grid_btnContainer"
                    >
                      {isShowSearchTags && (
                        <div style={{ "margin-top": "1px" }}>
                          {displaySearchTags()}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      xs={isShowSearchTags ? 3 : 5}
                      className="btnContainer"
                      id="iss_grid_btnContainer"
                    >
                      <div className="btnContainer" id="iss_div_btnContainer">
                        <ButtonGroup
                          aria-label="medium button group"
                          id="iss_buttonGroup_search"
                        >
                          <Button
                            variant="contained"
                            className="SearchButton"
                            disabled={searchDisabled}
                            size="medium"
                            id="iss_button_SearchButton"
                            data-testid="iss_button_SearchButton"
                            onClick={(evt) =>
                              getFilteredData(evt, selectedFilter)
                            }
                          >
                            Search
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup
                          aria-label="medium button group"
                          id="iss_buttonGroup_cancel"
                        >
                          <Button
                            variant="contained"
                            className="cancelFilterButton "
                            id="iss_button_cancelFilterButton"
                            onClick={() => cancelFilter()}
                          >
                            Reset
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <StyledSearchContainer>
                    <Box sx={{ width: "100%" }}>
                      <Grid container spacing={3}>
                        <Grid container xs={6}>
                          <Stack
                            spacing={2}
                            style={{
                              width: "100%",
                              borderRight: "1px solid #00a7c4",
                            }}
                          >
                            <Grid container>
                              <Grid xs={3}>
                                <Typography variant="h5" className="queryFeild">
                                  ISIN
                                </Typography>
                              </Grid>
                              <Grid xs={1}></Grid>
                              <Grid xs={8}>
                                <TextField
                                  placeholder="Enter Details"
                                  className="inputDetails inputEntry"
                                  variant="outlined"
                                  id="isinInput"
                                  onChange={(e) => {
                                    setIsin(e.target.value);
                                    setSelectedFilter({
                                      ...selectedFilter,
                                      isin: e.target.value,
                                    });
                                  }}
                                  value={isin || ""}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={3}>
                                <Typography variant="h5" className="queryFeild">
                                  Error Code
                                </Typography>
                              </Grid>
                              <Grid xs={1}></Grid>
                              <Grid xs={8}>
                                <MultiSelectDropdown
                                  isClearable={true}
                                  placeholder="Please Select"
                                  onChange={(option) => {
                                    setSelectedErrorCodes(option);
                                    option !== null
                                      ? setSelectedFilter({
                                          ...selectedFilter,
                                          errorCodes: option.map(
                                            (i) => i.value
                                          ),
                                        })
                                      : setSelectedFilter({
                                          ...selectedFilter,
                                          errorCodes: "",
                                        });
                                  }}
                                  className="searchOption queryDropdown"
                                  id="selectedCbfNo"
                                  options={errorCodes}
                                  value={selectedErrorCodes}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={3}>
                                <Typography variant="h5" className="queryFeild">
                                  Participant Role
                                </Typography>
                              </Grid>
                              <Grid xs={1}> </Grid>
                              <Grid container xs={8}>
                                <MultiSelectDropdown
                                  isClearable={true}
                                  placeholder="Please Select"
                                  onChange={(option) => {
                                    setSelectedSubmitterRole(option);
                                    option !== null
                                      ? setSelectedFilter({
                                          ...selectedFilter,
                                          submitterRoles: option.map(
                                            (i) => i.value
                                          ),
                                        })
                                      : setSelectedFilter({
                                          ...selectedFilter,
                                          submitterRoles: "",
                                        });
                                  }}
                                  className="searchOption queryDropdown"
                                  id="selectedSubmitterRole"
                                  value={selectedSubmitterRole}
                                  options={submitterRoles}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid container xs={6}>
                          <Stack
                            spacing={2}
                            style={{ width: "100%", paddingLeft: "30px" }}
                          >
                            <Grid container id="gridContainer_valueDate">
                              <Grid xs={3}>
                                <Typography
                                  variant="h5"
                                  className="queryFeild"
                                  id="typo_queryField_valueDate"
                                >
                                  Date
                                </Typography>
                              </Grid>
                              <Grid xs={1}>
                                <img
                                  src={dateSwitch ? notArray : ArrayIcon}
                                  onClick={() => {
                                    dateSwitch
                                      ? setDateSwitch(false)
                                      : setDateSwitch(true);
                                  }}
                                  alt="calendar"
                                  className="queryFeild"
                                  id="icon_queryField_valueDate"
                                />
                              </Grid>
                              <Grid
                                container
                                xs={8}
                                className="queryDataPicker"
                                id="grid_on_queryDataPicker_valueDate"
                              >
                                {dateSwitch ? (
                                  <React.Fragment>
                                    <Grid item xs={10}>
                                      <Row>
                                        <Col md={2} className="paddingToR">
                                          <Typography
                                            variant="h6"
                                            className="fromTo"
                                            id="iss_typo_on_queryDataPicker_valueDate"
                                          >
                                            On
                                          </Typography>
                                        </Col>
                                        <Col md={10}>
                                          <DatePicker
                                            showNow={false}
                                            id="onDate"
                                            format={dateFormat}
                                            placeholder={dateFormat}
                                            showTime={true}
                                            className="queryFromTo inputEntry"
                                            suffixIcon={
                                              <img
                                                src={calendarIcon}
                                                alt="calendar"
                                              />
                                            }
                                            onChange={(date, dateString) => {
                                              setToDate(null);
                                              setFromDate(
                                                date !== null ? date : ""
                                              );
                                              date !== null
                                                ? setSelectedFilter({
                                                    ...selectedFilter,
                                                    dateFrom:
                                                      getISODate(dateString),
                                                  })
                                                : setSelectedFilter({
                                                    ...selectedFilter,
                                                    dateFrom: "",
                                                    dateTo: "",
                                                  });
                                            }}
                                            value={
                                              fromDate !== null ? fromDate : ""
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Grid>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <Grid container spacing={1}>
                                      <Grid item xs={10}>
                                        <Row>
                                          <Col md={2} className="paddingToR">
                                            <Typography
                                              variant="h6"
                                              className="fromTo"
                                              id="iss_typo_pT-8_from_1"
                                            >
                                              From
                                            </Typography>
                                          </Col>
                                          <Col md={10}>
                                            <DatePicker
                                              data-testid="fromDate"
                                              id="fromDate"
                                              showNow={false}
                                              format={dateFormat}
                                              placeholder={dateFormat}
                                              showTime={true}
                                              className="queryFromTo inputEntry"
                                              suffixIcon={
                                                <img
                                                  src={calendarIcon}
                                                  alt="calendar"
                                                />
                                              }
                                              onChange={(date, dateString) => {
                                                setToDate(null);
                                                setFromDate(
                                                  date !== null ? date : ""
                                                );
                                                date !== null
                                                  ? setSelectedFilter({
                                                      ...selectedFilter,
                                                      dateFrom:
                                                        getISODate(dateString),
                                                    })
                                                  : setSelectedFilter({
                                                      ...selectedFilter,
                                                      dateFrom: "",
                                                      dateTo: "",
                                                    });
                                              }}
                                              value={
                                                fromDate !== null
                                                  ? fromDate
                                                  : ""
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Row>
                                          <Col md={2} className="paddingToR">
                                            <Typography
                                              variant="h6"
                                              className="fromTo"
                                              id="iss_typo_pT_8_to_1"
                                            >
                                              To
                                            </Typography>
                                          </Col>
                                          <Col md={10}>
                                            <DatePicker
                                              data-testid="toDate"
                                              id="toDate"
                                              className="queryFromTo inputEntry"
                                              showNow={false}
                                              format={dateFormat}
                                              placeholder={dateFormat}
                                              showTime={true}
                                              disabledDate={(event) =>
                                                fromDate > event
                                              }
                                              suffixIcon={
                                                <img
                                                  src={calendarIcon}
                                                  alt="calendar"
                                                />
                                              }
                                              onChange={(date, dateString) => {
                                                setToDate(
                                                  date !== null ? date : ""
                                                );
                                                date !== null
                                                  ? setSelectedFilter({
                                                      ...selectedFilter,
                                                      dateTo:
                                                        getISODate(dateString),
                                                    })
                                                  : setSelectedFilter({
                                                      ...selectedFilter,
                                                      dateTo: "",
                                                    });
                                              }}
                                              value={
                                                toDate !== null ? toDate : ""
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={3}>
                                <Typography variant="h5" className="queryFeild">
                                  Transaction Type
                                </Typography>
                              </Grid>
                              <Grid xs={1}></Grid>
                              <Grid xs={8}>
                                <MultiSelectDropdown
                                  isClearable={true}
                                  placeholder="Please Select"
                                  onChange={(option) => {
                                    setSelectedTransactionType(option);
                                    option !== null
                                      ? setSelectedFilter({
                                          ...selectedFilter,
                                          transactionTypes: option.map(
                                            (i) => i.value
                                          ),
                                        })
                                      : setSelectedFilter({
                                          ...selectedFilter,
                                          transactionTypes: "",
                                        });
                                  }}
                                  className="searchOption queryDropdown"
                                  id="selectedTransactionType"
                                  options={transactionTypeOptions}
                                  value={selectedTransactionType}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid xs={3}>
                                <Typography variant="h5" className="queryFeild">
                                  Transaction Status
                                </Typography>
                              </Grid>
                              <Grid xs={1}></Grid>
                              <Grid xs={8}>
                                <MultiSelectDropdown
                                  isClearable={true}
                                  placeholder="Please Select"
                                  onChange={(option) => {
                                    setSelectedTransactionStatus(option);
                                    option !== null
                                      ? setSelectedFilter({
                                          ...selectedFilter,
                                          transactionStatuses: option.map(
                                            (i) => i.value
                                          ),
                                        })
                                      : setSelectedFilter({
                                          ...selectedFilter,
                                          transactionStatuses: "",
                                        });
                                  }}
                                  className="searchOption queryDropdown"
                                  id="selectedTransactionStatus"
                                  options={transactionStatusOptions}
                                  value={selectedTransactionStatus}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </StyledSearchContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <StyledTableContainer>
              <Grid container className="gridContainer">
                <Accordion expanded={expandedTable}>
                  <AccordionSummary
                    id="panel1d-header"
                    onClick={(event) => {
                      if (
                        event.target.id !== "iss_icon_updateIssuance" &&
                        event.target.id !== "iss_icon_viewdetails" &&
                        event.target.id !== "iss_icon_imgViewDetails" &&
                        event.target.id !== "iss_icon_refreshTable" &&
                        event.target.className !== "MuiButton-label"
                      ) {
                        if (expandedTable) setExpandedTable(false);
                        else {
                          setExpandedTable(true);
                        }
                      }
                    }}
                  >
                    <Grid container>
                      <Grid
                        container
                        xs={5}
                        id="iss_heading_gridContainer_mostRecent"
                      >
                        <Grid item xs={2}>
                          <Typography
                            variant="h5"
                            className="pT-8 "
                            id="iss_typo_mostRecent"
                          >
                            Log
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className="pL-10"
                        id="iss_grid_iss_typo_mostRecent"
                      ></Grid>
                      {expandedTable && (
                        <Grid
                          xs={5}
                          className="btnContainer"
                          id="iss_grid_mostRecent_btnContainer"
                        >
                          <div
                            className="btnContainer"
                            id="iss_div_mostRecent_btnContainer"
                          >
                            <Space>
                              <HtmlTooltip
                                id="iss_htmltooltip_viewDetails3"
                                title={
                                  <React.Fragment>
                                    <Typography
                                      variant="h5"
                                      id="iss_typo_refresh"
                                    >
                                      Refresh
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="top"
                              >
                                <img
                                  src={refresh}
                                  alt="details"
                                  onClick={(evt) => {
                                    setTriggerResetPage(
                                      (triggerResetPage) => triggerResetPage + 1
                                    );
                                    getFilteredData(evt, selectedFilter);
                                  }}
                                  className="greyIcon tableTabItem"
                                  id="iss_icon_refreshTable"
                                />
                              </HtmlTooltip>
                            </Space>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RecordsTable
                      loading={loading}
                      columns={columns}
                      data={data}
                      onPageChange={handleChangePage}
                      itemsCount={itemsCount}
                      onClick={handleClick}
                      isSelected={isSelected}
                      onPDFDownload={downloadPDFbyId}
                      onXMLDownload={downloadXMLbyId}
                      resetPage={triggerResetPage}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </StyledTableContainer>
          </React.Fragment>
        </div>
      </StyledContainer>
    </React.Fragment>
  );
};

export default TransactionStore;
