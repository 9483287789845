import React, { useState, useEffect, useCallback } from "react";
import { AccountListsContainer } from "./index.css";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import PaginationContainer from "../../components/Paginator";
import NoData from "../../components/NoData/NoData";
import { getApprovementStatusClass } from "../../utils/StatusUtils";
import filterIcon from "../../assets/FilterIcon.svg";
import { DatePicker } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";
import { withStyles } from "@material-ui/core/styles";
import { MultiSelect } from "react-multi-select-component";
import Tooltip from "@material-ui/core/Tooltip";
import { getComparator, stableSort } from "../../components/Sorting";
import { DATE_FORMATS } from "../../constants";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ListTable = ({
  data,
  columns,
  onRowClick,
  onPageChange,
  itemsCount,
  loading,
}) => {
  const searchList = columns.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: "" }),
    {}
  );
  const defaultOptions = columns.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: [] }),
    {}
  );
  const [applyFilter, setApplyFilter] = useState(false);
  const [order, setOrder] = useState("asc"); //default sorting is set to ascending order
  const [orderBy, setOrderBy] = useState("");
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);
  const [searchArray, setSearchArray] = useState([]);
  const [searchValue, setSearchValue] = useState(searchList);
  const [optionsArray, setOptionsArray] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const removeAppliedFilter = () => {
    setSelectedColumnOption(defaultOptions);
    setSearchArray([]);
    setSearchValue(searchList);
    setFilteredData(data);
  };

  const getOptionList = (baseData, columnName) => {
    let arr = [];
    baseData.map((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
      return arr;
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({ value: elem, label: elem });
      return returnList;
    });
    return returnList;
  };

  const createFilterOptions = useCallback((baseData) => {
    let columnNames = baseData.length !== 0 ? Object.keys(baseData[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(baseData, elem);
    });
    setOptionsArray(optionsArrList);
  }, []);

  useEffect(() => {
    createFilterOptions(data);
    setFilteredData(data);
  }, [createFilterOptions, data]);

  const filterTableData = (event) => {
    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }
    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue); //Updating searchValue object with column id and search value
    let newData = [...data];
    searchArray.forEach((column) => {
      //Iterate each column and check if any filter applied to that column and update the table data with the search
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;
      tempNewData = newData.filter((row) => {
        if (value.length === 0) {
          return true;
        } else {
          return value.includes(row[column]);
        }
      });
      newData = tempNewData;
    });
    setFilteredData(newData);
  };

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <AccountListsContainer style={{ maxHeight: "auto" }}>
        <Table
          aria-label="sticky table"
          stickyHeader
          id="TableView"
          data-testid="TableView"
        >
          <TableHead>
            <TableRow>
              {filteredData.length > 0 && (
                <TableCell
                  className="tableCellBorder"
                  id="iss_tablecell_tableCellBorder"
                >
                  {/* {!selectedOption ? */}
                  <HtmlTooltip
                    id="iss_htmltooltip_applyfilter"
                    title={
                      <React.Fragment>
                        <Typography variant="h5">
                          {applyFilter !== true
                            ? "Add Filters"
                            : "Remove Filters"}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span className="filterIcon" id="iss_span_filterIcon">
                      <img
                        src={filterIcon}
                        alt="triangle"
                        id="iss_img_applyTableFilter"
                        onClick={() => {
                          if (applyFilter) {
                            removeAppliedFilter();
                          }
                          setApplyFilter(!applyFilter);
                        }}
                      />
                    </span>
                  </HtmlTooltip>
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell
                  className="cellBorder"
                  id={`label_${index}`}
                  key={column.key}
                >
                  <TableSortLabel
                    style={{
                      width: column.width,
                      paddingLeft: "12px",
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                    id={`iss_tableSortLabel_sortHandler_${column.id}`}
                    disabled={column.id === "timer"}
                  >
                    <Typography
                      variant="h5"
                      className="lH-17"
                      id={`iss_typo_sorting_${column.id}`}
                      style={{ marginBottom: ".5rem" }}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <span className="visuallyhidden">
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </Typography>
                  </TableSortLabel>
                  {applyFilter &&
                    (column.id !== "isin" ? (
                      column.type === "date" ? (
                        <DatePicker
                          showNow={false}
                          format={DATE_FORMATS.DATE}
                          id={`iss_date_filter_${column.id}`}
                          placeholder={DATE_FORMATS.DATE}
                          onChange={(date, dateString) => {
                            filterTableData({
                              target: {
                                id: column.id,
                                value: date !== null ? dateString : "",
                              },
                            });
                          }}
                          suffixIcon={
                            <img
                              src={calendarIcon}
                              alt="calendar"
                              className="iconColumn"
                            />
                          }
                          className="datePickerColumn"
                        />
                      ) : (
                        <MultiSelect
                          style={{ overflow: "visible" }}
                          className="selectColumn"
                          options={optionsArray[column.id] || []}
                          id={`iss_multiselect_column_${column.id}`}
                          onChange={(option) => {
                            let arr = [];
                            option.map((elem) => arr.push(elem.value));
                            let optionSelected = selectedColumnOption;
                            optionSelected[column.id] = option;
                            setSelectedColumnOption(optionSelected);
                            filterTableData({
                              target: {
                                id: column.id,
                                value: arr,
                              },
                            });
                          }}
                          value={selectedColumnOption[column.id] || []}
                        />
                      )
                    ) : (
                      <TextField
                        className="searchbarColumn"
                        fullWidth
                        placeholder="Enter"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={(event) => {
                          filterTableData(event);
                        }}
                        id={column.id}
                      ></TextField>
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.length === 0 ? (
              <NoData loading={loading} />
            ) : (
              <>
                {filteredData &&
                  filteredData.length &&
                  stableSort(filteredData, getComparator(order, orderBy))?.map(
                    (row, index) => {
                      let statusStyle = getApprovementStatusClass(
                        row.statusName
                      );
                      return (
                        <TableRow
                          key={index}
                          hover
                          onDoubleClick={
                            onRowClick ? () => onRowClick(row.id) : null
                          }
                          id={`tableRow_${index}`}
                          role="checkbox"
                          data-testid={`tableRowId_${index}`}
                          tabIndex={-1}
                          className={`${
                            index % 2 ? "FFFFFFColor" : "F7F7F7Color"
                          }`} //passing className to alternate rows for stylling
                        >
                          <TableCell></TableCell>
                          {Object.keys(row).map((key) => {
                            if (key !== "id" && key !== "statusStyle") {
                              return (
                                <TableCell>
                                  <Typography
                                    variant="subtitle1"
                                    id={`approvals_uploader_${index}`}
                                    className="lH-17"
                                    style={
                                      key === "statusName" ||
                                      key === "changeType"
                                        ? statusStyle
                                        : {}
                                    }
                                  >
                                    {row[key]}
                                  </Typography>
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </AccountListsContainer>
      {itemsCount && (
        <PaginationContainer
          itemsCount={itemsCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default ListTable;
