import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const EligibilityCheck = ({
  handleChange,
  expandState,
  isDesktop,
  issuanceObj,
}) => (
  <Accordion
    className="accordianStyle"
    id="det_clear_accordion"
    expanded={expandState["panel1"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      onClick={() => {
        handleChange("panel1");
      }}
    >
      <Typography variant="h3" id="det_clear_eligibility">
        Eligibility Check
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="det_clear_accDetials">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Security Type"}
          value={issuanceObj.cbfSecurityType}
        />
        <RowElement label={"Issuer Name"} value={issuanceObj.issuer} />
        <RowElement label={"Value Date"} value={issuanceObj.valueDate} />
        <RowElement label={"ISIN"} value={issuanceObj.isin} />
        <RowElement label={"WKN"} value={issuanceObj.wkn} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Issued Nominal Amount"}
          value={issuanceObj.issuedNominalAmount}
        />
        <RowElement label={"UpTo Nominal"} value={issuanceObj.upToNominal} />
        <RowElement label={"Maturity Date"} value={issuanceObj.maturityDate} />
        <RowElement label={"Option Type"} value={issuanceObj.optionsType} />
        <RowElement label={"Instrument Name"} value={issuanceObj.productName} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Redemption Option"}
          value={issuanceObj.settlementType}
        />
        <RowElement
          label={"Issue Currency"}
          value={issuanceObj.nominalCurrency}
        />
        <RowElement
          label={"Minimum Denomination"}
          value={issuanceObj.minTransferAmount}
        />
        <RowElement
          label={"Applicable Law"}
          value={issuanceObj.applicableLaw}
        />
        <RowElement
          label={"Underlying ISIN"}
          value={issuanceObj.underlyingIsin}
        />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default EligibilityCheck;
