/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ChangesetStatus.
* @enum {}
* @readonly
*/
export default class ChangesetStatus {
    
        /**
         * value: "TO_REVIEW"
         * @const
         */
        "TO_REVIEW" = "TO_REVIEW";

    
        /**
         * value: "APPROVED"
         * @const
         */
        "APPROVED" = "APPROVED";

    
        /**
         * value: "REJECTED"
         * @const
         */
        "REJECTED" = "REJECTED";

    
        /**
         * value: "null"
         * @const
         */
        "null" = "null";

    

    /**
    * Returns a <code>ChangesetStatus</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ChangesetStatus} The enum <code>ChangesetStatus</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

