/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AutomatedTask from './AutomatedTask';
import ManualTask from './ManualTask';

/**
 * The ProcessingDetail model module.
 * @module model/ProcessingDetail
 * @version 1.0.3
 */
class ProcessingDetail {
    /**
     * Constructs a new <code>ProcessingDetail</code>.
     * @alias module:model/ProcessingDetail
     */
    constructor() { 
        
        ProcessingDetail.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProcessingDetail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProcessingDetail} obj Optional instance to populate.
     * @return {module:model/ProcessingDetail} The populated <code>ProcessingDetail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProcessingDetail();

            if (data.hasOwnProperty('StepName')) {
                obj['StepName'] = ApiClient.convertToType(data['StepName'], 'String');
            }
            if (data.hasOwnProperty('Direction')) {
                obj['Direction'] = ApiClient.convertToType(data['Direction'], 'String');
            }
            if (data.hasOwnProperty('Timestamp')) {
                obj['Timestamp'] = ApiClient.convertToType(data['Timestamp'], 'Date');
            }
            if (data.hasOwnProperty('Result')) {
                obj['Result'] = ApiClient.convertToType(data['Result'], 'String');
            }
            if (data.hasOwnProperty('AutomatedTask')) {
                obj['AutomatedTask'] = AutomatedTask.constructFromObject(data['AutomatedTask']);
            }
            if (data.hasOwnProperty('ManualTask')) {
                obj['ManualTask'] = ManualTask.constructFromObject(data['ManualTask']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProcessingDetail</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProcessingDetail</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['StepName'] && !(typeof data['StepName'] === 'string' || data['StepName'] instanceof String)) {
            throw new Error("Expected the field `StepName` to be a primitive type in the JSON string but got " + data['StepName']);
        }
        // ensure the json data is a string
        if (data['Direction'] && !(typeof data['Direction'] === 'string' || data['Direction'] instanceof String)) {
            throw new Error("Expected the field `Direction` to be a primitive type in the JSON string but got " + data['Direction']);
        }
        // ensure the json data is a string
        if (data['Result'] && !(typeof data['Result'] === 'string' || data['Result'] instanceof String)) {
            throw new Error("Expected the field `Result` to be a primitive type in the JSON string but got " + data['Result']);
        }
        // validate the optional field `AutomatedTask`
        if (data['AutomatedTask']) { // data not null
          AutomatedTask.validateJSON(data['AutomatedTask']);
        }
        // validate the optional field `ManualTask`
        if (data['ManualTask']) { // data not null
          ManualTask.validateJSON(data['ManualTask']);
        }

        return true;
    }


}



/**
 * 
 * @member {String} StepName
 */
ProcessingDetail.prototype['StepName'] = undefined;

/**
 * 
 * @member {String} Direction
 */
ProcessingDetail.prototype['Direction'] = undefined;

/**
 * 
 * @member {Date} Timestamp
 */
ProcessingDetail.prototype['Timestamp'] = undefined;

/**
 * 
 * @member {String} Result
 */
ProcessingDetail.prototype['Result'] = undefined;

/**
 * @member {module:model/AutomatedTask} AutomatedTask
 */
ProcessingDetail.prototype['AutomatedTask'] = undefined;

/**
 * @member {module:model/ManualTask} ManualTask
 */
ProcessingDetail.prototype['ManualTask'] = undefined;






export default ProcessingDetail;

