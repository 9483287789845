import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ViewDetailsContainer } from "./index.css";
import ListTable from "./ListTable";
import MessagePopup from "./MessagePopup";
import { accountItemColumns } from "../../utils/columnUtils";
import { AccountsInternalApiClient } from "../../api";
import { saveAs } from "file-saver";
import { Space } from "antd";

const AccountListData = () => {
  const history = useHistory();
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [data, setData] = useState([]);
  const [itemsCount, setItemsCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    history.replace(`/accountlist`, { state: {} });
    let cols = accountItemColumns.filter(
      (obj) => obj.id !== "changeType" && obj.id !== "protocol"
    );
    setColumns(cols);
    fetchAccountListData();
  }, []);

  const onPageChange = (page, pageSize) => {
    const newNumOfPages = Math.ceil(itemsCount / pageSize);
    const newPage = page > newNumOfPages ? newNumOfPages : page;

    fetchAccountListData(newPage, pageSize);
  };

  const fetchAccountListData = (page = 1, pagesize = 100) => {
    const paginationParam = { page, pagesize };
    AccountsInternalApiClient.getAccounts(paginationParam)
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          let responseData;
          if (Array.isArray(response?.data)) {
            responseData = [...response?.data];
          } else if (typeof response?.data === "object") {
            responseData = [...response?.data?.items];
          }
          if (responseData?.length) {
            let data = [];
            responseData?.sort(function (a, b) {
              return new Date(b.timestamp) - new Date(a.timestamp);
            });
            responseData?.forEach((element) => {
              let obj = {
                issuerName: element.issuerName ?? "",
                issuerLei: element.issuerLei ?? "",
                issuerAccountNo: element.issuerAccountNo ?? "",
                issuerAgentName: element.issuerAgentName ?? "",
                issuerAgentAccountNo: element.issuerAgentAccountNo ?? "",
                payAgentAccountNo: element.payAgentAccountNo ?? "",
                cbfAktEmitCode: element.cbfAktEmitCode ?? "",
              };
              data.push(obj);
            });
            setData(data);
            setItemsCount(response?.data?.count);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const exportAccountlistExcel = () => {
    AccountsInternalApiClient.exportAccountRecords("xlsx")
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `AccountListRecords.xlsx`);
          setResponseText("Excel downloaded successfully");
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
        } else {
          setResponseText(
            "Unable to download accounts records. Please try later"
          );
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to download accounts records.Please try later");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const exportAccountlistCSV = () => {
    AccountsInternalApiClient.exportAccountRecords("csv")
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `AccountListRecords.csv`);
          document.body.appendChild(link);
          link.click();
          setResponseText("CSV downloaded successfully");
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
        } else {
          setResponseText(
            "Unable to download accounts records.Please try later"
          );
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to download accounts records.Please try later");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const exportAccountlistJSON = () => {
    AccountsInternalApiClient.exportAccountRecords("json")
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `AccountListRecords.json`);
          document.body.appendChild(link);
          link.click();
          setResponseText("JSON downloaded successfully");
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
        } else {
          setResponseText(
            "Unable to download accounts records.Please try later"
          );
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to download accounts records.Please try later");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };
  return (
    <>
      <ViewDetailsContainer>
        <MessagePopup
          responseText={responseText}
          showPopUp={showPopUp}
          flagPopUp={flagPopUp}
          closeErrorSuccessPopup={closeErrorSuccessPopup}
        />

        <Grid container>
          <Grid item xs={2} align="start">
            {/* Display the last uploaded date time here */}
          </Grid>
          <Grid item xs={10} align="end">
            <Space>
              <Button
                variant="outlined"
                id="btn_uploadBlackList"
                color="primary"
                className="btnActionsTop"
                onClick={() => exportAccountlistExcel()}
              >
                {" "}
                <FileDownloadIcon /> Excel
              </Button>
              <Button
                variant="outlined"
                id="btn_uploadBlackList"
                color="primary"
                className="btnActionsTop"
                onClick={() => exportAccountlistCSV()}
              >
                {" "}
                <FileDownloadIcon /> CSV
              </Button>
              <Button
                variant="outlined"
                id="btn_uploadBlackList"
                color="primary"
                className="btnActionsTop"
                onClick={() => exportAccountlistJSON(true)}
              >
                {" "}
                <FileDownloadIcon /> JSON
              </Button>
            </Space>
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      {data && (
        <ListTable
          loading={loading}
          data={data}
          columns={columns}
          onPageChange={onPageChange}
          itemsCount={itemsCount}
        />
      )}
    </>
  );
};

export default AccountListData;
