/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ComplianceChangeset from '../model/ComplianceChangeset';
import ComplianceChangesetResponse from '../model/ComplianceChangesetResponse';
import ComplianceChangesetsData from '../model/ComplianceChangesetsData';
import CompliancePendingCountItems from '../model/CompliancePendingCountItems';
import ComplianceProtocolData from '../model/ComplianceProtocolData';
import ComplianceRecordsData from '../model/ComplianceRecordsData';
import ComplianceStatus from '../model/ComplianceStatus';
import ComplianceType from '../model/ComplianceType';
import Error from '../model/Error';
import UploadDate from '../model/UploadDate';

/**
* Compliance service.
* @module api/ComplianceApi
* @version 1.0.3
*/
export default class ComplianceApi {

    /**
    * Constructs a new ComplianceApi. 
    * @alias module:api/ComplianceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addNewComplianceChangeSet operation.
     * @callback module:api/ComplianceApi~addNewComplianceChangeSetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ComplianceChangesetResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to upload changesets.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @param {module:api/ComplianceApi~addNewComplianceChangeSetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ComplianceChangesetResponse}
     */
    addNewComplianceChangeSet(type, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling addNewComplianceChangeSet");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = ComplianceChangesetResponse;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportComplianceChangeset operation.
     * @callback module:api/ComplianceApi~exportComplianceChangesetCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to export CSV file.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {String} id Specific change set should be accessible by its id.
     * @param {module:api/ComplianceApi~exportComplianceChangesetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportComplianceChangeset(type, id, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling exportComplianceChangeset");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling exportComplianceChangeset");
      }

      let pathParams = {
        'type': type,
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets/{id}/export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportComplianceRecords operation.
     * @callback module:api/ComplianceApi~exportComplianceRecordsCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to export csv file with list of records.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {module:api/ComplianceApi~exportComplianceRecordsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportComplianceRecords(type, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling exportComplianceRecords");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['test/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/records-export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportComplianceTemplate operation.
     * @callback module:api/ComplianceApi~exportComplianceTemplateCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to export csv file with template csv file.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {module:api/ComplianceApi~exportComplianceTemplateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportComplianceTemplate(type, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling exportComplianceTemplate");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets-template', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getComplianceChangesetDetails operation.
     * @callback module:api/ComplianceApi~getComplianceChangesetDetailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ComplianceChangeset} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get specific ChangesetItem and it's full details.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {String} id Changeset can be queried using the Unique Identifier - id
     * @param {module:api/ComplianceApi~getComplianceChangesetDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ComplianceChangeset}
     */
    getComplianceChangesetDetails(type, id, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getComplianceChangesetDetails");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getComplianceChangesetDetails");
      }

      let pathParams = {
        'type': type,
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ComplianceChangeset;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getComplianceChangesetProtocol operation.
     * @callback module:api/ComplianceApi~getComplianceChangesetProtocolCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ComplianceProtocolData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get protocol for changeset.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {String} id Changeset can be queried using the Unique Identifier - id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/ComplianceApi~getComplianceChangesetProtocolCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ComplianceProtocolData}
     */
    getComplianceChangesetProtocol(type, id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getComplianceChangesetProtocol");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getComplianceChangesetProtocol");
      }

      let pathParams = {
        'type': type,
        'id': id
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ComplianceProtocolData;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets/{id}/records', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getComplianceChangesets operation.
     * @callback module:api/ComplianceApi~getComplianceChangesetsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ComplianceChangesetsData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get paginated list of all ComplianceListItems that needs to be revised.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:model/ComplianceStatus} opts.status This parameter is used for filtering based on the status of the listed changeset items.
     * @param {module:api/ComplianceApi~getComplianceChangesetsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ComplianceChangesetsData}
     */
    getComplianceChangesets(type, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getComplianceChangesets");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize'],
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ComplianceChangesetsData;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCompliancePendingCount operation.
     * @callback module:api/ComplianceApi~getCompliancePendingCountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompliancePendingCountItems} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return the count of the compliance pending items.
     * @param {module:api/ComplianceApi~getCompliancePendingCountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CompliancePendingCountItems}
     */
    getCompliancePendingCount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompliancePendingCountItems;
      return this.apiClient.callApi(
        '/di/v1/compliance/pending/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getComplianceTime operation.
     * @callback module:api/ComplianceApi~getComplianceTimeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UploadDate} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get when was the last time that a changeset was added.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {module:api/ComplianceApi~getComplianceTimeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UploadDate}
     */
    getComplianceTime(type, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getComplianceTime");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UploadDate;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets/time', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPendingComplianceChangesets operation.
     * @callback module:api/ComplianceApi~getPendingComplianceChangesetsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ComplianceChangeset>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get list of pending ComplianceListItems.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {module:api/ComplianceApi~getPendingComplianceChangesetsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ComplianceChangeset>}
     */
    getPendingComplianceChangesets(type, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getPendingComplianceChangesets");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ComplianceChangeset];
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/pending/reviews', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRecords operation.
     * @callback module:api/ComplianceApi~getRecordsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ComplianceRecordsData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get paginated list of all ComplianceRecordItems that needs to be revised.
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/ComplianceApi~getRecordsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ComplianceRecordsData}
     */
    getRecords(type, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getRecords");
      }

      let pathParams = {
        'type': type
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ComplianceRecordsData;
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/records', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateComplianceChangesetById operation.
     * @callback module:api/ComplianceApi~updateComplianceChangesetByIdCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to update specific ChangesetItem
     * @param {module:model/ComplianceType} type This parameter is used for filtering based on the type of the listed changeset items.
     * @param {String} id Changeset can be selected using the Unique Identifier - id
     * @param {String} status Defines the new status of the Changeset approved/rejected
     * @param {module:api/ComplianceApi~updateComplianceChangesetByIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    updateComplianceChangesetById(type, id, status, callback) {
      let postBody = null;
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling updateComplianceChangesetById");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateComplianceChangesetById");
      }
      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling updateComplianceChangesetById");
      }

      let pathParams = {
        'type': type,
        'id': id
      };
      let queryParams = {
        'status': status
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/di/v1/compliance/{type}/changesets/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
