/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SecuritiesSettlement from './SecuritiesSettlement';

/**
 * The Redemption model module.
 * @module model/Redemption
 * @version 1.0.3
 */
class Redemption {
    /**
     * Constructs a new <code>Redemption</code>.
     * @alias module:model/Redemption
     */
    constructor() { 
        
        Redemption.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Redemption</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Redemption} obj Optional instance to populate.
     * @return {module:model/Redemption} The populated <code>Redemption</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Redemption();

            if (data.hasOwnProperty('BarrierBreachDate')) {
                obj['BarrierBreachDate'] = ApiClient.convertToType(data['BarrierBreachDate'], 'Date');
            }
            if (data.hasOwnProperty('PaymentDate')) {
                obj['PaymentDate'] = ApiClient.convertToType(data['PaymentDate'], 'Date');
            }
            if (data.hasOwnProperty('AuctionPrice')) {
                obj['AuctionPrice'] = ApiClient.convertToType(data['AuctionPrice'], 'Number');
            }
            if (data.hasOwnProperty('DeliveryType')) {
                obj['DeliveryType'] = ApiClient.convertToType(data['DeliveryType'], 'String');
            }
            if (data.hasOwnProperty('RedemptionPrice')) {
                obj['RedemptionPrice'] = ApiClient.convertToType(data['RedemptionPrice'], 'Number');
            }
            if (data.hasOwnProperty('PaymentCurrency')) {
                obj['PaymentCurrency'] = ApiClient.convertToType(data['PaymentCurrency'], 'String');
            }
            if (data.hasOwnProperty('RedemptionType')) {
                obj['RedemptionType'] = ApiClient.convertToType(data['RedemptionType'], 'String');
            }
            if (data.hasOwnProperty('RedemptionDate')) {
                obj['RedemptionDate'] = ApiClient.convertToType(data['RedemptionDate'], 'Date');
            }
            if (data.hasOwnProperty('FinalRedemptionRate')) {
                obj['FinalRedemptionRate'] = ApiClient.convertToType(data['FinalRedemptionRate'], 'Number');
            }
            if (data.hasOwnProperty('SecuritiesSettlement')) {
                obj['SecuritiesSettlement'] = ApiClient.convertToType(data['SecuritiesSettlement'], [SecuritiesSettlement]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Redemption</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Redemption</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['DeliveryType'] && !(typeof data['DeliveryType'] === 'string' || data['DeliveryType'] instanceof String)) {
            throw new Error("Expected the field `DeliveryType` to be a primitive type in the JSON string but got " + data['DeliveryType']);
        }
        // ensure the json data is a string
        if (data['PaymentCurrency'] && !(typeof data['PaymentCurrency'] === 'string' || data['PaymentCurrency'] instanceof String)) {
            throw new Error("Expected the field `PaymentCurrency` to be a primitive type in the JSON string but got " + data['PaymentCurrency']);
        }
        // ensure the json data is a string
        if (data['RedemptionType'] && !(typeof data['RedemptionType'] === 'string' || data['RedemptionType'] instanceof String)) {
            throw new Error("Expected the field `RedemptionType` to be a primitive type in the JSON string but got " + data['RedemptionType']);
        }
        if (data['SecuritiesSettlement']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['SecuritiesSettlement'])) {
                throw new Error("Expected the field `SecuritiesSettlement` to be an array in the JSON data but got " + data['SecuritiesSettlement']);
            }
            // validate the optional field `SecuritiesSettlement` (array)
            for (const item of data['SecuritiesSettlement']) {
                SecuritiesSettlement.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * Date of barrier breach (or touch)[103]
 * @member {Date} BarrierBreachDate
 */
Redemption.prototype['BarrierBreachDate'] = undefined;

/**
 * Planned final payment date known at the time of issuance.[95]
 * @member {Date} PaymentDate
 */
Redemption.prototype['PaymentDate'] = undefined;

/**
 * Auction Price is received in the final redemption instruction to indicate the redemption and coupon payment amount for Credit Linked Notes.[1]
 * @member {Number} AuctionPrice
 */
Redemption.prototype['AuctionPrice'] = undefined;

/**
 * Determines the Redemption type as either a cash settlement, physical settlement or cash and pyhsical[120]
 * @member {String} DeliveryType
 */
Redemption.prototype['DeliveryType'] = undefined;

/**
 * Price at which an instrument is redeemed[185]
 * @member {Number} RedemptionPrice
 */
Redemption.prototype['RedemptionPrice'] = undefined;

/**
 * 3 letter ISO 4127 code representing the currency in which payment is to be made.[186]
 * @member {String} PaymentCurrency
 */
Redemption.prototype['PaymentCurrency'] = undefined;

/**
 * Indicates the type of redemption. Mandatory.ISO 20022 MaturityRedemptionType1Code code which indicates the type of redemption (Final Redemption, Partial Redemption without reduction, Partial Redemption with reduction, Drawing at random, Drawing prorata, Call, Put). Mandatory.[392]
 * @member {String} RedemptionType
 */
Redemption.prototype['RedemptionType'] = undefined;

/**
 * Date at which redemption is planned to be processed.[572]
 * @member {Date} RedemptionDate
 */
Redemption.prototype['RedemptionDate'] = undefined;

/**
 * Redemption rate at the time of final redemption[533]
 * @member {Number} FinalRedemptionRate
 */
Redemption.prototype['FinalRedemptionRate'] = undefined;

/**
 * Model: SecuritiesSettlement
 * @member {Array.<module:model/SecuritiesSettlement>} SecuritiesSettlement
 */
Redemption.prototype['SecuritiesSettlement'] = undefined;






export default Redemption;

