import React, { useState, useEffect, useCallback } from "react";
import { ComplianceListsContainer } from "./index.css";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import PaginationContainer from "../../components/Paginator";
import NoData from "../../components/NoData/NoData";
import {
  getApprovementStatusClass,
  getApprovementStatusLabel,
} from "../../utils/StatusUtils";
import filterIcon from "../../assets/FilterIcon.svg";
import { DatePicker } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";
import { withStyles } from "@material-ui/core/styles";
import { MultiSelect } from "react-multi-select-component";
import Tooltip from "@material-ui/core/Tooltip";
import { getComparator, stableSort } from "../../components/Sorting";
import { DATE_FORMATS } from "../../constants";

const columns = [
  { id: "type", label: "Type" },
  { id: "statusName", label: "Status" },
  { id: "description", label: "Description" },
  { id: "user", label: "User" },
  { id: "timestamp", label: "Timestamp" },
  { id: "timer", label: "Timer" },
];

const defaultOptions = {
  type: [],
  statusName: [],
  description: [],
  user: [],
  timestamp: [],
  timer: [],
};
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const searchList = {
  type: "",
  statusName: "",
  description: "",
  user: "",
  timestamp: "",
  timer: "",
};
const ListTable = ({
  data,
  loading,
  setSelected,
  onRowClick,
  onPageChange,
  itemsCount,
  paginationEnabled = true,
  displayTypeColumn = true,
}) => {
  const [applyFilter, setApplyFilter] = useState(false);
  const [order, setOrder] = useState("asc"); //default sorting is set to ascending order
  const [orderBy, setOrderBy] = useState("");
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);
  const [searchArray, setSearchArray] = useState([]);
  const [searchValue, setSearchValue] = useState(searchList);
  const [optionsArray, setOptionsArray] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const removeAppliedFilter = () => {
    setSelectedColumnOption({
      type: [],
      statusName: [],
      description: [],
      user: [],
      timestamp: [],
      timer: [],
    });
    setSearchArray([]);
    setSearchValue(searchList);
    setFilteredData(data);
  };

  const getOptionList = (issuances, columnName) => {
    let arr = [];
    issuances.map((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
      return arr;
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({ value: elem, label: elem });
      return returnList;
    });
    return returnList;
  };

  const createFilterOptions = useCallback((baseData) => {
    let columnNames = baseData.length !== 0 ? Object.keys(baseData[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(baseData, elem);
    });
    setOptionsArray(optionsArrList);
  }, []);

  useEffect(() => {
    createFilterOptions(data);
    setFilteredData(data);
  }, [createFilterOptions, data]);

  const filterTableData = (event) => {
    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }
    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue); //Updating searchValue object with column id and search value
    let newData = [...data];
    searchArray.forEach((column) => {
      //Iterate each column and check if any filter applied to that column and update the table data with the search
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;
      tempNewData = newData.filter((row) => {
        if (value.length === 0) {
          return true;
        } else {
          return value.includes(row[column]);
        }
      });
      newData = tempNewData;
    });
    setFilteredData(newData);
  };

  return (
    <div>
      <ComplianceListsContainer style={{ maxHeight: "auto" }}>
        <Table
          aria-label="sticky table"
          stickyHeader
          id="isWhitelist_TableView"
          data-testid="TableView"
        >
          <TableHead>
            <TableRow>
              {filteredData.length > 0 && (
                <TableCell
                  className="cellBorder"
                  id="iss_tablecell_tableCellBorder"
                  key="filterCol"
                >
                  {/* {!selectedOption ? */}
                  <HtmlTooltip
                    id="iss_htmltooltip_applyfilter"
                    title={
                      <React.Fragment>
                        <Typography variant="h5">
                          {applyFilter !== true
                            ? "Add Filters"
                            : "Remove Filters"}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span className="filterIcon" id="iss_span_filterIcon">
                      <img
                        src={filterIcon}
                        alt="triangle"
                        id="iss_img_applyTableFilter"
                        onClick={() => {
                          if (applyFilter) {
                            removeAppliedFilter();
                          }
                          setApplyFilter(!applyFilter);
                        }}
                      />
                    </span>
                  </HtmlTooltip>
                </TableCell>
              )}
              {columns.map((column, index) => {
                if (column.id === "type") {
                  return (
                    displayTypeColumn && (
                      <TableCell
                        className="cellBorder"
                        id={`isWhitelist_label_${index}`}
                        key={column.key}
                      >
                        <TableSortLabel
                          style={{
                            width: column.width,
                            paddingLeft: "12px",
                          }}
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                          id={`iss_tableSortLabel_sortHandler_${column.id}`}
                          disabled={column.id === "timer"}
                        >
                          <Typography
                            variant="h5"
                            className="lH-17"
                            id={`iss_typo_sorting_${column.id}`}
                            style={{ marginBottom: ".5rem" }}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <span className="visuallyhidden">
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </Typography>
                        </TableSortLabel>
                        {applyFilter && (
                          <MultiSelect
                            style={{ overflow: "visible" }}
                            className="selectColumn"
                            options={optionsArray[column.id] || []}
                            id={`iss_multiselect_column_${column.id}`}
                            onChange={(option) => {
                              let arr = [];
                              option.map((elem) => arr.push(elem.value));
                              let optionSelected = selectedColumnOption;
                              optionSelected[column.id] = option;
                              setSelectedColumnOption(optionSelected);
                              filterTableData({
                                target: {
                                  id: column.id,
                                  value: arr,
                                },
                              });
                            }}
                            value={selectedColumnOption[column.id] || []}
                          />
                        )}
                      </TableCell>
                    )
                  );
                } else {
                  return (
                    <TableCell
                      className="cellBorder"
                      id={`isWhitelist_label_${index}`}
                      key={column.key}
                    >
                      <TableSortLabel
                        style={{
                          width: column.width,
                          paddingLeft: "12px",
                        }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                        id={`iss_tableSortLabel_sortHandler_${column.id}`}
                        disabled={column.id === "timer"}
                      >
                        <Typography
                          variant="h5"
                          className="lH-17"
                          id={`iss_typo_sorting_${column.id}`}
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <span className="visuallyhidden">
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </Typography>
                      </TableSortLabel>
                      {applyFilter &&
                        (column.id !== "isin" ? (
                          column.type === "date" ? (
                            <DatePicker
                              showNow={false}
                              format={DATE_FORMATS.DATE}
                              id={`iss_date_filter_${column.id}`}
                              placeholder={DATE_FORMATS.DATE}
                              onChange={(date, dateString) => {
                                filterTableData({
                                  target: {
                                    id: column.id,
                                    value: date !== null ? dateString : "",
                                  },
                                });
                              }}
                              suffixIcon={
                                <img
                                  src={calendarIcon}
                                  alt="calendar"
                                  className="iconColumn"
                                />
                              }
                              className="datePickerColumn"
                            />
                          ) : (
                            <MultiSelect
                              style={{ overflow: "visible" }}
                              className="selectColumn"
                              options={optionsArray[column.id] || []}
                              id={`iss_multiselect_column_${column.id}`}
                              onChange={(option) => {
                                let arr = [];
                                option.map((elem) => arr.push(elem.value));
                                let optionSelected = selectedColumnOption;
                                optionSelected[column.id] = option;
                                setSelectedColumnOption(optionSelected);
                                filterTableData({
                                  target: {
                                    id: column.id,
                                    value: arr,
                                  },
                                });
                              }}
                              value={selectedColumnOption[column.id] || []}
                            />
                          )
                        ) : (
                          <TextField
                            className="searchbarColumn"
                            fullWidth
                            placeholder="Enter"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            onChange={(event) => {
                              filterTableData(event);
                            }}
                            id={column.id}
                          ></TextField>
                        ))}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <NoData loading={loading} />
            ) : (
              <>
                {paginationEnabled
                  ? stableSort(filteredData, getComparator(order, orderBy)).map(
                      (row, index) => {
                        let statusClass = "status";
                        let statusStyle = getApprovementStatusClass(
                          row.statusName
                        );
                        return (
                          <TableRow
                            key={index}
                            hover
                            onClick={
                              setSelected
                                ? () =>
                                    setSelected({
                                      id: row.id,
                                      type: row.type,
                                      isin: row.description,
                                      uploaderUserId: row?.user,
                                    })
                                : null
                            }
                            onDoubleClick={
                              onRowClick
                                ? () =>
                                    onRowClick(
                                      row.id,
                                      row.type,
                                      row.description,
                                      row?.user
                                    )
                                : null
                            }
                            id={`isWhitelist_tableRow_${index}`}
                            role="checkbox"
                            data-testid={`isWhitelist_tableRowId_${index}`}
                            tabIndex={-1}
                            className={`${
                              index % 2 ? "FFFFFFColor" : "F7F7F7Color"
                            }`} //passing className to alternate rows for stylling
                          >
                            <TableCell></TableCell>
                            {displayTypeColumn && (
                              <TableCell>
                                <Typography
                                  variant="subtitle1"
                                  id={`isWhitelist_valueDate_${index}`}
                                  className="lH-17"
                                >
                                  {row.type}
                                </Typography>
                              </TableCell>
                            )}
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                id={`isWhitelist_valueDate_${index}`}
                                className={statusClass}
                                style={statusStyle}
                              >
                                {row.statusName}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                id={`isWhitelist_isin_${index}`}
                                className="lH-17"
                              >
                                {row.description}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                id={`isWhitelist_issuerName_${index}`}
                                className="lH-17"
                              >
                                {row.user}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                id={`isWhitelist_instrumentType_${index}`}
                                className="lH-17"
                              >
                                {row.timestamp}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                id={`isWhitelist_pendingSince_${index}`}
                                className="lH-17"
                              >
                                {row.timer}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )
                  : stableSort(
                      filteredData,
                      getComparator(order, orderBy)
                    )?.map((row, index) => {
                      let statusStyle = getApprovementStatusClass(
                        row.statusName
                      );
                      return (
                        <TableRow
                          key={index}
                          hover
                          onClick={
                            setSelected
                              ? () =>
                                  setSelected({
                                    id: row.id,
                                    type: row.type,
                                    isin: row.description,
                                    uploaderUserId: row.user,
                                  })
                              : null
                          }
                          onDoubleClick={
                            onRowClick
                              ? () =>
                                  onRowClick(
                                    row.id,
                                    row.type,
                                    row?.description,
                                    row?.user
                                  )
                              : null
                          }
                          id={`isWhitelist_tableRow_${index}`}
                          role="checkbox"
                          data-testid={`isWhitelist_tableRowId_${index}`}
                          tabIndex={-1}
                          className={`${
                            index % 2 ? "FFFFFFColor" : "F7F7F7Color"
                          }`} //passing className to alternate rows for stylling
                        >
                          <TableCell></TableCell>
                          {displayTypeColumn && (
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                id={`isWhitelist_valueDate_${index}`}
                                className="lH-17"
                              >
                                {row.type}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`isWhitelist_valueDate_${index}`}
                              style={statusStyle}
                            >
                              {getApprovementStatusLabel(
                                row.statusName ? row.statusName : row.status
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`isWhitelist_isin_${index}`}
                              className="lH-17"
                            >
                              {row.description}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`isWhitelist_issuerName_${index}`}
                              className="lH-17"
                            >
                              {row.user}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`isWhitelist_instrumentType_${index}`}
                              className="lH-17"
                            >
                              {row.timestamp}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              id={`isWhitelist_pendingSince_${index}`}
                              className="lH-17"
                            >
                              {row.timer}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </>
            )}
          </TableBody>
        </Table>
      </ComplianceListsContainer>
      {paginationEnabled && itemsCount !== 0 && (
        <PaginationContainer
          itemsCount={itemsCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default ListTable;
