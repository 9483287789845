/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import US from './US';

/**
 * The Tax model module.
 * @module model/Tax
 * @version 1.0.3
 */
class Tax {
    /**
     * Constructs a new <code>Tax</code>.
     * @alias module:model/Tax
     */
    constructor() { 
        
        Tax.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Tax</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Tax} obj Optional instance to populate.
     * @return {module:model/Tax} The populated <code>Tax</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Tax();

            if (data.hasOwnProperty('RedemptionCapitalGains')) {
                obj['RedemptionCapitalGains'] = ApiClient.convertToType(data['RedemptionCapitalGains'], 'Boolean');
            }
            if (data.hasOwnProperty('WithholdingTaxRegime')) {
                obj['WithholdingTaxRegime'] = ApiClient.convertToType(data['WithholdingTaxRegime'], 'String');
            }
            if (data.hasOwnProperty('US')) {
                obj['US'] = US.constructFromObject(data['US']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Tax</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Tax</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['WithholdingTaxRegime'] && !(typeof data['WithholdingTaxRegime'] === 'string' || data['WithholdingTaxRegime'] instanceof String)) {
            throw new Error("Expected the field `WithholdingTaxRegime` to be a primitive type in the JSON string but got " + data['WithholdingTaxRegime']);
        }
        // validate the optional field `US`
        if (data['US']) { // data not null
          US.validateJSON(data['US']);
        }

        return true;
    }


}



/**
 * WM Datenservice field VD052A Type ZaStTax.Amount  Taxable amt. Type (Redemption amount subject to capital gains)[400]
 * @member {Boolean} RedemptionCapitalGains
 */
Tax.prototype['RedemptionCapitalGains'] = undefined;

/**
 * Indicates the grossup default taxwithholding treatment (the applicable law) or the grossup clause.[404]
 * @member {String} WithholdingTaxRegime
 */
Tax.prototype['WithholdingTaxRegime'] = undefined;

/**
 * @member {module:model/US} US
 */
Tax.prototype['US'] = undefined;






export default Tax;

