/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GeneralRecordItem model module.
 * @module model/GeneralRecordItem
 * @version 1.0.3
 */
class GeneralRecordItem {
    /**
     * Constructs a new <code>GeneralRecordItem</code>.
     * @alias module:model/GeneralRecordItem
     */
    constructor() { 
        
        GeneralRecordItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>GeneralRecordItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GeneralRecordItem} obj Optional instance to populate.
     * @return {module:model/GeneralRecordItem} The populated <code>GeneralRecordItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GeneralRecordItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('underlyingISIN')) {
                obj['underlyingISIN'] = ApiClient.convertToType(data['underlyingISIN'], 'String');
            }
            if (data.hasOwnProperty('underlyingName')) {
                obj['underlyingName'] = ApiClient.convertToType(data['underlyingName'], 'String');
            }
            if (data.hasOwnProperty('issuerCountry')) {
                obj['issuerCountry'] = ApiClient.convertToType(data['issuerCountry'], 'String');
            }
            if (data.hasOwnProperty('entryDate')) {
                obj['entryDate'] = ApiClient.convertToType(data['entryDate'], 'Date');
            }
            if (data.hasOwnProperty('lE')) {
                obj['lE'] = ApiClient.convertToType(data['lE'], 'String');
            }
            if (data.hasOwnProperty('LEI')) {
                obj['LEI'] = ApiClient.convertToType(data['LEI'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('addedAt')) {
                obj['addedAt'] = ApiClient.convertToType(data['addedAt'], 'Date');
            }
            if (data.hasOwnProperty('addedByUserId')) {
                obj['addedByUserId'] = ApiClient.convertToType(data['addedByUserId'], 'String');
            }
            if (data.hasOwnProperty('addedByUserName')) {
                obj['addedByUserName'] = ApiClient.convertToType(data['addedByUserName'], 'String');
            }
            if (data.hasOwnProperty('reviewedAt')) {
                obj['reviewedAt'] = ApiClient.convertToType(data['reviewedAt'], 'Date');
            }
            if (data.hasOwnProperty('reviewedByUserId')) {
                obj['reviewedByUserId'] = ApiClient.convertToType(data['reviewedByUserId'], 'String');
            }
            if (data.hasOwnProperty('reviewedByUserName')) {
                obj['reviewedByUserName'] = ApiClient.convertToType(data['reviewedByUserName'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GeneralRecordItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GeneralRecordItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['underlyingISIN'] && !(typeof data['underlyingISIN'] === 'string' || data['underlyingISIN'] instanceof String)) {
            throw new Error("Expected the field `underlyingISIN` to be a primitive type in the JSON string but got " + data['underlyingISIN']);
        }
        // ensure the json data is a string
        if (data['underlyingName'] && !(typeof data['underlyingName'] === 'string' || data['underlyingName'] instanceof String)) {
            throw new Error("Expected the field `underlyingName` to be a primitive type in the JSON string but got " + data['underlyingName']);
        }
        // ensure the json data is a string
        if (data['issuerCountry'] && !(typeof data['issuerCountry'] === 'string' || data['issuerCountry'] instanceof String)) {
            throw new Error("Expected the field `issuerCountry` to be a primitive type in the JSON string but got " + data['issuerCountry']);
        }
        // ensure the json data is a string
        if (data['lE'] && !(typeof data['lE'] === 'string' || data['lE'] instanceof String)) {
            throw new Error("Expected the field `lE` to be a primitive type in the JSON string but got " + data['lE']);
        }
        // ensure the json data is a string
        if (data['LEI'] && !(typeof data['LEI'] === 'string' || data['LEI'] instanceof String)) {
            throw new Error("Expected the field `LEI` to be a primitive type in the JSON string but got " + data['LEI']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['comment'] && !(typeof data['comment'] === 'string' || data['comment'] instanceof String)) {
            throw new Error("Expected the field `comment` to be a primitive type in the JSON string but got " + data['comment']);
        }
        // ensure the json data is a string
        if (data['addedByUserId'] && !(typeof data['addedByUserId'] === 'string' || data['addedByUserId'] instanceof String)) {
            throw new Error("Expected the field `addedByUserId` to be a primitive type in the JSON string but got " + data['addedByUserId']);
        }
        // ensure the json data is a string
        if (data['addedByUserName'] && !(typeof data['addedByUserName'] === 'string' || data['addedByUserName'] instanceof String)) {
            throw new Error("Expected the field `addedByUserName` to be a primitive type in the JSON string but got " + data['addedByUserName']);
        }
        // ensure the json data is a string
        if (data['reviewedByUserId'] && !(typeof data['reviewedByUserId'] === 'string' || data['reviewedByUserId'] instanceof String)) {
            throw new Error("Expected the field `reviewedByUserId` to be a primitive type in the JSON string but got " + data['reviewedByUserId']);
        }
        // ensure the json data is a string
        if (data['reviewedByUserName'] && !(typeof data['reviewedByUserName'] === 'string' || data['reviewedByUserName'] instanceof String)) {
            throw new Error("Expected the field `reviewedByUserName` to be a primitive type in the JSON string but got " + data['reviewedByUserName']);
        }

        return true;
    }


}



/**
 * id of active blacklist/whitelist
 * @member {String} id
 */
GeneralRecordItem.prototype['id'] = undefined;

/**
 * ISIN of the Underlying
 * @member {String} underlyingISIN
 */
GeneralRecordItem.prototype['underlyingISIN'] = undefined;

/**
 * Name of the Underlying
 * @member {String} underlyingName
 */
GeneralRecordItem.prototype['underlyingName'] = undefined;

/**
 * Country of the Issuer
 * @member {String} issuerCountry
 */
GeneralRecordItem.prototype['issuerCountry'] = undefined;

/**
 * Entry Date, based on ISO 8601 standard
 * @member {Date} entryDate
 */
GeneralRecordItem.prototype['entryDate'] = undefined;

/**
 * Legal entity
 * @member {String} lE
 */
GeneralRecordItem.prototype['lE'] = undefined;

/**
 * The Issuer LEI on the Compliance Issuer Blacklist
 * @member {String} LEI
 */
GeneralRecordItem.prototype['LEI'] = undefined;

/**
 * The name of the issuer on the issuer blacklist
 * @member {String} name
 */
GeneralRecordItem.prototype['name'] = undefined;

/**
 * Additional comments
 * @member {String} comment
 */
GeneralRecordItem.prototype['comment'] = undefined;

/**
 * The time when record was added (ISO 8601 standard)
 * @member {Date} addedAt
 */
GeneralRecordItem.prototype['addedAt'] = undefined;

/**
 * The id the of the user who added this record
 * @member {String} addedByUserId
 */
GeneralRecordItem.prototype['addedByUserId'] = undefined;

/**
 * The name the of the user who added this record
 * @member {String} addedByUserName
 */
GeneralRecordItem.prototype['addedByUserName'] = undefined;

/**
 * The time when record was reviewed (ISO 8601 standard)
 * @member {Date} reviewedAt
 */
GeneralRecordItem.prototype['reviewedAt'] = undefined;

/**
 * User id of reviewer
 * @member {String} reviewedByUserId
 */
GeneralRecordItem.prototype['reviewedByUserId'] = undefined;

/**
 * User name of reviewer
 * @member {String} reviewedByUserName
 */
GeneralRecordItem.prototype['reviewedByUserName'] = undefined;






export default GeneralRecordItem;

