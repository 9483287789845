import React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);


const ImageButton = ({tooltipText, placement, image, onClick, ...rest}) => (
  <HtmlTooltip
    title={
      <>
        <Typography variant="h5" id="det_typo_viewPdf">
          {tooltipText}
        </Typography>
      </>
    }
    placement={placement}
  >
    <img
      src={image}
      alt={tooltipText}
      className="showpdf"
      onClick={onClick}
      {...rest}
    />
</HtmlTooltip>
)

export default ImageButton
