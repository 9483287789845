/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import TXStoreErrorCodes from '../model/TXStoreErrorCodes';
import TXStoreSubmitterRoles from '../model/TXStoreSubmitterRoles';
import TXStoreTransactions from '../model/TXStoreTransactions';
import TransactionStatus from '../model/TransactionStatus';
import TransactionType from '../model/TransactionType';
import XmlFileType from '../model/XmlFileType';

/**
* TransactionStore service.
* @module api/TransactionStoreApi
* @version 1.0.3
*/
export default class TransactionStoreApi {

    /**
    * Constructs a new TransactionStoreApi. 
    * @alias module:api/TransactionStoreApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getIssuanceXmlByIsinAndXmlFileType operation.
     * @callback module:api/TransactionStoreApi~getIssuanceXmlByIsinAndXmlFileTypeCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the latest Issuance XML file by isin
     * @param {String} isin Issuance XML can be queried using the latest ISIN
     * @param {Object} opts Optional parameters
     * @param {module:model/XmlFileType} opts.type Type of the XML file
     * @param {module:api/TransactionStoreApi~getIssuanceXmlByIsinAndXmlFileTypeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getIssuanceXmlByIsinAndXmlFileType(isin, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getIssuanceXmlByIsinAndXmlFileType");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
        'type': opts['type']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/xml', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/txstore/xml/{isin}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPdfByTransactionId operation.
     * @callback module:api/TransactionStoreApi~getPdfByTransactionIdCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Issuance PDF file by id
     * @param {Number} id Issuance PDF can be queried using the id
     * @param {module:api/TransactionStoreApi~getPdfByTransactionIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getPdfByTransactionId(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPdfByTransactionId");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/pdf', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/txstore/transactions/{id}/pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getTXStoreErrorcodes operation.
     * @callback module:api/TransactionStoreApi~getTXStoreErrorcodesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TXStoreErrorCodes} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get error codes
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pageSize number of elements per page
     * @param {module:api/TransactionStoreApi~getTXStoreErrorcodesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TXStoreErrorCodes}
     */
    getTXStoreErrorcodes(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pageSize': opts['pageSize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TXStoreErrorCodes;
      return this.apiClient.callApi(
        '/di/v1/txstore/errorcodes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getTXStoreSubmitterRoles operation.
     * @callback module:api/TransactionStoreApi~getTXStoreSubmitterRolesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TXStoreSubmitterRoles} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get submitter roles
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pageSize number of elements per page
     * @param {module:api/TransactionStoreApi~getTXStoreSubmitterRolesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TXStoreSubmitterRoles}
     */
    getTXStoreSubmitterRoles(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pageSize': opts['pageSize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TXStoreSubmitterRoles;
      return this.apiClient.callApi(
        '/di/v1/txstore/submitter-roles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getTXStoreTransactions operation.
     * @callback module:api/TransactionStoreApi~getTXStoreTransactionsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TXStoreTransactions} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Tx Store transactions based on filter
     * @param {Object} opts Optional parameters
     * @param {String} opts.isin Functional errors can be queried using the Unique Identifier - ISIN
     * @param {Date} opts.dateFrom Timestamp from
     * @param {Date} opts.dateTo Timestamp to
     * @param {Array.<String>} opts.errorCodes error codes
     * @param {Array.<module:model/TransactionType>} opts.transactionTypes Transaction Types
     * @param {Array.<module:model/TransactionStatus>} opts.transactionStatuses Transaction Statuses
     * @param {Array.<String>} opts.submitterRoles Submitter Roles
     * @param {Number} opts.page page number
     * @param {Number} opts.pageSize number of elements per page
     * @param {module:api/TransactionStoreApi~getTXStoreTransactionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TXStoreTransactions}
     */
    getTXStoreTransactions(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'isin': opts['isin'],
        'dateFrom': opts['dateFrom'],
        'dateTo': opts['dateTo'],
        'errorCodes': this.apiClient.buildCollectionParam(opts['errorCodes'], 'multi'),
        'transactionTypes': this.apiClient.buildCollectionParam(opts['transactionTypes'], 'multi'),
        'transactionStatuses': this.apiClient.buildCollectionParam(opts['transactionStatuses'], 'multi'),
        'submitterRoles': this.apiClient.buildCollectionParam(opts['submitterRoles'], 'multi'),
        'page': opts['page'],
        'pageSize': opts['pageSize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TXStoreTransactions;
      return this.apiClient.callApi(
        '/di/v1/txstore/transactions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getXmlByTransactionId operation.
     * @callback module:api/TransactionStoreApi~getXmlByTransactionIdCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Issuance XML file by id
     * @param {Number} id Issuance XML can be queried using the id
     * @param {module:api/TransactionStoreApi~getXmlByTransactionIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getXmlByTransactionId(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getXmlByTransactionId");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/xml', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/txstore/transactions/{id}/xml', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
