/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ComplianceType from './ComplianceType';

/**
 * The ComplianceChangeset model module.
 * @module model/ComplianceChangeset
 * @version 1.0.3
 */
class ComplianceChangeset {
    /**
     * Constructs a new <code>ComplianceChangeset</code>.
     * @alias module:model/ComplianceChangeset
     */
    constructor() { 
        
        ComplianceChangeset.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ComplianceChangeset</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ComplianceChangeset} obj Optional instance to populate.
     * @return {module:model/ComplianceChangeset} The populated <code>ComplianceChangeset</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ComplianceChangeset();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('statusName')) {
                obj['statusName'] = ApiClient.convertToType(data['statusName'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ComplianceType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('addedAt')) {
                obj['addedAt'] = ApiClient.convertToType(data['addedAt'], 'Date');
            }
            if (data.hasOwnProperty('addedByUserId')) {
                obj['addedByUserId'] = ApiClient.convertToType(data['addedByUserId'], 'String');
            }
            if (data.hasOwnProperty('addedByUserName')) {
                obj['addedByUserName'] = ApiClient.convertToType(data['addedByUserName'], 'String');
            }
            if (data.hasOwnProperty('reviewedAt')) {
                obj['reviewedAt'] = ApiClient.convertToType(data['reviewedAt'], 'Date');
            }
            if (data.hasOwnProperty('reviewedByUserId')) {
                obj['reviewedByUserId'] = ApiClient.convertToType(data['reviewedByUserId'], 'String');
            }
            if (data.hasOwnProperty('reviewedByUserName')) {
                obj['reviewedByUserName'] = ApiClient.convertToType(data['reviewedByUserName'], 'String');
            }
            if (data.hasOwnProperty('numOfRecords')) {
                obj['numOfRecords'] = ApiClient.convertToType(data['numOfRecords'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ComplianceChangeset</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ComplianceChangeset</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['statusName'] && !(typeof data['statusName'] === 'string' || data['statusName'] instanceof String)) {
            throw new Error("Expected the field `statusName` to be a primitive type in the JSON string but got " + data['statusName']);
        }
        // ensure the json data is a string
        if (data['addedByUserId'] && !(typeof data['addedByUserId'] === 'string' || data['addedByUserId'] instanceof String)) {
            throw new Error("Expected the field `addedByUserId` to be a primitive type in the JSON string but got " + data['addedByUserId']);
        }
        // ensure the json data is a string
        if (data['addedByUserName'] && !(typeof data['addedByUserName'] === 'string' || data['addedByUserName'] instanceof String)) {
            throw new Error("Expected the field `addedByUserName` to be a primitive type in the JSON string but got " + data['addedByUserName']);
        }
        // ensure the json data is a string
        if (data['reviewedByUserId'] && !(typeof data['reviewedByUserId'] === 'string' || data['reviewedByUserId'] instanceof String)) {
            throw new Error("Expected the field `reviewedByUserId` to be a primitive type in the JSON string but got " + data['reviewedByUserId']);
        }
        // ensure the json data is a string
        if (data['reviewedByUserName'] && !(typeof data['reviewedByUserName'] === 'string' || data['reviewedByUserName'] instanceof String)) {
            throw new Error("Expected the field `reviewedByUserName` to be a primitive type in the JSON string but got " + data['reviewedByUserName']);
        }

        return true;
    }


}



/**
 * id of the item
 * @member {String} id
 */
ComplianceChangeset.prototype['id'] = undefined;

/**
 * Current status of the compliance changeset. TO_REVIEW APPROVED REJECTED 
 * @member {String} statusName
 */
ComplianceChangeset.prototype['statusName'] = undefined;

/**
 * @member {module:model/ComplianceType} type
 */
ComplianceChangeset.prototype['type'] = undefined;

/**
 * The time when record was added (ISO 8601 standard)
 * @member {Date} addedAt
 */
ComplianceChangeset.prototype['addedAt'] = undefined;

/**
 * The id the of the user who added this record
 * @member {String} addedByUserId
 */
ComplianceChangeset.prototype['addedByUserId'] = undefined;

/**
 * The name the of the user who added this record
 * @member {String} addedByUserName
 */
ComplianceChangeset.prototype['addedByUserName'] = undefined;

/**
 * The time when record was reviewed (ISO 8601 standard)
 * @member {Date} reviewedAt
 */
ComplianceChangeset.prototype['reviewedAt'] = undefined;

/**
 * User id of reviewer
 * @member {String} reviewedByUserId
 */
ComplianceChangeset.prototype['reviewedByUserId'] = undefined;

/**
 * User name of reviewer
 * @member {String} reviewedByUserName
 */
ComplianceChangeset.prototype['reviewedByUserName'] = undefined;

/**
 * @member {Number} numOfRecords
 */
ComplianceChangeset.prototype['numOfRecords'] = undefined;






export default ComplianceChangeset;

