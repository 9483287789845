import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { ViewDetailsContainer } from "../index.css";
import viewDetailsGreyIcon from "../../../assets/inactiveActionIcons/View details-Grey.svg";
import viewDetailsActiveIcon from "../../../assets/deselectedActionIcons/View Details-Outline.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ListTable from "../../ComplianceList/ListTable";
import {
  AccountsInternalApiClient,
  ComplianceApiClient,
  RedemptionsInternalApiClient,
} from "../../../api";
import {
  displayDescription,
  getApprovementStatusLabel,
} from "../../../utils/StatusUtils";
import { DATE_FORMATS } from "../../../constants";
import { convertDateTime, timer } from "../../../utils/dateUtils";

export const typesValueToLabel = {
  whitelist: "Underlying Whitelist",
  blacklist: "Underlying Blacklist",
  account: "Account",
  redemption: "Redemption",
  "whitelist-issuer": "Issuer Whitelist",
  "greylist-issuer": "Issuer Greylist",
};

const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
}));

/*
 * Below represents the styling of Tooltip used for icons
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function Reviews(props) {
  const history = useHistory();
  const classes = useStyles();
  const reviewRecordsLimit = 50;
  const [selectedComplianceId, setSelectedComplianceId] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const userId = sessionStorage.getItem("userId");

  useEffect(() => {
    let jwtToken = sessionStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    //getIssuancePendingReviewsData();
    getPendingReviewData(config);
  }, []);

  const getAccountRespData = (response) => ({
    data: response?.response.data.items,
  });

  const getPendingReviewData = (config) => {
    const whitelistPromise =
      ComplianceApiClient.getPendingComplianceChangesets("whitelist");
    const blacklistPromise =
      ComplianceApiClient.getPendingComplianceChangesets("blacklist");
    const whitelistIssuerPromise =
      ComplianceApiClient.getPendingComplianceChangesets("whitelist-issuer");
    // const greylistIssuerPromise =
    //   ComplianceApiClient.getPendingComplianceChangesets("greylist-issuer");
    const accountsPromise =
      AccountsInternalApiClient.getPendingAccountChangesets({
        page: 1,
        pagesize: 1000,
      });
    const redemptionsPromise =
      RedemptionsInternalApiClient.getRedemptionPendingList();

    axios
      .all(
        [
          whitelistPromise,
          blacklistPromise,
          whitelistIssuerPromise,
          accountsPromise,
          redemptionsPromise,
          //greylistIssuerPromise,
        ].map((promise) => promise.catch((error) => ({ error })))
      )
      .then(
        axios.spread((...response) => {
          let complianceData = [];
          const res = [];
          res[0] = response[0]?.response;
          res[1] = response[1]?.response;
          // FIX: needed because shape of the response of accounts is different from the rest
          res[2] = response[2]?.response;
          res[3] = getAccountRespData(response[3]);
          res[4] = response[4]?.response;
          //res[5] = response[5]?.response;

          if (res[0] && Array.isArray(res[0].data) && res[0].data.length > 0) {
            complianceData.push(
              ...res[0].data?.map((rec) => ({
                ...rec,
                timestamp: rec.addedAt,
              }))
            );
          }
          if (
            res[1] &&
            Array.isArray(res[1].data) &&
            res[1].data.length > 0 &&
            complianceData.length < reviewRecordsLimit
          ) {
            complianceData.push(
              ...res[1].data?.map((rec) => ({
                ...rec,
                timestamp: rec.addedAt,
              }))
            );
          }
          if (res[2] && Array.isArray(res[2].data) && res[2].data.length > 0) {
            complianceData.push(
              ...res[2].data?.map((rec) => ({
                ...rec,
                timestamp: rec.addedAt,
              }))
            );
          }
          if (
            res[3] &&
            Array.isArray(res[3].data) &&
            res[3].data.length > 0 &&
            complianceData.length < reviewRecordsLimit
          ) {
            complianceData.push(
              ...res[3].data?.map((rec) => {
                return {
                  ...rec,
                  type: "account",
                  timestamp: rec.uploadTimestamp,
                  statusName: rec.status,
                };
              })
            );
          }
          if (
            res[4] &&
            Array.isArray(res[4].data) &&
            res[4].data.length > 0 &&
            complianceData.length < reviewRecordsLimit
          ) {
            complianceData.push(
              ...res[4].data?.map((rec) => ({
                ...rec,
                type: "redemption",
                timestamp: rec.timestamp,
                statusName: rec.status,
              }))
            );
          }
          const data = complianceData.map((record) => ({
            id: record.id,
            timestamp: convertDateTime(
              record.timestamp,
              DATE_FORMATS.DATE_AND_TIME
            ),
            type: typesValueToLabel[record.type],
            statusName: getApprovementStatusLabel(record.statusName),
            description: displayDescription(record),
            user:
              record?.type === "redemption" || record?.type === "account"
                ? `${record.uploaderUserName} (${record.uploaderUserId})`
                : `${record.addedByUserName} (${record.addedByUserId})`,
            timer: timer(record.timestamp),
          }));
          setData(data);
          setLoading(false);
        })
      )
      .catch((error) => {
        setLoading(false);
      });
  };

  const openDetailsPage = (id, type, isin, uploaderUserId) => {
    const _type = Object.keys(typesValueToLabel).find(
      (key) => typesValueToLabel[key] === type
    );
    if (_type.toLowerCase() === "redemption") {
      const isinVal = isin !== null;
      isinVal &&
        history.push({
          pathname: `/issuanceDetails`,
          state: {
            isin: isin,
            tabName: "redemptionReview",
            allowActions: userId !== uploaderUserId,
          },
        });
    } else {
      history.push({
        pathname: `/${_type}/details/${id}`,
      });
    }
  };

  return (
    <React.Fragment>
      <ViewDetailsContainer>
        <Grid container>
          <Grid item xs={12} align="end">
            {selectedComplianceId === null ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="issBlotter_toolTip" variant="h5">
                      View Details
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={viewDetailsGreyIcon}
                  alt="details"
                  id="issBlotter_viewDetailsGreyIcon"
                  className="viewDetailsGreyIcon"
                />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography id="issBlotter_htmlToolTip" variant="h5">
                      View Details{" "}
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={viewDetailsActiveIcon}
                  alt="details"
                  id="issBlotter_viewDetailsActiveIcon"
                  data-testid="imgViewDetails"
                  className="viewDetailsActiveIcon"
                  onClick={() =>
                    openDetailsPage(
                      selectedComplianceId.id,
                      selectedComplianceId.type,
                      selectedComplianceId.description,
                      selectedComplianceId.uploaderUserId
                    )
                  }
                />
              </HtmlTooltip>
            )}
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      <ListTable
        loading={loading}
        data={data.slice(0, reviewRecordsLimit)}
        classes={classes}
        setSelected={setSelectedComplianceId}
        onRowClick={openDetailsPage}
        paginationEnabled={false}
        itemsCount={1}
        onPageChange={() => {}}
      />
    </React.Fragment>
  );
}

export default Reviews;
