/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TransactionStatus from './TransactionStatus';
import TransactionType from './TransactionType';

/**
 * The TXStoreTransaction model module.
 * @module model/TXStoreTransaction
 * @version 1.0.3
 */
class TXStoreTransaction {
    /**
     * Constructs a new <code>TXStoreTransaction</code>.
     * @alias module:model/TXStoreTransaction
     */
    constructor() { 
        
        TXStoreTransaction.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TXStoreTransaction</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TXStoreTransaction} obj Optional instance to populate.
     * @return {module:model/TXStoreTransaction} The populated <code>TXStoreTransaction</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TXStoreTransaction();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('messages')) {
                obj['messages'] = ApiClient.convertToType(data['messages'], 'String');
            }
            if (data.hasOwnProperty('transactionType')) {
                obj['transactionType'] = TransactionType.constructFromObject(data['transactionType']);
            }
            if (data.hasOwnProperty('transactionStatus')) {
                obj['transactionStatus'] = TransactionStatus.constructFromObject(data['transactionStatus']);
            }
            if (data.hasOwnProperty('submitterID')) {
                obj['submitterID'] = ApiClient.convertToType(data['submitterID'], 'String');
            }
            if (data.hasOwnProperty('submitterRole')) {
                obj['submitterRole'] = ApiClient.convertToType(data['submitterRole'], 'String');
            }
            if (data.hasOwnProperty('hasXml')) {
                obj['hasXml'] = ApiClient.convertToType(data['hasXml'], 'Boolean');
            }
            if (data.hasOwnProperty('hasPdf')) {
                obj['hasPdf'] = ApiClient.convertToType(data['hasPdf'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>TXStoreTransaction</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TXStoreTransaction</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['messages'] && !(typeof data['messages'] === 'string' || data['messages'] instanceof String)) {
            throw new Error("Expected the field `messages` to be a primitive type in the JSON string but got " + data['messages']);
        }
        // ensure the json data is a string
        if (data['submitterID'] && !(typeof data['submitterID'] === 'string' || data['submitterID'] instanceof String)) {
            throw new Error("Expected the field `submitterID` to be a primitive type in the JSON string but got " + data['submitterID']);
        }
        // ensure the json data is a string
        if (data['submitterRole'] && !(typeof data['submitterRole'] === 'string' || data['submitterRole'] instanceof String)) {
            throw new Error("Expected the field `submitterRole` to be a primitive type in the JSON string but got " + data['submitterRole']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
TXStoreTransaction.prototype['id'] = undefined;

/**
 * @member {Date} timestamp
 */
TXStoreTransaction.prototype['timestamp'] = undefined;

/**
 * @member {String} isin
 */
TXStoreTransaction.prototype['isin'] = undefined;

/**
 * @member {String} messages
 */
TXStoreTransaction.prototype['messages'] = undefined;

/**
 * @member {module:model/TransactionType} transactionType
 */
TXStoreTransaction.prototype['transactionType'] = undefined;

/**
 * @member {module:model/TransactionStatus} transactionStatus
 */
TXStoreTransaction.prototype['transactionStatus'] = undefined;

/**
 * @member {String} submitterID
 */
TXStoreTransaction.prototype['submitterID'] = undefined;

/**
 * @member {String} submitterRole
 */
TXStoreTransaction.prototype['submitterRole'] = undefined;

/**
 * @member {Boolean} hasXml
 */
TXStoreTransaction.prototype['hasXml'] = undefined;

/**
 * @member {Boolean} hasPdf
 */
TXStoreTransaction.prototype['hasPdf'] = undefined;






export default TXStoreTransaction;

