import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import "./Login.css";
import decode from "jwt-decode";
import { loginSuccessful } from "../../store/actions/UserActions";
import { useUserStore } from "../../store/Store";
import { roleRights } from "../../App";
import { rolesObject } from "./userIdJSON";
import { Typography } from "@mui/material";
import { AuthenticationApiClient } from "../../api";

/*
 * Array consisting list of users in Login dropdown when running code locally
 */
const users = [
  { name: "Clearstream", key: 1 },
  { name: "BNPParibas", key: 2 },
  { name: "Deka", key: 3 },
  { name: "DZBank", key: 4 },
  { name: "UniCredit", key: 5 },
  { name: "Vontobel", key: 6 },
  { name: "Landesbank", key: 7 },
  { name: "NRWBank", key: 8 },
  { name: "NordLB", key: 9 },
  { name: "MuHyp", key: 10 },
  { name: "BerlinHyp", key: 11 },
  { name: "KfW", key: 12 },
  { name: "DefaultIssuer3", key: 13 },
];

/*
 * Array consisting list of users in Login dropdown when code is deployed any env (Clearstream node/URL)
 */
const clearstream = [{ name: "Clearstream", key: 1 }];

/*
 * Array consisting list of users in Login dropdown when code is deployed any env (Issuer node/URL)
 */
const issuers = [
  { name: "BNPParibas", key: 1 },
  { name: "Deka", key: 2 },
  { name: "DZBank", key: 3 },
  { name: "UniCredit", key: 4 },
  { name: "Vontobel", key: 5 },
  { name: "Landesbank", key: 6 },
  { name: "NRWBank", key: 7 },
  { name: "NordLB", key: 8 },
  { name: "MuHyp", key: 9 },
  { name: "BerlinHyp", key: 10 },
  { name: "KfW", key: 11 },
  { name: "DefaultIssuer3", key: 12 },
];

function Login(props) {
  //eslint-disable-next-line no-unused-vars
  const [userState, userDispatch] = useUserStore(); // to access store (context API concept)
  const [dropDownValue, setDropDownValue] = useState("Select the user");
  const [dropDownForUserId, setDropDownForUserId] =
    useState("Select the User ID");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [userIdDropDown, setUserIdDropDown] = useState([]);
  const emptyUser = !sessionStorage.getItem("user");
  /*
   * This method is used to make sure we are on Login page if we aren't logged-in
   */
  useEffect(() => {
    if (emptyUser) {
      props.history.push("/");
    }
  }, [emptyUser, props.history]);

  useEffect(() => {
    if (selectedUser) {
      setUserIdDropDown(rolesObject[selectedUser]);
    }
  }, [selectedUser]);

  /*
   * This method is used to set the selected value in login dropdown
   */
  const changeValue = (event) => {
    if (event.target.textContent !== dropDownValue) {
      setSelectedUserId("");
      setDropDownForUserId("Select the User ID");
    }
    setDropDownValue(event.target.textContent);
  };

  /*
   * This method will request mock iam to generate jwt token
   */
  const login = (path) => {
    sessionStorage.setItem("user", selectedUser);
    let redirectPath = path;
    AuthenticationApiClient.getJwtTokenUsingGET(
      selectedUserId,
      selectedUser
    ).then(async (res) => {
      try {
        const userId = decode(res.data.message).sub;
        sessionStorage.setItem("userId", userId);
        sessionStorage.setItem("jwtToken", res.data.message);
        if (userId === "clearstream_com") {
          redirectPath = "/pending";
        }
        props.history.push(redirectPath);
        props.setIsLoggedIn(true);
        if (res.data.message) {
          let jwtToken = sessionStorage.getItem("jwtToken");
          const token = decode(sessionStorage.getItem("jwtToken"));
          userDispatch(
            loginSuccessful({
              userRoles: token.digitiser_roles,
              userRoleRight: roleRights(token.digitiser_roles),
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleUserIdDropDownChange = (event, user) => {
    setDropDownForUserId(event.target.textContent);
    setSelectedUserId(user);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (selectedUser && selectedUserId) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedUser, selectedUserId]);

  return (
    <React.Fragment>
      <Container>
        <Row
          id="login_justify-content-md-center"
          className="justify-content-md-center"
        >
          <Card id="login_card" className="card">
            <Card.Body id="login_cardBody" className="cardBody">
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6">User Login</Typography>
                <Grid item xs={12}>
                  <DropdownButton
                    id="
                  -item-button"
                    title={dropDownValue}
                  >
                    {process.env.REACT_APP_LOCAL_LOGIN === "true"
                      ? users.map((user, index) => (
                          <Dropdown.Item
                            as="button"
                            id={`login_user_${index}`}
                            key={user.key}
                          >
                            <div
                              onClick={(event) => {
                                changeValue(event);
                                setSelectedUser(user.name);
                              }}
                            >
                              {user.name}
                            </div>
                          </Dropdown.Item>
                        ))
                      : process.env.REACT_APP_PARTICIPANT === "clearstream"
                      ? clearstream.map((user, index) => (
                          <Dropdown.Item
                            as="button"
                            id={`login_clearstream_${index}`}
                            key={user.key}
                          >
                            <div
                              onClick={(event) => {
                                changeValue(event);
                                setSelectedUser(user.name);
                              }}
                            >
                              {user.name}
                            </div>
                          </Dropdown.Item>
                        ))
                      : issuers.map((user, index) => (
                          <Dropdown.Item as="button" key={user.key}>
                            <div
                              id={`login_issuer_${index}`}
                              data-testid={"IssuerDropdown" + user.key}
                              onClick={(event) => {
                                changeValue(event);
                                setSelectedUser(user.name);
                              }}
                            >
                              {user.name}
                            </div>
                          </Dropdown.Item>
                        ))}
                  </DropdownButton>
                </Grid>
                {process.env.REACT_APP_ENABLED === "false" && (
                  <>
                    <Typography variant="h6">User ID</Typography>
                    <Grid item xs={12}>
                      <DropdownButton
                        id="-item-button"
                        title={dropDownForUserId}
                      >
                        {userIdDropDown.map((user, index) => (
                          <Dropdown.Item
                            as="button"
                            id={`login_user_${index}`}
                            key={user}
                          >
                            <div
                              onClick={(event) =>
                                handleUserIdDropDownChange(event, user)
                              }
                            >
                              {user}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Grid>
                  </>
                )}

                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    id="login_buttonSubmit"
                    className="buttonSubmit"
                    onClick={() => login("/issuance")}
                    disabled={isButtonDisabled}
                    data-testid="loginButton"
                  >
                    Log in
                  </Button>
                </Grid>
              </Grid>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Login;
