/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DeFi model module.
 * @module model/DeFi
 * @version 1.0.3
 */
class DeFi {
    /**
     * Constructs a new <code>DeFi</code>.
     * @alias module:model/DeFi
     */
    constructor() { 
        
        DeFi.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DeFi</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeFi} obj Optional instance to populate.
     * @return {module:model/DeFi} The populated <code>DeFi</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeFi();

            if (data.hasOwnProperty('ScDecimals')) {
                obj['ScDecimals'] = ApiClient.convertToType(data['ScDecimals'], 'Number');
            }
            if (data.hasOwnProperty('ScPaused')) {
                obj['ScPaused'] = ApiClient.convertToType(data['ScPaused'], 'Boolean');
            }
            if (data.hasOwnProperty('ScStatus')) {
                obj['ScStatus'] = ApiClient.convertToType(data['ScStatus'], ['String']);
            }
            if (data.hasOwnProperty('BcRestrictions')) {
                obj['BcRestrictions'] = ApiClient.convertToType(data['BcRestrictions'], ['String']);
            }
            if (data.hasOwnProperty('Predeployment')) {
                obj['Predeployment'] = ApiClient.convertToType(data['Predeployment'], 'Boolean');
            }
            if (data.hasOwnProperty('ScTemplate')) {
                obj['ScTemplate'] = ApiClient.convertToType(data['ScTemplate'], ['String']);
            }
            if (data.hasOwnProperty('DIChecksum')) {
                obj['DIChecksum'] = ApiClient.convertToType(data['DIChecksum'], 'String');
            }
            if (data.hasOwnProperty('TokenNetwork')) {
                obj['TokenNetwork'] = ApiClient.convertToType(data['TokenNetwork'], ['String']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DeFi</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeFi</code>.
     */
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['ScStatus'])) {
            throw new Error("Expected the field `ScStatus` to be an array in the JSON data but got " + data['ScStatus']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['BcRestrictions'])) {
            throw new Error("Expected the field `BcRestrictions` to be an array in the JSON data but got " + data['BcRestrictions']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['ScTemplate'])) {
            throw new Error("Expected the field `ScTemplate` to be an array in the JSON data but got " + data['ScTemplate']);
        }
        // ensure the json data is a string
        if (data['DIChecksum'] && !(typeof data['DIChecksum'] === 'string' || data['DIChecksum'] instanceof String)) {
            throw new Error("Expected the field `DIChecksum` to be a primitive type in the JSON string but got " + data['DIChecksum']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['TokenNetwork'])) {
            throw new Error("Expected the field `TokenNetwork` to be an array in the JSON data but got " + data['TokenNetwork']);
        }

        return true;
    }


}



/**
 * Number of decimals in smart contract[11]
 * @member {Number} ScDecimals
 */
DeFi.prototype['ScDecimals'] = undefined;

/**
 * Pause smart contract functions except for registrar[18]
 * @member {Boolean} ScPaused
 */
DeFi.prototype['ScPaused'] = undefined;

/**
 * Smart contract life cycle status[19]
 * @member {Array.<String>} ScStatus
 */
DeFi.prototype['ScStatus'] = undefined;

/**
 * Blockchain address of restrictions smart contract for on-chain role and access management[13]
 * @member {Array.<String>} BcRestrictions
 */
DeFi.prototype['BcRestrictions'] = undefined;

/**
 * Deployment of SC after filing process completed or at issue date[607]
 * @member {Boolean} Predeployment
 */
DeFi.prototype['Predeployment'] = undefined;

/**
 * Template identifier of issuance smart contract[608]
 * @member {Array.<String>} ScTemplate
 */
DeFi.prototype['ScTemplate'] = undefined;

/**
 * Hash of off-chain register data (API response)[609]
 * @member {String} DIChecksum
 */
DeFi.prototype['DIChecksum'] = undefined;

/**
 * Token blockchain[556]
 * @member {Array.<String>} TokenNetwork
 */
DeFi.prototype['TokenNetwork'] = undefined;






export default DeFi;

