import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { StyledContainer, StyledCard, StyledDialogActions } from "./index.css";
import Dialog from "@material-ui/core/Dialog";
import { DatePicker } from "antd";
import calendarIcon from "../../assets/calendar-icon.svg";
import moment from "moment-timezone";
import { Col, Row } from "react-bootstrap";
import { IssuancesLogsInternalApiClient } from "../../api";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import { makeStyles } from "@material-ui/core/styles";
import { flipDateFormat } from "../../utils/dateUtils";
import { DATE_FORMATS } from "../../constants";

/*
 * the below code is used for styling Audit Export dialog box
 */
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "450px",
    height: "150px",
    borderRadius: "10px",
  },
}));

function AuditExport() {
  const classes = useStyles(); //to use useStyle hook we need to declare it
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [responseText, setResponseText] = React.useState("");
  const [flagPopUp, setFlagPopUp] = React.useState("");
  const [warningOpen, setWarningOpen] = React.useState(false);

  /*
   * This method is to update the form fields with our set values
   */
  const changeFieldValue = (event) => {
    const id = [event.target.id][0];
    const value = event.target.value;

    if (id === "fromDate") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  /*
   *
   */
  const startDateDisable = (current) => {
    return current > moment(new Date()) || moment(toDate) < current;
  };

  /*
   *
   */
  const toDateDisable = (current) => {
    return current > moment(new Date()) || fromDate > current;
  };

  /*
   * This method is for closing error and success msg popup
   */
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
    setFlagPopUp("");
  };

  /*
   * Method to export audit log and download the csv file
   */
  const exportLog = () => {
    IssuancesLogsInternalApiClient.getAuditLogs({
      dateFrom: flipDateFormat(fromDate),
      dateTo: flipDateFormat(toDate),
    })
      .then((res) => {
        if (res.response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `AuditLog.csv`);
          document.body.appendChild(link);
          link.click();
          setResponseText("CSV downloaded successfully");
          setShowPopUp(true);
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
        } else {
          setResponseText("Unable to export audit log. Please try later!");
          setShowPopUp(true);
        }
      })
      .catch((error) => {
        setResponseText("Export has failed.");
        setShowPopUp(true);
        setFlagPopUp("error");
      });
  };

  /*
   * This method is to close large size file warning dialog box
   */
  const closeDialog = () => {
    setWarningOpen(false);
  };

  return (
    <React.Fragment>
      <StyledContainer style={{ width: 600 }}>
        <div id="audExp_popup" className="popup">
          {responseText &&
            showPopUp &&
            (flagPopUp === "error" ? (
              <ErrorPopup
                errorPopupText={responseText}
                popupVisibility={showPopUp}
                handleClose={() => closeErrorSuccessPopup()}
              />
            ) : (
              <SuccessPopup
                successPopupText={responseText}
                popupVisibility={showPopUp}
                handleClose={() => closeErrorSuccessPopup()}
              />
            ))}
        </div>
        <Typography id="audExp_exportAuditReport" className="exportAuditReport">
          Export Audit Log
        </Typography>
        <StyledCard>
          <Typography variant="h5" className="dataRange" id="audExp_dateRange">
            Please select a date range:
          </Typography>
          <Grid container id="audExp_padding1" className="padding1">
            <Grid item xs={6}>
              <Row>
                <Col md={2}>
                  <Typography
                    variant="h5"
                    id="audExp_fromTo"
                    className="fromTo"
                  >
                    From
                  </Typography>
                </Col>
                <Col md={10}>
                  <DatePicker
                    data-testid="changeDate"
                    id="audExp_changeDate"
                    showNow={false}
                    format={DATE_FORMATS.DATE}
                    placeholder={DATE_FORMATS.DATE}
                    disabledDate={startDateDisable}
                    suffixIcon={
                      <img
                        src={calendarIcon}
                        id="audExp_calendarIcon"
                        alt="calendar"
                      />
                    }
                    onChange={(date, dateString) =>
                      changeFieldValue({
                        target: {
                          id: "fromDate",
                          value: date !== null ? dateString : "",
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </Grid>
            <Grid item xs={6} id="audExp_paddingTo" className="paddingTo">
              <Row>
                <Col md={1} id="audExp_paddingToR" className="paddingToR">
                  <Typography
                    variant="h5"
                    id="audExp_fromTo"
                    className="fromTo"
                  >
                    To
                  </Typography>
                </Col>
                <Col md={11}>
                  <DatePicker
                    showNow={false}
                    format={DATE_FORMATS.DATE}
                    placeholder={DATE_FORMATS.DATE}
                    disabledDate={toDateDisable}
                    id="audExp_datePicker"
                    suffixIcon={
                      <img
                        src={calendarIcon}
                        id="audExp_calendar"
                        alt="calendar"
                      />
                    }
                    onChange={(date, dateString) =>
                      changeFieldValue({
                        target: {
                          id: "toDate",
                          value: date !== null ? dateString : "",
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </Grid>
          <Grid container id="audExp_padding2" className="padding2">
            <Grid item xs={12} align="end">
              <Button
                data-testid="exportCancel"
                id="audExp_exportCancel"
                onClick={() => {
                  console.log("cancel");
                }}
                className="cancel"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="export"
                id="audExp_export"
                data-testid="exportLog"
                onClick={() => {
                  exportLog();
                }}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </StyledCard>
      </StyledContainer>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        style={{ zIndex: "1" }}
        open={warningOpen}
        data-testid="largeFilePopup"
        id="audExp_largeFilePopup"
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <Typography
          variant="h5"
          id="audExp_largeFileWarning"
          style={{
            color: "#000000",
            fontSize: "17px",
            lineHeight: "21px",
            paddingTop: "7%",
            paddingLeft: "17%",
          }}
        >
          You are trying to download a large file.
        </Typography>
        <StyledDialogActions>
          <Button
            variant="outlined"
            color="primary"
            data-testid="cancelPopup"
            id="audExp_cancelPopup"
            onClick={() => closeDialog()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="largeExportLog"
            id="audExp_continue"
            onClick={() => {
              exportLog();
              closeDialog();
            }}
          >
            Continue
          </Button>
        </StyledDialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AuditExport;
