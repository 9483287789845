/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OtherPartyIdentification model module.
 * @module model/OtherPartyIdentification
 * @version 1.0.3
 */
class OtherPartyIdentification {
    /**
     * Constructs a new <code>OtherPartyIdentification</code>.
     * @alias module:model/OtherPartyIdentification
     */
    constructor() { 
        
        OtherPartyIdentification.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OtherPartyIdentification</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OtherPartyIdentification} obj Optional instance to populate.
     * @return {module:model/OtherPartyIdentification} The populated <code>OtherPartyIdentification</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OtherPartyIdentification();

            if (data.hasOwnProperty('AccountActive')) {
                obj['AccountActive'] = ApiClient.convertToType(data['AccountActive'], 'Boolean');
            }
            if (data.hasOwnProperty('AccountNumber')) {
                obj['AccountNumber'] = ApiClient.convertToType(data['AccountNumber'], 'String');
            }
            if (data.hasOwnProperty('AccountType')) {
                obj['AccountType'] = ApiClient.convertToType(data['AccountType'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OtherPartyIdentification</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OtherPartyIdentification</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['AccountNumber'] && !(typeof data['AccountNumber'] === 'string' || data['AccountNumber'] instanceof String)) {
            throw new Error("Expected the field `AccountNumber` to be a primitive type in the JSON string but got " + data['AccountNumber']);
        }
        // ensure the json data is a string
        if (data['AccountType'] && !(typeof data['AccountType'] === 'string' || data['AccountType'] instanceof String)) {
            throw new Error("Expected the field `AccountType` to be a primitive type in the JSON string but got " + data['AccountType']);
        }

        return true;
    }


}



/**
 * Identifier whether the account associated with the bc address in AccoundNumber is active[574]
 * @member {Boolean} AccountActive
 */
OtherPartyIdentification.prototype['AccountActive'] = undefined;

/**
 * Party account number[517]
 * @member {String} AccountNumber
 */
OtherPartyIdentification.prototype['AccountNumber'] = undefined;

/**
 * Indicates type of account used for issuance of the instrument[577]
 * @member {String} AccountType
 */
OtherPartyIdentification.prototype['AccountType'] = undefined;






export default OtherPartyIdentification;

