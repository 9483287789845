import { DEFAULT_TIMEZONE } from "../constants";
import moment from "moment";

export const flipDateFormat = (input) => {
  const dateParts = input.split("/");
  return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
};

export const flipDateTimeFormat = (input) => {
  const date = input.split(" ");
  const dateParts = date[0].split("/");
  return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0] + " " + date[1];
};

export const addOneDay = (dateString) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return [
    date.getFullYear(),
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
  ].join("-");
};

export const convertDateTime = (dateTime, format) =>
  moment.tz(dateTime, DEFAULT_TIMEZONE).format(format);

export const timer = (dateTime) =>
  moment.tz(dateTime, DEFAULT_TIMEZONE).fromNow();
