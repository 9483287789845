/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Conversion model module.
 * @module model/Conversion
 * @version 1.0.3
 */
class Conversion {
    /**
     * Constructs a new <code>Conversion</code>.
     * @alias module:model/Conversion
     */
    constructor() { 
        
        Conversion.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Conversion</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Conversion} obj Optional instance to populate.
     * @return {module:model/Conversion} The populated <code>Conversion</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Conversion();

            if (data.hasOwnProperty('CreditEventOccured')) {
                obj['CreditEventOccured'] = ApiClient.convertToType(data['CreditEventOccured'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Conversion</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Conversion</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * Indicates whether a default event occured[38]
 * @member {Boolean} CreditEventOccured
 */
Conversion.prototype['CreditEventOccured'] = undefined;






export default Conversion;

