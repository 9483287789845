import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import { useUserStore } from "../../store/Store";
import { StyledContainer } from "./index.css";
import { styled } from "@mui/material/styles";
import IssuanceBlotter from "./IssuanceBlotter/IssuanceBlotter";
import Reviews from "./Reviews/Reviews";
import LifecycleManagement from "./LifecycleManagement/LifecycleManagement";
import moment from "moment-timezone";
import Compliance from "./Compliance/Compliance";
import ResponsiveTabs from "../ComplianceList/ResponsiveTabs";
import { DATE_FORMATS } from "../../constants";
import { convertDateTime } from "../../utils/dateUtils";
/*
 * The below const variables used for tab styling
 */
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid rgba(135, 135, 135, 0.6)",
  "& .MuiTabs-indicator": {
    backgroundColor: "#00A5C0",
    height: "3px",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#B7B7B7",
    "&:hover": {
      color: "#00A5C0",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#00A5C0",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#00A5C0",
    },
  })
);

function Pending(props) {
  const [userState] = useUserStore();
  const showComplianceOnly =
    userState.userRoles.length === 1 &&
    userState.userRoles.includes("Clearstream Compliance");

  //value is storing the selected tabName, using session storage we tried to check the previous selected tabName
  const [value, setValue] = React.useState(
    sessionStorage.getItem("pending")
      ? JSON.parse(sessionStorage.getItem("pending")).tabName
      : showComplianceOnly
      ? "compliance"
      : "issuanceBlotter"
  );

  //default count for every tab is 0 at beginning
  const [countArray, setCountArray] = React.useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    getCount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.individualPendingSectionCount]);

  /*
   * The below method checks and updates the individual section's count
   */
  const getCount = () => {
    props.setTimeStamp(convertDateTime(moment.now(), DATE_FORMATS.DATETIME)); //refresh the timestamp when counts are updating
    let dataArr = [];
    Object.values(props.individualPendingSectionCount).forEach((element) => {
      dataArr.push(element);
    });
    setCountArray(dataArr);
  };

  const tabs = [
    {
      label: `Issuances (${countArray[0]})`,
      value: "issuanceBlotter",
      testid: "issuanceBlotterTab",
      hidden: showComplianceOnly,
    },
    {
      label: `Lifecycle Management (${countArray[1]})`,
      value: "lifecycleManagement",
      testid: "lifecycleManagementTab",
      hidden: showComplianceOnly,
    },
    {
      label: `Reviews (${countArray[3]})`,
      value: "reviews",
      testid: "reviewsTab",
      hidden: showComplianceOnly,
    },
    {
      label: `Compliance (${countArray[2]})`,
      value: "compliance",
      testid: "complianceTab",
      hidden: false,
    },
  ];

  return (
    <React.Fragment>
      <StyledContainer>
        <TabContext value={value}>
          <ResponsiveTabs
            allTabs={tabs.filter((item) => !item.hidden)}
            value={value}
            setValue={setValue}
            sessionStorageItem="pending"
          />
          {!showComplianceOnly && (
            <>
              <TabPanel value="issuanceBlotter">
                <IssuanceBlotter />
              </TabPanel>
              <TabPanel value="lifecycleManagement">
                <LifecycleManagement />
              </TabPanel>
              <TabPanel value="reviews">
                <Reviews />
              </TabPanel>
            </>
          )}
          <TabPanel value="compliance">
            <Compliance />
          </TabPanel>
        </TabContext>
      </StyledContainer>
    </React.Fragment>
  );
}

export default Pending;
