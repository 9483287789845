import React, { useCallback, useEffect, useState } from "react";
import { ComplianceListsContainer } from "./index.css";
import Typography from "@material-ui/core/Typography";

import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { DatePicker } from "antd";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { MultiSelect } from "react-multi-select-component";
import PaginationContainer from "../../components/Paginator";
import NoData from "../../components/NoData/NoData";
import filterIcon from "../../assets/FilterIcon.svg";
import calendarIcon from "../../assets/calendar-icon.svg";
import { getComparator, stableSort } from "../../components/Sorting";
import { DATE_FORMATS } from "../../constants";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
/*
 * Below represents the styling of Tooltip used for icons
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const RecordsTable = ({
  data,
  loading,
  onRowClick,
  columns,
  onPageChange,
  itemsCount,
  onClick,
  isSelected,
  onPDFDownload,
  onXMLDownload,
  resetPage = false,
}) => {
  const [optionsArray, setOptionsArray] = useState({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const searchList = columns.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: "" }),
    {}
  );
  const [searchArray, setSearchArray] = useState([]);
  const [searchValue, setSearchValue] = useState(searchList);
  const defaultOptions = columns.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: [] }),
    {}
  );
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeAppliedFilter = () => {
    setSelectedColumnOption(defaultOptions);
    setSearchArray([]);
    setSearchValue(searchList);
    setFilteredData(data);
  };

  const onPageChangeTrigger = (pageNumber, pageSize) => {
    setSelectedColumnOption(defaultOptions);
    setSearchArray([]);
    setSearchValue(searchList);
    onPageChange(pageNumber, pageSize);
  };
  /*
   * Updating option lists of columns dynamically with available values
   */
  const getOptionList = (baseData, columnName) => {
    let arr = [];
    baseData.map((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
      return arr;
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({ value: elem, label: elem });
      return returnList;
    });
    return returnList;
  };

  /*
   * Updating options array for filter search
   */
  const createFilterOptions = useCallback((baseData) => {
    let columnNames = baseData.length !== 0 ? Object.keys(baseData[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(baseData, elem);
    });
    setOptionsArray(optionsArrList);
  }, []);

  const filterTableData = (event) => {
    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }
    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue); //Updating searchValue object with column id and search value
    let newData = [...data];
    searchArray.forEach((column) => {
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;
      tempNewData = newData.filter((row) => {
        if (column === "isin" || column === "underlyingISIN") {
          return row[column]?.toLowerCase().includes(value.toLowerCase());
        } else if (value.length === 0) {
          return true;
        } else {
          return value.includes(row[column]);
        }
      });
      newData = tempNewData;
    });
    setFilteredData(newData);
  };

  useEffect(() => {
    createFilterOptions(data);
    setFilteredData(data);
  }, [createFilterOptions, data]);

  return (
    <div>
      <ComplianceListsContainer style={{ maxHeight: "auto" }}>
        <Table
          aria-label="sticky table"
          stickyHeader
          id="TableView"
          data-testid="TableView"
        >
          <TableHead>
            <TableRow>
              <TableCell className="cellBorder">
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography id="drp_applyFilterTooltip" variant="h5">
                        {applyFilter !== true
                          ? "Add Filters"
                          : "Remove Filters"}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span id="drp_filterIcon" className="filterIcon">
                    <img
                      src={filterIcon}
                      id="drp_triangle"
                      data-testid="addFilter_icon"
                      alt="triangle"
                      onClick={() => {
                        if (applyFilter) {
                          removeAppliedFilter();
                        }
                        setApplyFilter(!applyFilter);
                      }}
                    />
                  </span>
                </HtmlTooltip>
              </TableCell>
              {!applyFilter
                ? columns.map((column, index) => (
                    <TableCell
                      className="cellBorder"
                      id={`label_${index}`}
                      key={column.key}
                    >
                      <TableSortLabel
                        style={{
                          width: column.width,
                          paddingLeft: "12px",
                        }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                        id={`iss_tableSortLabel_sortHandler_${column.id}`}
                        disabled={column.id === "timer"}
                      >
                        <Typography
                          variant="h5"
                          id={`label_text_${index}`}
                          className="lH-17"
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <span className="visuallyhidden">
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))
                : columns.map((column, index) => {
                    return (
                      <TableCell
                        className="cellBorder"
                        id={`label_${index}`}
                        key={column.key}
                      >
                        <TableSortLabel
                          style={{
                            width: column.width,
                            paddingLeft: "12px",
                          }}
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                          id={`iss_tableSortLabel_sortHandler_${column.id}`}
                          disabled={column.id === "timer"}
                        >
                          <Typography
                            variant="h5"
                            id={`label_text_${index}`}
                            className="lH-17"
                            style={{ marginBottom: ".5rem" }}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <span className="visuallyhidden">
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </Typography>
                        </TableSortLabel>
                        {(column.id === "isin" ||
                          column.id === "underlyingISIN") && (
                          <TextField
                            className="searchbarColumn"
                            data-testid="filter_tempId_text" //textField input for ISIN column search
                            fullWidth
                            placeholder="Enter"
                            InputProps={{ disableUnderline: true }}
                            onChange={(event) => {
                              filterTableData(event);
                            }}
                            id={column.id}
                          ></TextField>
                        )}
                        {(column.id === "valueDate" ||
                          column.id === "entryDate") && (
                          <DatePicker
                            showNow={false}
                            format={DATE_FORMATS.DATE}
                            placeholder={DATE_FORMATS.DATE}
                            onChange={(date, dateString) => {
                              filterTableData({
                                target: {
                                  id: column.id,
                                  value: date !== null ? dateString : "",
                                },
                              });
                            }}
                            suffixIcon={
                              <img
                                src={calendarIcon}
                                alt="calendar"
                                className="iconColumn"
                              />
                            }
                            className="datePickerColumn"
                            id="drp_datePickerColumn"
                          />
                        )}
                        {column.id !== "isin" &&
                          column.id !== "underlyingISIN" &&
                          column.id !== "valueDate" &&
                          column.id !== "entryDate" && (
                            <MultiSelect
                              style={{ overflow: "visible" }}
                              className="selectColumn"
                              id="drp_selectColumn"
                              options={optionsArray[column.id] || []}
                              onChange={(option) => {
                                let arr = [];
                                option.map((elem) => arr.push(elem.value));
                                let optionSelected = selectedColumnOption;
                                optionSelected[column.id] = option;
                                setSelectedColumnOption(optionSelected);
                                filterTableData({
                                  target: {
                                    id: column.id,
                                    value: arr,
                                  },
                                });
                              }}
                              value={selectedColumnOption[column.id] || []}
                            />
                          )}
                      </TableCell>
                    );
                  })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.length === 0 ? (
              <NoData loading={loading} />
            ) : (
              <>
                {filteredData &&
                  filteredData.length &&
                  stableSort(filteredData, getComparator(order, orderBy))?.map(
                    (row, index) => {
                      const isItemSelected = isSelected
                        ? isSelected(index)
                        : null;
                      return (
                        <TableRow
                          key={index}
                          hover
                          onDoubleClick={
                            onRowClick ? () => onRowClick(row, index) : null
                          }
                          onClick={onClick ? () => onClick(row, index) : null}
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                          id={`tableRow_${index}`}
                          role="checkbox"
                          data-testid={`tableRowId_${index}`}
                          tabIndex={-1}
                          className={`${
                            index % 2 ? "FFFFFFColor" : "F7F7F7Color"
                          }`} //passing className to alternate rows for stylling
                        >
                          <TableCell></TableCell>
                          {columns.map(({ id, label }) => {
                            if (
                              id === "hasXML" ||
                              (id === "hasPDF" && row[id] === true)
                            ) {
                              return (
                                <TableCell className="alignIcon">
                                  <HtmlTooltip
                                    id="iss_htmltooltip_viewDetails3"
                                    title={
                                      <React.Fragment>
                                        <Typography
                                          variant="h5"
                                          id="iss_typo_refresh"
                                        >
                                          Download{" "}
                                          {id === "hasXML" ? "XML" : "PDF"}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                    placement="top"
                                  >
                                    <FileDownloadIcon
                                      className="downloadIcon"
                                      fontSize="small"
                                      variant="fill"
                                      id={`records_uploader_${index}`}
                                      onClick={() =>
                                        id === "hasPDF"
                                          ? onPDFDownload(row)
                                          : onXMLDownload(row)
                                      }
                                    ></FileDownloadIcon>
                                  </HtmlTooltip>
                                </TableCell>
                              );
                            }

                            if (
                              id !== "id" &&
                              id !== "statusStyle" &&
                              id !== "statusClass"
                            ) {
                              return (
                                <TableCell>
                                  <Typography
                                    variant="subtitle1"
                                    id={`records_uploader_${index}`}
                                    className={
                                      id === "statusName" || id === "changeType"
                                        ? row?.statusClass
                                        : "lH-17"
                                    }
                                  >
                                    {row[id]}
                                  </Typography>
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </ComplianceListsContainer>
      {itemsCount !== 0 && itemsCount !== undefined && (
        <PaginationContainer
          itemsCount={itemsCount}
          onPageChange={onPageChangeTrigger}
          resetPage={resetPage}
        />
      )}
    </div>
  );
};

export default RecordsTable;
