export const DEFAULT_PAGE_LIST = [
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
];

export const ISSUANCE_PAGE_LIST = [
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 1000, label: "1000" },
];
