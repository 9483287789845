/*
 * These methods are used for sorting (used in TableView.js, Issuance.js, IssuerStaticData.js, Template.js files)
 */
export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function descendingComparator(a, b, orderBy) {
  if (
    orderBy === "payDate" ||
    orderBy === "valueDate" ||
    orderBy === "statusSince" ||
    orderBy === "timestamp"
  ) {
    let date1, date2;

    if (orderBy !== "timestamp") {
      for (const key in a) {
        if (key === orderBy) {
          date1 = getTimeStamp(a[key]);
        }
      }
      for (const key in b) {
        if (key === orderBy) {
          date2 = getTimeStamp(b[key]);
        }
      }
    } else {
      for (const key in a) {
        if (key === orderBy) {
          const [day, month, year, time] = a[key].split(/[/\s:]/);
          date1 = new Date(year, month - 1, day, ...time.split(":"));
        }
      }
      for (const key in b) {
        if (key === orderBy) {
          const [day, month, year, time] = b[key].split(/[/\s:]/);
          date2 = new Date(year, month - 1, day, ...time.split(":"));
        }
      }
    }
    if (date2 < date1) {
      return -1;
    }
    if (date2 > date1) {
      return 1;
    }
    return 0;
  } else if (orderBy === "numOfRecords") {
    const [recordsA] = a[orderBy].split(" ");
    const [recordsB] = b[orderBy].split(" ");
    const numRecordsA = Number(recordsA);
    const numRecordsB = Number(recordsB);
    if (numRecordsB < numRecordsA) {
      return -1;
    }
    if (numRecordsB > numRecordsA) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}
//convert date to timestamp
const getTimeStamp = (date) => {
  var myDate = date.split("/");
  var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
  return newDate.getTime();
};
