/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Account from './Account';

/**
 * The Position model module.
 * @module model/Position
 * @version 1.0.3
 */
class Position {
    /**
     * Constructs a new <code>Position</code>.
     * @alias module:model/Position
     */
    constructor() { 
        
        Position.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Position</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Position} obj Optional instance to populate.
     * @return {module:model/Position} The populated <code>Position</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Position();

            if (data.hasOwnProperty('Account')) {
                obj['Account'] = ApiClient.convertToType(data['Account'], [Account]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Position</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Position</code>.
     */
    static validateJSON(data) {
        if (data['Account']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Account'])) {
                throw new Error("Expected the field `Account` to be an array in the JSON data but got " + data['Account']);
            }
            // validate the optional field `Account` (array)
            for (const item of data['Account']) {
                Account.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * Model: Account
 * @member {Array.<module:model/Account>} Account
 */
Position.prototype['Account'] = undefined;






export default Position;

