import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useHistory } from "react-router-dom";

import LinkButton from "../LinkButton";
import arrowLeft from "../../assets/arrow-left.svg";

const BackButton = () => {
  const history = useHistory()

  return (
    <Breadcrumbs
      separator={""}
      aria-label="breadcrumb"
      className="breadcrumbs"
      id="cr_breadcrumbs"
    >

      <img
        src={arrowLeft}
        onClick={history.goBack}
        data-testid="arrowLeft"
        id="cr_arrowLeft"
        alt="arrow"
      />
        <LinkButton label="Back" onClick={history.goBack}/> 
    </Breadcrumbs>
  )
}

export default BackButton