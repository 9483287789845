import React, {
  useState,
} from "react";
import { StyledContainer } from "./index.css";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import BlackLists from "./BlackList/BlackLists";
import WhiteLists from "./WhiteList/WhiteLists";
import WhiteListChangeset from "./WhiteList/WhiteListChangeset";
import BlackListChangeset from "./BlackList/BlackListChangeset";
// import IssuerGreyListChangeset from "./IssuerGreyList/IssuerGreyListChangeset";
// import IssuerGreyList from "./IssuerGreyList/IssuerGreyLists";
import IssuerWhiteList from "./IssuerWhiteList/IssuerWhiteList";
import IssuerWhiteListChangeset from "./IssuerWhiteList/IssuerWhiteListChangeset";
import ResponsiveTabs from "./ResponsiveTabs";

const tabsList = [
  { label: "Underlying Blacklist", value: "blackList" },
  { label: "Underlying Blacklist Changesets", value: "blackList_changeset" },
  { label: "Underlying Whitelist", value: "whiteList"  },
  { label: "Underlying Whitelist Changesets", value: "whiteList_changeset" },
  { label: "Issuer Whitelist", value: "issuer_whiteList" },
  { label: "Issuer Whitelist Changeset", value: "issuer_whiteList_changeset" },
  // { label: "Issuer Greylist", value: "issuer_greylist" },
  // { label: "Issuer Greylist Changeset", value: "issuer_greylist_changeset" },
];

function ComplianceLists(props) {
  const [value, setValue] = useState(
    sessionStorage.getItem("complianceList")
      ? JSON.parse(sessionStorage.getItem("complianceList")).tabName
      : "blackList"
  );

  return (
    <React.Fragment>
      <StyledContainer>
        <TabContext value={value}>
          <ResponsiveTabs
            allTabs={tabsList}
            value={value}
            setValue={setValue}
            sessionStorageItem={"complianceList"}
          />
          <TabPanel value="whiteList">
            <WhiteLists />
          </TabPanel>
          <TabPanel value="whiteList_changeset">
            <WhiteListChangeset />
          </TabPanel>
          <TabPanel value="blackList">
            <BlackLists />
          </TabPanel>
          <TabPanel value="blackList_changeset">
            <BlackListChangeset />
          </TabPanel>
          {/* <TabPanel value="issuer_greylist">
            <IssuerGreyList />
          </TabPanel>
          <TabPanel value="issuer_greylist_changeset">
            <IssuerGreyListChangeset />
          </TabPanel> */}
          <TabPanel value="issuer_whiteList">
            <IssuerWhiteList />
          </TabPanel>
          <TabPanel value="issuer_whiteList_changeset">
            <IssuerWhiteListChangeset />
          </TabPanel>
        </TabContext>
      </StyledContainer>
    </React.Fragment>
  );
}

export default ComplianceLists;
