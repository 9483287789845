import React from "react";
import XMLViewer from "react-xml-viewer";
import { ViewXMLBody } from "../index.css";

function ViewXML(props) {
  const xmlData = props.initialXMLData;
  return (
    <React.Fragment>
      <ViewXMLBody>{<XMLViewer xml={xmlData} />}</ViewXMLBody>
    </React.Fragment>
  );
}

export default ViewXML;
