/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The General model module.
 * @module model/General
 * @version 1.0.3
 */
class General {
    /**
     * Constructs a new <code>General</code>.
     * @alias module:model/General
     */
    constructor() { 
        
        General.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>General</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/General} obj Optional instance to populate.
     * @return {module:model/General} The populated <code>General</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new General();

            if (data.hasOwnProperty('PlaceOfIssuance')) {
                obj['PlaceOfIssuance'] = ApiClient.convertToType(data['PlaceOfIssuance'], 'String');
            }
            if (data.hasOwnProperty('DeliveryDate')) {
                obj['DeliveryDate'] = ApiClient.convertToType(data['DeliveryDate'], 'Date');
            }
            if (data.hasOwnProperty('Purpose')) {
                obj['Purpose'] = ApiClient.convertToType(data['Purpose'], 'String');
            }
            if (data.hasOwnProperty('State')) {
                obj['State'] = ApiClient.convertToType(data['State'], 'String');
            }
            if (data.hasOwnProperty('Reason')) {
                obj['Reason'] = ApiClient.convertToType(data['Reason'], 'String');
            }
            if (data.hasOwnProperty('Model')) {
                obj['Model'] = ApiClient.convertToType(data['Model'], 'String');
            }
            if (data.hasOwnProperty('SystemName')) {
                obj['SystemName'] = ApiClient.convertToType(data['SystemName'], 'String');
            }
            if (data.hasOwnProperty('SystemVersion')) {
                obj['SystemVersion'] = ApiClient.convertToType(data['SystemVersion'], 'String');
            }
            if (data.hasOwnProperty('ReceiptId')) {
                obj['ReceiptId'] = ApiClient.convertToType(data['ReceiptId'], 'String');
            }
            if (data.hasOwnProperty('ClientInstructionReference')) {
                obj['ClientInstructionReference'] = ApiClient.convertToType(data['ClientInstructionReference'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>General</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>General</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['PlaceOfIssuance'] && !(typeof data['PlaceOfIssuance'] === 'string' || data['PlaceOfIssuance'] instanceof String)) {
            throw new Error("Expected the field `PlaceOfIssuance` to be a primitive type in the JSON string but got " + data['PlaceOfIssuance']);
        }
        // ensure the json data is a string
        if (data['Purpose'] && !(typeof data['Purpose'] === 'string' || data['Purpose'] instanceof String)) {
            throw new Error("Expected the field `Purpose` to be a primitive type in the JSON string but got " + data['Purpose']);
        }
        // ensure the json data is a string
        if (data['State'] && !(typeof data['State'] === 'string' || data['State'] instanceof String)) {
            throw new Error("Expected the field `State` to be a primitive type in the JSON string but got " + data['State']);
        }
        // ensure the json data is a string
        if (data['Reason'] && !(typeof data['Reason'] === 'string' || data['Reason'] instanceof String)) {
            throw new Error("Expected the field `Reason` to be a primitive type in the JSON string but got " + data['Reason']);
        }
        // ensure the json data is a string
        if (data['Model'] && !(typeof data['Model'] === 'string' || data['Model'] instanceof String)) {
            throw new Error("Expected the field `Model` to be a primitive type in the JSON string but got " + data['Model']);
        }
        // ensure the json data is a string
        if (data['SystemName'] && !(typeof data['SystemName'] === 'string' || data['SystemName'] instanceof String)) {
            throw new Error("Expected the field `SystemName` to be a primitive type in the JSON string but got " + data['SystemName']);
        }
        // ensure the json data is a string
        if (data['SystemVersion'] && !(typeof data['SystemVersion'] === 'string' || data['SystemVersion'] instanceof String)) {
            throw new Error("Expected the field `SystemVersion` to be a primitive type in the JSON string but got " + data['SystemVersion']);
        }
        // ensure the json data is a string
        if (data['ReceiptId'] && !(typeof data['ReceiptId'] === 'string' || data['ReceiptId'] instanceof String)) {
            throw new Error("Expected the field `ReceiptId` to be a primitive type in the JSON string but got " + data['ReceiptId']);
        }
        // ensure the json data is a string
        if (data['ClientInstructionReference'] && !(typeof data['ClientInstructionReference'] === 'string' || data['ClientInstructionReference'] instanceof String)) {
            throw new Error("Expected the field `ClientInstructionReference` to be a primitive type in the JSON string but got " + data['ClientInstructionReference']);
        }

        return true;
    }


}



/**
 * Which CSD to be addressed (CBF, CBL, LuxCSD)[45]
 * @member {String} PlaceOfIssuance
 */
General.prototype['PlaceOfIssuance'] = undefined;

/**
 * Time and date of the data delivery, or creation of the XML file. Used as a reference.[46]
 * @member {Date} DeliveryDate
 */
General.prototype['DeliveryDate'] = undefined;

/**
 * Type of data delivery e.g. new issuance,update etc.[49]
 * @member {String} Purpose
 */
General.prototype['Purpose'] = undefined;

/**
 * Technical status for future use[50]
 * @member {String} State
 */
General.prototype['State'] = undefined;

/**
 * Kind of Action within message type (e.g. increase global note)[51]
 * @member {String} Reason
 */
General.prototype['Reason'] = undefined;

/**
 * Identifier to direct the request either in central register or crypto register[41]
 * @member {String} Model
 */
General.prototype['Model'] = undefined;

/**
 * Name of the provider or of the software used to send the interface/delivery.[47]
 * @member {String} SystemName
 */
General.prototype['SystemName'] = undefined;

/**
 * Version number of the interface format. This is important due to fact that different versions of the interface exist.[48]
 * @member {String} SystemVersion
 */
General.prototype['SystemVersion'] = undefined;

/**
 * Identifier to uniquely identify a request[581]
 * @member {String} ReceiptId
 */
General.prototype['ReceiptId'] = undefined;

/**
 * Client Reference[705]
 * @member {String} ClientInstructionReference
 */
General.prototype['ClientInstructionReference'] = undefined;






export default General;

