import React, { useEffect, useState } from "react";
import { ViewDetailsContainer } from "../index.css";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUserStore } from "../../../store/Store";
import { useRecords } from "../hooks/useRecords";
import FileDowloadIcon from "@mui/icons-material/FileDownload";
import MessagePopup from "../MessagePopup";
import RecordsTable from "../RecordsTable";
import { issuerWhitelistColumns } from "../../../utils/columnUtils";
import { Space } from "antd";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "480px",
    height: "320px",
  },
  reportModal: {
    width: "80%",
    height: "400px",
  },
}));

const IssuerWhiteList = () => {
  const classes = useStyles();
  const [userState] = useUserStore();
  const [data, setData] = useState([]);
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [columns, setColumns] = useState([]);

  const { exportCurrentRecordsList, changePage, itemsCount, loading } =
    useRecords(
      "whitelist-issuer",
      setData,
      setResponseText,
      setShowPopUp,
      setflagPopUp,
      userState
    );

  useEffect(() => {
    let cols = issuerWhitelistColumns.filter(
      (obj) =>
        obj.id !== "changeType" &&
        obj.id !== "protocol" &&
        obj.id !== "errorText"
    );
    setColumns(cols);
  }, []);

  const handlePageChange = (pageNumber, pageSize) =>
    changePage("whitelist-issuer", pageNumber, pageSize);

  const exportCurrentIssuerBlacklist = () =>
    exportCurrentRecordsList("whitelist-issuer");

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  return (
    <React.Fragment>
      <ViewDetailsContainer>
        <MessagePopup
          responseText={responseText}
          showPopUp={showPopUp}
          flagPopUp={flagPopUp}
          closeErrorSuccessPopup={closeErrorSuccessPopup}
        />
        <Grid container>
          <Grid item xs={12} align="end">
            <Space>
              <Button
                variant="outlined"
                id="btn_downloadloadBlackList"
                color="primary"
                className="btnUploadWhiteList"
                onClick={exportCurrentIssuerBlacklist}
              >
                {" "}
                <FileDowloadIcon /> Export Current Issuer Whitelist
              </Button>
            </Space>
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      <RecordsTable
        loading={loading}
        columns={columns}
        data={data}
        onPageChange={handlePageChange}
        itemsCount={itemsCount}
      />
    </React.Fragment>
  );
};

export default IssuerWhiteList;
