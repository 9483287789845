/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AccountData from '../model/AccountData';
import AccountProtocolData from '../model/AccountProtocolData';
import AccountsChangesetsData from '../model/AccountsChangesetsData';
import ChangesetInfo from '../model/ChangesetInfo';
import ChangesetResponse from '../model/ChangesetResponse';
import ChangesetStatus from '../model/ChangesetStatus';
import CountResponse from '../model/CountResponse';
import Error from '../model/Error';
import ProductCheck from '../model/ProductCheck';
import UploadDate from '../model/UploadDate';

/**
* AccountsInternal service.
* @module api/AccountsInternalApi
* @version 1.0.3
*/
export default class AccountsInternalApi {

    /**
    * Constructs a new AccountsInternalApi. 
    * @alias module:api/AccountsInternalApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addNewAccountChangeSet operation.
     * @callback module:api/AccountsInternalApi~addNewAccountChangeSetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ChangesetResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to upload changesets.
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @param {module:api/AccountsInternalApi~addNewAccountChangeSetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ChangesetResponse}
     */
    addNewAccountChangeSet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = ChangesetResponse;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportAccountChangeset operation.
     * @callback module:api/AccountsInternalApi~exportAccountChangesetCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to export csv file with list of changesets.
     * @param {String} id Changeset can be queried using the Unique Identifier - id
     * @param {module:api/AccountsInternalApi~exportAccountChangesetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportAccountChangeset(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling exportAccountChangeset");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets/{id}/export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportAccountRecords operation.
     * @callback module:api/AccountsInternalApi~exportAccountRecordsCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to export csv/xlsx/json file with list of Account records.
     * @param {module:model/String} format Format of file that needs to export
     * @param {module:api/AccountsInternalApi~exportAccountRecordsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportAccountRecords(format, callback) {
      let postBody = null;
      // verify the required parameter 'format' is set
      if (format === undefined || format === null) {
        throw new Error("Missing the required parameter 'format' when calling exportAccountRecords");
      }

      let pathParams = {
      };
      let queryParams = {
        'format': format
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/accounts/records-export', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the exportAccountsTemplate operation.
     * @callback module:api/AccountsInternalApi~exportAccountsTemplateCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to export CSV file with template CSV file.
     * @param {module:api/AccountsInternalApi~exportAccountsTemplateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    exportAccountsTemplate(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets-template', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccounts operation.
     * @callback module:api/AccountsInternalApi~getAccountsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get a list of accounts data.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page, starts from 1 (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/AccountsInternalApi~getAccountsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountData}
     */
    getAccounts(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AccountData;
      return this.apiClient.callApi(
        '/di/v1/accounts/records', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountsChangesets operation.
     * @callback module:api/AccountsInternalApi~getAccountsChangesetsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountsChangesetsData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get paginated list of all Accounts changesetsItems.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:model/ChangesetStatus} opts.status This parameter is used for filtering based on the status of the listed items.
     * @param {module:api/AccountsInternalApi~getAccountsChangesetsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountsChangesetsData}
     */
    getAccountsChangesets(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize'],
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AccountsChangesetsData;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountsPendingCount operation.
     * @callback module:api/AccountsInternalApi~getAccountsPendingCountCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CountResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to return the count of the pending items.
     * @param {module:api/AccountsInternalApi~getAccountsPendingCountCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CountResponse}
     */
    getAccountsPendingCount(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CountResponse;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets-pending-count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAccountsTime operation.
     * @callback module:api/AccountsInternalApi~getAccountsTimeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UploadDate} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get when was the last time that a changeset was added.
     * @param {module:api/AccountsInternalApi~getAccountsTimeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UploadDate}
     */
    getAccountsTime(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UploadDate;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets-time', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getChangesetById operation.
     * @callback module:api/AccountsInternalApi~getChangesetByIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ChangesetInfo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get specific ChangesetItem and it's full details.
     * @param {String} id Changeset can be queried using the Unique Identifier - id
     * @param {module:api/AccountsInternalApi~getChangesetByIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ChangesetInfo}
     */
    getChangesetById(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getChangesetById");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ChangesetInfo;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getChangesetRecords operation.
     * @callback module:api/AccountsInternalApi~getChangesetRecordsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountProtocolData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get protocol for changeset.
     * @param {String} id Changeset can be queried using the Unique Identifier - id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page that needs to be sent (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/AccountsInternalApi~getChangesetRecordsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountProtocolData}
     */
    getChangesetRecords(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getChangesetRecords");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AccountProtocolData;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets/{id}/records', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getIssuerNames operation.
     * @callback module:api/AccountsInternalApi~getIssuerNamesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<String>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get a list of issuer names.
     * @param {module:api/AccountsInternalApi~getIssuerNamesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<String>}
     */
    getIssuerNames(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/di/v1/accounts/records-issuer-names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPendingAccountChangesets operation.
     * @callback module:api/AccountsInternalApi~getPendingAccountChangesetsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccountsChangesetsData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get list of pending items.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Number of the page, starts from 1 (default to 1)
     * @param {Number} opts.pagesize Count of items per page (default to 50)
     * @param {module:api/AccountsInternalApi~getPendingAccountChangesetsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccountsChangesetsData}
     */
    getPendingAccountChangesets(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AccountsChangesetsData;
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets-pending', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getShortCodeByIssuerLei operation.
     * @callback module:api/AccountsInternalApi~getShortCodeByIssuerLeiCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get specific shortcode based on lei.
     * @param {String} issuerLei Shortcode can be queried using the LEI - lei
     * @param {module:api/AccountsInternalApi~getShortCodeByIssuerLeiCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getShortCodeByIssuerLei(issuerLei, callback) {
      let postBody = null;
      // verify the required parameter 'issuerLei' is set
      if (issuerLei === undefined || issuerLei === null) {
        throw new Error("Missing the required parameter 'issuerLei' when calling getShortCodeByIssuerLei");
      }

      let pathParams = {
        'issuerLei': issuerLei
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/di/v1/accounts/issuer-short-code/{issuerLei}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the issuanceCheck operation.
     * @callback module:api/AccountsInternalApi~issuanceCheckCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used check if account is enabled
     * @param {module:model/ProductCheck} ProductCheck 
     * @param {module:api/AccountsInternalApi~issuanceCheckCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    issuanceCheck(ProductCheck, callback) {
      let postBody = ProductCheck;
      // verify the required parameter 'ProductCheck' is set
      if (ProductCheck === undefined || ProductCheck === null) {
        throw new Error("Missing the required parameter 'ProductCheck' when calling issuanceCheck");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['text/plain', 'application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/di/v1/accounts/issuance-check', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateChangesetById operation.
     * @callback module:api/AccountsInternalApi~updateChangesetByIdCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to update specific ChangesetItem
     * @param {String} id Changeset can be selected using the Unique Identifier - id
     * @param {module:model/String} status Defines the new status of the Changeset approved/rejected
     * @param {module:api/AccountsInternalApi~updateChangesetByIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    updateChangesetById(id, status, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateChangesetById");
      }
      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling updateChangesetById");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'status': status
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/di/v1/accounts/changesets/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
