/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The IsinHistoryLog model module.
 * @module model/IsinHistoryLog
 * @version 1.0.3
 */
class IsinHistoryLog {
    /**
     * Constructs a new <code>IsinHistoryLog</code>.
     * @alias module:model/IsinHistoryLog
     * @param externalProcessingStatus {String} External status enum value
     * @param internalStatus {String} Internal status enum value
     * @param externalOverallStatus {String} Overall status enum value
     * @param errorMessage {String} Error massage
     * @param affectedAttributes {String} Affected attributes
     * @param contentMap {Object.<String, String>} Info
     * @param dateTime {Date} DateTime
     * @param dti {String} DTI
     * @param isin {String} ISIN
     * @param logIndicator {String} Log Indicator
     * @param userId {String} UserID
     */
    constructor(externalProcessingStatus, internalStatus, externalOverallStatus, errorMessage, affectedAttributes, contentMap, dateTime, dti, isin, logIndicator, userId) { 
        
        IsinHistoryLog.initialize(this, externalProcessingStatus, internalStatus, externalOverallStatus, errorMessage, affectedAttributes, contentMap, dateTime, dti, isin, logIndicator, userId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, externalProcessingStatus, internalStatus, externalOverallStatus, errorMessage, affectedAttributes, contentMap, dateTime, dti, isin, logIndicator, userId) { 
        obj['externalProcessingStatus'] = externalProcessingStatus;
        obj['internalStatus'] = internalStatus;
        obj['externalOverallStatus'] = externalOverallStatus;
        obj['errorMessage'] = errorMessage;
        obj['affectedAttributes'] = affectedAttributes;
        obj['contentMap'] = contentMap;
        obj['dateTime'] = dateTime;
        obj['dti'] = dti;
        obj['isin'] = isin;
        obj['logIndicator'] = logIndicator;
        obj['userId'] = userId;
    }

    /**
     * Constructs a <code>IsinHistoryLog</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/IsinHistoryLog} obj Optional instance to populate.
     * @return {module:model/IsinHistoryLog} The populated <code>IsinHistoryLog</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new IsinHistoryLog();

            if (data.hasOwnProperty('externalProcessingStatus')) {
                obj['externalProcessingStatus'] = ApiClient.convertToType(data['externalProcessingStatus'], 'String');
            }
            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
            if (data.hasOwnProperty('externalOverallStatus')) {
                obj['externalOverallStatus'] = ApiClient.convertToType(data['externalOverallStatus'], 'String');
            }
            if (data.hasOwnProperty('errorMessage')) {
                obj['errorMessage'] = ApiClient.convertToType(data['errorMessage'], 'String');
            }
            if (data.hasOwnProperty('affectedAttributes')) {
                obj['affectedAttributes'] = ApiClient.convertToType(data['affectedAttributes'], 'String');
            }
            if (data.hasOwnProperty('contentMap')) {
                obj['contentMap'] = ApiClient.convertToType(data['contentMap'], {'String': 'String'});
            }
            if (data.hasOwnProperty('dateTime')) {
                obj['dateTime'] = ApiClient.convertToType(data['dateTime'], 'Date');
            }
            if (data.hasOwnProperty('dti')) {
                obj['dti'] = ApiClient.convertToType(data['dti'], 'String');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('logIndicator')) {
                obj['logIndicator'] = ApiClient.convertToType(data['logIndicator'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>IsinHistoryLog</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>IsinHistoryLog</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of IsinHistoryLog.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['externalProcessingStatus'] && !(typeof data['externalProcessingStatus'] === 'string' || data['externalProcessingStatus'] instanceof String)) {
            throw new Error("Expected the field `externalProcessingStatus` to be a primitive type in the JSON string but got " + data['externalProcessingStatus']);
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }
        // ensure the json data is a string
        if (data['externalOverallStatus'] && !(typeof data['externalOverallStatus'] === 'string' || data['externalOverallStatus'] instanceof String)) {
            throw new Error("Expected the field `externalOverallStatus` to be a primitive type in the JSON string but got " + data['externalOverallStatus']);
        }
        // ensure the json data is a string
        if (data['errorMessage'] && !(typeof data['errorMessage'] === 'string' || data['errorMessage'] instanceof String)) {
            throw new Error("Expected the field `errorMessage` to be a primitive type in the JSON string but got " + data['errorMessage']);
        }
        // ensure the json data is a string
        if (data['affectedAttributes'] && !(typeof data['affectedAttributes'] === 'string' || data['affectedAttributes'] instanceof String)) {
            throw new Error("Expected the field `affectedAttributes` to be a primitive type in the JSON string but got " + data['affectedAttributes']);
        }
        // ensure the json data is a string
        if (data['dti'] && !(typeof data['dti'] === 'string' || data['dti'] instanceof String)) {
            throw new Error("Expected the field `dti` to be a primitive type in the JSON string but got " + data['dti']);
        }
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['logIndicator'] && !(typeof data['logIndicator'] === 'string' || data['logIndicator'] instanceof String)) {
            throw new Error("Expected the field `logIndicator` to be a primitive type in the JSON string but got " + data['logIndicator']);
        }
        // ensure the json data is a string
        if (data['userId'] && !(typeof data['userId'] === 'string' || data['userId'] instanceof String)) {
            throw new Error("Expected the field `userId` to be a primitive type in the JSON string but got " + data['userId']);
        }

        return true;
    }


}

IsinHistoryLog.RequiredProperties = ["externalProcessingStatus", "internalStatus", "externalOverallStatus", "errorMessage", "affectedAttributes", "contentMap", "dateTime", "dti", "isin", "logIndicator", "userId"];

/**
 * External status enum value
 * @member {String} externalProcessingStatus
 */
IsinHistoryLog.prototype['externalProcessingStatus'] = undefined;

/**
 * Internal status enum value
 * @member {String} internalStatus
 */
IsinHistoryLog.prototype['internalStatus'] = undefined;

/**
 * Overall status enum value
 * @member {String} externalOverallStatus
 */
IsinHistoryLog.prototype['externalOverallStatus'] = undefined;

/**
 * Error massage
 * @member {String} errorMessage
 */
IsinHistoryLog.prototype['errorMessage'] = undefined;

/**
 * Affected attributes
 * @member {String} affectedAttributes
 */
IsinHistoryLog.prototype['affectedAttributes'] = undefined;

/**
 * Info
 * @member {Object.<String, String>} contentMap
 */
IsinHistoryLog.prototype['contentMap'] = undefined;

/**
 * DateTime
 * @member {Date} dateTime
 */
IsinHistoryLog.prototype['dateTime'] = undefined;

/**
 * DTI
 * @member {String} dti
 */
IsinHistoryLog.prototype['dti'] = undefined;

/**
 * ISIN
 * @member {String} isin
 */
IsinHistoryLog.prototype['isin'] = undefined;

/**
 * Log Indicator
 * @member {String} logIndicator
 */
IsinHistoryLog.prototype['logIndicator'] = undefined;

/**
 * UserID
 * @member {String} userId
 */
IsinHistoryLog.prototype['userId'] = undefined;






export default IsinHistoryLog;

