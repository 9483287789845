import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useReducer } from "react";
import { Route, Redirect } from "react-router-dom";
import Routes from "./Routes";
import NavBar from "./components/NavBar/NavBar";
import "./App.css";
import Login from "./modules/Login/Login";
import { useUserStore } from "./store/Store";
import { loginSuccessful, logout } from "./store/actions/UserActions";
import { getPendingCount, getCountsBySection } from "./utility";
import decode from "jwt-decode";
import axios from "axios";
import CookieConsent from "./CookieConsent";
import { mappingRoles } from "./userRoleJSON";
import MessagePopup from "./modules/ComplianceList/MessagePopup";
import { AUTHORIZED_CEFI_ROLES } from "./constants";

export const AppCommonContext = React.createContext();

export const roleRights = (roles) => {
  let rights = [];
  for (var i = 0; i < roles.length; i++) {
    let role = roles[i];
    rights.push(mappingRoles[role]);
  }
  rights = rights.flat();
  const uniqueRights = rights.filter((userRight, index) => {
    return rights.indexOf(userRight) === index;
  });
  return uniqueRights;
};

const initialState = {
  isin: "",
  response: "",
  receiptRetryCount: 0,
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TRIGGER_RECEIPT":
      return {
        isin: action.payload,
        response: "",
        error: "",
        receiptRetryCount: state.receiptRetryCount + 1,
      };
    case "RESET_RECEIPT_RETRY_COUNT":
      return { ...initialState };
    case "SET_ERROR_RESPONSE":
      return {
        ...state,
        error: "",
        receiptRetryCount: 0,
        response: action.payload,
      };
    case "ERROR":
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
};

function App() {
  const [showDetails, setShowDetails] = useState(false);
  const [timeStamp, setTimeStamp] = useState();
  const [userState, userDispatch] = useUserStore();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [cefiCounter, setCefiCounter] = useState(0);
  const [individualPendingSectionCount, setIndividualPendingSectionCount] =
    useState([0, 0, 0, 0, 0]);
  const countApiFlagRef = React.useRef("firstApiCall");
  const pendingApiFlagRef = React.useRef("firstApiCall");
  const countCefiFlagRef = React.useRef("firstApiCall");
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isin, receiptRetryCount } = state;
  const receiptRetriesVariable = process.env.REACT_APP_RECEIPT_RETRIES
    ? process.env.REACT_APP_RECEIPT_RETRIES
    : 3;
  const receiptRetries = parseInt(receiptRetriesVariable);
  const receiptIntervalVariable = process.env.REACT_APP_RECEIPT_INTERVAL
    ? process.env.REACT_APP_RECEIPT_INTERVAL
    : 2;
  const receiptInterval = parseInt(receiptIntervalVariable);
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    if (receiptRetryCount > 0 && receiptRetryCount <= receiptRetries) {
      let receiptTemplateInterval = receiptInterval * 1000;
      setTimeout(() => {
        receiptTemplate(isin);
      }, receiptTemplateInterval);
    }
  }, [receiptRetryCount, isin]);

  useEffect(() => {
    if (individualPendingSectionCount) {
      const pendingCount = getPendingCount(individualPendingSectionCount);
      setPendingCount(pendingCount);
    }
  }, [individualPendingSectionCount]);

  const receiptTemplate = (isin) => {
    let jwtToken = sessionStorage.getItem("jwtToken");
    axios
      .get("/di/receipt?isin=" + isin, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })

      .then((res) => {
        //checking if response status is Error
        if (res.data.Status === "Error") {
          dispatch({
            type: "SET_ERROR_RESPONSE",
            payload: res.data.Description,
          });
        }

        //checking if response data is empty,then updates the counter and call receiptTemplate function again
        if (!Object.keys(res.data).length) {
          dispatch({ type: "TRIGGER_RECEIPT", payload: isin });
        }

        //To display popup when daml is response is empty for all the receipt retries. In last retry error popup is shown.
        if (
          !Object.keys(res.data).length &&
          state.receiptRetryCount === receiptRetries
        ) {
          dispatch({
            type: "SET_ERROR_RESPONSE",
            payload:
              "No response received. Please check Issuance details for more information",
          });
        }
      })
      .catch((error) => {
        let errRes = error?.response?.data?.message
          ? error.response.data.message
          : "Unknown Server Error. Please try later.";
        dispatch({ type: "ERROR", payload: errRes });
      });
  };

  const getCefiCounts = async () => {
    if (userState.userRoles.includes("Clearstream Compliance")) {
      return;
    }
    let user = sessionStorage.getItem("user");
    if (user !== null) {
      let jwtToken = sessionStorage.getItem("jwtToken");
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      };

      let bookingBuildQuery = {
        reviewStatus: {
          value: "Pending",
          operator: "=",
        },
      };
      let reviewBuildQuery = {
        status: {
          value: "Pending",
          operator: "=",
        },
      };

      const cefiBookingCountPromise = axios
        .post("/cefi/v1/bookings/review/count", bookingBuildQuery, config)
        .catch(() => showError("Some resources are not available"));
      const cefiSecuritiesCountPromise = axios
        .post("/cefi/v1/securities/reviewList/count", reviewBuildQuery, config)
        .catch(() => showError("Some resources are not available"));
      let reviewsCount = 0;
      await axios
        .all([cefiBookingCountPromise, cefiSecuritiesCountPromise])
        .then(
          axios.spread((...res) => {
            let bookingCount = res[0]?.status === 200 ? res[0]?.data || 0 : 0;
            let securitiesCount =
              res[1]?.status === 200 ? res[1]?.data || 0 : 0;
            reviewsCount = bookingCount + securitiesCount;
            setCefiCounter(reviewsCount);
            countApiFlagRef.current = "successApiCall";
          })
        )
        .catch((error) => {
          showError("Some resources are not available");
        });
    }
  };

  const getPending = async () => {
    const individualPendingCount = await getCountsBySection(userState);
    if (individualPendingCount) {
      setIndividualPendingSectionCount(individualPendingCount);
      pendingApiFlagRef.current = "successApiCall";
    }
  };

  const checkAuthCefiRole = (cefiRoles) => {
    return cefiRoles.some((role) => AUTHORIZED_CEFI_ROLES.includes(role));
  };

  useEffect(() => {
    let timeoutId;
    if (process.env.REACT_APP_ENABLED === "false") {
      //for mockIam login in local
      if (sessionStorage.getItem("user")) {
        const token = decode(sessionStorage.getItem("jwtToken"));
        const cefiRoles = token.central_registry_roles;
        let fetchCefiCount = false;
        if (cefiRoles.length > 0) {
          fetchCefiCount = checkAuthCefiRole(cefiRoles);
        }
        userDispatch(
          loginSuccessful({
            userRoles: token.digitiser_roles,
            userRoleRight: roleRights(token.digitiser_roles),
          })
        );
        setIsLoggedIn(true);
        const start = Math.floor(Date.now() / 1000);
        const tokenExp = token.exp;
        const remainingTimeInMilliSeconds = (tokenExp - start) * 1000 - 200;
        timeoutId = setTimeout(() => {
          sessionStorage.clear();
          window.location.reload(false);
          userDispatch(logout());
        }, remainingTimeInMilliSeconds);
        getAppCounters(timeoutId, fetchCefiCount);
      }
    } else {
      const jwtToken = sessionStorage.getItem("jwtToken");
      if (userState.isAuthenticated && jwtToken !== null) {
        const token = decode(jwtToken);
        const cefiRoles = token.central_registry_roles;
        let fetchCefiCount = false;
        if (cefiRoles.length > 0) {
          fetchCefiCount = checkAuthCefiRole(cefiRoles);
        }
        userDispatch(
          loginSuccessful({
            expirationOfTokenInSec: token.exp,
            userRoles: token.digitiser_roles,
            userRoleRight: roleRights(token.digitiser_roles),
          })
        );
        //
        const start = Math.floor(Date.now() / 1000);
        const tokenExp = token.exp;
        const remainingTimeInMilliSeconds = (tokenExp - start) * 1000 - 200;
        timeoutId = setTimeout(() => {
          sessionStorage.clear();
          window.location.reload(false);
        }, remainingTimeInMilliSeconds);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userState.idToken}`;

        getAppCounters(timeoutId, fetchCefiCount);
      } else {
        sessionStorage.removeItem("jwtToken");
        window.location.reload();
      }
    }
  }, [userState.isAuthenticated]);

  const getAppCounters = (timeoutId, fetchCefiCount) => {
    const pendingTimer = process.env.REACT_APP_COUNT_PENDING_TIMER
      ? process.env.REACT_APP_COUNT_PENDING_TIMER
      : 120;
    const cefiCountsTimer = process.env.REACT_APP_COUNT_CEFICOUNT_TIMER
      ? process.env.REACT_APP_COUNT_CEFICOUNT_TIMER
      : 120;
    //checks if api is called for first time
    if (pendingApiFlagRef.current === "firstApiCall") {
      getPending();
    }
    if (countCefiFlagRef.current === "firstApiCall" && fetchCefiCount) {
      getCefiCounts();
    }
    const intervalID = setInterval(() => {
      //checks if api response is recieved,success/failure
      if (pendingApiFlagRef.current === "successApiCall") {
        pendingApiFlagRef.current = "resetApiCall";
        getPending();
      }
    }, pendingTimer * 1000);
    let cefiCounts = 0;
    if (fetchCefiCount) {
      cefiCounts = setInterval(() => {
        if (countCefiFlagRef.current === "successApiCall") {
          countCefiFlagRef.current = "resetApiCall";
          getCefiCounts();
        }
      }, cefiCountsTimer * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalID);
      clearInterval(cefiCounts);
    };
  };
  const showError = (message) => {
    setResponseText(message);
    setShowPopUp(true);
    setTimeout(() => {
      setResponseText("");
      setShowPopUp(false);
    }, 5000);
  };

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  const hasFeatureLevel = (level) => {
    return (
      process.env.REACT_APP_FEATURE_LEVEL === undefined ||
      process.env.REACT_APP_FEATURE_LEVEL >= level
    );
  };
  return (
    <div>
      <AppCommonContext.Provider
        value={{
          state,
          dispatch,
          individualPendingSectionCount,
          setIndividualPendingSectionCount,
          showDetails,
          setShowDetails,
          setTimeStamp,
          hasFeatureLevel,
        }}
      >
        {process.env.REACT_APP_ENABLED === "true" ? (
          <div>
            {userState.isAuthenticated ? <CookieConsent /> : ""}
            <NavBar
              setShowDetails={setShowDetails}
              timeStamp={timeStamp}
              setTimeStamp={setTimeStamp}
              count={pendingCount}
              ceFiCount={cefiCounter}
            />
            {userState.isAuthenticated && (
              <MessagePopup
                responseText={responseText}
                showPopUp={showPopUp}
                flagPopUp={"error"}
                closeErrorSuccessPopup={closeErrorSuccessPopup}
              />
            )}
            {userState.isAuthenticated && (
              <>
                <Routes />
              </>
            )}
            <Route exact path="/">
              {userState.isAuthenticated ? (
                userState.userRoles.includes("Clearstream Compliance") ? (
                  <Redirect to="/pending" />
                ) : (
                  <Redirect to="/issuance" />
                )
              ) : (
                ""
              )}
            </Route>
          </div>
        ) : (
          <div>
            {isLoggedIn ? <CookieConsent /> : ""}

            <NavBar
              isLoggedIn={isLoggedIn}
              setShowDetails={setShowDetails}
              setTimeStamp={setTimeStamp}
              ceFiCount={cefiCounter}
              count={pendingCount}
            />
            {isLoggedIn && (
              <MessagePopup
                responseText={responseText}
                showPopUp={showPopUp}
                flagPopUp={"error"}
                closeErrorSuccessPopup={closeErrorSuccessPopup}
              />
            )}
            {!isLoggedIn ? (
              <>
                <Route
                  render={(props) => (
                    <Login {...props} setIsLoggedIn={setIsLoggedIn} />
                  )}
                ></Route>
              </>
            ) : (
              <>
                <Routes />
              </>
            )}
          </div>
        )}
      </AppCommonContext.Provider>
    </div>
  );
}

export default App;
