import React from 'react'

export const Authenticating = () => (
  <div>
    <h1>Authentication in progress</h1>
    <p>You will be redirected to the login page.</p>
  </div>
);

export const AuthenticateError = () => (
  <div>
    <h1>Error authentication</h1>
    <p>An error occurred during authentication.</p>
  </div>
);