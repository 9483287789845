/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StatusByIsinModel model module.
 * @module model/StatusByIsinModel
 * @version 1.0.3
 */
class StatusByIsinModel {
    /**
     * Constructs a new <code>StatusByIsinModel</code>.
     * @alias module:model/StatusByIsinModel
     * @param isin {String} Isin
     * @param externalProcessingStatus {String} External status enum value
     * @param internalStatus {String} Internal status enum value
     * @param timestamp {Date} TimeStamp
     */
    constructor(isin, externalProcessingStatus, internalStatus, timestamp) { 
        
        StatusByIsinModel.initialize(this, isin, externalProcessingStatus, internalStatus, timestamp);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, isin, externalProcessingStatus, internalStatus, timestamp) { 
        obj['isin'] = isin;
        obj['externalProcessingStatus'] = externalProcessingStatus;
        obj['internalStatus'] = internalStatus;
        obj['timestamp'] = timestamp;
    }

    /**
     * Constructs a <code>StatusByIsinModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatusByIsinModel} obj Optional instance to populate.
     * @return {module:model/StatusByIsinModel} The populated <code>StatusByIsinModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatusByIsinModel();

            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('externalProcessingStatus')) {
                obj['externalProcessingStatus'] = ApiClient.convertToType(data['externalProcessingStatus'], 'String');
            }
            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>StatusByIsinModel</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>StatusByIsinModel</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of StatusByIsinModel.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['externalProcessingStatus'] && !(typeof data['externalProcessingStatus'] === 'string' || data['externalProcessingStatus'] instanceof String)) {
            throw new Error("Expected the field `externalProcessingStatus` to be a primitive type in the JSON string but got " + data['externalProcessingStatus']);
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }

        return true;
    }


}

StatusByIsinModel.RequiredProperties = ["isin", "externalProcessingStatus", "internalStatus", "timestamp"];

/**
 * Isin
 * @member {String} isin
 */
StatusByIsinModel.prototype['isin'] = undefined;

/**
 * External status enum value
 * @member {String} externalProcessingStatus
 */
StatusByIsinModel.prototype['externalProcessingStatus'] = undefined;

/**
 * Internal status enum value
 * @member {String} internalStatus
 */
StatusByIsinModel.prototype['internalStatus'] = undefined;

/**
 * TimeStamp
 * @member {Date} timestamp
 */
StatusByIsinModel.prototype['timestamp'] = undefined;






export default StatusByIsinModel;

