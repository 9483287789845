import React from "react";
import Typography from "@material-ui/core/Typography";
import { ErrorSuccessPopupBody } from "./index.css";
import { Grid } from "@material-ui/core";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@material-ui/icons/Cancel";

function RecieptErrorPopup(props) {
  return (
    <ErrorSuccessPopupBody>
      <div id="receipt_errorMsgBox" className="errorMsgBox">
        <Grid container>
          <Grid item xs={1}>
            <WarningAmberIcon
              id="receipt_Icon"
              className="successOrErrorIcon"
            />
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h5"
              id="receipt_errorLabel"
              className="errorLabel"
            >
              Error:
            </Typography>
          </Grid>
          <Grid item xs={1} align="right">
            <CancelIcon
              className="closeIcon"
              id="receipt_closeIcon"
              onClick={() => {
                props?.handleClose();
              }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <label id="receipt_respMsg" className="respMsg">
              <Typography
                variant="h6"
                id="receipt_errorText"
                className="errorText"
                data-testid="recieptText"
              >
                {props.recieptErrorPopupText}
              </Typography>
            </label>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
    </ErrorSuccessPopupBody>
  );
}

export default RecieptErrorPopup;
