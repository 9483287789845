import React from "react";
import { useNoIAM } from "../../config";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { UserContext } from "../Store";
import { UserReducer } from "../reducers/UserReducer";
import { USER_INITIAL_STATE } from "../initialStates/UserInitialState";
import decode from "jwt-decode";

export const UserProvider = ({ children }) => {
  // here we have recieved some props and destructured children from those props
  const credentials = useNoIAM // fn declaration based on condition whether Xact/Xact_Clearstream is enabled or not (useNoIAM !== true --> Xact/Xact_Clearstream enabled)
    ? useCredentialsFromSessionStorage // if useNoIAM is true use jwt token from session storage (refer lines 48 to 54)
    : useCredentialsFromOidc; // if usenoIAM is false use extracted jwt token via Xact/Xact_Clearstream (refer lines 30 to 46)

  const { idToken } = credentials(); // calling credentials fn and storing the value in idToken

  const [userState, userDispatch] = React.useReducer(UserReducer, {
    // useReducer fn is responsible for a controlled mechanism for changing state, it takes type of reducer and some initial state
    ...USER_INITIAL_STATE, // useReducer fn gives state(userState) and a dispatch fn (userDispatch) to change that state
    isAuthenticated: !!idToken, // while we are passing the initial state values to userReducer we already have idToken in place from credentials() fn,
    idToken, // we are sending idToken and isAuthenticated initially itself (got these value from credentials() fn) so the value inside USER_INITIAL_STATE will get overriden
  });

  return (
    <UserContext.Provider value={[userState, userDispatch]}>
      {" "}
      {/* creating context provider and passing some values to children component */}
      {children}{" "}
      {/* here userState is having different objects inside it, and userDispatch is a fn used to change userState */}
    </UserContext.Provider>
  );
};

/*
 * This method is used to read token from Response Headers (in Network Log) in case of Xact/Xact_Clearstream
 */
function useCredentialsFromOidc() {
  const oidcProps = useReactOidc(); // Hook to read data from Response headers (in Network Log)
  let idToken = sessionStorage.getItem("jwtToken");
  if (!idToken) {
    if (oidcProps.oidcUser) {
      const user = oidcProps.oidcUser;
      idToken = user?.id_token;
      sessionStorage.setItem("jwtToken", idToken);
    }
  } else {
    const token = decode(idToken);
    if (token.exp && Math.floor(Date.now() / 1000) > token.exp) {
      window.location.reload();
    }
  }
  return { idToken };
}

/*
 * This method is used to access token stored in session storage at the time of login via MockIAM login screen
 */
function useCredentialsFromSessionStorage() {
  let idToken = sessionStorage.getItem("jwtToken");

  return { idToken };
}
