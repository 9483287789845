/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ChangesetBriefInfo model module.
 * @module model/ChangesetBriefInfo
 * @version 1.0.3
 */
class ChangesetBriefInfo {
    /**
     * Constructs a new <code>ChangesetBriefInfo</code>.
     * @alias module:model/ChangesetBriefInfo
     */
    constructor() { 
        
        ChangesetBriefInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ChangesetBriefInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ChangesetBriefInfo} obj Optional instance to populate.
     * @return {module:model/ChangesetBriefInfo} The populated <code>ChangesetBriefInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChangesetBriefInfo();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('statusName')) {
                obj['statusName'] = ApiClient.convertToType(data['statusName'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], ['String']);
            }
            if (data.hasOwnProperty('initiatorUserName')) {
                obj['initiatorUserName'] = ApiClient.convertToType(data['initiatorUserName'], 'String');
            }
            if (data.hasOwnProperty('initiatorUserId')) {
                obj['initiatorUserId'] = ApiClient.convertToType(data['initiatorUserId'], 'String');
            }
            if (data.hasOwnProperty('uploaderUserId')) {
                obj['uploaderUserId'] = ApiClient.convertToType(data['uploaderUserId'], 'String');
            }
            if (data.hasOwnProperty('uploaderUserName')) {
                obj['uploaderUserName'] = ApiClient.convertToType(data['uploaderUserName'], 'String');
            }
            if (data.hasOwnProperty('numOfRecords')) {
                obj['numOfRecords'] = ApiClient.convertToType(data['numOfRecords'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ChangesetBriefInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ChangesetBriefInfo</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['statusName'] && !(typeof data['statusName'] === 'string' || data['statusName'] instanceof String)) {
            throw new Error("Expected the field `statusName` to be a primitive type in the JSON string but got " + data['statusName']);
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['isin'])) {
            throw new Error("Expected the field `isin` to be an array in the JSON data but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['initiatorUserName'] && !(typeof data['initiatorUserName'] === 'string' || data['initiatorUserName'] instanceof String)) {
            throw new Error("Expected the field `initiatorUserName` to be a primitive type in the JSON string but got " + data['initiatorUserName']);
        }
        // ensure the json data is a string
        if (data['initiatorUserId'] && !(typeof data['initiatorUserId'] === 'string' || data['initiatorUserId'] instanceof String)) {
            throw new Error("Expected the field `initiatorUserId` to be a primitive type in the JSON string but got " + data['initiatorUserId']);
        }
        // ensure the json data is a string
        if (data['uploaderUserId'] && !(typeof data['uploaderUserId'] === 'string' || data['uploaderUserId'] instanceof String)) {
            throw new Error("Expected the field `uploaderUserId` to be a primitive type in the JSON string but got " + data['uploaderUserId']);
        }
        // ensure the json data is a string
        if (data['uploaderUserName'] && !(typeof data['uploaderUserName'] === 'string' || data['uploaderUserName'] instanceof String)) {
            throw new Error("Expected the field `uploaderUserName` to be a primitive type in the JSON string but got " + data['uploaderUserName']);
        }

        return true;
    }


}



/**
 * id of the item
 * @member {String} id
 */
ChangesetBriefInfo.prototype['id'] = undefined;

/**
 * Current status of the compliance changeset. TO_REVIEW APPROVED REJECTED 
 * @member {String} statusName
 */
ChangesetBriefInfo.prototype['statusName'] = undefined;

/**
 * Type of changeset   Blacklist   Whitelist   Account   Redemption
 * @member {String} type
 */
ChangesetBriefInfo.prototype['type'] = undefined;

/**
 * Date and time of upload based on ISO 8601 standard
 * @member {Date} timestamp
 */
ChangesetBriefInfo.prototype['timestamp'] = undefined;

/**
 * Only be filled with a values for items in status 'DRP upload review'
 * @member {Array.<String>} isin
 */
ChangesetBriefInfo.prototype['isin'] = undefined;

/**
 * initiator user name
 * @member {String} initiatorUserName
 */
ChangesetBriefInfo.prototype['initiatorUserName'] = undefined;

/**
 * Shows internal user ID (xx123) - not XACT user
 * @member {String} initiatorUserId
 */
ChangesetBriefInfo.prototype['initiatorUserId'] = undefined;

/**
 * User id of uploader
 * @member {String} uploaderUserId
 */
ChangesetBriefInfo.prototype['uploaderUserId'] = undefined;

/**
 * User name of uploader
 * @member {String} uploaderUserName
 */
ChangesetBriefInfo.prototype['uploaderUserName'] = undefined;

/**
 * @member {Number} numOfRecords
 */
ChangesetBriefInfo.prototype['numOfRecords'] = undefined;






export default ChangesetBriefInfo;

