/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ValidationError from './ValidationError';

/**
 * The BusinessValidationError model module.
 * @module model/BusinessValidationError
 * @version 1.0.3
 */
class BusinessValidationError {
    /**
     * Constructs a new <code>BusinessValidationError</code>.
     * @alias module:model/BusinessValidationError
     */
    constructor() { 
        
        BusinessValidationError.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BusinessValidationError</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BusinessValidationError} obj Optional instance to populate.
     * @return {module:model/BusinessValidationError} The populated <code>BusinessValidationError</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BusinessValidationError();

            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('messageId')) {
                obj['messageId'] = ApiClient.convertToType(data['messageId'], 'String');
            }
            if (data.hasOwnProperty('timeStamp')) {
                obj['timeStamp'] = ApiClient.convertToType(data['timeStamp'], 'String');
            }
            if (data.hasOwnProperty('validationErrors')) {
                obj['validationErrors'] = ApiClient.convertToType(data['validationErrors'], ['String']);
            }
            if (data.hasOwnProperty('businessValidationErrors')) {
                obj['businessValidationErrors'] = ApiClient.convertToType(data['businessValidationErrors'], [ValidationError]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>BusinessValidationError</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BusinessValidationError</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
            throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
        }
        // ensure the json data is a string
        if (data['messageId'] && !(typeof data['messageId'] === 'string' || data['messageId'] instanceof String)) {
            throw new Error("Expected the field `messageId` to be a primitive type in the JSON string but got " + data['messageId']);
        }
        // ensure the json data is a string
        if (data['timeStamp'] && !(typeof data['timeStamp'] === 'string' || data['timeStamp'] instanceof String)) {
            throw new Error("Expected the field `timeStamp` to be a primitive type in the JSON string but got " + data['timeStamp']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['validationErrors'])) {
            throw new Error("Expected the field `validationErrors` to be an array in the JSON data but got " + data['validationErrors']);
        }
        if (data['businessValidationErrors']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['businessValidationErrors'])) {
                throw new Error("Expected the field `businessValidationErrors` to be an array in the JSON data but got " + data['businessValidationErrors']);
            }
            // validate the optional field `businessValidationErrors` (array)
            for (const item of data['businessValidationErrors']) {
                ValidationError.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * Message
 * @member {String} message
 */
BusinessValidationError.prototype['message'] = undefined;

/**
 * Message Id
 * @member {String} messageId
 */
BusinessValidationError.prototype['messageId'] = undefined;

/**
 * Time stamp
 * @member {String} timeStamp
 */
BusinessValidationError.prototype['timeStamp'] = undefined;

/**
 * Validation errors
 * @member {Array.<String>} validationErrors
 */
BusinessValidationError.prototype['validationErrors'] = undefined;

/**
 * Business validation errors
 * @member {Array.<module:model/ValidationError>} businessValidationErrors
 */
BusinessValidationError.prototype['businessValidationErrors'] = undefined;






export default BusinessValidationError;

