import React, { useState } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { StyledDialog } from "./index.css";

import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UploadFile = ({ classes, uploadFileOpen, closeDialog, uploadlist }) => {
  const [uploadedfiles, setuploadedfiles] = useState([]);
  const [show, setShow] = React.useState(false);
  const [flag, setflag] = React.useState("");
  const [respText, setrespText] = React.useState("");
  const [selectedFileData, setSelectedFileData] = React.useState("");

  const uploadCsv = () => {
    uploadlist(uploadedfiles);
    setuploadedfiles([]);
  };

  const filePathSet = (event) => {
    event.preventDefault();
    let fileData = event.target.files;

    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length
      );
      if (fileExtension.toLowerCase() === "csv") {
        let obj = {
          fileName: fileData[x].name,
          file: fileData[x],
        };
        let files = uploadedfiles;
        files.push(obj);
        setuploadedfiles(files);
        setShow(false);
        setrespText("");
        setflag("success");
      } else {
        setuploadedfiles([]);
        setrespText(
          "Error! Selected file is not supported. Please choose another file."
        );
        setflag("error");
        setShow(true);
      }
    }
    setSelectedFileData({ fileData }); //storing csv file data
  };

  return (
    <StyledDialog
      classes={{ paper: classes.dialogPaper }}
      className="uploadFile"
      id="compList_uploadFile"
      open={uploadFileOpen}
      onClose={closeDialog}
      data-testid="dialogForCsvUpload"
      aria-labelledby="form-dialog-title"
    >
      <Typography
        variant="h5"
        className="dialogText"
        data-testid="dialogUploadNewFileTitle"
        id="compList_dialogUploadNewFileTitle"
      >
        Upload a new file
      </Typography>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant="h5"
            className="fileSelect"
            id="compList_fileSelect"
          >
            Please select an CSV file.
          </Typography>
        </DialogContentText>
        <Grid
          container
          id="compList_paddingChooseFile"
          className="paddingChooseFile"
        >
          <Grid item xs={1}>
            <InsertDriveFileOutlinedIcon
              id="compList_paddingFileIcon"
              className="paddingFileIcon"
            />
          </Grid>
          {uploadedfiles.length !== 0 ? (
            <>
              <Grid item xs={6}>
                <div className="uploadFileFont" id="compList_uploadFileFont">
                  {selectedFileData.fileData[0].name}
                </div>
              </Grid>
              <Grid item xs={1}>
                <div id="compList_checkCircleIcon" className="checkCircleIcon">
                  <CheckCircleIcon />
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs={7}>
              <div id="compList_underlined" className="underlined">
                Please Select
              </div>
              {show && (
                <div>
                  {flag !== "error" ? (
                    <label>
                      <Typography
                        variant="subtitle1"
                        className="error1"
                        id="compList_error1"
                      >
                        {respText}
                      </Typography>
                    </label>
                  ) : (
                    <label>
                      <Typography
                        variant="subtitle1"
                        className="error2"
                        id="compList_error2"
                        data-testid="errorMessage"
                      >
                        {respText}
                      </Typography>
                    </label>
                  )}
                </div>
              )}
            </Grid>
          )}

          <Grid item xs={4} id="compList_paddingFile" className="paddingFile">
            <label htmlFor="fileUpload">
              <Button
                variant="outlined"
                id="compList_csvUpload"
                data-testid="csvUpload"
                color="primary"
                component="span"
              >
                Choose CSV
              </Button>
            </label>
            <input
              className="input"
              type="file"
              id="fileUpload"
              data-testid="fileUpload"
              accept=".csv"
              onChange={filePathSet.bind(this)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions id="compList_paddingBottom" className="paddingBottom">
        <Button
          onClick={() => {
            closeDialog();
            setuploadedfiles([]);
          }}
          data-testid="closeDialog"
          id="compList_closeDialog"
          className="cancelColor"
        >
          Cancel
        </Button>
        {uploadedfiles.length === 0 || flag === "error" ? (
          <Button
            variant="contained"
            color="primary"
            className="submitButton"
            id="compList_submitButton"
            disabled
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            data-testid="submitForCsvUpload"
            className="submitButton"
            id="compList_submitForCsvUpload"
            onClick={() => {
              uploadCsv();
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default UploadFile;
