/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CurrentState from './CurrentState';
import DIBase from './DIBase';
import LifeCycle from './LifeCycle';

/**
 * The DIObject model module.
 * @module model/DIObject
 * @version 1.0.3
 */
class DIObject {
    /**
     * Constructs a new <code>DIObject</code>.
     * @alias module:model/DIObject
     */
    constructor() { 
        
        DIObject.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DIObject</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DIObject} obj Optional instance to populate.
     * @return {module:model/DIObject} The populated <code>DIObject</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DIObject();

            if (data.hasOwnProperty('DIBase')) {
                obj['DIBase'] = DIBase.constructFromObject(data['DIBase']);
            }
            if (data.hasOwnProperty('InstrumentId')) {
                obj['InstrumentId'] = ApiClient.convertToType(data['InstrumentId'], 'String');
            }
            if (data.hasOwnProperty('Version')) {
                obj['Version'] = ApiClient.convertToType(data['Version'], 'String');
            }
            if (data.hasOwnProperty('CurrentState')) {
                obj['CurrentState'] = CurrentState.constructFromObject(data['CurrentState']);
            }
            if (data.hasOwnProperty('LifeCycle')) {
                obj['LifeCycle'] = LifeCycle.constructFromObject(data['LifeCycle']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DIObject</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DIObject</code>.
     */
    static validateJSON(data) {
        // validate the optional field `DIBase`
        if (data['DIBase']) { // data not null
          DIBase.validateJSON(data['DIBase']);
        }
        // ensure the json data is a string
        if (data['InstrumentId'] && !(typeof data['InstrumentId'] === 'string' || data['InstrumentId'] instanceof String)) {
            throw new Error("Expected the field `InstrumentId` to be a primitive type in the JSON string but got " + data['InstrumentId']);
        }
        // ensure the json data is a string
        if (data['Version'] && !(typeof data['Version'] === 'string' || data['Version'] instanceof String)) {
            throw new Error("Expected the field `Version` to be a primitive type in the JSON string but got " + data['Version']);
        }
        // validate the optional field `CurrentState`
        if (data['CurrentState']) { // data not null
          CurrentState.validateJSON(data['CurrentState']);
        }
        // validate the optional field `LifeCycle`
        if (data['LifeCycle']) { // data not null
          LifeCycle.validateJSON(data['LifeCycle']);
        }

        return true;
    }


}



/**
 * @member {module:model/DIBase} DIBase
 */
DIObject.prototype['DIBase'] = undefined;

/**
 * 
 * @member {String} InstrumentId
 */
DIObject.prototype['InstrumentId'] = undefined;

/**
 * 
 * @member {String} Version
 */
DIObject.prototype['Version'] = undefined;

/**
 * @member {module:model/CurrentState} CurrentState
 */
DIObject.prototype['CurrentState'] = undefined;

/**
 * @member {module:model/LifeCycle} LifeCycle
 */
DIObject.prototype['LifeCycle'] = undefined;






export default DIObject;

