/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Exchange from './Exchange';

/**
 * The Listing model module.
 * @module model/Listing
 * @version 1.0.3
 */
class Listing {
    /**
     * Constructs a new <code>Listing</code>.
     * @alias module:model/Listing
     */
    constructor() { 
        
        Listing.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Listing</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Listing} obj Optional instance to populate.
     * @return {module:model/Listing} The populated <code>Listing</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Listing();

            if (data.hasOwnProperty('AdmissionRequest')) {
                obj['AdmissionRequest'] = ApiClient.convertToType(data['AdmissionRequest'], 'Boolean');
            }
            if (data.hasOwnProperty('AdmissionApprovalDate')) {
                obj['AdmissionApprovalDate'] = ApiClient.convertToType(data['AdmissionApprovalDate'], 'Date');
            }
            if (data.hasOwnProperty('FirstListingDate')) {
                obj['FirstListingDate'] = ApiClient.convertToType(data['FirstListingDate'], 'Date');
            }
            if (data.hasOwnProperty('LastListingDate')) {
                obj['LastListingDate'] = ApiClient.convertToType(data['LastListingDate'], 'Date');
            }
            if (data.hasOwnProperty('DelistingDate')) {
                obj['DelistingDate'] = ApiClient.convertToType(data['DelistingDate'], 'Date');
            }
            if (data.hasOwnProperty('DelistingReason')) {
                obj['DelistingReason'] = ApiClient.convertToType(data['DelistingReason'], 'String');
            }
            if (data.hasOwnProperty('InclusionRegulatedMarket')) {
                obj['InclusionRegulatedMarket'] = ApiClient.convertToType(data['InclusionRegulatedMarket'], 'Boolean');
            }
            if (data.hasOwnProperty('InclusionRegulatedMarketRegistrationDate')) {
                obj['InclusionRegulatedMarketRegistrationDate'] = ApiClient.convertToType(data['InclusionRegulatedMarketRegistrationDate'], 'Date');
            }
            if (data.hasOwnProperty('PrimaryListing')) {
                obj['PrimaryListing'] = ApiClient.convertToType(data['PrimaryListing'], 'Boolean');
            }
            if (data.hasOwnProperty('Exchange')) {
                obj['Exchange'] = Exchange.constructFromObject(data['Exchange']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Listing</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Listing</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['DelistingReason'] && !(typeof data['DelistingReason'] === 'string' || data['DelistingReason'] instanceof String)) {
            throw new Error("Expected the field `DelistingReason` to be a primitive type in the JSON string but got " + data['DelistingReason']);
        }
        // validate the optional field `Exchange`
        if (data['Exchange']) { // data not null
          Exchange.validateJSON(data['Exchange']);
        }

        return true;
    }


}



/**
 * Indicates whether admission of trading has been requested[319]
 * @member {Boolean} AdmissionRequest
 */
Listing.prototype['AdmissionRequest'] = undefined;

/**
 * Date of approved admission of trading[320]
 * @member {Date} AdmissionApprovalDate
 */
Listing.prototype['AdmissionApprovalDate'] = undefined;

/**
 * First trading day on the respective exchange[291]
 * @member {Date} FirstListingDate
 */
Listing.prototype['FirstListingDate'] = undefined;

/**
 * Last trading day of the financial instrument on the exchange.[292]
 * @member {Date} LastListingDate
 */
Listing.prototype['LastListingDate'] = undefined;

/**
 * Date on which the instrument is to be delisted from the exchange. This is usually after the final valuation day.[293]
 * @member {Date} DelistingDate
 */
Listing.prototype['DelistingDate'] = undefined;

/**
 * Reason for delisting of the instrument[325]
 * @member {String} DelistingReason
 */
Listing.prototype['DelistingReason'] = undefined;

/**
 * Inclusion into the regulated market in accordance with Article 33 I of the Stock Exchange Act[303]
 * @member {Boolean} InclusionRegulatedMarket
 */
Listing.prototype['InclusionRegulatedMarket'] = undefined;

/**
 * Date on which the admission into regulated market was approved[304]
 * @member {Date} InclusionRegulatedMarketRegistrationDate
 */
Listing.prototype['InclusionRegulatedMarketRegistrationDate'] = undefined;

/**
 * Declaration that the exchange (domestic or/and foreign) is the primary listing venue for this instrument[305]
 * @member {Boolean} PrimaryListing
 */
Listing.prototype['PrimaryListing'] = undefined;

/**
 * @member {module:model/Exchange} Exchange
 */
Listing.prototype['Exchange'] = undefined;






export default Listing;

