/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Attribute model module.
 * @module model/Attribute
 * @version 1.0.3
 */
class Attribute {
    /**
     * Constructs a new <code>Attribute</code>.
     * @alias module:model/Attribute
     */
    constructor() { 
        
        Attribute.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Attribute</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Attribute} obj Optional instance to populate.
     * @return {module:model/Attribute} The populated <code>Attribute</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Attribute();

            if (data.hasOwnProperty('PutOption')) {
                obj['PutOption'] = ApiClient.convertToType(data['PutOption'], 'Boolean');
            }
            if (data.hasOwnProperty('CallOption')) {
                obj['CallOption'] = ApiClient.convertToType(data['CallOption'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Attribute</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Attribute</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * Indicates whether the security has a Put Option embedded.[493]
 * @member {Boolean} PutOption
 */
Attribute.prototype['PutOption'] = undefined;

/**
 * Indicates whether the security has a Call Option embedded.[489]
 * @member {Boolean} CallOption
 */
Attribute.prototype['CallOption'] = undefined;






export default Attribute;

