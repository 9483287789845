import { Button, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { StyledConsentCookie } from "./StyledConsentCookie";
import Cookies from "js-cookie"; //js-cookie lets us set and get the value of cookies
import settingIcon from "./assets/SettingForCookieWarning.png";
import CloseIcon from "@mui/icons-material/Close";

/*
 * This method can be used to delete cookie
 */
export const resetCookieConsentValue = (name) => {
  Cookies.remove(name);
};

/*
 * Array consisting cookie details table's column header
 */
const columns = [
  { id: "name", label: "Name" },
  { id: "domain", label: "Domain" },
  { id: "exp", label: "Expiration" },
  { id: "des", label: "Description" },
];

function CookieConsent(props) {
  const cookieName = "Cookie_Consent"; // Cookie Name set to "Cookie_Consent"
  const [visible, setVisible] = useState(false); //cookie consent banner visibility set to false
  const [showCookieDetails, setShowCookieDetails] = useState(false);
  const [showPrivacyNotice, setShowPrivacyNotice] = useState(false);
  const [view, setView] = React.useState("usedCookies"); // for Cookie details (when clicked on Show Details button) we have 2 views - usedCookie and aboutCookie
  const [privacyNoticeView, setPrivacyNoticeView] =
    React.useState("privacyNotice"); // when clicked on Privacy Notice hyperlink the view is set to "privacyNotice"

  /*
   * This array consists all the necessary Xact_Clearstream cookie's information alongwith Cookie_consent cookie which we are setting
   */
  const clearstreamCookieData = [
    {
      name: "OAUTH_REQUEST_ATTIBUTES",
      domain: "iamtfls.deutsche-boerse.de",
      exp: "Session",
      des: "This cookie is created by AM when a POST request is made to the OAuth2 /authorize endpoint (AM 7 and later). This cookie stores the POST data needed to authenticate in case you are redirected for authentication and is deleted upon successful authorization. Max-Age is 300s.",
    },
    {
      name: "amlbcookie",
      domain: "iamtfls.deutsche-boerse.de",
      exp: "When the browsing session ends",
      des: "This cookie is created by AM when a load balancer is in use and is used to implement sticky load balancing. This cookie identifies the server that holds the user’s session information, which is then used by the load balancer to redirect the user’s requests to that server.",
    },
    {
      name: "es",
      domain: "iamtfls.deutsche-boerse.de",
      exp: "When the browsing session ends",
      des: "Session cookie of ForgeRock AM",
    },
    {
      name: "Cookie_Consent",
      domain: window.location.hostname,
      exp: "When the browsing session ends",
      des: "This is a JavaScript cookie that handles the visibility of cookie consent popup.",
    },
  ];

  /*
   * This array consists all the necessary Xact cookie's information alongwith Cookie_consent cookie which we are setting
   */
  const xactCookieData = [
    {
      name: "amlbcookie",
      domain: "xactcobtestf5.clearstream.com",
      exp: "When the browsing session ends",
      des: "This is technical loadbalancing cookie.",
    },
    {
      name: "portalcookie",
      domain: "xactcobtestf5.clearstream.com",
      exp: "When the browsing session ends",
      des: "This is authentication cookie of Xact. It is required to maintain the user's session.",
    },
    {
      name: "portalid",
      domain: "xactcobtestf5.clearstream.com",
      exp: "When the browsing session ends",
      des: "This is a technical identifier in addition to the session cookie, required to maintain the user's session.",
    },
    {
      name: "Cookie_Consent",
      domain: window.location.hostname,
      exp: "When the browsing session ends",
      des: "This is a JavaScript cookie that handles the visibility of cookie consent popup.",
    },
  ];

  /*
   * This array consists of Cookie_Consent cookie which we are setting (which is responsible for visibility of cookie consent banner)
   */
  const mockIAMCookieData = [
    {
      name: "Cookie_Consent",
      domain: window.location.hostname,
      exp: "When the browsing session ends",
      des: "This is a JavaScript cookie that handles the visibility of cookie consent popup.",
    },
  ];

  /*
   * This method is to get the value of the cookie
   */
  const getCookieConsentValue = (name = cookieName) => {
    let cookieValue = Cookies.get(name);

    if (cookieValue === undefined) {
      cookieValue = Cookies.get(getLegacyCookieName(name));
    }

    return cookieValue;
  };

  /*
   * This method is used to set the cookie name
   */
  const getLegacyCookieName = (name) => {
    return `${name}-legacy`;
  };

  /*
   * This method is used to set cookie and close cookie consent banner when Accept button is clicked
   */
  const accept = () => {
    setVisible(false);
    setCookie(cookieName, true);
  };

  /*
   * This method is to set the value of the cookie
   */
  const setCookie = (cookieName, cookieValue) => {
    const cookieSecurity = window.location
      ? window.location.protocol === "https:"
      : true;

    let cookieOptions = { secure: cookieSecurity };
    Cookies.set(cookieName, cookieValue, cookieOptions);
  };

  /*
   * This method is used to get the cookie by calling getCookieConsentValue fn
   */
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const getCookieValue = () => {
    return getCookieConsentValue(cookieName);
  };

  /*
   * This method is responsible for cookie consent banner visibilty as soon as component renders (based on some conditions)
   */
  useEffect(() => {
    if (getCookieValue() === undefined || getCookieValue() === "false") {
      setVisible(true);
    }
  }, [getCookieValue]);

  /*
   * This method is called on Show Details and Hide Details button click
   */
  const handleChangeCookieDetailsVisiblity = (event) => {
    setShowCookieDetails(!showCookieDetails);
    setShowPrivacyNotice(false);
    setView("usedCookies");
  };

  /*
   * This method is called on Privacy Notices hyperlink selection
   */
  const handleChangePrivacyNoticeVisibility = (event) => {
    setShowPrivacyNotice(true);
    setPrivacyNoticeView("privacyNotice");
    setShowCookieDetails(false);
  };

  const toggleCookieDetailButton = showCookieDetails; //used to toggle between visibility and non visibility of cookie details (when clicked on Show Details and Hide Details buttons)

  const togglePrivacyNotice = showPrivacyNotice; //used to between visibility and non visibility on Privacy Notice section (when clicked on Privacy Notices hyperlink multiple times)

  return !!visible ? (
    <StyledConsentCookie>
      <div className="cookieBanner">
        <Grid container>
          <Grid item xs={9} className="content">
            <Typography className="content-color">
              Welcome to Clearstream! We only use cookies which are strictly
              necessary for this website to be operational. The full list of
              cookies in use on this website can be found below. Further
              information can be found in our
              <Button
                onClick={() => handleChangePrivacyNoticeVisibility()}
                data-testid="privacyNotice"
              >
                <Typography className="privacyNoticeColor">
                  Privacy Notices
                </Typography>
              </Button>
              .
            </Typography>
          </Grid>
          <Grid item xs={3} className="buttons">
            <Button
              className="accept"
              variant="contained"
              color="primary"
              onClick={accept}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
        <div className="detailContent">
          <Button
            onClick={() => handleChangeCookieDetailsVisiblity()}
            className="showDetail"
          >
            <img src={settingIcon} alt="icon" />
            <Typography className="showDetails">
              {toggleCookieDetailButton ? "HIDE DETAILS" : "SHOW DETAILS"}
            </Typography>
          </Button>
        </div>
        <div className="detailsTable">
          {toggleCookieDetailButton && (
            <>
              <div className="TableHeaderStyling">
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={2}
                    align="center"
                    className={`${view === "usedCookies" ? "underlined" : ""}`}
                  >
                    <Typography
                      id="usedCookies"
                      onClick={() => setView("usedCookies")}
                      className="cookiesFont"
                    >
                      USED COOKIES
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid
                    item
                    xs={2}
                    align="center"
                    className={`${view === "aboutCookies" ? "underlined" : ""}`}
                  >
                    <Typography
                      id="aboutCookies"
                      onClick={() => setView("aboutCookies")}
                      className="cookiesFont"
                    >
                      ABOUT COOKIES
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </div>
              {view === "usedCookies" && (
                <div className="formatBg">
                  <Typography className="formatText" variant="subtitle1">
                    Strictly necessary cookies allow core website functionality
                    such as user login and account management. The website
                    cannot be used properly without strictly necessary cookies.
                  </Typography>
                  <TableContainer className="tableHeight1">
                    <Table aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={column.key} className="bgColor">
                              <Typography variant="h5">
                                {column.label}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {process.env.REACT_APP_ENABLED === "true"
                          ? process.env.REACT_APP_AUTHORITY !== "Xact"
                            ? clearstreamCookieData.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.domain}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.exp}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.des}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : xactCookieData.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.domain}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.exp}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.des}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          : mockIAMCookieData.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Typography variant="subtitle1">
                                      {row.name}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="subtitle1">
                                      {row.domain}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="subtitle1">
                                      {row.exp}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="subtitle1">
                                      {row.des}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {view === "aboutCookies" && (
                <div className="formatBg1">
                  <Typography className="formatText" variant="subtitle1">
                    A cookie is a small text file that a website saves on your
                    computer or mobile device when you visit the site. It
                    enables the website to remember your actions and preferences
                    (such as login, language, font size and other display
                    preferences) over a period of time, so you don’t have to
                    keep re-entering them whenever you come back to the site or
                    browse from one page to another.
                  </Typography>
                </div>
              )}
            </>
          )}
          {togglePrivacyNotice && (
            <>
              <div className="TableHeaderStyling">
                <Grid container>
                  <Grid item xs={5}></Grid>
                  <Grid
                    item
                    xs={2}
                    align="center"
                    className={`${privacyNoticeView === "privacyNotice" ? "underlined" : ""}`}
                  >
                    <Typography id="privacyNotice" className="cookiesFont">
                      PRIVACY NOTICE
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={1} className="closeIcon">
                    <CloseIcon
                      onClick={() => setShowPrivacyNotice(false)}
                      data-testid="closePrivacyNotice"
                    />
                  </Grid>
                </Grid>
              </div>
              {privacyNoticeView === "privacyNotice" && (
                <div className="formatBg1">
                  <Typography className="formatText" variant="subtitle1">
                    <TableContainer
                      className="tableHeight2"
                      data-testid="privacyNoticeTable"
                    >
                      When you visit the website and Our online platform,
                      information is stored on your terminal device in the form
                      of a "cookie." Cookies are small files that are stored on
                      your terminal device and save certain settings and data to
                      exchange with our websites via your browser.
                      <br />
                      <br />
                      For example, cookies enable us to tailor a website to
                      better match your interests or to store your password so
                      that you do not have to re-enter it every time. As a
                      general rule, we never collect personal data via cookies,
                      unless you have given us your express permission to do so.
                      <br />
                      <br />
                      If you do not want us to recognize your terminal device,
                      please configure your Internet browser to erase all
                      cookies from your device, to block all cookies or to
                      receive a warning before a cookie is stored. You will find
                      brief instructions on how to do this below.
                      <br />
                      <br />
                      Please note that certain functions of our website may no
                      longer work, or not correctly, without cookies.
                      <br />
                      <br />
                      a. Types of cookies
                      <br />
                      Cookies can be assigned to four categories, depending on
                      their function and intended purpose: absolutely necessary
                      cookies, performance cookies, functional cookies, and
                      cookies for marketing purposes.
                      <br />
                      <br />
                      i. Absolutely necessary cookies
                      <br />
                      Are needed for you to navigate within websites and operate
                      basic website functions, such as the issuance of anonymous
                      Session IDs for bundling several related queries to a
                      server.
                      <br />
                      <br />
                      ii. Performance cookies
                      <br />
                      collect information on the usage of our websites,
                      including for example the Internet browsers and operating
                      systems used, the domain name of the website which you
                      previously visited, the number of visits, average duration
                      of visit, and pages called up. These cookies do not store
                      any information that would make it possible to personally
                      identify the user. The information collected with the aid
                      of these cookies is aggregated and is therefore anonymous.
                      Performance cookies serve the purpose of improving the
                      user friendliness of a website and therefore enhancing the
                      user’s experience. You can block the use of such cookies
                      by creating an exclusion cookie (see “managing cookies”
                      below).
                      <br />
                      <br />
                      iii. Functional cookies
                      <br />
                      enable a website to store information the user has already
                      entered (such as user ID, language selection, or the
                      user’s location), in order to offer improved, personalized
                      functions to the user. Functional cookies are also used to
                      enable requested functions such as playing videos and to
                      make a user’s decision to block or disable a certain
                      function (e.g. web analysis) - “opt-out cookies”.
                      <br />
                      <br />
                      iv. Cookies for marketing purposes
                      <br />
                      are used to offer more relevant content to users, based on
                      their specific interests. They are also used to limit the
                      display frequency of an ad and to measure and control the
                      effectiveness of advertising campaigns. They register
                      whether users have visited a website or not, and which
                      contents were used. This information may possibly also be
                      shared with third parties, such as advertisers, for
                      example. These cookies are often linked to the functions
                      of third-party websites. You can block the use of such
                      cookies by creating an opt-out cookie (see “Managing
                      cookies” below).
                      <br />
                      <br />
                      b. Cookies on Our website and Our online platform
                      <br />
                      <Table aria-label="sticky table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell key={column.key} className="bgColor">
                                <Typography variant="h5">
                                  {column.label}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {process.env.REACT_APP_ENABLED === "true"
                            ? process.env.REACT_APP_AUTHORITY !== "Xact"
                              ? clearstreamCookieData.map((row, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.domain}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.exp}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.des}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              : xactCookieData.map((row, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.domain}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.exp}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {row.des}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                            : mockIAMCookieData.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.domain}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.exp}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="subtitle1">
                                        {row.des}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </StyledConsentCookie>
  ) : null;
}

export default CookieConsent;
