/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Submitter from './Submitter';

/**
 * The Manual model module.
 * @module model/Manual
 * @version 1.0.3
 */
class Manual {
    /**
     * Constructs a new <code>Manual</code>.
     * @alias module:model/Manual
     */
    constructor() { 
        
        Manual.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Manual</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Manual} obj Optional instance to populate.
     * @return {module:model/Manual} The populated <code>Manual</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Manual();

            if (data.hasOwnProperty('Submitter')) {
                obj['Submitter'] = Submitter.constructFromObject(data['Submitter']);
            }
            if (data.hasOwnProperty('Notes')) {
                obj['Notes'] = ApiClient.convertToType(data['Notes'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Manual</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Manual</code>.
     */
    static validateJSON(data) {
        // validate the optional field `Submitter`
        if (data['Submitter']) { // data not null
          Submitter.validateJSON(data['Submitter']);
        }
        // ensure the json data is a string
        if (data['Notes'] && !(typeof data['Notes'] === 'string' || data['Notes'] instanceof String)) {
            throw new Error("Expected the field `Notes` to be a primitive type in the JSON string but got " + data['Notes']);
        }

        return true;
    }


}



/**
 * @member {module:model/Submitter} Submitter
 */
Manual.prototype['Submitter'] = undefined;

/**
 * 
 * @member {String} Notes
 */
Manual.prototype['Notes'] = undefined;






export default Manual;

