/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AccountChangesetItem model module.
 * @module model/AccountChangesetItem
 * @version 1.0.3
 */
class AccountChangesetItem {
    /**
     * Constructs a new <code>AccountChangesetItem</code>.
     * @alias module:model/AccountChangesetItem
     */
    constructor() { 
        
        AccountChangesetItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AccountChangesetItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AccountChangesetItem} obj Optional instance to populate.
     * @return {module:model/AccountChangesetItem} The populated <code>AccountChangesetItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccountChangesetItem();

            if (data.hasOwnProperty('changeType')) {
                obj['changeType'] = ApiClient.convertToType(data['changeType'], 'String');
            }
            if (data.hasOwnProperty('payAgentAccountNo')) {
                obj['payAgentAccountNo'] = ApiClient.convertToType(data['payAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('issuerAccountNo')) {
                obj['issuerAccountNo'] = ApiClient.convertToType(data['issuerAccountNo'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentName')) {
                obj['issuerAgentName'] = ApiClient.convertToType(data['issuerAgentName'], 'String');
            }
            if (data.hasOwnProperty('issuerAgentAccountNo')) {
                obj['issuerAgentAccountNo'] = ApiClient.convertToType(data['issuerAgentAccountNo'], 'String');
            }
            if (data.hasOwnProperty('cbfAktEmitCode')) {
                obj['cbfAktEmitCode'] = ApiClient.convertToType(data['cbfAktEmitCode'], 'String');
            }
            if (data.hasOwnProperty('issuerLei')) {
                obj['issuerLei'] = ApiClient.convertToType(data['issuerLei'], 'String');
            }
            if (data.hasOwnProperty('issuerCountry')) {
                obj['issuerCountry'] = ApiClient.convertToType(data['issuerCountry'], 'String');
            }
            if (data.hasOwnProperty('dateOfEntry')) {
                obj['dateOfEntry'] = ApiClient.convertToType(data['dateOfEntry'], 'String');
            }
            if (data.hasOwnProperty('addedBy')) {
                obj['addedBy'] = ApiClient.convertToType(data['addedBy'], 'String');
            }
            if (data.hasOwnProperty('le')) {
                obj['le'] = ApiClient.convertToType(data['le'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('protocol')) {
                obj['protocol'] = ApiClient.convertToType(data['protocol'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AccountChangesetItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AccountChangesetItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['changeType'] && !(typeof data['changeType'] === 'string' || data['changeType'] instanceof String)) {
            throw new Error("Expected the field `changeType` to be a primitive type in the JSON string but got " + data['changeType']);
        }
        // ensure the json data is a string
        if (data['payAgentAccountNo'] && !(typeof data['payAgentAccountNo'] === 'string' || data['payAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `payAgentAccountNo` to be a primitive type in the JSON string but got " + data['payAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['issuerAccountNo'] && !(typeof data['issuerAccountNo'] === 'string' || data['issuerAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAccountNo` to be a primitive type in the JSON string but got " + data['issuerAccountNo']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['issuerAgentName'] && !(typeof data['issuerAgentName'] === 'string' || data['issuerAgentName'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentName` to be a primitive type in the JSON string but got " + data['issuerAgentName']);
        }
        // ensure the json data is a string
        if (data['issuerAgentAccountNo'] && !(typeof data['issuerAgentAccountNo'] === 'string' || data['issuerAgentAccountNo'] instanceof String)) {
            throw new Error("Expected the field `issuerAgentAccountNo` to be a primitive type in the JSON string but got " + data['issuerAgentAccountNo']);
        }
        // ensure the json data is a string
        if (data['cbfAktEmitCode'] && !(typeof data['cbfAktEmitCode'] === 'string' || data['cbfAktEmitCode'] instanceof String)) {
            throw new Error("Expected the field `cbfAktEmitCode` to be a primitive type in the JSON string but got " + data['cbfAktEmitCode']);
        }
        // ensure the json data is a string
        if (data['issuerLei'] && !(typeof data['issuerLei'] === 'string' || data['issuerLei'] instanceof String)) {
            throw new Error("Expected the field `issuerLei` to be a primitive type in the JSON string but got " + data['issuerLei']);
        }
        // ensure the json data is a string
        if (data['issuerCountry'] && !(typeof data['issuerCountry'] === 'string' || data['issuerCountry'] instanceof String)) {
            throw new Error("Expected the field `issuerCountry` to be a primitive type in the JSON string but got " + data['issuerCountry']);
        }
        // ensure the json data is a string
        if (data['dateOfEntry'] && !(typeof data['dateOfEntry'] === 'string' || data['dateOfEntry'] instanceof String)) {
            throw new Error("Expected the field `dateOfEntry` to be a primitive type in the JSON string but got " + data['dateOfEntry']);
        }
        // ensure the json data is a string
        if (data['addedBy'] && !(typeof data['addedBy'] === 'string' || data['addedBy'] instanceof String)) {
            throw new Error("Expected the field `addedBy` to be a primitive type in the JSON string but got " + data['addedBy']);
        }
        // ensure the json data is a string
        if (data['le'] && !(typeof data['le'] === 'string' || data['le'] instanceof String)) {
            throw new Error("Expected the field `le` to be a primitive type in the JSON string but got " + data['le']);
        }
        // ensure the json data is a string
        if (data['comment'] && !(typeof data['comment'] === 'string' || data['comment'] instanceof String)) {
            throw new Error("Expected the field `comment` to be a primitive type in the JSON string but got " + data['comment']);
        }
        // ensure the json data is a string
        if (data['protocol'] && !(typeof data['protocol'] === 'string' || data['protocol'] instanceof String)) {
            throw new Error("Expected the field `protocol` to be a primitive type in the JSON string but got " + data['protocol']);
        }

        return true;
    }


}



/**
 * Description type of the item type New or Delete
 * @member {String} changeType
 */
AccountChangesetItem.prototype['changeType'] = undefined;

/**
 * Pay Agent Account No
 * @member {String} payAgentAccountNo
 */
AccountChangesetItem.prototype['payAgentAccountNo'] = undefined;

/**
 * Issuer Account No
 * @member {String} issuerAccountNo
 */
AccountChangesetItem.prototype['issuerAccountNo'] = undefined;

/**
 * name of the issuerName
 * @member {String} issuerName
 */
AccountChangesetItem.prototype['issuerName'] = undefined;

/**
 * IssuerAgentName
 * @member {String} issuerAgentName
 */
AccountChangesetItem.prototype['issuerAgentName'] = undefined;

/**
 * Issuer Agent Account No
 * @member {String} issuerAgentAccountNo
 */
AccountChangesetItem.prototype['issuerAgentAccountNo'] = undefined;

/**
 * Issuer Sort Code
 * @member {String} cbfAktEmitCode
 */
AccountChangesetItem.prototype['cbfAktEmitCode'] = undefined;

/**
 * Issuer Lei
 * @member {String} issuerLei
 */
AccountChangesetItem.prototype['issuerLei'] = undefined;

/**
 * Country of the Issuer
 * @member {String} issuerCountry
 */
AccountChangesetItem.prototype['issuerCountry'] = undefined;

/**
 * Date of Entry, based on ISO 8601 standard
 * @member {String} dateOfEntry
 */
AccountChangesetItem.prototype['dateOfEntry'] = undefined;

/**
 * Entry Added by (incl. UserID)
 * @member {String} addedBy
 */
AccountChangesetItem.prototype['addedBy'] = undefined;

/**
 * Legal entity
 * @member {String} le
 */
AccountChangesetItem.prototype['le'] = undefined;

/**
 * Additional comments
 * @member {String} comment
 */
AccountChangesetItem.prototype['comment'] = undefined;

/**
 * Will tell about the state of the account changeset record based on changeType
 * @member {String} protocol
 */
AccountChangesetItem.prototype['protocol'] = undefined;






export default AccountChangesetItem;

