import React from "react";
import Typography from "@material-ui/core/Typography";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { SimpleTableContainer } from "./TableContainer";
import SimpleTableCell from "./SimpleTableCell";
import nodataIcon from "../../assets/Error Icon.svg";

const RecordsTable = ({ data = [], onRowClick, columns = [] }) => {
  const emptyTableContainer = (
    <TableRow style={{ backgroundColor: "white" }}>
      <TableCell
        colSpan={9}
        align="center"
        id="noDataIcon"
        className="nodataicon"
      >
        <div>
          <img
            src={nodataIcon}
            alt="nodata"
            id="noIconColumn"
            className="noiconColumn"
          />
          <Typography id="noDataText">No data available to display</Typography>
        </div>
      </TableCell>
    </TableRow>
  );

  const handleRowClick = (rowId) => () => onRowClick ? onRowClick(rowId) : null;

  return (
    <SimpleTableContainer style={{ maxHeight: 580 }}>
      <Table
        aria-label="sticky table"
        stickyHeader
        id="TableView"
        data-testid="TableView"
      >
        <TableHead>
          <TableRow>
            {Object.keys(columns).map((key, index) => (
              <TableCell className="cellBorder" id={`label_${index}`} key={key}>
                <Typography
                  variant="h5"
                  id={`label_text_${index}`}
                  className="lH-17"
                >
                  {columns[key]}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0
            ? emptyTableContainer
            : data.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    hover
                    onDoubleClick={
                      onRowClick ? () => onRowClick(row, index) : null
                    }
                    id={`tableRow_${index}`}
                    role="checkbox"
                    data-testid={`tableRowId_${index}`}
                    tabIndex={-1}
                    className={index % 2 ? "FFFFFFColor" : "F7F7F7Color"}
                  >
                    {Object.keys(columns).map((key) => (
                      <SimpleTableCell label={row[key]} />
                    ))}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </SimpleTableContainer>
  );
};

export default RecordsTable;
