/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompliancePendingCountItems model module.
 * @module model/CompliancePendingCountItems
 * @version 1.0.3
 */
class CompliancePendingCountItems {
    /**
     * Constructs a new <code>CompliancePendingCountItems</code>.
     * @alias module:model/CompliancePendingCountItems
     */
    constructor() { 
        
        CompliancePendingCountItems.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompliancePendingCountItems</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompliancePendingCountItems} obj Optional instance to populate.
     * @return {module:model/CompliancePendingCountItems} The populated <code>CompliancePendingCountItems</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompliancePendingCountItems();

            if (data.hasOwnProperty('whitelist')) {
                obj['whitelist'] = ApiClient.convertToType(data['whitelist'], 'Number');
            }
            if (data.hasOwnProperty('blacklist')) {
                obj['blacklist'] = ApiClient.convertToType(data['blacklist'], 'Number');
            }
            if (data.hasOwnProperty('issuerBlacklist')) {
                obj['issuerBlacklist'] = ApiClient.convertToType(data['issuerBlacklist'], 'Number');
            }
            if (data.hasOwnProperty('issuerWhitelist')) {
                obj['issuerWhitelist'] = ApiClient.convertToType(data['issuerWhitelist'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CompliancePendingCountItems</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CompliancePendingCountItems</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * Whitelist count
 * @member {Number} whitelist
 */
CompliancePendingCountItems.prototype['whitelist'] = undefined;

/**
 * Blacklist count
 * @member {Number} blacklist
 */
CompliancePendingCountItems.prototype['blacklist'] = undefined;

/**
 * IssuerBlacklist count
 * @member {Number} issuerBlacklist
 */
CompliancePendingCountItems.prototype['issuerBlacklist'] = undefined;

/**
 * IssuerWhitelist count
 * @member {Number} issuerWhitelist
 */
CompliancePendingCountItems.prototype['issuerWhitelist'] = undefined;






export default CompliancePendingCountItems;

