import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const Underlying = ({ handleChange, expandState, isDesktop, issuanceObj }) => (
  <Accordion
    className="accordianStyle"
    id="cp_under_accCont7"
    expanded={expandState["panel7"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel7a-content"
      id="panel7a-header"
      onClick={() => {
        handleChange("panel7");
      }}
    >
      <Typography variant="h3" id="cp_under_typo7">
        Underlying
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="accordionContent" id="cp_under_acc1">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Underlying ISIN"}
          value={issuanceObj.underlyingIsin}
        />
        <RowElement
          label={"Name of the Underlying"}
          value={issuanceObj.underlyingName}
        />
        <RowElement
          label={"Country Code of Underlying"}
          value={issuanceObj.country}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement label={"Currency of Underlying"} value={""} />
        <RowElement
          label={"Multiplier"}
          value={issuanceObj.underlyingMultiplier}
        />
        <RowElement label={"Asset Class"} value={issuanceObj.assetClass} />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"Reference Source"}
          value={issuanceObj.referenceSource}
        />
        <RowElement label={"Type"} value={issuanceObj.underlyingType} />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default Underlying;
