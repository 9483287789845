/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProcessingLogData model module.
 * @module model/ProcessingLogData
 * @version 1.0.3
 */
class ProcessingLogData {
    /**
     * Constructs a new <code>ProcessingLogData</code>.
     * @alias module:model/ProcessingLogData
     * @param dti {String} DTI
     * @param externalOverallStatus {String} Overall Status
     * @param externalProcessingStatus {String} Processing Status
     * @param internalStatus {String} Internal Processing Status
     * @param processDateTime {Date} Process Date & Time
     */
    constructor(dti, externalOverallStatus, externalProcessingStatus, internalStatus, processDateTime) { 
        
        ProcessingLogData.initialize(this, dti, externalOverallStatus, externalProcessingStatus, internalStatus, processDateTime);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, dti, externalOverallStatus, externalProcessingStatus, internalStatus, processDateTime) { 
        obj['dti'] = dti;
        obj['externalOverallStatus'] = externalOverallStatus;
        obj['externalProcessingStatus'] = externalProcessingStatus;
        obj['internalStatus'] = internalStatus;
        obj['processDateTime'] = processDateTime;
    }

    /**
     * Constructs a <code>ProcessingLogData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProcessingLogData} obj Optional instance to populate.
     * @return {module:model/ProcessingLogData} The populated <code>ProcessingLogData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProcessingLogData();

            if (data.hasOwnProperty('dti')) {
                obj['dti'] = ApiClient.convertToType(data['dti'], 'String');
            }
            if (data.hasOwnProperty('externalOverallStatus')) {
                obj['externalOverallStatus'] = ApiClient.convertToType(data['externalOverallStatus'], 'String');
            }
            if (data.hasOwnProperty('externalProcessingStatus')) {
                obj['externalProcessingStatus'] = ApiClient.convertToType(data['externalProcessingStatus'], 'String');
            }
            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
            if (data.hasOwnProperty('processDateTime')) {
                obj['processDateTime'] = ApiClient.convertToType(data['processDateTime'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProcessingLogData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProcessingLogData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ProcessingLogData.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['dti'] && !(typeof data['dti'] === 'string' || data['dti'] instanceof String)) {
            throw new Error("Expected the field `dti` to be a primitive type in the JSON string but got " + data['dti']);
        }
        // ensure the json data is a string
        if (data['externalOverallStatus'] && !(typeof data['externalOverallStatus'] === 'string' || data['externalOverallStatus'] instanceof String)) {
            throw new Error("Expected the field `externalOverallStatus` to be a primitive type in the JSON string but got " + data['externalOverallStatus']);
        }
        // ensure the json data is a string
        if (data['externalProcessingStatus'] && !(typeof data['externalProcessingStatus'] === 'string' || data['externalProcessingStatus'] instanceof String)) {
            throw new Error("Expected the field `externalProcessingStatus` to be a primitive type in the JSON string but got " + data['externalProcessingStatus']);
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }

        return true;
    }


}

ProcessingLogData.RequiredProperties = ["dti", "externalOverallStatus", "externalProcessingStatus", "internalStatus", "processDateTime"];

/**
 * DTI
 * @member {String} dti
 */
ProcessingLogData.prototype['dti'] = undefined;

/**
 * Overall Status
 * @member {String} externalOverallStatus
 */
ProcessingLogData.prototype['externalOverallStatus'] = undefined;

/**
 * Processing Status
 * @member {String} externalProcessingStatus
 */
ProcessingLogData.prototype['externalProcessingStatus'] = undefined;

/**
 * Internal Processing Status
 * @member {String} internalStatus
 */
ProcessingLogData.prototype['internalStatus'] = undefined;

/**
 * Process Date & Time
 * @member {Date} processDateTime
 */
ProcessingLogData.prototype['processDateTime'] = undefined;






export default ProcessingLogData;

