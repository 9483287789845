import styled from "styled-components";
import { TableContainer } from "@material-ui/core";

export const SimpleTableContainer = styled(TableContainer)`
  margin-top: 10px;
  box-shadow: 0px 1px 5px 1px #87878799;
  border-radius: 12px;
  background-color: #ffffff;
  padding-bottom: 10px;
  min-height: 350px;
  .lH-17 {
    line-height: 17px;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: rgba(178, 228, 236, 0.3) !important;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .status {
    line-height: 17px;
  }
  .requestedStatus {
    color: #ff6600;
    line-height: 17px;
  }
  .eligibilityCheckRequiredStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .checkReferenceDatabase {
    color: #cc0099;
    line-height: 17px;
  }
  .failedStatus {
    color: #cc0099;
    line-height: 17px;
  }
  .FFFFFFColor {
    background-color: #ffffff;
  }
  .F7F7F7Color {
    backgroundcolor: #f7f7f7;
  }
  .redColor {
    color: #ff0000;
    line-height: 17px;
  }
  .greenColor {
    color: #00cc99;
    line-height: 17px;
  }
`;
