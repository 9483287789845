/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DIObject from './DIObject';

/**
 * The DetailsModel model module.
 * @module model/DetailsModel
 * @version 1.0.3
 */
class DetailsModel {
    /**
     * Constructs a new <code>DetailsModel</code>.
     * @alias module:model/DetailsModel
     */
    constructor() { 
        
        DetailsModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DetailsModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DetailsModel} obj Optional instance to populate.
     * @return {module:model/DetailsModel} The populated <code>DetailsModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DetailsModel();

            if (data.hasOwnProperty('DIObject')) {
                obj['DIObject'] = DIObject.constructFromObject(data['DIObject']);
            }
            if (data.hasOwnProperty('ExternalOverallStatus')) {
                obj['ExternalOverallStatus'] = ApiClient.convertToType(data['ExternalOverallStatus'], 'String');
            }
            if (data.hasOwnProperty('ExternalProcessingStatus')) {
                obj['ExternalProcessingStatus'] = ApiClient.convertToType(data['ExternalProcessingStatus'], 'String');
            }
            if (data.hasOwnProperty('DocId')) {
                obj['DocId'] = ApiClient.convertToType(data['DocId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DetailsModel</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DetailsModel</code>.
     */
    static validateJSON(data) {
        // validate the optional field `DIObject`
        if (data['DIObject']) { // data not null
          DIObject.validateJSON(data['DIObject']);
        }
        // ensure the json data is a string
        if (data['ExternalOverallStatus'] && !(typeof data['ExternalOverallStatus'] === 'string' || data['ExternalOverallStatus'] instanceof String)) {
            throw new Error("Expected the field `ExternalOverallStatus` to be a primitive type in the JSON string but got " + data['ExternalOverallStatus']);
        }
        // ensure the json data is a string
        if (data['ExternalProcessingStatus'] && !(typeof data['ExternalProcessingStatus'] === 'string' || data['ExternalProcessingStatus'] instanceof String)) {
            throw new Error("Expected the field `ExternalProcessingStatus` to be a primitive type in the JSON string but got " + data['ExternalProcessingStatus']);
        }
        // ensure the json data is a string
        if (data['DocId'] && !(typeof data['DocId'] === 'string' || data['DocId'] instanceof String)) {
            throw new Error("Expected the field `DocId` to be a primitive type in the JSON string but got " + data['DocId']);
        }

        return true;
    }


}



/**
 * @member {module:model/DIObject} DIObject
 */
DetailsModel.prototype['DIObject'] = undefined;

/**
 * Default: null
 * @member {String} ExternalOverallStatus
 */
DetailsModel.prototype['ExternalOverallStatus'] = undefined;

/**
 * Default: null
 * @member {String} ExternalProcessingStatus
 */
DetailsModel.prototype['ExternalProcessingStatus'] = undefined;

/**
 * document name
 * @member {String} DocId
 */
DetailsModel.prototype['DocId'] = undefined;






export default DetailsModel;

