/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StatusHistoryLog model module.
 * @module model/StatusHistoryLog
 * @version 1.0.3
 */
class StatusHistoryLog {
    /**
     * Constructs a new <code>StatusHistoryLog</code>.
     * @alias module:model/StatusHistoryLog
     */
    constructor() { 
        
        StatusHistoryLog.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StatusHistoryLog</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatusHistoryLog} obj Optional instance to populate.
     * @return {module:model/StatusHistoryLog} The populated <code>StatusHistoryLog</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatusHistoryLog();

            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('externalOverallStatus')) {
                obj['externalOverallStatus'] = ApiClient.convertToType(data['externalOverallStatus'], 'String');
            }
            if (data.hasOwnProperty('externalProcessingStatus')) {
                obj['externalProcessingStatus'] = ApiClient.convertToType(data['externalProcessingStatus'], 'String');
            }
            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>StatusHistoryLog</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>StatusHistoryLog</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['externalOverallStatus'] && !(typeof data['externalOverallStatus'] === 'string' || data['externalOverallStatus'] instanceof String)) {
            throw new Error("Expected the field `externalOverallStatus` to be a primitive type in the JSON string but got " + data['externalOverallStatus']);
        }
        // ensure the json data is a string
        if (data['externalProcessingStatus'] && !(typeof data['externalProcessingStatus'] === 'string' || data['externalProcessingStatus'] instanceof String)) {
            throw new Error("Expected the field `externalProcessingStatus` to be a primitive type in the JSON string but got " + data['externalProcessingStatus']);
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }

        return true;
    }


}



/**
 * TimeStamp
 * @member {Date} timestamp
 */
StatusHistoryLog.prototype['timestamp'] = undefined;

/**
 * Overall Status
 * @member {String} externalOverallStatus
 */
StatusHistoryLog.prototype['externalOverallStatus'] = undefined;

/**
 * Processing Status
 * @member {String} externalProcessingStatus
 */
StatusHistoryLog.prototype['externalProcessingStatus'] = undefined;

/**
 * Internal Processing Status
 * @member {String} internalStatus
 */
StatusHistoryLog.prototype['internalStatus'] = undefined;






export default StatusHistoryLog;

