import styled from "styled-components";
import Select, { components, createFilter } from "react-select";
import { useState } from "react";

// custom filter function that only considers the label field
const filterByLabel = createFilter({
  stringify: ({ label }) => label,
});

function InputOption({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused || isActive || isSelected) bg = "#DEEBFF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      <div style={{ paddingLeft: "6px" }}>{children}</div>
    </components.Option>
  );
}

function MultiSelectDropdown(props) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <MultiDropdown
      classNamePrefix="select"
      isClearable={props.isClearable}
      placeholder={props.placeholder}
      onChange={props.onChange}
      id={props.id}
      name={props.id}
      value={props.value}
      options={props.options}
      className="queryDropdown inputEntry basic-multi-select"
      isMulti
      menuPortalTarget={document.body}
      onKeyDown={(e) => {
        e.preventDefault();
        if (e.key === "Enter") {
          setMenuIsOpen(false);
        }
      }}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      menuIsOpen={menuIsOpen}
      styles={{
        menu: (base) => ({
          ...base,
          borderRadius: "0px",
          height: "fit-content",
        }),
      }}
      //isOptionDisabled={() => props.limitSelectCount ? props.value.length >= props.limitSelectCount : false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isSearchable={true}
      filterOption={filterByLabel}
      components={{
        Option: InputOption,
      }}
    />
  );
}

export default MultiSelectDropdown;

const MultiDropdown = styled(Select)`
  .select__placeholder {
    color: darkgray;
    top: 51%;
  }

  .select__control {
    border-radius: 17px;
    min-height: 35px;
    border: 1px solid rgb(135 135 135 / 60%);
    .select__indicator-separator {
      display: none;
    }
    .select__indicators {
      height: 35px;
      padding: 0;
    }
    .select__dropdown-indicator {
      color: #00a5c0;
      padding-right: 5px;
    }
    .select__multi-value {
      border-radius: 17px;
    }
    .select__multi-value__remove {
      border-radius: 17px;
    }
  }
`;
