/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ComplianceChangesetItem model module.
 * @module model/ComplianceChangesetItem
 * @version 1.0.3
 */
class ComplianceChangesetItem {
    /**
     * Constructs a new <code>ComplianceChangesetItem</code>.
     * @alias module:model/ComplianceChangesetItem
     */
    constructor() { 
        
        ComplianceChangesetItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ComplianceChangesetItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ComplianceChangesetItem} obj Optional instance to populate.
     * @return {module:model/ComplianceChangesetItem} The populated <code>ComplianceChangesetItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ComplianceChangesetItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('changesetId')) {
                obj['changesetId'] = ApiClient.convertToType(data['changesetId'], 'String');
            }
            if (data.hasOwnProperty('changeType')) {
                obj['changeType'] = ApiClient.convertToType(data['changeType'], 'String');
            }
            if (data.hasOwnProperty('underlyingISIN')) {
                obj['underlyingISIN'] = ApiClient.convertToType(data['underlyingISIN'], 'String');
            }
            if (data.hasOwnProperty('underlyingName')) {
                obj['underlyingName'] = ApiClient.convertToType(data['underlyingName'], 'String');
            }
            if (data.hasOwnProperty('issuerCountry')) {
                obj['issuerCountry'] = ApiClient.convertToType(data['issuerCountry'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('entryDate')) {
                obj['entryDate'] = ApiClient.convertToType(data['entryDate'], 'Date');
            }
            if (data.hasOwnProperty('lE')) {
                obj['lE'] = ApiClient.convertToType(data['lE'], 'String');
            }
            if (data.hasOwnProperty('LEI')) {
                obj['LEI'] = ApiClient.convertToType(data['LEI'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('protocol')) {
                obj['protocol'] = ApiClient.convertToType(data['protocol'], 'String');
            }
            if (data.hasOwnProperty('errorText')) {
                obj['errorText'] = ApiClient.convertToType(data['errorText'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ComplianceChangesetItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ComplianceChangesetItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['changesetId'] && !(typeof data['changesetId'] === 'string' || data['changesetId'] instanceof String)) {
            throw new Error("Expected the field `changesetId` to be a primitive type in the JSON string but got " + data['changesetId']);
        }
        // ensure the json data is a string
        if (data['changeType'] && !(typeof data['changeType'] === 'string' || data['changeType'] instanceof String)) {
            throw new Error("Expected the field `changeType` to be a primitive type in the JSON string but got " + data['changeType']);
        }
        // ensure the json data is a string
        if (data['underlyingISIN'] && !(typeof data['underlyingISIN'] === 'string' || data['underlyingISIN'] instanceof String)) {
            throw new Error("Expected the field `underlyingISIN` to be a primitive type in the JSON string but got " + data['underlyingISIN']);
        }
        // ensure the json data is a string
        if (data['underlyingName'] && !(typeof data['underlyingName'] === 'string' || data['underlyingName'] instanceof String)) {
            throw new Error("Expected the field `underlyingName` to be a primitive type in the JSON string but got " + data['underlyingName']);
        }
        // ensure the json data is a string
        if (data['issuerCountry'] && !(typeof data['issuerCountry'] === 'string' || data['issuerCountry'] instanceof String)) {
            throw new Error("Expected the field `issuerCountry` to be a primitive type in the JSON string but got " + data['issuerCountry']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['lE'] && !(typeof data['lE'] === 'string' || data['lE'] instanceof String)) {
            throw new Error("Expected the field `lE` to be a primitive type in the JSON string but got " + data['lE']);
        }
        // ensure the json data is a string
        if (data['LEI'] && !(typeof data['LEI'] === 'string' || data['LEI'] instanceof String)) {
            throw new Error("Expected the field `LEI` to be a primitive type in the JSON string but got " + data['LEI']);
        }
        // ensure the json data is a string
        if (data['comment'] && !(typeof data['comment'] === 'string' || data['comment'] instanceof String)) {
            throw new Error("Expected the field `comment` to be a primitive type in the JSON string but got " + data['comment']);
        }
        // ensure the json data is a string
        if (data['protocol'] && !(typeof data['protocol'] === 'string' || data['protocol'] instanceof String)) {
            throw new Error("Expected the field `protocol` to be a primitive type in the JSON string but got " + data['protocol']);
        }
        // ensure the json data is a string
        if (data['errorText'] && !(typeof data['errorText'] === 'string' || data['errorText'] instanceof String)) {
            throw new Error("Expected the field `errorText` to be a primitive type in the JSON string but got " + data['errorText']);
        }

        return true;
    }


}



/**
 * id of the changeset record
 * @member {String} id
 */
ComplianceChangesetItem.prototype['id'] = undefined;

/**
 * id of the changeset
 * @member {String} changesetId
 */
ComplianceChangesetItem.prototype['changesetId'] = undefined;

/**
 * Description type of the item type New or Delete
 * @member {String} changeType
 */
ComplianceChangesetItem.prototype['changeType'] = undefined;

/**
 * ISIN of the Underlying
 * @member {String} underlyingISIN
 */
ComplianceChangesetItem.prototype['underlyingISIN'] = undefined;

/**
 * Name of the Underlying
 * @member {String} underlyingName
 */
ComplianceChangesetItem.prototype['underlyingName'] = undefined;

/**
 * Country of the Issuer
 * @member {String} issuerCountry
 */
ComplianceChangesetItem.prototype['issuerCountry'] = undefined;

/**
 * The name of the issuer on the issuer blacklist
 * @member {String} name
 */
ComplianceChangesetItem.prototype['name'] = undefined;

/**
 * Date of Entry, based on ISO 8601 standard (YYYY-MM-DD)
 * @member {Date} entryDate
 */
ComplianceChangesetItem.prototype['entryDate'] = undefined;

/**
 * Legal entity
 * @member {String} lE
 */
ComplianceChangesetItem.prototype['lE'] = undefined;

/**
 * The Issuer LEI on the Compliance Issuer Blacklist
 * @member {String} LEI
 */
ComplianceChangesetItem.prototype['LEI'] = undefined;

/**
 * Additional comments
 * @member {String} comment
 */
ComplianceChangesetItem.prototype['comment'] = undefined;

/**
 * Protocol details
 * @member {String} protocol
 */
ComplianceChangesetItem.prototype['protocol'] = undefined;

/**
 * error message
 * @member {String} errorText
 */
ComplianceChangesetItem.prototype['errorText'] = undefined;






export default ComplianceChangesetItem;

