/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ComplianceDetails model module.
 * @module model/ComplianceDetails
 * @version 1.0.3
 */
class ComplianceDetails {
    /**
     * Constructs a new <code>ComplianceDetails</code>.
     * @alias module:model/ComplianceDetails
     * @param internalStatus {String} internal status
     * @param isin {String} ISIN
     * @param underlyingIsin {String} Underlying ISIN
     * @param issuerName {String} Issuer Name
     * @param valueDate {Date} Value Date
     * @param le {String} Legal entity
     * @param correlationId {String} correlationId
     * @param docId {String} document name
     */
    constructor(internalStatus, isin, underlyingIsin, issuerName, valueDate, le, correlationId, docId) { 
        
        ComplianceDetails.initialize(this, internalStatus, isin, underlyingIsin, issuerName, valueDate, le, correlationId, docId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, internalStatus, isin, underlyingIsin, issuerName, valueDate, le, correlationId, docId) { 
        obj['internalStatus'] = internalStatus;
        obj['isin'] = isin;
        obj['underlyingIsin'] = underlyingIsin;
        obj['issuerName'] = issuerName;
        obj['valueDate'] = valueDate;
        obj['le'] = le;
        obj['correlationId'] = correlationId;
        obj['docId'] = docId;
    }

    /**
     * Constructs a <code>ComplianceDetails</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ComplianceDetails} obj Optional instance to populate.
     * @return {module:model/ComplianceDetails} The populated <code>ComplianceDetails</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ComplianceDetails();

            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('underlyingIsin')) {
                obj['underlyingIsin'] = ApiClient.convertToType(data['underlyingIsin'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('valueDate')) {
                obj['valueDate'] = ApiClient.convertToType(data['valueDate'], 'Date');
            }
            if (data.hasOwnProperty('le')) {
                obj['le'] = ApiClient.convertToType(data['le'], 'String');
            }
            if (data.hasOwnProperty('correlationId')) {
                obj['correlationId'] = ApiClient.convertToType(data['correlationId'], 'String');
            }
            if (data.hasOwnProperty('docId')) {
                obj['docId'] = ApiClient.convertToType(data['docId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ComplianceDetails</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ComplianceDetails</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ComplianceDetails.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['underlyingIsin'] && !(typeof data['underlyingIsin'] === 'string' || data['underlyingIsin'] instanceof String)) {
            throw new Error("Expected the field `underlyingIsin` to be a primitive type in the JSON string but got " + data['underlyingIsin']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['le'] && !(typeof data['le'] === 'string' || data['le'] instanceof String)) {
            throw new Error("Expected the field `le` to be a primitive type in the JSON string but got " + data['le']);
        }
        // ensure the json data is a string
        if (data['correlationId'] && !(typeof data['correlationId'] === 'string' || data['correlationId'] instanceof String)) {
            throw new Error("Expected the field `correlationId` to be a primitive type in the JSON string but got " + data['correlationId']);
        }
        // ensure the json data is a string
        if (data['docId'] && !(typeof data['docId'] === 'string' || data['docId'] instanceof String)) {
            throw new Error("Expected the field `docId` to be a primitive type in the JSON string but got " + data['docId']);
        }

        return true;
    }


}

ComplianceDetails.RequiredProperties = ["internalStatus", "isin", "underlyingIsin", "issuerName", "valueDate", "le", "correlationId", "docId"];

/**
 * internal status
 * @member {String} internalStatus
 */
ComplianceDetails.prototype['internalStatus'] = undefined;

/**
 * ISIN
 * @member {String} isin
 */
ComplianceDetails.prototype['isin'] = undefined;

/**
 * Underlying ISIN
 * @member {String} underlyingIsin
 */
ComplianceDetails.prototype['underlyingIsin'] = undefined;

/**
 * Issuer Name
 * @member {String} issuerName
 */
ComplianceDetails.prototype['issuerName'] = undefined;

/**
 * Value Date
 * @member {Date} valueDate
 */
ComplianceDetails.prototype['valueDate'] = undefined;

/**
 * Legal entity
 * @member {String} le
 */
ComplianceDetails.prototype['le'] = undefined;

/**
 * correlationId
 * @member {String} correlationId
 */
ComplianceDetails.prototype['correlationId'] = undefined;

/**
 * document name
 * @member {String} docId
 */
ComplianceDetails.prototype['docId'] = undefined;






export default ComplianceDetails;

