/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BusinessValidationError from '../model/BusinessValidationError';
import Error from '../model/Error';

/**
* Redemptions service.
* @module api/RedemptionsApi
* @version 1.0.3
*/
export default class RedemptionsApi {

    /**
    * Constructs a new RedemptionsApi. 
    * @alias module:api/RedemptionsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the uploadDRPFileUsingPOST operation.
     * @callback module:api/RedemptionsApi~uploadDRPFileUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to validate redemption xml to Google Cloud Storage. Finally send the xml to BPO.
     * @param {File} file Redemption XML contains the Redemption details.
     * @param {module:api/RedemptionsApi~uploadDRPFileUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     */
    uploadDRPFileUsingPOST(file, callback) {
      let postBody = null;
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadDRPFileUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/di/v1/redemptions', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
