import { useEffect, useCallback, useState } from "react";
import { saveAs } from "file-saver";
import { ComplianceApiClient as complianceApiClient } from "../../../api";
import { getCountsBySection } from "../../../utility";
import { typesValueToLabel } from "../../Pending/Reviews/Reviews";
import {
  displayDescription,
  getApprovementStatusLabel,
} from "../../../utils/StatusUtils";
import { DATE_FORMATS } from "../../../constants";
import { convertDateTime, timer } from "../../../utils/dateUtils";

const useChangesets = (
  type,
  setData,
  setResponseText,
  setShowPopUp,
  setflagPopUp,
  userState,
  setLastUploadTime,
  setUploadFileOpen,
  setIndividualPendingSectionCount,
  defaultRowsCount = 100
) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchChangeSets = useCallback(
    (type, page = 1, pagesize = 100) => {
      if (
        userState.userRoleRight.includes("View_Compliance_List_Upload_Time")
      ) {
        const paginationParam = { page, pagesize };
        complianceApiClient
          .getComplianceChangesets(type, paginationParam)
          .then(({ response }) => {
            if (response && Array.isArray(response.data.items)) {
              const sortedArr = response.data.items
                .sort((a, b) => new Date(b.addedAt) - new Date(a.addedAt))
                .map((record) => ({
                  id: record.id,
                  timestamp: convertDateTime(
                    record.addedAt,
                    DATE_FORMATS.DATE_AND_TIME
                  ),
                  type: typesValueToLabel[record.type],
                  statusName: getApprovementStatusLabel(record.statusName),
                  description: displayDescription(record),
                  user: `${record.addedByUserName} (${record.addedByUserId})`,
                  timer: timer(record.addedAt),
                }));
              setItemsCount(response.data.count);
              setData(sortedArr);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            setResponseText("Something went wrong.Please try later");
            setShowPopUp(true);
            setflagPopUp("error");
          });
      }
    },
    [
      setData,
      setResponseText,
      setShowPopUp,
      setflagPopUp,
      userState.userRoleRight,
    ]
  );

  const exportTemplate = (type) => {
    complianceApiClient
      .exportComplianceTemplate(type)
      .then(({ response }) => {
        const blob = new Blob([response.data]);
        saveAs(blob, `${type}_Template.csv`);
        setResponseText("Template downloaded successfully");
        setShowPopUp(true);
        setflagPopUp("success");
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
      })
      .catch((error) => {
        setResponseText(`Unable to download ${type} template.Please try later`);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const uploadList = (type, uploadedFiles) => {
    setUploadFileOpen(false);
    complianceApiClient
      .addNewComplianceChangeSet(type, { file: uploadedFiles[0].file })
      .then(async (res) => {
        setResponseText("Upload successful");
        setShowPopUp(true);
        setflagPopUp("success");
        fetchChangeSets(type);
        const individualPendingCount = await getCountsBySection();
        setIndividualPendingSectionCount(individualPendingCount);
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
      })
      .catch((error) => {
        setResponseText(error.message);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const changePage = (type, pageNumber, pageSize) =>
    fetchChangeSets(type, pageNumber, pageSize);

  useEffect(() => {
    if (userState.userRoleRight.includes("View_Compliance_List_Upload_Time")) {
      complianceApiClient
        .getComplianceTime(type)
        .then(({ response: { data } }) => {
          setLastUploadTime(data.uploadDateTime);
        })
        .catch((error) => {
          setResponseText("Something went wrong. Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  }, [setLastUploadTime, type, userState.userRoleRight]);

  useEffect(() => {
    fetchChangeSets(type, 1, defaultRowsCount);
  }, [fetchChangeSets, type, defaultRowsCount]);

  return {
    fetchChangeSets,
    exportTemplate,
    uploadList,
    itemsCount,
    changePage,
    loading,
  };
};

export { useChangesets };
