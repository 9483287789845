import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ErrorBody } from "../index.css";
import historyCircle from "../../../assets/Oval Copy.png";
import { DATE_FORMATS } from "../../../constants";
import { convertDateTime } from "../../../utils/dateUtils";
/*
 * This array is for table column headers
 */
const errorLogColumns = [
  { id: "errorcode", label: "Error Code" },
  { id: "status", label: "Status" },
  { id: "dti", label: "DTI" },
  { id: "message", label: "Error Message" },
  { id: "info", label: "Error Additional Info" },
];

function ErrorLog(props) {
  return (
    <React.Fragment>
      <ErrorBody>
        <Typography variant="h3">Error Events</Typography>
        <div className="paddingbtm">
          {props.errorLogData.map((row) => {
            return (
              <Grid container className="padding1">
                <Grid item xs={1} className="flex">
                  <img src={historyCircle} alt="circle" className="padding2" />
                  <div className="history"></div>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h5" className="textcolour1">
                    {row.errorDateTimeDi
                      ? convertDateTime(
                          row.errorDateTimeDi,
                          DATE_FORMATS.DATE_AND_TIME
                        )
                      : row.errorDateTimeDi.replace(
                          /([a-z])([A-Z])/g,
                          "$1 $2"
                        )}{" "}
                    | Publisher: {row.errorPublisher}
                  </Typography>

                  <TableContainer className="tableBorder">
                    <Table aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow className="bgcolour">
                          <TableCell width="15%" className="bottomborder">
                            <Typography variant="h5">
                              {" "}
                              {errorLogColumns[0].label}
                            </Typography>
                          </TableCell>
                          <TableCell width="15%" className="bottomborder">
                            <Typography variant="h5">
                              {" "}
                              {errorLogColumns[1].label}
                            </Typography>
                          </TableCell>
                          <TableCell width="15%" className="bottomborder">
                            <Typography variant="h5">
                              {errorLogColumns[2].label}
                            </Typography>
                          </TableCell>
                          <TableCell width="15%" className="bottomborder">
                            <Typography variant="h5">
                              {errorLogColumns[3].label}
                            </Typography>
                          </TableCell>
                          <TableCell width="25%" className="bottomborder">
                            <Typography variant="h5">
                              {errorLogColumns[4].label}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.errorDetails.map((elem) => {
                          return (
                            <TableRow>
                              <TableCell width="15%">
                                <Typography variant="h6">
                                  {elem.errorCode}
                                </Typography>
                              </TableCell>
                              {elem.errorStatus === "UnknownCode" ? ( //for unknownCode errorStatus will be color coded
                                <TableCell width="15%">
                                  <Typography
                                    variant="h6"
                                    className="textcolour2"
                                  >
                                    {elem.errorStatus}
                                  </Typography>
                                </TableCell>
                              ) : (
                                <TableCell width="15%">
                                  <Typography variant="h6">
                                    {elem.errorStatus}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell width="30%">
                                <Typography variant="h6">
                                  {elem.optionalDti}
                                </Typography>
                              </TableCell>
                              {elem.errorStatus === "UnknownCode" ? ( //for unknownCode errorMessage will be started with "Unknown Error:"
                                <TableCell width="30%">
                                  <Typography variant="h6">
                                    Unknown Error:{" "}
                                    {elem.errorMessage !== ""
                                      ? elem.errorMessage
                                      : "No Error Message available"}
                                  </Typography>
                                </TableCell>
                              ) : (
                                <TableCell width="30%">
                                  <Typography variant="h6">
                                    {elem.errorMessage}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell width="40%">
                                <Typography variant="h6">
                                  {elem.errorAdditionalInfo
                                    ?.join(", ")
                                    .replace(/^\[(.+)\]$/, "$1")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            );
          })}
        </div>
      </ErrorBody>
    </React.Fragment>
  );
}

export default ErrorLog;
