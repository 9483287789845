import { createTheme } from "@material-ui/core/styles";
import { dinBold, dinRegular, dinMedium } from "./fonts";

export const theme = createTheme({
  //defined different types of typography as per requirements globally
  typography: {
    fontFamily: ["FFDINRegular", "FFDINMedium", "FFDINBold"].join(","),
    fontSize: 14,
    color: "#000000",
    button: {
      textTransform: "none",
    },
    h1: {
      fontFamily: "FFDINRegular",
      fontSize: 28,
    },
    h2: {
      fontFamily: "FFDINBold",
      fontSize: 21,
    },
    h3: {
      fontFamily: "FFDINMedium",
      fontSize: 17,
    },
    h4: {
      fontFamily: "FFDINRegular",
      fontSize: 17,
    },
    h5: {
      fontFamily: "FFDINBold",
      fontSize: 14,
    },
    h6: {
      fontFamily: "FFDINMedium",
      fontSize: 14,
    },
    subtitle1: {
      fontFamily: "FFDINRegular",
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: "FFDINBold",
      fontSize: 12,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [dinBold, dinMedium, dinRegular],
      },
    },
    //custom styled MuiButton for contained primary and outlined primary variants
    MuiButton: {
      root: {
        borderRadius: "17px",
        width: "120px",
        height: "34px",
        padding: 0,
      },
      containedPrimary: {
        backgroundColor: "#00A7C4",
        fontSize: 14,
        fontFamily: "FFDINBold",
        "&:hover": {
          backgroundColor: "#00A7C4",
          "@media (hover:none)": {
            backgroundColor: "#00A7C4",
          },
        },
        "&:disabled": {
          color: "white",
          backgroundColor: "#A8BDC1",
        },
      },

      outlinedPrimary: {
        color: "#00A7C4",
        backgroundColor: "white",
        fontSize: 14,
        fontFamily: "FFDINBold",
        border: "solid 1px #00A7C4",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "14px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: "white",
        "&$focused $notchedOutline": {
          borderColor: "rgb(38, 152, 255)",
          borderWidth: 2,
        },
      },
    },
  },
});
