//custom material UI Accordian components
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: "100%",
  boxShadow: "0px 1px 5px 1px #87878799",
  borderRadius: "12px",
  backgroundColor: "#FFFFFF",
  paddingLeft: "21px",
  paddingRight: "21px",
  paddingBottom: "10px",
  alignItems: "center",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        id="accordionIcon"
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff !important",
  fontSize: "15px",
  flexDirection: "row-reverse",
  alignItems: "center",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiTypography-root": {
    fontSize: "17px",
    fontFamily: "DIN",
    fontWeight: "600",
  },
  "& #accordionIcon": {
    color: "#00a7c4",
    width: "0",
    height: "0",
    borderTop: "6px solid transparent",
    borderBottom: "6px solid transparent",
    borderLeft: "8px solid #00a7c4",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0px`,
  borderTop: "2px solid #00a7c4",
  "& .MuiAccordionDetails-root": {
    borderTop: "2px solid #00a74",
  },
}));
