/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RedemptionBriefInfo model module.
 * @module model/RedemptionBriefInfo
 * @version 1.0.3
 */
class RedemptionBriefInfo {
    /**
     * Constructs a new <code>RedemptionBriefInfo</code>.
     * @alias module:model/RedemptionBriefInfo
     */
    constructor() { 
        
        RedemptionBriefInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RedemptionBriefInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RedemptionBriefInfo} obj Optional instance to populate.
     * @return {module:model/RedemptionBriefInfo} The populated <code>RedemptionBriefInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RedemptionBriefInfo();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = ApiClient.convertToType(data['user'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('timer')) {
                obj['timer'] = ApiClient.convertToType(data['timer'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RedemptionBriefInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RedemptionBriefInfo</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }
        // ensure the json data is a string
        if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
            throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['user'] && !(typeof data['user'] === 'string' || data['user'] instanceof String)) {
            throw new Error("Expected the field `user` to be a primitive type in the JSON string but got " + data['user']);
        }

        return true;
    }


}



/**
 * id
 * @member {String} id
 */
RedemptionBriefInfo.prototype['id'] = undefined;

/**
 * Type
 * @member {String} type
 */
RedemptionBriefInfo.prototype['type'] = undefined;

/**
 * Status
 * @member {String} status
 */
RedemptionBriefInfo.prototype['status'] = undefined;

/**
 * Description
 * @member {String} description
 */
RedemptionBriefInfo.prototype['description'] = undefined;

/**
 * User
 * @member {String} user
 */
RedemptionBriefInfo.prototype['user'] = undefined;

/**
 * issuance blotter Count
 * @member {Date} timestamp
 */
RedemptionBriefInfo.prototype['timestamp'] = undefined;

/**
 * Timestamp
 * @member {Number} timer
 */
RedemptionBriefInfo.prototype['timer'] = undefined;






export default RedemptionBriefInfo;

