/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Observation model module.
 * @module model/Observation
 * @version 1.0.3
 */
class Observation {
    /**
     * Constructs a new <code>Observation</code>.
     * @alias module:model/Observation
     */
    constructor() { 
        
        Observation.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Observation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Observation} obj Optional instance to populate.
     * @return {module:model/Observation} The populated <code>Observation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Observation();

            if (data.hasOwnProperty('BarrierEndDate')) {
                obj['BarrierEndDate'] = ApiClient.convertToType(data['BarrierEndDate'], 'Date');
            }
            if (data.hasOwnProperty('AutoCallable')) {
                obj['AutoCallable'] = ApiClient.convertToType(data['AutoCallable'], 'Boolean');
            }
            if (data.hasOwnProperty('ReferencePrice')) {
                obj['ReferencePrice'] = ApiClient.convertToType(data['ReferencePrice'], 'Number');
            }
            if (data.hasOwnProperty('BarrierStartDate')) {
                obj['BarrierStartDate'] = ApiClient.convertToType(data['BarrierStartDate'], 'Date');
            }
            if (data.hasOwnProperty('BarrierType')) {
                obj['BarrierType'] = ApiClient.convertToType(data['BarrierType'], 'String');
            }
            if (data.hasOwnProperty('BarrierValue')) {
                obj['BarrierValue'] = ApiClient.convertToType(data['BarrierValue'], 'Number');
            }
            if (data.hasOwnProperty('RollOverDate')) {
                obj['RollOverDate'] = ApiClient.convertToType(data['RollOverDate'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Observation</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Observation</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['BarrierType'] && !(typeof data['BarrierType'] === 'string' || data['BarrierType'] instanceof String)) {
            throw new Error("Expected the field `BarrierType` to be a primitive type in the JSON string but got " + data['BarrierType']);
        }

        return true;
    }


}



/**
 * End of the barrier observation period[3]
 * @member {Date} BarrierEndDate
 */
Observation.prototype['BarrierEndDate'] = undefined;

/**
 * An autocallable is a popular type of certificate that pays a high coupon if the underlying – typically equity indexes or single stocks – passes an upside barrier, at which point it automatically matures and the investor’s principal is returned.[4]
 * @member {Boolean} AutoCallable
 */
Observation.prototype['AutoCallable'] = undefined;

/**
 * The price/quotation/level of the Underlying, on one or more Valuation Dates, that is used to calculate the Cash Amount that is to be paid. For nominally traded instruments the amount in percent and for instruments traded in units, the amount in the currency of the underlying[231]
 * @member {Number} ReferencePrice
 */
Observation.prototype['ReferencePrice'] = undefined;

/**
 * Begin of the barrier observation period.[97]
 * @member {Date} BarrierStartDate
 */
Observation.prototype['BarrierStartDate'] = undefined;

/**
 * Type of Barrier/Threshold[232]
 * @member {String} BarrierType
 */
Observation.prototype['BarrierType'] = undefined;

/**
 * Value of the Barrier/Threshold. Some equity/indexlinked Reverse Convertible Notes may have an additional Barrier below the Strike. If the price of the Underlying does not touch/breach the Barrier during the term of the Note or during the observation period, as determined in the Terms and Conditions, the Note will be redeemed with the Nominal Amount at Maturity. Mandatory if a type was delivered.[233]
 * @member {Number} BarrierValue
 */
Observation.prototype['BarrierValue'] = undefined;

/**
 * The rollover will be carried out on one trading day (the \"Rollover Day\") within a time frame specified in the Final Terms shortly before the expiry date of the current forward contract.[36]
 * @member {Date} RollOverDate
 */
Observation.prototype['RollOverDate'] = undefined;






export default Observation;

