import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import EllipisWithTooltip from "../../components/EllipsisWithTooltip";

const RowElement = ({ label, value }) => (
  <Grid container xs={12} spacing={2} className="padding3">
    <Grid item xs={6} key="id">
      <Typography variant="h5" id="det_clear_typo_sec">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6} key="id" align="right">
      <Typography variant="body2" id="det_clearB_typo_sec">
        <EllipisWithTooltip>{value}</EllipisWithTooltip>
      </Typography>
    </Grid>
  </Grid>
);

export default RowElement;
