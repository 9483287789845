export const styles = {
    ul: {
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#00A7C4",
            color: "whitesmoke",
        },
        "& .MuiPaginationItem-icon": {
            border: "1px solid #00A7C4",
            borderRadius: "12px",
        },
        "& .MuiPaginationItem-page": {
            color: "#00A7C4",
        },
    }
}