import React, { useState, useContext } from "react";
import {
  StyledContent,
  StyledOfDialog,
  StyledDialogActions,
  StyledDialogContentText,
} from "../Template/index.css";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import uploadSuccessIcon from "../../assets/UploadSuccess.svg";
import uploadFailureIcon from "../../assets/UploadFailure.svg";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppCommonContext } from "../../App";
import { IssuancesCommandsApiClient, IncreaseCommandsApiClient } from "../../api/index";

const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
  dialogPaper: {
    width: "450px",
    height: "380px",
  },
}));

const CreateIssuance = ({
  action,
  setflagPopUp,
  open,
  onClose,
  setResponseText,
  showMessagePopUp,
  setValidationError,
  showValidationErrors,
  setValidationMessage
}) => {
  const [show, setShow] = useState(false);
  const [showPDFmsg, setShowPDFmsg] = useState(false);
  const [uploadedXmlFiles, setUploadedXmlfiles] = useState([]);
  const [uploadedPdfFiles, setUploadedPdfFiles] = useState([]);
  const [tempFileData, setTempFileData] = useState();
  const [tempFileDataForPdf, setTempFileDataForPdf] = useState();
  const classes = useStyles();
  const [flag, setflag] = React.useState("");
  const [flagForPDF, setflagForPDF] = React.useState("");
  const [isinData, setIsinData] = useState("");
  const { dispatch } = useContext(AppCommonContext);

  const catchBlockError = (error) => {
    if (error.response.status === 400) {
      return "Bad Request.Please try with correct request";
    } else if (error.response.status === 404) {
      return "Api not found.Please try again later";
    } else if (error.response.status === 408) {
      return "Request Timeout.Please try again later";
    } else if (error.response.status >= 500) {
      return "Services are not available.Please try again later";
    } else {
      return "Unknown Error.Please try later";
    }
  };

  const getISINValue = (xml) => {
    if (
      xml.getElementsByTagName("IDS")[0]?.childNodes[1]?.nodeName === "ISIN"
    ) {
      return xml.getElementsByTagName("IDS")[0].childNodes[1].innerHTML;
    } else if (
      xml.getElementsByTagName("ns0:IDS")[0]?.childNodes[1]?.nodeName ===
      "ns0:ISIN"
    ) {
      return xml.getElementsByTagName("ns0:IDS")[0].childNodes[1].innerHTML;
    } else {
      return "";
    }
  };
  /*
   * Validating uploaded excel file in UI
   */
  const filePathSet = (event) => {
    event.preventDefault();
    let fileData = event.target.files;

    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length,
      );
      let obj = {
        fileName: fileData[x].name,
        file: fileData[x],
      };
      let files = [];
      files.push(obj);
      setUploadedXmlfiles(files);
      if (fileExtension.toLowerCase() === "xml") {
        var reader = new FileReader();
        reader.readAsText(event.target.files[0]);
        reader.onloadend = (evt) => {
          //code for fetching ISIN from XML
          const readerData = evt.target.result;
          const parser = new DOMParser();
          const xml = parser.parseFromString(readerData, "text/xml");
          const isin = getISINValue(xml);
          setIsinData(isin);
        };
        setResponseText("");
        setflag("success");
        showMessagePopUp(false);
      } else {
        setResponseText(
          "Error! Selected file is not supported. Please choose another file.",
        );
        setflag("error");
        showMessagePopUp(false);
      }
      setShow(true);
    }
    setTempFileData({ fileData }); //storing XML file data
  };

  /*
   * Validating uploaded PDF file in UI for Issuance creation
   */
  const filePathSetForPDF = (event) => {
    event.preventDefault();
    let fileData = event.target.files;

    for (let x = 0; x < fileData.length; x++) {
      let fileExtension = fileData[x].name.substring(
        fileData[x].name.lastIndexOf(".") + 1,
        fileData[x].name.length,
      );
      let obj = {
        fileName: fileData[x].name,
        file: fileData[x],
      };
      let files = [];
      files.push(obj);
      setUploadedPdfFiles(files);
      if (fileExtension.toLowerCase() === "pdf") {
        setflagForPDF("success");
        showMessagePopUp(false);
      } else {
        setflagForPDF("error");
        showMessagePopUp(false);
      }
      setShowPDFmsg(true);
    }
    setTempFileDataForPdf({ fileData }); //storing PDF file data
  };

  const upload = () => {
    closeDialog();
    showMessagePopUp(true);
    let axiosObject = "";
    if (action === "New Issuance") {
      var postData = {
        issuance: uploadedXmlFiles[0].file,
        tc: uploadedPdfFiles[0].file,
      };
      axiosObject = IssuancesCommandsApiClient.convertUploadedXMLFileUsingPOST(postData);
    } else {
      var postData = {
        increase: uploadedXmlFiles[0].file,
        tc: uploadedPdfFiles[0].file,
      };
      axiosObject = IncreaseCommandsApiClient.uploadIncrease(postData);
    }
    if (axiosObject) {
      axiosObject.then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          setResponseText(`${action} created`);
          showMessagePopUp(true);
          setUploadedXmlfiles([]);
          setUploadedPdfFiles([]);
          setflagPopUp("success");
          setTimeout(() => {
            showMessagePopUp(false);
            setResponseText("");
          }, 8000);
        } else {
          if (response?.data?.businessValidationErrors.length > 0) {
            setValidationMessage("XML Error : " + response?.data?.message);
            setValidationError(response?.data?.businessValidationErrors);
            showValidationErrors(true);
            setUploadedXmlfiles([]);
          } else {
            let errRes = "XML Error : " + response?.data?.message;
            setResponseText(errRes);
            showMessagePopUp(true);
            setUploadedXmlfiles([]);
            setflagPopUp("error");
          }
        }
      })
        .catch((error) => {
          let errRes = error
            ? error.toString()
            : "XML/PDF Error : " + catchBlockError(error);
          setResponseText(errRes);
          showMessagePopUp(true);
          setUploadedXmlfiles([]);
          setUploadedPdfFiles([]);
          setflagPopUp("error");
        });
    }
  };

  const closeDialog = () => {
    setflag("");
    setflagPopUp("");
    setShow(false);
    setShowPDFmsg(false);
    setUploadedXmlfiles([]);
    setUploadedPdfFiles([]);
    onClose();
  };

  return (
    <StyledOfDialog
      classes={{ paper: classes.dialogPaper }}
      className="newIssuanceDialog"
      id="temp_newIssuanceDialog"
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <Typography variant="h5" className="newIssFont" id="temp_newIssFont">
        {action}
      </Typography>
      <StyledContent>
        <StyledDialogContentText>
          <Typography
            variant="h5"
            id="temp_uploadXmlPdf"
            className="uploadXmlPdf"
            data-testid="issuanceDialogTitle"
          >
            Please upload XML & PDF file to create a{" "}
            {action.toLocaleLowerCase()}.
          </Typography>
        </StyledDialogContentText>
        <Grid
          container
          id="temp_tempPaddingTop_container"
          className="tempPaddingTop"
        >
          <Grid item xs={1}>
            <InsertDriveFileOutlinedIcon
              id="temp_issuanceMargin"
              className="issuanceMargin"
            />
          </Grid>
          <Grid
            item
            container
            xs={7}
            id="temp_underlineXml"
            className="underlineXml"
          >
            <Grid item xs={11}>
              <div id="temp_word" className="word">
                {uploadedXmlFiles.length !== 0
                  ? tempFileData.fileData[0]?.name
                  : "No file choosen"}
              </div>
            </Grid>
            <Grid item xs={1}>
              <span id="temp_float" className="float">
                {show === true && (
                  <>
                    {flag === "error" ? (
                      <img
                        src={uploadFailureIcon}
                        alt={"uploadFailure"}
                        id="temp_uploadFailureIcon"
                      />
                    ) : (
                      <img
                        src={uploadSuccessIcon}
                        alt={"uploadSuccess"}
                        id="temp_uploadSuccessIcon"
                        data-testid="uploadSuccessIconXML"
                      />
                    )}
                  </>
                )}{" "}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={4} id="temp_chooseXML" className="paddingXml">
            <label htmlFor="xmlfileUpload">
              <Button
                variant="outlined"
                id="temp_chooseXML_button"
                color="primary"
                component="span"
              >
                Choose XML
              </Button>
            </label>
            <input
              className="displayIss"
              type="file"
              id="xmlfileUpload"
              data-testid="uploadFileXML"
              onChange={filePathSet.bind(this)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            {" "}
            {show && (
              <>
                {flag === "error" ? (
                  <Typography
                    variant="subtitle1"
                    id="temp_error_message"
                    className="colorError"
                  >
                    Error: File type is not supported. Please select another
                    file and try again.
                  </Typography>
                ) : (
                  ""
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          id="temp_tempPaddingTop_Grid"
          className="tempPaddingTop"
        >
          <Grid item xs={1}>
            <InsertDriveFileOutlinedIcon
              id="temp_margin"
              className="issuanceMargin"
            />
          </Grid>
          <Grid
            item
            container
            xs={7}
            id="temp_underline"
            className="underlineXml"
          >
            <Grid item xs={11}>
              <div className="word" id="temp_word_div">
                {uploadedPdfFiles.length !== 0
                  ? tempFileDataForPdf.fileData[0]?.name
                  : "No file choosen"}
              </div>
            </Grid>
            <Grid item xs={1}>
              <span id="temp_float_span" className="float">
                {showPDFmsg === true && (
                  <>
                    {flagForPDF === "error" ? (
                      <img
                        id="temp_failureIconPdf"
                        alt={"FailureIcon"}
                        src={uploadFailureIcon}
                      />
                    ) : (
                      <img
                        src={uploadSuccessIcon}
                        alt={"successIcon"}
                        id="temp_uploadSuccessIconPDF"
                        data-testid="uploadSuccessIconPDF"
                      />
                    )}
                  </>
                )}{" "}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={4} id="temp_choosePDF" className="paddingXml">
            <label htmlFor="pdffileUpload">
              <Button
                variant="outlined"
                color="primary"
                id="temp_choosePDF_button"
                component="span"
              >
                Choose PDF
              </Button>
            </label>
            <input
              className="displayIss"
              data-testid="uploadFilePDF"
              type="file"
              id="pdffileUpload"
              onChange={filePathSetForPDF.bind(this)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            {" "}
            {showPDFmsg && (
              <>
                {flagForPDF === "error" ? (
                  <Typography
                    variant="subtitle1"
                    id="temp_error_text"
                    className="colorError"
                  >
                    Error: File type is not supported. Please select another
                    file and try again.
                  </Typography>
                ) : (
                  ""
                )}
              </>
            )}
          </Grid>
        </Grid>
      </StyledContent>

      <StyledDialogActions id="temp_actions" className="paddingActions">
        <Button
          onClick={() => {
            closeDialog();
          }}
          id="temp_cancelButton"
          data-testid="cancel_issuance"
          className="cancelColor"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="marginNega"
          id="temp_submitButton"
          disabled={
            uploadedXmlFiles.length === 0 ||
            flagForPDF === "error" ||
            flag === "error" ||
            uploadedPdfFiles.length === 0
          }
          data-testid="submit_issuance"
          onClick={() => {
            upload();
          }}
        >
          Submit
        </Button>
      </StyledDialogActions>
    </StyledOfDialog>
  );
};

export default CreateIssuance;
