import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {
  StyledContainer,
  StyledHeader,
  StyledBody,
  StyledDialog,
} from "./index.css";
import { Button, Grid } from "@material-ui/core";
import ViewPdfGreyIcon from "../../assets/inactiveActionIcons/View PDF-Grey.svg";
import viewPdfActiveIcon from "../../assets/deselectedActionIcons/View PDF-Outline.svg";
import downloadCSVActiveIcon from "../../assets/deselectedActionIcons/Download-CSV.svg";
import copytoClipboardIcon from "../../assets/deselectedActionIcons/CopytoClipboard.svg";
import downloadXMLIcon from "../../assets/deselectedActionIcons/Download-Outline.svg";
import closeIcon from "../../assets/deselectedActionIcons/Close.svg";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ProcessLog from "./ProcessLog/ProcessLog";
import ViewXML from "./ViewXML/ViewXML";
import ErrorLog from "./ErrorLog/ErrorLog";
import CombinedLog from "./CombinedLog/CombinedLog";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import SimpleTable from "../../components/SimpleTable";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import { useUserStore } from "../../store/Store";
import refresh from "../../assets/refresh.svg";
import initialXMLFixture from "./test/initialXMLFixture";
import { debounce } from "../../utility";
import {
  getExternalProcessStatusLabel,
  getExternalOverallStatusLabel,
  getInternalStatusLabel,
  getInternalStatusValue,
  getPublisherLabel,
} from "../../utils/StatusUtils";
import { useHistory } from "react-router-dom";
import {
  IssuancesQueriesApiClient,
  RedemptionsInternalApiClient,
  IssuancesLogsInternalApiClient,
  IssuancesInternalApiClient,
  TransactionStoreApiClient,
} from "../../api";
import {
  addSeparator,
  formatNumberWithDecimal,
  formatNumberWithDecimallimits,
  formatNumberWithoutDecimal,
  isDCM,
} from "./utils";
import EligibilityCheckRSP from "./PanelsRSP/EligibilityCheck";
import EligibilityCheckDCM from "./PanelsDCM/EligibilityCheck";
import IdentifierRSP from "./PanelsRSP/Identifier";
import IdentifierDCM from "./PanelsDCM/Identifier";
import StatusListingRSP from "./PanelsRSP/StatusListing";
import StatusListingDCM from "./PanelsDCM/StatusListing";
import CalendarRSP from "./PanelsRSP/Calendar";
import CalendarDCM from "./PanelsDCM/Calendar";
import DetailInstrumentInfoRSP from "./PanelsRSP/DetailInstrumentInfo";
import DetailInstrumentInfoDCM from "./PanelsDCM/DetailInstrumentInfo";
import AccountSenderDetailsRSP from "./PanelsRSP/AccountSenderDetails";
import AccountSenderDetailsDCM from "./PanelsDCM/AccountSenderDetails";
import Underlying from "./PanelsRSP/Underlying";
import UnderlyingDCM from "./PanelsDCM/Underlying";
import { Space } from "antd";
import { DATE_FORMATS } from "../../constants";
import { convertDateTime } from "../../utils/dateUtils";
import BackButton from "../../components/BackButton";

/*
 * Below represents the styling of Tooltip used in icons
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function IssuanceDetails(props) {
  const location = useLocation();
  const [userState] = useUserStore();
  const history = useHistory();
  var propsValue;
  const [expandState, setExpandState] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
    panel7: true,
    panel8: true,
  }); // Firstly all the accordions will be expanded
  const [expandAll, setExpandAll] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [view, setView] = useState("details");
  const [flagPopUp, setflagPopUp] = useState("");
  const [processLogData, setProcessLogData] = useState([]);
  const [initialXMLData, setInitialXMLData] = useState([]);
  const [errorLogData, setErrorLogData] = useState([]);
  const [combinedLogData, setCombinedLogData] = useState([]);
  const [issuanceObj, setIssuanceObj] = useState({});
  const [TCDocId, setTCDocId] = useState();
  const [errorLogEntries, setErrorLogEntries] = useState([]);
  const [showIncreaseSection, setShowIncreaseSection] = useState(false);
  const [increaseTableData, setIncreaseTableData] = useState([]);
  const [acceptOrRejectIssuance, setAcceptOrRejectIssuance] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [UnderlingData, setUnderlingData] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [urlFlag, setUrlFlag] = useState(false);
  const [isViewDetailsApiSuccess, setIsViewDetailsApiSuccess] = useState(false);
  const [apiFlag, setApiFlag] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [showAcceptRejectButtons, setShowAcceptRejectButtons] = useState(false);
  const [acceptRejectEnabled, setAcceptRejectEnabled] = useState(true);
  const isRedemption = history.location?.state?.tabName === "redemptionReview";
  const allowActionsForRedemptions = history.location?.state?.allowActions;
  const increseTableColumns = {
    ValueDate: "Value Date",
    NominalAmount: "Delta",
    IssuedNominalAmount: "Total nominal amount",
  };
  const isClearstream = userState.userRoleRight.includes(
    "View_Internal_Status"
  );
  const tabs = ["redemptionReview", "blotter", "compliance", "lifecycle"];
  const showBackNavigationButton = tabs.includes(
    history.location?.state?.tabName
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const debouncedHandleResize = debounce(function handleResize() {
      setIsDesktop(window.innerWidth > 1450);
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);
    if (location.state == null) {
      //eslint-disable-next-line react-hooks/exhaustive-deps
      propsValue = props.issuanceObj;
      if (propsValue.state.pageType === "details") {
        getIssuance();
        setApiFlag(false);
      } else {
        getCombinedLog();
        setApiFlag(true);
      }
      propsValue.state.pageType = "details";
    } else {
      propsValue = location; //setting value for issuance details
      getIssuance();
    }
    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    setAcceptRejectButtons();
  }, [apiFlag, view, issuanceObj]);

  //open pdf in new tab when view - pdf is clicked
  useEffect(() => {
    if (urlFlag) openPdfWindow();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl]);

  useEffect(() => {
    const {
      initialNominalAmount: initialAmount,
      issuedNominalAmount: currentAmount,
    } = issuanceObj;
    if (
      parseInt(initialAmount?.replace(",", "")) <
        parseInt(currentAmount?.replace(",", "")) &&
      issuanceObj.increase?.length !== 0
    ) {
      setShowIncreaseSection(true);
    }
    if (issuanceObj?.increase) {
      const fixedDateIncreases = issuanceObj.increase.map((record) => ({
        ...record,
        ValueDate: moment(record.ValueDate).format(DATE_FORMATS.DATE),
      }));
      setIncreaseTableData(fixedDateIncreases);
    }
  }, [setShowIncreaseSection, issuanceObj, setIncreaseTableData]);

  const parseValue = (evaluateValue) => {
    try {
      const value = evaluateValue();
      if (value !== undefined || value !== null) return value;
      else return "";
    } catch (error) {
      console.log("Err", error);
      return "";
    }
  };
  /*
   * This method will fetch details of the issuance
   */
  const getIssuance = () => {
    location.state == null
      ? (propsValue = props.issuanceObj)
      : (propsValue = location); //setting value for issuance details
    IssuancesQueriesApiClient.getIssuanceByISIN(propsValue.state.isin)
      .then(({ response }) => {
        //checking issuance type
        setTCDocId(response?.data?.DocId);
        let temp = response?.data?.DIObject?.DIBase;
        let externalOverallStatus = response?.data?.ExternalOverallStatus;
        let externalProcessingStatus = response?.data?.ExternalProcessingStatus;
        let status = response?.data?.DIObject?.CurrentState?.Status;
        sessionStorage.setItem(
          "issuer",
          parseValue(
            () =>
              temp.Issuance?.Party?.find((r) => r.PartyRole?.includes("Issuer"))
                ?.Name
          )
        );

        let {
          Issuance,
          Redemption = [],
          Interest = [],
          Increase = [],
          General,
          Venue,
          Identifiers,
        } = temp;
        const {
          Party = [],
          Documentation,
          InterestFeatures,
          Jurisdiction,
          Programme,
          RedemptionFeatures,
          Tax,
          Underlying = [],
          VenueData,
        } = Issuance;
        if (!Redemption) {
          Redemption = [];
        }
        if (!Interest) {
          Interest = [];
        }
        let obj = {
          issuer: parseValue(
            () => Party?.find((r) => r.PartyRole.includes("Issuer"))?.Name
          ), // Issuer
          isin: parseValue(() => Identifiers?.ISIN), // ISIN
          eusipaID: parseValue(() => Issuance?.EusipaID),
          sampleDate: parseValue(
            () =>
              Issuance?.SampleDate &&
              moment(Issuance?.SampleDate).format(DATE_FORMATS.DATE)
          ),
          maturityDate: parseValue(
            () =>
              Issuance?.MaturityDate &&
              moment(Issuance.MaturityDate).format(DATE_FORMATS.DATE)
          ), //Maturity Date
          finalValuationDate: parseValue(
            () =>
              Issuance.FinalValuationDate &&
              moment(Issuance.FinalValuationDate).format(DATE_FORMATS.DATE)
          ),
          redemptionType: parseValue(() => Redemption?.[0]?.RedemptionType),
          redemptionRate: parseValue(
            () =>
              RedemptionFeatures?.RedemptionRate &&
              addSeparator(RedemptionFeatures?.RedemptionRate)
          ),
          redemptionCashAmountCurrency: parseValue(
            () => Issuance?.SettlementCurrency
          ), //Distribution Currency & Settlement Currency
          underlyingName: parseValue(() => Underlying?.[0]?.InstrumentLongName),
          country: parseValue(
            () => Interest?.[0]?.UnderlyingInstrument?.Country
          ),
          assetClass: parseValue(
            () => Interest?.[0]?.UnderlyingInstrument?.UnderlyingProductType
          ),
          barrierStartDate: parseValue(
            () =>
              Underlying?.[0]?.Observation?.[0]?.BarrierStartDate &&
              moment(
                Underlying?.[0]?.Observation?.[0]?.BarrierStartDate
              )?.format(DATE_FORMATS.DATE)
          ),
          bonusCurrency: parseValue(() => RedemptionFeatures?.BonusCurrency),
          referenceSource: parseValue(() => InterestFeatures?.RateSource),
          underlyingIsin: parseValue(() => Underlying?.[0]?.ISIN),
          underlyingMultiplier: parseValue(
            () =>
              Issuance?.Multiplier &&
              addSeparator(
                formatNumberWithDecimallimits(Issuance?.Multiplier, 10)
              )
          ),
          bonusAmount: parseValue(
            () =>
              RedemptionFeatures?.BonusAmount &&
              addSeparator(
                formatNumberWithDecimal(RedemptionFeatures?.BonusAmount)
              )
          ),
          underlyingType: parseValue(() => Underlying?.[0]?.UnderlyingType),
          underlyingCurrency: parseValue(
            () => Interest?.[0]?.UnderlyingInstrument?.Currency
          ),
          quotation: parseValue(() => Issuance?.TradingMethod),
          exerciseMinimum: parseValue(
            () =>
              VenueData?.CBF?.RefData?.MinimumExerciseAmount &&
              addSeparator(
                formatNumberWithDecimallimits(
                  VenueData?.CBF?.RefData?.MinimumExerciseAmount,
                  10
                )
              )
          ), //*
          upToNominal: parseValue(() => Issuance?.UpTo.toString()),
          exerciseAutomatic: parseValue(
            () => VenueData?.CBF?.RefData?.AutomaticExercise
          ),
          issuedNominalAmount: parseValue(
            () =>
              Issuance?.IssuedNominalAmount &&
              addSeparator(
                formatNumberWithDecimallimits(Issuance?.IssuedNominalAmount, 5)
              )
          ),
          wkn: parseValue(
            () =>
              Identifiers?.OtherIdentification?.find((r) => r?.Type === "WKNR")
                ?.Value
          ), // WKN
          valueDate: parseValue(
            () =>
              Issuance?.ValueDate &&
              moment(Issuance?.ValueDate).format(DATE_FORMATS.DATE)
          ), //Value Date
          messageDateTime: parseValue(
            () =>
              General?.DeliveryDate &&
              moment(General?.DeliveryDate).format(DATE_FORMATS.DATE)
          ),
          messageType: parseValue(() => General?.Purpose),
          issuerAgentEmail: parseValue(
            () =>
              Party?.find((r) => r?.PartyRole?.includes("IssuerAgent"))?.Email
          ),
          languageDocument: parseValue(() => Documentation?.Language),
          firstTradingDate: parseValue(
            () => Venue?.Trading?.[0]?.FirstTradingDate
          ), //TBD
          nominalCurrency: parseValue(() => Issuance?.NominalCurrency),
          paymentCurrency: parseValue(() => Redemption?.[0]?.PaymentCurrency),
          furtherExchange: "",
          minTradingUnit: parseValue(
            () =>
              Issuance?.MinimumDenomination &&
              addSeparator(
                formatNumberWithDecimal(Issuance?.MinimumDenomination)
              )
          ),
          minOrderLots: "",
          listingStatus: "",
          delistingReason: "",
          lastTradingDate: "",
          securitiesAccountNumber: parseValue(
            () =>
              Party?.find((r) =>
                r?.PartyRole?.includes("IssuerAgent")
              )?.OtherPartyIdentification?.find(
                (r) => r?.AccountType === "CBF Account"
              )?.AccountNumber
          ),
          issuerKvNoPayAgent: parseValue(
            () =>
              Party?.find((r) =>
                r?.PartyRole?.includes("PrincipalPayingAgent")
              )?.OtherPartyIdentification?.find(
                (r) => r?.AccountType === "CBF Account"
              )?.AccountNumber
          ),
          productName: parseValue(() =>
            Issuance?.SecurityLongName?.replace(/([a-z])([A-Z])/g, "$1 $2")
          ),
          dti: parseValue(
            () =>
              Identifiers?.OtherIdentification?.find((r) => r.Type === "DTID")
                ?.Value
          ), //DTI
          instrumentStructureCategory: parseValue(
            () => Issuance?.InstrumentStructureCategory
          ),
          instrumentCategory: parseValue(() => Issuance?.InstrumentCategory),
          clientFreeText: parseValue(() => VenueData?.CBF?.ClientFreeText), //Client Free Text
          issueDate: parseValue(
            () =>
              Issuance?.IssueDate &&
              moment(Issuance?.IssueDate).format(DATE_FORMATS.DATE)
          ),
          globalType: parseValue(() => Issuance?.Form?.GlobalType),
          minTransferAmount: parseValue(
            () =>
              Issuance?.MinimumDenomination &&
              addSeparator(
                formatNumberWithDecimal(Issuance?.MinimumDenomination)
              )
          ),
          issuePrice: parseValue(
            () =>
              Issuance?.IssuePrice &&
              addSeparator(formatNumberWithDecimal(Issuance?.IssuePrice))
          ),
          interestFrom: parseValue(
            () =>
              InterestFeatures?.InterestFromDate &&
              moment(InterestFeatures?.InterestFromDate).format(
                DATE_FORMATS.DATE
              )
          ), // Interest Period From
          interestTo: parseValue(
            () =>
              InterestFeatures?.InterestToDate &&
              moment(InterestFeatures?.InterestToDate).format(DATE_FORMATS.DATE)
          ), //Interest Period To
          interestRate: parseValue(
            () =>
              InterestFeatures?.FixedInterestRate &&
              formatNumberWithDecimallimits(
                InterestFeatures?.FixedInterestRate,
                8
              )
          ),
          firstInterestPaymentDate: parseValue(
            () => InterestFeatures?.FirstPaymentDate
          ),
          interestPaymentFrequency: parseValue(
            () => InterestFeatures?.InterestPaymentFrequency
          ),
          interestCalcType: parseValue(() => InterestFeatures?.DayCountBasis),
          couponDevPeriodFirst: parseValue(
            () => InterestFeatures?.FirstFractionalPeriod
          ),
          couponDevPeriodLast: parseValue(
            () => InterestFeatures?.LastFractionalPeriod
          ),
          irs1042sRelevant: parseValue(() => Tax?.US?.Relevant871M?.toString()),
          us871mRelevant: parseValue(() =>
            Tax?.US?.Classification1042S?.toString()
          ),
          privatePlacement: parseValue(() =>
            Issuance?.PrivatePlacement?.toString()
          ),
          documentationUrl: parseValue(() => Documentation?.DocumentURL),
          settlementType: parseValue(
            () => RedemptionFeatures?.RedemptionOption
          ),
          exerciseStyle: parseValue(() => Issuance?.OptionStyle),
          cbfSecurityType: parseValue(() => Issuance?.InstrumentClassification),
          optionsType: parseValue(() => Issuance?.OptionType),
          applicableLaw: parseValue(() => Jurisdiction?.GoverningLaw),
          flagInterestRate: parseValue(() => InterestFeatures?.InterestType),
          issuerKvNoIssuer: parseValue(
            () =>
              Party?.find((r) =>
                r?.PartyRole?.includes("Issuer")
              )?.OtherPartyIdentification?.find(
                (r) => r?.AccountType === "CBF Account"
              )?.AccountNumber
          ),
          overallStatus: parseValue(
            () =>
              externalOverallStatus &&
              getExternalOverallStatusLabel(externalOverallStatus)
          ),
          processingStatus: parseValue(
            () =>
              externalProcessingStatus &&
              getExternalProcessStatusLabel(externalProcessingStatus)
          ),
          internalStatus: parseValue(
            () =>
              status?.InternalStatus &&
              getInternalStatusLabel(status?.InternalStatus)
          ),
          senderName: parseValue(
            () =>
              Party?.find((r) => r?.PartyRole?.includes("IssuerAgent"))?.Name
          ),
          issuerAgentTelephone: parseValue(
            () =>
              Party?.find((r) => r?.PartyRole?.includes("IssuerAgent"))
                ?.PhoneNumber
          ),
          issuerCbfNo: parseValue(
            () =>
              Party.find((r) =>
                r?.PartyRole?.includes("IssuerAgent")
              )?.OtherPartyIdentification?.find(
                (r) => r?.AccountType === "CBF Account"
              )?.AccountNumber
          ),
          payingAgentAccount: parseValue(
            () =>
              Party.find((r) =>
                r?.PartyRole?.includes("PrincipalPayingAgent")
              )?.OtherPartyIdentification?.find(
                (r) => r?.AccountType === "CBF Account"
              )?.AccountNumber
          ),
          issuerLei: parseValue(
            () => Party?.find((r) => r?.PartyRole?.includes("Issuer"))?.LEI
          ),
          redemptionValue: parseValue(() => Redemption?.[0]?.RedemptionPrice),
          paymentDate: parseValue(
            () =>
              Redemption?.[0]?.PaymentDate &&
              moment(Redemption[0]?.PaymentDate).format(DATE_FORMATS.DATE)
          ),
          initialNominalAmount: parseValue(
            () =>
              Issuance?.InitialNominalAmount &&
              addSeparator(
                formatNumberWithDecimal(Issuance?.InitialNominalAmount)
              )
          ),
          exerciseAgent: parseValue(
            () =>
              Party?.find((r) => r?.PartyRole?.includes("ExerciseAgent"))?.Name
          ),
          issuerShortCode: parseValue(
            () =>
              Party?.find((r) => r?.PartyRole?.includes("Issuer"))
                ?.Identification
          ),
          increase: Increase || [],
        };

        if (
          obj.underlyingName ||
          obj.underlyingIsin ||
          obj.underlyingType ||
          obj.underlyingCurrency ||
          obj.underlyingMultiplier ||
          obj.referenceSource
        ) {
          setUnderlingData(true);
        }

        obj["firstTradingDate"] = "";
        obj["lastTradingDate"] = "";
        obj["furtherExchange"] = "";
        obj["listingLocation"] = ""; //MIC
        obj["listingStatus"] = "";
        obj["minOrderLots"] = "";
        obj["minOrderValueAmount"] = "";
        obj["delistingReason"] = "";
        obj["otcChannelsActual"] = parseValue(() => Issuance?.OTCChannels);

        if (Venue && Venue?.Trading?.length > 0) {
          obj["firstTradingDate"] = parseValue(
            () =>
              Venue?.Trading[0]?.FirstTradingDate &&
              moment(Venue?.Trading[0]?.FirstTradingDate).format(
                DATE_FORMATS.DATE
              )
          ); //First Trading Date
          obj["lastTradingDate"] = parseValue(
            () =>
              Venue?.Trading[0]?.LastTradingDate &&
              moment(Venue?.Trading[0]?.LastTradingDate).format(
                DATE_FORMATS.DATE
              )
          );
        }

        if (Venue && Venue?.Listing?.length > 0) {
          //updating these as mentioned in 1.9.6 ppt to show only first values if multiples
          obj["firstListingDate"] = parseValue(
            () =>
              Venue?.Listing[0]?.FirstListingDate &&
              moment(Venue?.Listing[0]?.FirstListingDate).format(
                DATE_FORMATS.DATE
              )
          );
          obj["lastListingDate"] = parseValue(
            () =>
              Venue?.Listing[0]?.LastListingDate &&
              moment(Venue?.Listing[0]?.LastListingDate).format(
                DATE_FORMATS.DATE
              )
          );
          obj["listingLocation"] = parseValue(
            () => Venue?.Listing[0]?.Exchange?.MIC
          ); //MIC
        }
        setIsViewDetailsApiSuccess(true);
        setApiFlag(false);
        setIssuanceObj(obj);
        setAcceptRejectButtons();
      })
      .catch((err) => {
        console.log("error", err);
        setResponseText("Something went wrong");
        setShowPopUp(true);
        setflagPopUp("error");
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
      });
  };
  /*
    Set Accept Reject buttons visibility
  */
  const setAcceptRejectButtons = () => {
    let show = false;
    const issuanceObject = issuanceObj;
    if (issuanceObject) {
      show =
        (view === "details" &&
          sessionStorage.getItem("pending") &&
          ((JSON.parse(sessionStorage.getItem("pending")).tabName ===
            "issuanceBlotter" &&
            (issuanceObject.internalStatus === "Recon review pending" ||
              issuanceObject.internalStatus === "Recon review pending" ||
              issuanceObject.internalStatus === "RefData review pending" ||
              issuanceObject.internalStatus ===
                "Increase recon review pending")) ||
            (JSON.parse(sessionStorage.getItem("pending")).tabName ===
              "compliance" &&
              userState.userRoles.includes("Clearstream Compliance") &&
              (issuanceObject.internalStatus ===
                "Underlying Whitelist review pending" ||
                issuanceObject.internalStatus ===
                  "Issuer Greylist review pending" ||
                issuanceObject.internalStatus ===
                  "Increase Issuer Greylist review pending" ||
                issuanceObject.internalStatus ===
                  "Increase Underlying Whitelist review pending")))) ||
        (isRedemption && allowActionsForRedemptions);
    }
    setShowAcceptRejectButtons(show);
  };
  /*
   * This method will fetch all details of the issuance for CSV download
   */
  const getCSVDownload = () => {
    location.state == null
      ? (propsValue = props.issuanceObj)
      : (propsValue = location); //setting value for issuance details
    if (propsValue.state.contractType === "failed") {
      let obj = [
        { Issuer: propsValue.state.issuer },
        { ISIN: propsValue.state.isin },
        { "Overall Status": propsValue.state.status },
        { "Value Date": propsValue.state.valueDate },
        { "Payment Date": propsValue.state.payDate },
        { "Issuer CBF No.": propsValue.state.cbfAccount },
        { "Issuer KV No. Issuer": propsValue.state.payingAgent },
        { "Template Type": propsValue.state.templateType },
        { "Lifecycle Status": propsValue.state.lifecycleStatus },
      ];
      var csv = [];
      csv = obj.map((elem) => {
        let keyName = Object.keys(elem)[0];

        let value = elem.keyName === null ? "" : elem[keyName];

        return keyName + "," + value;
      });
      csv.unshift(["Name", "Value"].join(","));
      csv = csv.join("\r\n");
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", propsValue.state.issuer + ".csv");
      document.body.appendChild(link);
      link.click();
      setResponseText("CSV dowloaded successfully");
      setflagPopUp("success");
      setShowPopUp(true);
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 10000);
    } else {
      IssuancesQueriesApiClient.getIssuanceFileByISIN(propsValue.state.isin, {
        format: "csv",
      })
        .then(({ response }) => {
          if (response.status === 200) {
            var csvData = new Blob([response.data], {
              type: "text/csv;charset=utf-8;",
            });
            var csvURL = window.URL.createObjectURL(csvData);
            var link = document.createElement("a");
            link.href = csvURL;
            link.setAttribute("download", propsValue.state.issuer + ".csv");
            link.click();
            setResponseText("CSV dowloaded successfully");
            setflagPopUp("success");
            setShowPopUp(true);
            setTimeout(() => {
              setShowPopUp(false);
              setResponseText("");
            }, 10000);
          } else {
            setResponseText("Unable to generate CSV.Please try later");
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setResponseText("Unable to generate CSV.Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  /*
   * Handle accordion's collapse/expand
   */
  const handleChange = (panel) => {
    expandState[panel] = !expandState[panel];
    let tempPanel = {};
    let allExpanded = true;
    for (let i = 1; i <= 8; i++) {
      tempPanel[`panel${i}`] = expandState[`panel${i}`];
      allExpanded = allExpanded && expandState[`panel${i}`];
    }
    setExpandState(tempPanel);
    setExpandAll(!allExpanded);
  };

  const handleChangeButton = (panel) => {
    let temp = {};
    for (let i = 1; i <= 8; i++) {
      temp[`panel${i}`] = panel;
    }
    setExpandState(temp);
    setExpandAll(!panel);
  };

  /*
   * This method will copy the process log data/ error log data
   */
  const copyToClipboard = () => {
    if (view === "process") {
      let json = processLogData;
      let fields = Object.keys(processLogData[0]);
      let csv = json.map((row) => {
        return JSON.stringify(fields.map((key) => row[key]).join(","));
      });
      csv.unshift(fields.join(",")); // adds fieldNames at beginning
      csv = csv.join(",\r\n"); // every element is joined in nextlines as  separate row
      navigator.clipboard.writeText(csv);
      setResponseText("Copied Successfully!");
      setShowPopUp(true);
      setflagPopUp("success");
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 10000);
    } else if (view === "error") {
      let json = errorLogData;
      let fields = Object.keys(errorLogData[0]);
      let replacer = (key, value) => {
        return value === null ? "" : value;
      };
      let csv = [];
      let detailsFields = [];
      json.map((row) => {
        let logData = fields
          .map((fieldName) => {
            if (fieldName !== "errorDetails") {
              detailsFields?.includes(fieldName) !== true &&
                detailsFields.push(fieldName);
              return JSON.stringify(
                (fieldName === "errorDateTimePublisher" ||
                fieldName === "errorDateTimeDi"
                  ? convertDateTime(row[fieldName], DATE_FORMATS.DATE_AND_TIME)
                  : row[fieldName]
                  ? row[fieldName]
                  : ""
                ).replace(/([a-z])([A-Z])/g, "$1 $2"),
                replacer
              );
            } else {
              let detailsCsv = Object.keys(row["errorDetails"])
                .map((detailsKey) => {
                  detailsFields?.includes(detailsKey) !== true &&
                    detailsFields.push(detailsKey);
                  if (detailsKey === "errorAdditionalInfo") {
                    let infoData = "";
                    row["errorDetails"][detailsKey].forEach((info) => {
                      infoData === ""
                        ? (infoData += info)
                        : (infoData += "," + info);
                    });
                    return JSON.stringify(infoData, replacer);
                  } else {
                    return JSON.stringify(
                      row.errorStatus === "UnknownCode" &&
                        detailsKey === "errorMessage"
                        ? "Unknown Error: " + row["errorDetails"][detailsKey]
                        : row["errorDetails"][detailsKey],
                      replacer
                    );
                  }
                })
                .join(",");
              return detailsCsv;
            }
          })
          .join(",");
        csv.push(logData);
        return csv;
      });
      csv.unshift(detailsFields.join(","));
      csv = csv.join("\r\n");
      navigator.clipboard.writeText(csv);
      setResponseText("Copied Successfully!");
      setShowPopUp(true);
      setflagPopUp("success");
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 10000);
    } else if (view === "initialXML") {
      navigator.clipboard.writeText(initialXMLData);
      setResponseText("Copied Successfully!");
      setShowPopUp(true);
      setflagPopUp("success");
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 10000);
    } else {
      let json = combinedLogData;
      let fields = Object.keys(json[0]); //fetching keys of table log
      let nestedKeys = [
        "errorDateTimePublisher",
        "errorDateTimeDi",
        "errorStatus",
        "errorCode",
        "errorMessage",
        "errorAdditionalInfo",
        "errorPublisher",
        "errorDTI",
        "processDateTime",
        "processAttributeName",
        "processAttributeValue",
        "processDTI",
        "Timestamp",
        "party",
        "message",
        "userId",
        "isin",
        "auditDTI",
      ];
      let replacer = (key, value) => {
        return value === null || value === undefined ? "" : value;
      }; //setting value as empty if it is null or undefined
      let csv = json.map((row) => {
        //mapping through each row data
        return fields
          .map((fieldName) => {
            if (fieldName === "contentMap") {
              return nestedKeys.map((keys) => {
                //checking if nested keys contains value
                let temp = row[fieldName];
                return JSON.stringify(temp[keys], replacer);
              });
            } else {
              return JSON.stringify(row[fieldName], replacer);
            }
          })
          .join(",");
      });
      let tableKeys = [],
        fieldNames = []; // formatting the headers
      tableKeys.push(...fields);
      tableKeys.push(...nestedKeys);
      tableKeys.map((keys) => {
        //formatting headers of table
        if (keys === "dateTime") {
          fieldNames.push("DateTime");
        } else if (keys === "logIndicator") {
          fieldNames.push("LogIndicator");
        } else if (keys === "dti") {
          fieldNames.push("DTI");
        } else if (keys === "isin") {
          fieldNames.push("ISIN");
        } else if (keys === "content") {
          fieldNames.push("Content");
        } else if (keys === "userId") {
          fieldNames.push("UserId");
        } else if (keys === "auditDTI") {
          fieldNames.push("DTI");
        } else if (keys === "errorDTI") {
          fieldNames.push("DTI");
        } else if (keys === "processDTI") {
          fieldNames.push("DTI");
        } else if (keys === "contentMap") {
        } else {
          fieldNames.push(keys);
        }
        return fieldNames;
      });
      csv.unshift(fieldNames.join(",")); // adds fieldNames at beginning
      csv = csv.join("\r\n"); // every element is joined in nextlines as  separate row
      location.state == null
        ? (csv = "ISIN : " + props.issuanceObj.state.isin + "\r\n" + csv)
        : (csv = "ISIN : " + location.state.isin + "\r\n" + csv); //appending isin to copy to clipboard data
      navigator.clipboard.writeText(csv);
      setResponseText("Copied Successfully!");
      setShowPopUp(true);
      setflagPopUp("success");
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 10000);
    }
  };

  /*
   * This method will handle the action of accept/reject for pending issuance under issuance blotter
   */
  const managePending = async (action) => {
    try {
      await IssuancesInternalApiClient.processManualInteraction({
        isin: issuanceObj.isin,
        internalStatus: getInternalStatusValue(issuanceObj.internalStatus),
        resultType: action,
      });
      setAcceptRejectEnabled(false);
      setflagPopUp("success");
      setResponseText(`${issuanceObj.isin} updated successfully`);
      setShowPopUp(true);
    } catch (e) {
      setflagPopUp("error");
      setResponseText(`${e}`);
      setShowPopUp(true);
    }
  };

  const approveRejectRedemptionReview = (action) => {
    const isin = location.state.isin;
    RedemptionsInternalApiClient.updateRedemptionStatusByIsin(isin, action)
      .then(({ response }) => {
        setResponseText("Success " + response.data?.message);
        setShowPopUp(true);
        setflagPopUp("success");
        setTimeout(() => {
          setShowPopUp(false);
          setResponseText("");
        }, 10000);
        getIssuance();
      })
      .catch((error) => {
        setResponseText("Unable to proceed. Please try later.");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  /*
   * This method will open the warning dialog box for accepting/rejecting pending issuance under issuance blotter
   */
  const openDialog = (para) => {
    if (para === "accept") {
      setAcceptOrRejectIssuance(true);
      setButtonClicked("accept");
    } else if (para === "reject") {
      setAcceptOrRejectIssuance(true);
      setButtonClicked("reject");
    }
  };

  /*
   * This method will open the pdf for corresponsing issuance in another tab
   */
  const getPdfData = async () => {
    return await IssuancesQueriesApiClient.getPdfUsingGET(TCDocId);
  };
  const showPDF = async () => {
    await getPdfData()
      .then(({ response }) => {
        if (response.status === 200) {
          const file = new Blob([response.data], { type: "application/pdf" });
          var fileURLvalue = URL.createObjectURL(file);
          setUrlFlag(true);
          setFileUrl(fileURLvalue);
        }
      })
      .catch((error) => {
        setResponseText("Unable to generate pdf.Please Try later");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  const openPdfWindow = () => {
    window.open(fileUrl);
    setUrlFlag(false);
  };
  /*
   * This method will fetch the processing log for corresponsing issuance
   */
  const getProcesslog = () => {
    IssuancesLogsInternalApiClient.getProcessingLogDetailsUsingGET(
      issuanceObj.isin
    )
      .then(({ response }) => {
        if (response.status === 200) {
          const data = [];
          response?.data?.forEach((row) => {
            let obj = {
              processDateTime: convertDateTime(
                row.processDateTime,
                DATE_FORMATS.DATE_AND_TIME
              ),
              overallStatus: getExternalOverallStatusLabel(
                row.externalOverallStatus
              ),
              processingStatus: getExternalProcessStatusLabel(
                row.externalProcessingStatus
              ),
              internalProcessingStatus: row.internalStatus
                ? getInternalStatusLabel(row.internalStatus)
                : "",
            };
            data.push(obj);
          });
          setProcessLogData(data);
          setView("process");
          expandState["panel7"] === false && handleChange("panel7");
        } else {
          setResponseText("Unable to generate process log.Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to generate process log.Please try later");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  const getInitialXML = () => {
    TransactionStoreApiClient.getIssuanceXmlByIsinAndXmlFileType(
      issuanceObj.isin
    )
      .then(({ response }) => {
        if (response.status === 200) {
          var xmlData = new Blob([response.data]);
          var xmlURL = window.URL.createObjectURL(xmlData);
          var link = document.createElement("a");
          link.href = xmlURL;
          link.setAttribute("download", issuanceObj.isin + ".xml");
          link.click();
          setResponseText("XML dowloaded successfully");
          setflagPopUp("success");
          setShowPopUp(true);
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
        } else {
          setResponseText("Unable to dowload XML.Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText(error.toString());
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  /*
   * This method will fetch the error log for corresponsing issuance
   */
  const getErrorLog = () => {
    IssuancesLogsInternalApiClient.getErrorLogDetailsForIsinUsingGET(
      issuanceObj.isin
    )
      .then(({ response }) => {
        if (response.status === 200 && Array.isArray(response.data)) {
          let logEntry = response.data.reverse();
          setErrorLogData(logEntry);
          let errorArray = [];
          logEntry.forEach((elem) => {
            let entryObj = {
              errorDateTimeDi: elem.errorDateTimeDi,
              errorPublisher: elem.errorPublisher
                ? getPublisherLabel(elem.errorPublisher)
                : "",
              errorDetails: [
                {
                  errorMessage: elem.errorDetails.errorMessage,
                  errorStatus: elem.errorStatus
                    ? isClearstream
                      ? getInternalStatusLabel(elem.errorStatus)
                      : getExternalProcessStatusLabel(elem.errorStatus)
                    : "",
                  errorCode: elem.errorDetails.errorCode,
                  errorAdditionalInfo: elem.errorDetails.errorAdditionalInfo,
                  optionalDti: elem.optionalDti,
                },
              ],
            };
            if (errorArray.length === 0) {
              errorArray.push(entryObj);
            } else {
              //eslint-disable-next-line array-callback-return
              const index = errorArray.findIndex((element) => {
                if (entryObj.errorDateTimeDi === element.errorDateTimeDi) {
                  return true;
                }
              });
              if (index !== -1) {
                errorArray[index].errorDetails.push(entryObj.errorDetails[0]);
              } else {
                errorArray.push(entryObj);
              }
            }
          });

          setErrorLogEntries(errorArray);
          setView("error");
        } else {
          setResponseText("Unable to generate error log. Please try later.");
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to generate error log. Please try later.");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const getCombinedLog = () => {
    let CombinedLogIsin;
    location.state == null
      ? (CombinedLogIsin = props.issuanceObj.state.isin)
      : (CombinedLogIsin = location.state.isin); //setting value for issuance details

    IssuancesLogsInternalApiClient.getCombinedLogForIsinUsingGET(
      CombinedLogIsin
    )
      .then(({ response }) => {
        if (response.status === 200) {
          if (response.data.length) {
            const data = [];
            response?.data?.forEach((row) => {
              const overAllStatus = row.externalOverallStatus
                ? getExternalOverallStatusLabel(row.externalOverallStatus)
                : "";
              const processingStatus = row.externalProcessingStatus
                ? getExternalProcessStatusLabel(row.externalProcessingStatus)
                : "";
              const internalProcessingStatus = row.internalStatus
                ? getInternalStatusLabel(row.internalStatus)
                : "";
              let obj = {
                userId: row.userId,
                dateTime: moment(row.dateTime).isValid()
                  ? convertDateTime(row.dateTime, DATE_FORMATS.DATE_AND_TIME)
                  : "",
                dti: row.dti,
                isin: row.isin,
                logIndicator: row.logIndicator,
                internalStatus: internalProcessingStatus,
                overAllStatus: overAllStatus,
                processingStatus: processingStatus,
                content:
                  row.errorMessage &&
                  row.affectedAttributes &&
                  `${row.errorMessage}; ${row.affectedAttributes}`,
              };
              data.push(obj);
            });
            setCombinedLogData(data);
            setView("combined"); //setting view to combined log
          }
        } else {
          setResponseText("Unable to generate combined log. Please try later.");
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to generate combined log. Please try later.");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  const user = sessionStorage.getItem("user");

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  return (
    <React.Fragment>
      <StyledContainer id="det_styCont_responsepopup">
        <div
          className="popup"
          id="det_div_responsepopup"
          data-testid="issuance_view"
          style={{ zIndex: showPopUp ? 4 : 2 }}
        >
          {responseText && showPopUp && (
            <div>
              {flagPopUp !== "error" ? (
                <SuccessPopup
                  id="det_div_responsepopup_success"
                  successPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              ) : (
                <ErrorPopup
                  id="det_div_responsepopup_error"
                  errorPopupText={responseText}
                  popupVisibility={showPopUp}
                  handleClose={() => closeErrorSuccessPopup()}
                />
              )}
            </div>
          )}
        </div>
        <StyledHeader id="det_styhead_responseype">
          {showBackNavigationButton && <BackButton />}
          {view === "details" && (
            <Typography variant="h2" id="det_typo_detailson">
              Details on Instrument {issuanceObj.insName} (ISIN{" "}
              {issuanceObj.isin})
            </Typography>
          )}
          {view === "process" && (
            <Typography variant="h2" id="det_typo_processof">
              Processing log of Issuance {issuanceObj.insName} (ISIN{" "}
              {issuanceObj.isin}){" "}
            </Typography>
          )}
          {view === "initialXML" && (
            <Typography variant="h2" id="det_typo_processof">
              Initial XML of Issuance {issuanceObj.insName} (ISIN{" "}
              {issuanceObj.isin}){" "}
            </Typography>
          )}
          {view === "error" && (
            <Typography variant="h2" id="det_typo_errorof">
              Error Event log of Issuance {issuanceObj.insName} (ISIN{" "}
              {issuanceObj.isin}){" "}
            </Typography>
          )}
          {view === "combined" && (
            <Typography variant="h2" id="det_typo_errorof">
              ISIN History log of Issuance {issuanceObj.insName} (ISIN{" "}
              {location.state == null
                ? props.issuanceObj.state.isin
                : location.state.isin}
              ){" "}
            </Typography>
          )}
          <Grid container id="det_grid_container">
            <Grid item xs={12} id="det_grid_item">
              {view === "details" && (
                <Typography
                  variant="subtitle2"
                  id="det_typo_isindet"
                  className="item"
                >
                  ISIN-{issuanceObj.isin}
                </Typography>
              )}
              {view === "process" && (
                <Breadcrumbs
                  id="det_breadcrumbs_isin"
                  separator={
                    <NavigateNextIcon fontSize="small" className="padding2" />
                  }
                  aria-label="breadcrumb"
                >
                  <Link
                    color="inherit"
                    onClick={() => setView("details")}
                    id="det_link_setview"
                  >
                    <Typography
                      variant="subtitle2"
                      className="mainItem"
                      id="det_typo_issObjisin"
                    >
                      ISIN-{issuanceObj.isin}
                    </Typography>
                  </Link>
                  <Typography
                    variant="subtitle2"
                    className="item"
                    id="det_typo_processingLog"
                  >
                    Processing Log
                  </Typography>
                </Breadcrumbs>
              )}
              {view === "initialXML" && (
                <Breadcrumbs
                  id="det_breadcrumbs_isin"
                  separator={
                    <NavigateNextIcon fontSize="small" className="padding2" />
                  }
                  aria-label="breadcrumb"
                >
                  <Link
                    color="inherit"
                    onClick={() => setView("details")}
                    id="det_link_setview"
                  >
                    <Typography
                      variant="subtitle2"
                      className="mainItem"
                      id="det_typo_issObjisin"
                    >
                      ISIN-{issuanceObj.isin}
                    </Typography>
                  </Link>
                  <Typography
                    variant="subtitle2"
                    className="item"
                    id="det_typo_processingLog"
                  >
                    Initial XML
                  </Typography>
                </Breadcrumbs>
              )}
              {view === "error" && (
                <Breadcrumbs
                  separator={
                    <NavigateNextIcon fontSize="small" className="padding2" />
                  }
                  aria-label="breadcrumb"
                  id="det_breadcrumbs_errorlog"
                >
                  <Link
                    color="inherit"
                    onClick={() => setView("details")}
                    id="det_link_setviewerror"
                  >
                    <Typography
                      variant="subtitle2"
                      className="mainItem"
                      id="det_typo_issObjerror"
                    >
                      ISIN-{issuanceObj.isin}
                    </Typography>
                  </Link>
                  <Typography
                    variant="subtitle2"
                    className="item"
                    id="det_typo_issObjerrorLog"
                  >
                    Error Log
                  </Typography>
                </Breadcrumbs>
              )}
              {view === "combined" && (
                <Breadcrumbs
                  separator={
                    <NavigateNextIcon fontSize="small" className="padding2" />
                  }
                  aria-label="breadcrumb"
                  id="det_breadcrumbs_combined"
                >
                  <Link
                    color="inherit"
                    onClick={() => {
                      apiFlag && getIssuance();
                      setView("details");
                    }}
                    id="det_link_setviewcombined"
                  >
                    <Typography
                      variant="subtitle2"
                      className="mainItem"
                      id="det_typo_issObjcombined"
                    >
                      ISIN-
                      {location.state == null
                        ? props.issuanceObj.state.isin
                        : location.state.isin}
                    </Typography>
                  </Link>
                  <Typography
                    variant="subtitle2"
                    className="item"
                    id="det_typo_issObjcombined"
                  >
                    History
                  </Typography>
                </Breadcrumbs>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              className="paddingDetails"
              id="det_grid_processStatus"
            >
              {showAcceptRejectButtons && (
                <Space>
                  <Button
                    variant="contained"
                    id="det_button_openDialogA"
                    data-testid="btn_accept"
                    className="buttonStyle"
                    color="primary"
                    onClick={() => {
                      openDialog("accept");
                    }}
                    disabled={!acceptRejectEnabled}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    id="det_button_openDialogR"
                    data-testid="btn_reject"
                    className="buttonStyle"
                    onClick={() => {
                      openDialog("reject");
                    }}
                    disabled={!acceptRejectEnabled}
                  >
                    Reject
                  </Button>
                </Space>
              )}
            </Grid>
            <Grid
              item
              xs={9}
              align="right"
              className="paddingDetails"
              id="det_grid_logs"
            >
              {view === "details" && (
                <Space>
                  {location.state == null && (
                    <HtmlTooltip
                      id="iss_htmltooltip_viewDetails3"
                      title={
                        <React.Fragment>
                          <Typography variant="h5" id="iss_typo_refresh">
                            Refresh
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img
                        src={refresh}
                        alt="details"
                        onClick={getIssuance}
                        className="greyIcon"
                        id="iss_icon_refreshTable"
                      />
                    </HtmlTooltip>
                  )}
                  <Button
                    variant="outlined"
                    className="buttonStyle"
                    id="det_but_getCombinedlog"
                    color="primary"
                    onClick={() => {
                      getCombinedLog();
                    }}
                    disabled={
                      !isViewDetailsApiSuccess ||
                      !userState.userRoleRight.includes("View_Combined_Log")
                    }
                    data-testid="btn_CombinedLog"
                  >
                    History
                  </Button>
                  <Button
                    variant="outlined"
                    className="buttonStyle"
                    id="det_but_geterrorlog"
                    color="primary"
                    disabled={
                      !isViewDetailsApiSuccess ||
                      !userState.userRoleRight.includes("View_Error_Log")
                    }
                    onClick={() => {
                      getErrorLog();
                    }}
                    data-testid="btn_ErrorLog"
                  >
                    Error Log
                  </Button>
                  <Button
                    variant="outlined"
                    id="det_but_getProcessinglog"
                    data-testid="btn_ProcessLog"
                    color="primary"
                    onClick={() => {
                      getProcesslog();
                    }}
                    disabled={!isViewDetailsApiSuccess}
                    className="processlog buttonStyle"
                  >
                    Processing Log
                  </Button>
                  {isClearstream && (
                    <Button
                      variant="outlined"
                      id="det_but_getProcessinglog"
                      data-testid="btn_ProcessLog"
                      color="primary"
                      onClick={() => {
                        getInitialXML();
                      }}
                      disabled={!isViewDetailsApiSuccess}
                      className="processlog buttonStyle"
                    >
                      XML
                    </Button>
                  )}
                  <HtmlTooltip
                    id="det_htmltooltil_viewCsv"
                    title={
                      <React.Fragment>
                        <Typography variant="h5" id="det_typo_viewCsv">
                          View CSV
                        </Typography>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <img
                      src={downloadCSVActiveIcon}
                      id="det_img_getCsv"
                      alt="details"
                      data-testid="btn_CSV"
                      onClick={() => getCSVDownload()}
                      className="icons"
                    />
                  </HtmlTooltip>

                  {issuanceObj.pdf === "" ||
                  !userState.userRoleRight.includes("View_Pdf") ? (
                    <HtmlTooltip
                      id="det_htmltooltil_viewPdf"
                      title={
                        <React.Fragment>
                          <Typography variant="h5" id="det_typo_viewPdf">
                            View T&C
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img src={ViewPdfGreyIcon} alt="pdf" id="det_img_pdf" />
                    </HtmlTooltip>
                  ) : (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography variant="h5" id="det_typo_viewPdf">
                            View T&C{" "}
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img
                        src={viewPdfActiveIcon}
                        alt="details"
                        id="det_img_showPdf"
                        className="showpdf"
                        onClick={() => showPDF()}
                        data-testid="btn_pdf"
                      />
                    </HtmlTooltip>
                  )}

                  {expandAll ? (
                    <Button
                      onClick={() => handleChangeButton(true)}
                      id="det_btn_handleChangetrue"
                    >
                      <Typography
                        variant="h6"
                        className="excolAll"
                        data-testid="btn_expand"
                        id="det_typo_expandAll"
                      >
                        Expand All
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleChangeButton(false)}
                      data-testid="btn_collapse"
                      id="det_btn_handleChangefalse"
                    >
                      <Typography
                        variant="h6"
                        className="excolAll"
                        id="det_typo_collapseAll"
                      >
                        Collapse All
                      </Typography>
                    </Button>
                  )}
                </Space>
              )}
              {(view === "initialXML" ||
                view === "process" ||
                view === "error" ||
                view === "combined") && (
                <div className="padding2" id="det_div_copy">
                  <Space>
                    <HtmlTooltip
                      id="det_tooltip_copy"
                      title={
                        <React.Fragment>
                          <Typography variant="h5" id="det_typo_copy">
                            Copy to Clipboard
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img
                        src={copytoClipboardIcon}
                        alt="clipboard"
                        id="det_img_copy"
                        className="icons"
                        data-testid="btn_copyClipboard"
                        onClick={() => copyToClipboard()}
                      />
                    </HtmlTooltip>
                    {view === "initialXML" && (
                      <HtmlTooltip
                        id="det_tooltip_download"
                        title={
                          <React.Fragment>
                            <Typography variant="h5" id="det_typo_download">
                              Download
                            </Typography>
                          </React.Fragment>
                        }
                        placement="top"
                      >
                        <a
                          href={window.URL.createObjectURL(
                            new Blob([initialXMLData])
                          )}
                          download="initialXml.xml"
                        >
                          <img
                            src={downloadXMLIcon}
                            alt="download XML"
                            id="det_img_download"
                            className="icons"
                            data-testid="btn_downloadXML"
                          />
                        </a>
                      </HtmlTooltip>
                    )}
                    <HtmlTooltip
                      id="det_tooltip_close"
                      title={
                        <React.Fragment>
                          <Typography variant="h5" id="det_typo_close">
                            Close
                          </Typography>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <img
                        src={closeIcon}
                        alt="close"
                        id="det_img_close"
                        onClick={() => {
                          view === "combined" && apiFlag && getIssuance();
                          setView("details");
                        }}
                        className="icons"
                      />
                    </HtmlTooltip>
                  </Space>
                </div>
              )}
            </Grid>
          </Grid>
        </StyledHeader>
        {view === "details" && (
          <StyledBody id="det_clear_styledBody">
            {user === "Clearstream" &&
              (!isDCM(
                issuanceObj.instrumentStructureCategory,
                issuanceObj.instrumentCategory,
                issuanceObj.eusipaID
              ) ? (
                <EligibilityCheckRSP
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
              ) : (
                <EligibilityCheckDCM
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
              ))}
            {!isDCM(
              issuanceObj.instrumentStructureCategory,
              issuanceObj.instrumentCategory,
              issuanceObj.eusipaID
            ) ? (
              <React.Fragment>
                <IdentifierRSP
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                <StatusListingRSP
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  user={user}
                  issuanceObj={issuanceObj}
                />
                <CalendarRSP
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                <DetailInstrumentInfoRSP
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                <AccountSenderDetailsRSP
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                {UnderlingData && (
                  <Underlying
                    expandState={expandState}
                    handleChange={handleChange}
                    isDesktop={isDesktop}
                    issuanceObj={issuanceObj}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <IdentifierDCM
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                <StatusListingDCM
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  user={user}
                  issuanceObj={issuanceObj}
                />
                <CalendarDCM
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                <DetailInstrumentInfoDCM
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                <AccountSenderDetailsDCM
                  expandState={expandState}
                  handleChange={handleChange}
                  isDesktop={isDesktop}
                  issuanceObj={issuanceObj}
                />
                {UnderlingData && (
                  <UnderlyingDCM
                    expandState={expandState}
                    handleChange={handleChange}
                    isDesktop={isDesktop}
                    issuanceObj={issuanceObj}
                  />
                )}
              </React.Fragment>
            )}
            {showIncreaseSection && (
              <Accordion
                className="accordianStyle"
                id="cp_under_accCont8"
                expanded={expandState["panel8"]}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8a-content"
                  id="panel8a-header"
                  onClick={() => handleChange("panel8")}
                >
                  <Typography variant="h3" id="cp_under_typo7">
                    Increase
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="accordionContent"
                  id="cp_under_acc1"
                >
                  <SimpleTable
                    columns={increseTableColumns}
                    data={increaseTableData}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </StyledBody>
        )}
        {view === "process" && (
          <ProcessLog
            processLogData={processLogData}
            showInternalStatus={isClearstream}
          />
        )}
        {view === "initialXML" && <ViewXML initialXMLData={initialXMLData} />}
        {view === "error" && <ErrorLog errorLogData={errorLogEntries} />}
        {view === "combined" && (
          <CombinedLog
            combinedLogData={combinedLogData}
            isClearstream={isClearstream}
          />
        )}
      </StyledContainer>
      <StyledDialog
        className="acceptRejectDialog"
        open={acceptOrRejectIssuance}
        data-testid="blotter_dialog"
        onClose={() => {
          setAcceptOrRejectIssuance(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"xs"}
        id="det_styleDialog_acceptIss"
      >
        <DialogContent id="det_dialogContent">
          <DialogContentText id="det_dialogContentText">
            {buttonClicked === "accept" ? (
              <Typography
                className="acceptRejectButton"
                id="det_acceptButton"
                data-testid="accept_text"
              >
                Do you want to accept the following issuance?
              </Typography>
            ) : (
              <Typography
                className="acceptRejectButton"
                id="det_RejectButton"
                data-testid="reject_text"
              >
                Do you want to reject the following issuance?
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="accept" id="det_action_acceptButton">
          {buttonClicked === "accept" ? (
            <Button
              id="det_action_acceptButton_approve"
              variant="contained"
              color="primary"
              onClick={() => {
                isRedemption
                  ? approveRejectRedemptionReview("APPROVED")
                  : managePending("Success");
                setAcceptOrRejectIssuance(false);
              }}
              data-testid="btn_yes"
            >
              Yes
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              id="det_action_acceptButton_reject"
              onClick={() => {
                isRedemption
                  ? approveRejectRedemptionReview("REJECTED")
                  : managePending("Fail");
                setAcceptOrRejectIssuance(false);
              }}
              data-testid="btn_yes_reject"
            >
              Yes
            </Button>
          )}
          <Button
            id="det_action_setAcceptOrRejectIssuance"
            onClick={() => {
              setAcceptOrRejectIssuance(false);
            }}
            data-testid="btn_no_reject"
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </StyledDialog>
    </React.Fragment>
  );
}

export default IssuanceDetails;
