/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OtherIdentification from './OtherIdentification';

/**
 * The Identifiers model module.
 * @module model/Identifiers
 * @version 1.0.3
 */
class Identifiers {
    /**
     * Constructs a new <code>Identifiers</code>.
     * @alias module:model/Identifiers
     */
    constructor() { 
        
        Identifiers.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Identifiers</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Identifiers} obj Optional instance to populate.
     * @return {module:model/Identifiers} The populated <code>Identifiers</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Identifiers();

            if (data.hasOwnProperty('ISIN')) {
                obj['ISIN'] = ApiClient.convertToType(data['ISIN'], 'String');
            }
            if (data.hasOwnProperty('OtherIdentification')) {
                obj['OtherIdentification'] = ApiClient.convertToType(data['OtherIdentification'], [OtherIdentification]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Identifiers</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Identifiers</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['ISIN'] && !(typeof data['ISIN'] === 'string' || data['ISIN'] instanceof String)) {
            throw new Error("Expected the field `ISIN` to be a primitive type in the JSON string but got " + data['ISIN']);
        }
        if (data['OtherIdentification']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['OtherIdentification'])) {
                throw new Error("Expected the field `OtherIdentification` to be an array in the JSON data but got " + data['OtherIdentification']);
            }
            // validate the optional field `OtherIdentification` (array)
            for (const item of data['OtherIdentification']) {
                OtherIdentification.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * A 12 digit security identification code (ISO 6166) comprised of a 2digit ISO 3166 country code, a 9 digit national number (e.g. cusip) and a 1digit 'check number'. Conditional based on issuer requirements.[88]
 * @member {String} ISIN
 */
Identifiers.prototype['ISIN'] = undefined;

/**
 * Model: OtherIdentification
 * @member {Array.<module:model/OtherIdentification>} OtherIdentification
 */
Identifiers.prototype['OtherIdentification'] = undefined;






export default Identifiers;

