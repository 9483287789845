/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import IssuerData from '../model/IssuerData';
import PayingAgentData from '../model/PayingAgentData';

/**
* PartiesQueries service.
* @module api/PartiesQueriesApi
* @version 1.0.3
*/
export default class PartiesQueriesApi {

    /**
    * Constructs a new PartiesQueriesApi. 
    * @alias module:api/PartiesQueriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getIssuersAgentNames operation.
     * @callback module:api/PartiesQueriesApi~getIssuersAgentNamesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuerData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the available issuers agents names
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pagesize number of elements per page
     * @param {module:api/PartiesQueriesApi~getIssuersAgentNamesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuerData}
     */
    getIssuersAgentNames(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuerData;
      return this.apiClient.callApi(
        '/di/v1/parties/issuer-agents/names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getIssuersNamesV2 operation.
     * @callback module:api/PartiesQueriesApi~getIssuersNamesV2Callback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuerData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the available issuers names
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pagesize number of elements per page
     * @param {module:api/PartiesQueriesApi~getIssuersNamesV2Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuerData}
     */
    getIssuersNamesV2(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuerData;
      return this.apiClient.callApi(
        '/di/v1/parties/issuers/names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPayingAgentNames operation.
     * @callback module:api/PartiesQueriesApi~getPayingAgentNamesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PayingAgentData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the available paying agent names.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pagesize number of elements per page
     * @param {module:api/PartiesQueriesApi~getPayingAgentNamesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PayingAgentData}
     */
    getPayingAgentNames(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PayingAgentData;
      return this.apiClient.callApi(
        '/di/v1/parties/paying-agents/names', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPayingAgentNumbers operation.
     * @callback module:api/PartiesQueriesApi~getPayingAgentNumbersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/IssuerData} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the available paying agent numbers
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.pagesize number of elements per page
     * @param {module:api/PartiesQueriesApi~getPayingAgentNumbersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IssuerData}
     */
    getPayingAgentNumbers(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'pagesize': opts['pagesize']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = IssuerData;
      return this.apiClient.callApi(
        '/di/v1/parties/paying-agents/account-numbers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
