/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PostalAddress model module.
 * @module model/PostalAddress
 * @version 1.0.3
 */
class PostalAddress {
    /**
     * Constructs a new <code>PostalAddress</code>.
     * @alias module:model/PostalAddress
     */
    constructor() { 
        
        PostalAddress.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PostalAddress</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PostalAddress} obj Optional instance to populate.
     * @return {module:model/PostalAddress} The populated <code>PostalAddress</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PostalAddress();

            if (data.hasOwnProperty('Department')) {
                obj['Department'] = ApiClient.convertToType(data['Department'], 'String');
            }
            if (data.hasOwnProperty('PostCode')) {
                obj['PostCode'] = ApiClient.convertToType(data['PostCode'], 'String');
            }
            if (data.hasOwnProperty('TownName')) {
                obj['TownName'] = ApiClient.convertToType(data['TownName'], 'String');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PostalAddress</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PostalAddress</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Department'] && !(typeof data['Department'] === 'string' || data['Department'] instanceof String)) {
            throw new Error("Expected the field `Department` to be a primitive type in the JSON string but got " + data['Department']);
        }
        // ensure the json data is a string
        if (data['PostCode'] && !(typeof data['PostCode'] === 'string' || data['PostCode'] instanceof String)) {
            throw new Error("Expected the field `PostCode` to be a primitive type in the JSON string but got " + data['PostCode']);
        }
        // ensure the json data is a string
        if (data['TownName'] && !(typeof data['TownName'] === 'string' || data['TownName'] instanceof String)) {
            throw new Error("Expected the field `TownName` to be a primitive type in the JSON string but got " + data['TownName']);
        }
        // ensure the json data is a string
        if (data['Country'] && !(typeof data['Country'] === 'string' || data['Country'] instanceof String)) {
            throw new Error("Expected the field `Country` to be a primitive type in the JSON string but got " + data['Country']);
        }

        return true;
    }


}



/**
 * The issuing department[518]
 * @member {String} Department
 */
PostalAddress.prototype['Department'] = undefined;

/**
 * ZIP Code of the issuer[519]
 * @member {String} PostCode
 */
PostalAddress.prototype['PostCode'] = undefined;

/**
 * City of the issuer[520]
 * @member {String} TownName
 */
PostalAddress.prototype['TownName'] = undefined;

/**
 * ISO country code (ISO 3166Alpha3)[521]
 * @member {String} Country
 */
PostalAddress.prototype['Country'] = undefined;






export default PostalAddress;

