import {
  ComplianceApi,
  AccountsInternalApi,
  IssuancesLogsApi,
  IssuancesQueriesApi,
  IssuancesCommandsApi,
  PendingApi,
  RedemptionsApi,
  RedemptionsInternalApi,
  AuthenticationApi,
  IssuersQueriesApi,
  IssuancesInternalApi,
  IncreaseCommandsApi,
  BatchProcessorApi,
  PartiesQueriesApi,
  TransactionStoreApi,
} from "./api-client/src/index.js";
import ApiAxiosClient from "./ApiAxiosClient";

const protocol = window.location.protocol;
const host = window.location.host;
const client = new ApiAxiosClient(`${protocol}//${host}/`);

export const ComplianceApiClient = new ComplianceApi(client);
export const AccountsInternalApiClient = new AccountsInternalApi(client);
export const IssuancesCommandsApiClient = new IssuancesCommandsApi(client);
export const IssuancesLogsInternalApiClient = new IssuancesLogsApi(client);
export const IssuancesQueriesApiClient = new IssuancesQueriesApi(client);
export const PendingApiClient = new PendingApi(client);
export const RedemptionsApiClient = new RedemptionsApi(client);
export const RedemptionsInternalApiClient = new RedemptionsInternalApi(client);
export const AuthenticationApiClient = new AuthenticationApi(client);
export const IssuersQueriesApiClient = new IssuersQueriesApi(client);
export const IssuancesInternalApiClient = new IssuancesInternalApi(client);
export const IncreaseCommandsApiClient = new IncreaseCommandsApi(client);
export const BatchProcessorApiClient = new BatchProcessorApi(client);
export const PartiesQueriesApiClient = new PartiesQueriesApi(client);
export const TransactionStoreApiClient = new TransactionStoreApi(client);
