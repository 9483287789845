/*
 * Configuration consisting hard-coded token for local testing
 */
const config = {
  id_token:
    "eyJ0eXAiOiJKV1QiLCJraWQiOiJIbkFDZFRNQ1NwcmRiRnZaMXM3dzFDVHcwQlE9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoidzltYTZOeUpyRncyZDJYaEhpdjlaZyIsInN1YiI6ImR3MzAyIiwiYXVkaXRUcmFja2luZ0lkIjoiM2Y1YWVkOWUtMGY2MC00NTg5LWJlN2ItNDdmNDJlZWFlYTMxLTE4NzQ1IiwiaXNzIjoiaHR0cHM6Ly9pYW10ZmxzLmRldXRzY2hlLWJvZXJzZS5kZTo0NDMvYXV0aC9vYXV0aDIiLCJ0b2tlbk5hbWUiOiJpZF90b2tlbiIsImh0dHBzOi8vZGFtbC5jb20vbGVkZ2VyLWFwaSI6eyJsZWRnZXJJZCI6ImRpLWV4ZWN1dGlvbiIsImFwcGxpY2F0aW9uSWQiOiJENyBESSBFeGVjdXRpb24iLCJhY3RBcyI6WyJEYW1sLVBhcnR5LUNsZWFyc3RyZWFtOjoxMjIwNGIyYjMxNDliYWRjN2FjNTQwODU0MWMwMzdjMTQ4MWJhY2IxMjVjMjk5ODIzOWVhNjJlOWU0N2QzMjk2YWIwOSJdLCJyZWFkQXMiOlsiRGFtbC1QYXJ0eS1DbGVhcnN0cmVhbTo6MTIyMDRiMmIzMTQ5YmFkYzdhYzU0MDg1NDFjMDM3YzE0ODFiYWNiMTI1YzI5OTgyMzllYTYyZTllNDdkMzI5NmFiMDkiXSwiYWRtaW4iOmZhbHNlfSwiYXVkIjoiZGktZXhlY3V0aW9uIiwiY19oYXNoIjoiN0tGam5zQmlodDI0amhCMjdTM0ZnQSIsImFjciI6IlVUUCIsIm9yZy5mb3JnZXJvY2sub3BlbmlkY29ubmVjdC5vcHMiOiJEWmRqaVFRS2k1ekgtMFpKM1B2OXd5N3d1d1kiLCJzX2hhc2giOiJyVHZkUFJTR0NPS0JVOXhwSVJjQlNBIiwiYXpwIjoiZGktZXhlY3V0aW9uIiwiYXV0aF90aW1lIjoxNjMzNjc1NzAxLCJyZWFsbSI6Ii9pbnRyYW5ldCIsImV4cCI6MTYzMzcxODkwNCwidG9rZW5UeXBlIjoiSldUVG9rZW4iLCJpYXQiOjE2MzM2NzU3MDR9.RwbbHpiI1A4rsY3sMNxEitUxH1sY06Qcfxa-m3Emp6hUZcvLHmM7MAECHrGbXOiAgRR_H3UENhsJgTEkC362a7vcq9PNAWdkb9iz126BNMdlY73tZ5116vdicFx0Vkt2FkVzrRf4B5Kw0XHHj9aNMkP5G-AIOYGVAKaHbEcfZiMUQtLvo0BtFQ9zmsVuV5jTiNAmc3Ih_FO0XGvchrd05k-MRhIA3-j5HOP7MTiHWpRl-fY16kKarTDUGw7hvcszcrCQTcMG7ED3PjilKgevN-musWpwtwrXFzVc5ER6zET2evpVrEzUgWD1yloD8QuJd4NpStPDYORGHHAnSFOlK2RsYoL1Asf6qnAX6xaq-KuQy9xzJuXoU8qhRpDJMzbDmQGlJNXHdwzZvRFoDSyity3t1_knz37d5Bv4qc_CWA6F9G2v3PgbLfE1L06HfSP4Qnr6Ix7qRVoVn_juM5bWM9AjadjG3l7JWpeLMSnb73PaTi3JLtJ5xivbmZiEnQyZS6-mmGmNHvW51w6s9XFZidVMjHlONh92xIRPug82hIP4F7tKDHc_hUu8JrozZdlyNumbP45dSI3HrjkjiHpqM3GpWaXIr96NZKb9fiNlGNW_Q10i9JQMNq0PAYt10hSZY7Qu_E_MiCfl4Vieu9lEd2ryAVGEkyaSH5W4etxJDbg",
};
export default config;

/*
 * Configuration for Xact and Xact_Clearstream depending on an environment variable "Auth"
 */
export const oidcConfiguration =
  process.env.REACT_APP_AUTH === "Xact"
    ? /*
       * Xact configuration with metadata added to avoid CORS (cross origin) error
       * Below mentioned configuartion are set in env file and then fetched here via 'process.env followed by variable name' in env file
       */
      {
        client_id: process.env.REACT_APP_XACT_CLIENT_ID,
        redirect_uri:
          process.env.REACT_APP_XACT_REDIRECT_URI || "http://localhost:3000",
        response_type: process.env.REACT_APP_XACT_RESPONSE_TYPE,
        post_logout_redirect_uri:
          process.env.REACT_APP_XACT_POST_LOGOUT_REDIRECT_URI ||
          "http://localhost:3000",
        scope: process.env.REACT_APP_XACT_SCOPE,
        authority:
          process.env.REACT_APP_XACT_AUTHORITY || "http://localhost:3000/",
        automaticSilentRenew: false,
        silent_redirect_uri:
          process.env.REACT_APP_XACT_SILENT_REDIRECT_URI ||
          "http://localhost:3000",
        loadUserInfo: true,
        nonce: "e78pjdb5tgh",

        /* 
           * When we try to call any other domain outside our domain then we get CORS error from the browser, 
             i.e., the browser doesn't allow to access any resources between different domains. So we need to set metadata.
           * Xact provided all the endpoints (via a link) to be set for the properties of metadata
        */
        metadata: {
          issuer: process.env.REACT_APP_XACT_AUTHORITY,
          jwks_uri: process.env.REACT_APP_XACT_AUTHORITY + "/connect/jwk_uri",
          authorization_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/authorize",
          token_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/access_token",
          userinfo_endpoint: process.env.REACT_APP_XACT_AUTHORITY + "/userinfo",
          end_session_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/connect/endSession",
          revocation_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/token/revoke",
          introspection_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/introspect",
        },
      }
    : /*
       * Below mentioned configuartion are set in env file and then fetched here via 'process.env followed by variable name' in env file
       */
      {
        client_id: process.env.REACT_APP_XACT_CLIENT_ID,
        redirect_uri:
          process.env.REACT_APP_XACT_CLEARSTREAM_REDIRECT_URI ||
          "http://localhost:3000",
        response_type: process.env.REACT_APP_XACT_RESPONSE_TYPE,
        post_logout_redirect_uri:
          process.env.REACT_APP_XACT_CLEARSTREAM_POST_LOGOUT_REDIRECT_URI ||
          "http://localhost:3000",
        scope: process.env.REACT_APP_XACT_SCOPE,
        authority:
          process.env.REACT_APP_XACT_AUTHORITY || "http://localhost:3000/",
        automaticSilentRenew: false,
        silent_redirect_uri:
          process.env.REACT_APP_XACT_CLEARSTREAM_SILENT_REDIRECT_URI ||
          "http://localhost:3000",
        loadUserInfo: true,
        nonce: "e78pjdb5tgh",

        metadata: {
          issuer: process.env.REACT_APP_XACT_AUTHORITY,
          jwks_uri: process.env.REACT_APP_XACT_AUTHORITY + "/connect/jwk_uri",
          authorization_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/authorize",
          token_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/access_token",
          userinfo_endpoint: process.env.REACT_APP_XACT_AUTHORITY + "/userinfo",
          end_session_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/connect/endSession",
          revocation_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/token/revoke",
          introspection_endpoint:
            process.env.REACT_APP_XACT_AUTHORITY + "/introspect",
        },
      };

export const useNoIAM = process.env.REACT_APP_ENABLED !== "true"; // here we are checking if process.env.REACT_APP_ENABLED !== "true", then we are not going to use Xact/Xact_Clearstream
