/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ProductCheck model module.
 * @module model/ProductCheck
 * @version 1.0.3
 */
class ProductCheck {
    /**
     * Constructs a new <code>ProductCheck</code>.
     * @alias module:model/ProductCheck
     * @param sender {String} Issuing Agent account number
     * @param issuerLEI {String} The Issuer of issuance
     * @param payAgent {String} Pay Agent account number
     */
    constructor(sender, issuerLEI, payAgent) { 
        
        ProductCheck.initialize(this, sender, issuerLEI, payAgent);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, sender, issuerLEI, payAgent) { 
        obj['sender'] = sender;
        obj['issuerLEI'] = issuerLEI;
        obj['payAgent'] = payAgent;
    }

    /**
     * Constructs a <code>ProductCheck</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductCheck} obj Optional instance to populate.
     * @return {module:model/ProductCheck} The populated <code>ProductCheck</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductCheck();

            if (data.hasOwnProperty('sender')) {
                obj['sender'] = ApiClient.convertToType(data['sender'], 'String');
            }
            if (data.hasOwnProperty('issuerLEI')) {
                obj['issuerLEI'] = ApiClient.convertToType(data['issuerLEI'], 'String');
            }
            if (data.hasOwnProperty('payAgent')) {
                obj['payAgent'] = ApiClient.convertToType(data['payAgent'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ProductCheck</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ProductCheck</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ProductCheck.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['sender'] && !(typeof data['sender'] === 'string' || data['sender'] instanceof String)) {
            throw new Error("Expected the field `sender` to be a primitive type in the JSON string but got " + data['sender']);
        }
        // ensure the json data is a string
        if (data['issuerLEI'] && !(typeof data['issuerLEI'] === 'string' || data['issuerLEI'] instanceof String)) {
            throw new Error("Expected the field `issuerLEI` to be a primitive type in the JSON string but got " + data['issuerLEI']);
        }
        // ensure the json data is a string
        if (data['payAgent'] && !(typeof data['payAgent'] === 'string' || data['payAgent'] instanceof String)) {
            throw new Error("Expected the field `payAgent` to be a primitive type in the JSON string but got " + data['payAgent']);
        }

        return true;
    }


}

ProductCheck.RequiredProperties = ["sender", "issuerLEI", "payAgent"];

/**
 * Issuing Agent account number
 * @member {String} sender
 */
ProductCheck.prototype['sender'] = undefined;

/**
 * The Issuer of issuance
 * @member {String} issuerLEI
 */
ProductCheck.prototype['issuerLEI'] = undefined;

/**
 * Pay Agent account number
 * @member {String} payAgent
 */
ProductCheck.prototype['payAgent'] = undefined;






export default ProductCheck;

