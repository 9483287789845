import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const LinkButton = ({ label, onClick }) => {
  return (
    <Link
      color="inherit"
      onClick={onClick}
      id="cr_setcr"
    >
      <Typography
        variant="subtitle2"
        className="navigationItem"
        data-testid="breadcrumbs_text"
        id="cr_text"
      >
        {label}
      </Typography>
    </Link>
  )
}

export default LinkButton
