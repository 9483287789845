import styled from "styled-components";
import { Container, Card, Dialog, TableContainer } from "@material-ui/core";

export const StyledContainer = styled(Container)`
    padding-top: 15px;
    padding-left: 80px;
    padding-right: 80px;
    @media (min-width: 1280px){
        max-width: inherit;
    }
    .MuiTabPanel-root{
        padding-top: 12px;
    }
    .popup {
        position: absolute;

        width: 29%;
        height: 4em;
        left: 65%;
        top: 75px;
    }
    .mainItem {
        color: #00A7C4;
        border-bottom: 1px solid #00A5C0;
        padding-top: 5px;
        margin-Left: -10px;
        font-size: 14px;

    }
    .breadcrumbs{
        margin-left: 280px
    }
    .exportComplianceReport{
        font-size: 17px;
        font-weight:bolder;
        padding-bottom: 4%;
        margin-top: 14px;
        color: #000000;
    }
    .compLists{
        padding-bottom: 4%
`;

export const StyledCard = styled(Card)`
  height: 90px;
  width: 280px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  border-radius: 10px;
  .padding1 {
    padding-top: 27px;
  }
  .padding2 {
    padding-top: 3%;
  }
  .cursor {
    cursor: pointer;
  }
  .exCurrentFile {
    color: #000000;
  }
  .lastUpload {
    font-size: 12px;
    font-weight: bold;
    color: #000000;
  }
`;
export const StyledDialog = styled(Dialog)`
  .uploadFile {
    z-index: 3;
  }
  .dialogText {
    font-size: 17px;
    padding-top: 40px;
    padding-left: 24px;
  }
  .fileSelect {
    color: #000000;
    padding-top: 30px;
  }
  .paddingChooseFile {
    padding-top: 20px;
  }
  .paddingFileIcon {
    margin-top: 10px;
  }
  .uploadFileFont {
    border-bottom: 1px solid black;
    padding-top: 10px;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
  .checkCircleIcon {
    border-bottom: 1px solid black;
    padding-top: 6px;
    color: lightgreen;
  }
  .underlined {
    border-bottom: 1px solid black;
    padding-top: 10px;
  }
  .error1 {
    color: #00cc99;
  }
  .error2 {
    color: #cc0099;
  }
  .paddingFile {
    padding-left: 2%;
  }
  .input {
    display: none;
  }
  .paddingBottom {
    padding-bottom: 30px;
    padding-right: 40px;
  }
  .cancelColor {
    color: #00a7c4;
    width: 8%;
  }
`;
export const ViewDetailsContainer = styled(TableContainer)`
  .viewDetailsGreyIcon {
  }
  .viewDetailsActiveIcon {
    cursor: pointer;
  }
  .btnActionsTop {
    border-radius: 17px;
    width: auto;
  }
  .btnUploadList {
    border-radius: 17px;
    width: auto;
  }
`;
export const AccountListsContainer = styled(TableContainer)`
  margin-top: 10px;
  box-shadow: 0px 1px 5px 1px #87878799;
  border-radius: 12px;
  background-color: #ffffff;
  padding-bottom: 10px;
  min-height: 350px;
  .lH-17 {
    line-height: 17px;
  }
  .MuiTableCell-body {
    border-bottom: 0;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: rgba(178, 228, 236, 0.3) !important;
  }
  .cellBorder {
    border-bottom: 1px solid #00a5c0;
  }
  .completedStatus {
    color: #00cc99;
    line-height: 17px;
  }
  .requestedStatus {
    color: #ff6600;
    line-height: 17px;
  }
  .eligibilityCheckRequiredStatus {
    color: #00a5c0;
    line-height: 17px;
  }
  .checkReferenceDatabase {
    color: #cc0099;
    line-height: 17px;
  }
  .failedStatus {
    color: #cc0099;
    line-height: 17px;
  }
  .FFFFFFColor {
    background-color: #ffffff;
  }
  .F7F7F7Color {
    backgroundcolor: #f7f7f7;
  }
  .redColor {
    color: #ff0000;
    line-height: 17px;
  }
  .greenColor {
    color: #00cc99;
    line-height: 17px;
  }
  .searchbarColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    padding-left: 10px;
    padding-top: 2px;
  }
  .selectColumn .dropdown-container {
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
  }
  .datePickerColumn {
    border: 1px solid rgb(135 135 135 / 50%);
    border-radius: 17px;
    margin-top: 10px;
    height: 35px;
    display: flex
  }
  .tableCellBorder {
    border-bottom: 1px solid #00a5c0;
    paddingright: 5;
  }
  .filterIcon {
    cursor: pointer;
  }
  .status {
    line-height: 17px;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
  .filterIcon {
    cursor: pointer;
  }
  .filterColumn .css-g1d714-ValueContainer {
    padding-top: 0px;
  }
  .filterColumn .select__control {
    margin-top: 10px;
    max-height: 35px;
    border: 1px solid rgb(135 135 135 / 50%);
  }
  .filterColumn .select__control .select__dropdown-indicator {
    color: #aaaaaa;
  }
  .iconColumn {
    height: 15px;
  }
`;

export const StyledOffSubSectionContainer = styled(TableContainer)`
  .rowsAndPaginationGrid {
    padding-top: 22px;
    margin: 0px;
  }
  .paginationLabel {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .rowsPerPage {
    padding-top: 2px;
    color: #00a7c4;
    margin-right: 10px;
  }
  .select-styles {
    width: 80px;
    .select__control {
      border-radius: 6px;
      min-height: 34px;
      color: black;
    }
    .select__indicator {
      color: black;
    }
    .select__single-value {
      color: #00a5c0;
    }
  }
`;
