/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Conversion from './Conversion';
import RefData from './RefData';

/**
 * The CBF model module.
 * @module model/CBF
 * @version 1.0.3
 */
class CBF {
    /**
     * Constructs a new <code>CBF</code>.
     * @alias module:model/CBF
     */
    constructor() { 
        
        CBF.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CBF</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CBF} obj Optional instance to populate.
     * @return {module:model/CBF} The populated <code>CBF</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CBF();

            if (data.hasOwnProperty('BondType')) {
                obj['BondType'] = ApiClient.convertToType(data['BondType'], 'String');
            }
            if (data.hasOwnProperty('Conversion')) {
                obj['Conversion'] = Conversion.constructFromObject(data['Conversion']);
            }
            if (data.hasOwnProperty('RefData')) {
                obj['RefData'] = RefData.constructFromObject(data['RefData']);
            }
            if (data.hasOwnProperty('ClientFreeText')) {
                obj['ClientFreeText'] = ApiClient.convertToType(data['ClientFreeText'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CBF</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CBF</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['BondType'] && !(typeof data['BondType'] === 'string' || data['BondType'] instanceof String)) {
            throw new Error("Expected the field `BondType` to be a primitive type in the JSON string but got " + data['BondType']);
        }
        // validate the optional field `Conversion`
        if (data['Conversion']) { // data not null
          Conversion.validateJSON(data['Conversion']);
        }
        // validate the optional field `RefData`
        if (data['RefData']) { // data not null
          RefData.validateJSON(data['RefData']);
        }
        // ensure the json data is a string
        if (data['ClientFreeText'] && !(typeof data['ClientFreeText'] === 'string' || data['ClientFreeText'] instanceof String)) {
            throw new Error("Expected the field `ClientFreeText` to be a primitive type in the JSON string but got " + data['ClientFreeText']);
        }

        return true;
    }


}



/**
 * Indication of type of bond based on GD483[349]
 * @member {String} BondType
 */
CBF.prototype['BondType'] = undefined;

/**
 * @member {module:model/Conversion} Conversion
 */
CBF.prototype['Conversion'] = undefined;

/**
 * @member {module:model/RefData} RefData
 */
CBF.prototype['RefData'] = undefined;

/**
 * Field allows to enter a 9-digit reference which is passed through to the SWIFT confirmation message (MT536) in narrative 70E:[569]
 * @member {String} ClientFreeText
 */
CBF.prototype['ClientFreeText'] = undefined;






export default CBF;

