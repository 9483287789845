/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Clearing model module.
 * @module model/Clearing
 * @version 1.0.3
 */
class Clearing {
    /**
     * Constructs a new <code>Clearing</code>.
     * @alias module:model/Clearing
     */
    constructor() { 
        
        Clearing.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Clearing</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Clearing} obj Optional instance to populate.
     * @return {module:model/Clearing} The populated <code>Clearing</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Clearing();

            if (data.hasOwnProperty('DealerClearingHouse')) {
                obj['DealerClearingHouse'] = ApiClient.convertToType(data['DealerClearingHouse'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Clearing</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Clearing</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['DealerClearingHouse'] && !(typeof data['DealerClearingHouse'] === 'string' || data['DealerClearingHouse'] instanceof String)) {
            throw new Error("Expected the field `DealerClearingHouse` to be a primitive type in the JSON string but got " + data['DealerClearingHouse']);
        }

        return true;
    }


}



/**
 * Clearing house used by the billing and delivery dealer/manager for the distribution of funds. Mandatory at the Post Trade stage.[251]
 * @member {String} DealerClearingHouse
 */
Clearing.prototype['DealerClearingHouse'] = undefined;






export default Clearing;

