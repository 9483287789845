/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import IsinHistoryLog from '../model/IsinHistoryLog';
import NewErrorLogData from '../model/NewErrorLogData';
import ProcessingLogData from '../model/ProcessingLogData';

/**
* IssuancesLogs service.
* @module api/IssuancesLogsApi
* @version 1.0.3
*/
export default class IssuancesLogsApi {

    /**
    * Constructs a new IssuancesLogsApi. 
    * @alias module:api/IssuancesLogsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAuditLogs operation.
     * @callback module:api/IssuancesLogsApi~getAuditLogsCallback
     * @param {String} error Error message, if any.
     * @param {File} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Audit log file based on a date range.
     * @param {Object} opts Optional parameters
     * @param {Date} opts.dateFrom From which date, including the value provided, audit logs should be requested.
     * @param {Date} opts.dateTo To which date, including the value provided, audit logs should be requested.
     * @param {module:api/IssuancesLogsApi~getAuditLogsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link File}
     */
    getAuditLogs(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'dateFrom': opts['dateFrom'],
        'dateTo': opts['dateTo']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/di/v1/audit-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCombinedLogForIsinUsingGET operation.
     * @callback module:api/IssuancesLogsApi~getCombinedLogForIsinUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/IsinHistoryLog>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API will return the combined log containing AuditLogs, ErrorLogs and ProcessingLogs for the ISINs provided in the request.
     * @param {String} isin ISIN Example : 'DI000HR1DSH1'
     * @param {module:api/IssuancesLogsApi~getCombinedLogForIsinUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/IsinHistoryLog>}
     */
    getCombinedLogForIsinUsingGET(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getCombinedLogForIsinUsingGET");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [IsinHistoryLog];
      return this.apiClient.callApi(
        '/di/v1/issuances/{isin}/combined-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCombinedLogForIsinsUsingGET operation.
     * @callback module:api/IssuancesLogsApi~getCombinedLogForIsinsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/IsinHistoryLog>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API will return the combined log containing AuditLogs, ErrorLogs and ProcessingLogs for the ISINs provided in the request.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.isin List of ISINs. Example value : ['DE000HR1ATN2', 'DE000HR1ATN3'].
     * @param {module:api/IssuancesLogsApi~getCombinedLogForIsinsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/IsinHistoryLog>}
     */
    getCombinedLogForIsinsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'isin': this.apiClient.buildCollectionParam(opts['isin'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [IsinHistoryLog];
      return this.apiClient.callApi(
        '/di/v1/combined-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getErrorLogDetailsForIsinUsingGET operation.
     * @callback module:api/IssuancesLogsApi~getErrorLogDetailsForIsinUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/NewErrorLogData>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Error log details corresponding to a list of ISINs.
     * @param {String} isin ISIN. 'DE000HR1ATN2'
     * @param {module:api/IssuancesLogsApi~getErrorLogDetailsForIsinUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/NewErrorLogData>}
     */
    getErrorLogDetailsForIsinUsingGET(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getErrorLogDetailsForIsinUsingGET");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [NewErrorLogData];
      return this.apiClient.callApi(
        '/di/v1/issuances/{isin}/error-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getErrorLogDetailsForIsinsUsingGET operation.
     * @callback module:api/IssuancesLogsApi~getErrorLogDetailsForIsinsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/NewErrorLogData>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Error log details corresponding to a list of ISINs.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.isin List of ISINs. Example value : ['DE000HR1ATN2', 'DE000HR1ATN3'].
     * @param {module:api/IssuancesLogsApi~getErrorLogDetailsForIsinsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/NewErrorLogData>}
     */
    getErrorLogDetailsForIsinsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'isin': this.apiClient.buildCollectionParam(opts['isin'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [NewErrorLogData];
      return this.apiClient.callApi(
        '/di/v1/error-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProcessingLogDetailsByIsinsUsingGET operation.
     * @callback module:api/IssuancesLogsApi~getProcessingLogDetailsByIsinsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProcessingLogData>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Processing log details containing the sequence of status transitions of Issuances corresponding to the given list of ISINs.
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.isin List of ISINs. Example value : ['DE000HR1ATN2', 'DE000HR1ATN3'].
     * @param {module:api/IssuancesLogsApi~getProcessingLogDetailsByIsinsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProcessingLogData>}
     */
    getProcessingLogDetailsByIsinsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'isin': this.apiClient.buildCollectionParam(opts['isin'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProcessingLogData];
      return this.apiClient.callApi(
        '/di/v1/process-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProcessingLogDetailsUsingGET operation.
     * @callback module:api/IssuancesLogsApi~getProcessingLogDetailsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ProcessingLogData>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to get the Processing log details containing the sequence of status transitions of Issuances corresponding to the given list of ISINs.
     * @param {String} isin List of ISINs. Example value : ['DE000HR1ATN2', 'DE000HR1ATN3'].
     * @param {module:api/IssuancesLogsApi~getProcessingLogDetailsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ProcessingLogData>}
     */
    getProcessingLogDetailsUsingGET(isin, callback) {
      let postBody = null;
      // verify the required parameter 'isin' is set
      if (isin === undefined || isin === null) {
        throw new Error("Missing the required parameter 'isin' when calling getProcessingLogDetailsUsingGET");
      }

      let pathParams = {
        'isin': isin
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProcessingLogData];
      return this.apiClient.callApi(
        '/di/v1/issuances/{isin}/process-logs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
