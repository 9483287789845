/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BusinessValidationError from '../model/BusinessValidationError';
import Error from '../model/Error';

/**
* IncreaseCommands service.
* @module api/IncreaseCommandsApi
* @version 1.0.3
*/
export default class IncreaseCommandsApi {

    /**
    * Constructs a new IncreaseCommandsApi. 
    * @alias module:api/IncreaseCommandsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the uploadIncrease operation.
     * @callback module:api/IncreaseCommandsApi~uploadIncreaseCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to upload a new Increase in XML.
     * @param {Object} opts Optional parameters
     * @param {File} opts.increase 
     * @param {File} opts.tc 
     * @param {module:api/IncreaseCommandsApi~uploadIncreaseCallback} callback The callback function, accepting three arguments: error, data, response
     */
    uploadIncrease(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'increase': opts['increase'],
        'tc': opts['tc']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/di/v1/increases', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
