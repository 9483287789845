/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class TransactionType.
* @enum {}
* @readonly
*/
export default class TransactionType {
    
        /**
         * value: "ISSUANCE"
         * @const
         */
        "ISSUANCE" = "ISSUANCE";

    
        /**
         * value: "REDEMPTION"
         * @const
         */
        "REDEMPTION" = "REDEMPTION";

    
        /**
         * value: "INCREASE"
         * @const
         */
        "INCREASE" = "INCREASE";

    
        /**
         * value: "BATCH REDEMPTION"
         * @const
         */
        "BATCH REDEMPTION" = "BATCH REDEMPTION";

    

    /**
    * Returns a <code>TransactionType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/TransactionType} The enum <code>TransactionType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

