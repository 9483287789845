/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RedemptionFeatures model module.
 * @module model/RedemptionFeatures
 * @version 1.0.3
 */
class RedemptionFeatures {
    /**
     * Constructs a new <code>RedemptionFeatures</code>.
     * @alias module:model/RedemptionFeatures
     */
    constructor() { 
        
        RedemptionFeatures.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RedemptionFeatures</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RedemptionFeatures} obj Optional instance to populate.
     * @return {module:model/RedemptionFeatures} The populated <code>RedemptionFeatures</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RedemptionFeatures();

            if (data.hasOwnProperty('RedemptionOption')) {
                obj['RedemptionOption'] = ApiClient.convertToType(data['RedemptionOption'], 'String');
            }
            if (data.hasOwnProperty('BonusAmount')) {
                obj['BonusAmount'] = ApiClient.convertToType(data['BonusAmount'], 'Number');
            }
            if (data.hasOwnProperty('BonusCurrency')) {
                obj['BonusCurrency'] = ApiClient.convertToType(data['BonusCurrency'], 'String');
            }
            if (data.hasOwnProperty('PartialRedemptionAllowed')) {
                obj['PartialRedemptionAllowed'] = ApiClient.convertToType(data['PartialRedemptionAllowed'], 'Boolean');
            }
            if (data.hasOwnProperty('OptionalRedemptionDates')) {
                obj['OptionalRedemptionDates'] = ApiClient.convertToType(data['OptionalRedemptionDates'], ['Date']);
            }
            if (data.hasOwnProperty('OptionalRedemptionPrice')) {
                obj['OptionalRedemptionPrice'] = ApiClient.convertToType(data['OptionalRedemptionPrice'], ['Number']);
            }
            if (data.hasOwnProperty('RedemptionRate')) {
                obj['RedemptionRate'] = ApiClient.convertToType(data['RedemptionRate'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RedemptionFeatures</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RedemptionFeatures</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['RedemptionOption'] && !(typeof data['RedemptionOption'] === 'string' || data['RedemptionOption'] instanceof String)) {
            throw new Error("Expected the field `RedemptionOption` to be a primitive type in the JSON string but got " + data['RedemptionOption']);
        }
        // ensure the json data is a string
        if (data['BonusCurrency'] && !(typeof data['BonusCurrency'] === 'string' || data['BonusCurrency'] instanceof String)) {
            throw new Error("Expected the field `BonusCurrency` to be a primitive type in the JSON string but got " + data['BonusCurrency']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['OptionalRedemptionDates'])) {
            throw new Error("Expected the field `OptionalRedemptionDates` to be an array in the JSON data but got " + data['OptionalRedemptionDates']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['OptionalRedemptionPrice'])) {
            throw new Error("Expected the field `OptionalRedemptionPrice` to be an array in the JSON data but got " + data['OptionalRedemptionPrice']);
        }

        return true;
    }


}



/**
 * Options for redemption; SECU: Security, Distribution of securities to holders. CASH: Cash, Option to choose cash CASE: CashAndSecurity, Option to choose between different security and cash options.[568]
 * @member {String} RedemptionOption
 */
RedemptionFeatures.prototype['RedemptionOption'] = undefined;

/**
 * Absolute Amount for a Bonus Certificate[189]
 * @member {Number} BonusAmount
 */
RedemptionFeatures.prototype['BonusAmount'] = undefined;

/**
 * Currency of the value/amount in accordance with the ISOstandard[190]
 * @member {String} BonusCurrency
 */
RedemptionFeatures.prototype['BonusCurrency'] = undefined;

/**
 * Determines whether the partial redemption is allowed in T&C for certain instrument[35]
 * @member {Boolean} PartialRedemptionAllowed
 */
RedemptionFeatures.prototype['PartialRedemptionAllowed'] = undefined;

/**
 * Date(s) on which the call option on the bond can be exercised by the issuer. ISO 8601 date format YYYY,MM,DD. Mandatory if an issuer call is applicable.[194]
 * @member {Array.<Date>} OptionalRedemptionDates
 */
RedemptionFeatures.prototype['OptionalRedemptionDates'] = undefined;

/**
 * Price at which the bond will be redeemed if the call option is exercised. Mandatory if an issuer call is applicable and the optional redemption value is to be expressed as a price.[195]
 * @member {Array.<Number>} OptionalRedemptionPrice
 */
RedemptionFeatures.prototype['OptionalRedemptionPrice'] = undefined;

/**
 * Redemption to xyz percent. Quoted in percent points (e.g. 100 for a redemption to 100%)[358]
 * @member {Number} RedemptionRate
 */
RedemptionFeatures.prototype['RedemptionRate'] = undefined;






export default RedemptionFeatures;

