/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Reoffer model module.
 * @module model/Reoffer
 * @version 1.0.3
 */
class Reoffer {
    /**
     * Constructs a new <code>Reoffer</code>.
     * @alias module:model/Reoffer
     */
    constructor() { 
        
        Reoffer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Reoffer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Reoffer} obj Optional instance to populate.
     * @return {module:model/Reoffer} The populated <code>Reoffer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Reoffer();

            if (data.hasOwnProperty('CouponSpread')) {
                obj['CouponSpread'] = ApiClient.convertToType(data['CouponSpread'], 'Number');
            }
            if (data.hasOwnProperty('SpreadToBenchmarkRatio')) {
                obj['SpreadToBenchmarkRatio'] = ApiClient.convertToType(data['SpreadToBenchmarkRatio'], 'Number');
            }
            if (data.hasOwnProperty('SpreadMidSwapRatio')) {
                obj['SpreadMidSwapRatio'] = ApiClient.convertToType(data['SpreadMidSwapRatio'], 'Number');
            }
            if (data.hasOwnProperty('Yield')) {
                obj['Yield'] = ApiClient.convertToType(data['Yield'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Reoffer</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Reoffer</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * The yield spread between the reference security/rate being used as the basis for pricing the floating rate note and the security to be issued.[214]
 * @member {Number} CouponSpread
 */
Reoffer.prototype['CouponSpread'] = undefined;

/**
 * The spread between the re,offer yield of the security to be issued and benchmark yield of the benchmark reference security.[215]
 * @member {Number} SpreadToBenchmarkRatio
 */
Reoffer.prototype['SpreadToBenchmarkRatio'] = undefined;

/**
 * The spread between the reoffer yield of the security to be issued and mid,swap yield of the currency in which the security is to be issued.rate component.[216]
 * @member {Number} SpreadMidSwapRatio
 */
Reoffer.prototype['SpreadMidSwapRatio'] = undefined;

/**
 * The reoffer yield of the security to be issued.[217]
 * @member {Number} Yield
 */
Reoffer.prototype['Yield'] = undefined;






export default Reoffer;

