export const auditLogCount = 20; // Audit Export's large file size warning popup check
export const DATE_FORMATS = {
  DATE: "DD/MM/YYYY",
  DATE_AND_TIME: "DD/MM/YYYY HH:mm:ss z",
  DATETIME: "Do MMM, YYYY HH:mm:ss z",
};

export const DEFAULT_TIMEZONE = "Europe/Berlin";

export const AUTHORIZED_CEFI_ROLES = [
  "CBF New Issues Operator",
  "CBF ONF Operator",
  "CBF Reconciliation",
  "CBF Audit",
  "CBF Support User",
];

export const FEATURES_LEVEL = {
  P1: 1,
  P2: 2,
  P3: 3,
  P4: 4,
  P5: 5,
  P6: 6,
  P7: 7,
  P8: 8,
  P9: 9,
  P10: 10,
};
