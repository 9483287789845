import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from "@material-ui/core";
import { getComparator, stableSort } from "../../components/Sorting";
import downloadJsonActiveIcon from "../../assets/deselectedActionIcons/jsonDownload.svg";
import downloadCSVActiveIcon from "../../assets/deselectedActionIcons/csvDownload.svg";
import Pagination from "@material-ui/lab/Pagination";
import Tooltip from "@material-ui/core/Tooltip";
import Dropdown from "../../components/Dropdown";
import filterIcon from "../../assets/FilterIcon.svg";
import { MultiSelect } from "react-multi-select-component";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import nodataIcon from "../../assets/Error Icon.svg";
import axios from "axios";
import { StyledContainer, StyledHeader } from "./index.css";
import SuccessPopup from "../../components/ErrorSuccessPopUp/SuccessPopup";
import ErrorPopup from "../../components/ErrorSuccessPopUp/ErrorPopup";
import RecieptErrorPopup from "../../components/ErrorSuccessPopUp/RecieptErrorPopup";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(135, 135, 135, 0.6)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  back: {
    " > &": {
      marginTop: theme.spacing(2),
    },
  },
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
}));
//header data for table
const columns = [
  {
    id: "issuerName",
    label: "Issuer Name",
    width: "107px",
    length: "15%",
    marginTop: "0px",
  },
  {
    id: "instrumentClassification",
    label: "Instrument Classification",
    width: "97px",
    length: "",
    marginTop: "0px",
  },
  {
    id: "issuerKvNoPayAgent",
    label: "Issuer Kv Number  Pay Agent",
    width: "142px",
    length: "",
    marginTop: "-10px",
  },
  {
    id: "issuerKvNo",
    label: "Issuer Kv Number Issuer",
    width: "122px",
    length: "",
    marginTop: "-10px",
  },
  {
    id: "issuerLei",
    label: "Issuer Lei",
    width: "100px",
    length: "19%",
    marginTop: "0px",
  },
  {
    id: "senderName",
    label: "Sender Name",
    width: "113px",
    length: "14%",
    marginTop: "0px",
  },
  {
    id: "issuerCbfNo",
    label: "Issuer CBF Number",
    width: "96px",
    length: "10%",
    marginTop: "-10px",
  },
  {
    id: "cbfAktEmitCode",
    label: "Issuer Short Code",
    width: "105px",
    length: "10%",
    marginTop: "-10px",
  },
];
//list for filter functionality
const searchList = {
  issuerName: "",
  instrumentClassification: "",
  issuerKvNoPayAgent: "",
  issuerKvNo: "",
  issuerLei: "",
  senderName: "",
  issuerCbfNo: "",
  cbfAktEmitCode: "",
};
const defaultOptions = {
  issuerName: [],
  instrumentClassification: [],
  issuerKvNoPayAgent: [],
  issuerKvNo: [],
  issuerLei: [],
  senderName: [],
  issuerCbfNo: [],
  cbfAktEmitCode: [],
};
//pagination dropdown content
const pageList = [
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];
function IssuerStaticData(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0); //pagination - page number
  //const [previousPage, setPreviousPage] = React.useState(0);//pagination - previous page number
  const [responseText, setResponseText] = React.useState("");
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState([]); //contains all issuerData
  const [flagPopUp, setflagPopUp] = React.useState("");
  const [recieptResponseText, setRecieptResponseText] = React.useState(""); //Reciept err msg popup
  const [showRecieptPopUp, setShowRecieptPopUp] = React.useState(false); //Reciept err msg flag
  const [rowsPerPage, setRowsPerPage] = React.useState(20); //current row per page on pagination
  const [applyFilter, setApplyFilter] = React.useState(false); //flag for filter applied or not
  const [tempData, setTempData] = React.useState([]); //seting temp issuer details
  const [optionsArray, setOptionsArray] = React.useState({});
  const [orderBy, setOrderBy] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [selectedColumnOption, setSelectedColumnOption] =
    useState(defaultOptions);
  const [searchArray, setSearchArray] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(searchList);
  const [issuerData, setIssuerData] = React.useState([]);
  const [apiFlag, setApiFlag] = React.useState(0); //checks if api is called

  // This method fetch issuance static data with api "/di/issuer/data
  const getIssuanceStaticData = () => {
    let id_token = sessionStorage.getItem("jwtToken");
    axios
      .get("/di/v1/issuer-data/all", {
        headers: {
          Authorization: `Bearer ${id_token}`,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          let issuerData = resp.data;
          setData(issuerData);
          setIssuerData(issuerData); //storing issunace data
          setTempData(issuerData); //storing issuance data for refernce
          createFilterOptions(issuerData);
          setApiFlag(1);
        }
      })
      .catch((error) => {
        setApiFlag(1);
        setResponseText(
          error.response.data.message
            ? error.response.data.message
            : "Unknown Server error. Please try later.",
        );
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  //setting filter options based to data
  const createFilterOptions = (issuances) => {
    let columnNames = issuances.length !== 0 ? Object.keys(issuances[0]) : [];
    let optionsArrList = {};
    columnNames.forEach((elem) => {
      optionsArrList[elem] = getOptionList(issuances, elem);
    });
    setOptionsArray(optionsArrList);
  };
  // updating options in dropdown dynamically
  const getOptionList = (issuances, columnName) => {
    let arr = [];
    issuances.forEach((elem) => {
      elem[columnName] !== "" && arr.push(elem[columnName]);
    });
    let optionsArr = [...new Set(arr)];
    let returnList = [];
    optionsArr.map((elem) => {
      returnList.push({ value: elem, label: elem });
      return returnList;
    });
    return returnList;
  };
  //when pagination page changes
  const onPageChange = (pageValue) => {
    //setPreviousPage(pageValue - 1);
    setPage(pageValue - 1);
  };
  //filter handler
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //close Reciept Error Popup
  const closeRecieptErrorPopup = () => {
    setShowRecieptPopUp(false);
    setRecieptResponseText("");
  };
  // Filtering with multiple column values
  const newRequestSearch = (event) => {
    sessionStorage.setItem("search", "true");

    if (searchArray.includes(event.target.id) !== true) {
      let tempSearch = searchArray;
      tempSearch.push(event.target.id);
      setSearchArray(tempSearch);
    }

    let tempSearchValue = { ...searchValue };
    tempSearchValue[event.target.id] = event.target.value;
    setSearchValue(tempSearchValue);

    let newData = [...tempData];
    searchArray.forEach((column) => {
      let tempNewData = newData;
      let value =
        column !== event.target.id ? searchValue[column] : event.target.value;

      tempNewData = newData.filter((row) => {
        sessionStorage.setItem("search", "true");

        if (value.length === 0) {
          return true;
        } else {
          return value.includes(row[column]);
        }
      });
      //setting new data based on filter
      newData = tempNewData;
    });
    setPage(0);
    setData(newData);
  };

  // This method is used for selecting a row from table
  const handleClick = (event, name, row) => {
    const selectedIndex = name;
    let newSelected = [selectedIndex];
    if (selected[0] === selectedIndex) {
      setSelected([]);
    } else {
      setSelected(newSelected);
    }
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  //close Success/Error Popup
  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };
  //common code for both csv and json file download
  const downloadFile = (filename, data) => {
    try {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      setResponseText("File dowloaded successfully");
      setflagPopUp("success");
      setShowPopUp(true);
    } catch (err) {
      setResponseText("File download failed");
      setShowPopUp(true);
      setflagPopUp("error");
    } finally {
      setTimeout(() => {
        setShowPopUp(false);
        setResponseText("");
      }, 10000);
    }
  };
  //code for downloading csv
  const getCSVDownload = () => {
    if (typeof issuerData !== "undefined" && issuerData.length > 0) {
      var csv = [];
      var json = issuerData;
      var fields = Object.keys(issuerData[0]);
      var replacer = (key, value) => {
        return value === null ? "" : value;
      };
      csv = json.map((row) => {
        return fields
          .map((fieldName) => {
            return JSON.stringify(row[fieldName], replacer);
          })
          .join(",");
      });
      let fieldNames = [];
      //field names are mapped with key name
      fields.map((keyname) => {
        if (keyname === "issuerName") {
          fieldNames.push("Issuer Name");
        } else if (keyname === "instrumentClassification") {
          fieldNames.push("instrumentClassification");
        } else if (keyname === "issuerKvNoPayAgent") {
          fieldNames.push("Issuer Kv Number  Pay Agent");
        } else if (keyname === "issuerKvNo") {
          fieldNames.push("Issuer Kv Number Issuer");
        } else if (keyname === "issuerLei") {
          fieldNames.push("Issuer Lei");
        } else if (keyname === "senderName") {
          fieldNames.push("Sender Name");
        } else if (keyname === "issuerCbfNo") {
          fieldNames.push("Issuer CBF Number");
        } else {
          fieldNames.push("Issuer Short Code");
        }
        return fieldNames;
      });
      csv.unshift(fieldNames.join(","));
      csv = csv.join("\r\n");
      downloadFile("IssuerStaticData.csv", new Blob([csv]));
    } else {
      downloadFile(
        "IssuerStaticData.csv",
        new Blob([
          '"Issuer Name","Instrument Classification","Issuer Kv Number Pay Agent","Issuer Kv Number Issuer","Issuer Lei","Sender Name","Issuer CBF Number","Issuer Short Code"',
        ]),
      );
    }
  };
  //code for downloading json
  const getJSONDownload = () => {
    downloadFile(
      "IssuerStaticData.json",
      new Blob([JSON.stringify(issuerData)]),
    );
  };
  useEffect(() => {
    //set page and call getIssuanceStaticData function
    setPage(0);
    getIssuanceStaticData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //removing applied filters
  const removeAppliedFilter = () => {
    setSelectedColumnOption({
      issuerName: [],
      instrumentClassification: [],
      issuerKvNoPayAgent: [],
      issuerKvNo: [],
      issuerLei: [],
      senderName: [],
      issuerCbfNo: [],
      cbfAktEmitCode: [],
    });
    setSearchArray([]);
  };
  return (
    <React.Fragment>
      <StyledContainer>
        <div>
          {recieptResponseText && showRecieptPopUp ? (
            <div>
              <div className="popup" id="static_div_popup_reciept">
                <RecieptErrorPopup
                  recieptErrorPopupText={recieptResponseText}
                  recieptErrorVisibility={showRecieptPopUp}
                  handleClose={() => closeRecieptErrorPopup()}
                />
              </div>
              <div>
                {responseText && showPopUp && (
                  <div className="popup2" id="static_div_popup_response">
                    {flagPopUp !== "error" ? (
                      <SuccessPopup
                        data-testid="success_message"
                        successPopupText={responseText}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    ) : (
                      <ErrorPopup
                        data-testid="error_message"
                        errorPopupText={responseText}
                        popupVisibility={showPopUp}
                        handleClose={() => closeErrorSuccessPopup()}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              {responseText && showPopUp && (
                <div className="popup" id="static_div_popup_recieptresponse">
                  {flagPopUp !== "error" ? (
                    <SuccessPopup
                      data-testid="success_message"
                      successPopupText={responseText}
                      popupVisibility={showPopUp}
                      handleClose={() => closeErrorSuccessPopup()}
                    />
                  ) : (
                    <ErrorPopup
                      data-testid="error_message"
                      errorPopupText={responseText}
                      popupVisibility={showPopUp}
                      handleClose={() => closeErrorSuccessPopup()}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <StyledHeader>
          <Typography
            variant="h2"
            className="mainHeader"
            id="static_typo_mainheader"
          >
            Issuer Static Data
          </Typography>
          <Grid
            container
            className="gridContainer"
            id="static_grid_gridcontainer"
          >
            <Grid item xs={12} align="right" id="static_grid_download">
              <HtmlTooltip
                id="static_htmltooltip_downloadcsv"
                title={
                  <React.Fragment>
                    <Typography variant="h5" id="static_typo_downloadcsv">
                      Download CSV
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={downloadCSVActiveIcon}
                  onClick={() => getCSVDownload()}
                  data-testid="csvDownloadIcon"
                  alt="details"
                  className="icons"
                />
              </HtmlTooltip>
              <HtmlTooltip
                id="static_htmltooltip_downloadjson"
                title={
                  <React.Fragment>
                    <Typography variant="h5" id="static_typo_downloadjson">
                      Download JSON{" "}
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                <img
                  src={downloadJsonActiveIcon}
                  onClick={() => getJSONDownload()}
                  data-testid="jsonDownloadIcon"
                  alt="details"
                  className="showJson"
                  id="static_img_showjson"
                />
              </HtmlTooltip>
            </Grid>
          </Grid>
        </StyledHeader>
        <TableContainer
          className="tableStyling"
          style={{ maxHeight: 580 }}
          id="static_tablecontainer_tablestyling"
        >
          <Table
            aria-label="sticky table"
            stickyHeader
            id="static_table_tablestyling"
            data-testid="TableView"
          >
            <TableHead>
              <TableRow>
                {data.length !== 0 ? (
                  <TableCell
                    className="tableCellBorder"
                    id="static_tablecell_applyfilter"
                  >
                    <HtmlTooltip
                      id="static_htmltooltip_applyfilter"
                      title={
                        <React.Fragment>
                          <Typography variant="h5" id="static_typo_applyfilter">
                            {applyFilter !== true
                              ? "Add Filters"
                              : "Remove Filters"}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <span className="filterIcon" id="static_span_applyfilter">
                        <img
                          src={filterIcon}
                          alt="triangle"
                          id="static_img_applyfilter"
                          data-testid="filter_Icon"
                          onClick={() => {
                            if (applyFilter === true) {
                              setData(tempData);
                              sessionStorage.setItem("search", "false");
                            }
                            if (data.length !== 0) {
                              removeAppliedFilter();
                              setApplyFilter(!applyFilter);
                            } else if (applyFilter) {
                              removeAppliedFilter();
                              setApplyFilter(!applyFilter);
                            }
                          }}
                        />
                      </span>
                    </HtmlTooltip>
                  </TableCell>
                ) : (
                  <React.Fragment>
                    {applyFilter && (
                      <TableCell
                        className="tableCellBorder"
                        id="static_tablecell_tooltip"
                      >
                        <HtmlTooltip
                          id="static_htmltooltip_tooltip"
                          title={
                            <React.Fragment>
                              <Typography
                                variant="h5"
                                id="static_typo_tooltip"
                                data-testid="filter_Icon"
                              >
                                {applyFilter !== true
                                  ? "Add Filters"
                                  : "Remove Filters"}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <span
                            className="filterIcon"
                            id="static_span_filteIcon"
                          >
                            <img
                              src={filterIcon}
                              alt="triangle"
                              data-testid="filter_Icon"
                              id="static_img_filteIcon"
                              onClick={() => {
                                if (applyFilter === true) {
                                  setData(tempData);
                                  sessionStorage.setItem("search", "false");
                                }
                                if (data.length !== 0) {
                                  removeAppliedFilter();
                                  setApplyFilter(!applyFilter);
                                } else if (applyFilter) {
                                  removeAppliedFilter();
                                  setApplyFilter(!applyFilter);
                                }
                              }}
                            />
                          </span>
                        </HtmlTooltip>
                      </TableCell>
                    )}
                  </React.Fragment>
                )}

                {columns.map((column) => {
                  return (
                    <TableCell
                      className="cellBorder"
                      key={column.key}
                      style={{ maxWidth: column.width, width: column.length }}
                      id={`static_tablecell_sorting_${column.id}`}
                    >
                      <TableSortLabel
                        style={{
                          width: column.width,
                          marginTop: column.marginTop,
                        }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                        id={`static_tablesortlabel_sorting_${column.id}`}
                      >
                        <Typography
                          variant="h5"
                          className="lH-17"
                          id={`static_typo_sorting_${column.id}`}
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <span
                              className="visuallyhidden"
                              id={`static_span_sorting_${column.id}`}
                            >
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </Typography>
                      </TableSortLabel>

                      <div>
                        {applyFilter && (
                          <MultiSelect
                            className="selectColumn"
                            options={optionsArray[column.id]}
                            id={`static_multiselect_column_${column.id}`}
                            onChange={(option) => {
                              let arr = [];
                              option.map((elem) => {
                                arr.push(elem.value);
                                return arr;
                              });
                              let optionSelected = selectedColumnOption;
                              optionSelected[column.id] = option;
                              setSelectedColumnOption(optionSelected);
                              newRequestSearch({
                                target: {
                                  id: column.id,
                                  value: arr,
                                },
                              });
                            }}
                            value={selectedColumnOption[column.id]}
                          />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* if api is called and no data is available to show */}
              {data.length === 0 && apiFlag === 1 ? (
                <TableRow id="static_tablerow_nodata">
                  <TableCell
                    colSpan={9}
                    align="center"
                    className="nodataicon"
                    data-testid="tableNoRowData"
                    id="static_tablecell_nodata"
                  >
                    <div>
                      <img
                        src={nodataIcon}
                        alt="nodata"
                        className="noiconColumn"
                        id="static_img_nodata"
                      />
                      <Typography id="static_typo_nodata" data-testid="noData">
                        No data available to display
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {stableSort(data, getComparator(order, orderBy))
                    .reverse()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(index);

                      return (
                        <TableRow
                          className="tableRow"
                          data-testid={`tableRow_${index}`}
                          hover
                          onClick={(event) => handleClick(event, index, row)}
                          id={`static_tableRow_issuerStaticData_${index}`}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          selected={isItemSelected}
                        >
                          <TableCell></TableCell>

                          <TableCell
                            className="padding2"
                            id={`static_tableCell_issuerName_${index}`}
                            data-testid="tableRowData"
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_v_${index}`}
                            >
                              {row.issuerName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_instrumentClassification_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_instrumentClassification_${index}`}
                            >
                              {row.instrumentClassification}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_issuerKvNoPayAgent_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_issuerKvNoPayAgent_${index}`}
                            >
                              {row.issuerKvNoPayAgent}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_issuerKvNoIssuer_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_issuerKvNoIssuer_${index}`}
                            >
                              {row.issuerKvNo}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_issuerLei_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_issuerLei_${index}`}
                            >
                              {row.issuerLei}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_senderName_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_senderName_${index}`}
                            >
                              {row.senderName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_issuerCbfNo_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_issuerCbfNo_${index}`}
                            >
                              {row.issuerCbfNo}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="padding2"
                            id={`static_tableCell_cbfAktEmitCode_${index}`}
                          >
                            <Typography
                              variant="subtitle1"
                              className="lH-17"
                              id={`static_typo_cbfAktEmitCode_${index}`}
                            >
                              {row.cbfAktEmitCode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container className="paginationGrid" id="static_grid_pagination">
          <Grid container xs={2}>
            <Grid item xs={7}>
              <Typography
                variant="subtitle1"
                className="pT-2"
                id="static_typo_rosperpage"
              >
                Rows per page:
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Dropdown
                isClearable={false}
                className="select-styles"
                onChange={(option) => {
                  setRowsPerPage(option.value);
                }}
                id="rowOption"
                options={pageList}
                value={{ value: rowsPerPage, label: rowsPerPage.toString() }}
              />
            </Grid>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={3}></Grid>
            <Grid item xs={1} align="right" id="static_grid_page">
              <Typography
                variant="subtitle1"
                className="page"
                id="static_typo_page"
              >
                Page:
              </Typography>
            </Grid>
            <Grid item xs={8} id="static_grid_pagination">
              <Pagination
                onChange={(event, page) => onPageChange(page)}
                count={Math.ceil(data.length / rowsPerPage)}
                page={page + 1}
                classes={{ ul: classes.ul }}
                id="static_pagination_onpagechange"
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledContainer>
    </React.Fragment>
  );
}
export default IssuerStaticData;
