/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OtherPartyIdentification from './OtherPartyIdentification';
import PostalAddress from './PostalAddress';
import Rating from './Rating';

/**
 * The Party model module.
 * @module model/Party
 * @version 1.0.3
 */
class Party {
    /**
     * Constructs a new <code>Party</code>.
     * @alias module:model/Party
     */
    constructor() { 
        
        Party.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Party</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Party} obj Optional instance to populate.
     * @return {module:model/Party} The populated <code>Party</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Party();

            if (data.hasOwnProperty('PartyRole')) {
                obj['PartyRole'] = ApiClient.convertToType(data['PartyRole'], ['String']);
            }
            if (data.hasOwnProperty('LEI')) {
                obj['LEI'] = ApiClient.convertToType(data['LEI'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('PhoneNumber')) {
                obj['PhoneNumber'] = ApiClient.convertToType(data['PhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('URLAddress')) {
                obj['URLAddress'] = ApiClient.convertToType(data['URLAddress'], 'String');
            }
            if (data.hasOwnProperty('GroupID')) {
                obj['GroupID'] = ApiClient.convertToType(data['GroupID'], 'String');
            }
            if (data.hasOwnProperty('GroupName')) {
                obj['GroupName'] = ApiClient.convertToType(data['GroupName'], 'String');
            }
            if (data.hasOwnProperty('IssuerType')) {
                obj['IssuerType'] = ApiClient.convertToType(data['IssuerType'], 'String');
            }
            if (data.hasOwnProperty('Identification')) {
                obj['Identification'] = ApiClient.convertToType(data['Identification'], 'String');
            }
            if (data.hasOwnProperty('OtherPartyIdentification')) {
                obj['OtherPartyIdentification'] = ApiClient.convertToType(data['OtherPartyIdentification'], [OtherPartyIdentification]);
            }
            if (data.hasOwnProperty('PostalAddress')) {
                obj['PostalAddress'] = PostalAddress.constructFromObject(data['PostalAddress']);
            }
            if (data.hasOwnProperty('Rating')) {
                obj['Rating'] = ApiClient.convertToType(data['Rating'], [Rating]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Party</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Party</code>.
     */
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['PartyRole'])) {
            throw new Error("Expected the field `PartyRole` to be an array in the JSON data but got " + data['PartyRole']);
        }
        // ensure the json data is a string
        if (data['LEI'] && !(typeof data['LEI'] === 'string' || data['LEI'] instanceof String)) {
            throw new Error("Expected the field `LEI` to be a primitive type in the JSON string but got " + data['LEI']);
        }
        // ensure the json data is a string
        if (data['Name'] && !(typeof data['Name'] === 'string' || data['Name'] instanceof String)) {
            throw new Error("Expected the field `Name` to be a primitive type in the JSON string but got " + data['Name']);
        }
        // ensure the json data is a string
        if (data['Email'] && !(typeof data['Email'] === 'string' || data['Email'] instanceof String)) {
            throw new Error("Expected the field `Email` to be a primitive type in the JSON string but got " + data['Email']);
        }
        // ensure the json data is a string
        if (data['PhoneNumber'] && !(typeof data['PhoneNumber'] === 'string' || data['PhoneNumber'] instanceof String)) {
            throw new Error("Expected the field `PhoneNumber` to be a primitive type in the JSON string but got " + data['PhoneNumber']);
        }
        // ensure the json data is a string
        if (data['URLAddress'] && !(typeof data['URLAddress'] === 'string' || data['URLAddress'] instanceof String)) {
            throw new Error("Expected the field `URLAddress` to be a primitive type in the JSON string but got " + data['URLAddress']);
        }
        // ensure the json data is a string
        if (data['GroupID'] && !(typeof data['GroupID'] === 'string' || data['GroupID'] instanceof String)) {
            throw new Error("Expected the field `GroupID` to be a primitive type in the JSON string but got " + data['GroupID']);
        }
        // ensure the json data is a string
        if (data['GroupName'] && !(typeof data['GroupName'] === 'string' || data['GroupName'] instanceof String)) {
            throw new Error("Expected the field `GroupName` to be a primitive type in the JSON string but got " + data['GroupName']);
        }
        // ensure the json data is a string
        if (data['IssuerType'] && !(typeof data['IssuerType'] === 'string' || data['IssuerType'] instanceof String)) {
            throw new Error("Expected the field `IssuerType` to be a primitive type in the JSON string but got " + data['IssuerType']);
        }
        // ensure the json data is a string
        if (data['Identification'] && !(typeof data['Identification'] === 'string' || data['Identification'] instanceof String)) {
            throw new Error("Expected the field `Identification` to be a primitive type in the JSON string but got " + data['Identification']);
        }
        if (data['OtherPartyIdentification']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['OtherPartyIdentification'])) {
                throw new Error("Expected the field `OtherPartyIdentification` to be an array in the JSON data but got " + data['OtherPartyIdentification']);
            }
            // validate the optional field `OtherPartyIdentification` (array)
            for (const item of data['OtherPartyIdentification']) {
                OtherPartyIdentification.validateJsonObject(item);
            };
        }
        // validate the optional field `PostalAddress`
        if (data['PostalAddress']) { // data not null
          PostalAddress.validateJSON(data['PostalAddress']);
        }
        if (data['Rating']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Rating'])) {
                throw new Error("Expected the field `Rating` to be an array in the JSON data but got " + data['Rating']);
            }
            // validate the optional field `Rating` (array)
            for (const item of data['Rating']) {
                Rating.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * type of Role[506]
 * @member {Array.<String>} PartyRole
 */
Party.prototype['PartyRole'] = undefined;

/**
 * ISO 17442 Legal Entity Identifier code for the party.[507]
 * @member {String} LEI
 */
Party.prototype['LEI'] = undefined;

/**
 * An agent appointed by the issuer to issue securities to the market and receive corresponding payments, if applicable, from the Dealer on the Issue Date. Mandatory.[508]
 * @member {String} Name
 */
Party.prototype['Name'] = undefined;

/**
 * The issuers EMail address[510]
 * @member {String} Email
 */
Party.prototype['Email'] = undefined;

/**
 * Telephone number for inquiries or technical difficulties regarding the issuance[511]
 * @member {String} PhoneNumber
 */
Party.prototype['PhoneNumber'] = undefined;

/**
 * The URL of the issuer/applicant homepage where information regarding an instrument may be found[512]
 * @member {String} URLAddress
 */
Party.prototype['URLAddress'] = undefined;

/**
 * Distinctive ID of the parent company. Should the parent company be the issuer, then the issuer ID of the parent company is to be used.[513]
 * @member {String} GroupID
 */
Party.prototype['GroupID'] = undefined;

/**
 * Name of the issuers parent company. If the issuer is the parent company, then the name of the issuer shall be used once more.[514]
 * @member {String} GroupName
 */
Party.prototype['GroupName'] = undefined;

/**
 * The type of party. Mandatory.[87]
 * @member {String} IssuerType
 */
Party.prototype['IssuerType'] = undefined;

/**
 * The distinctive ID of the sender sending the issuance. The ID does not need to be identical to the issuer ID. WM issuer number.[58]
 * @member {String} Identification
 */
Party.prototype['Identification'] = undefined;

/**
 * Model: OtherPartyIdentification
 * @member {Array.<module:model/OtherPartyIdentification>} OtherPartyIdentification
 */
Party.prototype['OtherPartyIdentification'] = undefined;

/**
 * @member {module:model/PostalAddress} PostalAddress
 */
Party.prototype['PostalAddress'] = undefined;

/**
 * Model: Rating
 * @member {Array.<module:model/Rating>} Rating
 */
Party.prototype['Rating'] = undefined;






export default Party;

