/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UnderlyingInstrument from './UnderlyingInstrument';

/**
 * The Interest model module.
 * @module model/Interest
 * @version 1.0.3
 */
class Interest {
    /**
     * Constructs a new <code>Interest</code>.
     * @alias module:model/Interest
     */
    constructor() { 
        
        Interest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Interest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Interest} obj Optional instance to populate.
     * @return {module:model/Interest} The populated <code>Interest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Interest();

            if (data.hasOwnProperty('InterestPaymentCurrency')) {
                obj['InterestPaymentCurrency'] = ApiClient.convertToType(data['InterestPaymentCurrency'], 'String');
            }
            if (data.hasOwnProperty('InterestAmount')) {
                obj['InterestAmount'] = ApiClient.convertToType(data['InterestAmount'], 'Number');
            }
            if (data.hasOwnProperty('FixingReferenceDate')) {
                obj['FixingReferenceDate'] = ApiClient.convertToType(data['FixingReferenceDate'], 'String');
            }
            if (data.hasOwnProperty('NumberOfDays')) {
                obj['NumberOfDays'] = ApiClient.convertToType(data['NumberOfDays'], 'Number');
            }
            if (data.hasOwnProperty('Term')) {
                obj['Term'] = ApiClient.convertToType(data['Term'], 'Number');
            }
            if (data.hasOwnProperty('PaymentDate')) {
                obj['PaymentDate'] = ApiClient.convertToType(data['PaymentDate'], 'Date');
            }
            if (data.hasOwnProperty('UnderlyingInstrument')) {
                obj['UnderlyingInstrument'] = UnderlyingInstrument.constructFromObject(data['UnderlyingInstrument']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Interest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Interest</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['InterestPaymentCurrency'] && !(typeof data['InterestPaymentCurrency'] === 'string' || data['InterestPaymentCurrency'] instanceof String)) {
            throw new Error("Expected the field `InterestPaymentCurrency` to be a primitive type in the JSON string but got " + data['InterestPaymentCurrency']);
        }
        // ensure the json data is a string
        if (data['FixingReferenceDate'] && !(typeof data['FixingReferenceDate'] === 'string' || data['FixingReferenceDate'] instanceof String)) {
            throw new Error("Expected the field `FixingReferenceDate` to be a primitive type in the JSON string but got " + data['FixingReferenceDate']);
        }
        // validate the optional field `UnderlyingInstrument`
        if (data['UnderlyingInstrument']) { // data not null
          UnderlyingInstrument.validateJSON(data['UnderlyingInstrument']);
        }

        return true;
    }


}



/**
 * Currency of amount of fixed interest that will be received per denomination.[541]
 * @member {String} InterestPaymentCurrency
 */
Interest.prototype['InterestPaymentCurrency'] = undefined;

/**
 * Amount of fixed interest that will be received per denomination.[153]
 * @member {Number} InterestAmount
 */
Interest.prototype['InterestAmount'] = undefined;

/**
 * The reference date from which a number of days is deducted to get to the date on which the floating rate coupon is fixed.[169]
 * @member {String} FixingReferenceDate
 */
Interest.prototype['FixingReferenceDate'] = undefined;

/**
 * For term rates, the number of business days to deduct from the fixing_reference_date to get to the interest fixing date.[174]
 * @member {Number} NumberOfDays
 */
Interest.prototype['NumberOfDays'] = undefined;

/**
 * Number of days for which interest is to be paid[181]
 * @member {Number} Term
 */
Interest.prototype['Term'] = undefined;

/**
 * Indicates the intended interest payment date[579]
 * @member {Date} PaymentDate
 */
Interest.prototype['PaymentDate'] = undefined;

/**
 * @member {module:model/UnderlyingInstrument} UnderlyingInstrument
 */
Interest.prototype['UnderlyingInstrument'] = undefined;






export default Interest;

