/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LifecycleManagementItem model module.
 * @module model/LifecycleManagementItem
 * @version 1.0.3
 */
class LifecycleManagementItem {
    /**
     * Constructs a new <code>LifecycleManagementItem</code>.
     * @alias module:model/LifecycleManagementItem
     */
    constructor() { 
        
        LifecycleManagementItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>LifecycleManagementItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LifecycleManagementItem} obj Optional instance to populate.
     * @return {module:model/LifecycleManagementItem} The populated <code>LifecycleManagementItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LifecycleManagementItem();

            if (data.hasOwnProperty('errorMessage')) {
                obj['errorMessage'] = ApiClient.convertToType(data['errorMessage'], 'String');
            }
            if (data.hasOwnProperty('instrumentType')) {
                obj['instrumentType'] = ApiClient.convertToType(data['instrumentType'], 'String');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('lifecycleOverallStatus')) {
                obj['lifecycleOverallStatus'] = ApiClient.convertToType(data['lifecycleOverallStatus'], 'String');
            }
            if (data.hasOwnProperty('internalStatus')) {
                obj['internalStatus'] = ApiClient.convertToType(data['internalStatus'], 'String');
            }
            if (data.hasOwnProperty('payDate')) {
                obj['payDate'] = ApiClient.convertToType(data['payDate'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>LifecycleManagementItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LifecycleManagementItem</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['errorMessage'] && !(typeof data['errorMessage'] === 'string' || data['errorMessage'] instanceof String)) {
            throw new Error("Expected the field `errorMessage` to be a primitive type in the JSON string but got " + data['errorMessage']);
        }
        // ensure the json data is a string
        if (data['instrumentType'] && !(typeof data['instrumentType'] === 'string' || data['instrumentType'] instanceof String)) {
            throw new Error("Expected the field `instrumentType` to be a primitive type in the JSON string but got " + data['instrumentType']);
        }
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['lifecycleOverallStatus'] && !(typeof data['lifecycleOverallStatus'] === 'string' || data['lifecycleOverallStatus'] instanceof String)) {
            throw new Error("Expected the field `lifecycleOverallStatus` to be a primitive type in the JSON string but got " + data['lifecycleOverallStatus']);
        }
        // ensure the json data is a string
        if (data['internalStatus'] && !(typeof data['internalStatus'] === 'string' || data['internalStatus'] instanceof String)) {
            throw new Error("Expected the field `internalStatus` to be a primitive type in the JSON string but got " + data['internalStatus']);
        }

        return true;
    }


}



/**
 * Error Message
 * @member {String} errorMessage
 */
LifecycleManagementItem.prototype['errorMessage'] = undefined;

/**
 * Instrument Type
 * @member {String} instrumentType
 */
LifecycleManagementItem.prototype['instrumentType'] = undefined;

/**
 * ISIN
 * @member {String} isin
 */
LifecycleManagementItem.prototype['isin'] = undefined;

/**
 * Issuer Name
 * @member {String} issuerName
 */
LifecycleManagementItem.prototype['issuerName'] = undefined;

/**
 * LifeCycle Overall Status
 * @member {String} lifecycleOverallStatus
 */
LifecycleManagementItem.prototype['lifecycleOverallStatus'] = undefined;

/**
 * LifeCycle Internal Status
 * @member {String} internalStatus
 */
LifecycleManagementItem.prototype['internalStatus'] = undefined;

/**
 * Pay Date
 * @member {Date} payDate
 */
LifecycleManagementItem.prototype['payDate'] = undefined;






export default LifecycleManagementItem;

