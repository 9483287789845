import styled from "styled-components";
import { Container, Card } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

export const StyledContainer = styled(Container)`
  @media (min-width: 1280px) {
    max-width: inherit;
  }
  padding-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  .popup {
    position: absolute;
    z-index: 2;
    width: 29%;
    height: 4em;
    left: 65%;
    top: 75px;
  }
  #iss_icon_refreshTable {
    cursor: pointer;
  }
  .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const StyledHeader = styled(Card)`
  z-index: 2;
  border: none;
  padding-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  box-shadow: none;
  background-color: #ffffff;
  .MuiBreadcrumbs-ol {
    margin-top: 1%;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 2em;
  }
  .MuiSvgIcon-root {
    padding-top: 10px;
    color: #000000;
  }
  .mainItem {
    color: #00a7c4;
    border-bottom: 1px solid #00a5c0;
    padding-top: 10px;
    cursor: pointer;
  }
  .item {
    padding-top: 10px;
    color: #000000;
  }
  .container {
    flex: 1 0 0;
  }
  .divider {
    display: inline-block;
    width: 2px;
    background-color: #00a7c4;
    margin: 0 10px;
    height: 4em;
  }
  .processlog {
    width: 129px;
  }
  .icons {
    cursor: pointer;
  }
  .showpdf {
    cursor: pointer;
  }
  .excolAll {
    color: #00a7c4;
    border-bottom: 1px solid #00a7c4;
  }
  .padding1,
  .paddingDetails {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .padding2 {
    paddding-top: 10px;
  }
  .Mui-disabled,
  .Mui-disabled:hover {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .MuiButton-root {
    width: inherit !important;
  }
  .navigationItem {
    color: #00a7c4;
    border-bottom: 1px solid #00a5c0;
    padding-top: 5px;
    margin-left: -10px;
    font-size: 14px;
  }
  .breadcrumbs {
    padding-bottom: 10px;
  }
`;

export const StyledDialog = styled(Dialog)`
    .acceptRejectDialog{
        z-index: 1;
    }
    .acceptRejectButton{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        padding-top: 20px;
        padding-left: 10px
    }
    .accept{
        padding-top: 20px
        padding-bottom: 30px;
        padding-right: 100px
    }
`;

export const ErrorBody = styled(Container)`
  .MuiTableCell-root {
    border: none;
  }
  height: 60vh;
  max-width: 100%;
  width: -webkit-fill-available;
  //margin: 30px !important;
  //box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  //border-radius: 10px;
  padding-left: 30px;
  padding-top: 18px;
  padding-bottom: 18px;
  // min-height:350px;
  .paddingbtm {
    padding-bottom: 10px;
    height: 50vh;
    overflow-y: auto;
  }
  .padding1 {
    padding-left: 13px;
    padding-top: 10px;
  }
  .flex {
    flex-basis: 2%;
  }
  .padding2 {
    padding-top: 4px;
    padding-left: 0px !important;
  }
  .history {
    border-left: 1px dashed #b7b7b7;
    height: 100%;
    margin-left: 5px;
  }
  .textcolour1 {
    color: #00a5c0;
    line-height: 25px;
  }
  .bottomborder {
    border-bottom: 1px solid #00a7c4;
  }
  .textcolour2 {
    color: #ff1a1a;
  }
  .bgcolour {
    background-color: #ffffff;
  }
  .tableheight {
    min-height: 160px;
    max-height: 50vh;
  }
  .tableBorder {
    box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
    border-radius: 5px;
  }
`;

export const ViewXMLBody = styled(Container)`
  .MuiTableCell-root {
    border: none;
  }
  height: 60vh;
  max-width: 100%;
  width: -webkit-fill-available;
  overflow: scroll;
  margin: 30px !important;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  border-radius: 10px;
  padding-left: 14px;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 10px;
  // min-height:350px;
  .underlined {
    margin-top: 15px;
    margin-bottom: 5px;
    border-top: 1px solid #00a7c4;
  }
  .tableheight {
    min-height: 160px;
    max-height: 50vh;
  }
  .textcolour {
    color: #00a5c0;
  }
  .bgcolour {
    background-color: #ffffff;
  }
`;

export const LogBody = styled(Container)`
  .MuiTableCell-root {
    border: none;
  }
  .tableClass {
    table-layout: fixed;
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
  }

  height: 60vh;
  max-width: 100%;
  width: -webkit-fill-available;
  margin: 30px !important;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  border-radius: 10px;
  overflow: hidden;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  .underlined {
    margin-top: 15px;
    margin-bottom: 5px;
    border-top: 1px solid #00a7c4;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  table {
    border-collapse: collapse;
  }

  thead tr {
    border-bottom: 5px solid #00a7c4;
  }
  thead h5 {
    font-size: 15px;
  }
  .tableheight {
    min-height: 160px;
    max-height: 100%;
  }
  .bgcolour {
    margin-bottom: 15px;
    border-bottom: 3px solid #00a7c4;
  }
`;
export const StyledBody = styled(Card)`
  border: none;
  box-shadow: none;
  background-color: #ffffff;
  padding-top: 0;
  .MuiTableCell-root {
    border: none;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 1px 5px rgba(135, 135, 135, 0.6);
  }
  .accordianStyle {
    margin: 25px 30px 30px 30px !important;
    border-radius: 12px;
  }
  .MuiAccordion-root:before {
    background-color: rgba(0, 0, 0, 0);
  }

  .accordionContent {
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid #00a7c4;
    background-color: #ffffff;
    #det_clearB_typo_sec {
      overflow-wrap: anywhere;
    }
  }
  .MuiSvgIcon-root {
    transition: fill 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .bgColour {
    background-color: rgba(227, 227, 227, 0.15);
  }
  .divider1 {
    display: inline-block;
    background-color: #00a5c0;
    width: 1px;
    height: inherit;
    margin-top: 8px;
  }
  .padding3 {
    padding-top: 10px;
  }
  .padding4 {
    padding-left: 15px;
  }
  .paddingColumn {
    height: min-content;
    padding-top: 10px;
    padding-left: 15px;
  }

  @media only screen and (max-width: 1450px) {
    /* For smaller screens: */
    [class*="accordionContent"] {
      flex-wrap: wrap;
    }
    [class*="padding4"] {
      padding-left: 0px;
    }
    [class*="divider1"] {
      display: none;
    }
  }
`;
