/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import IssuanceErrorDetails from './IssuanceErrorDetails';

/**
 * The NewErrorLogData model module.
 * @module model/NewErrorLogData
 * @version 1.0.3
 */
class NewErrorLogData {
    /**
     * Constructs a new <code>NewErrorLogData</code>.
     * @alias module:model/NewErrorLogData
     * @param errorDateTimeDi {Date} Error Date Time Di
     * @param errorDateTimePublisher {Date} Error Date Time Publisher
     * @param errorDetails {module:model/IssuanceErrorDetails} 
     * @param errorPublisher {String} Error Publisher
     * @param errorStatus {String} Status
     * @param optionalDti {String} DTI
     */
    constructor(errorDateTimeDi, errorDateTimePublisher, errorDetails, errorPublisher, errorStatus, optionalDti) { 
        
        NewErrorLogData.initialize(this, errorDateTimeDi, errorDateTimePublisher, errorDetails, errorPublisher, errorStatus, optionalDti);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, errorDateTimeDi, errorDateTimePublisher, errorDetails, errorPublisher, errorStatus, optionalDti) { 
        obj['errorDateTimeDi'] = errorDateTimeDi;
        obj['errorDateTimePublisher'] = errorDateTimePublisher;
        obj['errorDetails'] = errorDetails;
        obj['errorPublisher'] = errorPublisher;
        obj['errorStatus'] = errorStatus;
        obj['optionalDti'] = optionalDti;
    }

    /**
     * Constructs a <code>NewErrorLogData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/NewErrorLogData} obj Optional instance to populate.
     * @return {module:model/NewErrorLogData} The populated <code>NewErrorLogData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewErrorLogData();

            if (data.hasOwnProperty('errorDateTimeDi')) {
                obj['errorDateTimeDi'] = ApiClient.convertToType(data['errorDateTimeDi'], 'Date');
            }
            if (data.hasOwnProperty('errorDateTimePublisher')) {
                obj['errorDateTimePublisher'] = ApiClient.convertToType(data['errorDateTimePublisher'], 'Date');
            }
            if (data.hasOwnProperty('errorDetails')) {
                obj['errorDetails'] = IssuanceErrorDetails.constructFromObject(data['errorDetails']);
            }
            if (data.hasOwnProperty('errorPublisher')) {
                obj['errorPublisher'] = ApiClient.convertToType(data['errorPublisher'], 'String');
            }
            if (data.hasOwnProperty('errorStatus')) {
                obj['errorStatus'] = ApiClient.convertToType(data['errorStatus'], 'String');
            }
            if (data.hasOwnProperty('optionalDti')) {
                obj['optionalDti'] = ApiClient.convertToType(data['optionalDti'], 'String');
            }
            if (data.hasOwnProperty('process')) {
                obj['process'] = ApiClient.convertToType(data['process'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>NewErrorLogData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>NewErrorLogData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of NewErrorLogData.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `errorDetails`
        if (data['errorDetails']) { // data not null
          IssuanceErrorDetails.validateJSON(data['errorDetails']);
        }
        // ensure the json data is a string
        if (data['errorPublisher'] && !(typeof data['errorPublisher'] === 'string' || data['errorPublisher'] instanceof String)) {
            throw new Error("Expected the field `errorPublisher` to be a primitive type in the JSON string but got " + data['errorPublisher']);
        }
        // ensure the json data is a string
        if (data['errorStatus'] && !(typeof data['errorStatus'] === 'string' || data['errorStatus'] instanceof String)) {
            throw new Error("Expected the field `errorStatus` to be a primitive type in the JSON string but got " + data['errorStatus']);
        }
        // ensure the json data is a string
        if (data['optionalDti'] && !(typeof data['optionalDti'] === 'string' || data['optionalDti'] instanceof String)) {
            throw new Error("Expected the field `optionalDti` to be a primitive type in the JSON string but got " + data['optionalDti']);
        }
        // ensure the json data is a string
        if (data['process'] && !(typeof data['process'] === 'string' || data['process'] instanceof String)) {
            throw new Error("Expected the field `process` to be a primitive type in the JSON string but got " + data['process']);
        }

        return true;
    }


}

NewErrorLogData.RequiredProperties = ["errorDateTimeDi", "errorDateTimePublisher", "errorDetails", "errorPublisher", "errorStatus", "optionalDti"];

/**
 * Error Date Time Di
 * @member {Date} errorDateTimeDi
 */
NewErrorLogData.prototype['errorDateTimeDi'] = undefined;

/**
 * Error Date Time Publisher
 * @member {Date} errorDateTimePublisher
 */
NewErrorLogData.prototype['errorDateTimePublisher'] = undefined;

/**
 * @member {module:model/IssuanceErrorDetails} errorDetails
 */
NewErrorLogData.prototype['errorDetails'] = undefined;

/**
 * Error Publisher
 * @member {String} errorPublisher
 */
NewErrorLogData.prototype['errorPublisher'] = undefined;

/**
 * Status
 * @member {String} errorStatus
 */
NewErrorLogData.prototype['errorStatus'] = undefined;

/**
 * DTI
 * @member {String} optionalDti
 */
NewErrorLogData.prototype['optionalDti'] = undefined;

/**
 * process
 * @member {String} process
 */
NewErrorLogData.prototype['process'] = undefined;






export default NewErrorLogData;

