import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  PendingApiClient,
  IssuancesQueriesApiClient,
  IssuancesInternalApiClient,
} from "../../api";
import { useUserStore } from "../../store/Store";
import { StyledHeader, StyledContainer } from "../Details/index.css";
import SimplePopup, {
  FLAGS,
  useSimplePopup,
} from "../../components/SimplePopup";
import SimpleTable from "../../components/SimpleTable";
import BackButton from "../../components/BackButton";
import ImageButton from "../../components/ImageButton";
import { DATE_FORMATS } from "../../constants";
import {
  internalStatus as INTERNAL_STATUSES,
  getInternalStatusValue,
} from "../../utils/StatusUtils";
import viewPdfActiveIcon from "../../assets/deselectedActionIcons/View PDF-Outline.svg";
import { Space } from "antd";

const columns = {
  status: "Status",
  isin: "ISIN",
  issuerName: "Issuance for",
  underlyingIsin: "ISIN of the Underlying",
  date: "Date",
  le: "LE",
};

const ComplianceDetails = () => {
  const history = useHistory();
  const { isin } = useParams();
  const [userState] = useUserStore();
  const [tableData, setTableData] = useState([]);
  const {
    showPopup,
    hidePopup,
    show,
    flag,
    text: PopupText,
  } = useSimplePopup();
  const [fileUrl, setFileUrl] = useState("");
  const [urlFlag, setUrlFlag] = useState(false);
  const [acceptRejectEnabled, setAcceptRejectEnabled] = useState(true);

  // Get the data for the table
  useEffect(() => {
    PendingApiClient.getComplianceDetails(isin)
      .then(({ response }) => {
        const {
          internalStatus,
          isin,
          issuerName,
          underlyingIsin,
          valueDate,
          le,
          docId,
        } = response.data;
        const { label: status } = INTERNAL_STATUSES.find(
          (st) => st.value === internalStatus
        );
        const date = moment(valueDate).format(DATE_FORMATS.DATE);

        const complianceData = {
          status,
          isin,
          issuerName,
          underlyingIsin,
          date,
          le,
          docId,
        };
        setTableData([complianceData]);
      })
      .catch((e) => {
        showPopup({ flag: FLAGS.ERROR, text: `${e}` });
      });
  }, []);

  useEffect(() => {
    if (urlFlag) openPdfWindow();
  }, [fileUrl]);

  const openPdfWindow = () => {
    window.open(fileUrl);
    setUrlFlag(false);
  };

  const handleApproveReject = async (action) => {
    try {
      await IssuancesInternalApiClient.processManualInteraction({
        isin,
        internalStatus: getInternalStatusValue(tableData[0].status),
        resultType: action,
      });
      setAcceptRejectEnabled(false);
      showPopup({ flag: FLAGS.SUCCESS, text: `${isin} updated successfully` });
    } catch (e) {
      showPopup({ flag: FLAGS.ERROR, text: `${e}` });
    }
  };

  const handleTCClick = async () => {
    const [complince] = tableData;
    IssuancesQueriesApiClient.getPdfUsingGET(complince.docId)
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          const file = new Blob([response.data], { type: "application/pdf" });
          var fileURLvalue = URL.createObjectURL(file);
          setUrlFlag(true);
          setFileUrl(fileURLvalue);
        }
      })
      .catch((e) => {
        showPopup({ flag: FLAGS.ERROR, text: `${e}` });
      });
  };

  const showAcceptRejectButtons = userState.userRoles.includes(
    "Clearstream Compliance"
  );

  const openIssuanceDetailsPage = (isin) => {
    history.push({
      pathname: "/issuanceDetails",
      state: {
        isin: isin,
        tabName: "compliance",
      },
    });
  };

  return (
    <React.Fragment>
      <StyledContainer>
        <SimplePopup
          showPopUp={show}
          responseText={PopupText}
          flagPopUp={flag}
          handleClose={hidePopup}
        />
        <StyledHeader>
          <BackButton />
          <Grid item className="paddingDetails">
            {showAcceptRejectButtons && (
              <Space>
                <Button
                  variant="contained"
                  color="primary"
                  id="det_button_openDialogR"
                  data-testid="btn_reject"
                  className="buttonStyle"
                  onClick={() => handleApproveReject("Success")}
                  disabled={!acceptRejectEnabled}
                  style={{ padding: "0 .8rem" }}
                >
                  Recommended
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  id="det_button_openDialogR"
                  data-testid="btn_reject"
                  className="buttonStyle"
                  onClick={() => handleApproveReject("Fail")}
                  disabled={!acceptRejectEnabled}
                  style={{ padding: "0 .8rem" }}
                >
                  Not recommended
                </Button>
              </Space>
            )}
          </Grid>
          <Grid align="right">
            <ImageButton
              tooltipText="T&C"
              placement="top"
              image={viewPdfActiveIcon}
              onClick={handleTCClick}
            />
          </Grid>
        </StyledHeader>
        <div style={{ marginTop: "15px" }}>
          <SimpleTable
            columns={columns}
            data={tableData}
            onRowClick={(row, index) => {
              openIssuanceDetailsPage(row.isin);
            }}
          />
        </div>
      </StyledContainer>
    </React.Fragment>
  );
};

export default ComplianceDetails;
