/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The US model module.
 * @module model/US
 * @version 1.0.3
 */
class US {
    /**
     * Constructs a new <code>US</code>.
     * @alias module:model/US
     */
    constructor() { 
        
        US.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>US</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/US} obj Optional instance to populate.
     * @return {module:model/US} The populated <code>US</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new US();

            if (data.hasOwnProperty('Factor871M')) {
                obj['Factor871M'] = ApiClient.convertToType(data['Factor871M'], 'Number');
            }
            if (data.hasOwnProperty('TefraCategory')) {
                obj['TefraCategory'] = ApiClient.convertToType(data['TefraCategory'], 'String');
            }
            if (data.hasOwnProperty('Classification1042S')) {
                obj['Classification1042S'] = ApiClient.convertToType(data['Classification1042S'], 'Boolean');
            }
            if (data.hasOwnProperty('Relevant871M')) {
                obj['Relevant871M'] = ApiClient.convertToType(data['Relevant871M'], 'Boolean');
            }
            if (data.hasOwnProperty('TefraStart')) {
                obj['TefraStart'] = ApiClient.convertToType(data['TefraStart'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>US</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>US</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['TefraCategory'] && !(typeof data['TefraCategory'] === 'string' || data['TefraCategory'] instanceof String)) {
            throw new Error("Expected the field `TefraCategory` to be a primitive type in the JSON string but got " + data['TefraCategory']);
        }

        return true;
    }


}



/**
 * 871m tax rate[128]
 * @member {Number} Factor871M
 */
US.prototype['Factor871M'] = undefined;

/**
 * Indicates the TEFRA rule under which the security is issued. This uses the ISO 20022 code where TEFRA category is C or D. If a TEFRA category is not explicitly mentioned, this will be defaulted to NOT_APP. Mandatory.[242]
 * @member {String} TefraCategory
 */
US.prototype['TefraCategory'] = undefined;

/**
 * Indicates if the instrument is US tax IRS1042S relevant[398]
 * @member {Boolean} Classification1042S
 */
US.prototype['Classification1042S'] = undefined;

/**
 * Denotes whether the securities to be issued are in scope of US Regulation 871m.[127]
 * @member {Boolean} Relevant871M
 */
US.prototype['Relevant871M'] = undefined;

/**
 * TEFRA Date from  relating to the U.S. Securities and Exchange Act of 1933 regulations on U.S. securities sold abroad[402]
 * @member {Date} TefraStart
 */
US.prototype['TefraStart'] = undefined;






export default US;

