import React, { useEffect, useState, useContext } from "react";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { StyledContainer, StyledHeader, StyledBody } from "./index.css";
import { Button, Grid } from "@material-ui/core";
import EllipisWithTooltip from "../../components/EllipsisWithTooltip";
import { useParams, useHistory } from "react-router-dom";
import arrowLeft from "../../assets/arrow-left.svg";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useUserStore } from "../../store/Store";
import { AppCommonContext } from "../../App";
import { getCountsBySection } from "../../utility";
import FileDowloadIcon from "@mui/icons-material/FileDownload";
import MessagePopup from "../ComplianceList/MessagePopup";
import moment from "moment";
import { getApprovementStatusLabel } from "../../utils/StatusUtils";
import { saveAs } from "file-saver";
import RecordsTable from "../ComplianceList/RecordsTable";
import { AccountsInternalApiClient, ComplianceApiClient } from "../../api";
import {
  blacklistColumns,
  accountItemColumns,
  whitelistColumns,
  issuerGreylistColumns,
  issuerWhitelistColumns,
} from "../../utils/columnUtils";
import { DATE_FORMATS } from "../../constants";
import { Space } from "antd";
import { convertDateTime } from "../../utils/dateUtils";

function Details(props) {
  const [userState] = useUserStore();
  const appContext = useContext(AppCommonContext);
  const { setIndividualPendingSectionCount } = appContext;
  const userId = sessionStorage.getItem("userId");
  const [data, setData] = useState(null);
  const [dataItems, setDataItems] = useState([]);
  const [protocolData, setProtocolData] = useState(null);
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [expandDetails, setExpandDetails] = useState(false);
  const [expandProtocol, setExpandProtocol] = useState(false);
  const { id, type } = useParams();
  const history = useHistory();
  const [allowAcceptRejectActions, setAllowAcceptRejectActions] =
    useState(false);
  const TYPE = type.replace("_", "-").toLocaleLowerCase();
  const [columns, setColumns] = useState([]);

  const [itemsCount, setItemsCount] = useState(0);

  useEffect(() => {
    if (
      TYPE === "whitelist" ||
      TYPE === "blacklist" ||
      TYPE === "greylist-issuer" ||
      TYPE === "whitelist-issuer"
    ) {
      getComplianceDetail();
      let cols =
        TYPE === "whitelist"
          ? whitelistColumns.filter(
              (obj) => obj.id !== "protocol" && obj.id !== "errorText"
            )
          : TYPE === "blacklist"
          ? blacklistColumns.filter(
              (obj) => obj.id !== "protocol" && obj.id !== "errorText"
            )
          : TYPE === "greylist-issuer"
          ? issuerGreylistColumns.filter(
              (obj) => obj.id !== "protocol" && obj.id !== "errorText"
            )
          : issuerWhitelistColumns.filter(
              (obj) => obj.id !== "protocol" && obj.id !== "errorText"
            );
      setColumns(cols);
    } else if (TYPE === "account") {
      let cols = accountItemColumns.filter((obj) => obj.id !== "protocol");
      setColumns(cols);
      getAccountDetails();
    } else if (TYPE === "redemption") {
      //TODO
    }
  }, []);

  const onPageChange = (page, pageSize) => {
    if (TYPE === "account") {
      getProtocolDetails(data?.status, page, pageSize);
    } else {
      getProtocolDetails(data?.statusName, page, pageSize);
    }
  };

  const getComplianceDetail = () => {
    ComplianceApiClient.getComplianceChangesetDetails(TYPE, id)
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          (response.status === 202 && response.data)
        ) {
          let userFlag = response.data.addedByUserId
            ? userId !== response.data.addedByUserId
            : userId !== response.data.uploaderUserId;
          let statusFlag =
            response.data.statusName !== "APPROVED" &&
            response.data.statusName !== "REJECTED";
          let isClearstreamComplianceUser = userState.userRoles.includes(
            "Clearstream Compliance"
          );
          let allowAcceptRejectActions =
            userFlag && statusFlag && !isClearstreamComplianceUser;
          setAllowAcceptRejectActions(allowAcceptRejectActions);
          setData(response.data);
          getProtocolDetails(response.data.statusName);
        }
      })
      .catch((error) => {
        setResponseText("Something went wrong. Please try later", error);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  const getAccountDetails = () => {
    AccountsInternalApiClient.getChangesetById(id)
      .then(({ response }) => {
        let userFlag = response.data.uploaderUserId
          ? userId !== response.data.uploaderUserId
          : userId !== response.data.initiatorUserId;
        let statusFlag =
          response.data.status !== "APPROVED" &&
          response.data.status !== "REJECTED";
        let allowAcceptRejectActions =
          userFlag &&
          statusFlag &&
          userState.userRoleRight.includes("View_Issuer_Static_Data");
        setAllowAcceptRejectActions(allowAcceptRejectActions);

        const accountDetails = {
          statusName: response.data.status,
          timestamp: response.data.uploadTimestamp,
          ...response.data,
        };
        setData(accountDetails);
        setItemsCount(response.data.count);
        getProtocolDetails(response.data.status);
      })
      .catch((error) => {
        setResponseText("Something went wrong.Please try later", error);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const exportChangeSet = () => {
    let axiosObject = "";
    if (
      TYPE === "whitelist" ||
      TYPE === "blacklist" ||
      TYPE === "greylist-issuer" ||
      TYPE === "whitelist-issuer"
    ) {
      axiosObject = ComplianceApiClient.exportComplianceChangeset(type, id);
    } else {
      if (TYPE === "account") {
        axiosObject = AccountsInternalApiClient.exportAccountChangeset(id);
      } else if (TYPE === "redemption") {
        axiosObject = null; //TODO
      }
    }

    if (axiosObject) {
      axiosObject
        .then((res) => {
          const response = res?.response || res;
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            var blob = new Blob([response.data]);
            saveAs(
              blob,
              `${type} changeset ${moment().format(DATE_FORMATS.DATE)}.csv`
            );
            setResponseText("Changeset downloaded successfully");
            setShowPopUp(true);
            setflagPopUp("success");
            setTimeout(() => {
              setShowPopUp(false);
              setResponseText("");
            }, 10000);
          } else {
            setResponseText("Unable to download. Please try later");
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setResponseText("Unable to download. Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  const updateStatus = (status) => {
    let axiosObject = "";
    if (
      TYPE === "whitelist" ||
      TYPE === "blacklist" ||
      TYPE === "greylist-issuer" ||
      TYPE === "whitelist-issuer"
    ) {
      axiosObject = ComplianceApiClient.updateComplianceChangesetById(
        type,
        id,
        status
      );
    } else {
      if (TYPE === "account") {
        axiosObject = AccountsInternalApiClient.updateChangesetById(id, status);
      } else if (TYPE === "redemption") {
        axiosObject = null; //TODO
      }
    }

    if (axiosObject) {
      axiosObject
        .then(async (res) => {
          const response = res?.response || res;
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            setResponseText("Status updated successfully");
            setShowPopUp(true);
            setflagPopUp("success");
            if (
              TYPE === "whitelist" ||
              TYPE === "blacklist" ||
              TYPE === "greylist-issuer" ||
              TYPE === "whitelist-issuer"
            ) {
              getComplianceDetail();
            } else if (TYPE === "account") {
              getAccountDetails();
            } else if (TYPE === "redemption") {
              //TODO
            }
            const individualPendingCount = await getCountsBySection();
            setIndividualPendingSectionCount(individualPendingCount);
            getProtocolDetails(status);

            setTimeout(() => {
              setShowPopUp(false);
              setResponseText("");
            }, 10000);
          } else {
            setResponseText("Unable to update status. Please try later");
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setResponseText("Unable to update status. Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  const getProtocolDetails = (changesetStatus, page = 1, pagesize = 100) => {
    const paginationParam = { page, pagesize };
    let axiosObject = "";
    if (
      TYPE === "whitelist" ||
      TYPE === "blacklist" ||
      TYPE === "greylist-issuer" ||
      TYPE === "whitelist-issuer"
    ) {
      axiosObject = ComplianceApiClient.getComplianceChangesetProtocol(
        TYPE,
        id,
        paginationParam
      );
    } else {
      if (TYPE === "account") {
        axiosObject = AccountsInternalApiClient.getChangesetRecords(
          id,
          paginationParam
        );
      } else if (TYPE === "redemption") {
        axiosObject = null; //TODO
      }
    }
    if (axiosObject) {
      axiosObject
        .then((res) => {
          const response = res?.response || res;
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            if (response.data) {
              if (
                response.data.items &&
                Array.isArray(response.data.items) &&
                response.data.count > 0
              ) {
                let cols =
                  TYPE === "whitelist"
                    ? whitelistColumns
                    : TYPE === "blacklist"
                    ? blacklistColumns
                    : TYPE === "greylist-issuer"
                    ? issuerGreylistColumns
                    : TYPE === "whitelist-issuer"
                    ? issuerWhitelistColumns
                    : TYPE === "account"
                    ? accountItemColumns
                    : TYPE === "redemption"
                    ? null //TODO
                    : null;
                cols =
                  changesetStatus === "APPROVED"
                    ? cols
                    : cols.filter(
                        (obj) => obj.id !== "protocol" && obj.id !== "errorText"
                      );
                setColumns(cols);
                let itemData = [];
                response.data.items.forEach((item) => {
                  let obj = { ...item };
                  obj.statusClass = getChangeTypeClass(item.changeType);
                  itemData.push(obj);
                });
                if (
                  TYPE === "whitelist" ||
                  TYPE === "blacklist" ||
                  TYPE === "greylist-issuer" ||
                  TYPE === "whitelist-issuer"
                ) {
                  itemData = itemData.map((item) => ({
                    ...item,
                    entryDate: moment(item.entryDate).format(DATE_FORMATS.DATE),
                  }));
                }
                setItemsCount(response.data.count);
                setDataItems(itemData);
                setProtocolData(response.data.protocolDetail);
              } else {
                setResponseText(
                  "Unable to fetch protocol details. Please try later"
                );
                setShowPopUp(true);
                setflagPopUp("error");
              }
            }
          } else {
            setResponseText(
              "Unable to fetch protocol details. Please try later"
            );
            setShowPopUp(true);
            setflagPopUp("error");
          }
        })
        .catch((error) => {
          setResponseText("Unable to fetch protocol details. Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        });
    }
  };

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  const displayDescription = (data) => {
    if (
      TYPE === "redemption" &&
      data.isin !== null &&
      Array.isArray(data.isin) &&
      data.isin.length > 0
    ) {
      return data.isin[0];
    } else {
      if (data.numOfRecords) {
        return data.numOfRecords === 0
          ? ""
          : data.numOfRecords === 1
          ? "1 record"
          : `${data.numOfRecords} records`;
      } else {
        return "";
      }
    }
  };

  const getChangeTypeClass = (changeType) => {
    return changeType
      ? changeType.toLocaleLowerCase() === "new"
        ? "greenColor"
        : "redColor"
      : "redColor";
  };

  const getType = () => {
    const type = data.type ? data.type : TYPE;
    return (
      type.charAt(0).toUpperCase() +
      type.replace("_", "-").toLocaleLowerCase().slice(1)
    );
  };

  const getColumns = (columns) => {
    return columns.filter(
      (obj) => obj.id !== "addedBy" && obj.id !== "reviewedBy"
    );
  };

  return (
    <React.Fragment>
      <StyledContainer id="details_container">
        <div>
          <MessagePopup
            responseText={responseText}
            showPopUp={showPopUp}
            flagPopUp={flagPopUp}
            closeErrorSuccessPopup={closeErrorSuccessPopup}
          />
        </div>
        <StyledHeader id="det_styhead_responseype">
          <Grid container>
            <Breadcrumbs
              separator={""}
              aria-label="breadcrumb"
              className="breadcrumbs"
              id="cr_breadcrumbs"
            >
              <img
                src={arrowLeft}
                onClick={() => history.goBack()}
                data-testid="arrowLeft"
                id="cr_arrowLeft"
                alt="arrow"
              />
              <Link
                color="inherit"
                onClick={() => history.goBack()}
                id="cr_setcr"
              >
                <Typography
                  variant="subtitle2"
                  className="navigationItem"
                  data-testid="breadcrumbs_text"
                  id="cr_text"
                >
                  {" "}
                  Back{" "}
                </Typography>
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid container id="det_grid_container">
            <Grid
              item
              xs={3}
              className="paddingDetails"
              id="det_grid_processStatus"
            >
              <Space>
                <Button
                  variant="contained"
                  id="det_button_openDialogA"
                  data-testid="btn_accept"
                  disabled={!allowAcceptRejectActions}
                  className="buttonStyle"
                  color="primary"
                  onClick={() => updateStatus("APPROVED")}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  id="det_button_openDialogR"
                  disabled={!allowAcceptRejectActions}
                  data-testid="btn_reject"
                  className="buttonStyle"
                  onClick={() => updateStatus("REJECTED")}
                >
                  Reject
                </Button>
              </Space>
            </Grid>
            <Grid item xs={7} />
            <Grid
              item
              xs={2}
              className="paddingDetails"
              id="det_grid_processStatus"
              align="end"
            >
              <Button
                variant="contained"
                id="det_button_openDialogA"
                data-testid="btn_export"
                color="primary"
                className="btnExport"
                onClick={() => exportChangeSet()}
              >
                {" "}
                <FileDowloadIcon
                  className="exportIcon"
                  color="white"
                /> Export{" "}
              </Button>
            </Grid>
          </Grid>
        </StyledHeader>
        {data && (
          <StyledBody id="det_clear_styledBody">
            <Accordion
              className="accordianStyle"
              id="det_clear_accordion"
              expanded={expandDetails}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
                onClick={() => setExpandDetails(!expandDetails)}
              >
                <Typography variant="h3" id="det_clear_eligibility">
                  Details - {getType()} #{data.id}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className="accordionContent"
                id="det_clear_accDetials"
              >
                <Grid
                  item
                  xs={12}
                  container
                  className="padding3"
                  id="det_clear_el_gridcont0"
                >
                  <Grid
                    item
                    xs={4}
                    container
                    className="padding4 MuiGrid-spacing-xs-1"
                    direction="column"
                    spacing={1}
                    id="det_clear_el_grid0"
                  >
                    <Grid item xs id="det_clear_el_val">
                      <Typography variant="h5" id="det_clear_typo_val">
                        Type
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" id="det_clear_el_isin">
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" id="det_clear_el_isin">
                        Timestamp
                      </Typography>
                    </Grid>
                    <Grid item xs id="det_clear_el_app">
                      <Typography variant="h5" id="det_clear_typo_app">
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs id="det_clear_el_app">
                      <Typography variant="h5" id="det_clear_typo_app">
                        Added By
                      </Typography>
                    </Grid>
                    {(data.statusName === "APPROVED" ||
                      data.statusName === "REJECTED") && (
                      <Grid item xs id="det_clear_el_app">
                        <Typography variant="h5" id="det_clear_typo_app">
                          Reviewed By
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="column"
                    align="right"
                    spacing={1}
                  >
                    <Grid item xs id="det_clearB_grid_val">
                      <Typography variant="body2" id="det_clearB_typo_val">
                        <EllipisWithTooltip>{getType()}</EllipisWithTooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs d="det_clearB_grid_isin">
                      <Typography variant="body2" id="det_clearB_typo_isin">
                        <EllipisWithTooltip>
                          {getApprovementStatusLabel(data.statusName)}
                        </EllipisWithTooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs d="det_clearB_grid_isin">
                      <Typography variant="body2" id="det_clearB_typo_isin">
                        <EllipisWithTooltip>
                          {data.addedAt
                            ? convertDateTime(
                                data.addedAt,
                                DATE_FORMATS.DATE_AND_TIME
                              )
                            : convertDateTime(
                                data.timestamp,
                                DATE_FORMATS.DATE_AND_TIME
                              )}
                        </EllipisWithTooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs id="det_clearB_grid_law">
                      <Typography variant="body2" id="det_clearB_typo_law">
                        <EllipisWithTooltip>
                          {data && displayDescription(data)}
                        </EllipisWithTooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs id="det_clearB_grid_law">
                      <Typography variant="body2" id="det_clearB_typo_law">
                        <EllipisWithTooltip>
                          {data && data.uploaderUserId && TYPE === "account"
                            ? `${data.uploaderUserName} (${data.uploaderUserId})`
                            : `${data.addedByUserName} (${data.addedByUserId})`}
                        </EllipisWithTooltip>
                      </Typography>
                    </Grid>
                    {(data.statusName === "APPROVED" ||
                      data.statusName === "REJECTED") && (
                      <Grid item xs id="det_clearB_grid_law">
                        <Typography variant="body2" id="det_clearB_typo_law">
                          <EllipisWithTooltip>
                            {data && data.reviewedByUserId && TYPE !== "account"
                              ? `${data.reviewedByUserName} (${data.reviewedByUserId})`
                              : `${data.reviewerUserName} (${data.reviewerUserId})`}
                          </EllipisWithTooltip>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {data?.statusName === "APPROVED" && protocolData && (
              <Accordion
                className="accordianStyle"
                id="det_clear_accordion"
                expanded={expandProtocol}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => setExpandProtocol(!expandProtocol)}
                >
                  <Typography variant="h3" id="det_clear_eligibility">
                    Protocol
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="accordionContent"
                  id="det_clear_accDetials"
                >
                  <Grid
                    item
                    xs={12}
                    container
                    className="padding3"
                    id="det_clear_el_gridcont0"
                  >
                    <Grid item xs id="det_clearB_grid_val">
                      <Typography variant="body2" id="det_clearB_typo_val">
                        <EllipisWithTooltip>{protocolData}</EllipisWithTooltip>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            <div className="accordianStyle">
              <RecordsTable
                data={dataItems}
                columns={getColumns(columns)}
                onPageChange={onPageChange}
                itemsCount={itemsCount}
              />
            </div>
          </StyledBody>
        )}
      </StyledContainer>
    </React.Fragment>
  );
}

export default Details;
