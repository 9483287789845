/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Reoffer from './Reoffer';

/**
 * The Pricing model module.
 * @module model/Pricing
 * @version 1.0.3
 */
class Pricing {
    /**
     * Constructs a new <code>Pricing</code>.
     * @alias module:model/Pricing
     */
    constructor() { 
        
        Pricing.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Pricing</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Pricing} obj Optional instance to populate.
     * @return {module:model/Pricing} The populated <code>Pricing</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Pricing();

            if (data.hasOwnProperty('PriceQuotationType')) {
                obj['PriceQuotationType'] = ApiClient.convertToType(data['PriceQuotationType'], 'String');
            }
            if (data.hasOwnProperty('SpreadBenchmarkRatio')) {
                obj['SpreadBenchmarkRatio'] = ApiClient.convertToType(data['SpreadBenchmarkRatio'], 'Number');
            }
            if (data.hasOwnProperty('BenchmarkReference')) {
                obj['BenchmarkReference'] = ApiClient.convertToType(data['BenchmarkReference'], 'String');
            }
            if (data.hasOwnProperty('BenchmarkReferenceYield')) {
                obj['BenchmarkReferenceYield'] = ApiClient.convertToType(data['BenchmarkReferenceYield'], 'Number');
            }
            if (data.hasOwnProperty('CommissionPercentage')) {
                obj['CommissionPercentage'] = ApiClient.convertToType(data['CommissionPercentage'], 'Number');
            }
            if (data.hasOwnProperty('MidSwapYield')) {
                obj['MidSwapYield'] = ApiClient.convertToType(data['MidSwapYield'], 'Number');
            }
            if (data.hasOwnProperty('Agio')) {
                obj['Agio'] = ApiClient.convertToType(data['Agio'], 'Number');
            }
            if (data.hasOwnProperty('BIDOnly')) {
                obj['BIDOnly'] = ApiClient.convertToType(data['BIDOnly'], 'Boolean');
            }
            if (data.hasOwnProperty('Fees')) {
                obj['Fees'] = ApiClient.convertToType(data['Fees'], 'Number');
            }
            if (data.hasOwnProperty('Reoffer')) {
                obj['Reoffer'] = Reoffer.constructFromObject(data['Reoffer']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Pricing</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Pricing</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['PriceQuotationType'] && !(typeof data['PriceQuotationType'] === 'string' || data['PriceQuotationType'] instanceof String)) {
            throw new Error("Expected the field `PriceQuotationType` to be a primitive type in the JSON string but got " + data['PriceQuotationType']);
        }
        // ensure the json data is a string
        if (data['BenchmarkReference'] && !(typeof data['BenchmarkReference'] === 'string' || data['BenchmarkReference'] instanceof String)) {
            throw new Error("Expected the field `BenchmarkReference` to be a primitive type in the JSON string but got " + data['BenchmarkReference']);
        }
        // validate the optional field `Reoffer`
        if (data['Reoffer']) { // data not null
          Reoffer.validateJSON(data['Reoffer']);
        }

        return true;
    }


}



/**
 * Indicates whether acrued interest is included or excluded from prices on the transaction.[208]
 * @member {String} PriceQuotationType
 */
Pricing.prototype['PriceQuotationType'] = undefined;

/**
 * The all in issuer spread of the bond to be issued over the benchmark reference.[209]
 * @member {Number} SpreadBenchmarkRatio
 */
Pricing.prototype['SpreadBenchmarkRatio'] = undefined;

/**
 * The benchmark being used as a reference security for pricing purposes.[210]
 * @member {String} BenchmarkReference
 */
Pricing.prototype['BenchmarkReference'] = undefined;

/**
 * The yield of the benchmark being used as a reference security for pricing purposes.[211]
 * @member {Number} BenchmarkReferenceYield
 */
Pricing.prototype['BenchmarkReferenceYield'] = undefined;

/**
 * The commission percentage paid to the dealer(s) for this transaction.[212]
 * @member {Number} CommissionPercentage
 */
Pricing.prototype['CommissionPercentage'] = undefined;

/**
 * The midswap yield for the currency of the notes being issued.[213]
 * @member {Number} MidSwapYield
 */
Pricing.prototype['MidSwapYield'] = undefined;

/**
 * Difference between intrinsic value and market value of a bond[129]
 * @member {Number} Agio
 */
Pricing.prototype['Agio'] = undefined;

/**
 * Indicator whether a market maker is buying back only (no ask price)[130]
 * @member {Boolean} BIDOnly
 */
Pricing.prototype['BIDOnly'] = undefined;

/**
 * Indicates aggregate amount of fees[131]
 * @member {Number} Fees
 */
Pricing.prototype['Fees'] = undefined;

/**
 * @member {module:model/Reoffer} Reoffer
 */
Pricing.prototype['Reoffer'] = undefined;






export default Pricing;

