import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import RowElement from "../RowElement";

const Calendar = ({ handleChange, expandState, isDesktop, issuanceObj }) => (
  <Accordion
    className="accordianStyle"
    id="cp_cal_acc4"
    expanded={expandState["panel4"]}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel4a-content"
      id="panel4a-header"
      onClick={() => {
        handleChange("panel4");
      }}
    >
      <Typography variant="h3" id="cp_cal_typo">
        Calendar
      </Typography>
    </AccordionSummary>

    <AccordionDetails className="accordionContent" id="cp_cal_accContent4">
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement label={"Issue Date"} value={issuanceObj.issueDate} />
        <RowElement label={"Value Date"} value={issuanceObj.valueDate} />
        <RowElement
          label={"First Trading Date"}
          value={issuanceObj.firstTradingDate}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement
          label={"First Interest Payment Date"}
          value={issuanceObj.firstInterestPaymentDate}
        />
        <RowElement
          label={"Interest Period From"}
          value={issuanceObj.interestFrom}
        />
        <RowElement
          label={"Interest Period To"}
          value={issuanceObj.interestTo}
        />
      </Grid>
      <span className="divider1" />
      <Grid
        container
        spacing={1}
        xs={isDesktop ? 4 : 12}
        className="paddingColumn"
      >
        <RowElement label={"Maturity Date"} value={issuanceObj.maturityDate} />
        <RowElement label={"Payment Date"} value={issuanceObj.paymentDate} />
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default Calendar;
