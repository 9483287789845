import React, { useEffect, useState } from "react";
import { ViewDetailsContainer } from "../index.css";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUserStore } from "../../../store/Store";
import FileDowloadIcon from "@mui/icons-material/FileDownload";
import MessagePopup from "../MessagePopup";
import RecordsTable from "../RecordsTable";
import { whitelistColumns } from "../../../utils/columnUtils";
import { useRecords } from "../hooks/useRecords";
import { Space } from "antd";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "480px",
    height: "320px",
  },
  reportModal: {
    width: "80%",
    height: "400px",
  },
}));

const WhiteLists = () => {
  const classes = useStyles();
  const [userState] = useUserStore();
  const [data, setData] = useState([]);
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [columns, setColumns] = useState([]);

  const { exportCurrentRecordsList, changePage, itemsCount, loading } =
    useRecords(
      "whitelist",
      setData,
      setResponseText,
      setShowPopUp,
      setflagPopUp,
      userState
    );

  useEffect(() => {
    let cols = whitelistColumns.filter(
      (obj) =>
        obj.id !== "changeType" &&
        obj.id !== "protocol" &&
        obj.id !== "errorText"
    );
    setColumns(cols);
  }, []);

  const exportCurrentWhitelist = () => exportCurrentRecordsList("whitelist");

  const handleChangePage = (pageNumber, pageSize) => {
    const newNumOfPages = Math.ceil(itemsCount / pageSize);
    const newPage = pageNumber > newNumOfPages ? newNumOfPages : pageNumber;

    changePage("whitelist", newPage, pageSize);
  };

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };

  return (
    <React.Fragment>
      <ViewDetailsContainer>
        <MessagePopup
          responseText={responseText}
          showPopUp={showPopUp}
          flagPopUp={flagPopUp}
          closeErrorSuccessPopup={closeErrorSuccessPopup}
        />
        <Grid container>
          <Grid item xs={12} align="end">
            <Space>
              <Button
                variant="outlined"
                id="btn_downloadloadWhiteList"
                color="primary"
                className="btnUploadWhiteList"
                onClick={exportCurrentWhitelist}
              >
                {" "}
                <FileDowloadIcon /> Export Current Whitelist
              </Button>
            </Space>
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      <RecordsTable
        loading={loading}
        columns={columns}
        data={data}
        onPageChange={handleChangePage}
        itemsCount={itemsCount}
      />
    </React.Fragment>
  );
};

export default WhiteLists;
