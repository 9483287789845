/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import StatusHistoryLog from './StatusHistoryLog';

/**
 * The StatusHistory model module.
 * @module model/StatusHistory
 * @version 1.0.3
 */
class StatusHistory {
    /**
     * Constructs a new <code>StatusHistory</code>.
     * @alias module:model/StatusHistory
     */
    constructor() { 
        
        StatusHistory.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StatusHistory</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StatusHistory} obj Optional instance to populate.
     * @return {module:model/StatusHistory} The populated <code>StatusHistory</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatusHistory();

            if (data.hasOwnProperty('maturityDate')) {
                obj['maturityDate'] = ApiClient.convertToType(data['maturityDate'], 'Date');
            }
            if (data.hasOwnProperty('issuedNominalAmount')) {
                obj['issuedNominalAmount'] = ApiClient.convertToType(data['issuedNominalAmount'], 'String');
            }
            if (data.hasOwnProperty('statusHistory')) {
                obj['statusHistory'] = ApiClient.convertToType(data['statusHistory'], [StatusHistoryLog]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>StatusHistory</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>StatusHistory</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['issuedNominalAmount'] && !(typeof data['issuedNominalAmount'] === 'string' || data['issuedNominalAmount'] instanceof String)) {
            throw new Error("Expected the field `issuedNominalAmount` to be a primitive type in the JSON string but got " + data['issuedNominalAmount']);
        }
        if (data['statusHistory']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['statusHistory'])) {
                throw new Error("Expected the field `statusHistory` to be an array in the JSON data but got " + data['statusHistory']);
            }
            // validate the optional field `statusHistory` (array)
            for (const item of data['statusHistory']) {
                StatusHistoryLog.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * maturity date
 * @member {Date} maturityDate
 */
StatusHistory.prototype['maturityDate'] = undefined;

/**
 * issuedNominalAmount
 * @member {String} issuedNominalAmount
 */
StatusHistory.prototype['issuedNominalAmount'] = undefined;

/**
 * @member {Array.<module:model/StatusHistoryLog>} statusHistory
 */
StatusHistory.prototype['statusHistory'] = undefined;






export default StatusHistory;

