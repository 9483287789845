/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Submitter model module.
 * @module model/Submitter
 * @version 1.0.3
 */
class Submitter {
    /**
     * Constructs a new <code>Submitter</code>.
     * @alias module:model/Submitter
     */
    constructor() { 
        
        Submitter.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Submitter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Submitter} obj Optional instance to populate.
     * @return {module:model/Submitter} The populated <code>Submitter</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Submitter();

            if (data.hasOwnProperty('Subject')) {
                obj['Subject'] = ApiClient.convertToType(data['Subject'], 'String');
            }
            if (data.hasOwnProperty('ParticipantId')) {
                obj['ParticipantId'] = ApiClient.convertToType(data['ParticipantId'], 'String');
            }
            if (data.hasOwnProperty('ParticipantRole')) {
                obj['ParticipantRole'] = ApiClient.convertToType(data['ParticipantRole'], 'String');
            }
            if (data.hasOwnProperty('Timestamp')) {
                obj['Timestamp'] = ApiClient.convertToType(data['Timestamp'], 'Date');
            }
            if (data.hasOwnProperty('SourceIP')) {
                obj['SourceIP'] = ApiClient.convertToType(data['SourceIP'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Submitter</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Submitter</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['Subject'] && !(typeof data['Subject'] === 'string' || data['Subject'] instanceof String)) {
            throw new Error("Expected the field `Subject` to be a primitive type in the JSON string but got " + data['Subject']);
        }
        // ensure the json data is a string
        if (data['ParticipantId'] && !(typeof data['ParticipantId'] === 'string' || data['ParticipantId'] instanceof String)) {
            throw new Error("Expected the field `ParticipantId` to be a primitive type in the JSON string but got " + data['ParticipantId']);
        }
        // ensure the json data is a string
        if (data['ParticipantRole'] && !(typeof data['ParticipantRole'] === 'string' || data['ParticipantRole'] instanceof String)) {
            throw new Error("Expected the field `ParticipantRole` to be a primitive type in the JSON string but got " + data['ParticipantRole']);
        }
        // ensure the json data is a string
        if (data['SourceIP'] && !(typeof data['SourceIP'] === 'string' || data['SourceIP'] instanceof String)) {
            throw new Error("Expected the field `SourceIP` to be a primitive type in the JSON string but got " + data['SourceIP']);
        }

        return true;
    }


}



/**
 * 
 * @member {String} Subject
 */
Submitter.prototype['Subject'] = undefined;

/**
 * 
 * @member {String} ParticipantId
 */
Submitter.prototype['ParticipantId'] = undefined;

/**
 * 
 * @member {String} ParticipantRole
 */
Submitter.prototype['ParticipantRole'] = undefined;

/**
 * 
 * @member {Date} Timestamp
 */
Submitter.prototype['Timestamp'] = undefined;

/**
 * 
 * @member {String} SourceIP
 */
Submitter.prototype['SourceIP'] = undefined;






export default Submitter;

