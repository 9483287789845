import styled from "styled-components";
import { Container, Card, DialogActions } from "@material-ui/core";

export const StyledContainer = styled(Container)`
  padding-top: 3%;
  .popup {
    position: absolute;
    width: 29%;
    height: 4em;
    left: 65%;
    top: 75px;
  }
  .exportAuditReport {
    font-size: 17px;
    font-weight: bolder;
    padding-bottom: 4%;
    color: #000000;
  }
`;

export const StyledCard = styled(Card)`
  margin-top: 3%;
  background-color: #ffffff;
  padding-left: 48px;
  padding-right: 40px;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 1);
  border-radius: 10px;
  .ant-picker {
    border-radius: 17px;
    width: 100%;
  }
  .ant-picker-clear {
    display: none;
  }
  .dataRange {
    color: #000000;
    padding-top: 30px;
  }
  .fromTo {
    padding-top: 8px;
    color: #00a5c0;
  }
  .padding1 {
    padding-top: 28px;
  }
  .paddingTo {
    padding-left: 15px;
  }
  .paddingToR {
    padding-right: 0px;
  }
  .padding2 {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .cancel {
    color: #00a7c4;
    width: 8%;
    margin-right: 5px;
  }
  .export {
    margin-right: 0px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding-top: 7%;
  padding-right: 22%;
`;
