import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LogBody } from "../index.css";

/*
 * This array is for table column headers
 */
const columns = [
  { id: "processDateTime", label: "Process Date & Time" },
  // { id: "businessProcessName", label: "Process Name" },
  { id: "overallStatus", label: "Overall Status" },
  { id: "processingStatus", label: "Processing Status" },
  { id: "internalProcessingStatus", label: "Internal Status" },
];

function ProcessLog(props) {
  return (
    <React.Fragment>
      <LogBody>
        {props.processLogData.length !== 0 && (
          <TableContainer className="tableheight">
            <Table aria-label="sticky table" stickyHeader>
              <TableHead>
                <TableRow className="bgcolour">
                  {columns?.map((row) => {
                    if (
                      row.id === "internalProcessingStatus" &&
                      !props.showInternalStatus
                    ) {
                      return;
                    }
                    return (
                      <TableCell width="20%">
                        <Typography variant="h5"> {row.label}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.processLogData.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography>{row.processDateTime}</Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography>{row.businessProcessName}</Typography>
                      </TableCell> */}
                      <TableCell>
                        <Typography>{row.overallStatus}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.processingStatus}</Typography>
                      </TableCell>
                      {props.showInternalStatus && (
                        <TableCell>
                          <Typography>
                            {row.internalProcessingStatus}
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </LogBody>
    </React.Fragment>
  );
}

export default ProcessLog;
