/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The IssuanceBlotterItem model module.
 * @module model/IssuanceBlotterItem
 * @version 1.0.3
 */
class IssuanceBlotterItem {
    /**
     * Constructs a new <code>IssuanceBlotterItem</code>.
     * @alias module:model/IssuanceBlotterItem
     * @param instrumentType {String} Instrument Type
     * @param isin {String} ISIN
     * @param issuerName {String} Issuer Name
     * @param pendingSince {Date} Pending Since
     * @param processStatus {String} Process Status
     * @param valueDate {Date} Value Date
     */
    constructor(instrumentType, isin, issuerName, pendingSince, processStatus, valueDate) { 
        
        IssuanceBlotterItem.initialize(this, instrumentType, isin, issuerName, pendingSince, processStatus, valueDate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, instrumentType, isin, issuerName, pendingSince, processStatus, valueDate) { 
        obj['instrumentType'] = instrumentType;
        obj['isin'] = isin;
        obj['issuerName'] = issuerName;
        obj['pendingSince'] = pendingSince;
        obj['processStatus'] = processStatus;
        obj['valueDate'] = valueDate;
    }

    /**
     * Constructs a <code>IssuanceBlotterItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/IssuanceBlotterItem} obj Optional instance to populate.
     * @return {module:model/IssuanceBlotterItem} The populated <code>IssuanceBlotterItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new IssuanceBlotterItem();

            if (data.hasOwnProperty('instrumentType')) {
                obj['instrumentType'] = ApiClient.convertToType(data['instrumentType'], 'String');
            }
            if (data.hasOwnProperty('isin')) {
                obj['isin'] = ApiClient.convertToType(data['isin'], 'String');
            }
            if (data.hasOwnProperty('issuerName')) {
                obj['issuerName'] = ApiClient.convertToType(data['issuerName'], 'String');
            }
            if (data.hasOwnProperty('pendingSince')) {
                obj['pendingSince'] = ApiClient.convertToType(data['pendingSince'], 'Date');
            }
            if (data.hasOwnProperty('processStatus')) {
                obj['processStatus'] = ApiClient.convertToType(data['processStatus'], 'String');
            }
            if (data.hasOwnProperty('valueDate')) {
                obj['valueDate'] = ApiClient.convertToType(data['valueDate'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>IssuanceBlotterItem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>IssuanceBlotterItem</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of IssuanceBlotterItem.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['instrumentType'] && !(typeof data['instrumentType'] === 'string' || data['instrumentType'] instanceof String)) {
            throw new Error("Expected the field `instrumentType` to be a primitive type in the JSON string but got " + data['instrumentType']);
        }
        // ensure the json data is a string
        if (data['isin'] && !(typeof data['isin'] === 'string' || data['isin'] instanceof String)) {
            throw new Error("Expected the field `isin` to be a primitive type in the JSON string but got " + data['isin']);
        }
        // ensure the json data is a string
        if (data['issuerName'] && !(typeof data['issuerName'] === 'string' || data['issuerName'] instanceof String)) {
            throw new Error("Expected the field `issuerName` to be a primitive type in the JSON string but got " + data['issuerName']);
        }
        // ensure the json data is a string
        if (data['processStatus'] && !(typeof data['processStatus'] === 'string' || data['processStatus'] instanceof String)) {
            throw new Error("Expected the field `processStatus` to be a primitive type in the JSON string but got " + data['processStatus']);
        }

        return true;
    }


}

IssuanceBlotterItem.RequiredProperties = ["instrumentType", "isin", "issuerName", "pendingSince", "processStatus", "valueDate"];

/**
 * Instrument Type
 * @member {String} instrumentType
 */
IssuanceBlotterItem.prototype['instrumentType'] = undefined;

/**
 * ISIN
 * @member {String} isin
 */
IssuanceBlotterItem.prototype['isin'] = undefined;

/**
 * Issuer Name
 * @member {String} issuerName
 */
IssuanceBlotterItem.prototype['issuerName'] = undefined;

/**
 * Pending Since
 * @member {Date} pendingSince
 */
IssuanceBlotterItem.prototype['pendingSince'] = undefined;

/**
 * Process Status
 * @member {String} processStatus
 */
IssuanceBlotterItem.prototype['processStatus'] = undefined;

/**
 * Value Date
 * @member {Date} valueDate
 */
IssuanceBlotterItem.prototype['valueDate'] = undefined;






export default IssuanceBlotterItem;

