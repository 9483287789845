const instrumentTypes = [
  // RSP Eusipa IDs
  { label: "Uncapped Capital Protection", value: "1100" },
  { label: "Capital Protection with Coupon", value: "1140" },
  { label: "Spread Warrants", value: "2110" },
  { label: "Capped Bonus Certificate", value: "1250" },
  { label: "Discount Certificate", value: "1200" },
  { label: "Reverse Convertible", value: "1220" },
  { label: "Barrier Reverse Convertible", value: "1230" },
  { label: "Double Knock-Out Warrants", value: "2230" },
  { label: "Outperformance Certificate", value: "1310" },
  { label: "Capped Outperformance Certificate", value: "1240" },
  { label: "Open End Knock-Out Warrants", value: "2205" },
  { label: "Mini Futures", value: "2210" },
  { label: "Tracker Certificate", value: "1300" },
  { label: "Credit Linked Note Linear", value: "1440" },
  { label: "Constant Leverage Certificate", value: "2300" },
  { label: "Express Certificates", value: "1260" },
  { label: "Bonus Certificates", value: "1320" },
  { label: "Knock-Out Warrants", value: "2200" },
  { label: "Warrants", value: "2100" },
  // DCM instrument types
  { label: "Medium Term Note", value: "Medium-term notes" },
  { label: "Medium Term Note", value: "Money market instruments" },
  { label: "Commercial Paper", value: "Commercial papers" },
  { label: "Bond", value: "Bonds" },
];

export { instrumentTypes };
