import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("jwtToken");

    if (token) {
      config.headers["Authorization"] =
        `Bearer ${sessionStorage.getItem("jwtToken")}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axios;
