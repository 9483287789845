/*
 * Proving some initial user states
 */
export const USER_INITIAL_STATE = {
  isAuthenticated: false, // variable to check if user if authenticated (logged in)
  idToken: "", // variable to store jwt token
  expirationOfTokenInSec: 0, // variable to store expiry date-time of token
  userRoles: [], // variable to store role of a logged in user (Future scope)
  userRoleRight: [], // variable to store right of a logged in user depending upon the role (Future scope)
};
