/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class BatchStatusDetail.
* @enum {}
* @readonly
*/
export default class BatchStatusDetail {
    
        /**
         * value: "NEW"
         * @const
         */
        "NEW" = "NEW";

    
        /**
         * value: "PROCESSING"
         * @const
         */
        "PROCESSING" = "PROCESSING";

    
        /**
         * value: "SUCCESS"
         * @const
         */
        "SUCCESS" = "SUCCESS";

    
        /**
         * value: "RESCHEDULED"
         * @const
         */
        "RESCHEDULED" = "RESCHEDULED";

    
        /**
         * value: "FAIL"
         * @const
         */
        "FAIL" = "FAIL";

    

    /**
    * Returns a <code>BatchStatusDetail</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/BatchStatusDetail} The enum <code>BatchStatusDetail</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

