/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import RolesResponse from '../model/RolesResponse';
import TokenResponse from '../model/TokenResponse';

/**
* Authentication service.
* @module api/AuthenticationApi
* @version 1.0.3
*/
export default class AuthenticationApi {

    /**
    * Constructs a new AuthenticationApi. 
    * @alias module:api/AuthenticationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getJwtTokenUsingGET operation.
     * @callback module:api/AuthenticationApi~getJwtTokenUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TokenResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * To get jwt token which will be used by all the api's for the authentication.
     * @param {String} userId UserId of the user. Example : dzbank_iao
     * @param {String} userOrg Organization of the user. Example : DZBank
     * @param {module:api/AuthenticationApi~getJwtTokenUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TokenResponse}
     */
    getJwtTokenUsingGET(userId, userOrg, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling getJwtTokenUsingGET");
      }
      // verify the required parameter 'userOrg' is set
      if (userOrg === undefined || userOrg === null) {
        throw new Error("Missing the required parameter 'userOrg' when calling getJwtTokenUsingGET");
      }

      let pathParams = {
        'userId': userId,
        'userOrg': userOrg
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*', 'application/json'];
      let returnType = TokenResponse;
      return this.apiClient.callApi(
        '/di/v1/jwt/{userOrg}/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRolesUsingGET operation.
     * @callback module:api/AuthenticationApi~getRolesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RolesResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the roles corresponding to an user
     * @param {module:api/AuthenticationApi~getRolesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RolesResponse}
     */
    getRolesUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RolesResponse;
      return this.apiClient.callApi(
        '/di/v1/roles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
