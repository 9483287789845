/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BusinessValidationError from '../model/BusinessValidationError';
import Error from '../model/Error';
import ManualInteractionRequest from '../model/ManualInteractionRequest';

/**
* IssuancesInternal service.
* @module api/IssuancesInternalApi
* @version 1.0.3
*/
export default class IssuancesInternalApi {

    /**
    * Constructs a new IssuancesInternalApi. 
    * @alias module:api/IssuancesInternalApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the processManualInteraction operation.
     * @callback module:api/IssuancesInternalApi~processManualInteractionCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * This API is used to handle manual interaction messages.
     * @param {module:model/ManualInteractionRequest} ManualInteractionRequest Manual interaction request.
     * @param {module:api/IssuancesInternalApi~processManualInteractionCallback} callback The callback function, accepting three arguments: error, data, response
     */
    processManualInteraction(ManualInteractionRequest, callback) {
      let postBody = ManualInteractionRequest;
      // verify the required parameter 'ManualInteractionRequest' is set
      if (ManualInteractionRequest === undefined || ManualInteractionRequest === null) {
        throw new Error("Missing the required parameter 'ManualInteractionRequest' when calling processManualInteraction");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/di/v1/issuances/manual-interaction', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
