/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SecuritiesTransfer from './SecuritiesTransfer';

/**
 * The SecuritiesSettlement model module.
 * @module model/SecuritiesSettlement
 * @version 1.0.3
 */
class SecuritiesSettlement {
    /**
     * Constructs a new <code>SecuritiesSettlement</code>.
     * @alias module:model/SecuritiesSettlement
     */
    constructor() { 
        
        SecuritiesSettlement.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SecuritiesSettlement</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SecuritiesSettlement} obj Optional instance to populate.
     * @return {module:model/SecuritiesSettlement} The populated <code>SecuritiesSettlement</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SecuritiesSettlement();

            if (data.hasOwnProperty('Quantity')) {
                obj['Quantity'] = ApiClient.convertToType(data['Quantity'], 'Number');
            }
            if (data.hasOwnProperty('SettlementDate')) {
                obj['SettlementDate'] = ApiClient.convertToType(data['SettlementDate'], 'Date');
            }
            if (data.hasOwnProperty('Unit')) {
                obj['Unit'] = ApiClient.convertToType(data['Unit'], 'Number');
            }
            if (data.hasOwnProperty('SecuritiesTransfer')) {
                obj['SecuritiesTransfer'] = ApiClient.convertToType(data['SecuritiesTransfer'], [SecuritiesTransfer]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SecuritiesSettlement</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SecuritiesSettlement</code>.
     */
    static validateJSON(data) {
        if (data['SecuritiesTransfer']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['SecuritiesTransfer'])) {
                throw new Error("Expected the field `SecuritiesTransfer` to be an array in the JSON data but got " + data['SecuritiesTransfer']);
            }
            // validate the optional field `SecuritiesTransfer` (array)
            for (const item of data['SecuritiesTransfer']) {
                SecuritiesTransfer.validateJsonObject(item);
            };
        }

        return true;
    }


}



/**
 * Number of securities which are settled for redemption[389]
 * @member {Number} Quantity
 */
SecuritiesSettlement.prototype['Quantity'] = undefined;

/**
 * Date at which redemption of the instrument is settled[393]
 * @member {Date} SettlementDate
 */
SecuritiesSettlement.prototype['SettlementDate'] = undefined;

/**
 * Used in DIML payment file, AutomaticRedemptionProcess Field 6  Shares Amount[403]
 * @member {Number} Unit
 */
SecuritiesSettlement.prototype['Unit'] = undefined;

/**
 * Model: SecuritiesTransfer
 * @member {Array.<module:model/SecuritiesTransfer>} SecuritiesTransfer
 */
SecuritiesSettlement.prototype['SecuritiesTransfer'] = undefined;






export default SecuritiesSettlement;

