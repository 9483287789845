import {
  LOGIN_SUCCESS,
  SIGN_OUT_SUCCESS,
} from "../actionTypes/UserActionTypes";

/*
 * This method is called when the user has successfully logged in and some modifications required in user initial state via userDispatch function
 */
export const loginSuccessful = (userData) => {
  return {
    type: LOGIN_SUCCESS,
    userData,
  };
};

/*
 * This method is called when the user has successfully logged out (in case of MockIAM)
 */
export const logout = () => {
  return {
    type: SIGN_OUT_SUCCESS,
  };
};
