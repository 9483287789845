/**
 * DI API
 * This is a new DI Data Model in OpenAPI format
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InterestFeatures model module.
 * @module model/InterestFeatures
 * @version 1.0.3
 */
class InterestFeatures {
    /**
     * Constructs a new <code>InterestFeatures</code>.
     * @alias module:model/InterestFeatures
     */
    constructor() { 
        
        InterestFeatures.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>InterestFeatures</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InterestFeatures} obj Optional instance to populate.
     * @return {module:model/InterestFeatures} The populated <code>InterestFeatures</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InterestFeatures();

            if (data.hasOwnProperty('FirstPaymentDate')) {
                obj['FirstPaymentDate'] = ApiClient.convertToType(data['FirstPaymentDate'], 'Date');
            }
            if (data.hasOwnProperty('AmortizationYield')) {
                obj['AmortizationYield'] = ApiClient.convertToType(data['AmortizationYield'], 'Number');
            }
            if (data.hasOwnProperty('FinancialCentreforPayment')) {
                obj['FinancialCentreforPayment'] = ApiClient.convertToType(data['FinancialCentreforPayment'], 'String');
            }
            if (data.hasOwnProperty('Margin')) {
                obj['Margin'] = ApiClient.convertToType(data['Margin'], 'Number');
            }
            if (data.hasOwnProperty('InterestFromDate')) {
                obj['InterestFromDate'] = ApiClient.convertToType(data['InterestFromDate'], 'Date');
            }
            if (data.hasOwnProperty('InterestToDate')) {
                obj['InterestToDate'] = ApiClient.convertToType(data['InterestToDate'], 'Date');
            }
            if (data.hasOwnProperty('InterestCommencementDate')) {
                obj['InterestCommencementDate'] = ApiClient.convertToType(data['InterestCommencementDate'], 'Date');
            }
            if (data.hasOwnProperty('Benchmark')) {
                obj['Benchmark'] = ApiClient.convertToType(data['Benchmark'], 'String');
            }
            if (data.hasOwnProperty('FixedInterestRate')) {
                obj['FixedInterestRate'] = ApiClient.convertToType(data['FixedInterestRate'], 'Number');
            }
            if (data.hasOwnProperty('DayCountBasis')) {
                obj['DayCountBasis'] = ApiClient.convertToType(data['DayCountBasis'], 'String');
            }
            if (data.hasOwnProperty('AdjustmentOfInterestPeriod')) {
                obj['AdjustmentOfInterestPeriod'] = ApiClient.convertToType(data['AdjustmentOfInterestPeriod'], 'String');
            }
            if (data.hasOwnProperty('BusinessDayConvention')) {
                obj['BusinessDayConvention'] = ApiClient.convertToType(data['BusinessDayConvention'], 'String');
            }
            if (data.hasOwnProperty('FloatingBasis')) {
                obj['FloatingBasis'] = ApiClient.convertToType(data['FloatingBasis'], 'Boolean');
            }
            if (data.hasOwnProperty('FloatingBasisType')) {
                obj['FloatingBasisType'] = ApiClient.convertToType(data['FloatingBasisType'], 'String');
            }
            if (data.hasOwnProperty('PeriodicInterestDetermination')) {
                obj['PeriodicInterestDetermination'] = ApiClient.convertToType(data['PeriodicInterestDetermination'], 'String');
            }
            if (data.hasOwnProperty('MaxInterest')) {
                obj['MaxInterest'] = ApiClient.convertToType(data['MaxInterest'], 'Number');
            }
            if (data.hasOwnProperty('MinInterest')) {
                obj['MinInterest'] = ApiClient.convertToType(data['MinInterest'], 'Number');
            }
            if (data.hasOwnProperty('CalculationType')) {
                obj['CalculationType'] = ApiClient.convertToType(data['CalculationType'], 'String');
            }
            if (data.hasOwnProperty('DeterminationMethod')) {
                obj['DeterminationMethod'] = ApiClient.convertToType(data['DeterminationMethod'], 'String');
            }
            if (data.hasOwnProperty('ObservationMethod')) {
                obj['ObservationMethod'] = ApiClient.convertToType(data['ObservationMethod'], 'String');
            }
            if (data.hasOwnProperty('RelevantFinancialCentreForFixing')) {
                obj['RelevantFinancialCentreForFixing'] = ApiClient.convertToType(data['RelevantFinancialCentreForFixing'], 'String');
            }
            if (data.hasOwnProperty('TermPeriod')) {
                obj['TermPeriod'] = ApiClient.convertToType(data['TermPeriod'], 'String');
            }
            if (data.hasOwnProperty('InterestType')) {
                obj['InterestType'] = ApiClient.convertToType(data['InterestType'], 'String');
            }
            if (data.hasOwnProperty('InterestPaymentFrequency')) {
                obj['InterestPaymentFrequency'] = ApiClient.convertToType(data['InterestPaymentFrequency'], 'String');
            }
            if (data.hasOwnProperty('FirstFractionalPeriod')) {
                obj['FirstFractionalPeriod'] = ApiClient.convertToType(data['FirstFractionalPeriod'], 'String');
            }
            if (data.hasOwnProperty('LastFractionalPeriod')) {
                obj['LastFractionalPeriod'] = ApiClient.convertToType(data['LastFractionalPeriod'], 'String');
            }
            if (data.hasOwnProperty('RateSource')) {
                obj['RateSource'] = ApiClient.convertToType(data['RateSource'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>InterestFeatures</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>InterestFeatures</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['FinancialCentreforPayment'] && !(typeof data['FinancialCentreforPayment'] === 'string' || data['FinancialCentreforPayment'] instanceof String)) {
            throw new Error("Expected the field `FinancialCentreforPayment` to be a primitive type in the JSON string but got " + data['FinancialCentreforPayment']);
        }
        // ensure the json data is a string
        if (data['Benchmark'] && !(typeof data['Benchmark'] === 'string' || data['Benchmark'] instanceof String)) {
            throw new Error("Expected the field `Benchmark` to be a primitive type in the JSON string but got " + data['Benchmark']);
        }
        // ensure the json data is a string
        if (data['DayCountBasis'] && !(typeof data['DayCountBasis'] === 'string' || data['DayCountBasis'] instanceof String)) {
            throw new Error("Expected the field `DayCountBasis` to be a primitive type in the JSON string but got " + data['DayCountBasis']);
        }
        // ensure the json data is a string
        if (data['AdjustmentOfInterestPeriod'] && !(typeof data['AdjustmentOfInterestPeriod'] === 'string' || data['AdjustmentOfInterestPeriod'] instanceof String)) {
            throw new Error("Expected the field `AdjustmentOfInterestPeriod` to be a primitive type in the JSON string but got " + data['AdjustmentOfInterestPeriod']);
        }
        // ensure the json data is a string
        if (data['BusinessDayConvention'] && !(typeof data['BusinessDayConvention'] === 'string' || data['BusinessDayConvention'] instanceof String)) {
            throw new Error("Expected the field `BusinessDayConvention` to be a primitive type in the JSON string but got " + data['BusinessDayConvention']);
        }
        // ensure the json data is a string
        if (data['FloatingBasisType'] && !(typeof data['FloatingBasisType'] === 'string' || data['FloatingBasisType'] instanceof String)) {
            throw new Error("Expected the field `FloatingBasisType` to be a primitive type in the JSON string but got " + data['FloatingBasisType']);
        }
        // ensure the json data is a string
        if (data['PeriodicInterestDetermination'] && !(typeof data['PeriodicInterestDetermination'] === 'string' || data['PeriodicInterestDetermination'] instanceof String)) {
            throw new Error("Expected the field `PeriodicInterestDetermination` to be a primitive type in the JSON string but got " + data['PeriodicInterestDetermination']);
        }
        // ensure the json data is a string
        if (data['CalculationType'] && !(typeof data['CalculationType'] === 'string' || data['CalculationType'] instanceof String)) {
            throw new Error("Expected the field `CalculationType` to be a primitive type in the JSON string but got " + data['CalculationType']);
        }
        // ensure the json data is a string
        if (data['DeterminationMethod'] && !(typeof data['DeterminationMethod'] === 'string' || data['DeterminationMethod'] instanceof String)) {
            throw new Error("Expected the field `DeterminationMethod` to be a primitive type in the JSON string but got " + data['DeterminationMethod']);
        }
        // ensure the json data is a string
        if (data['ObservationMethod'] && !(typeof data['ObservationMethod'] === 'string' || data['ObservationMethod'] instanceof String)) {
            throw new Error("Expected the field `ObservationMethod` to be a primitive type in the JSON string but got " + data['ObservationMethod']);
        }
        // ensure the json data is a string
        if (data['RelevantFinancialCentreForFixing'] && !(typeof data['RelevantFinancialCentreForFixing'] === 'string' || data['RelevantFinancialCentreForFixing'] instanceof String)) {
            throw new Error("Expected the field `RelevantFinancialCentreForFixing` to be a primitive type in the JSON string but got " + data['RelevantFinancialCentreForFixing']);
        }
        // ensure the json data is a string
        if (data['TermPeriod'] && !(typeof data['TermPeriod'] === 'string' || data['TermPeriod'] instanceof String)) {
            throw new Error("Expected the field `TermPeriod` to be a primitive type in the JSON string but got " + data['TermPeriod']);
        }
        // ensure the json data is a string
        if (data['InterestType'] && !(typeof data['InterestType'] === 'string' || data['InterestType'] instanceof String)) {
            throw new Error("Expected the field `InterestType` to be a primitive type in the JSON string but got " + data['InterestType']);
        }
        // ensure the json data is a string
        if (data['InterestPaymentFrequency'] && !(typeof data['InterestPaymentFrequency'] === 'string' || data['InterestPaymentFrequency'] instanceof String)) {
            throw new Error("Expected the field `InterestPaymentFrequency` to be a primitive type in the JSON string but got " + data['InterestPaymentFrequency']);
        }
        // ensure the json data is a string
        if (data['FirstFractionalPeriod'] && !(typeof data['FirstFractionalPeriod'] === 'string' || data['FirstFractionalPeriod'] instanceof String)) {
            throw new Error("Expected the field `FirstFractionalPeriod` to be a primitive type in the JSON string but got " + data['FirstFractionalPeriod']);
        }
        // ensure the json data is a string
        if (data['LastFractionalPeriod'] && !(typeof data['LastFractionalPeriod'] === 'string' || data['LastFractionalPeriod'] instanceof String)) {
            throw new Error("Expected the field `LastFractionalPeriod` to be a primitive type in the JSON string but got " + data['LastFractionalPeriod']);
        }
        // ensure the json data is a string
        if (data['RateSource'] && !(typeof data['RateSource'] === 'string' || data['RateSource'] instanceof String)) {
            throw new Error("Expected the field `RateSource` to be a primitive type in the JSON string but got " + data['RateSource']);
        }

        return true;
    }


}



/**
 * The first planned interest payment date.[348]
 * @member {Date} FirstPaymentDate
 */
InterestFeatures.prototype['FirstPaymentDate'] = undefined;

/**
 * Rate used to calculate the Amortised Nominal Amount or inferred from the Amortised Nominal amount compared to the par value of a Zero Coupon Note. Mandatory for MTN Zero Coupon bonds where an amortization yield is required.[156]
 * @member {Number} AmortizationYield
 */
InterestFeatures.prototype['AmortizationYield'] = undefined;

/**
 * ISO 20022 FinancialCenterCode(s) which indicate the calendar used to determine the payment business day. Payment must be made on an open business day of the named financial centre. Where an ISO code is not available, \"OTHR\" will be provided , see the business_days_for_payment for the payment calendar location(s).[164]
 * @member {String} FinancialCentreforPayment
 */
InterestFeatures.prototype['FinancialCentreforPayment'] = undefined;

/**
 * Margin expressed in basis point that must be added or deducted from the floating rate base (index) to calculate the final rate.[171]
 * @member {Number} Margin
 */
InterestFeatures.prototype['Margin'] = undefined;

/**
 * Date on which the the interest period will start, usually defined by the interest commencement date for the first period or the last interest payment date of the prior interest period. Mandatory.[346]
 * @member {Date} InterestFromDate
 */
InterestFeatures.prototype['InterestFromDate'] = undefined;

/**
 * Date on which the the interest period will end, usually defined by the last interest payment date of the interest period.[347]
 * @member {Date} InterestToDate
 */
InterestFeatures.prototype['InterestToDate'] = undefined;

/**
 * Date on which the first interest accrual period begins. This is typically the settlement date, except for tap issuance where it is the interest payment date on the mother code security prior to the settlement of the tap tranche being issued[155]
 * @member {Date} InterestCommencementDate
 */
InterestFeatures.prototype['InterestCommencementDate'] = undefined;

/**
 * The benchmark rate used to price the floating rate coupon. Mandatory for notes with a Floating Rate component.[151]
 * @member {String} Benchmark
 */
InterestFeatures.prototype['Benchmark'] = undefined;

/**
 * Per annum rate of interest paid to the principal amount of the financial instrument.[152]
 * @member {Number} FixedInterestRate
 */
InterestFeatures.prototype['FixedInterestRate'] = undefined;

/**
 * ISO 20022 InterestComputationMethodCode reference codes associated with the day count fraction. Where an ISO code is not available for a specific day count fraction, \"OTHR\" is required[154]
 * @member {String} DayCountBasis
 */
InterestFeatures.prototype['DayCountBasis'] = undefined;

/**
 * Specifies whether the interest period end date moves if the planned interest end date falls on a nonbusiness day. Mandatory (Adjusted/Unadjusted)[160]
 * @member {String} AdjustmentOfInterestPeriod
 */
InterestFeatures.prototype['AdjustmentOfInterestPeriod'] = undefined;

/**
 * ISO 20022 BusinessDayConventionCode code which specifies how a date is adjusted when it falls on a non,business day. Where an ISO code is not available for a specific business day convention, we will return \"OTHR\" , please see fixed_business_day_convention.[162]
 * @member {String} BusinessDayConvention
 */
InterestFeatures.prototype['BusinessDayConvention'] = undefined;

/**
 * The benchmark rate and its periodicity (where applicable).[166]
 * @member {Boolean} FloatingBasis
 */
InterestFeatures.prototype['FloatingBasis'] = undefined;

/**
 * Determines whether the rate used is a term rate (e.g. LIBOR) or an overnight rate (e.g. SOFR/SONIA/€STR).[167]
 * @member {String} FloatingBasisType
 */
InterestFeatures.prototype['FloatingBasisType'] = undefined;

/**
 * Whether an interest payment can occur peridiocally and is not covered by conventional interest calculation methods.[168]
 * @member {String} PeriodicInterestDetermination
 */
InterestFeatures.prototype['PeriodicInterestDetermination'] = undefined;

/**
 * The maximum interest rate or 'cap' to be used when calculating the floating rate coupon.[172]
 * @member {Number} MaxInterest
 */
InterestFeatures.prototype['MaxInterest'] = undefined;

/**
 * The minimum interest rate or 'floor' to be used when calculating the floating rate coupon.[173]
 * @member {Number} MinInterest
 */
InterestFeatures.prototype['MinInterest'] = undefined;

/**
 * The type of calculation methodology applied to overnight rates for the purposes of calculating a variable rate coupon.[175]
 * @member {String} CalculationType
 */
InterestFeatures.prototype['CalculationType'] = undefined;

/**
 * Indicates whether the rate will be calculated with reference to an index or calculated for each period.[176]
 * @member {String} DeterminationMethod
 */
InterestFeatures.prototype['DeterminationMethod'] = undefined;

/**
 * Indicates the observation method to be used in determining the variable rate for overnight rates.[177]
 * @member {String} ObservationMethod
 */
InterestFeatures.prototype['ObservationMethod'] = undefined;

/**
 * ISO 20022 code associated with the financial place taken into account in fixing the rate. Where an ISO code is not available, \"OTHR\" will be provided , see the floating_relevant financial centre for fixing for the financial centre.[179]
 * @member {String} RelevantFinancialCentreForFixing
 */
InterestFeatures.prototype['RelevantFinancialCentreForFixing'] = undefined;

/**
 * Period for which interest is fixed[182]
 * @member {String} TermPeriod
 */
InterestFeatures.prototype['TermPeriod'] = undefined;

/**
 * Indicates the coupon type (fixed, variable, etc.).[183]
 * @member {String} InterestType
 */
InterestFeatures.prototype['InterestType'] = undefined;

/**
 * Specifies the frequency of the interest payments. Mandatory for all except Zero Coupon bonds.[351]
 * @member {String} InterestPaymentFrequency
 */
InterestFeatures.prototype['InterestPaymentFrequency'] = undefined;

/**
 * Indicates the type of broken period. Mandatory (can be 'None' if not required) except for Zero Coupon notes.[352]
 * @member {String} FirstFractionalPeriod
 */
InterestFeatures.prototype['FirstFractionalPeriod'] = undefined;

/**
 * Indicates the type of broken period.Mandatory (can be 'None' if not required) except for Zero Coupon notes.[353]
 * @member {String} LastFractionalPeriod
 */
InterestFeatures.prototype['LastFractionalPeriod'] = undefined;

/**
 * The rate determination source which provides the rate determination page which will provide the reference rate for the floating rate coupon.[383]
 * @member {String} RateSource
 */
InterestFeatures.prototype['RateSource'] = undefined;






export default InterestFeatures;

