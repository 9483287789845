import React, { useEffect, useState } from "react";
import { useUserStore } from "../../store/Store";
import { AppCommonContext } from "../../App";
import { getCountsBySection } from "../../utility";
import { ViewDetailsContainer } from "./index.css";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDowloadIcon from "@mui/icons-material/FileDownload";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import {
  getApprovementStatusLabel,
  getApprovementStatusClass,
} from "../../utils/StatusUtils";
import MessagePopup from "./MessagePopup";
import UploadFile from "./UploadFile";
import ListTable from "./ListTable";
import { saveAs } from "file-saver";
import { AccountsInternalApiClient } from "../../api";
import { Space } from "antd";
import { DATE_FORMATS } from "../../constants";
import { convertDateTime, timer } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  ul: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#00A7C4",
      color: "whitesmoke",
    },
    "& .MuiPaginationItem-icon": {
      border: "1px solid #00A7C4",
      borderRadius: "12px",
    },
    "& .MuiPaginationItem-page": {
      color: "#00A7C4",
    },
  },
  dialogPaper: {
    width: "480px",
    height: "320px",
  },
}));

const columns = [
  { id: "statusName", label: "Status" },
  { id: "numOfRecords", label: "Description" },
  { id: "user", label: "User" },
  { id: "timestamp", label: "Timestamp" },
  { id: "timer", label: "Timer" },
];

const AccountListChangeSets = () => {
  const classes = useStyles();
  const history = useHistory();
  const appContext = React.useContext(AppCommonContext);
  const { setIndividualPendingSectionCount } = appContext;
  const [userState] = useUserStore();
  const [responseText, setResponseText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [flagPopUp, setflagPopUp] = useState("");
  const [data, setData] = useState([]);
  const [uploadFileOpen, setUploadFileOpen] = useState(false);
  const [lastUploadTime, setLastUploadTime] = useState();
  const [itemsCount, setItemsCount] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAccountChangeSets();
    getLastUploadTime();
  }, []);

  const onPageChange = (page, pageSize) => {
    const newNumOfPages = Math.ceil(itemsCount / pageSize);
    const newPage = page > newNumOfPages ? newNumOfPages : page;

    fetchAccountChangeSets(newPage, pageSize);
  };

  const fetchAccountChangeSets = (page = 1, pagesize = 100) => {
    const paginationParam = { page, pagesize };
    AccountsInternalApiClient.getAccountsChangesets(paginationParam)
      .then(({ response }) => {
        const displayNumberOfRecords = (numOfRecords) => {
          if (numOfRecords) {
            return numOfRecords === 0
              ? ""
              : numOfRecords === 1
              ? "1 record"
              : `${numOfRecords} records`;
          } else {
            return "";
          }
        };
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          let responseData;
          if (Array.isArray(response?.data)) {
            responseData = [...response?.data];
          } else if (typeof response?.data === "object") {
            responseData = [...response?.data?.items];
          }
          if (responseData?.length) {
            let data = [];
            responseData?.sort(function (a, b) {
              return new Date(b.uploadTimestamp) - new Date(a.uploadTimestamp);
            });
            responseData?.forEach((element) => {
              let obj = {
                id: element.id ?? "",
                statusName: getApprovementStatusLabel(element?.status),
                statusStyle: getApprovementStatusClass(element?.status),
                numOfRecords: displayNumberOfRecords(element.numOfRecords),
                user:
                  `${element.uploaderUserName} (${element.uploaderUserId})` ??
                  "",
                timestamp:
                  convertDateTime(
                    element.uploadTimestamp,
                    DATE_FORMATS.DATE_AND_TIME
                  ) || "",
                timer: timer(element.uploadTimestamp),
              };
              data.push(obj);
            });
            setLoading(false);
            setData(data);
            setItemsCount(response?.data?.count);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const uploadChangeSets = (uploadedFiles) => {
    setUploadFileOpen(false);

    AccountsInternalApiClient.addNewAccountChangeSet(uploadedFiles[0])
      .then(async ({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          setResponseText("Upload successful");
          setShowPopUp(true);
          setflagPopUp("success");
          fetchAccountChangeSets(1, 1000);
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
          const individualPendingCount = await getCountsBySection();
          setIndividualPendingSectionCount(individualPendingCount);
        } else {
          setResponseText("Unable to upload CSV. Please try later.");
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText(error.message);
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };

  const exportTemplate = () => {
    AccountsInternalApiClient.exportAccountsTemplate()
      .then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          var blob = new Blob([response.data]);
          saveAs(
            blob,
            `Account Changeset template ${moment(new Date()).format(
              "DD.MM.YYYY"
            )}.csv`
          );
          setResponseText("Template downloaded successfully");
          setShowPopUp(true);
          setflagPopUp("success");
          setTimeout(() => {
            setShowPopUp(false);
            setResponseText("");
          }, 10000);
        } else {
          setResponseText("Unable to download template.Please try later");
          setShowPopUp(true);
          setflagPopUp("error");
        }
      })
      .catch((error) => {
        setResponseText("Unable to download template.Please try later");
        setShowPopUp(true);
        setflagPopUp("error");
      });
  };
  const getLastUploadTime = () => {
    if (userState.userRoleRight.includes("View_Compliance_List_Upload_Time")) {
      AccountsInternalApiClient.getAccountsTime().then(({ response }) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          setLastUploadTime(response.data.uploadDateTime);
        }
      });
    }
  };

  const closeErrorSuccessPopup = () => {
    setShowPopUp(false);
    setResponseText("");
  };
  const openDetailsPage = (id) => {
    history.push({
      pathname: "/account/details/" + id,
      state: { from: "account" },
    });
  };

  return (
    <>
      <ViewDetailsContainer>
        <MessagePopup
          responseText={responseText}
          showPopUp={showPopUp}
          flagPopUp={flagPopUp}
          closeErrorSuccessPopup={closeErrorSuccessPopup}
        />

        <Grid container>
          <Grid
            item
            xs={3}
            align="start"
            style={{ textAlign: "justify", alignSelf: "end" }}
          >
            <Typography variant="h5" id="det_typo_isindet" className="item">
              {lastUploadTime &&
                `Last Upload: ${convertDateTime(
                  lastUploadTime,
                  DATE_FORMATS.DATE_AND_TIME
                )}`}
            </Typography>
          </Grid>
          <Grid item xs={9} align="end">
            <Space>
              <Button
                variant="outlined"
                id="btnActionsTop"
                color="primary"
                className="btnUploadList"
                onClick={() => setUploadFileOpen(true)}
              >
                {" "}
                <FileUploadIcon /> Upload
              </Button>
              <Button
                variant="outlined"
                id="btn_downloadTemplate"
                color="primary"
                className="btnUploadList"
                onClick={() => exportTemplate()}
              >
                {" "}
                <FileDowloadIcon /> Export Template
              </Button>
            </Space>
          </Grid>
        </Grid>
      </ViewDetailsContainer>
      {data && (
        <ListTable
          loading={loading}
          data={data}
          columns={columns}
          classes={classes}
          onRowClick={openDetailsPage}
          onPageChange={onPageChange}
          itemsCount={itemsCount}
        />
      )}
      <UploadFile
        classes={classes}
        uploadFileOpen={uploadFileOpen}
        closeDialog={() => setUploadFileOpen(false)}
        uploadlist={uploadChangeSets}
      />
    </>
  );
};

export default AccountListChangeSets;
